export const configValues = {
  checksConfig: [
    { check: 'IDENTITY', title: 'Identity', type: 'integer', limit: 5 },
    { check: 'ADDRESS', title: 'Address', type: 'integer', limit: 2 },
    { check: 'COURT', title: 'Court', type: 'integer', limit: 2 },
    { check: 'EMPLOYMENT', title: 'Employment', type: 'integer', limit: 10 },
    { check: 'EDUCATION_CHECK', title: 'Education Check', type: 'integer', limit: 5 },
    { check: 'REFERENCE', title: 'Reference Check', type: 'integer', limit: 10 },
    { check: 'WORLD', title: 'World Check', type: 'boolean', limit: 1 },
    { check: 'CREDIT', title: 'Credit Check', type: 'boolean', limit: 1 },
    { check: 'DRUG', title: 'Drug Test', type: 'boolean', limit: 1 }
  ]
};
