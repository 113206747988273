import { connect } from 'react-redux';
import { compose } from 'redux';

import { getCompanyUsageLogs as getCompanyUsageLogsAPI } from '../../../../../actions/admin';

const mapStateToProps = (state) => {
  return {
    companyUsageLogs: state.companyUsageLogs.companyUsageLogs || []
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyUsageLogs: (
      companyId,
      limit,
      offset,
      event,
      from_date,
      to_date,
      candidate_name,
      user_name,
      invoice_number,
      credit_type,
      user_id
    ) =>
      dispatch(
        getCompanyUsageLogsAPI(
          companyId,
          limit,
          offset,
          event,
          from_date,
          to_date,
          candidate_name,
          user_name,
          invoice_number,
          credit_type,
          user_id
        )
      )
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
