import React, { useState, useEffect, useRef } from 'react';

import { isEmpty } from 'lodash';
import {
  Typography,
  IconButton,
  Button,
  Grid,
  Box,
  Tooltip,
  Zoom,
  Paper,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormGroup
} from '@material-ui/core';
import { Field, FieldArray } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { getTrimmedValue } from '../../../../../../utils/Utlities';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';

import FormInput from '../../../../../../core-components/FormInput';
import ConfirmationPackage from '../../../../../../core-components/ConfirmationPackage';
import InternationalChargesBanner from '../../../../UtilsComponents/InternationalChargesBanner';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex'
  },
  formControl: {
    marginBottom: theme.spacing(3),
    marginLeft: '8px'
  },
  activeBtn: {
    margin: theme.spacing(1),
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  disabledBtn: {
    cursor: 'not-allowed',
    margin: theme.spacing(1),
    color: '#dfdfdf',
    borderColor: '#d9d9d9',
    backgroundColor: '#f5f5f5',
    fontSize: 14,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  progressContainer: {
    textAlign: 'center',
    width: '100%'
  },
  unitContainer: {
    display: 'flex',
    padding: '20px 16px 20px 16px !important',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    marginBottom: '8px',
    borderRadius: '4px'
  },
  costPerCandidateContainer: {
    padding: '20px 16px 20px 16px !important',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderLeft: 'unset',
    marginBottom: '8px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px'
  },
  marginBottom16: {
    marginBottom: '16px'
  },
  marginTop16: {
    marginTop: '16px'
  },
  required: {
    color: '#DB3131'
  },
  notRequired: {
    color: '#FFA500'
  },
  fieldControl: {
    '& input': {
      padding: '18.5px 14px'
    }
  },
  errorMsg: {
    color: 'red',
    fontSize: '10px',
    paddingLeft: '.5rem'
  }
}));

function AssignCompanyStep2(props) {
  const classes = useStyles();

  const { values, dirty, errors, setFieldValue } = props;
  const [subtypes, setSubtypes] = useState(values.packageText);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const myRef = useRef(null);

  // For smooth scroll
  const scrollToRef = (ref) => {
    ref.current.scrollIntoView(true);
  };

  useEffect(() => {
    scrollToRef(myRef);
  }, []);

  useEffect(() => {
    setSubtypes(values.packageText);
  }, [props.values.packageText]);

  const handleStep2 = (isBack = false) => {
    setShowConfirmationPopup(true);
    props.handleStepSubmit('step2', values, isBack ? 1 : 3);
  };

  const handleFormSubmit = (e) => {
    if (e) e.preventDefault();

    const hasEmptyPackageText = values.packageText.some(
      (item) => item.isAssigned && !item.subtypePackageText.trim()
    );

    if (hasEmptyPackageText) {
      setShowConfirmationPopup(true);
    } else {
      props.handleSubmit();
    }
  };

  const handleAddRechargeDetails = () => {
    const rechargeDetailsArrCopy = [...values.rechargeDetails];
    rechargeDetailsArrCopy.push({
      rechargeUnits: 0,
      costPerCandidate: 0
    });
    setFieldValue('rechargeDetails', [...rechargeDetailsArrCopy]);
  };

  const handleChange = (event, subtypeId) => {
    // getting copy of all subtypes
    const tempArr = [...subtypes];
    // getting index and subtype which is clicked
    const index = tempArr.findIndex((sub) => sub.subtypeId === subtypeId);

    // updating subtype disable property and updating subtypes array
    tempArr[index].isAssigned = !tempArr[index].isAssigned;

    setSubtypes(tempArr);
  };

  // Disable save button if at least one checkbox is checked
  const validatePackageText = (subtypesArr) => {
    const assigned = subtypesArr.filter((val) => val.isAssigned);
    return assigned.length === 0 ? false : true;
  };
  const isValidForm = isEmpty(errors) && validatePackageText(subtypes);

  const isInvalidPricing = () => {
    return getInvalidPricingTiers().some((item) => item === true);
  };
  const getInvalidPricingTiers = () => {
    if (values.rechargeDetails.length > 1) {
      const invalidPricing = values.rechargeDetails.map((rd, index) => {
        const lowerRechargeUnits = values.rechargeDetails.filter(
          (obj) =>
            parseInt(obj.rechargeUnits) > 0 &&
            parseInt(obj.rechargeUnits) < parseInt(rd.rechargeUnits)
        );
        const higherCost = lowerRechargeUnits.filter(
          (obj1) =>
            parseInt(obj1.costPerCandidate) > 0 &&
            parseInt(obj1.costPerCandidate) < parseInt(rd.costPerCandidate)
        );
        return higherCost.length > 0;
      });
      return invalidPricing;
    } else {
      return [];
    }
  };
  const isInvalidUnit = () => {
    return getInvalidUnitTiers().some((item) => item === true);
  };
  const getInvalidUnitTiers = () => {
    if (values.rechargeDetails.length > 1) {
      const invalidUnits = values.rechargeDetails.map((rd, index) => {
        const sameRechargeUnits = values.rechargeDetails.filter(
          (obj) =>
            parseInt(obj.rechargeUnits) > 0 &&
            parseInt(obj.rechargeUnits) === parseInt(rd.rechargeUnits)
        );
        return sameRechargeUnits.length > 1;
      });
      return invalidUnits;
    } else {
      return [];
    }
  };
  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <Grid ref={myRef} className={classes.root} container direction={'row'}>
          <Grid item lg={8} md={8} sm={10} xs={10}>
            {props.parent === 'COMPANY' ? (
              <Field
                type='text'
                name='packageName'
                requiredfield={'true'}
                label='Package Name'
                component={FormInput}
                variant={'outlined'}
                fullWidth
                disabled={true}
              />
            ) : (
              <Field
                type='text'
                name='companyName'
                requiredfield={'true'}
                label='Company Name'
                component={FormInput}
                variant={'outlined'}
                fullWidth
                disabled={true}
              />
            )}
            <Typography
              variant={'subtitle2'}
              gutterBottom
              style={{
                marginBottom: '16px',
                marginTop: '16px',
                padding: '10px',
                backgroundColor: '#FAEBD7'
              }}
            >
              Package base price : {props.packageBaseCost}
            </Typography>
            {props.packageDetails?.loading === false &&
              props.packageDetails?.data?.packageDetails?.internationalChargesIncluded && (
                <InternationalChargesBanner />
              )}
            {/* Recharge details section */}
            <Box mt={2} mb={2}>
              <Typography variant={'subtitle2'} gutterBottom style={{ marginBottom: '16px' }}>
                {'Recharge details'}
              </Typography>

              <Grid container style={{ alignItems: 'center' }}>
                {values?.rechargeDetails && values?.rechargeDetails?.length ? (
                  <FieldArray
                    name='rechargeDetails'
                    render={(arrayHelpers) =>
                      values?.rechargeDetails.map((item, index) => {
                        return (
                          <React.Fragment key={`questions_${index + 1}`}>
                            <Grid item lg={10} md={10} sm={10} xs={10}>
                              <Paper
                                elevation={3}
                                variant={'outlined'}
                                className={classes.unitContainer}
                              >
                                <Grid container>
                                  <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <div>
                                      <Field
                                        type='number'
                                        id={`rechargeDetails_unit_${index}`}
                                        name={`rechargeDetails.${index}.rechargeUnits`}
                                        placeholder={'1'}
                                        requiredfield={'true'}
                                        label={'Recharge unit'}
                                        component={FormInput}
                                        variant={'outlined'}
                                        fullWidth
                                        style={{ marginRight: '8px' }}
                                        error={
                                          values.rechargeDetails.length > 1
                                            ? getInvalidUnitTiers()[index]
                                            : false
                                        }
                                      />
                                      {values.rechargeDetails.length > 1 &&
                                        getInvalidUnitTiers()[index] && (
                                          <div className={classes.errorMsg}>
                                            Recharge Units cannot be of equal value
                                          </div>
                                        )}
                                    </div>
                                  </Grid>
                                  <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <div>
                                      <Field
                                        type='number'
                                        id={`rechargeDetails_cost_${index}`}
                                        name={`rechargeDetails.${index}.costPerCandidate`}
                                        placeholder={'500'}
                                        requiredfield={'true'}
                                        label={'Cost (per candidate)'}
                                        component={FormInput}
                                        variant={'outlined'}
                                        fullWidth
                                        style={{ marginLeft: '8px' }}
                                        error={
                                          values.rechargeDetails.length > 1
                                            ? getInvalidPricingTiers()[index]
                                            : false
                                        }
                                      />
                                      {values.rechargeDetails.length > 1 &&
                                        getInvalidPricingTiers()[index] && (
                                          <div className={classes.errorMsg}>
                                            Higher volume tier price can't surpass lower tier price
                                          </div>
                                        )}
                                    </div>
                                  </Grid>
                                </Grid>
                              </Paper>
                            </Grid>
                            {values.rechargeDetails && values.rechargeDetails.length > 1 ? (
                              <Grid item xs={2} sm={2} md={2} lg={2}>
                                <IconButton
                                  aria-label='Delete'
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <Tooltip
                                    className={classes.tooltip}
                                    title='Delete'
                                    arrow={true}
                                    TransitionComponent={Zoom}
                                  >
                                    <DeleteIcon style={{ fontSize: '21px' }} />
                                  </Tooltip>
                                </IconButton>
                              </Grid>
                            ) : null}
                          </React.Fragment>
                        );
                      })
                    }
                  />
                ) : null}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Button
                    onClick={() => {
                      handleAddRechargeDetails();
                    }}
                    color={'primary'}
                    startIcon={<AddCircleOutlineIcon />}
                  >
                    {'Add Recharge Units'}
                  </Button>
                </Grid>
              </Grid>
            </Box>

            {/* Package text section */}
            <Box mt={2} mb={2}>
              <Typography
                variant={'subtitle2'}
                gutterBottom
                className={`${classes.marginBottom16} ${
                  props.parent === 'COMPANY' ? classes.marginTop16 : ''
                }`}
              >
                Package text for Sub-types <span className={classes.notRequired}>*</span>
              </Typography>

              <Grid container spacing={2}>
                {subtypes && subtypes.length ? (
                  <FieldArray
                    name='packageText'
                    render={() =>
                      subtypes.map((item, index) => {
                        const handleBlur = (e) => {
                          const trimmedValue = getTrimmedValue(e.target.value, true);
                          setFieldValue(
                            `packageText.${index}.subtypePackageText`,
                            trimmedValue || ''
                          );
                        };
                        return (
                          <Grid item lg={12} md={12} sm={12} xs={12} key={`questions_${index + 1}`}>
                            <Field
                              type='text'
                              id={`packageText_${index + 1}`}
                              name={`packageText.${index}.subtypePackageText`}
                              placeholder='Package text'
                              label={item.subtypeName}
                              component={FormInput}
                              variant='outlined'
                              className={classes.fieldControl}
                              onBlur={handleBlur}
                            />
                            <FormControl component='fieldset' className={classes.formControl}>
                              <FormGroup>
                                <FormControlLabel
                                  key={item.subtypeId}
                                  control={
                                    <Checkbox
                                      checked={item.isAssigned}
                                      onChange={(e) => handleChange(e, item.subtypeId)}
                                      name={item.subtypeName}
                                    />
                                  }
                                  label={'Assign'}
                                />
                              </FormGroup>
                            </FormControl>
                          </Grid>
                        );
                      })
                    }
                  />
                ) : null}
              </Grid>
            </Box>
          </Grid>
        </Grid>

        {/* Footer */}
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Button
              variant='outlined'
              color={'primary'}
              size='large'
              onClick={() => handleStep2(true)}
              startIcon={<ArrowBackIosIcon />}
            >
              {'Back'}
            </Button>
            <Button
              type={'submit'}
              variant='contained'
              size='large'
              className={isValidForm ? classes.activeBtn : classes.disabledBtn}
              disabled={!isValidForm || isInvalidPricing() || isInvalidUnit() || !dirty}
              endIcon={<ArrowForwardIosIcon />}
            >
              {'Save & Next'}
            </Button>
          </Grid>
        </Grid>
        {showConfirmationPopup ? (
          <ConfirmationPackage
            open={showConfirmationPopup}
            handleClose={() => {
              setShowConfirmationPopup(false);
            }}
            handleSubmit={() => {
              props.handleSubmit();
            }}
            buttonLabel={'Yes,Save'}
            title='No package text assigned'
            content='Are you sure you want to proceed without package text?'
          />
        ) : null}
      </form>
    </>
  );
}

export default AssignCompanyStep2;
