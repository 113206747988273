import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import {
  Typography,
  Grid,
  TextField,
  FormControl,
  FormGroup,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox
} from '@material-ui/core';
import {
  PLAIN_CHECK_ICONS,
  EDUCATION_TYPES_FOR_PACKAGE_CONFIG,
  EDUCATION_TYPES
} from '../../../../../../core-components/CommonConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginLeft: '68px',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  required: {
    color: '#db3131'
  }
}));

/**
 * cPkgDetails: Company PackageDetails
 * sPkgDetails: Customized/CA selected package detailsInfo
 * aPkgDetails: Addon's allowed config/available options in addons
 * onValueChange: Func to save the values to parent state
 * values: Addons values as they changes
 */
export default (props) => {
  const classes = useStyles();

  //   Refer DocBlock above for detailed variable usage
  const { cPkgDetails, sPkgDetails, aPkgDetails, onValueChange, values, educationType } = props;

  const handleConfigChange = (event, name) => {
    let dummy = [...values.config];
    if (event.target.checked) {
      if (!dummy?.includes(name)) {
        dummy.push(name);
        onValueChange('config', dummy);
      }
    } else {
      dummy.splice(dummy.indexOf(name), 1);
      onValueChange('config', dummy);
    }
    if (values.config.length > 0) {
      onValueChange('method', educationType);
    }
    if (values.config.length === 0) {
      onValueChange('method', '');
    }
  };

  const hasValue = (name) => {
    return values?.config?.includes(name);
  };

  const isDisabled = () => {
    return values.config.length === props.limit;
  };
  const getEducationRadioOptions = () => {
    return EDUCATION_TYPES.filter((ob) => ob.name === educationType);
  };
  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
            <FormControl component='fieldset'>
              {/* <FormLabel component='legend'>Label Placement</FormLabel> */}
              <FormGroup aria-label='position' row>
                {EDUCATION_TYPES_FOR_PACKAGE_CONFIG.map((value, index) => {
                  return (
                    <React.Fragment key={`Package_Configure_EDU_${index}`}>
                      {!cPkgDetails?.includes(value?.name) ? (
                        <FormControlLabel
                          key={`Package_Configure_${index}`}
                          control={
                            <Checkbox
                              disabled={!hasValue(value?.name) && isDisabled()}
                              checked={hasValue(value?.name)}
                              onChange={(e) => handleConfigChange(e, value?.name)}
                              name={value?.name}
                            />
                          }
                          label={value?.label}
                        />
                      ) : null}
                    </React.Fragment>
                  );
                })}
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item className='mt-4'>
            <FormLabel component='legend'>
              Choose verification type: <span className={classes.required}>*</span>
            </FormLabel>
            <RadioGroup aria-label='method' name='method' value={values?.method}>
              <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                {getEducationRadioOptions().map((ob) => (
                  <FormControlLabel
                    value={ob.name}
                    control={
                      <Radio
                        value={ob.name}
                        color='primary'
                        checked={values?.method === ob.name}
                        disabled={values.config.length === 0}
                      />
                    }
                    label={ob.label}
                  />
                ))}
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
