import _, { isEmpty } from 'lodash';
import { ADDON_CONFIG_MAPPING } from '../../../core-components/CommonConstants';
import { toSnakeCase } from '../../../utils/Utlities';

const mapChecks = (check) => {
  const any = check.any ? check.any === 5 ? `ALL` : `ANY_${check.any}` : null;
  let arr = [];

  if (any) {
    arr = [any];
  }
  if (check.config.length) {
    arr = [...arr, ...check.config];
  }

  return arr;
};

const mapChecksForAadhaar = (check, isAadhaarAllowed) => {
  let configArray = [];
  if (check.any === 4 && !isAadhaarAllowed) {
    configArray = ['PAN', 'DL', 'VOTER_ID', 'PASSPORT'];
  } else if (check.any === 5 && isAadhaarAllowed) {
    configArray = ['PAN', 'DL', 'VOTER_ID', 'PASSPORT', 'AADHAAR'];
  } else if (check.any > 0) {
    configArray = [...[`ANY_${check.any}`, ...check.config]];
  } else {
    configArray = [...check.config];
  }
  return configArray;
};
const getEduPrice = (values, config) => {
  let price = 0;
  if (values.education && values.education.method) {
    switch (values.education.method) {
      case 'OFFICIAL':
        price = config?.education?.price || 0;
        break;
      case 'HYBRID':
        price = config?.educationHybrid?.price || 0;
        break;
      case 'REGIONAL_PARTNER':
        price = config?.educationThirdParty?.price || 0;
        break;

      default:
        break;
    }
  }
  return price;
};

const
  mapAddonsConfig = (originalAddons, userSelectedAddons, isAadhaarAllowed) => {
    const obj = {};
    const {
      identity,
      address,
      court,
      educationOfficial,
      educationHybrid,
      educationRegionalPartner,
      employment,
      reference,
      cibil,
      drug10Panel,
      drug5Panel,
      drug7Panel,
      world
    } = userSelectedAddons;

    if (originalAddons.identity && identity && (identity.any || identity.config.length)) {
      obj['identity'] = {
        price: originalAddons.identity.price,
        config: [...mapChecksForAadhaar(identity, isAadhaarAllowed)]
      };
    }

    if (originalAddons[ADDON_CONFIG_MAPPING[address.verification_type]] && address.config.length) {
      obj[_.snakeCase(ADDON_CONFIG_MAPPING[address.verification_type])] = {
        price: originalAddons[ADDON_CONFIG_MAPPING[address.verification_type]].price,
        config: [...mapChecks(address)]
      };
    }

    if (originalAddons[ADDON_CONFIG_MAPPING[court.verification_type]] && court.config.length) {
      obj[_.snakeCase(ADDON_CONFIG_MAPPING[court.verification_type])] = {
        price: originalAddons[ADDON_CONFIG_MAPPING[court.verification_type]].price,
        config: [...mapChecks(court)]
      };
    }

    if (originalAddons.employment && employment) {
      obj['employment'] = {
        price: originalAddons.employment.price,
        config: employment
      };
    }
    // official
    if (
      originalAddons.education &&
      educationOfficial &&
      (educationOfficial.count || educationOfficial.config.length)
    ) {
      obj['education'] = {
        price: originalAddons.education.price
      };

      if (educationOfficial.count) {
        obj['education']['config'] = educationOfficial.count;
      }

      if (educationOfficial.config.length) {
        obj['education']['config'] = [...educationOfficial.config];
      }
      obj['education']['type'] = 'OFFICIAL';
    }
    // 3rd Party
    if (
      originalAddons.educationThirdParty &&
      educationRegionalPartner &&
      (educationRegionalPartner.count || educationRegionalPartner.config.length)
    ) {
      obj['education'] = {
        price: originalAddons.educationThirdParty.price
      };

      if (educationRegionalPartner.count) {
        obj['education']['config'] = educationRegionalPartner.count;
      }

      if (educationRegionalPartner.config.length) {
        obj['education']['config'] = [...educationRegionalPartner.config];
      }
      obj['education']['type'] = 'REGIONAL_PARTNER';
    }
    //  Hybrid
    if (
      originalAddons.educationHybrid &&
      educationHybrid &&
      (educationHybrid.count || educationHybrid.config.length)
    ) {
      obj['education'] = {
        price: originalAddons.educationHybrid.price
      };

      if (educationHybrid.count) {
        obj['education']['config'] = educationHybrid.count;
      }

      if (educationHybrid.config.length) {
        obj['education']['config'] = [...educationHybrid.config];
      }
      obj['education']['type'] = 'HYBRID';
    }

    if (
      userSelectedAddons.education &&
      (userSelectedAddons.education.count || userSelectedAddons.education.config.length)
    ) {
      obj['education'] = {
        price: getEduPrice(userSelectedAddons, originalAddons)
      };

      if (userSelectedAddons.education.count) {
        obj['education']['config'] = userSelectedAddons.education.count;
      }

      if (userSelectedAddons.education.config.length) {
        obj['education']['config'] = [...userSelectedAddons.education.config];
      }
      obj['education']['type'] = userSelectedAddons.education.method || '';
      obj['education']['option'] = userSelectedAddons.education.type || '';
    }

    if (originalAddons.cibil && cibil) {
      obj['cibil'] = {
        price: originalAddons.cibil.price
      };
    }

    if (originalAddons.world && world) {
      obj['world'] = {
        price: originalAddons.world.price
      };
    }

    if (originalAddons.reference && reference.config.length) {
      obj['reference'] = {
        price: originalAddons.reference.price,
        config: [...reference.config]
      };

      if (reference.count) {
        obj['reference']['config'] = reference.count;
      }

    }

    if (originalAddons.drug10Panel && drug10Panel) {
      obj['drug10Panel'] = {
        price: originalAddons.drug10Panel.price,
        config: originalAddons.drug10Panel.config
      };
    }
    if (originalAddons.drug7Panel && drug7Panel) {
      obj['drug7Panel'] = {
        price: originalAddons.drug7Panel.price,
        config: originalAddons.drug7Panel.config
      };
    }
    if (originalAddons.drug5Panel && drug5Panel) {
      obj['drug5Panel'] = {
        price: originalAddons.drug5Panel.price,
        config: originalAddons.drug5Panel.config
      };
    }

    return obj;
  };

export default (values, addCandidateConfig, isAadhaarAllowed) => {
  return {
    candidate: {
      ...values.step1
    },
    package: {
      ...values.step2,
      config: { ...values.step2.config },
      originalConfig: {
        ...values.step2.config
      }
    },
    addons:
      addCandidateConfig && addCandidateConfig.addons && !isEmpty(addCandidateConfig.addons)
        ? mapAddonsConfig(addCandidateConfig.addons, values.step3, isAadhaarAllowed)
        : {}
  };
};

const formatAddressCourtConfig = (address) => {
  return address.map(o => o.type)
}