import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Grid,
  Button,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Box
} from '@material-ui/core';
import AddInsuffComments from '../../AddInsuffComments';
import { INSUFF_STATUSES } from '../../../../core-components/CommonConstants';
import ReviewedComments from './ReviewedComments';
import UnreviewedComments from './UnreviewedComments';
import { updateManualInsuffTkt } from '../../../../api/admin';
import CheckStatusUpdateModal from './CheckStatusUpdateModal';
import { errToastMessage, getErrorMessage } from '../../../../utils/Utlities';

const useStyles = makeStyles({
  formControl: {
    width: '100%'
  },
  inputHeight: {
    height: 36
  },
  alignItemCenter: {
    alignItems: 'center'
  }
});

export default (props) => {
  const classes = useStyles();
  const [displayAddInsuff, setDisplayAddInsuff] = useState(false);
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showCheckStatusUpdateModal, setShowCheckStatusUpdateModal] = useState(false);
  const [tempStatus, setTempStatus] = useState(null);

  const handleChange = (name, value, sendCheckStatus = false, checkStatus = '') => {
    let request = {
      candidateId: props?.candidateDetails?.candidateId,
      candidateUuid: props?.candidateDetails?.candidateUuid,
      checkUuid: props?.commentDetails?.checkId,
      checkType: props?.commentDetails?.checkType,
      manualInsufficiencyId: props?.commentDetails?.id,
      [name]: value || null
    };

    if (sendCheckStatus) {
      request['finalCheckStatus'] = checkStatus;
    }

    setLoading(true);
    updateManualInsuffTkt(request)
      .then((response) => {
        props.getInsuffReview(null, props.page, props.offset, true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        errToastMessage(error);
      });
  };

  const handleSubmitAction = () => {
    props.getInsuffReview(null, props.page, props.offset, true);
  };

  const handleCheckStatusModalSubmit = (checkStatus = '') => {
    setShowCheckStatusUpdateModal(false);
    handleChange('status', tempStatus, true, checkStatus);
  };

  // console.log('Step3', props);

  return (
    <>
      <Grid container spacing={2} className={classes.alignItemCenter}>
        <Grid item md={6} xs={6} lg={6}>
          <Typography variant='subtitle2' gutterBottom>
            {props.commentDetails.heading}
          </Typography>
        </Grid>
        <Grid item md={6} xs={6} lg={6}>
          <FormControl variant='outlined' className={classes.formControl} size='small'>
            <InputLabel id='insuffSelectLabel'>Status</InputLabel>
            <Select
              labelId='insuffSelectLabel'
              id='insuffSelectStatus'
              value={props.commentDetails.status}
              onChange={(e) => {
                if (
                  props?.commentDetails?.remainingOpenTickets === 1 &&
                  ['RESOLVED', 'UNRESOLVED'].includes(e.target.value)
                ) {
                  setShowCheckStatusUpdateModal(true);
                  setTempStatus(e.target.value);
                } else {
                  handleChange('status', e.target.value);
                }
              }}
              className={classes.inputHeight}
              label='Status'
              fullWidth={true}
              disabled={loading}
            >
              {INSUFF_STATUSES.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box>
        <Button
          color='primary'
          onClick={() => {
            setDisplayAddInsuff(true);
            setDetails({
              candidateId: ''
              // type: 'public'
            });
          }}
        >
          Add comment
        </Button>
      </Box>

      {props?.commentDetails &&
      props?.commentDetails?.comments &&
      props?.commentDetails?.comments?.unreviewedComments &&
      props?.commentDetails?.comments?.unreviewedComments?.length ? (
        <UnreviewedComments
          tktDetails={{ id: props.commentDetails?.id || null }}
          comments={props.commentDetails.comments.unreviewedComments}
          page={props.page}
          offset={props.offset}
          status={props.commentDetails.status}
          getInsuffReview={props.getInsuffReview}
        />
      ) : null}

      {props?.commentDetails &&
      props?.commentDetails?.comments &&
      props?.commentDetails?.comments?.allComments &&
      props?.commentDetails?.comments?.allComments?.length ? (
        <ReviewedComments
          comments={props.commentDetails.comments.allComments}
          page={props.page}
          offset={props.offset}
          status={props.commentDetails.status}
          getInsuffReview={props.getInsuffReview}
        />
      ) : null}

      {displayAddInsuff ? (
        <AddInsuffComments
          tktDetails={{ id: props.commentDetails?.id || null }}
          open={displayAddInsuff}
          action={handleSubmitAction}
          type={details.type || null}
          details={details}
          isPushToReview={true}
          handleClose={() => {
            // setIsEditComment(false);
            setDisplayAddInsuff(false);
          }}
        />
      ) : null}

      {showCheckStatusUpdateModal ? (
        <CheckStatusUpdateModal
          open={showCheckStatusUpdateModal}
          handleClose={() => {
            setShowCheckStatusUpdateModal(false);
            setTempStatus(null);
          }}
          handleCheckStatusModalSubmit={handleCheckStatusModalSubmit}
        />
      ) : null}
    </>
  );
};
