import * as Yup from 'yup';

Yup.addMethod(Yup.array, 'atMostOne', function (args) {
  const { message, predicate } = args;
  return this.test('atMostOne', message, function (list) {
    // If there are 2+ elements after filtering, we know atMostOne must be false.
    return list.filter(predicate).length >= 1;
  });
});

export default Yup.object().shape({
  comments: Yup.array().atMostOne({
    message: 'Anyone of comments is required',
    predicate: (a, i) => {
      return a['text'];
    }
  })
});
