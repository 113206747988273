import * as Yup from 'yup';

export default Yup.object().shape({
  packageUnits: Yup.number()
    .required('Enter units of package')
    .integer()
    .positive('Value must be positive')
    .typeError('Enter valid units'),
  credits: Yup.number()
    .required('Enter valid Credits')
    .integer()
    .positive('Value must be positive')
    .typeError('Enter valid Credits'),
  totalCredits: Yup.string()
    .required('Enter valid Credits')
    .typeError('Enter valid value')
});
