import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, has } from 'lodash';
import { toast } from 'react-toastify';

import validationSchema from './ApprovalOfCharges.validation';

import {
  setAutoApproveSetting as setAutoApproveSettingAPI,
  getCompanySettings as getCompanySettingsAPI
} from '../../../../api/admin';

import { errToastMessage, getErrorMessage } from '../../../../utils/Utlities';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let propsValue = {};

      if (has(props, 'companyId')) {
        propsValue.companyId = props.companyId;
      } else {
        propsValue = {};
      }
      let storedValues = {
        companyId: new URLSearchParams(document.location.search).get('company_id'),
        additionalChargeSettingType: props.settingsType || 'ALWAYS',
        additionalCharge: props.amount || '0'
      };

      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    validationSchema,
    enableReinitialize: true,
    handleSubmit: (values, { setSubmitting, resetForm, props }) => {
      values.additionalCharge = parseInt(values.additionalCharge);
      setAutoApproveSettingAPI(values)
        .then(() => {
          setSubmitting(false);
          toast.success('Approval of Additional Charges updated successfully');
          getCompanySettingsAPI(values.companyId);
          resetForm();
          props.handleClose();
        })
        .catch((error) => {
          console.error(error);
          errToastMessage(error);
        });
    },
    displayName: 'Update Additional Setting page '
  })
);
