import { isEmpty } from 'lodash';
import moment from 'moment';

import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  getViewCandidate as getViewCandidateAPI,
  invalidateViewCandidates as invalidateViewCandidatesAPI
} from '../../../actions/admin';
import { GENDER } from '../../../core-components/CommonConstants';

const formatData = (data) => {
  return {
    status: data.status,
    empId: data.employeeId,
    companyName: data.company.name,
    name: data.candidate.name,
    gender: data.candidate.gender ? GENDER[data.candidate.gender].text : '-',
    createdAt: moment(data.candidate.createdAt).format('DD MMM YYYY hh:mm A'),
    updatedAt: moment(data.candidate.updatedAt).format('DD MMM YYYY hh:mm A'),
    email: data.candidate.email
  };
};

const mapStateToProps = (state) => {
  return {
    viewCandidateData:
      (!isEmpty(state.viewCandidateData.viewCandidateData) &&
        formatData(state.viewCandidateData.viewCandidateData)) ||
      {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getViewCandidate: (candidateId, companyId) =>
      dispatch(getViewCandidateAPI(candidateId, companyId)),
    invalidateViewCandidates: () => dispatch(invalidateViewCandidatesAPI())
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
