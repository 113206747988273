import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, isEmpty } from 'lodash';
import { toast } from 'react-toastify';

import validationSchema from './CompanyAssignedServices.validation';

import {
  getAssignedServices as getAssignedServicesAPI,
  invalidateAssignedServices
} from '../../../actions/admin';
import { saveCompanyAssignedServices as saveCompanyAssignedServicesAPI } from '../../../api/admin';

import { errToastMessage, getErrorMessage, toSnakeCase } from '../../../utils/Utlities';

const mapStateToProps = (state) => {
  return {
    assignedServices: (!isEmpty(state.assignedServices) && state.assignedServices) || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAssignedServices: (companyId) => dispatch(getAssignedServicesAPI(companyId)),
    invalidateAssignedServices: () => dispatch(invalidateAssignedServices())
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        companyId: new URLSearchParams(document.location.search).get('id') || null,
        companyUuid: null,
        assignedServices: '',
        packageText: '',
        packageQuantity: '',
        packageType: '',
        creditPeriod: '',
        walletBalance: '',
        priceOfPackage: '',
        model: ''
      };
      let propsValue = {};
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (values, { setFieldError, setStatus, setSubmitting, resetForm, props }) => {
      const finalData = {
        companyId: values.companyId,
        services: values.assignedServices,
        packageText: values.packageText,
        companyUuid: values.companyUuid,
        packageQuantity:
          values.packageQuantity || values.packageQuantity === 0 || values.packageQuantity === '0'
            ? parseInt(values.packageQuantity)
            : null,
        packageType: values.packageType || null,
        creditPeriod:
          values.creditPeriod || values.creditPeriod === 0 || values.creditPeriod === '0'
            ? parseInt(values.creditPeriod)
            : null,
        walletBalance:
          values.walletBalance || values.walletBalance === 0 || values.walletBalance === '0'
            ? parseInt(values.walletBalance)
            : null,
        priceOfPackage:
          values.priceOfPackage || values.priceOfPackage === 0 || values.priceOfPackage === '0'
            ? parseInt(values.priceOfPackage)
            : null,
        model: values.model || null
      };
      saveCompanyAssignedServicesAPI(finalData)
        .then((response) => {
          setSubmitting(false);
          toast.info('Services updated successfully');
          props.history.push({
            pathname: '/company/view',
            search: `id=${values.companyId}`
          });
        })
        .catch((error) => {
          setSubmitting(false);
          console.error(error);
          errToastMessage(error);
        });
    },
    displayName: 'Assign Company Services'
  })
);
