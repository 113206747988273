import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, isEmpty, has } from 'lodash';
import { toast } from 'react-toastify';
import validationSchema from './CreateAdmin.validation';

import {
  createAdmin as createAdminAPI,
  updateAdmin as updateAdminAPI
} from '../../../../api/admin';
import { getAdmins as getAdminsAPI } from '../../../../actions/admin';

import {
  errToastMessage,
  getErrorMessage,
  getIntlPhoneNumberObject
} from '../../../../utils/Utlities';
import { removeAuthToken, removeCookie } from '../../../../api/storage';
import AppConstants from '../../../../core-components/AppConstants';

const mapStateToProps = (state) => {
  return {
    profile: state.profile && !isEmpty(state.profile) ? state.profile : null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAdmins: (limit, offset, includeCompanyAdmins, name, email, accessLevel, status) =>
      dispatch(getAdminsAPI(limit, offset, includeCompanyAdmins, name, email, accessLevel, status))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let propsValue = {};
      let supervisorEnabled = false;
      let telephonyEnabaled = false;
      let isEscalate,
        isCs,
        isEmp,
        isAdd,
        isRef,
        isEdu = false;
      if (has(props.details, 'name')) {
        for (let i = 0; i < props?.details?.authAssignment?.userAccessRights?.length; i++) {
          const { right } = props?.details?.authAssignment?.userAccessRights[i];
          if (right === 'SUPERVISOR') {
            supervisorEnabled = true;
          }
          if (right === 'TELEPHONY') {
            telephonyEnabaled = true;
          }
          if (right === 'PHONE_ESCALATION') {
            isEscalate = true;
          }
          if (right === 'PHONE_CUSTOMER_SUPPORT') {
            isCs = true;
          }
          if (right === 'PHONE_ADDRESS') {
            isAdd = true;
          }
          if (right === 'PHONE_EMPLOYMENT') {
            isEmp = true;
          }
          if (right === 'PHONE_REFERENCE') {
            isRef = true;
          }
          if (right === 'PHONE_EDUCATION') {
            isEdu = true;
          }
        }
        propsValue = props.details;
        propsValue['supervisorMode'] = supervisorEnabled;
        propsValue['isTelephonyEnabled'] = telephonyEnabaled;
        propsValue['isEscalate'] = isEscalate;
        propsValue['isCs'] = isCs;
        propsValue['isAdd'] = isAdd;
        propsValue['isEmp'] = isEmp;
        propsValue['isRef'] = isRef;
        propsValue['isEdu'] = isEdu;

        if (props?.details?.mobile?.length > 10) {
          let phoneNumberObj = getIntlPhoneNumberObject(props?.details?.mobile);
          if (phoneNumberObj) {
            propsValue.mobile = phoneNumberObj.nationalNumber;
            propsValue.countryCode = `+${phoneNumberObj.countryCallingCode}`;
          }
        } else {
          propsValue.mobile = props?.details?.mobile;
          propsValue.countryCode = props?.details?.countryCode;
        }
      } else {
        propsValue = {};
      }
      let supervisor = false;
      props.profile?.userAccessRights?.forEach((element) => {
        if (element.right === 'SUPERVISOR') {
          supervisor = true;
        }
      });
      let storedValues = {
        name: '',
        email: '',
        mobile: '',
        countryCode: '+91',
        isValidPhone: true,
        accessLevel: 5,
        supervisorMode: false,
        isSupervisorModeEnabled: supervisor,
        isTelephonyEnabled: false,
        telephony: '',
        isEscalate: false,
        isCs: false,
        isAdd: false,
        isEmp: false,
        isRef: false,
        isEdu: false,
        accessRights: []
      };

      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    handleSubmit: (values, { setFieldError, setStatus, setSubmitting, resetForm, props }) => {
      let accessRights = [];
      values.email = values.email.toLowerCase(); // Force lowercase for email in add/edit users
      if (values) {
        if (values.isEscalate) {
          accessRights.push('PHONE_ESCALATION');
        }
        if (values.isCs) {
          accessRights.push('PHONE_CUSTOMER_SUPPORT');
        }
        if (values.isAdd) {
          accessRights.push('PHONE_ADDRESS');
        }
        if (values.isEmp) {
          accessRights.push('PHONE_EMPLOYMENT');
        }
        if (values.isRef) {
          accessRights.push('PHONE_REFERENCE');
        }
        if (values.isEdu) {
          accessRights.push('PHONE_EDUCATION');
        }
      }

      values.accessRights = accessRights;
      values.isEscalate = undefined;
      values.isCs = undefined;
      values.isAdd = undefined;
      values.isEmp = undefined;
      values.isRef = undefined;
      values.isEdu = undefined;
      values.telephony = values?.telephony || undefined;

      let isAdminLoggedIn = props?.adminId === props?.profile?.id;
      if (props.title === 'Edit') {
        let finalValue = {
          adminId: props?.details?.id,
          name: values.name,
          email: values.email,
          mobile: values?.mobile ? values?.countryCode + values?.mobile : values?.mobile,
          accessLevel: values.accessLevel,
          supervisorMode: values.supervisorMode,
          isSupervisorModeEnabled: values.isSupervisorModeEnabled,
          isTelephonyEnabled: values.isTelephonyEnabled,
          telephony: values.telephony,
          accessRights: accessRights
        };
        updateAdminAPI(finalValue)
          .then(() => {
            setSubmitting(false);
            toast.info('Admin updated successfully');
            props.handleClose();
            if (isAdminLoggedIn) {
              removeAuthToken();
              removeCookie('isExpirySet');
              localStorage.clear();
              toast.info(
                '"Maybe your permissions are updated. Logging you out to reflect the new changes"'
              );
              setTimeout(() => {
                window.location.href = `${AppConstants.baseURL}login`;
              }, 2500);
            } else {
              props.getAdmins(props.rowsPerPage, props.offset, false);
              resetForm();
            }
          })
          .catch((error) => {
            console.error(error);
            errToastMessage(error);
            setSubmitting(false);
          });
      } else {
        let finalValues = {};
        if (values?.mobile) {
          let mobileValue = values?.countryCode + values?.mobile;
          finalValues = { ...values, mobile: mobileValue };
        } else {
          finalValues = { ...values, mobile: '' };
        }
        createAdminAPI(finalValues)
          .then(() => {
            setSubmitting(false);
            toast.info('Admin created successfully');
            props.getAdmins(props.rowsPerPage, props.offset, false);
            resetForm();
            props.handleClose();
          })
          .catch((error) => {
            setSubmitting(false);
            console.error(error);
            errToastMessage(error);
          });
      }
    },
    displayName: 'Create Admins'
  })
);
