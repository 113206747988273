import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { has } from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography, Box, Grid, CircularProgress } from '@material-ui/core';

import BreadCrumbs from '../../../../core-components/BreadCrumbs';
import AppConstants from '../../../../core-components/AppConstants';

import PrepaidBalances from '../Prepaid/Balances';
import PrepaidRechargeLog from '../Prepaid/RechargeLog';
import PrepaidUsageLog from '../Prepaid/UsageLog';

import PostpaidBalances from '../Postpaid/Balances';
import MonthlyUsageLog from '../Postpaid/MonthlyUsageLog';
import PostpaidUsageLog from '../Postpaid/UsageLog';
import AuditLog from '../Postpaid/AuditLog';

import { getToken } from './../../../../api/storage';
import PendingOrders from '../Prepaid/PendingOrders';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={index} aria-labelledby={index} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  tab: {
    width: '180px',
    marginRight: '16px',
    backgroundColor: '#ebecf1',
    borderRadius: '5px 5px 0 0'
  },
  tabPanel: {
    minHeight: '500px',
    border: '1px solid rgba(0, 0, 0, 0.05)',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.10)'
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '30vh',
    alignItems: 'center'
  }
}));

export default function CreditCompany(props) {
  const classes = useStyles(),
    companyId = new URLSearchParams(document.location.search).get('id') || null,
    selectedTab = new URLSearchParams(document.location.search).get('tab') || null,
    packageType = new URLSearchParams(document.location.search).get('type') || null;
  const [companyName, setCompanyName] = useState('');
  const [balance, setBalance] = useState(0);
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [pendingOrderCount, setPendingOrderCount] = useState(0);
  const breadCrumbsArray = [
    { label: 'Home', href: `${AppConstants.baseURL}dashboard` }, //credits/list,
    { label: 'Companies', href: `${AppConstants.baseURL}companies` },
    { label: companyName || '', href: `${AppConstants.baseURL}company/view?id=${companyId}` }
  ];

  const [optionPrepaidTabs, setOptionPrepaidTabs] = useState([
    'Balances',
    'Recharge Log',
    'Usage Log',
    `Pending Orders (${pendingOrderCount})`
  ]);
  const optionPostpaidTabs = ['Balances', 'Monthly Usage', 'Usage Log', 'Audit Log'];
  useEffect(() => {
    if (selectedTab === 'pending_orders') {
      setValue(3);
    }
    if (packageType === 'PREPAID') {
      props.getCompanyCreditBalance({ companyId: companyId, onlyNegative: false });
      props.getCompanyPendingOrders({ company_id: companyId, limit: 10, offset: 0 }, true);
    } else {
      props.getPostpaidCreditCompany(companyId);
    }

    return () => {
      setCompanyName('');
      props.invalidateCreditBalanceAPI();
      props.invalidatePostpaidCreditCompanyAPI();
    };
  }, []);

  useEffect(() => {
    if (props?.companyCreditBalance && props?.companyCreditBalance?.credits) {
      setLoading(false);
      setCompanyName(
        props?.companyCreditBalance?.credits?.company
          ? props.companyCreditBalance.credits.company.name
          : ''
      );
      setBalance(props.companyCreditBalance.credits.balance);
    } else {
      setCompanyName('');
    }
  }, [props.companyCreditBalance]);

  useEffect(() => {
    if (
      props?.postpaidCreditCompany &&
      has(props.postpaidCreditCompany, 'loading') &&
      !props?.postpaidCreditCompany?.loading
    ) {
      setLoading(false);
      setCompanyName(
        props.postpaidCreditCompany.companyDetails
          ? props.postpaidCreditCompany.companyDetails.name
          : ''
      );
    } else {
      setCompanyName('');
    }
  }, [props.postpaidCreditCompany]);

  useEffect(() => {
    if (
      props.companyPendingOrders &&
      !props.companyPendingOrders.loading &&
      props.companyPendingOrders.paymentOrders
    ) {
      const tabName = `Pending Orders (${props.companyPendingOrders.pendingOrdersCount})`;
      const updatedPrepaidTab = [...optionPrepaidTabs];
      updatedPrepaidTab[3] = tabName;
      setOptionPrepaidTabs(updatedPrepaidTab);
    }
  }, [props.companyPendingOrders]);

  const handleChange = (event, newValue) => {
    if (newValue == 0 && packageType === 'PREPAID') {
      //Fetching fresh response whenever we open the Credit Balance Tab
      props.getCompanyCreditBalance({ companyId: companyId, onlyNegative: false });
    }
    setValue(newValue);
  };
  const renderPrepaidTabContent = (index) => {
    switch (index) {
      case 0:
        return (
          <PrepaidBalances
            credits={balance}
            companyId={companyId}
            profile={props.profile}
            companyCreditBalance={props?.companyCreditBalance}
          />
        );
      case 1:
        return <PrepaidRechargeLog companyId={companyId} />;
      case 2:
        return <PrepaidUsageLog companyId={companyId} profile={props.profile} />;
      case 3:
        return <PendingOrders companyId={companyId} />;
      default:
        return (
          <PrepaidBalances
            credits={balance}
            companyId={companyId}
            profile={props.profile}
            getCompanyCreditBalance={props.getCompanyCreditBalance}
          />
        );
    }
  };

  const renderPostpaidTabContent = (index) => {
    switch (index) {
      case 0:
        return (
          <PostpaidBalances
            credits={props.postpaidCreditCompany}
            companyId={companyId}
            profile={props.profile}
          />
        );
      case 1:
        return <MonthlyUsageLog companyId={companyId} profile={props.profile} />;
      case 2:
        return <PostpaidUsageLog companyId={companyId} profile={props.profile} />;
      case 3:
        return <AuditLog companyId={companyId} profile={props.profile} />;
      default:
        return (
          <PostpaidBalances
            credits={props.postpaidCreditCompany}
            companyId={companyId}
            profile={props.profile}
            balance={balance}
          />
        );
    }
  };
  return (
    <>
      <BreadCrumbs linksArray={breadCrumbsArray} current={'Credit Balance'} />
      {loading ||
      (packageType === 'PREPAID'
        ? props?.companyCreditBalance?.loading
        : props?.postpaidCreditCompany?.loading) ? (
        <Grid container spacing={2} className={classes.spinnerContainer}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container className={classes.root} spacing={2}>
          <Grid item md={12}>
            <Typography variant='h4' gutterBottom>
              {'Credit Balance'}
            </Typography>
            <Typography variant='h5' gutterBottom>
              {companyName}
            </Typography>
          </Grid>
          <br />
          <Grid item md={12}>
            {packageType === 'PREPAID' ? (
              <>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor='primary'
                  textColor='primary'
                  variant='scrollable'
                  scrollButtons='auto'
                  aria-label='scrollable auto tabs example'
                >
                  {optionPrepaidTabs.map((label, index) => {
                    return (
                      <Tab
                        className={classes.tab}
                        label={label}
                        key={index}
                        id={index}
                        aria-controls={index}
                      />
                    );
                  })}
                </Tabs>
                {optionPrepaidTabs.map((label, index) => {
                  return (
                    <TabPanel value={value} index={index} key={label} className={classes.tabPanel}>
                      {renderPrepaidTabContent(index)}
                    </TabPanel>
                  );
                })}
              </>
            ) : (
              <>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor='primary'
                  textColor='primary'
                  variant='scrollable'
                  scrollButtons='auto'
                  aria-label='scrollable auto tabs example'
                >
                  {optionPostpaidTabs.map((label, index) => {
                    return (
                      <Tab
                        className={classes.tab}
                        label={label}
                        key={index}
                        id={index}
                        aria-controls={index}
                      />
                    );
                  })}
                </Tabs>
                {optionPostpaidTabs.map((label, index) => {
                  return (
                    <TabPanel value={value} index={index} key={label} className={classes.tabPanel}>
                      {renderPostpaidTabContent(index)}
                    </TabPanel>
                  );
                })}
              </>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}
