import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import AppHelper from '../../../../core-components/AppHelper';
import AppConstants from '../../../../core-components/AppConstants';
import { covertObjToQueryString } from '../../../../utils/Utlities';
import secureLocalStorage from 'react-secure-storage';
import { isEmpty } from 'lodash';
import ThreeDots from '../../../../core-components/ThreeDotLoader';

const useStyles = makeStyles((theme) => ({
  iframe: {
    width: '100%',
    height: 'calc(100vh - 200px)'
  },
  modalBody: {
    padding: '0 !important',
    border: '0 !important',
    marginBottom: '-7px'
  },
  header: {
    position: 'absolute',
    top: '5px !important',
    right: '-10px !important',
    zIndex: 1000
  },
  loadingContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '100px'
  }
}));

const EmailPreferencesModal = ({ open, handleOnClose, companyId, editEmailPrefData }) => {
  const styles = useStyles();
  const localPath = 'http://localhost:3003/v1/sa/email-preferences';
  const baseUrl = AppConstants.baseURL + 'v1/email-preferences';
  const [params, setParams] = useState('');
  const [loading, setLoading] = useState(true);
  const newAdminData = secureLocalStorage.getItem('NEW_USER');
  useEffect(() => {
    if (editEmailPrefData) {
      const queryString = covertObjToQueryString(editEmailPrefData);
      setParams(queryString ? '&' + queryString : '');
    }
  }, [editEmailPrefData]);

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <Modal className='custom-dialog' centered show={open} onHide={handleOnClose}>
      <Modal.Header className={styles.header} closeButton></Modal.Header>
      <Modal.Body className={styles.modalBody}>
        {loading && (
          <div className={styles.loadingContainer}>
            <ThreeDots style={{ textAlign: 'center' }} />
          </div>
        )}
        <iframe
          onLoad={handleLoad}
          className={styles.iframe}
          src={
            AppHelper.getEnv() === 'local'
              ? `${localPath}?companyId=${companyId}${params}${
                  isEmpty(editEmailPrefData) ? '&role=' + newAdminData?.userType : ''
                }`
              : `${baseUrl}?companyId=${companyId}${params}${
                  isEmpty(editEmailPrefData) ? '&role=' + newAdminData?.userType : ''
                }`
          }
        ></iframe>
      </Modal.Body>
    </Modal>
  );
};

export default EmailPreferencesModal;
