import * as Yup from 'yup';
import { getPackageOrSubtypeNameValidationSchema } from '../../../../utils/ValidationSchema';

const customNumberSchema = ({ min, max, type }) => {
  return Yup.number()
    .integer('Please enter a valid count')
    .min(min, 'Please enter a valid count')
    .max(max, `Only ${max} ${type} can be added`);
};

export default Yup.object().shape({
  subtypeName: getPackageOrSubtypeNameValidationSchema({ type: 'subtype', isRequired: true }),
  basePrice: Yup.string()
    .matches(/^[^-\s][a-zA-Z0-9_,\s-]+$/, 'Please enter a Base price greater than or equal to 10')
    .required('Please enter a valid Base price'),
  identity: customNumberSchema({ min: 0, max: 5, type: 'IDs' }),
  address: customNumberSchema({ min: 0, max: 2, type: 'Address' }),
  court: customNumberSchema({ min: 0, max: 2, type: 'Court' }),
  employment: customNumberSchema({ min: 0, max: 10, type: 'Employment' }),
  educationCheck: customNumberSchema({ min: 0, max: 5, type: 'Education' }),
  reference: customNumberSchema({ min: 0, max: 10, type: 'Reference' })
});
