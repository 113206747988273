import {
  FormControlLabel,
  Grid,
  makeStyles,
  Checkbox,
  Button,
  CircularProgress
} from '@material-ui/core';

import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { getCompanyAdmin, postEmailPreferences } from '../../../api/admin';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../frontend-common/core-components/Utilities/utilities';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => ({
  cancelButton: {
    backgroundColor: 'white',
    borderColor: 'blue'
  },
  header: {
    height: '35px !important'
  },
  body: {
    paddingTop: '0px !important'
  },
  saveButton: {
    backgroundColor: 'blue'
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '10px',
    paddingBottom: '10px',
    maxHeight: '300px', // set a maximum height
    overflowY: 'auto'
  },
  listItem: {
    height: '25px !important'
  },
  modalHeader: {
    fontSize: '14px',
    fontWeight: '700'
  },
  adminCheckName: {
    fontSize: '13px',
    fontWeight: '500'
  },
  button: {
    cursor: 'pointer',
    fontSize: '12px',
    paddingRight: '14px',
    '&:hover': {
      color: 'blue',
      underline: 'hover'
    }
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px'
  }
}));

const InsuffEmailsCompanyAdminSelectionModal = (props) => {
  const {
    show,
    hide,
    companyId,
    setIsAtleastOneAdminSelectedForInsuffEmails,
    totalCountOfAdmins,
    setFieldValue
  } = props;
  const [selectedAdminIds, setSelectedAdminIds] = useState([]);
  const [adminsList, setAdminsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    getCompanyAdmins(companyId, totalCountOfAdmins, offset);
  }, []);

  const getCompanyAdmins = (companyId, limit, offset) => {
    setLoading(true);
    getCompanyAdmin(companyId, limit, offset)
      .then((response) => {
        setLoading(false);
        const data = response?.data?.data;
        const admins = data?.admins?.filter((admin) => admin?.access_level == 5);
        setAdminsList(admins);
      })
      .catch((error) => {
        toast.error(getErrorMessage(error));
        setLoading(false);
      });
  };

  const onSave = () => {
    const payload = {
      email_types: {
        DIGEST_INSUFFICIENCY: { is_checked: true, user_ids: selectedAdminIds }
      }
    };

    setIsAtleastOneAdminSelectedForInsuffEmails(true);
    postEmailPreferences(companyId, payload);
    hide();
  };

  const isValidForm = !isEmpty(selectedAdminIds);

  const onClose = () => {
    setFieldValue('reminderViaEmail', 'CANDIDATE');
    setFieldValue('isEmailOptional', 0);

    hide();
  };
  return (
    <Modal size='md' onHide={onClose} show={show} centered>
      <Modal.Header className={classes.header} closeButton></Modal.Header>
      <Modal.Body className={classes.body}>
        <Grid container>
          <div className={classes.modalHeader}>
            To change from “Candidate Only” to “CA Only” configuration, please select the CA Admin
            for whom insuff emails are required to be enabled (at least 1 is mandatory)*
          </div>
          <Grid lg={12} md={12} sm={12} className={classes.listContainer} item>
            {loading ? (
              <CircularProgress />
            ) : adminsList?.length ? (
              adminsList.map((admin, index) => {
                return (
                  <FormControlLabel
                    key={`ADMIN_U_${index}`}
                    className={classes.listItem}
                    control={
                      <Checkbox
                        value={admin?.user?.id}
                        color='primary'
                        checked={selectedAdminIds.includes(admin?.user?.id)}
                        onChange={(e) => {
                          const id = parseInt(e.target.value);
                          if (e.target.checked) {
                            setSelectedAdminIds((prevIds) => [...prevIds, id]);
                          } else {
                            setSelectedAdminIds((prevIds) =>
                              prevIds.filter((prevId) => prevId !== id)
                            );
                          }
                        }}
                      />
                    }
                    label={admin?.user?.name}
                  />
                );
              })
            ) : (
              <div>No Admins found</div>
            )}
          </Grid>
        </Grid>
      </Modal.Body>
      <Modal.Footer>
        <Grid className={classes.footer}>
          <Button variant='outlined' color='primary' onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={!isValidForm} variant='contained' color='primary' onClick={onSave}>
            Save
          </Button>
        </Grid>
      </Modal.Footer>
    </Modal>
  );
};

export default InsuffEmailsCompanyAdminSelectionModal;
