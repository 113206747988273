import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, FormControl, Button, InputLabel, MenuItem, Select } from '@material-ui/core';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import DocListings from './AgreementDocs';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginBottom: theme.spacing(2),
    },
    padding: {
        padding: theme.spacing(2),
    },
    documentPaper: {
        // padding: theme.spacing(4),
        margin: theme.spacing(2),
        display: 'flex',
    },
}));

export default (props) => {
    const classes = useStyles();
    const STATUSES = [
        { label: 'EMPTY', value: 'empty' },
        { label: 'DRAFT REVIEW', value: 'draft_review' },
        { label: 'SENT TO CLIENT', value: 'sent_to_client' },
        { label: 'LEGAL REVIEW', value: 'legal_review' },
        { label: 'INSUFFICIENT', value: 'insufficient' },
        { label: 'EXECUTED', value: 'executed' },
    ];

    return (
        <Paper
            key={`agreement_container_${props.currentIndex}`}
            className={classes.documentPaper}
            variant='outlined'
            elevation={3}
        >
            <Grid container>
                <Grid container>
                    <Grid item xl={3} md={3} lg={3} sm={3} className={classes.padding}>
                        <FormControl className={classes.formControl} style={{ width: '100%' }}>
                            <InputLabel id='status-type'>Status</InputLabel>
                            <Select
                                labelId='status-type-label'
                                value={props.agreementData.status}
                                onChange={(e) => {
                                    props.agreementChanges(e.target.value, 'status', props.currentIndex);
                                }}
                                displayEmpty
                            >
                                {STATUSES.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xl={3} md={3} lg={3} sm={3} className={classes.padding}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                label='Excecution date'
                                format='DD MMM YYYY'
                                error={
                                    props.agreementData.errorMsg &&
                                        (props.agreementData.errorMsg ===
                                            'Execution Date must be lesser than Expiry Date' ||
                                            props.agreementData.errorMsg === 'Execution Date is required with Expiry Date')
                                        ? true
                                        : false
                                }
                                helperText={
                                    props.agreementData.errorMsg &&
                                        (props.agreementData.errorMsg ===
                                            'Execution Date must be lesser than Expiry Date' ||
                                            props.agreementData.errorMsg === 'Execution Date is required with Expiry Date')
                                        ? props.agreementData.errorMsg
                                        : ''
                                }
                                value={props.agreementData.executionDate || null}
                                onChange={(e) => {
                                    props.agreementChanges(e, 'executionDate', props.currentIndex);
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xl={3} md={3} lg={3} sm={3} className={classes.padding}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                label='Expiry Date'
                                format='DD MMM YYYY'
                                error={
                                    props.agreementData.errorMsg &&
                                        (props.agreementData.errorMsg ===
                                            'Execution Date must be lesser than Expiry Date' ||
                                            props.agreementData.errorMsg === 'Expiry Date is required with Execution Date')
                                        ? true
                                        : false
                                }
                                helperText={
                                    props.agreementData.errorMsg &&
                                        props.agreementData.errorMsg === 'Expiry Date is required with Execution Date'
                                        ? props.agreementData.errorMsg
                                        : ''
                                }
                                value={props.agreementData.expiryDate || null}
                                onChange={(e) => {
                                    props.agreementChanges(e, 'expiryDate', props.currentIndex);
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xl={3} md={3} lg={3} sm={3} className={classes.padding}>
                        <Button
                            color='primary'
                            onClick={() => props.addDoc(props.currentIndex)}
                            startIcon={<NoteAddIcon />}
                        >
                            Add Document
                        </Button>
                    </Grid>
                </Grid>
                <Grid item container>
                    {props?.agreementData?.companyAgreementDocuments &&
                        props?.agreementData?.companyAgreementDocuments?.length
                        ? props?.agreementData?.companyAgreementDocuments.map((document, key) => {
                            return (
                                <DocListings
                                    agreementIndex={props.currentIndex}
                                    docIndex={key}
                                    documentData={document}
                                    documentsChange={props.documentsChange}
                                    key={`document_container_${props.currentIndex}_${key}`}
                                />
                            );
                        })
                        : null}
                </Grid>
            </Grid>
        </Paper>
    );
};
