import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import BreadCrumbs from '../../../core-components/BreadCrumbs';
import AppConstants from '../../../core-components/AppConstants';
import ThreeDots from '../../../core-components/ThreeDotLoader';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: '100%'
  },
  loadingContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '100px'
  },
  iframe: {
    width: '100%',
    height: 'calc(100vh - 300px)'
  },
  pageTitle: {
    margin: '15px 0',
    fontSize: '24px'
  }
}));

const InsufficiencyComments = () => {
  const classes = useStyles();

  const breadCrumbsArray = [{ label: 'Home', href: `${AppConstants.baseURL}dashboard` }];
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <>
      <BreadCrumbs linksArray={breadCrumbsArray} current={'Insufficiency Comments'} />
      <Typography variant='h4' className={classes.pageTitle} component='h3'>
        Insufficiency Comments
      </Typography>

      {loading && (
        <div className={classes.loadingContainer}>
          <ThreeDots style={{ textAlign: 'center' }} />
        </div>
      )}

      <iframe
        onLoad={handleLoad}
        className={classes.iframe}
        src={AppConstants.appSmithInsuffComments}
      ></iframe>
    </>
  );
};

export default InsufficiencyComments;
