export const getTotalCount = (ref = 0) => {
  if (typeof ref === 'number') {
    return ref;
  } else {
    return ref.reduce((acc, item) => {
      return (acc = acc + item);
    }, 0);
  }
};

export const getCountArr = (selectedPackage = {}) => {
  let arr = [];
  // selectedPackage.config.reference = [2, 3];

  if (typeof selectedPackage.config.reference === 'number') {
    arr = new Array(selectedPackage.config.employment).fill(0);
    arr[0] = selectedPackage.config.reference;
  } else {
    arr = [...selectedPackage.config.reference];
  }
  return arr;
};

// Fetching no. of any
export const getIdentityAnyCounter = (identity = []) => {
  let count = 0;
  const str = identity.find((item) => item.includes('ANY_'));
  count = str && str.substr(str.length - 1);
  return count ? parseInt(count) : 0;
};

export const getAddressAnyCounter = (address = []) => {
  let count = 0;
  const str = address.map(add => add.type).find((item) => item.includes('ANY_'));
  count = str && str.substr(str.length - 1);
  return count ? parseInt(count) : 0;
};
export const getTotalCheckCount = (checks = []) => {
  let total = checks.length;
  let anyCount = getIdentityAnyCounter(checks);
  if (anyCount) {
    total = total + anyCount - 1;
  }
  return parseInt(total);
};
