import * as Yup from 'yup';
import {
  getGSTValidationSchema,
  getNameValidationSchema,
  getBrandNameValidationSchema,
  getLinkedinUrlValidation
} from '../../../utils/ValidationSchema';

var urlPattern = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i'
); // fragment locator
export default Yup.object().shape({
  name: getNameValidationSchema({ message: 'Company name', isRequired: true }),
  friendlyName: getBrandNameValidationSchema({isNullable: true, isRequired: false}),
  companyUrl: Yup.string()
    .lowercase()
    .trim()
    .matches(urlPattern, {
      message: 'Enter valid website.',
      excludeEmptyString: false
    })
    .required('Company website cannot be blank'),
  linkedInUrl: getLinkedinUrlValidation({isRequired: false, isNullable: true}),
  discloseInfo: Yup.string().required('Please select Disclose Client Name for Work Experience'),
  gstin: getGSTValidationSchema(),
  hrNote: Yup.string()
    .trim()
    .required(`HR Note is Required`)
    .min(10, 'Must be more than 10 characters')
    .max(160, 'Must be less than of 160 characters'),
  hrWarningNote: Yup.string()
    .trim()
    .required('HR Warning Note is Required')
    .min(10, 'Must be more than 10 characters ')
    .max(160, 'Must be less than of 160 characters')
});
