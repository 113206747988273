// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kEIlvfDwXWbpbk8NQxCL {\n  padding: 20px;\n}\n\n.a2TVmlRCSafLJWGmeMxh {\n  display: flex;\n  padding-top: 20px;\n  flex-direction: column;\n  gap: 20px;\n}\n\n.nIzwwmsqi6APlvdhO2lL {\n  font-weight: 600;\n  font-size: 18px;\n  line-height: 26px;\n}\n\n.t7WK11E6zlieAHdkytKD {\n  font-family: \"Poppins\";\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  color: #464457;\n}\n\n.JvzdKuZeZVhSwR4DSK5J {\n  display: flex;\n}\n\n.Kexwlsmcx4idMcHerPC4 {\n  height: 30px;\n  width: 25px;\n  margin-right: 8px;\n  margin-top: -6px;\n}\n\n.nnl8cYnRRy9zBC0TeEGP {\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 16px;\n  color: #333333;\n}\n\n.gDmJlpNY6_9aS_C7bVyf {\n  color: #666666;\n  font-weight: normal;\n  font-size: \"13px\";\n}\n\n.YuN7yKUK9_9YGMsKVOJb {\n  height: 100%;\n  width: 30%;\n  display: flex;\n  justify-content: flex-end;\n}\n\n.eaCJf60R21Keyuq9bPRr {\n  color: #595959;\n  width: 111px;\n  height: 29px;\n  border-radius: 20px;\n  padding: 4px 8px;\n  display: flex;\n  align-items: center;\n  background-color: rgba(250, 173, 20, 0.5019607843);\n  gap: 10px;\n  font-family: Poppins;\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 21px;\n  letter-spacing: 0em;\n  text-align: left;\n}\n\n.VyNEYPGewCfG9f5y1_GT {\n  font-family: Poppins;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 21px;\n  letter-spacing: 0em;\n  text-align: left;\n}\n\n.br9CoKlu4PcUWhKlhtYt {\n  font-family: Poppins;\n  font-size: 14px;\n  font-weight: normal !important;\n  line-height: 21px;\n  letter-spacing: 0em;\n  text-align: left;\n}\n.br9CoKlu4PcUWhKlhtYt li {\n  font-weight: normal !important;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "kEIlvfDwXWbpbk8NQxCL",
	"cardContainer": "a2TVmlRCSafLJWGmeMxh",
	"containerHeader": "nIzwwmsqi6APlvdhO2lL",
	"cardHeadText": "t7WK11E6zlieAHdkytKD",
	"whatsAppContainer": "JvzdKuZeZVhSwR4DSK5J",
	"imageStyle": "Kexwlsmcx4idMcHerPC4",
	"cardSubtext": "nnl8cYnRRy9zBC0TeEGP",
	"cardSecondarySubtext": "gDmJlpNY6_9aS_C7bVyf",
	"comingSoonBanner": "YuN7yKUK9_9YGMsKVOJb",
	"comingSoonText": "eaCJf60R21Keyuq9bPRr",
	"cardText": "VyNEYPGewCfG9f5y1_GT",
	"listText": "br9CoKlu4PcUWhKlhtYt"
};
export default ___CSS_LOADER_EXPORT___;
