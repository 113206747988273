import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper, FormControlLabel, Checkbox, Badge } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';

import AddIcon from '@material-ui/icons/Add';

import { PLAIN_CHECK_ICONS } from '../../../../../../core-components/CommonConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '16px',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  paper: {
    padding: '0px 16px',
    minHeight: '48px'
  },
  headingGrid: {
    alignItems: 'center',
    display: 'flex'
  },
  checkBoxGrid: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  priceText: {
    fontFamily: 'roboto',
    color: '#333333',
    fontSize: '14px'
  },
  formControl: {
    width: '100%',
    paddingRight: '1rem'
  }
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#389E0D',
    padding: '0px 0px',
    height: '16px',
    minWidth: '16px'
  }
}))(Badge);
/**
 * onValueChange: Func to save the values to parent state
 * values: Addons values as they changes
 */
export default (props) => {
  const classes = useStyles();

  //   Refer DocBlock above for detailed variable usage
  const { onValueChange, value, price, type, heading } = props;

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={2}>
          <Grid container spacing={1}>
            <Grid
              item
              container
              xl={10}
              md={10}
              lg={10}
              sm={10}
              xs={10}
              className={classes.headingGrid}
            >
              <Grid item lg={1} md={1} sm={1} xs={1}>
                <StyledBadge
                  color='secondary'
                  badgeContent={<AddIcon style={{ fontSize: '10px' }} />}
                >
                  <span title={type}>
                    <FontAwesomeIcon
                      icon={PLAIN_CHECK_ICONS[type] ? PLAIN_CHECK_ICONS[type].icon : faBan}
                      color='rgba(39, 85, 254, 0.5)'
                      size='lg'
                    />
                  </span>
                </StyledBadge>
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={8}>
                <Typography variant='subtitle2' gutterBottom>
                  {heading}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xl={2} md={2} lg={2} sm={2} xs={2} className={classes.checkBoxGrid} justify='flex-end'>
              <FormControlLabel
                className={classes.marginLeft}
                value={value}
                className={classes.formControl}
                control={
                  <Checkbox
                    color='primary'
                    onChange={(e) => {
                      onValueChange(e.target.checked);
                    }}
                  />
                }

                label={<Typography className={classes.priceText}>{`₹${price}`}</Typography>}
                labelPlacement='start'
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
};
