import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { isEmpty } from 'lodash';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, Button, Grid } from '@material-ui/core';
import FormInput from '../../../../core-components/FormInput';
import { Field } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import SaveIcon from '@material-ui/icons/Save';

// import Button from './Button';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    activeBtn: {
        marginTop: theme.spacing(2),
        width: '150px',
        color: '#ffffff',
        borderColor: '#388e3c',
        backgroundColor: '#43a047',
        fontSize: 14,
        lineHeight: 1,
        marginRight: 2,
        textTransform: 'inherit',
        '&:hover': {
            backgroundColor: '#388e3c',
        },
    },
    disabledBtn: {
        marginTop: theme.spacing(2),
        width: '150px',
        color: '#dfdfdf',
        borderColor: '#d9d9d9',
        backgroundColor: '#f5f5f5',
        fontSize: 14,
        lineHeight: 1,
        marginRight: 2,
        textTransform: 'inherit',
        '&:hover': {
            backgroundColor: '#f5f5f5',
        },
    },
}));

const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    const classes = useStyles();
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant='h5'>{children}</Typography>
            {onClose ? (
                <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
}))(MuiDialogActions);

export default function CreateVendor(props) {
    const classes = useStyles();
    const {
        handleSubmit,
        isSubmitting,
        handleBlur,
        values,
        errors,
        setFieldValue,
        setFieldError,
        setTouched,
        touched,
        setFieldTouched,
        setErrors,
    } = props;
    const isValidForm = !isSubmitting && isEmpty(errors);
    const handleClose = () => {
        props.resetForm();
        props.handleClose();
    };
    return (
        <Dialog onClose={handleClose} aria-labelledby='popup-title' open={props.open} fullWidth={true}>
            <DialogTitle id='popup-title' onClose={handleClose}>
                {props.title}
            </DialogTitle>
            <DialogContent>
                <div style={{ padding: '20px' }}>
                    <form onSubmit={handleSubmit}>
                        <Field
                            type='text'
                            name='name'
                            requiredfield={'true'}
                            label='Vendor Name'
                            component={FormInput}
                        />
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={values.status}
                                        onChange={(e) => {
                                            setFieldValue('status', e.target.checked);
                                        }}
                                        name='status'
                                        color='primary'
                                    />
                                }
                                label='Is Active'
                            />
                        </Grid>
                        <Grid item className={classes.saveButton}>
                            <Button
                                variant='contained'
                                size='large'
                                type='submit'
                                className={isValidForm ? classes.activeBtn : classes.disabledBtn}
                                disabled={!isValidForm}
                                startIcon={<SaveIcon />}
                            >
                                Save
                            </Button>
                        </Grid>
                    </form>
                </div>
            </DialogContent>
            <DialogActions></DialogActions>
        </Dialog>
    );
}
