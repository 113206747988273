import React from 'react';

import { Container, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Image from '../../core-components/Image';
import Button from '../../core-components/Button';
import HomeIcon from '@material-ui/icons/Home';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    minHeight: '100vh',
    flexDirection: 'column',
    textAlign: 'center'
  },
  image: { marginTop: '100px' },
  heading: {
    marginTop: 40,
    marginBottom: 30,
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '38px',
    color: '#333333'
  },
  button: {
    marginTop: 40,
    color: '#ffffff',
    borderRadius: 20,
    borderColor: '#388e3c',
    background: 'linear-gradient(to right, #0844a6 0%, #086590 48.96%, #088077 100%)',
    fontSize: 14,
    textTransform: 'inherit',
    '&:hover': {
      background: 'linear-gradient(to right, #0844a6 0%, #086590 48.96%, #088077 100%)'
    }
  }
}));

export default (props) => {
  const classes = useStyles();

  const backToHome = () => {
    props.history.push('/dashboard');
  };
  return (
    <React.Fragment>
      <Container>
        <Grid container className={classes.gridContainer}>
          <Grid data-testid='notfound-box' item xl={12} md={12}>
            <Image className={classes.image} name={'404.png'} />
            <Typography className={classes.heading}>{'Oops! Page Not Found 🙄'}</Typography>
            <Typography>{"Looks like the Page you're looking for, is missing. 🧐"}</Typography>
            <Typography>{'We are verifying, who made a mistake? You or Us 🤔'}</Typography>
            <Button
              className={classes.button}
              label={'Back to Home'}
              data-testid='backtohome-btn'
              endIcon={<HomeIcon />}
              onClick={backToHome}
            />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};
