// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ze3DmnEPx5E_r7IzMp6W {\n  display: flex;\n  flex-direction: column;\n}\n\n.BPWTMv_aTvDZYXfNbGne {\n  text-align: end;\n}\n\n.e9rYUUEjB0LHOM1mHw7f {\n  color: #ffa500;\n  padding-left: \"10px\";\n}\n\n.IyYQ4o4rDiqATU57AgZd {\n  color: #ff0000;\n  padding-left: \"10px\";\n}\n\n.wIOMqY67kw5M5BBytxLh {\n  color: #2600ff;\n  text-decoration: none;\n}\n\n.MQfGT0AWDMdGMwpN5UMl > div > input {\n  min-width: 14rem;\n  padding: 0.4rem 2rem 0.4rem 0.4rem;\n  border-radius: 0.4rem;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": "Ze3DmnEPx5E_r7IzMp6W",
	"excessBridgeText": "BPWTMv_aTvDZYXfNbGne",
	"orangeColorText": "e9rYUUEjB0LHOM1mHw7f",
	"redColorText": "IyYQ4o4rDiqATU57AgZd",
	"blueColorText": "wIOMqY67kw5M5BBytxLh",
	"myDatePicker": "MQfGT0AWDMdGMwpN5UMl"
};
export default ___CSS_LOADER_EXPORT___;
