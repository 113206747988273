import { connect } from 'react-redux';
import { compose } from 'redux';
import { getCompanyById } from '../../../../actions/admin';

const mapStateToProps = (state) => {
  return {
    companyDetails: state.updateCompanyData ? state.updateCompanyData : {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyById: (companyId) => dispatch(getCompanyById(companyId))
  };
};
export default compose(connect(mapStateToProps, mapDispatchToProps));
