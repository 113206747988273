import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { FieldArray } from 'formik';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography, Button } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import RichTextEditor from '../../../core-components/RichTextEditor';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  commentLabel: {
    color: '#333333',
    fontSize: 17,
    fontFamily: 'Poppins',
    fontWeight: 400,
    lineHeight: 1.5
  },
  errorLabel: {
    color: '#f44336',
    fontSize: 17,
    fontFamily: 'Poppins',
    fontWeight: 400,
    lineHeight: 1.5
  },
  formControl: {
    width: '100%'
  },
  activeBtn: {
    margin: theme.spacing(1),
    width: '240px',
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  disabledBtn: {
    margin: theme.spacing(1),
    width: '240px',
    color: '#dfdfdf',
    borderColor: '#d9d9d9',
    backgroundColor: '#f5f5f5',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  heading: {
    paddingBottom: theme.spacing(2)
  },
  headingSubText: {
    fontSize: 16,
    color: theme.palette.text.secondary
  },
  emailErrorMsg: {
    fontSize: 14,
    color: '#f44336',
    marginLeft: 14,
    marginTop: 3,
    fontWeight: 400,
    lineHeight: 1.66
  }
}));

export default (props) => {
  const classes = useStyles();

  const handleClose = () => {
    props.handleClose();
  };

  const { handleSubmit, values, errors, setFieldValue, setFieldError, setTouched } = props;

  const isValidForm = !props.isSubmitting && isEmpty(props.errors);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='popup-title'
      open={props.open}
      className={classes.root}
      fullWidth={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      maxWidth={'md'}
    >
      <DialogTitle id='popup-title' onClose={handleClose}>
        <Typography variant='h5' gutterBottom>
          {props.isEdit ? 'Edit Comment' : 'Add comment(s)'}
        </Typography>
        <Typography className={classes.headingSubText} variant='h6' component='h6'>
          {props.subTitle || ''}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {values.comments && values.comments.length ? (
              <FieldArray
                name='comments'
                render={(arrayHelpers) => (
                  <>
                    {values?.comments && values?.comments?.length
                      ? values.comments.map((value, index) => (
                          <Grid item md={12} xs={12} lg={12} key={`insuffComm-${index}`}>
                            <Typography
                              className={
                                errors.comments ? classes.errorLabel : classes.commentLabel
                              }
                              gutterBottom
                            >
                              {`${value.type === 'PUBLIC' ? 'Public' : 'Private'} comment:`}
                            </Typography>
                            <RichTextEditor
                              textValue={value.text || ''}
                              textChange={(e) => {
                                setFieldValue(`comments.${index}.text`, e);
                              }}
                            />
                            {errors.comments ? (
                              <div className={classes.emailErrorMsg}>{errors.comments ?? null}</div>
                            ) : null}
                          </Grid>
                        ))
                      : null}
                  </>
                )}
              />
            ) : null}
          </Grid>
          <Box mb={2} mt={3} align={'right'}>
            <Button
              variant='contained'
              type='submit'
              className={isValidForm ? classes.activeBtn : classes.disabledBtn}
              disabled={!isValidForm}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};
