import * as Yup from 'yup';
import {
  getAlphabeticalStringValidationSchema,
  getEmailValidationSchema,
  getUANValidationSchema
} from '../../../../utils/ValidationSchema';

export default Yup.object().shape({
  name: getAlphabeticalStringValidationSchema({ message: 'name', isRequired: true }),
  email: getEmailValidationSchema({ isRequired: false })
    .when('formConfig', {
      is: (formConfig) => {
        return !formConfig.isEmailOptional;
      },
      then: getEmailValidationSchema({ isRequired: true })
    })
    .when('emailExists', {
      is: true,
      then: Yup.string().test({
        name: 'email',
        message: 'Email already exists',
        test: (value) => {
          return false;
        }
      })
    }),
  alternateEmail: getEmailValidationSchema({ fieldName: 'alternateEmail' }),
  phone: Yup.string()
    .required('This is a required field.')
    .when('phoneExists', {
      is: true,
      then: Yup.string().test({
        name: 'phone',
        message: 'Phone Number already exists',
        test: (value) => {
          return false;
        }
      })
    }),
  uanNumber: getUANValidationSchema({}),
  employeeId: Yup.string().when('employeeIdExists', {
    is: true,
    then: Yup.string().test({
      name: 'employeeId',
      message: 'Employee ID already exists',
      test: (value) => {
        return false;
      }
    })
  }),
  resumeLink: Yup.string().when('formConfig', {
    is: (formConfig) => !formConfig.isResumeOptional,
    then: Yup.string().required('Resume cannot be blank')
  })
});
