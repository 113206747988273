import React from 'react';

import { Box, Grid, Typography, InputLabel, FormHelperText, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'formik';

import SaveIcon from '@material-ui/icons/Save';

import FormInput from '../../../../../../core-components/FormInput';
import Dropdown from '../../../../../../core-components/DropdownInput';
import ModalWrapper from '../../../../../../core-components/ModalWrapper';

const useStyles = makeStyles((theme) => ({
  label: {
    margin: '10px 0 5px',
    color: '#333333'
  },
  dropdownError: {
    color: '#f44336',
    margin: '4px 14px 0'
  },
  activeBtn: {
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse'
  }
}));

const TAGS = [
  { label: 'Discount', value: 'DISCOUNT' },
  { label: 'Penalty', value: 'PENALTY' }
];

function UpdateBalance(props) {
  const classes = useStyles();

  const { values, setFieldValue, errors, handleSubmit, open, handleClose } = props;
  return (
    <div>
      <ModalWrapper open={open} onClose={handleClose} title={'Update balance'} modalSize={'md'}>
        <form onSubmit={handleSubmit}>
          <Typography>
            Current Balance: {props.creditLimit === 'UNLIMITED' ? 'UNLIMITED' : props.creditLimit}
          </Typography>
          <Box>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputLabel className={classes.label}>Set new Credit Balance :</InputLabel>
              <Field
                fullWidth={true}
                type='text'
                name='amount'
                component={FormInput}
                variant='outlined'
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputLabel className={classes.label}>Tag as :</InputLabel>
              <Dropdown
                name='tag'
                dropdownValues={TAGS}
                fullWidth={true}
                value={values.tag}
                handleSelect={(value) => {
                  setFieldValue('tag', value);
                }}
              />
              <FormHelperText className={classes.dropdownError}>{errors.tag}</FormHelperText>
            </Grid>
          </Box>
          <Box mt={3} className={classes.buttonContainer}>
            <Button
              variant='contained'
              type='submit'
              className={classes.activeBtn}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Box>
        </form>
      </ModalWrapper>
    </div>
  );
}

export default UpdateBalance;
