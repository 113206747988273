import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { has } from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Tooltip,
  Zoom,
  Typography,
  CircularProgress,
  MenuItem,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Checkbox,
  IconButton,
  TextField,
  Button,
  Link
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Pagination from '@material-ui/lab/Pagination';
import SearchIcon from '@material-ui/icons/Search';
import PublishIcon from '@material-ui/icons/Publish';

import BreadCrumbs from '../../../core-components/BreadCrumbs';
import AppConstants from '../../../core-components/AppConstants';
import DropdownPopup from '../../../core-components/DropdownPopup';
import { INSUFF_REVIEW_STATUSES } from '../../../core-components/CommonConstants';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { setGlobalEmailSettings, publishComments as publishCommentsAPI } from '../../../api/admin';
import { errToastMessage, getErrorMessage, getTrimmedValue } from '../../../utils/Utlities';
import ConfirmationPopup from '../../../core-components/ConfirmationPopup';

const useStyles = makeStyles((theme) => ({
  emailStatusOn: {
    background: '#6bc529'
  },
  emailStatusOff: {
    background: '#f05454'
  },
  emailStatusContainer: {
    padding: '0 0 0 8px',
    maxWidth: 'max-content',
    color: '#ffffff',
    borderRadius: '5px'
  },
  flex: {
    display: 'flex'
  },
  iconBtn: {
    fontSize: 18,
    padding: '8px'
  },
  countContainer: {
    background: '#f1f6f9',
    color: '#14274e',
    borderRadius: '12px'
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '60vh',
    alignItems: 'center'
  },
  nameTitle: {
    fontWeight: 600
  },
  searchBtn: {
    width: '100%'
  },
  filterCheckBox: {
    fontSize: '13px'
  },
  formControl: {
    width: '100%'
  },
  inputHeight: {
    height: 36
  },
  headingContainer: {
    marginBottom: theme.spacing(2)
  },
  pushButton: {
    textAlign: 'right'
  },
  noDataFoundText: {
    textAlign: 'center'
  }
}));

export default (props) => {
  const classes = useStyles();

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [step1Index, setStep1Index] = useState(0);
  const [currentCandidate, setCurrentCandidate] = useState(null);
  const [comments, setComments] = useState({});
  const [emailSettings, setEmailSettings] = useState(false);
  const [displayConfigModal, setDisplayConfigModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [candidates, setCandidates] = useState([]);
  const [wholeData, setWholeData] = useState({});

  const [status, setStatus] = useState('NONE');
  const [searchByName, setSearchByName] = useState(null);
  const [unreviewedPublicComments, setUnreviewedPublicComments] = useState(true);
  const [unreviewedPrivateComments, setUnreviewedPrivateComments] = useState(true);
  const [onlyPrivateComments, setOnlyPrivateComments] = useState(false);
  const [showPublishPopup, setShowPublishPopup] = useState(false);

  const [displayTextInPublish, setDisplayTextInPublish] = useState(null);

  const breadCrumbsArray = [{ label: 'Home', href: `${AppConstants.baseURL}dashboard` }];

  const fetchInsuffReview = (where, page, offset, isEdit = false) => {
    if (!where) {
      where = {
        limit,
        offset,
        onlyPrivate: 0,
        onlyUnreviewedPublic: unreviewedPublicComments ? 1 : 0,
        onlyUnreviewedPrivate: unreviewedPrivateComments ? 1 : 0,
        status,
        candidateName: searchByName
      };
    }

    props
      .getInsuffReview(where)
      .then((response) => {
        !isEdit && setStep1Index(0);
        setPage(page);
        setOffset(offset);
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  };

  // CDM initial get review API call
  useEffect(() => {
    const where = {
      limit,
      offset,
      onlyPrivate: 0,
      onlyUnreviewedPublic: 1,
      onlyUnreviewedPrivate: 1,
      status,
      candidateName: searchByName
    };
    fetchInsuffReview(where, 1, 0);

    return () => {
      props.invalidateInsuffReview();
    };
  }, []);

  useEffect(() => {
    if (props?.insuffData && has(props.insuffData, 'loading') && !props?.insuffData?.loading) {
      const wholeData = props.insuffData.all[offset] && props.insuffData.all[offset];
      const { rows, count, unreviewed, globalEmailSettings } = wholeData || {};

      setTotalPage(Math.ceil(count / rowsPerPage));
      setCurrentCandidate((rows[step1Index] && rows[step1Index]) || null);
      setCandidates(rows);
      setWholeData({ count, unreviewed, globalEmailSettings });
      setEmailSettings(globalEmailSettings && globalEmailSettings.subscriptionStatus);
      setLoading(false);
    }
  }, [props.insuffData, offset, page]);

  // Fetch Review when filter checkbox change
  const handleUnreviewedPublicChange = (e) => {
    setUnreviewedPublicComments(e.target.checked);
    const where = {
      limit,
      offset: 0,
      status,
      candidateName: null,
      onlyPrivate: onlyPrivateComments ? 1 : 0,
      onlyUnreviewedPublic: e.target.checked ? 1 : 0,
      onlyUnreviewedPrivate: unreviewedPrivateComments ? 1 : 0
    };
    fetchInsuffReview(where, 1, 0);
  };

  // Fetch Review when filter checkbox change
  const handleUnreviewedPrivateChange = (e) => {
    setUnreviewedPrivateComments(e.target.checked);
    const where = {
      limit,
      offset: 0,
      status,
      candidateName: null,
      onlyPrivate: onlyPrivateComments ? 1 : 0,
      onlyUnreviewedPublic: unreviewedPublicComments ? 1 : 0,
      onlyUnreviewedPrivate: e.target.checked ? 1 : 0
    };
    fetchInsuffReview(where, 1, 0);
  };

  // Fetch Review when filter checkbox change
  const handleOnlyPrivateChange = (e) => {
    setOnlyPrivateComments(e.target.checked);
    const where = {
      limit,
      offset: 0,
      status,
      candidateName: null,
      onlyPrivate: e.target.checked ? 1 : 0,
      onlyUnreviewedPublic: unreviewedPublicComments ? 1 : 0,
      onlyUnreviewedPrivate: unreviewedPrivateComments ? 1 : 0
    };
    fetchInsuffReview(where, 1, 0);
  };

  const handleStatusChange = (value) => {
    const where = {
      limit,
      offset: 0,
      status: value,
      candidateName: searchByName,
      onlyPrivate: onlyPrivateComments ? 1 : 0,
      onlyUnreviewedPublic: unreviewedPublicComments ? 1 : 0,
      onlyUnreviewedPrivate: unreviewedPrivateComments ? 1 : 0
    };
    fetchInsuffReview(where, 1, 0);
  };

  const handleFilter = () => {
    const where = {
      limit,
      offset: 0,
      status,
      candidateName: searchByName,
      onlyPrivate: onlyPrivateComments ? 1 : 0,
      onlyUnreviewedPublic: unreviewedPublicComments ? 1 : 0,
      onlyUnreviewedPrivate: unreviewedPrivateComments ? 1 : 0
    };
    fetchInsuffReview(where, 1, 0);
  };

  // handle when user click on candidate card step1
  const handleCandidateSelected = (index) => {
    setStep1Index(index);
    setComments(null);
    setCurrentCandidate(candidates[index]);
  };

  // handle when user clicks on insuff ticket step2
  const handleComments = (comments = []) => {
    setComments(comments);
  };

  // API for changing email status
  const handleSettingsChange = (value) => {
    let request = {
      global: true,
      globalSettingType: 'GLOBAL_INSUFFICIENCY',
      globalSettingValue: value
    };

    setGlobalEmailSettings(request)
      .then((response) => {
        setEmailSettings(value);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handlePagination = (e, page) => {
    const where = {
      limit,
      offset: page - 1,
      status,
      candidateName: searchByName,
      onlyPrivate: onlyPrivateComments ? 1 : 0,
      onlyUnreviewedPublic: unreviewedPublicComments ? 1 : 0,
      onlyUnreviewedPrivate: unreviewedPrivateComments ? 1 : 0
    };
    fetchInsuffReview(where, page, page - 1);
  };

  const handlePublishComments = () => {
    const where = {
      candidateUuid: currentCandidate.candidate?.uuid || null,
      candidateId: currentCandidate.candidate?.id || null
    };

    publishCommentsAPI(where)
      .then((response) => {
        toast.success('Comments published successfully');
        fetchInsuffReview(null, page, offset);
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  };

  const renderCount = () => {
    return (
      <>
        <Typography component={'span'}>{'Count: '}</Typography>
        <Tooltip title="Candidate's count" placement={'top'} arrow>
          <Typography component={'span'}>{`${wholeData?.count}  -  `}</Typography>
        </Tooltip>
        <Tooltip title="Ticket's count" placement={'top'} arrow>
          <Typography component={'span'}>
            {wholeData?.unreviewed?.insufficiencyTicketCount || 0}
          </Typography>
        </Tooltip>
      </>
    );
  };

  const setPublishDisplayText = () => {
    let returnValue = true;
    let privateComments = 0;
    let publicComments = 0;
    if (
      currentCandidate &&
      currentCandidate.insuffTickets &&
      currentCandidate.insuffTickets.length
    ) {
      for (let index = 0; index < currentCandidate.insuffTickets.length; index++) {
        const element = currentCandidate.insuffTickets[index];

        if (
          element.status &&
          ['REVIEW_CLEARANCE_L1', 'REVIEW_CLEARANCE_L2', 'UNRESOLVED', 'RESOLVED'].includes(
            element.status
          ) &&
          element.unreviewedComments
        ) {
          returnValue = false;
        }
        if (element.privateComments && element.unreviewedComments) {
          privateComments += element.privateComments;
        }
        if (element.publicComments && element.unreviewedComments) {
          publicComments += element.publicComments;
        }
      }
      if (!returnValue && privateComments > 0 && publicComments > 0) {
        setDisplayTextInPublish(true);
      }
      if (!returnValue && privateComments > 0) {
        returnValue = false;
      }
    }
    return returnValue;
  };

  const showPublishModal = () => {
    setPublishDisplayText();
    setShowPublishPopup(true);
  };

  return (
    <>
      {loading ? (
        <Grid container spacing={2} className={classes.spinnerContainer}>
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <BreadCrumbs linksArray={breadCrumbsArray} current={'Insufficiency Review'} />

          {/* Header */}
          <Box mt={2} mb={3} p={2} className={classes.countContainer}>
            <Grid container style={{ alignItems: 'center' }}>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Typography variant={'h4'}>{'Insufficiency review'}</Typography>
              </Grid>
              <Grid
                item
                lg={8}
                md={8}
                sm={12}
                xs={12}
                className={classes.flex}
                style={{ justifyContent: 'flex-end', alignItems: 'center' }}
              >
                <Box
                  mr={1}
                  p={1}
                  style={{ background: '#9ba4b4', color: '#f1f6f9', borderRadius: '8px' }}
                >
                  <Typography
                    display={'inline'}
                  >{`Unreviewed candidates: ${wholeData.unreviewed?.candidates}`}</Typography>
                  {' | '}
                  <Typography display={'inline'}>{`Unreviewed tickets: ${
                    wholeData?.unreviewed?.insufficiencyTicketCount || 0
                  } (${wholeData.unreviewed?.publicComments} Public, ${
                    wholeData.unreviewed?.privateComments
                  } Private)`}</Typography>
                </Box>
                <Box
                  className={`
                    ${classes.emailStatusContainer}
                    ${emailSettings ? classes.emailStatusOn : classes.emailStatusOff}
                  `}
                >
                  <span>{`Insuff emails Status: ${emailSettings ? 'On' : 'Off'}`}</span>
                  <IconButton
                    aria-label='edit'
                    className={classes.iconBtn}
                    onClick={() => {
                      setDisplayConfigModal(true);
                    }}
                  >
                    <Tooltip TransitionComponent={Zoom} title='Edit' arrow={true}>
                      <EditIcon style={{ color: '#ffffff' }} />
                    </Tooltip>
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/* Step 1 */}
          <Grid container spacing={2}>
            <Grid
              item
              lg={3}
              md={3}
              sm={12}
              style={{
                maxHeight: '650px',
                borderRight: '1px solid #132743',
                padding: '0 15px 0 15px',
                overflowY: 'auto'
              }}
            >
              {renderCount()}
              <Box style={{ display: 'flex', width: '100%' }} mt={1} mb={3}>
                <Grid container spacing={1}>
                  <Grid item lg={10} md={10} sm={10} xs={12}>
                    <FormControl variant='outlined' className={classes.formControl} size='small'>
                      <InputLabel id='insuffSelectLabel'>{'Status'}</InputLabel>
                      <Select
                        labelId='insuffSelectLabel'
                        id='insuffSelectStatus'
                        value={status}
                        onChange={(e) => {
                          setStatus(e.target.value);
                          handleStatusChange(e.target.value);
                        }}
                        className={classes.inputHeight}
                        label='Status'
                        fullWidth={true}
                      >
                        <MenuItem value='NONE'>
                          <em>None</em>
                        </MenuItem>
                        {INSUFF_REVIEW_STATUSES.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item container lg={12} md={12} sm={12} xs={12}>
                    <Grid item lg={10} md={10} sm={10}>
                      <TextField
                        className={classes.inputHeight}
                        size={'small'}
                        id='search-by-name'
                        label='Search by name'
                        type='search'
                        variant='outlined'
                        value={searchByName}
                        // onChange={(e) => {
                        //   setSearchByName(e.target.value);
                        // }}
                        onBlur={(e) => {
                          const trimmedValue = getTrimmedValue(e.target.value, true);
                          setSearchByName(trimmedValue);
                        }}
                      />
                    </Grid>
                    <Grid item lg={2} md={2} sm={2}>
                      <IconButton
                        color='primary'
                        aria-label='edit'
                        onClick={() => {
                          handleFilter();
                        }}
                      >
                        <SearchIcon />
                      </IconButton>
                    </Grid>
                    <Grid item container lg={10} md={10} sm={10} xs={12}>
                      <FormControl
                        component='fieldset'
                        size={'small'}
                        className={classes.formControl}
                      >
                        <FormControlLabel
                          classes={{ label: classes.filterCheckBox }}
                          control={
                            <Checkbox
                              color='primary'
                              size={'small'}
                              onChange={(e) => handleUnreviewedPublicChange(e)}
                              checked={unreviewedPublicComments}
                              name={'unreviewedPublicComments'}
                            />
                          }
                          label={'Show unreviewed public comments'}
                        />
                      </FormControl>
                      <FormControl
                        component='fieldset'
                        size={'small'}
                        className={classes.formControl}
                      >
                        <FormControlLabel
                          classes={{ label: classes.filterCheckBox }}
                          control={
                            <Checkbox
                              color='primary'
                              size={'small'}
                              onChange={(e) => handleUnreviewedPrivateChange(e)}
                              checked={unreviewedPrivateComments}
                              name={'unreviewedPrivateComments'}
                            />
                          }
                          label={'Show unreviewed private comments'}
                        />
                      </FormControl>
                      <FormControl
                        component='fieldset'
                        size={'small'}
                        className={classes.formControl}
                      >
                        <FormControlLabel
                          classes={{ label: classes.filterCheckBox }}
                          control={
                            <Checkbox
                              color='primary'
                              size={'small'}
                              onChange={(e) => handleOnlyPrivateChange(e)}
                              checked={onlyPrivateComments}
                              name={'onlyPrivateComments'}
                            />
                          }
                          label={'Only private comment '}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              {candidates && candidates.length ? (
                <Step1
                  candidates={candidates}
                  selectedCandidateIndex={step1Index}
                  handleCandidateSelected={handleCandidateSelected}
                />
              ) : (
                props?.insuffData?.all[offset] &&
                !props?.insuffData?.all[offset]?.length && (
                  <Typography className={classes.noDataFoundText} variant={'h6'}>
                    {'No Candidates found'}
                  </Typography>
                )
              )}
            </Grid>

            {/* Step 2 */}
            <Grid
              item
              lg={5}
              md={5}
              sm={12}
              style={{
                maxHeight: '650px',
                borderRight: '1px solid #132743',
                padding: '0 15px 0 15px',
                overflowY: 'auto'
              }}
            >
              {step1Index !== null && currentCandidate ? (
                <>
                  <Grid item container className={classes.headingContainer}>
                    <Grid item md={4} xs={4} lg={4}>
                      <Typography gutterBottom variant='h6'>
                        {' '}
                        {'Insuff Tickets'}{' '}
                      </Typography>
                      <Typography variant='caption' display='block' color='textSecondary'>
                        <Link
                          href={`${AppConstants.baseURL}candidate/details?id=${currentCandidate?.candidate?.id}&company_id=${currentCandidate?.company?.id}`}
                          color={'primary'}
                          target='_blank'
                          variant='body2'
                          className={classes.link}
                        >
                          {(currentCandidate &&
                            currentCandidate.candidate &&
                            currentCandidate.candidate.name) ||
                            'Candidate Name'}
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid item md={8} xs={8} lg={8} className={classes.pushButton}>
                      <Button
                        variant='contained'
                        color='primary'
                        startIcon={<PublishIcon />}
                        className={
                          currentCandidate.insuffDetails.pendingComments
                            ? classes.infoBtn
                            : classes.disabled
                        }
                        disabled={!currentCandidate.insuffDetails.pendingComments}
                        onClick={() => showPublishModal()}
                      >
                        {` Publish insuff comments (${currentCandidate?.insuffDetails?.pendingComments}) `}
                      </Button>
                    </Grid>
                  </Grid>
                  <Step2
                    selectedIndex={step1Index}
                    candidateDetails={{
                      candidateId: currentCandidate.candidate.id,
                      candidateUuid: currentCandidate.candidate.uuid,
                      candidateName: currentCandidate.candidate.name,
                      companyId: currentCandidate.company.id
                    }}
                    candidateVerifications={
                      currentCandidate ? currentCandidate.verifications : null
                    }
                    details={currentCandidate ? currentCandidate.insuffTickets : null}
                    insuffDetails={currentCandidate ? currentCandidate.insuffDetails : null}
                    setComments={handleComments}
                  />
                </>
              ) : null}
            </Grid>

            {/* step 3 */}
            <Grid
              item
              lg={4}
              md={4}
              sm={12}
              xs={12}
              style={{ maxHeight: '650px', overflowY: 'auto' }}
            >
              {comments &&
              Object.keys(comments).length !== 0 &&
              currentCandidate &&
              currentCandidate.insuffTickets &&
              currentCandidate.insuffTickets[comments['index']] ? (
                <Step3
                  getInsuffReview={fetchInsuffReview}
                  page={page}
                  offset={offset}
                  // insuffTickets={currentCandidate.insuffTickets}
                  commentDetails={{
                    ...currentCandidate.insuffTickets[comments['index']],
                    heading: comments.heading,
                    status: currentCandidate.insuffTickets[comments['index']]['status']
                    // remainingOpenTickets: 1
                  }}
                  candidateDetails={{
                    candidateId: currentCandidate.candidate.id,
                    candidateUuid: currentCandidate.candidate.uuid,
                    candidateName: currentCandidate.candidate.name,
                    companyId: currentCandidate.company.id
                  }}
                />
              ) : null}
            </Grid>
          </Grid>

          {/* Pagination */}
          <Box mt={3}>
            <Grid container>
              <Grid item>
                <Pagination
                  count={totalPage}
                  page={page}
                  size='small'
                  variant='outlined'
                  color='primary'
                  onChange={(e, page) => handlePagination(e, page)}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}

      {displayConfigModal ? (
        <DropdownPopup
          open={displayConfigModal}
          title={'Insufficiency Email Reminder Status'}
          label={'Send Emails ?'}
          value={emailSettings}
          dropdownValues={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
          handleSubmit={(status) => {
            handleSettingsChange(status);
            setDisplayConfigModal(false);
          }}
          handleClose={() => {
            setDisplayConfigModal(false);
          }}
          buttonLabel={'Save'}
        />
      ) : null}

      {showPublishPopup ? (
        <ConfirmationPopup
          open={showPublishPopup}
          title={'Publish insuff comments ?'}
          content={`Are you sure you want to publish ${currentCandidate.insuffDetails?.pendingComments}  insuff comments ?`}
          subContent={
            displayTextInPublish ? 'Public comments will still remain unpublished.' : null
          }
          handleSubmit={() => {
            handlePublishComments();
          }}
          handleClose={() => {
            setShowPublishPopup(false);
            setDisplayTextInPublish(false);
          }}
          buttonLabel={'Proceed'}
        />
      ) : null}
    </>
  );
};
