import React, { useState } from 'react';
import ReactSelect from 'react-select';
import './FloatingLabelSelect.scss';

const FloatingLabelSelect = (props) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div
      className={`floating-label-select ${isFocused ? 'focused' : ''} ${
        props?.value ? 'selected' : ''
      }`}
    >
      <ReactSelect
        {...props}
        onFocus={handleFocus}
        onBlur={handleBlur}
        classNamePrefix='react-select'
      />
      <label className='floating-label'>{props?.placeholder}</label>
    </div>
  );
};

export default FloatingLabelSelect;
