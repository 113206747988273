import { connect } from 'react-redux';
import { compose } from 'redux';

import { getServices as getServicesAPI } from '../../../actions/admin';

const mapStateToProps = (state) => {
    return {
        // stepCounter: 0,
        // companyData: state.companies,
        servicesData: state.services.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getServices: (limit, offset, orderId, id, price, name, status) =>
            dispatch(getServicesAPI(limit, offset, orderId, id, price, name, status)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
