import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, isEmpty, has } from 'lodash';
import { toast } from 'react-toastify';
import { errToastMessage, getErrorMessage } from '../../../../utils/Utlities';
import { updateEmailSettings } from '../../../../api/admin';
import {
  getCompanyEmailSettings as getCompanyEmailSettingsAPI,
  invalidateCompanyEmailSettings
} from '../../../../actions/admin';

const mapStateToProps = (state) => {
  return { settings: state.companyEmailSettings || null };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: (data) => dispatch(getCompanyEmailSettingsAPI(data)),
    invalidateCompanyEmailSettings: () => dispatch(invalidateCompanyEmailSettings())
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let propsValue = {
        companyId: new URLSearchParams(document.location.search).get('id') || null,
        adminId: props.details && props.details.user ? props.details.user.id : '',
        bounceEmail: false,
        digest: false,
        digestValues: {
          frequency: '',
          information: ''
        }
      };
      let storedValues = {};
      if (props.settings && has(props.settings, 'loading') && !props?.settings?.loading) {
        storedValues = {
          bounceEmail: props?.settings?.bounceEmail || false,
          digest: props?.settings?.digest ? true : false,
          digestValues: {
            frequency:
              props?.settings?.digest && props?.settings?.digest?.frequency
                ? props?.settings?.digest?.frequency
                : '',
            information:
              props?.settings?.digest && props?.settings?.digest?.information
                ? props?.settings?.digest?.information
                : ''
          }
        };
      }

      return mergeWith({}, propsValue, storedValues, (a, b) => (b === null ? a : b));
    },
    // validationSchema,
    enableReinitialize: true,
    handleSubmit: (values, { setFieldError, setStatus, setSubmitting, resetForm, props }) => {
      setSubmitting(true);
      let finalValues = values;
      finalValues.digest = finalValues.digest ? finalValues.digestValues : null;
      updateEmailSettings(finalValues)
        .then((response) => {
          setSubmitting(false);
          toast.success('Settings saved successfully!');
          props.handleClose();
        })
        .catch((error) => {
          setSubmitting(false);
          errToastMessage(error);
          props.handleClose();
          console.error(error);
        });
    },
    displayName: 'Email Settings'
  })
);
