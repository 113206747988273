import {
  INVALIDATE_PAYMENT_USERS,
  REQUEST_PAYMENT_USERS,
  SET_PAYMENT_USERS,
  INVALIDATE_ADMINISTRATORS,
  REQUEST_ADMINISTRATORS,
  SET_ADMINISTRATORS,
  UPDATE_ADMINISTRATORS,
  INVALIDATE_ZOHO_CONNECTION,
  REQUEST_ZOHO_CONNECTION,
  SET_ZOHO_CONNECTION,
  REQUEST_BRAND_SETTINGS,
  SET_BRAND_SETTINGS,
  SET_ALUMNI_UPLOAD_DATA,
  INVALIDATE_ALUMNI_UPLOAD_DATA,
  REQUEST_ALUMNI_RECORD_OVERVIEW,
  SET_ALUMNI_RECORD_OVERVIEW,
  SET_ALUMNI_REQUEST_OVERVIEW,
  REQUEST_ALUMNI_REQUEST_OVERVIEW,
  REQUEST_ALUMNI_RECORDS_TREND,
  SET_ALUMNI_RECORDS_TREND,
  SET_ALUMNI_REQUEST_TREND,
  REQUEST_ALUMNI_REQUEST_TREND,
  SET_ALUMNI_TABLE_DATA,
  REQUEST_ALUMNI_TABLE_DATA,
  REQUEST_ALUMNI_SETTINGS,
  SET_ALUMNI_SETTINGS,
  REQUEST_ALUMNI_DETAILS,
  SET_ALUMNI_DETAILS,
  INVALIDATE_ALUMNI_DETAILS
} from '../actions/company';

export default (state, action) => {
  return {
    administrators: administrators(state ? state.administrators : [], action),
    requestPaymentUsers: paymentRequestUserReducer(state ? state.requestPaymentUsers : {}, action),
    zohoConnection: zohoConnectedReducer(state ? state.zohoConnection : {}, action),
    brandSettings: brandSettings(state ? state.brandSettings : {}, action),
    alumniBulkUpload: alumniBulkUpload(state ? state.alumniBulkUpload : {}, action),
    alumniTableData: alumniTableData(state ? state.alumniTableData : {}, action),
    alumniRequestTrend: alumniRequestTrend(state ? state.alumniRequestTrend : {}, action),
    alumniRecordsTrend: alumniRecordsTrend(state ? state.alumniRecordsTrend : {}, action),
    alumniRequestOverview: alumniRequestOverview(state ? state.alumniRequestOverview : {}, action),
    alumniRecordOverview: alumniRecordOverview(state ? state.alumniRecordOverview : {}, action),
    alumniSettings: alumniSettings(state ? state.alumniSettings : {}, action),
    alumniDetails: alumniDetails(state ? state.alumniDetails : {}, action)
  };
};

const paymentRequestUserReducer = (state, action) => {
  switch (action.type) {
    case INVALIDATE_PAYMENT_USERS:
      return {};
    case REQUEST_PAYMENT_USERS:
      return { ...state, loading: true };
    case SET_PAYMENT_USERS:
      return {
        ...state,
        paymentUsers: action.data,
        loading: false
      };
    default:
      return state;
  }
};

const zohoConnectedReducer = (state, action) => {
  switch (action.type) {
    case INVALIDATE_ZOHO_CONNECTION:
      return {};
    case REQUEST_ZOHO_CONNECTION:
      return { ...state, loading: true };
    case SET_ZOHO_CONNECTION:
      return {
        ...state,
        zohoConnection: action.data,
        loading: false
      };
    default:
      return state;
  }
};

const administrators = (state, action) => {
  switch (action.type) {
    case INVALIDATE_ADMINISTRATORS:
      return {};
    case REQUEST_ADMINISTRATORS:
      return { ...state, loading: true };
    case SET_ADMINISTRATORS:
      return {
        ...state,
        loading: false
      };
    case UPDATE_ADMINISTRATORS:
      return Object.assign({}, state, action.data);
    default:
      return state;
  }
};

const brandSettings = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_BRAND_SETTINGS:
      return { loading: true };
    case SET_BRAND_SETTINGS:
      return { ...action?.data, loading: false };
    default:
      return state;
  }
};

const alumniBulkUpload = (state = {}, action) => {
  switch (action.type) {
    case SET_ALUMNI_UPLOAD_DATA:
      return { ...action?.data };
    case INVALIDATE_ALUMNI_UPLOAD_DATA:
      return {};
  }
};

const alumniTableData = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ALUMNI_TABLE_DATA:
      return { loading: true };
    case SET_ALUMNI_TABLE_DATA:
      return { ...action?.data, loading: false };
    default:
      return state;
  }
};

const alumniRequestTrend = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ALUMNI_REQUEST_TREND:
      return { loading: true };
    case SET_ALUMNI_REQUEST_TREND:
      return { ...action?.data, loading: false };
    default:
      return state;
  }
};

const alumniRecordsTrend = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ALUMNI_RECORDS_TREND:
      return { loading: true };
    case SET_ALUMNI_RECORDS_TREND:
      return { ...action?.data, loading: false };
    default:
      return state;
  }
};

const alumniRequestOverview = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ALUMNI_REQUEST_OVERVIEW:
      return { loading: true };
    case SET_ALUMNI_REQUEST_OVERVIEW:
      return { ...action?.data, loading: false };
    default:
      return state;
  }
};

const alumniRecordOverview = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ALUMNI_RECORD_OVERVIEW:
      return { loading: true };
    case SET_ALUMNI_RECORD_OVERVIEW:
      return { ...action?.data, loading: false };
    default:
      return state;
  }
};

const alumniSettings = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ALUMNI_SETTINGS:
      return { loading: true };
    case SET_ALUMNI_SETTINGS:
      return { ...action?.data, loading: false };
    default:
      return state;
  }
};

const alumniDetails = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ALUMNI_DETAILS:
      return { loading: true };
    case SET_ALUMNI_DETAILS:
      return { ...action?.data, loading: false };
    case INVALIDATE_ALUMNI_DETAILS:
      return {};
    default:
      return state;
  }
};
