import React, { useState, useEffect } from 'react';

import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Box, Button } from '@material-ui/core';
import { toast } from 'react-toastify';

import {
  companyLogoUpload as companyLogoUploadAPI,
  fileUpload as fileUploadAPI
} from '../api/admin';
import { errToastMessage, getErrorMessage } from '../utils/Utlities';
import { has } from 'lodash';
import { getFileMimeType } from '../components/SuperAdmin/frontend-common/core-components/Utilities/utilities';

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none'
  },
  filename: { margin: theme.spacing(1) },
  error: {
    fontSize: '12px',
    color: '#f44336',
    marginBottom: '5px',
    marginTop: '5px',
    width: '325px'
  },
  file: {
    color: `rgba(51, 51, 51, 0.7)`,
    margin: 0,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    marginLeft: '1rem'
  }
}));
export default (props) => {
  const { field, form, uploadFileType } = props;
  const [fileName, setFileName] = useState('');
  const classes = useStyles();

  const handleChange = (e) => {
    if (e.currentTarget?.files.length) {
      const file = e.currentTarget.files[0];

      // Check if the file is an image
      if (props?.accept === 'image/jpeg, image/png' && has(props, 'setIsCorrectLogoFormat')) {
        const fileType = file.type;

        // Check if the file type matches the accepted types
        const acceptedTypes = ['image/jpeg', 'image/png'];
        const isAcceptedType = acceptedTypes.includes(fileType);

        if (!isAcceptedType) {
          props?.setIsCorrectLogoFormat(false);
          return;
        } else {
          props?.setIsCorrectLogoFormat(true);
        }
      }

      if (props.nameField) {
        form.setFieldValue(props.nameField, e.currentTarget?.files[0]?.name);
      } else {
        setFileName(e.currentTarget?.files[0]?.name);
      }

      let fileType = '';
      var blob = e.currentTarget.files[0];
      var fileReader = new FileReader();
      fileReader.onloadend = () => {
        var arr = new Uint8Array(fileReader.result).subarray(0, 4);
        var header = '';
        for (var i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }
        // Check the file signature against known types
        fileType = getFileMimeType(header, blob);
        if (!props.accept.includes(fileType)) {
          props.setValidation && props.setValidation(false);
        } else {
          // Check image dimensions
          if (props?.checkFileSize && props?.setLogoFileSizeError) {
            const imageElement = new Image();
            imageElement.src = URL.createObjectURL(file);

            imageElement.onload = function () {
              const imageWidth = imageElement.naturalWidth;
              const imageHeight = imageElement.naturalHeight;

              if (imageWidth < 60 || imageHeight < 60) {
                props.setLogoFileSizeError(true);
              } else {
                props.setLogoFileSizeError(false);
              }
            };
          }

          props.setValidation && props.setValidation(true);
          if (props.uploadToServer) {
            props.setIsUploading(true);
            if (props.uploadToServer === 's3Upload') {
              if (props.candidateId && props.checkId) {
                const request = {
                  candidateId: props.candidateId,
                  checkId: props.checkId,
                  file: file,
                  fileType: props.uploadFileType
                };
                fileUploadAPI(request)
                  .then((response) => {
                    form.setFieldValue(field.name, file);
                    form.setFieldValue(props.linkFieldName, response.data.data);
                    props.setIsUploading(false);
                  })
                  .catch((error) => {
                    form.setFieldValue(field.name, '');
                    errToastMessage(error);
                  });
              } else if (props.candidateId) {
                let request = {
                  candidateId: props.candidateId,
                  file: file,
                  fileType: props.uploadFileType
                };
                if (props?.field?.name === 'consent') {
                  request = { ...request, checkType: 'consent' };
                }
                fileUploadAPI(request)
                  .then((response) => {
                    form.setFieldValue(field.name, file);
                    form.setFieldValue(props.linkFieldName, response.data.data);
                    props.setIsUploading(false);
                  })
                  .catch((error) => {
                    form.setFieldValue(field.name, '');
                    if (props?.field?.name !== 'consent') {
                      errToastMessage(error);
                    }
                  });
              } else {
                props.setIsUploading(false);
                fileReader.readAsDataURL(file);
                form.setFieldValue(field.name, file);
              }
            } else {
              let formData = new FormData();
              formData.append('logo', file);
              formData.append('fileType', props.uploadFileType);
              companyLogoUploadAPI(formData)
                .then((response) => {
                  form.setFieldValue(field.name, file);
                  form.setFieldValue('logoFile', response.data.data);
                  props.setIsUploading(false);
                })
                .catch((error) => {
                  console.error(error);
                  props.setIsUploading(false);
                  form.setFieldValue('logoFile', '');
                  form.setFieldValue(field.name, '');
                  errToastMessage(error);
                });
            }
          } else {
            fileReader.readAsDataURL(file);
            form.setFieldValue(field.name, file);
          }
        }
      };

      if (blob instanceof Blob) {
        {
          /** To avoid TyperError issue reported by sentry, need to check if param is an Blob type or not */
        }
        fileReader.readAsArrayBuffer(blob);
      } else {
        console.error(
          `Failed to execute 'readAsArrayBuffer' on 'FileReader': parameter is not of type 'Blob'`
        );
      }
      // preview image
      if (props.showImagePreview && !props.uploadToServer) {
        fileReader.onload = function (e) {
          form.setFieldValue('logoFile', e.currentTarget.result);
        };
      }
    }
  };

  return (
    <Box mb={3}>
      <Input
        inputProps={{ accept: props.accept || 'image/*,application/pdf' }}
        className={classes.input}
        id={`contained-button-file-${field.name}`}
        onChange={handleChange}
        type='file'
      />
      <label htmlFor={`contained-button-file-${field.name}`}>
        <Button
          variant='contained'
          color='default'
          startIcon={<CloudUploadIcon />}
          component='span'
        >
          {'Browse'}
        </Button>
        <span className={classes.file}>
          {props.fileUrl ? (
            <a href={props.fileUrl} target='_blank'>
              {props.fileName}
            </a>
          ) : props.fileName ? (
            props.fileName
          ) : fileName ? (
            fileName
          ) : (
            'No file chosen'
          )}
        </span>
      </label>
      {has(props, 'isCorrectLogoFormat') && !props?.isCorrectLogoFormat && (
        <div className={classes.error}>
          <span>Please provide a valid company logo format (.png, .jpeg, .jpg)</span>
        </div>
      )}
    </Box>
  );
};
