import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography, Button, Box } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import ChecksIncluded from './ChecksIncluded';

const useStyles = makeStyles((theme) => ({
  packageContainer: {
    padding: '24px',
    marginBottom: '16px'
  },
  checkIncludedText: {
    color: '#45A41C',
    fontWeight: 600,
    fontSize: '12px'
  },
  subtypeName: {
    color: '#333333',
    fontWeight: 600,
    fontSize: '20px'
  },
  balanceText: {
    fontWeight: 600,
    fontSize: '14px',
    color: '#595959'
  },
  bodyContainerContent: {
    display: 'flex',
    padding: '9px 16px',
    background: '#E6F7FF'
  },
  bodyContainerContentDescription: {
    color: '#262626',
    fontSize: '14px'
  },
  customisableText: {
    color: '#999999',
    fontWeight: 500,
    fontSize: '12px',
    marginTop: '8px'
  },
  checkTextHeading: {
    color: '#333333',
    fontSize: '16px !important',
    fontWeight: 500
  },
  root: {
    minWidth: '40px'
  },
  checkText: {
    color: '#555555',
    fontSize: '14px'
  },
  costPerCandidatePrice: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '32px',
    color: '#333333'
  },
  costPerCandidateText: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '14px',
    color: '#99999999',
    padding: '0 4px'
  }
}));

export default (props) => {
  const classes = useStyles();

  const {
    packageDetails,
    showChoose,
    index,
    showSelected,
    fromStep3,
    packageDetails: { config },
    packageType,
    isAadhaarAllowed
  } = props;

  const Heading = () => {
    return (
      <Grid container>
        <Grid item lg={8} md={8} sm={8} xs={8}>
          <Typography component={'p'} className={classes.subtypeName} gutterBottom>
            {packageDetails.packageName} ({packageDetails.subtypeName})
          </Typography>
          {packageType === 'PREPAID' && (
            <Typography component={'p'} className={classes.balanceText} gutterBottom>
              {`Current balance: ${packageDetails.currentBalance}`}
              <span style={{ verticalAlign: 'middle' }}>
                <CheckCircleIcon style={{ color: '#52C41A', fontSize: '14px' }} />
              </span>
            </Typography>
          )}
        </Grid>

        {showChoose ? (
          <Grid item lg={4} md={4} sm={4} xs={4} style={{ textAlign: 'left' }}>
            {packageType === 'POSTPAID' && (
              <p>
                <span className={classes.costPerCandidatePrice}>
                  &#8377;{packageDetails.costPerCandidate}
                </span>
                <span className={classes.costPerCandidateText}>per candidate</span>
              </p>
            )}
            <Button
              color={'primary'}
              variant={'contained'}
              onClick={() =>
                props.handleChoose(packageDetails.isCustomisable, packageDetails, index)
              }
            >
              {'Choose'}
            </Button>
            {packageDetails.isCustomisable && (
              <Typography component={'p'} gutterBottom className={classes.customisableText}>
                {'*customisable'}
              </Typography>
            )}
          </Grid>
        ) : null}

        {fromStep3 && packageDetails.isCustomisable ? (
          <Grid item lg={4} md={4} sm={4} xs={4} style={{ textAlign: 'right' }}>
            <Typography
              color={'primary'}
              component={'span'}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                props.handleChoose();
              }}
            >
              {'Customize'}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    );
  };

  const Body = () => {
    return (
      <div className={classes.bodyContainerContent}>
        <InfoIcon style={{ color: '#1890FF', marginRight: '8px' }} />
        <Typography
          gutterBottom
          component={'p'}
          className={classes.bodyContainerContentDescription}
        >
          {`Balance shared with ${packageDetails.sharedWith.join(',')}`}
        </Typography>
      </div>
    );
  };

  const Footer = () => {
    return (
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography className={classes.checkIncludedText} gutterBottom>
            {'CHECKS INCLUDED'}
          </Typography>
        </Grid>
        <ChecksIncluded config={config} isAadhaarAllowed={isAadhaarAllowed} />
      </Grid>
    );
  };

  // console.log('BB', props, config, packageDetails);

  return (
    <>
      <Paper
        elevation={3}
        className={classes.packageContainer}
        style={
          showSelected && props.selectedIndex === props.index
            ? { border: '2px solid #2755FE' }
            : null
        }
      >
        <Box mb={2}>
          <Heading packageDetails={packageDetails} />
        </Box>

        {packageType === 'PREPAID' &&
        packageDetails.sharedWith &&
        packageDetails.sharedWith.length ? (
          <Box className={classes.bodyContainer} mb={2}>
            <Body packageDetails={packageDetails} />
          </Box>
        ) : null}

        <Box mb={2}>
          <Footer config={config} />
        </Box>
      </Paper>
    </>
  );
};
