import * as Yup from 'yup';
import { getPackageOrSubtypeNameValidationSchema } from '../../../../../../utils/ValidationSchema';

export default Yup.object().shape({
  companyName: Yup.string().when('isCompany', {
    is: false,
    then: Yup.string().required('Please enter Company name')
  }),
  packageName: Yup.string().when('isCompany', {
    is: true,
    then: getPackageOrSubtypeNameValidationSchema({ type: 'Package', isRequired: true })
  }),
  rechargeDetails: Yup.object().shape({
    costPerCandidate: Yup.number()
      .integer('Please enter a valid cost')
      .min(1, `Cost can't be 0`)
      .required('Please enter a valid cost')
  })
});
