export default {
  identity: {
    any: 0,
    config: []
  },
  address: {
    any: 0,
    config: [],
    verification_type: ''
  },
  court: {
    any: 0,
    config: [],
    verification_type: ''
  },
  employment: 0,
  educationOfficial: {
    count: 0,
    config: [],
    type: '',
    method: ''
  },
  educationHybrid: {
    count: 0,
    config: [],
    type: '',
    method: ''
  },
  educationRegionalPartner: {
    count: 0,
    config: [],
    type: '',
    method: ''
  },
  education: {
    count: 0,
    config: [],
    type: '',
    method: ''
  },
  educationType: '',
  reference: {
    count: 0,
    config: []
  },
  world: false,
  cibil: false,
  drug10Panel: false,
  drug5Panel: false,
  drug7Panel: false
};
