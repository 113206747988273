// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FajSSIF8SmXhcjFow4W3 {\n  flex-grow: 1;\n}\n\n.Pms_Ln37gjGvXHAQG1sJ {\n  background: #222 !important;\n  box-shadow: none;\n}\n\n.ysnvVRPEGcVpLKW39dbM {\n  position: relative;\n  color: #fff;\n  flex-grow: 1;\n  cursor: pointer;\n}\n@media (max-width: 960px) {\n  .ysnvVRPEGcVpLKW39dbM {\n    font-size: 14px;\n  }\n}\n\n.rA5x6b6MqFgcIq6r6BLh {\n  color: #fff;\n  position: relative;\n  background-color: fade(#fff, 0.15);\n  width: 100%;\n}\n.rA5x6b6MqFgcIq6r6BLh:hover {\n  background-color: fade(#fff, 0.25);\n}\n@media (min-width: 600px) {\n  .rA5x6b6MqFgcIq6r6BLh {\n    margin-left: 8px;\n    margin-right: 8px;\n    width: auto;\n  }\n}\n\n.Kt4HJhGQt7WYctJ2JjAz {\n  display: none;\n}\n@media (min-width: 1662px) {\n  .Kt4HJhGQt7WYctJ2JjAz {\n    display: flex;\n  }\n}\n\n.BDRqHbBBhI8uI4ph_xHI {\n  display: flex;\n}\n@media (min-width: 1662px) {\n  .BDRqHbBBhI8uI4ph_xHI {\n    display: none;\n  }\n}\n\n.S6quneEo4DtFpJ8j3LiQ {\n  top: 65px;\n}\n\n.ChLO9N36JJZUWSTfrCEG {\n  top: 65px !important;\n}\n\n.KNCzWRikrqd2lxKj2OHb {\n  background: white;\n  color: #000;\n  max-height: 400px;\n  width: auto;\n  position: absolute;\n  overflow: auto;\n  border-radius: 5px;\n  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.81);\n  left: 90px;\n}\n\n.duWOTJAkbUWYbdsOH7ER {\n  white-space: pre;\n}\n\n.kSyFZynYhwEuBrNA58gc {\n  position: absolute;\n  top: -14px;\n}\n@media (max-width: 600px) {\n  .kSyFZynYhwEuBrNA58gc {\n    position: unset;\n  }\n}\n\n._SYO3KpH3m5HskAwexGM {\n  font-weight: bold;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grow": "FajSSIF8SmXhcjFow4W3",
	"customAppbar": "Pms_Ln37gjGvXHAQG1sJ",
	"customTypoContainer": "ysnvVRPEGcVpLKW39dbM",
	"search": "rA5x6b6MqFgcIq6r6BLh",
	"sectionDesktop": "Kt4HJhGQt7WYctJ2JjAz",
	"sectionMobile": "BDRqHbBBhI8uI4ph_xHI",
	"paperAnchorTop": "S6quneEo4DtFpJ8j3LiQ",
	"drawerModal": "ChLO9N36JJZUWSTfrCEG",
	"customSearchContainer": "KNCzWRikrqd2lxKj2OHb",
	"searchTypo": "duWOTJAkbUWYbdsOH7ER",
	"customTypo": "kSyFZynYhwEuBrNA58gc",
	"fontBold": "_SYO3KpH3m5HskAwexGM"
};
export default ___CSS_LOADER_EXPORT___;
