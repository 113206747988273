import React from 'react';
import { Button } from 'react-bootstrap';

import BrandPage from '../../frontend-common/AlumniVerification/BrandSettingsPage/BrandPage';
import { fileUpload as fileUploadAPI } from '../../../../api/admin';

const BrandSettings = (props) => {
  return (
    <BrandPage
      Button={Button}
      fileUploadAPI={fileUploadAPI}
      isSa={true}
      brandSettings={props.brandSettings}
      getBrandSettings={props.getBrandSettings}
      editBrandSettings={props.editBrandSettings}
      companyId={props?.companyId}
    />
  );
};

export default BrandSettings;
