import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import Button from './Button';
import { IoIosWarning } from 'react-icons/io';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  title: {
    fontWeight: '600'
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h5'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}));
export default function ConfirmationPackage(props) {
  const classes = useStyles();

  const handleClose = () => {
    props.handleClose();
  };

  const handleSubmit = () => {
    props.handleSubmit();
    props.handleClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='popup-title'
      open={props.open}
      className={classes.root}
      fullWidth={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
    >
      <DialogTitle id='popup-title' onClose={handleClose}></DialogTitle>
      <DialogContent>
        <div
          style={{ fontWeight: '700', display: 'flex', alignItems: 'center', marginBottom: '16px' }}
        >
          <IoIosWarning style={{ marginRight: '8px', color: '#E97070', fontSize: '28px' }} />
          {props.title}
        </div>
        <div>{props.content}</div>
      </DialogContent>
      <DialogActions>
        <Button
          color={'primary'}
          variant={'outlined'}
          label={props.buttonCancelLabel || 'Go Back'}
          onClick={props.handleCancel ? props.handleCancel : handleClose}
        />
        <Button
          disabled={props.disabled}
          color={props.buttonColor}
          label={props.buttonLabel}
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
}
ConfirmationPackage.defaultProps = {
  open: false,
  title: '',
  content: '',
  handleSubmit: null,
  handleClose: null,
  buttonLabel: '',
  disabled: false,
  buttonColor: 'primary' //secondary for Red button
};
