import React, { useEffect, useState, useRef } from 'react';
import { isEmpty } from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import Identity from '../../../../../../../core-components/Packages/ConfigureChecks/Identity';
import Address from '../../../../../../../core-components/Packages/ConfigureChecks/Address';
import Employment from '../../../../../../../core-components/Packages/ConfigureChecks/Employment';
import Education from '../../../../../../../core-components/Packages/ConfigureChecks/Education';
import Reference from '../../../../../../../core-components/Packages/ConfigureChecks/Reference';
import CreditWorld from '../../../../../../../core-components/Packages/ConfigureChecks/CreditWorld';
import Drug from '../../../../../../../core-components/Packages/ConfigureChecks/Drug';
import Custom from '../../../../../../../core-components/Packages/ConfigureChecks/Custom';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex'
  },
  paper: {
    padding: theme.spacing(4),
    margin: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  formControl: {
    marginBottom: theme.spacing(3)
  },
  activeBtn: {
    margin: theme.spacing(1),
    height: '44px',
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  disabledBtn: {
    cursor: 'not-allowed',
    margin: theme.spacing(1),
    height: '44px',
    color: '#dfdfdf',
    borderColor: '#d9d9d9',
    backgroundColor: '#f5f5f5',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  saveButtonGrid: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  customCheckButton: {
    fontSize: 12,
    padding: '0px 0px'
  },
  progressContainer: {
    textAlign: 'center',
    width: '100%'
  },
  backBtn: {
    margin: theme.spacing(1),
    height: '44px',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2
  },
  errorMsg: {
    marginTop: -'20px',
    marginBottom: '24px',
    marginLeft: '20px'
  }
}));

export default (props) => {
  const classes = useStyles();

  const {
    values,
    config,
    handleConfigChange,
    handleSourceChange,
    educationError,
    referenceError,
    isUnassigned,
  } = props;

  return (
    <>
      {/* Identity Check */}

      {config.identity > 0 ? (
        <Identity
          limit={config.identity}
          values={values.identity}
          identityChange={(name, value) => {
            handleConfigChange('identity', name, value);
          }}
          isAadhaarAllowed={props.isAadharInIdCheck}
          isUnassigned={isUnassigned}
        />
      ) : null}

      {/* Address Check */}
      {config.address > 0 ? (
        <Address
          limit={config.address}
          values={values.address}
          addressChange={(name, value) => {
            handleConfigChange('address', name, value);
          }}
          isUnassigned={isUnassigned}
        />
      ) : null}

      {/* Court Check */}
      {config.court > 0 ? (
        <Address
          limit={config.court}
          isCourt={true}
          values={values.court}
          addressChange={(name, value) => {
            handleConfigChange('court', name, value);
          }}
          isUnassigned={isUnassigned}
        />
      ) : null}

      {/* Employment Check */}
      {config.employment > 0 ? (
        <Employment value={values.employment} isUnassigned={isUnassigned} />
      ) : null}

      {/* Education Check */}
      {config.education > 0 ? (
        <Education
          limit={config.education}
          values={values.education}
          educationChange={(name, value) => {
            handleConfigChange('education', name, value);
          }}
          sourceChange={(name, value) => {
            handleSourceChange('education', name, value);
          }}
          isUnassigned={isUnassigned}
        />
      ) : null}
      {educationError ? (
        <Typography
          className={classes.errorMsg}
          variant='caption'
          display='block'
          gutterBottom
          color='error'
        >{`Please select ${config.education} options as per subtype config`}</Typography>
      ) : null}

      {/* Reference Check */}
      {config.reference > 0 ? (
        <Reference
          limit={config.reference}
          values={values.reference}
          empCount={values.employment}
          referenceChange={(name, value) => {
            handleConfigChange('reference', name, value);
          }}
          isUnassigned={isUnassigned}
        />
      ) : null}
      {referenceError ? (
        <Typography
          className={classes.errorMsg}
          variant='caption'
          display='block'
          gutterBottom
          color='error'
        >{`Please select ${config.reference} references as per subtype config`}</Typography>
      ) : null}

      {/* World Check */}

      {config.world > 0 ? (
        <CreditWorld value={values.world} isWorld={true} isUnassigned={isUnassigned} />
      ) : null}

      {/* Credit Check */}
      {config.cibil || config.credit > 0 ? (
        <CreditWorld value={values.cibil} isWorld={false} isUnassigned={isUnassigned} />
      ) : null}

      {/* Drug Check */}
      {config.drug && config.drug > 0 ? (
        <Drug
          value={values.drug}
          drugChange={(value) => {
            handleConfigChange('drug', null, value);
          }}
          isUnassigned={isUnassigned}
        />
      ) : null}
      {values?.custom?.length > 0 && <Custom value={values.custom} isUnassigned={isUnassigned} />}
    </>
  );
};
