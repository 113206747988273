import React, { useEffect, useState } from 'react';
import { forEach, isEmpty, has, debounce } from 'lodash';

import { Typography, makeStyles } from '@material-ui/core';
import { Box, Grid } from '@material-ui/core';

import Table from '../../../../core-components/Table';
import SearchInput from '../../../../core-components/SearchInput';
import Button from '../../../../core-components/Button';
import BreadCrumbs from '../../../../core-components/BreadCrumbs';
import AppConstants from '../../../../core-components/AppConstants';
import {
  getCompanyNameHyperlink,
  getCreditInRupee,
  convertToIndianNumeration
} from '../../../../utils/Utlities';

const useStyles = makeStyles({
  rupeeSymbol: {
    fontFamily: 'Roboto'
  },
  costContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
});

//this is for each row the heading creation  with whatever id for headcells
function createData(
  companyName,
  creditType,
  creditUsedCurrentMonth,
  creditUsedLastMonth,
  creditTotalOutstanding,
  actions
) {
  return {
    companyName,
    creditType,
    creditUsedCurrentMonth,
    creditUsedLastMonth,
    creditTotalOutstanding,
    actions
  };
}

const headCells = [
  {
    id: 'companyName',
    label: 'Company Name',
    align: 'center'
  },
  {
    id: 'creditType',
    label: 'Credit Type',
    align: 'center'
  },
  {
    id: 'creditUsedCurrentMonth',
    label: 'Credit used (current month)',
    align: 'center'
  },
  {
    id: 'creditUsedLastMonth',
    label: 'Credit used (last month)',
    align: 'center'
  },
  {
    id: 'creditTotalOutstanding',
    label: 'Credit balance (total outstanding)',
    align: 'center'
  },
  {
    id: 'actions',
    label: 'Actions',
    align: 'center'
  }
];

export default function CreditBalance(props) {
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [companyName, setCompanyName] = useState('');

  const classes = useStyles();

  const breadCrumbsArray = [{ label: 'Home', href: `${AppConstants.baseURL}dashboard` }];

  useEffect(() => {
    setLoading(true);
    props.getPostpaidCreditCompanies(rowsPerPage, offset, companyName).then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (props?.list && has(props.list, 'loading') && !props?.list?.loading) {
      let rowsData =
        props.list &&
        props.list.all[offset] &&
        props.list.all[offset].data &&
        props.list.all[offset].data.length &&
        getRowData(props.list.all[offset].data);
      setRows(rowsData || []);
      setTotalCount(props.list.all[offset] ? props.list.all[offset].count || 0 : 0);
    }
  }, [props.list]);

  const getRowData = (values) => {
    let creditRow = [];
    forEach(values, function (value, key) {
      let creditUsedLastMonth = 0;
      let creditTotalOutstanding = 0;
      let creditUsedCurrentMonth = 0;

      if (value?.creditUsedLastMonth) {
        creditUsedLastMonth = (
          <div className={classes.costContainer}>
            {value?.creditUsedLastMonth < 0 ? '-' : ''}
            <span className={classes.rupeeSymbol}>&nbsp;&#8377;</span>
            <span>
              {convertToIndianNumeration(
                parseInt(
                  value?.creditUsedLastMonth < 0
                    ? value?.creditUsedLastMonth * -1
                    : value?.creditUsedLastMonth
                )
              )}
            </span>
          </div>
        );
      }

      if (value?.creditTotalOutstanding) {
        creditTotalOutstanding = (
          <div className={classes.costContainer}>
            {value?.creditTotalOutstanding < 0 ? '-' : ''}
            <span className={classes.rupeeSymbol}>&nbsp;&#8377;</span>
            <span>
              {convertToIndianNumeration(
                parseInt(
                  value?.creditTotalOutstanding < 0
                    ? value?.creditTotalOutstanding * -1
                    : value?.creditTotalOutstanding
                )
              )}
            </span>
          </div>
        );
      }
      if (value?.creditUsedCurrentMonth) {
        creditUsedCurrentMonth = (
          <div className={classes.costContainer}>
            {value?.creditUsedCurrentMonth < 0 ? '-' : ''}
            <span className={classes.rupeeSymbol}>&nbsp;&#8377;</span>
            <span>
              {convertToIndianNumeration(
                parseInt(
                  value?.creditUsedCurrentMonth < 0
                    ? value?.creditUsedCurrentMonth * -1
                    : value?.creditUsedCurrentMonth
                )
              )}
            </span>
          </div>
        );
      }

      if (!isEmpty(value)) {
        let rowData = createData(
          getCompanyNameHyperlink(value.companyName, value.companyId),
          value.creditType || '-',
          getCreditInRupee(value.creditUsedCurrentMonth, '-'),
          getCreditInRupee(value.creditUsedLastMonth, '-'),
          getCreditInRupee(value.creditTotalOutstanding, '-'),
          viewButton(value)
        );
        creditRow.push(rowData);
      }
    });
    return creditRow;
  };

  const viewButton = (value) => {
    return (
      <Button
        label={'View'}
        color={'green'}
        onClick={() => {
          props.history.push({
            pathname: '/credits/balance/company',
            search: `?id=${value.companyId}&type=POSTPAID`,
            state: {
              details: value || null
            }
          });
        }}
      />
    );
  };

  //these are filters
  const getCompanyFilter = () => {
    return (
      <SearchInput
        placeholder={'Search Company'}
        handleSearch={(companyName) => {
          applyCompanyFilter(companyName?.trim());
        }}
      />
    );
  };

  const getPostpaidCreditCompanies = (rowsPerPage, offset, companyName) => {
    setLoading(true);
    props
      .getPostpaidCreditCompanies(rowsPerPage, offset, companyName)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const applyCompanyFilter = debounce((companyName) => {
    setCompanyName(companyName);
    setOffset(0);
    getPostpaidCreditCompanies(rowsPerPage, 0, companyName);
  }, 500);

  const handleChangePage = (newPage) => {
    setOffset(newPage);
    setLoading(true);
    getPostpaidCreditCompanies(rowsPerPage, newPage, companyName);
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setOffset(0);
    setLoading(true);
    getPostpaidCreditCompanies(parseInt(value, 10), 0, companyName);
  };

  return (
    <>
      <BreadCrumbs linksArray={breadCrumbsArray} current={'Credits'} />
      <Typography variant='h4' component='h3'>
        Credit Balance
      </Typography>
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item lg={4} md={6} sm={4} xs={10}>
            {getCompanyFilter()}
          </Grid>
        </Grid>
      </Box>
      <Table
        headCells={headCells}
        rowData={rows}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={offset}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        loading={loading}
      />
    </>
  );
}
