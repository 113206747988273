import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith } from 'lodash';
import { toast } from 'react-toastify';
import { setPassword as setPasswordApi } from '../../api/admin';
import { errToastMessage, getErrorMessage } from '../../utils/Utlities';
import validationSchema from './InvitePage.validation';

export default compose(
  connect(null, null),
  withFormik({
    mapPropsToValues: (_props) => {
      let storedValues = {
        password: '',
        confirmPassword: '',
        uuid: '',
        passwordResetToken: ''
      };
      let propsValue = {};
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (values, { setSubmitting, resetForm, props }) => {
      setPasswordApi(values)
        .then(() => {
          setSubmitting(false);
          resetForm();
          props.history.push('/login');
          toast.info('Password is set Successfully');
        })
        .catch((error) => errToastMessage(error));
    },
    displayName: 'Set Password'
  })
);
