import React, { useState } from 'react';
import { has } from 'lodash';
import PropTypes from 'prop-types';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  IconButton,
  Table,
  TablePagination,
  TableSortLabel
} from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import ContentLoader from 'react-content-loader';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';

const usePaginationStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const classes = usePaginationStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;
  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

const StyledTableCell = withStyles(() => ({
  root: {
    borderBottom: 'none',
    fontSize: 14,
    color: '#333333',
    padding: '10px 12px'
  },
  head: {
    backgroundColor: '#F2F8FF',
    fontWeight: 600
  },
  body: {}
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#FAFCFF'
    },
    '&:hover': {
      backgroundColor: '#F7F8FA'
    }
  }
}))(TableRow);

const useStyles = makeStyles({
  table: {
    margin: '32px auto',
    minWidth: 700,
    border: '1px solid rgba(0, 0, 0, 0.05)',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
    borderRadius: 4
  },
  leftCol: {
    paddingLeft: '16px'
  },
  paginationToolbar: {
    paddingLeft: '16px !important',
    paddingRight: '16px !important'
  },
  paginationSpacer: {
    flex: 'unset'
  },
  visuallyHidden: {
    border: 0,
    // clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    // overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 10,
    width: 1,
    left: '5px',
    fontSize: 'smaller',
    fontWeight: '100'
  },
  noRecords: {
    textAlign: 'center'
  },
  icon: {
    verticalAlign: 'middle'
  }
});

export default function CustomizedTables(props) {
  const classes = useStyles();
  const [sort, setSort] = useState(false);
  const rowsPerPage = props.rowsPerPage;
  const page = props.page;
  const noContentText = 'No Records Found';

  const handleChangePage = (event, newPage) => {
    props.handleChangePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.handleChangeRowsPerPage(event.target.value);
  };
  const sorthandler = (event) => {
    setSort(!sort);
    props.sortHandler(!sort);
  };

  return (
    <TableContainer size='small'>
      <Table className={classes.table} size='small' aria-label='customized table'>
        <TableHead>
          <TableRow>
            {props.headCells.map((header, index) => (
              <StyledTableCell
                key={header.id}
                align={header.align}
                className={index === 0 ? classes.leftCol : ''}
              >
                {header.label}
                {has(header, 'isSort') && (
                  <>
                    <TableSortLabel
                      active={true}
                      direction={sort ? 'desc' : 'asc'}
                      onClick={sorthandler}
                    >
                      <span className={classes.visuallyHidden}>{sort ? 'desc' : 'asc'}</span>
                    </TableSortLabel>
                  </>
                )}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.loading
            ? Array.apply(null, { length: rowsPerPage })
                .map(Number.call, Number)
                .map((data, index) => {
                  return (
                    <StyledTableRow key={index}>
                      {props.headCells.map((cell, index1) => (
                        <StyledTableCell
                          key={index1}
                          align={cell.align}
                          className={index1 === 0 ? classes.leftCol : ''}
                        >
                          <ContentLoader height={20} width={50}>
                            <rect x='0' y='0' height={20} width={50} />
                          </ContentLoader>
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  );
                })
            : null}
          {/* data row */}
          {!props.loading && props.rowData.length
            ? props.rowData.map((row, index) => (
                <StyledTableRow key={index}>
                  {props.headCells.map((cell, index) => (
                    <StyledTableCell
                      key={index}
                      align={cell.align}
                      className={index === 0 ? classes.leftCol : ''}
                    >
                      {row[cell.id]}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))
            : null}
          {!props.loading && props.rowData.length === 0 ? (
            <StyledTableRow>
              <StyledTableCell colSpan={props.headCells.length} className={classes.noRecords}>
                <Box mt={6} mb={6}>
                  <b>
                    {props?.noContentText ? props.noContentText : noContentText}{' '}
                    <SentimentVeryDissatisfiedIcon className={classes.icon} />
                  </b>
                </Box>
              </StyledTableCell>
            </StyledTableRow>
          ) : null}
        </TableBody>
        <TableFooter>
          <TableRow>
            {!props.noPagination && (
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, 100]}
                colSpan={props.headCells.length}
                count={props.totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true
                }}
                classes={{
                  toolbar: classes.paginationToolbar,
                  spacer: classes.paginationSpacer
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            )}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

CustomizedTables.defaultProps = {
  headCells: [],
  rowData: [],
  totalCount: 0,
  rowsPerPage: 10,
  page: 0,
  handleChangePage: null,
  handleChangeRowsPerPage: null
};
