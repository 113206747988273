import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, has } from 'lodash';
import { toast } from 'react-toastify';

import validationSchema from './UpdateBalance.validation';

import { updateCompanyCreditBalance as updateCompanyCreditBalanceAPI } from '../../../../../../api/admin';
import {
  getCompanyRechargeLogs as getCompanyRechargeLogsAPI,
  getPostpaidCreditCompany as getPostpaidCreditCompanyAPI
} from '../../../../../../actions/admin';

import { errToastMessage, getErrorMessage } from '../../../../../../utils/Utlities';

const mapStateToProps = (state) => {
  return {
    companyCreditBalance: state?.postpaidCreditCompany?.creditTotalOutstanding
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyCreditBalance: (companyId) => dispatch(getPostpaidCreditCompanyAPI(companyId)),
    getCompanyRechargeLogs: (companyId, limit, offset) =>
      dispatch(getCompanyRechargeLogsAPI(companyId, limit, offset))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        tag: '',
        companyId: '',
        amount: ''
      };

      let propsValue = {};

      if (has(props, 'companyId')) {
        propsValue.companyId = props.companyId;
      } else {
        propsValue = {};
      }

      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    validationSchema,
    enableReinitialize: true,
    handleSubmit: (values, { setFieldError, setSubmitting, resetForm, props }) => {
      values.amount = parseInt(values.amount);

      updateCompanyCreditBalanceAPI(values)
        .then(() => {
          setSubmitting(false);
          toast.info('Credits updated successfully');
          //update balance
          props.getCompanyCreditBalance(props.companyId);
          resetForm();
          props.handleClose();
        })
        .catch((error) => {
          console.log(error);
          errToastMessage(error);
        });
    },
    displayName: 'Update Balance'
  })
);
