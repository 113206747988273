import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  TextField,
  FormControl,
  FormGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox
} from '@material-ui/core';
import { FormLabel } from 'react-bootstrap';
import { PLAIN_CHECK_ICONS, EDUCATION_TYPES_FOR_PACKAGE_CONFIG } from '../../CommonConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  formControl: {
    margin: theme.spacing(3)
  },
  required: {
    color: '#db3131'
  }
}));

export default function Education(props) {
  const classes = useStyles();
  const { values, limit, educationChange, sourceChange, isUnassigned } = props;

  const handleTypeChange = (value) => {
    if (value === 'SPECIFIC') {
      educationChange('config', []);
    } else if (value === 'CHRONOLOGICAL') {
      educationChange('config', limit);
    } else {
      educationChange('config', 0);
    }
    educationChange('type', value);
  };
  const handleSourceChange = (value) => {
    sourceChange('source', value);
  };

  const handleConfigChange = (event, name) => {
    let dummy = values.config ? [...values.config] : [];
    if (event.target.checked) {
      if (!dummy.includes(name)) {
        dummy.push(name);
        educationChange('config', dummy);
      }
    } else {
      dummy.splice(dummy.indexOf(name), 1);
      educationChange('config', dummy);
    }
  };

  const hasValue = (name) => {
    return values.config ? values.config.includes(name) : false;
  };

  const isDisabled = () => {
    return values.config ? values.config.length === parseInt(limit) : false;
  };

  return (
    <>
      <div className={classes.root}>
        <Grid className={classes.root} item container>
          <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
            <Typography variant='h6' style={{ fontSize: '18px' }} gutterBottom>
              <span style={{ marginRight: '10px' }} title={'education'}>
                <FontAwesomeIcon
                  icon={
                    PLAIN_CHECK_ICONS['education'] ? PLAIN_CHECK_ICONS['education'].icon : faBan
                  }
                  color='#222'
                />
              </span>
              {'Education'}
              {!limit && parseInt(limit) ? (
                <span style={{ marginRight: '10px', fontSize: '12px', color: '#312c51' }}>
                  <i>{` (*Not Selected)`}</i>
                </span>
              ) : null}
            </Typography>
          </Grid>
          <Grid
            item
            container
            spacing={1}
            xl={12}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            style={{ marginTop: '12px', marginBottom: '24px' }}
          >
            <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
              <FormControl component='fieldset' disabled={limit === 0 || isUnassigned}>
                <FormLabel>Choose education type 1: <span className={classes.required}>*</span></FormLabel>
                <RadioGroup
                  row
                  aria-label='type'
                  name='type'
                  value={values.type}
                  onChange={(e) => {
                    handleTypeChange(e.target.value);
                  }}
                  disabled={isUnassigned}
                >
                  <FormControlLabel
                    value='CHRONOLOGICAL'
                    control={<Radio color='primary' />}
                    label='Chronological'
                    labelPlacement='end'
                  />
                  <FormControlLabel
                    value='SPECIFIC'
                    control={<Radio color='primary' />}
                    label='Specific'
                    labelPlacement='end'
                  />
                  <FormControlLabel
                    value='NONE'
                    control={<Radio color='primary' />}
                    label='None'
                    labelPlacement='end'
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
              <FormControl component='fieldset' disabled={limit === 0 || isUnassigned}>
                <FormLabel component="legend">Choose verification type: <span className={classes.required}>*</span></FormLabel>
                <RadioGroup
                  row
                  aria-label='type'
                  name='source'
                  value={values.source}
                  onChange={(e) => {
                    handleSourceChange(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value='OFFICIAL'
                    control={<Radio color='primary' />}
                    label='Official'
                    labelPlacement='end'
                  />
                  <FormControlLabel
                    value='REGIONAL_PARTNER'
                    control={<Radio color='primary' />}
                    label='Regional'
                    labelPlacement='end'
                  />
                  <FormControlLabel
                    value='HYBRID'
                    control={<Radio color='primary' />}
                    label='Hybrid'
                    labelPlacement='end'
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {values.type !== '' ? values.type === 'CHRONOLOGICAL' || values.type === 'NONE' ? (
              <>
                <Grid
                  item
                  xl={1}
                  md={1}
                  lg={1}
                  sm={1}
                  xs={1}
                  style={{ display: 'flex', flexFlow: 'row-reverse' }}
                >
                  <Typography variant='subtitle2' gutterBottom>
                    {'Highest:'}
                  </Typography>
                </Grid>
                <Grid item xl={1} md={1} lg={1} sm={1} xs={1}>
                  <TextField
                    // id='standard-read-only-input'
                    size={'small'}
                    // label='Last'
                    InputProps={{
                      readOnly: true
                    }}
                    variant='filled'
                    value={values.count}
                    disabled
                  />
                </Grid>
              </>
            ) : (
              <FormControl component='fieldset'>
                {/* <FormLabel component='legend'>Label Placement</FormLabel> */}
                <FormGroup aria-label='position' row>
                  {EDUCATION_TYPES_FOR_PACKAGE_CONFIG.map((value, index) => {
                    return (
                      <FormControlLabel
                        key={`Package_Configure_${index}`}
                        control={
                          <Checkbox
                            disabled={(!hasValue(value.name) && isDisabled()) || isUnassigned}
                            checked={hasValue(value.name)}
                            onChange={(e) => handleConfigChange(e, value.name)}
                            name={value.name}
                          />
                        }
                        label={value.label}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            ) : null}
          </Grid>
        </Grid>
      </div>
    </>
  );
}

Education.defaultProps = {
  sourceChange: () => { }
};
