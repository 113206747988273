import React, { useState, useEffect } from 'react';
import { Form, Row } from 'react-bootstrap';
import { Dialog, Button, MenuItem, Menu, Select } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import styles from './PendingOrders.module.scss';
import FormControl from '@material-ui/core/FormControl';
import { cancelOrder as cancelOrderAPI } from '../../../../../api/admin';
import { toast } from 'react-toastify';
import { errToastMessage, getErrorMessage } from '../../../../../utils/Utlities';

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    paddingTop: 2
  }
}))(MuiDialogActions);

export default (props) => {
  const { open, handleClose } = props;
  const [caList, setCAList] = useState([{ value: -1, label: 'None' }]);
  const [selectedCA, setSelectedCA] = useState(-1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    const filteredCAList =
      props.caList &&
      props.caList.map((e) => {
        return { value: e.user.id, label: e.user.name };
      });
    setCAList([...caList, ...filteredCAList]);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const payload = {
      orderId: props.orderId,
      caUserId: selectedCA,
      companyId: props.companyId
    };
    cancelOrderAPI(payload)
      .then(() => {
        toast.success('Order cancelled');
        handleClose(1);
      })
      .catch((e) => {
        setIsSubmitting(false);
        errToastMessage(e);
      });
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      aria-labelledby='customized-dialog-title'
      onClose={handleClose}
    >
      <div className={styles.modalContainer}>
        <MuiDialogTitle closeButton>Cancel Order?</MuiDialogTitle>
        <Form onSubmit={handleSubmit}>
          <MuiDialogContent>
            <FormControl variant='outlined' className={styles.formControl}>
              <div className={styles.container}>
                <div className={styles.cancelText}>
                  {'Are you sure you want to cancel the order?'}
                </div>
                <div className={styles.dropdownContainer}>
                  <p className={styles.dropdownContainerLabel}>Cancellation requested by*</p>

                  <Row>
                    <div className={styles.dropdown}>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left'
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left'
                          },
                          getContentAnchorEl: null
                        }}
                        value={selectedCA}
                        onChange={(e) => {
                          setSelectedCA(e.target.value);
                          setIsSubmitting(false);
                        }}
                        variant='outlined'
                        className={styles.dropdownSelect}
                        options={caList}
                      >
                        {caList.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                  </Row>
                </div>
              </div>
            </FormControl>
          </MuiDialogContent>
          <DialogActions>
            <Button className={styles.backButton} variant='outlined' onClick={() => handleClose(0)}>
              Close
            </Button>

            <Button
              className={selectedCA == -1 || isSubmitting ? styles.disabledBtn : styles.activeBtn}
              disabled={selectedCA == -1 || isSubmitting ? true : false}
              variant='contained'
              type='submit'
              onClick={handleSubmit}
            >
              Cancel Order
            </Button>
          </DialogActions>
        </Form>
      </div>
    </Dialog>
  );
};
