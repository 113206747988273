import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { mergeWith } from 'lodash';
import { getCompanyById, invalidateCompanyDetail, setCompanyDetail } from '../../../../actions/admin';
import AppConstants from '../../../../core-components/AppConstants';
const mapStateToProps = (state) => {
  return { updateCompanyData: state.updateCompanyData };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyById: (companyId) => dispatch(getCompanyById(companyId)),
    invalidateCompanyDetail: () => dispatch(invalidateCompanyDetail()),
    setCompanyDetails: () => dispatch(setCompanyDetail())
  }
}
const companyId = new URLSearchParams(document.location.search).get('id') || false;
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = { gstin: '', zohoId: '', tds: 'NONE' };
      let propsValue = {
        gstin: props.updateCompanyData.gstin,
        zohoId: props.updateCompanyData.zohoBooksContactId,
        tds: props.updateCompanyData.tds
      };
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    handleSubmit: (values, { props }) => {
      window.open(
        `${AppConstants.baseURL}company/addonConfiguration?create=true&id=${companyId}&package_type=${props.updateCompanyData.paymentMode === 'PREPAID' ? 'PREPAID' : 'POSTPAID'}`,
        '_self'
      );
    },
  })
);
