import React from 'react';

import moment from 'moment';
import MomentUtils from '@date-io/moment';
import EventIcon from '@material-ui/icons/Event';
import { makeStyles } from '@material-ui/core/styles';
import { InputAdornment, Button, TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { INPUT_VALUE_DATEFORMAT } from './CommonConstants';

const useStyles = makeStyles((theme) => ({
  changeButton: {
    height: '14px',
    fontWeight: '200',
    fontSize: '11px',
    display: 'flex',
    paddingTop: '11px'
  }
}));

export default (props) => {
  const classes = useStyles();
  return (
    <>
      {props.isTextField ? (
        <>
          <TextField
            size={'small'}
            id={props.name}
            name={props.name}
            variant='outlined'
            fullWidth={true}
            label={props.label}
            placeholder={props.format}
            disabled={props.disabled || false}
            helperText={props.errMessage ? props.errMessage : null}
            error={props.errMessage ? true : false}
            value={
              props.value
                ? moment.isMoment(props.value)
                  ? moment(props.value).format(INPUT_VALUE_DATEFORMAT)
                  : props.value
                : ''
            }
            onChange={(e) => {
              props.onChange(e.target.value);
            }}
            onBlur={(e) => {
              if (props.isTextField) {
                props.onBlur && props.onBlur(e);
              }
            }}
          />

          {props.textChange ? (
            <Button
              className={classes.changeButton}
              onClick={props.textChange}
              disabled={props.disabled}
              color='primary'
            >
              Change to Date Field
            </Button>
          ) : null}
        </>
      ) : (
        <>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              size={'small'}
              fullWidth={true}
              views={props.views || ['year', 'month', 'date']}
              inputVariant='outlined'
              id={props.name}
              style={props.disabled ? { background: '#F5F4F4' } : null}
              name={props.name}
              minDate={props.minDate ? new Date(props.minDate) : undefined}
              maxDate={props.maxDate ? new Date(props.maxDate) : undefined}
              label={props.label}
              format={props.format}
              required={props.required}
              disablePast={props.disablePast || false}
              disableFuture={props.disableFuture ? true : false}
              disabled={props.disabled || false}
              value={
                props.value
                  ? moment.isMoment(props.value)
                    ? props.value
                    : moment(props.value)
                  : null
              }
              onChange={(value) => {
                props.onChange && props.onChange(value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <EventIcon />
                  </InputAdornment>
                )
              }}
              helperText={props.errMessage ? props.errMessage : null}
              error={props.errMessage ? true : false}
              clearable
            />
          </MuiPickersUtilsProvider>
          {props.textChange ? (
            <Button
              className={classes.changeButton}
              disabled={props.disabled}
              onClick={props.textChange}
              color='primary'
            >
              Change to Text Field
            </Button>
          ) : null}
        </>
      )}
    </>
  );
};
