import React, { useEffect, useState } from 'react';
import { forEach, isEmpty, has } from 'lodash';

import { Grid, Typography } from '@material-ui/core';

import Table from '../../../../../core-components/Table';
import Button from '../../../../../core-components/Button';
import ShowDataModal from '../../../../../core-components/ShowDataModal';
import { makeStyles } from '@material-ui/core/styles';

import { convertToIndianNumeration } from '../../../../../utils/Utlities';

//this is for each row the heading creation  with whatever id for headcells
function createData(month, year, candidateCount, price, status, paymentNotes) {
  return { month, year, candidateCount, price, status, paymentNotes };
}
const useStyles = makeStyles((theme) => ({
  rupeeSymbol: {
    fontFamily: 'Roboto'
  }
}));

const headCells = [
  {
    id: 'month',
    label: 'Month',
    align: 'center'
  },
  {
    id: 'year',
    label: 'Year',
    align: 'center'
  },
  {
    id: 'candidateCount',
    label: '# of candidates',
    align: 'center'
  },
  {
    id: 'price',
    label: 'Price',
    align: 'center'
  },
  {
    id: 'status',
    label: 'Status',
    align: 'center'
  },
  {
    id: 'paymentNotes',
    label: 'Payment notes',
    align: 'center'
  }
];

export default function MonthlyUsageLog(props) {
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [onlyUnpaid, setOnlyUnpaid] = useState(false);
  const classes = useStyles();

  // for notes modal
  const [notes, setNotes] = useState([]);
  const [notesModal, setNotesModal] = useState(false);

  // 1st call is in parrent component
  useEffect(() => {
    setLoading(true);
    getMonthlyUsage(rowsPerPage, offset, onlyUnpaid);
  }, [props.companyId]);

  const statuses = [
    { label: 'Paid', value: 'PAID' },
    { label: 'Partially Due', value: 'PARTIAL' },
    { label: 'Due', value: 'DUE' }
  ];

  useEffect(() => {
    setLoading(true);
    if (
      props?.postpaidCreditDetailsMonthlyUsage &&
      has(props.postpaidCreditDetailsMonthlyUsage, 'loading') &&
      !props?.postpaidCreditDetailsMonthlyUsage?.loading
    ) {
      let rowsData =
        props?.postpaidCreditDetailsMonthlyUsage?.data &&
        props?.postpaidCreditDetailsMonthlyUsage?.data?.length
          ? getRowData(props.postpaidCreditDetailsMonthlyUsage.data)
          : [];
      setLoading(false);
      setRows(rowsData);
      setTotalCount(props.postpaidCreditDetailsMonthlyUsage.count);
    }
  }, [props.postpaidCreditDetailsMonthlyUsage]);

  const getMonthlyUsage = (rowsPerPage, offset, isUnpaid) => {
    setLoading(true);
    props
      .getPostpaidCreditDetailsMonthlyUsage(props.companyId, rowsPerPage, offset, isUnpaid)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const getRowData = (values) => {
    let data = [];
    forEach(values, function (value) {
      if (!isEmpty(value)) {
        let rowData = createData(
          value.month,
          value.year || '-',
          value.candidatesAdded,
          <>
            <span className={classes.rupeeSymbol}>&nbsp;₹</span>
            {convertToIndianNumeration(value?.amount) || '-'}
          </>,
          value.paymentStatus,
          viewButton(value.paymentNotes)
        );
        data.push(rowData);
      }
    });
    return data;
  };

  const viewButton = (paymentnotes) => {
    return paymentnotes && paymentnotes.length > 0 ? (
      <Button
        label={'View'}
        color={'green'}
        onClick={() => {
          let notesArray = !Array.isArray(paymentnotes) ? [] : paymentnotes;
          !Array.isArray(paymentnotes) ? notesArray.push(paymentnotes) : null;

          var notesValue = [];
          notesArray.forEach((value) => {
            if (value.paymentNotes || value.paymentReferenceNumber)
              notesValue.push(
                <div>
                  {value.paymentNotes ? `${value.paymentNotes}.` : ''} Reference Number:{' '}
                  {value.paymentReferenceNumber || ''}
                </div>
              );
          });
          setNotes(notesValue);
          setNotesModal(true);
        }}
      />
    ) : (
      '-'
    );
  };

  const handleChangePage = (newPage) => {
    setOffset(newPage);
    getMonthlyUsage(rowsPerPage, newPage, onlyUnpaid);
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setOffset(0);
    getMonthlyUsage(parseInt(value, 10), 0, onlyUnpaid);
  };

  const isUnpaid = (e) => {
    setOnlyUnpaid(e.target.checked);
    getMonthlyUsage(rowsPerPage, 0, e.target.checked);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item container md={12}>
          <Grid item md={3} style={{ alignItems: 'center', display: 'flex' }}>
            <Typography variant='subtitle2'>
              Total outstanding:
              {props?.postpaidCreditDetailsMonthlyUsage?.totalOutstandingAmount ? (
                <span className={classes.rupeeSymbol}>&nbsp;₹</span>
              ) : (
                ' '
              )}
              {convertToIndianNumeration(
                props?.postpaidCreditDetailsMonthlyUsage?.totalOutstandingAmount
              ) || 'NIL'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Table
            headCells={headCells}
            rowData={rows}
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            page={offset}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            loading={loading}
          />
        </Grid>
      </Grid>
      <ShowDataModal
        open={notesModal}
        title={notes.length ? 'View Notes' : 'No Payments Notes'}
        data={notes}
        handleClose={() => setNotesModal(false)}
      />
    </>
  );
}
