import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { Grid, Paper, Typography, Box, Button, InputAdornment, IconButton, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import FormInput from '../../../../core-components/FormInput';
import AppHelper from '../../../../core-components/AppHelper';
import { accessCookie, jwtParser } from '../../../../utils/Utlities';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        margin: theme.spacing(2, 0, 2, 0),
        color: theme.palette.text.secondary,
    },
    activeBtn: {
        color: '#ffffff',
        borderColor: '#388e3c',
        backgroundColor: '#43a047',
        fontSize: 14,
        textTransform: 'inherit',
        '&:hover': {
            backgroundColor: '#388e3c',
        },
    },
    disabledBtn: {
        color: '#dfdfdf',
        borderColor: '#d9d9d9',
        backgroundColor: '#f5f5f5',
        fontSize: 14,
        textTransform: 'inherit',
        '&:hover': {
            backgroundColor: '#f5f5f5',
        },
    },
    errorMsg: {
        fontSize: '0.75rem',
        color: '#f44336',
        fontWeight: '400',
        lineHeight: '1.66',
        margin: 0,
        marginTop: theme.spacing(-4),
        marginBottom: theme.spacing(3),
    },
    required: {
        color: '#db3131',
    },
    customFormLabel: {
        marginBottom: 10,
        marginTop: 10,
    },
    customImgWidth: {
        maxWidth: 180,
    },
    eye: {
        cursor: 'pointer',
    },
}));

export default (props) => {
    const classes = useStyles();
    const [passwordExistingIsMasked, setPasswordExistingIsMasked] = useState(true);
    const [passwordIsMasked, setPasswordIsMasked] = useState(true);
    const [passwordConfirmedIsMasked, setPasswordConfirmedIsMasked] = useState(true);
    // const [showHelperText, setShowHelperText] = useState(props.values.isExistingPasswordError);

    const fetchProfile = () => {
        const hostName = AppHelper.getEnv();
        const cookieName = hostName !== 'prod' ? `sa_node_auth_token_${hostName}` : 'sa_node_auth_token';
        const token = accessCookie(cookieName); //fetching token
        let profile = jwtParser(token); //fetching data from jwt token
        profile = (profile && profile.data && profile.data.email) || null;
        return profile;
    };

    useEffect(() => {
        props.setFieldValue('email', fetchProfile());
    }, []);

    useEffect(() => {
        props.setFieldValue('email', fetchProfile());
    }, [props.values.email]);

    const hasError = () => {
        if (values.existingPassword && values.password && values.confirmPassword) {
            return false;
        }
        return true;
    };

    const togglePasswordMask = (type) => {
        if (type === 'existing') {
            setPasswordExistingIsMasked(!passwordExistingIsMasked);
        }
        if (type === 'password') {
            setPasswordIsMasked(!passwordIsMasked);
        }
        if (type === 'confirmPassword') {
            setPasswordConfirmedIsMasked(!passwordConfirmedIsMasked);
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setPasswordExistingIsMasked(true);
        setPasswordIsMasked(true);
        setPasswordConfirmedIsMasked(true);
        props.handleSubmit();
    };

    const { isSubmitting, values, errors, handleSubmit } = props;

    const isValidForm = !isSubmitting && isEmpty(errors) && !hasError();
    // console.log('renders', isValidForm, errors, values);

    return (
        <Box mt={3} mb={3}>
            <Grid container direction='row'>
                <Grid item lg={2} md={2} sm={2} xs={2}></Grid>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                    <form onSubmit={handleFormSubmit}>
                        <Typography variant={'h6'}>Change Password</Typography>
                        <Paper variant='outlined' elevation={5} className={classes.paper}>
                            <Field
                                type={passwordExistingIsMasked ? 'password' : 'text'}
                                name='existingPassword'
                                label='Existing Password'
                                component={FormInput}
                                requiredfield={true}
                                error={values.isExistingPasswordError}
                                endAdornment={
                                    <InputAdornment position='end'>
                                        <IconButton
                                            onClick={() => togglePasswordMask('existing')}
                                            size='small'
                                        >
                                            {passwordExistingIsMasked ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <Field
                                type={passwordIsMasked ? 'password' : 'text'}
                                name='password'
                                label='Password'
                                component={FormInput}
                                requiredfield={true}
                                endAdornment={
                                    <InputAdornment position='end'>
                                        <IconButton onClick={() => togglePasswordMask('password')} size='small'>
                                            {passwordIsMasked ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <Field
                                type={passwordConfirmedIsMasked ? 'password' : 'text'}
                                name='confirmPassword'
                                label='Confirm Password'
                                component={FormInput}
                                requiredfield={true}
                                endAdornment={
                                    <InputAdornment position='end'>
                                        <IconButton onClick={() => togglePasswordMask('confirmPassword')} size='small'>
                                            {passwordConfirmedIsMasked ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </Paper>
                        <Button
                            variant='contained'
                            type='submit'
                            className={isValidForm ? classes.activeBtn : classes.disabledBtn}
                            disabled={!isValidForm}
                        >
                            Change Password
                        </Button>
                    </form>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2}></Grid>
            </Grid>
        </Box>
    );
};
