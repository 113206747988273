import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  getPostpaidCreditDetailsMonthlyUsage as getPostpaidCreditDetailsMonthlyUsageAPI,
  invalidatePostpaidCreditDetailsMonthlyUsage
} from '../../../../../actions/admin';

const mapStateToProps = (state) => {
  return {
    postpaidCreditDetailsMonthlyUsage: state.postpaidCreditDetailsMonthlyUsage || null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPostpaidCreditDetailsMonthlyUsage: (companyId, limit, offset) =>
      dispatch(getPostpaidCreditDetailsMonthlyUsageAPI(companyId, limit, offset)),
    invalidatePostpaidMonthlyUsage: () => dispatch(invalidatePostpaidCreditDetailsMonthlyUsage())
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
