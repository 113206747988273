import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Field, ErrorMessage, isEmptyArray } from 'formik';

import Button from '../../../../core-components/Button';
import FormInput from '../../../../core-components/FormInput';
import Dropdown from '../../../../core-components/DropdownInput';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, title, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{title} Admin</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default (props) => {
  const {
    handleSubmit,
    isSubmitting,
    handleBlur,
    values,
    errors,
    setFieldValue,
    setFieldError,
    setTouched,
    touched,
    setFieldTouched,
    setErrors
  } = props;

  const handleDropdown = (value) => {
    setFieldValue('status', value);
  };

  return (
    <div>
      <Dialog
        onClose={props.handleClose}
        aria-labelledby='customized-dialog-title'
        open={props.open}
      >
        <DialogTitle
          id='customized-dialog-title'
          title={props.title}
          onClose={props.handleClose}
        ></DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Field
              type='text'
              name='name'
              fullWidth={false}
              requiredfield={'true'}
              label=' Name'
              component={FormInput}
            />
            <Field
              type='email'
              name='email'
              label='Email'
              fullWidth={false}
              requiredfield={'true'}
              component={FormInput}
            />
            <Field
              type='text'
              name='mobile'
              label='Mobile'
              fullWidth={false}
              requiredfield={'true'}
              component={FormInput}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color={'green'} label={'Save'} size={'large'}></Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
