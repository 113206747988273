import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  getAdmins as getAdminsAPI,
  forceLogoutAdmin as forceLogoutAdminAPI,
  updateAdminStatus as updateAdminStatusAPI
} from '../../../actions/admin';

const mapStateToProps = (state) => {
  return {
    adminData: state.admins
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAdmins: (limit, offset, includeCompanyAdmins, name, email, accessLevel, status) =>
      dispatch(getAdminsAPI(limit, offset, includeCompanyAdmins, name, email, accessLevel, status)),
    forceLogoutAdmin: (adminData) => dispatch(forceLogoutAdminAPI(adminData)),
    updateAdminStatus: (data) => dispatch(updateAdminStatusAPI(data))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
