import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import { Typography, Button, Grid, TextField, Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import SearchInput from '../../../../../../core-components/SearchInput';
import { getPackageUnassignedCompany, getPackageList } from '../../../../../../api/admin';
import { errToastMessage, getErrorMessage, toCamelCase } from '../../../../../../utils/Utlities';
import { debounce } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex'
  },
  paper: {
    padding: theme.spacing(4),
    margin: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  formControl: {
    marginBottom: theme.spacing(3)
  },
  activeBtn: {
    height: '44px',
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    lineHeight: 1,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  disabledBtn: {
    cursor: 'not-allowed',
    height: '44px',
    color: '#dfdfdf',
    borderColor: '#d9d9d9',
    backgroundColor: '#f5f5f5',
    fontSize: 14,
    lineHeight: 1,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  booleanField: {
    marginLeft: '22px'
  },
  saveButtonGrid: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  customCheckButton: {
    fontSize: 12,
    padding: '0px 0px'
  },
  progressContainer: {
    textAlign: 'center',
    width: '100%'
  },
  mandatoryStar: {
    color: '#db3131'
  },
  boxStyle: {
    border: '1px solid',
    width: '70%'
  },
  unitContainer: {
    padding: '20px 16px 20px 16px !important',
    border: '1px solid',
    borderRight: 'unset',
    marginBottom: '8px'
  },
  packageListContainer: {
    maxHeight: '400px',
    overflowY: 'scroll',
    marginBottom: '16px',
    boxShadow: '0px 2px 11px #cccccc'
  }
}));

function AssignCompanyStep1(props) {
  const classes = useStyles();
  const limit = 20;
  const [packageId, setPackageId] = useState(
    new URLSearchParams(document.location.search).get('id') || null
  );
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [packageName, setPackageName] = useState('');
  const [packages, setPackages] = useState([]);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (props.savedValues) {
      setValue(props.savedValues);
      if (props.parent === 'COMPANY') {
        setPackageName(props.savedValues.packageName);
      }
    }
  }, []);

  useEffect(() => {
    if (inputValue && inputValue.trim() && inputValue.length >= 3) {
      setLoading(true);
      getPackageUnassignedCompany(offset, 0, packageId, inputValue.trim())
        .then((response) => {
          setLoading(false);
          const data = toCamelCase(response.data);
          setOptions(data.data);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
          errToastMessage(error);
        });
    } else {
      setOptions([]);
    }
  }, [inputValue]);

  useEffect(() => {
    if (offset > 0) {
      getPackagesApiCall(packageName, limit, offset);
    }
  }, [offset]);

  const handleStep1 = () => {
    props.handleStepSubmit('step1', value, 2);
  };

  const getPackageName = () => {
    return (
      <SearchInput
        placeholder={'Search Package Name'}
        handleSearch={debounce((packageName) => {
          applyPackageName(packageName?.trim());
        }, 500)}
      />
    );
  };

  const applyPackageName = (name) => {
    setPackageName(name);
    setOffset(0);
    setPackages([]);
    if (name && name.length > 3) {
      getPackagesApiCall(name, limit, 0);
    }
  };

  const getPackagesApiCall = (name, limit, offset) => {
    const status = 1;
    getPackageList(limit, offset, name, null, status, props.packageType)
      .then((response) => {
        setCount(response.data.data.count);
        const data = toCamelCase(response.data);
        if (packageName === name) {
          setPackages([...packages, ...data.data.packageList]);
        } else {
          setPackages(data.data.packageList);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getPackage = () => {
    return packages.map((p, index) => {
      return getPackageDetails(p, index);
    });
  };

  const getPackageDetails = (packageData, index) => {
    return (
      <Box
        component='div'
        mt={1}
        mb={1}
        key={index}
        onClick={() => {
          setValue(packageData);
          setPackageName(packageData.packageName);
        }}
      >
        <Paper elevation={3} style={{ padding: '10px', cursor: 'pointer' }}>
          <Typography variant={'subtitle1'} align='left'>
            {packageData.packageName}
          </Typography>
          <Typography variant={'caption'} align='left' gutterBottom>
            {packageData.subtypes} {'Subtype available |'} {packageData.companies.length}
            {' companies assigned'}
          </Typography>
          {packageData.checksCount && packageData.checksCount.length ? (
            <Typography variant={'subtitle1'} align='left' gutterBottom>
              {'Checks:'}
              {Object.keys(packageData.checksCount[0]).map((check) => {
                return `${check} ( ${packageData.checksCount[0][check]} ) `;
              })}
            </Typography>
          ) : null}
        </Paper>
      </Box>
    );
  };

  const isValidForm = value ? true : false;

  const scrollHandler = (e) => {
    const bottom =
      Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 1;
    if (bottom && packages.length < count) {
      setOffset(offset + 1);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {props.parent === 'COMPANY' ? (
          <>
            <Grid item lg={9} md={9} sm={9} xs={12}>
              {getPackageName()}
            </Grid>
            {packages && packages.length ? (
              <Grid
                item
                lg={9}
                md={9}
                sm={9}
                xs={12}
                className={classes.packageListContainer}
                onScroll={scrollHandler}
              >
                {getPackage()}
              </Grid>
            ) : null}
          </>
        ) : (
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Autocomplete
              id='companyId'
              size={'small'}
              name={'companyId'}
              fullWidth
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
              getOptionSelected={(option, value) => {
                if (JSON.stringify(option) === JSON.stringify(value)) {
                  return option;
                } else {
                  return value;
                }
              }}
              loading={loading}
              filterOptions={(x) => x}
              options={options}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={value}
              onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue || null);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  setInputValue(event.target.value);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <>
                      {'Select Company'}
                      <span className={classes.mandatoryStar}>*</span>
                    </>
                  }
                  variant='outlined'
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color='inherit' size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
              renderOption={(option) => {
                return (
                  <Grid container alignItems='center'>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography variant='body2' color='textSecondary'>
                        {option.name}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }}
            />
          </Grid>
        )}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Button
            variant='contained'
            onClick={() => handleStep1()}
            className={isValidForm ? classes.activeBtn : classes.disabledBtn}
            disabled={!isValidForm}
            endIcon={<ArrowForwardIosIcon />}
          >
            {'Save & Next'}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default AssignCompanyStep1;
