/* eslint-disable no-unused-expressions */
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withFormik } from 'formik';
import { mergeWith } from 'lodash';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {};
      if (props.parent === 'COMPANY') {
        if (props?.pConfig && props?.pConfig?.length) {
          props?.pConfig.map((config) => {
            storedValues[config.id] = {
              identity: {
                any: config.configCount.identity || 0,
                config: []
              },
              address: {
                any: config.configCount.address === 2 ? 0 : config.configCount.address || 0, //It wont set Address to ANY_2
                config: config.configCount.address === 2 ? [{ type: 'PERMANENT', verificationType: '' }, { type: 'CURRENT', verificationType: '' }] : [],
                verificationType: null,
              },
              court: {
                any: config.configCount.court === 2 ? 0 : config.configCount.court || 0,
                config: config.configCount.court === 2 ? [{ type: 'PERMANENT', verificationType: '' }, { type: 'CURRENT', verificationType: '' }] : [],
                verificationType: null,
              },
              employment: config.configCount.employment || 0,
              education: {
                type: '',
                count: config.configCount.education || 0,
                source: '',
                config: []
              },
              reference: {
                type: config.configCount.reference > 0 ? 'TOTAL' : 'NONE',
                count: config.configCount.reference || 0,
                config: []
              },
              world: config.configCount.world === 1 ? true : false,
              cibil: config.configCount.cibil === 1 ? true : false,
              drug: config.configCount.drug === 1 ? '' : false
            };
          });
        }
      } else {
        if (props?.pConfig && props?.pConfig?.length) {
          props?.pConfig.map((config) => {
            storedValues[config.id] = {
              identity: {
                any: config.configCount.identity || 0,
                config: []
              },
              address: {
                any: config.configCount.address === 2 ? 0 : config.configCount.address || 0, //It wont set Address to ANY_2
                config: config.configCount.address === 2 ? [{ type: 'PERMANENT', verificationType: '' }, { type: 'CURRENT', verificationType: '' }] : [],
                verificationType: null,
              },
              court: {
                any: config.configCount.court === 2 ? 0 : config.configCount.court || 0,
                config: config.configCount.court === 2 ? [{ type: 'PERMANENT', verificationType: '' }, { type: 'CURRENT', verificationType: '' }] : [],
                verificationType: null,
              },
              employment: config.configCount.employment || 0,
              education: {
                type: '',
                count: config.configCount.education || 0,
                source: '',
                config: []
              },
              reference: {
                type: config.configCount.reference > 0 ? 'TOTAL' : 'NONE',
                count: config.configCount.reference || 0,
                config: []
              },
              world: config.configCount.world === 1 ? true : false,
              cibil: config.configCount.cibil === 1 ? true : false,
              drug: config.configCount.drug === 1 ? '' : false
            };
          });
        }
      }

      let propsValue = {};

      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    handleSubmit: (values, { setFieldError, setStatus, setSubmitting, resetForm, props }) => {
      let finalData = { ...values };
      const assigned = props.savedValues.step2.packageText.filter((val) => val.isAssigned);
      let assignedTemp = {};
      for (const key in assigned) {
        assignedTemp = {
          ...assignedTemp,
          [assigned[key].subtypeId]: finalData[assigned[key].subtypeId]
        };
      }
      finalData = { ...assignedTemp };
      setSubmitting(false);
      props.handleStepSubmit('step3', finalData, 'submit');
    },
    displayName: 'Step3'
  })
);
