// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".E_THhXd4fulqsimJRCXW {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(91, 90, 90, 0.5);\n  z-index: 100;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.zrRmaFfzqMUNcLf3bLgQ {\n  width: 680px;\n  height: 341px;\n  background-color: rgb(255, 255, 255);\n  border: 1px solid rgba(0, 0, 0, 0.2);\n  z-index: 1000;\n  border-radius: 5px;\n  font-family: \"Poppins\", sans-serif;\n  padding: 24px 32px;\n  position: relative;\n}\n\n.ZRYlTbTVtDrkEqSqr5c5 {\n  position: absolute;\n  top: 10px;\n  right: 20px;\n  cursor: pointer;\n  font-size: 25px;\n}\n\n.Wdt4xtK_w3IJTveePvYn {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 12px;\n}\n\n.djEcAB3qAKbbvc4HUJgk {\n  font-size: 20px;\n  font-weight: 600;\n  line-height: 32px;\n  color: #333333;\n}\n\n.k6Uen8oecU_wmj02sUEQ {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 32px;\n  margin-top: 36px;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 21px;\n  color: #333333;\n}\n\n.oG4Yk7pIPvYR63UAPKIM {\n  width: 240px;\n  height: 196px;\n  border-radius: 4px;\n  border: 1px solid rgba(153, 153, 153, 0.4);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n}\n\n.oG4Yk7pIPvYR63UAPKIM:hover {\n  border: 2px solid #000000;\n  background-color: rgba(208, 208, 208, 0.2156862745);\n}\n\n.NJV3Eq3Cd2rAd4JgZDXD {\n  font-size: 20px;\n  font-weight: 500;\n}\n\n.AFTuw0bdkMmq0HjERmMq {\n  height: 100px;\n}\n\n.AFTuw0bdkMmq0HjERmMq img {\n  height: 80%;\n  object-fit: contain;\n}\n\n.t6LWorGJGlcCcv01bF1I {\n  color: #333333;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 24px;\n  text-align: left;\n  margin-bottom: 5px;\n}\n\n.OuZzHXrv7Fc7lZrRVewQ {\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 20px;\n  text-align: left;\n  color: #666666;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-overlay": "E_THhXd4fulqsimJRCXW",
	"modalContainer": "zrRmaFfzqMUNcLf3bLgQ",
	"close": "ZRYlTbTVtDrkEqSqr5c5",
	"header": "Wdt4xtK_w3IJTveePvYn",
	"title": "djEcAB3qAKbbvc4HUJgk",
	"body": "k6Uen8oecU_wmj02sUEQ",
	"box": "oG4Yk7pIPvYR63UAPKIM",
	"orText": "NJV3Eq3Cd2rAd4JgZDXD",
	"boxIcon": "AFTuw0bdkMmq0HjERmMq",
	"boxText": "t6LWorGJGlcCcv01bF1I",
	"boxSubText": "OuZzHXrv7Fc7lZrRVewQ"
};
export default ___CSS_LOADER_EXPORT___;
