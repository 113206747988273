import React, { useState, useEffect, useRef } from 'react';
import { isEmpty, isEqual } from 'lodash';

import {
  Grid,
  Typography,
  Box,
  Paper,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  FormHelperText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { toast } from 'react-toastify';

import BreadCrumbs from '../../../core-components/BreadCrumbs';
import AppConstants from '../../../core-components/AppConstants';
import { getCompanyConfigCreateCompany as getCompanyConfigCreateCompanyAPI } from '../../../api/admin';

import AssignPackage from '../Packages/Prepaid/AssignCompanyToPackage';
import ConfirmationPopup from '../../../core-components/ConfirmationPopup';

import {
  getErrorMessage,
  toCamelCase,
  isInt,
  validDocTypes,
  errToastMessage
} from '../../../utils/Utlities';
import PostpaidCreditLimitModal from './PostpaidCreditLimit';
import CompanyDetails from '../UtilsComponents/CompanyDetails';
import BgvFormConfig from '../UtilsComponents/BgvFormConfig';
import CreateCompanyInviteEmail from '../UtilsComponents/CreateCompanyInviteEmail';
import ReferAndEarnConfig from '../UtilsComponents/ReferAndEarnConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex'
  },
  rootTab: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0, 2, 0),
    color: theme.palette.text.secondary
  },
  card: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  activeBtn: {
    width: '240px',
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    lineHeight: 1,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  cancelBtn: {
    width: '200px',
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#ff0000',
    marginLeft: '20px',
    fontSize: 14,
    lineHeight: 1,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#ff0000'
    }
  },
  disabledBtn: {
    width: '240px',
    color: '#dfdfdf',
    borderColor: '#d9d9d9',
    backgroundColor: '#f5f5f5',
    fontSize: 14,
    lineHeight: 1,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  required: {
    color: '#db3131'
  },
  customImgWidth: {
    maxWidth: 180
  },
  iconElement: {
    marginTop: '10px',
    cursor: 'pointer'
  },
  error: {
    color: '#f44336',
    margin: 0,
    fontSize: '0.75rem',
    fontWeight: 400
  }
}));

export default (props) => {
  const classes = useStyles();
  const [companyBgvConfig, setCompanyBgvConfig] = useState({});
  const [defaultIdentityType, setDefaultIdentityType] = useState([]);
  const [openAssignPackagesModal, setOpenAssignPackagesModal] = useState(false);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [creditLimitModal, setCreditLimitModal] = useState(false);
  const [validConsentFormat, setValidConsentFormat] = useState(false);
  const [consentUploaded, setIsConsentUploaded] = useState(false);
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const [validNameConfig, setValidNameConfig] = useState(true);
  const [isInsuffReminderCaseConditionsSatisfied, setIsInsuffReminderCaseConditionsSatisfied] =
    useState(0);
  const { isSubmitting, values, errors, setFieldValue, dirty } = props;
  const initialValuesRef = useRef(values);

  let creditTotalValuation = true;

  const breadCrumbsArray = [
    { label: 'Home', href: `${AppConstants.baseURL}dashboard` },
    { label: 'Companies', href: `${AppConstants.baseURL}companies` }
  ];

  const paymentModeOptions = [
    { label: 'Prepaid Package', value: 'PREPAID' },
    { label: 'Postpaid Package', value: 'POSTPAID' }
    // { label: 'Postpaid Credit', value: 'POSTPAID_CREDIT' }
  ];

  const handleAssignPackagesModal = (isSubmit = false, packageToAssign = null, values = null) => {
    if (isSubmit) {
      setFieldValue('prefilledValue', values);
      setFieldValue('packageToAssign', packageToAssign);
    }
    setOpenAssignPackagesModal(!openAssignPackagesModal);
  };

  // Fetching company config
  useEffect(() => {
    props.invalidateCompanyDetail();
    getCompanyConfigCreateCompanyAPI()
      .then((response) => {
        setCompanyBgvConfig(toCamelCase(response.data.data));
        setFieldValue('hrNote', response.data.data.bgv_email_config.hr_invite_note || '');
        setFieldValue('defaultHrNote', response.data.data.bgv_email_config.hr_invite_note || '');
        setFieldValue(
          'defaultCustomHrNote',
          response.data.data.bgv_email_config.hr_invite_note || ''
        );
        setFieldValue('hrWarningNote', response.data.data.bgv_email_config.hr_warning_note || '');
        setFieldValue(
          'defaultHrWarningNote',
          response.data.data.bgv_email_config.hr_warning_note || ''
        );
        setFieldValue(
          'defaultCustomHrWarningNote',
          response.data.data.bgv_email_config.hr_warning_note || ''
        );
      })
      .catch((error) => {
        console.error(error);
        console.log('error', error);
        errToastMessage(error);
      });
  }, []);

  useEffect(() => {
    if (
      companyBgvConfig.identityVerification &&
      companyBgvConfig.identityVerification.items.length
    ) {
      props?.values?.includeAadhaarInIdCheck == 1
        ? setDefaultIdentityType([...defaultIdentityType, { label: 'Aadhaar', value: 6 }])
        : setDefaultIdentityType([...companyBgvConfig.identityVerification.items]);
    }
  }, [props.values.includeAadhaarInIdCheck]);

  useEffect(() => {
    if (isSubmitting) {
      initialValuesRef.current = values;
    }
  }, [isSubmitting, values]);

  useEffect(() => {
    if (!isEqual(initialValuesRef.current, values)) {
      setSaveButtonClicked(false);
    }
  }, [initialValuesRef.current, values]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setSaveButtonClicked(true);
    props.handleSubmit();
  };

  const handleCreditLimitModal = () => {
    if (!isInt(values.postpaidCreditLimit)) {
      setFieldValue('creditLimit', null);
    } else {
      setFieldValue('creditLimit', values.postpaidCreditLimit);
    }
    setFieldValue('unlimitedCredit', values.postpaidUnlimitedCredit);
    setCreditLimitModal(!creditLimitModal);
  };

  const handleCloseCreditLimit = () => {
    setCreditLimitModal(!creditLimitModal);
  };

  // Handle edit of package selected while creating company
  const handleEdit = () => {
    setOpenAssignPackagesModal(true);
  };

  // Handle delete of package selected while creating company
  const handleDelete = () => {
    setFieldValue('prefilledValue', null);
    setFieldValue('packageToAssign', null);
  };

  if (values.paymentMode === 'POSTPAID') {
    if (parseInt(values.creditLimit) > 0 || values.unlimitedCredit === true) {
      creditTotalValuation = true;
    } else {
      creditTotalValuation = false;
    }
  }

  let isValidForm =
    !isSubmitting &&
    isEmpty(errors) &&
    !values.uploadedLogoError &&
    (values.consentType === 'UNDERTAKING_LETTER'
      ? values.consentUndertakingLetterUrl && values.consentUndertakingLetterUrl !== ''
      : true) &&
    !values.isUploading &&
    creditTotalValuation &&
    !saveButtonClicked &&
    isInsuffReminderCaseConditionsSatisfied;

  if (consentUploaded) {
    isValidForm &&= validConsentFormat;
  }

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  return (
    <>
      <BreadCrumbs linksArray={breadCrumbsArray} current={'Create Company'} />
      <Grid container direction='row'>
        <Grid item lg={2} md={2} sm={1} xs={1}></Grid>
        <Grid item lg={8} md={8} sm={10} xs={10}>
          <form onSubmit={handleFormSubmit} onKeyDown={onKeyDown}>
            <Typography variant={'h6'}>{'Create Company'}</Typography>
            <CompanyDetails
              title='Create Company'
              values={values}
              errors={errors}
              setFieldValue={setFieldValue}
              handleBlur={props.handleBlur}
            />
            <Paper className={classes.paper} variant='outlined' elevation={5}>
              <Typography variant={'h6'}>{'Payment Mode'}</Typography>
              <FormControl
                component='fieldset'
                fullWidth
                className={classes.formControl}
                error={errors.discloseInfo ? true : false}
                style={{ marginBottom: '16px' }}
              >
                <RadioGroup
                  row
                  aria-label='paymentMode'
                  name='paymentMode'
                  value={values.paymentMode}
                  onChange={(e) => {
                    setFieldValue('paymentMode', e.target.value);
                  }}
                >
                  {paymentModeOptions.map((item, index) => {
                    return (
                      <FormControlLabel
                        key={`payment_radio_${index}_${item.value}`}
                        value={item.value}
                        control={<Radio color='primary' />}
                        label={item.label}
                      />
                    );
                  })}
                </RadioGroup>
                {errors.paymentMode && (
                  <FormHelperText id='payment-mode-helper-text' error>
                    {errors.paymentMode}
                  </FormHelperText>
                )}
              </FormControl>

              {/* If postpaid then need to select mode of payment too  */}
              {values.paymentMode === 'POSTPAID' ? (
                <>
                  <Paper className={classes.paper} variant='outlined' elevation={5}>
                    <Grid container spacing={3}>
                      <Grid item xs={6} md={5} sm={6} lg={5}>
                        Credit limit (total) <span className={classes.required}>*</span> :{' '}
                        {values.unlimitedCredit ? 'Unlimited' : values.creditLimit}
                      </Grid>
                      <Grid item xs={6} md={7} sm={6} lg={7}>
                        <EditIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => setCreditLimitModal(!creditLimitModal)}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </>
              ) : null}

              {/* Once package is selected  if it exists in then TO DO : add edit and delete functionality  */}
              {values.packageToAssign ? (
                <Grid container spacing={3} style={{ marginBottom: '8px' }}>
                  <Grid item xs={8} md={8} sm={8} lg={8}>
                    <Paper className={classes.card}>
                      {values.packageToAssign?.packageName}{' '}
                      {` - ${values.packageToAssign?.subtypes} subtypes ${
                        values.paymentMode === 'PREPAID'
                          ? `-  ${values.packageToAssign?.rechargeDetails.length} Recharges configured `
                          : ''
                      }`}
                    </Paper>
                  </Grid>
                  <Grid item xs={1} md={1} sm={1} lg={1}>
                    <EditIcon className={classes.iconElement} onClick={handleEdit} />
                  </Grid>
                  <Grid item xs={1} md={1} sm={1} lg={1}>
                    <DeleteIcon
                      className={classes.iconElement}
                      onClick={() => setDisplayConfirmation(true)}
                    />
                  </Grid>
                </Grid>
              ) : null}

              <Button color='primary' onClick={handleAssignPackagesModal} style={{ padding: 0 }}>
                Assign Packages
              </Button>
            </Paper>
            <BgvFormConfig
              values={values}
              setIsInsuffReminderCaseConditionsSatisfied={
                setIsInsuffReminderCaseConditionsSatisfied
              }
              validNameConfig={validNameConfig}
              setValidNameConfig={setValidNameConfig}
              setFieldValue={setFieldValue}
              errors={errors}
              setValidConsentFormat={setValidConsentFormat}
              setIsConsentUploaded={setIsConsentUploaded}
              accept={validDocTypes.consent}
              isCreateCompanyFlow={true}
              countOfAdmins={0}
            />
            <CreateCompanyInviteEmail
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
            />
            <ReferAndEarnConfig values={values} setFieldValue={setFieldValue} errors={errors} />
            <Box>
              <Button
                variant='contained'
                size='large'
                type='submit'
                className={isValidForm ? classes.activeBtn : classes.disabledBtn}
                disabled={!isValidForm || saveButtonClicked}
                startIcon={<SaveIcon />}
              >
                {'Save & Next'}
              </Button>
              <Button
                variant='contained'
                size='large'
                className={classes.cancelBtn}
                onClick={() => {
                  window.open(`${AppConstants.baseURL}companies`, '_self');
                }}
                startIcon={<CancelOutlinedIcon />}
              >
                {'Close'}
              </Button>
            </Box>
          </form>
        </Grid>
        <Grid item lg={2} md={2} sm={1} xs={1}></Grid>
      </Grid>

      {openAssignPackagesModal ? (
        <AssignPackage
          open={openAssignPackagesModal}
          handleClose={handleAssignPackagesModal}
          packageType={values.paymentMode}
          title={'Create Company'}
          isAadhaarAllowed={values.includeAadhaarInIdCheck === '1' ? true : false}
          prefilledValue={values.prefilledValue}
        />
      ) : null}

      {creditLimitModal ? (
        <PostpaidCreditLimitModal
          values={values}
          setFieldValue={setFieldValue}
          open={creditLimitModal}
          handleModalSubmit={handleCreditLimitModal}
          handleClose={handleCloseCreditLimit}
          dirty={dirty}
        />
      ) : null}

      {displayConfirmation ? (
        <ConfirmationPopup
          open={displayConfirmation}
          parent={'Delete package'}
          content={'Are you sure you want to delete this selected package ?'}
          handleSubmit={() => {
            handleDelete();
          }}
          handleClose={() => {
            setDisplayConfirmation(false);
          }}
          buttonLabel={'Save'}
        />
      ) : null}
    </>
  );
};
