import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, TextField } from '@material-ui/core';
import { PLAIN_CHECK_ICONS } from '../../CommonConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  formControl: {
    margin: theme.spacing(3)
  }
}));

export default (props) => {
  const classes = useStyles();

  const {isUnassigned} = props;
  return (
    <>
      <div className={classes.root}>
        <Grid className={classes.root} item container>
          <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
            <Typography variant='h6' style={{ fontSize: '18px' }} gutterBottom>
              <span style={{ marginRight: '10px' }} title={'employment'}>
                <FontAwesomeIcon
                  icon={
                    PLAIN_CHECK_ICONS['employment'] ? PLAIN_CHECK_ICONS['employment'].icon : faBan
                  }
                  color='#222'
                />
              </span>
              {'Employment'}
              {props.value === 0 ? (
                <span style={{ marginRight: '10px', fontSize: '12px', color: '#312c51' }}>
                  <i>{` (*Not Selected)`}</i>
                </span>
              ) : null}
            </Typography>
          </Grid>
          <Grid
            item
            container
            xl={12}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            style={{ marginLeft: '20px', marginTop: '12px', marginBottom: '24px' }}
          >
            <Grid
              item
              xl={1}
              md={1}
              lg={1}
              sm={1}
              xs={1}
              // style={{ display: 'flex', flexFlow: 'row-reverse' }}
            >
              <Typography variant='subtitle2' gutterBottom>
                {`Last Emp's:`}
              </Typography>
            </Grid>
            <Grid item xl={1} md={1} lg={1} sm={1} xs={1}>
              <TextField
                id='standard-read-only-input'
                size={'small'}
                InputProps={{
                  readOnly: true
                }}
                variant='filled'
                value={props.value}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
