import { accessCookie } from '../utils/Utlities';
import AppHelper from '../core-components/AppHelper';
import cookie from 'react-cookies';

export const getToken = () => {
  const hostName = AppHelper.getEnv();
  const cookieName = hostName !== 'prod' ? `sa_node_auth_token_${hostName}` : 'sa_node_auth_token';
  let token = null;

  if (window.location.pathname === '/candidate/report/html') {
    token = new URLSearchParams(document.location.search).get('token') || null; //accessiing token from url for reports
  } else {
    token = accessCookie(cookieName); //accessiing token from cookie
  }
  return token;
};

export const getCookieValue = (cookieName) => {
  const value = cookie.load(cookieName);
  return value;
};

export const setCookieValue = (cookieName, value, days = null) => {
  let options = { path: '/', domain: window.location.hostname };
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    options.maxAge = date;
    // options.maxAge = 20;
  }
  cookie.save(cookieName, value, options);
};

export const getAuthToken = (name = null) => {
  const hostName = AppHelper.getEnv();
  const cookieName = hostName !== 'prod' ? `sa_node_auth_token_${hostName}` : 'sa_node_auth_token';
  const authToken = getCookieValue(cookieName);
  return authToken;
};

export const setAuthToken = (value, days = null) => {
  const hostName = AppHelper.getEnv();
  const cookieName = hostName !== 'prod' ? `sa_node_auth_token_${hostName}` : 'sa_node_auth_token';
  setCookieValue(cookieName, value, days);
};

export const removeAuthToken = () => {
  const hostName = AppHelper.getEnv();
  const cookieName = hostName !== 'prod' ? `sa_node_auth_token_${hostName}` : 'sa_node_auth_token';
  removeCookie(cookieName);
};

export const removeCookie = (name) => {
  cookie.remove(name, { path: '/', domain: window.location.hostname, maxAge: 0 });
};

//jwt parser to fetch candidate id in BGV
export const jwtParser = (token) => {
  try {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  } catch (e) {
    return null;
  }
};

// fetching access level of loggedin person
export const getAccessLevel = () => {
  const data = jwtParser(getToken());
  return data && data.data.accessLevel;
};
