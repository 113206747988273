import React, { useEffect, useState } from 'react';

import { getToken } from '../../../../api/storage';
import AppConstants from '../../../../core-components/AppConstants';

export default (props) => {
  //   const consentUrl = `${AppConstants.baseURL}/candidate/consent?company_id=28&id=4413`;

  const [companyId, setCompanyId] = useState(
    new URLSearchParams(document.location.search).get('company_id') || null
  );
  const [candidateId, setCandidateId] = useState(
    new URLSearchParams(document.location.search).get('id') || null
  );

  useEffect(() => {
    window.open(
      `${
        AppConstants.developmentAPIURL
      }auth/docs?company_id=${companyId}&candidate_id=${candidateId}&type=consent&token=${getToken()}`,
      '_self'
    );
  });

  return <></>;
};
