import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    marginRight: '96px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, title, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{title}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    minWidth: '500px',
    minHeight: '100px',
    padding: '0 16px 32px',
    fontFamily: 'Roboto'
  }
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  item: {
    marginBottom: '16px',
    lineHeight: '24px'
  }
}));

export default function ShowDataModal(props) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        onClose={props.handleClose}
        aria-labelledby='customized-dialog-title'
        open={props.open}
      >
        <DialogTitle
          id='customized-dialog-title'
          onClose={props.handleClose}
          title={props.title}
        ></DialogTitle>
        <DialogContent role='dialog-content'>
          {props.data.map((value, index) => (
            <div key={index} className={classes.item}>
              {value}
            </div>
          ))}
        </DialogContent>
      </Dialog>
    </div>
  );
}
ShowDataModal.defaultProps = {
  title: '', //Modal title
  data: [], //should be an array
  open: false, //boolean to show or hide modal
  handleClose: null //function which need to called after close
};
