import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, has } from 'lodash';
import { toast } from 'react-toastify';

import validationSchema from './ViewUpdateLogsModal.validation';

import { updateCreditRefundLog as updateCreditRefundLogAPI } from '../../../../../api/admin';
import {
  getCompanyCreditBalance as getCompanyCreditBalanceAPI,
  getCompanyRechargeLogs as getCompanyRechargeLogsAPI
} from '../../../../../actions/admin';

import {
  errToastMessage,
  getErrorMessage,
  getFormattedFinalValues
} from '../../../../../utils/Utlities';
import moment from 'moment';
import { INPUT_VALUE_DATEFORMAT } from '../../../../../core-components/CommonConstants';
const mapStateToProps = (state) => {
  return { companyDetails: state.updateCompanyData ? state.updateCompanyData : {} };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyCreditBalance: (companyId) => dispatch(getCompanyCreditBalanceAPI(companyId)),
    getCompanyRechargeLogs: (companyId, limit, offset) =>
      dispatch(getCompanyRechargeLogsAPI(companyId, limit, offset))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      const { data } = props;
      let storedValues = {
        tag: '',
        amount: '',
        paymentMode: '',
        paymentReference: '',
        invoiceNumber: '',
        proformaNumber: '',
        refundMode: '',
        refundReference: '',
        creditNoteNumber: '',
        additionalInfo: '',
        invoiceUrl: '',
        disabled: false,
        tax: '',
        tds: '',
        invoiceDate: '',
        paymentDate: '',
        creditNoteDate: '',
        creditNoteUrl: '',
        rechargeLogId: ''
      };

      let propsValue = {};
      propsValue.tax = data?.paymentsCompanyPrepaidInvoice?.tax || 0;
      propsValue.tds = data?.paymentsCompanyPrepaidInvoice?.tds || 0;
      propsValue.invoiceNumber = data?.paymentsCompanyPrepaidInvoice?.invoiceNumber;
      propsValue.creditNoteNumber = data?.paymentsCompanyPrepaidInvoice?.creditNoteNumber;
      propsValue.creditNoteUrl = data?.paymentsCompanyPrepaidInvoice?.invoiceUrl;
      propsValue.creditNoteDate = data?.paymentsCompanyPrepaidInvoice?.documentDate
        ? moment(data?.paymentsCompanyPrepaidInvoice?.documentDate).format('YYYY-MM-DD')
        : '';
      propsValue.refundMode = data?.paymentsCompanyPrepaidInvoice?.paymentMode;
      propsValue.refundReference = data?.paymentsCompanyPrepaidInvoice?.paymentReference;
      propsValue.refundDate = data?.paymentsCompanyPrepaidInvoice?.paymentDate
        ? moment(data?.paymentsCompanyPrepaidInvoice?.paymentDate).format('YYYY-MM-DD')
        : '';
      propsValue.paymentMode = data?.paymentsCompanyPrepaidInvoice?.paymentMode;
      propsValue.additionalInfo = data?.notes;
      propsValue.rechargeLogId = data?.paymentsCompanyPrepaidInvoice?.rechargeLogId;
      propsValue.amount =
        data?.source === 'CREDIT_REFUND' ? data?.creditsAmount * -1 : data?.creditsAmount;
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    handleSubmit: (
      values,
      { setFieldError, setSubmitting, setFieldValue, resetForm, setErrors, props }
    ) => {
      const finalPayload = {
        invoiceNumber: values?.invoiceNumber,
        creditNoteNumber: values?.creditNoteNumber,
        creditNoteUrl: values?.creditNoteUrl,
        refundMode: values?.refundMode,
        refundReference: values?.refundReference,
        paymentMode: values?.paymentMode,
        companyId: new URLSearchParams(document.location.search).get('id'),
        rechargeLogId: values?.rechargeLogId
      };

      if (values.creditNoteDate && typeof values.creditNoteDate === 'object') {
        finalPayload.creditNoteDate = values?.creditNoteDate.format(INPUT_VALUE_DATEFORMAT);
      }
      if (values.refundDate && typeof values.refundDate === 'object') {
        finalPayload.refundDate = values?.refundDate.format(INPUT_VALUE_DATEFORMAT);
      }

      const finalValues = getFormattedFinalValues(finalPayload);
      updateCreditRefundLogAPI(finalValues)
        .then(() => {
          setSubmitting(false);
          toast.info('Logs updated successfully');
          resetForm();
          props.handleSuccess();
        })
        .catch((error) => {
          setSubmitting(false);
          errToastMessage(error);
        });
    },
    displayName: 'View Update Logs Modal'
  })
);
