import React, { useState, useEffect } from 'react';
import { isEmpty, isEqual } from 'lodash';

import { Grid, Typography, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';

import BreadCrumbs from '../../../core-components/BreadCrumbs';
import AppConstants from '../../../core-components/AppConstants';
import CompanyDetails from '../UtilsComponents/CompanyDetails';
import BgvFormConfig from '../UtilsComponents/BgvFormConfig';
import CreateCompanyInviteEmail from '../UtilsComponents/CreateCompanyInviteEmail';
import { validDocTypes } from '../../../utils/Utlities';
import ReferAndEarnConfig from '../UtilsComponents/ReferAndEarnConfig';
import { getCompanyAdmin } from '../../../api/admin';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex'
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0, 2, 0),
    color: theme.palette.text.secondary
  },
  activeBtn: {
    width: '240px',
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    lineHeight: 1,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  disabledBtn: {
    width: '240px',
    color: '#dfdfdf',
    borderColor: '#d9d9d9',
    backgroundColor: '#f5f5f5',
    fontSize: 14,
    lineHeight: 1,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  }
}));

export default (props) => {
  const classes = useStyles();
  const companyId = new URLSearchParams(document.location.search).get('id') || null;
  const [validConsentFormat, setValidConsentFormat] = useState(false);
  const [validNameConfig, setValidNameConfig] = useState(true);
  const [consentUploaded, setIsConsentUploaded] = useState(false);
  const [countOfAdmins, setCountOfAdmins] = useState(0);
  const [isAtleastOneAdminSelectedForInsuffEmails, setIsAtleastOneAdminSelectedForInsuffEmails] =
    useState(false);
  const [isInsuffReminderCaseConditionsSatisfied, setIsInsuffReminderCaseConditionsSatisfied] =
    useState(0);

  const breadCrumbsArray = [
    { label: 'Home', href: `${AppConstants.baseURL}dashboard` },
    { label: 'Companies', href: `${AppConstants.baseURL}companies` },
    { label: props?.values?.name, href: `${AppConstants.baseURL}company/view?id=${companyId}` }
  ];

  //component did mount (fetching company details)
  useEffect(() => {
    props.invalidateCompanyDetail();
    props.getCompanyById(companyId);
    return () => {
      props.invalidateCompanyDetail();
    };
  }, []);

  useEffect(() => {
    getCompanyAdmin(companyId, 10, 0).then((response) => {
      setCountOfAdmins(response?.data?.data?.count);
      setIsAtleastOneAdminSelectedForInsuffEmails(
        response?.data?.data?.isInsuffEmailOpted || false
      );
    });
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit();
  };

  const [initialData, setInitialData] = useState({});
  const { isSubmitting, values, errors, setFieldValue, validateField } = props;

  useEffect(() => {
    setInitialData(values);
  }, []);

  let isValidForm =
    !isSubmitting &&
    isEmpty(errors) &&
    !values.uploadedLogoError &&
    (values.consentType === 'UNDERTAKING_LETTER'
      ? values.consentUndertakingLetterUrl && values.consentUndertakingLetterUrl !== ''
      : true) &&
    !values.isUploading &&
    (values?.reminderViaEmail === 'HR' ? isAtleastOneAdminSelectedForInsuffEmails : true) &&
    isInsuffReminderCaseConditionsSatisfied &&
    validNameConfig;

  if (consentUploaded) {
    isValidForm &&= validConsentFormat;
  }

  const getModifiedData = () => {
    const data = { ...values };
    delete data?.validConsentFormat;
    return data;
  };

  const isFormDataChanged = isEqual(initialData, getModifiedData()) ? false : true;

  return (
    <>
      <BreadCrumbs linksArray={breadCrumbsArray} current={'Update'} />
      <Grid container direction='row'>
        <Grid item lg={2} md={2} sm={2} xs={2}></Grid>
        <Grid item lg={8} md={8} sm={8} xs={8}>
          <Box mt={2}>
            <Typography variant={'h6'}>Update Company: {values?.name} </Typography>
            <form onSubmit={handleFormSubmit} encType='multipart/form-data'>
              <CompanyDetails
                title='Update Company'
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                validateField={validateField}
                handleBlur={props.handleBlur}
              />
              <BgvFormConfig
                values={values}
                setFieldValue={setFieldValue}
                setIsInsuffReminderCaseConditionsSatisfied={
                  setIsInsuffReminderCaseConditionsSatisfied
                }
                companyId={companyId}
                errors={errors}
                validNameConfig={validNameConfig}
                setValidNameConfig={setValidNameConfig}
                setValidConsentFormat={setValidConsentFormat}
                setIsConsentUploaded={setIsConsentUploaded}
                accept={validDocTypes.consent}
                setIsAtleastOneAdminSelectedForInsuffEmails={
                  setIsAtleastOneAdminSelectedForInsuffEmails
                }
                isCreateCompanyFlow={false}
                countOfAdmins={countOfAdmins}
              />
              <CreateCompanyInviteEmail
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
              />
              <ReferAndEarnConfig values={values} setFieldValue={setFieldValue} errors={errors} />
              <Button
                variant='contained'
                size='large'
                type='submit'
                className={isValidForm ? classes.activeBtn : classes.disabledBtn}
                disabled={!isValidForm || !props.dirty || !isFormDataChanged}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </form>
          </Box>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}></Grid>
      </Grid>
    </>
  );
};
