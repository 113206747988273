import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import VerificationIconDetails from '../../ViewCandidateDetails/VerificationIconDetails';

const useStyles = makeStyles({
  searchIcon: {
    background: 'darkgreen'
  },
  detailsCardContainer: {
    cursor: 'pointer',
    marginBottom: '16px',
    transition: '0.3s'
  },
  selectedCard: {
    backgroundColor: '#132743 !important',
    color: '#ffffff !important'
  },
  cardContent: {
    paddingBottom: '16px !important',
    '&:hover': {
      backgroundColor: '#f6f5f5'
    }
  },
  nameTitle: {
    fontWeight: 600
    // color: '#71716b'
  },
  searchBtn: {
    width: '100%'
  },
  filterCheckBox: {
    fontSize: '13px'
  },
  formControl: {
    width: '100%'
  },
  inputHeight: {
    height: 36
  }
});

function Step1(props) {
  const classes = useStyles();
  const [selectedCandidate, setSelectedCandidate] = useState(0);

  useEffect(() => {
    setSelectedCandidate(props.selectedCandidateIndex);
  }, [props.selectedCandidateIndex]);

  return (
    <>
      {/* Listing Cards */}
      {props?.candidates.map((insuff, index) => {
        return (
          <Card
            key={`insuff_data_${index}`}
            className={classes.detailsCardContainer}
            onClick={() => {
              setSelectedCandidate(index);
              props.handleCandidateSelected(index);
            }}
            data-testid={`insuff_data_${index}`}
          >
            <CardContent
              className={`${classes.cardContent} ${index === selectedCandidate ? classes.selectedCard : ''
                }`}
            >
              <Grid container spacing={1}>
                <Grid item md={12} xs={12} lg={12}>
                  <Typography variant='h6' gutterBottom className={classes.nameTitle}>
                    {insuff.candidate.name || ''}
                  </Typography>
                </Grid>

                <Grid item md={12} xs={12} lg={12}>
                  {Object.keys(insuff.verifications).length
                    ? Object.keys(insuff.verifications).map((check, key) =>
                      check.length ? (
                        <VerificationIconDetails
                          key={`${check}_icon_${key}`}
                          type={check}
                          details={insuff.verifications[check]}
                          handleIconClick={() => { }}
                        />
                      ) : null
                    )
                    : null}
                </Grid>

                <Grid item md={12} xs={12} lg={12}>
                  <Typography variant='caption' display='block' gutterBottom>
                    {insuff.company.name || ''}
                  </Typography>
                </Grid>

                <Grid item md={12} xs={12} lg={12}>
                  <Typography variant='subtitle2' gutterBottom>
                    {'Comments to review:'} {insuff.insuffDetails.pendingInsuff}
                  </Typography>
                </Grid>

                <Grid item container spacing={2} md={12} xs={12} lg={12}>
                  <Grid item>
                    <Typography variant='caption' display='block' gutterBottom>
                      {`Insuff tickets - ${insuff.insuffDetails.openInsuff + insuff.insuffDetails.closedInsuff
                        }`}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography variant='caption' display='block' gutterBottom>
                      {'|'}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography variant='caption' display='block' gutterBottom>
                      {`Open - ${insuff.insuffDetails.openInsuff}`}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography variant='caption' display='block' gutterBottom>
                      {'|'}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography variant='caption' display='block' gutterBottom>
                      {`Closed - ${insuff.insuffDetails.closedInsuff}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );
      })}
    </>
  );
}

export default Step1;
