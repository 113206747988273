import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import './dataEditor.css';

const DataEditor = ({ graphData, setGraphData, graphDataBackup }) => {
  const [selectedKey, setSelectedKey] = useState(Object.keys(graphData)[0]);
  const [inputValue, setInputValue] = useState(JSON.stringify(graphData[selectedKey], null, 2));
  const [isValidJSON, setIsValidJSON] = useState(true);

  const handleKeyChange = (e) => {
    const key = e.target.value;
    setSelectedKey(key);
    setInputValue(JSON.stringify(graphData[key], null, 2));
  };

  const handleTextareaChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    let parsedValue;
    try {
      parsedValue = JSON.parse(value);
      setIsValidJSON(true);
    } catch (error) {
      setIsValidJSON(false);
      return;
    }
    setGraphData({ ...graphData, [selectedKey]: parsedValue });
  };

  const handleReset = () => {
    setGraphData({
      ...graphData,
      [selectedKey]: graphDataBackup[selectedKey]
    });
    setInputValue(JSON.stringify(graphDataBackup[selectedKey], null, 2));
    setIsValidJSON(true);
  };

  return (
    <div className='data-editor'>
      <h2>
        Data Editor{' '}
        <button onClick={handleReset} className='masterUndo'>
          <FontAwesomeIcon icon={faUndo} />
        </button>
      </h2>
      <label htmlFor='selectKey'>Select Key:</label>
      <select id='selectKey' value={selectedKey} onChange={handleKeyChange}>
        {Object.keys(graphData).map((key) => (
          <option key={key} value={key}>
            {graphData[key].options.title.text}
          </option>
        ))}
      </select>
      <label htmlFor='editValue'>Edit Value:</label>
      <textarea
        id='editValue'
        className={isValidJSON ? '' : 'invalid'}
        value={inputValue}
        onChange={handleTextareaChange}
        rows={10}
      />
      {!isValidJSON && <p style={{ color: 'red' }}>Invalid JSON</p>}
    </div>
  );
};

export default DataEditor;
