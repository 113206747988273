import { createStore, applyMiddleware } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducer from '../reducers/index.js';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'candidateDetails',
    'warnings',
    'identityDataFound',
    'ocrData',
    'identityRunOcrCheck',
    'reportData',
    'customCheckDataFound',
    'assignedServices',
    'companyEmailSettings',
    'candidateInsufficiency',
    'companyBgvConfig',
    'candidates',
    'addCandidateConfig',
    'packageDetailsPackageConfig',
    'globalAddonConfiguration',
    'companyAddonConfig',
    'packageDetails',
    'companyAuditLogs',
    'employmentDataFound',
    'employmentDataProvided',
    'educationDataFound',
    'educationDataProvided',
    'addressDataFound',
    'addressDataProvided',
    'identityDataProvided',
    'courtDataProvided',
    'courtDataFound',
    'cibilDataProvided',
    'cibilDataFound',
    'drugDataProvided',
    'drugDataFound',
    'referenceDataFound',
    'referenceDataProvided',
    'worldCheckDataFound',
    'worldCheckDataProvided',
    'verificationOrderSummary',
    'postpaidCreditDetailsMonthlyUsage',
    'postpaidCreditDetailsUsageLog',
    'postpaidUnbilledCandidatesList',
    'postpaidUnbilledCandidatesFilters',
    'postpaidCompanyInvoicesList',
    'postpaidCompanyInvoicesFilters',
    'companyPendingOrders',
    'search',
    'companyAdmin'
  ]
};
const store = createStore(
  persistReducer(persistConfig, reducer),
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
