import { connect } from 'react-redux';
import { compose } from 'redux';

import { getCreditBalance as getCreditBalanceAPI } from '../../../../actions/admin';

const mapStateToProps = (state) => {
  return {
    creditBalance: state.creditBalance || null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCreditBalance: (query) => dispatch(getCreditBalanceAPI(query))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
