import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { toast } from 'react-toastify';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';

import Application from './components/Application';
import { customTheme } from './theme';
import store from './store';
import AppHelper from './core-components/AppHelper';
import googleEnviroment from './config/googleauth.json';
import { GoogleOAuthProvider } from '@react-oauth/google';

import registerServiceWorker from './registerServiceWorker';
import 'react-toastify/dist/ReactToastify.css';
import 'react-dates/lib/css/_datepicker.css';
// Call it once in your app. At the root of your app is the best place
toast.configure({
  autoClose: 8000,
  draggable: false,
  position: toast.POSITION.BOTTOM_CENTER
});

const theme = createMuiTheme({
  ...customTheme
});

if (AppHelper.getEnv() !== 'local' && AppHelper.getEnv() !== 'dev') {
  Sentry.init({
    dsn: 'https://beefc8afb13947d9b827a9428d839b4c@o271082.ingest.sentry.io/5423125',
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      `JWT failed to verify`,
      `Request failed with status code 401`,
      'ChunkLoadError',
      `Network request failed`,
      `Failed to fetch`,
      `NetworkError`,
      `Cannot read properties of null (reading 'offsetTop')`,
      `Cannot read properties of null (reading 'CodeMirror')`
    ],
    environment: AppHelper.getEnv()
  });
}

ReactDOM.render(
  <GoogleOAuthProvider clientId={googleEnviroment[AppHelper?.getEnv()]?.clientId}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistStore(store)}>
        <ThemeProvider theme={theme}>
          <Application />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);

registerServiceWorker(AppHelper.getEnv());
