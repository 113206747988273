import React, { Component } from 'react';
import Select, { components } from 'react-select';
import SearchInputIcon from '../../images/SearchInputIcon.svg';

import styles from './CustomSelect.module.scss';

const { MenuList, ValueContainer } = components;

const CustomMenuList = (props) => {
  // Copied from source
  const ariaAttributes = {
    'aria-autocomplete': 'list',
    'aria-label': props.selectProps['aria-label'],
    'aria-labelledby': props.selectProps['aria-labelledby']
  };

  return (
    <div>
      <div style={{ padding: 5, boxSizing: 'border-box' }}>
        <SearchInputIcon />
        <input
          style={{
            flex: '0 0 75%',
            maxWidth: '75%',
            boxSizing: 'border-box',
            border: 'none',
            borderBottom: '2px solid #2755FE'
          }}
          className={styles.customSelectSearch}
          autoCorrect='off'
          autoComplete='off'
          spellCheck='false'
          type='text'
          value={props.selectProps.inputValue}
          onChange={(e) =>
            props.selectProps.onInputChange(e.currentTarget.value, {
              action: 'input-change'
            })
          }
          onMouseDown={(e) => {
            e.stopPropagation();
            e.target.focus();
          }}
          onTouchEnd={(e) => {
            e.stopPropagation();
            e.target.focus();
          }}
          onFocus={props.selectProps.onMenuInputFocus}
          {...ariaAttributes}
        />
      </div>
      <MenuList {...props} />
    </div>
  );
};

const CustomValueContainer = ({ children, ...props }) => {
  const commonProps = {
    cx: props.cx,
    clearValue: props.clearValue,
    getStyles: props.getStyles,
    getValue: props.getValue,
    hasValue: props.hasValue,
    isMulti: props.isMulti,
    isRtl: props.isRtl,
    options: props.options,
    selectOption: props.selectOption,
    setValue: props.setValue,
    selectProps: props.selectProps,
    theme: props.theme
  };

  return (
    <ValueContainer {...props}>
      {React.Children.map(children, (child) => {
        return child ? (
          child
        ) : props.hasValue ? (
          <components.SingleValue
            {...commonProps}
            isFocused={props.selectProps.isFocused}
            isDisabled={props.selectProps.isDisabled}
          >
            {props.selectProps.getOptionLabel(props.getValue()[0])}
          </components.SingleValue>
        ) : (
          <components.Placeholder
            {...commonProps}
            key='placeholder'
            isDisabled={props.selectProps.isDisabled}
            data={props.getValue()}
          >
            {props.selectProps.placeholder}
          </components.Placeholder>
        );
      })}
    </ValueContainer>
  );
};

export default class CustomSelect extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.onDocumentClick = this.onDocumentClick.bind(this);
  }

  state = { isFocused: false, selectedValue: this.props.defaultValue };

  inputFocus = (e) => this.setState({ isFocused: true });
  onChange = (option) => {
    this.setState({ isFocused: false, selectedValue: option.value });
    this.props.setFieldValue(this.props.name, option.value);
    this.props.onChange(option.value);
  };

  onInputChange = (val) => this.setState({ inputValue: val });

  onDocumentClick = (e) => {
    // e.persist();
    var menu = this.containerRef.current.querySelector('.select__menu');

    if (!this.containerRef.current.contains(e.target) || !menu || !menu.contains(e.target)) {
      this.setState({ isFocused: false, inputValue: '' });
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.onDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onDocumentClick);
  }

  render() {
    var selectProps = {
      isFocused: this.state.isFocused || undefined,
      menuIsOpen: this.state.isFocused || undefined
    };

    return (
      <div ref={this.containerRef}>
        <Select
          className='basic-single'
          classNamePrefix='select'
          name='color'
          options={this.props.options}
          components={{
            MenuList: CustomMenuList,
            ValueContainer: CustomValueContainer
          }}
          captureMenuScroll={false}
          inputValue={this.state.inputValue}
          isSearchable={false}
          onMenuInputFocus={this.inputFocus.bind(this)}
          onChange={this.onChange.bind(this)}
          onInputChange={this.onInputChange.bind(this)}
          value={this.props.filterLabel.filter(({ value }) => value === this?.props?.defaultValue)}
          {...selectProps}
        />
      </div>
    );
  }
}
