import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, sortBy, isArray, forEach, isEmpty, has } from 'lodash';
import { updateServices as updateServicesAPI } from '../../../../api/admin';
import { getServices as getServicesAPI } from '../../../../actions/admin';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../../../utils/Utlities';
import validationSchema from './CreateServices.validation';

// import AppConstants from '../../../core-components/AppConstants';

// import { toSnakeCase } from '../../../utils/Utlities';

const formatCompanyServices = (services = {}) => {
    const cServices = [];
    for (let value in services) {
        cServices.push({ ...services[value] });
    }
    return cServices;
};

const mapStateToProps = (state) => {
    return {
        // companyServices:
        //     (!isEmpty(state.companyServices.companyServices) &&
        //         formatCompanyServices(state.companyServices.companyServices)) ||
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getServices: (limit, offset) => dispatch(getServicesAPI(limit, offset)),
    };
};
const getStatus = (val) => {
    switch (val) {
        case 0:
            return 'pending';

        case 1:
            return 'active';
        case 2:
            return 'custom';
        case 3:
            return 'automatic';

        default:
            return 'active';
    }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFormik({
        mapPropsToValues: (props) => {
            let propsValue;

            if (has(props.details, 'id')) {
                propsValue = props.details;
            } else {
                propsValue = {};
            }
            let storedValues = {
                name: '',
                price: '',
                status: 1,
            };

            return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
        },
        validationSchema,
        enableReinitialize: true,
        handleSubmit: (values, { setFieldError, setStatus, setSubmitting, resetForm, props }) => {
            let finalValues;
            if (has(props.details, 'id')) {
                finalValues = {
                    name: values.name,
                    price: values.price,
                    status: getStatus(values.status),
                    serviceId: props.details.id,
                };
            } else {
                finalValues = {
                    name: values.name,
                    price: values.price,
                    status: getStatus(values.status),
                    serViceId: null,
                };
            }
            updateServicesAPI(finalValues)
                .then(() => {
                    props.getServices(10, 0, 'asc');
                    resetForm();
                    props.handleClose();
                })
                .catch((error) => {
                    console.error(error);
                });
            //call api for saving ....
        },
        displayName: 'Create Services',
    })
);
