import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Grid, FormControl, InputLabel, MenuItem, Select, makeStyles } from '@material-ui/core';

import SearchInput from './../../../../../core-components/SearchInput';
import Table from '../../../../../core-components/Table';
import AppConstants from '../../../../../core-components/AppConstants';
import {
  ADC_TYPE_USAGE_LOG,
  CHECK_TYPE_SHORT_VERSION,
  displayDateFormat,
  EVENT_LIST,
  EVENT_POSTPAID
} from '../../../../../core-components/CommonConstants';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getUsersList } from '../../CreditCompany/Shared/commonUtils';
import UsageLogsTable from '../../CreditCompany/Shared/Table';
import { getCreditInRupee } from '../../../../../utils/Utlities';
import { debounce } from 'lodash';

//this is for each row the heading creation  with whatever id for headcells
function createData(event, date, candidate, description, amount, available_balance, user, actions) {
  return { event, date, candidate, description, amount, available_balance, user, actions };
}

const headCells = [
  {
    id: 'date',
    label: 'TXN Date'
  },
  {
    id: 'event',
    label: 'TXN Type'
  },
  {
    id: 'description',
    label: 'Description'
  },
  {
    id: 'candidate',
    label: 'Candidate Name'
  },
  {
    id: 'user',
    label: 'User'
  },
  {
    id: 'amount',
    label: 'Amount',
    align: 'end'
  },
  {
    id: 'available_balance',
    label: 'Available Balance',
    align: 'end'
  },
  {
    id: 'actions',
    label: 'Additional Info/Actions',
    align: 'center'
  }
];

const useStyles = makeStyles((theme) => ({
  dateFilter: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    color: 'grey'
  },
  costContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  rupeeSymbol: {
    fontFamily: 'Roboto'
  }
}));

export default function UsageLog(props) {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [dateRange, setDateRange] = useState([null, null]);
  const [DateFrom, setDateFrom] = useState('');
  const [DateTo, setDateTo] = useState('');
  const [event, setEvent] = useState('All');
  const [name, setName] = useState('');
  const [userName, setuserName] = useState('All');
  const [creditType, setCreditType] = useState('All');
  const [invoiceNumber, setinvoiceNumber] = useState(null);
  const eventList = [...EVENT_POSTPAID, ...EVENT_LIST];
  const [details, setDetails] = useState([]);
  const [companyUserList, setCompanyUserList] = useState([]);

  const { companyUsageLogs } = props;

  useEffect(() => {
    getUsersList('USAGE_LOGS', props?.companyId, setCompanyUserList);
  }, []);

  useEffect(() => {
    setLoading(true);
    setOffset(0);
    getUsageLogs({
      rowsPerPage,
      offset: 0,
      event: event,
      from_date: DateFrom,
      to_date: DateTo ? DateTo : null,
      candidate_name: name,
      user_name: '',
      invoice_number: invoiceNumber,
      credit_type: getCreditOrDebit(),
      user_id: getUserName()
    });
  }, [props.companyId, name, event, DateFrom, DateTo, userName, invoiceNumber, creditType]);

  useEffect(() => {
    if (companyUsageLogs) {
      let rowsData =
        companyUsageLogs && companyUsageLogs.count ? getRowData(companyUsageLogs.rows) : [];
      setRows(rowsData);
      setTotalCount(companyUsageLogs.count);
    }
  }, [companyUsageLogs]);

  const getCreditOrDebit = () => {
    switch (creditType) {
      case 'All':
        return null;
      case 'Credit':
        return 'CREDIT';
      case 'Debit':
        return 'DEBIT';
    }
  };

  const getUserName = () => {
    if (userName === 'All') {
      return '';
    }

    return userName;
  };

  const getUsageLogs = ({
    rowsPerPage,
    offset,
    event,
    from_date,
    to_date,
    candidate_name,
    user_name,
    invoice_number,
    credit_type,
    user_id
  }) => {
    setLoading(true);
    props
      .getCompanyUsageLogs(
        props.companyId,
        rowsPerPage,
        offset,
        event === 'All' ? null : event,
        from_date,
        to_date,
        candidate_name,
        user_name,
        invoice_number,
        credit_type,
        user_id
      )
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const getRowData = (values) => {
    let usageLogs = [];
    values.forEach((usage) => {
      let rowData = createData(
        getDetails(usage?.event),
        moment(usage?.created_at).utc().format(displayDateFormat),
        getCandidateName(usage),
        getDescription(usage),
        getCreditInRupee(usage?.amount, 'UNLIMITED'),
        getCreditInRupee(usage?.available_balance, 'UNLIMITED'),
        getUser(usage),
        actions(usage)
      );
      usageLogs.push(rowData);
    });
    return usageLogs;
  };

  const getDetails = (value) => {
    const event = eventList.filter((e) => e.value === value);
    return event[0]?.label;
  };

  const getCandidateName = (usage) => {
    const name = usage?.candidate?.name || '-';
    const id = usage?.candidate?.id;
    const text = id ? `${name} (${id})` : name;

    return text === '-' ? (
      text
    ) : (
      <a
        aria-disabled={true}
        href={`${AppConstants.baseURL}candidate/details?id=${usage?.candidate?.id}&company_id=${usage?.company_id_fk}`}
        target='_blank'
      >
        {text}
      </a>
    );
  };
  const getUser = (usage) => {
    const name =
      usage?.user_type === 5
        ? usage?.company_api_token?.name
        : usage?.user?.name
        ? usage?.user?.name
        : '-';
    const id = usage.user_id_fk;
    const companyUserMapping = usage?.user?.company_user_mapping;
    return usage?.user_type === 5
      ? `API: ${name}`
      : companyUserMapping != null
      ? `CA: ${name} (${id})`
      : `SA: ${name} (${id})`;
  };

  const getAdditionalChargeType = (value) => {
    const type = ADC_TYPE_USAGE_LOG[value?.charge_type];

    return `${type}`;
  };

  const getAdditionalCheckType = (value) => {
    if (value) {
      const res = CHECK_TYPE_SHORT_VERSION[value];

      if (res) return res;
      else return value;
    }
    return '-';
  };
  const getDescription = (usage) => {
    switch (usage.event) {
      case 'CANDIDATE_ADDED':
        return 'Candidate Added';
      case 'CANDIDATE_DELETED':
        return 'Candidate Deleted';
      case 'ADDON_BOUGHT':
      case 'CHECK_REFUND':
        return `${getAdditionalCheckType(usage?.check_type)}`;
      case 'ADDITIONAL_CHARGE':
      case 'ADDITIONAL_CHARGE_REFUND':
        return `${getAdditionalChargeType(
          usage?.payments_company_additional_charge
        )}: ${getAdditionalCheckType(usage?.check_type)}`;
      case 'INVOICE_PAID':
        const paidDetails = getInvoiceDescription(usage?.invoice_details || '');
        return `Payment Received: ${paidDetails}`;
      case 'REFUND':
        return `Refunded: ${usage?.package_details ? usage.package_details?.name : '-'}`;
      case 'CREDIT_LIMIT_INCREASED':
        return 'Credit Limit increased';
      case 'CREDIT_LIMIT_DECREASED':
        return 'Credit Limit decreased';
      case 'TRIAL':
        const desc = getInvoiceDescription(usage?.invoice_details || '');
        if (usage?.candidate?.id) {
          return `Trial Discount on Candidate ${desc}`;
        } else {
          return `Trial Discount on invoice ${desc}`;
        }
      case 'DISCOUNT':
        const description = getInvoiceDescription(usage?.invoice_details || '');
        if (usage?.candidate?.id) {
          return `Discount on Candidate ${description}`;
        } else {
          return `Discount on invoice ${description}`;
        }
      case 'PENALTY':
        const textValue = getInvoiceDescription(usage?.invoice_details || '');
        if (usage?.candidate?.id) {
          return `Penalty on Candidate ${textValue}`;
        } else {
          return `Penalty on invoice ${textValue}`;
        }
      case 'CHECK_VERIFICATION_TYPE_CONVERSION':
        return CHECK_TYPE_SHORT_VERSION[usage?.check_type];
      case 'ALUMNI_VERIFICATION_BONUS':
        return 'Credits Earned';
      case 'CREDITS_APPLIED_AS_DISCOUNT':
        return 'Alumni Verification credits redeemed as Discount';
      default:
        return '';
    }
  };

  const getInvoiceDescription = (details) => {
    const invoiceNumber = details?.invoice_number || '';
    const invoiceDate = details?.invoice_date || '';
    let text = '';

    if (invoiceNumber && invoiceDate) {
      const date = moment(invoiceDate).format('DD-MM-YYYY');
      text = `(Inv No. ${invoiceNumber} : ${date})`;
    }
    return text;
  };

  const actions = (value) => {
    if (['INVOICE_PAID', 'DISCOUNT', 'PENALTY'].includes(value.event)) {
      if (value.company_id_fk) {
        return (
          <div>
            <a
              href={`${AppConstants.baseURL}postpaid/unbilled/candidates?id=${value.company_id_fk}`}
              target='_blank'
            >
              Billing List /
            </a>
            <a
              href={`${AppConstants.baseURL}postpaid/company/invoice/list?id=${value.company_id_fk}&monthUrl=${value?.invoice_details?.month}&yearUrl=${value?.invoice_details?.year}&invoiceUrl=${value?.invoice_details?.id}`}
              target='_blank'
            >
              Go to Invoice
            </a>
          </div>
        );
      } else {
        return '-';
      }
      // } else if (['CREDIT_LIMIT_INCREASED', 'CREDIT_LIMIT_DECREASED'].includes(value.event)) {
      //   return (
      //     <div>
      //       <span>Credit Balance</span>
      //     </div>
      //   );
      // } else {
      //   if (
      //     value.candidate &&
      //     value.candidate.id &&
      //     value.company_id_fk &&
      //     value.event !== 'CANDIDATE_DELETED'
      //   ) {
      //     return (
      //       <a
      //         href={`${AppConstants.baseURL}candidate/details?id=${value.candidate.id}&company_id=${value.company_id_fk}`}
      //         target='_blank'
      //       >
      //         Candidate Log
      //       </a>
      //     );
      //   } else {
      //     return '-';
      //   }
      // }
      // return '';
    }

    return '-';
  };

  const handleChangePage = (newPage) => {
    setOffset(newPage);
    setLoading(true);
    getUsageLogs({
      rowsPerPage,
      offset: newPage,
      event: event,
      from_date: DateFrom,
      to_date: DateTo ? DateTo : null,
      candidate_name: name,
      user_name: '',
      invoice_number: invoiceNumber,
      credit_type: getCreditOrDebit(),
      user_id: getUserName()
    });
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setOffset(0);
    setLoading(true);
    getUsageLogs({
      rowsPerPage: parseInt(value, 10),
      offset: 0,
      event: event,
      from_date: DateFrom,
      to_date: DateTo ? DateTo : null,
      candidate_name: name,
      user_name: '',
      invoice_number: invoiceNumber,
      credit_type: getCreditOrDebit(),
      user_id: getUserName()
    });
  };

  const handleEvent = (e) => {
    setEvent(e.target.value);
  };
  const applyNameFilter = (cName) => {
    setName(cName);
  };
  const applyUserNameFilter = (uName) => {
    setuserName(uName);
  };
  const applyInvoiceFilter = (inv) => {
    if (inv) {
      setinvoiceNumber(inv);
    } else {
      setinvoiceNumber(null);
    }
  };

  const applyDateFilter = (dates) => {
    setDateRange(dates);
    if (dates[0] && dates[1]) {
      const date1 = new Date(dates[0]).toLocaleDateString('fr-CA');
      const date2 = new Date(dates[1]).toLocaleDateString('fr-CA');
      setDateFrom(date1);
      setDateTo(date2);
    } else {
      setDateFrom('');
      setDateTo('');
    }
  };

  const dateField = () => {
    return (
      <DatePicker
        selectsRange={true}
        startDate={dateRange[0]}
        endDate={dateRange[1]}
        onChange={(update) => {
          applyDateFilter(update);
        }}
        isClearable={true}
      />
    );
  };
  const handleUserChange = (e) => {
    const value = e.target.value;
    setuserName(value);
  };

  const handleCreditDebitFilter = (e) => {
    const value = e.target.value;
    setCreditType(value);
  };

  return (
    <>
      <Grid container>
        <Grid item container md={12} spacing={2}>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1}>
            {/** Date Filter*/}
            <Grid item lg md sm={12} xs>
              <div style={{ maxWidth: '250px', marginLeft: '11px', marginTop: '15px' }}>
                <span className={classes.dateFilter}>Date Filter</span>
                {dateField()}
              </div>
            </Grid>
            {/** TXN Type Filter*/}
            <Grid style={{ paddingTop: '33px' }} item lg md sm={12} xs>
              <FormControl style={{ width: '100%' }} variant='outlined' size='small'>
                <InputLabel id='event-outlined-label'>Filter by TXN Type</InputLabel>
                <Select
                  labelId='event-outlined-label'
                  id='event-outlined'
                  value={event}
                  onChange={handleEvent}
                  label='Filter by TXN Type'
                >
                  <MenuItem value={'All'}>All</MenuItem>
                  {eventList.map((event, i) => (
                    <MenuItem value={event.value} key={i}>
                      {event.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/** User Filter*/}
            <Grid style={{ paddingTop: '33px' }} item lg md sm={12} xs>
              <FormControl style={{ width: '100%' }} variant='outlined' size='small'>
                <InputLabel id='event-outlined-label'>Filter by User</InputLabel>
                <Select
                  labelId='event-outlined-label'
                  id='event-outlined'
                  value={userName}
                  onChange={handleUserChange}
                  label='Filter by User'
                  size={'small'}
                >
                  <MenuItem value={'All'}>All</MenuItem>
                  {/** Map User list from API */}
                  {companyUserList.length &&
                    companyUserList.map((user) => (
                      <MenuItem value={user.user_id} key={user.user_id}>
                        {`${user.isCompanyAdmin ? 'CA' : 'SA'}: ${user.user_name} (${
                          user.user_id
                        })`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            {/* Credit/Debit Filter */}
            <Grid style={{ paddingTop: '33px' }} item lg md sm={12} xs>
              <FormControl style={{ width: '100%' }} variant='outlined' size='small'>
                <InputLabel id='event-outlined-label'>Filter by Credit/Debit</InputLabel>
                <Select
                  labelId='event-outlined-label'
                  id='event-outlined'
                  value={creditType}
                  onChange={handleCreditDebitFilter}
                  label='Filter by Credit/Debit'
                  size={'small'}
                >
                  <MenuItem value={'All'}>All</MenuItem>
                  <MenuItem value={'Credit'}>Credit Only</MenuItem>
                  <MenuItem value={'Debit'}>Debit Only</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* Search by candidate name Filter */}
            <Grid style={{ paddingTop: '33px' }} item lg md={12} sm={12} xs>
              <SearchInput
                isBackSpaceCallEnabled={true}
                applyMinFont={true}
                placeholder={'Candidate Name'}
                handleSearch={debounce((name) => {
                  applyNameFilter(name?.trim());
                }, 500)}
              />
            </Grid>
            {/* Search by invoice number Filter */}
            <Grid style={{ paddingTop: '33px' }} item lg md={12} sm={12} xs>
              <SearchInput
                applyMinFont={true}
                placeholder={'Invoice Number'}
                handleSearch={debounce((invNumber) => {
                  applyInvoiceFilter(invNumber);
                }, 500)}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Table Section */}
        <Grid item md={12}>
          <UsageLogsTable
            headCells={headCells}
            rowData={rows}
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            page={offset}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            loading={loading}
          />
        </Grid>
      </Grid>
    </>
  );
}
