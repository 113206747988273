import * as Yup from 'yup';

export default Yup.object().shape({
  creditLimit: Yup.string().when('isUnlimited', {
    is: (isUnlimited) => isUnlimited == false,
    then: Yup.string()
      .required('Please enter new credit balance')
      .matches(/^[0-9]+$/, 'Please enter valid credit limit')
  }),
  creditLimit: Yup.number().max(5000000, 'Limit is 50L only.')
});
