import React, { useState, useEffect } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  Typography,
  Box,
  Button,
  Grid,
  FormGroup,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ordinal from 'ordinal';

import IncrementCounter from '../../../../core-components/Packages/IncrementCounterWithoutValidation';
import { getTotalCount, getCountArr, getIdentityAnyCounter } from './helper';
import {
  ADDRESS_FOR_CUSTOMIZE_PACKAGE_CONFIG,
  INDENTITES_FOR_PACKAGE_CONFIG,
  PLAIN_CHECK_ICONS
} from '../../../../core-components/CommonConstants';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    marginRight: '96px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, packageName, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: '0 16px 16px'
  }
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  listItem: {
    fontFamily: 'Roboto',
    lineHeight: '24px'
  },
  checkTextHeading: {
    color: '#333333',
    fontSize: '16px !important',
    fontWeight: 500
  },
  checkTextDesc: {
    fontSize: '14px',
    color: '#8C8C8C'
  },
  checkTextConfig: {
    fontSize: '14px',
    color: '#595959',
    marginRight: '8px'
  },
  errorText: {
    color: '#F5222D',
    fontSize: '14px'
  },
  activeBtn: {
    margin: theme.spacing(1),
    height: '44px',
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  disabledBtn: {
    cursor: 'not-allowed',
    margin: theme.spacing(1),
    height: '44px',
    color: '#dfdfdf',
    borderColor: '#d9d9d9',
    backgroundColor: '#f5f5f5',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  }
}));

const IdentityArr = {
  PAN: 'PAN card',
  DL: 'Driving license',
  VOTER_ID: 'Voter ID',
  AADHAR: 'Aadhar',
  AADHAAR: 'Aadhaar',
  PASSPORT: 'Passport'
};

export default (props) => {
  const classes = useStyles();

  const [identityArr, setIdentityArr] = useState([]);
  const [selectedIdentity, setSelectedIdentity] = useState([]);
  const [address, setAddress] = useState('ANY_1');
  const [court, setCourt] = useState('ANY_1');
  const [refArr, setRefArr] = useState([]);
  const [refError, setRefError] = useState('');
  const [identityAnyCount, setIdentityAnyCount] = useState(0);
  const { open, handleClose, title, isAadhaarAllowed, selectedPackage } = props;

  const config = Object.assign({}, selectedPackage?.config);

  useEffect(() => {
    if (selectedPackage) {
      setIdentityArr(selectedPackage.config.identity || []);
      setIdentityAnyCount(
        props.updatedConfig && props.updatedConfig.identity
          ? getIdentityAnyCounter(props.updatedConfig.identity)
          : getIdentityAnyCounter(selectedPackage.config.identity)
      );
      setSelectedIdentity(
        props.updatedConfig && props.updatedConfig.identity ? props.updatedConfig?.identity : []
      );
      setAddress(
        props.updatedConfig && props.updatedConfig.address
          ? props.updatedConfig.address[0]?.type
          : 'ANY_1'
      );
      setCourt(
        props.updatedConfig && props.updatedConfig?.court
          ? props.updatedConfig.court[0]?.type
          : 'ANY_1'
      );
      setRefArr(
        props.updatedConfig && props.updatedConfig.reference
          ? props.updatedConfig.reference
          : getCountArr(selectedPackage)
      );
    } else {
      setIdentityArr([]);
      setSelectedIdentity([]);
      setAddress('ANY_1');
      setCourt('ANY_1');
      setRefArr([]);
      setIdentityAnyCount(0);
    }
  }, [selectedPackage]);

  // Validating ref count and show error
  const handleConfigChange = (value, index) => {
    const arr = [...refArr];
    arr[index] = value;

    if (getTotalCount(arr) !== getTotalCount(selectedPackage.config.reference)) {
      setRefError(
        `You can only choose ${getTotalCount(
          selectedPackage.config.reference
        )} total reference checks`
      );
    } else {
      setRefError('');
    }
    setRefArr(arr);
  };

  const handleAddressChange = (value) => {
    setAddress(value);
  };

  const handleCourtChange = (value) => {
    setCourt(value);
  };

  // Handle change of identity checkbox
  const handleChange = (event, name) => {
    const dummy = [...selectedIdentity];

    if (event.target.checked) {
      if (!dummy?.includes(name)) {
        dummy.push(name);
      }
    } else {
      dummy.splice(dummy.indexOf(name), 1);
    }
    setSelectedIdentity(dummy);
  };

  // Handle config update submit
  const handleDoneClick = () => {
    if (config.identity) {
      const tempIdentityAnyCount = getIdentityAnyCounter(selectedPackage.config.identity);
      const anyCount = tempIdentityAnyCount - selectedIdentity.length;

      let anyStr = '';
      const tempIdentity = [...config.identity];

      if (anyCount > 0) {
        anyStr = `ANY_${anyCount}`;
        tempIdentity[tempIdentity.indexOf(`ANY_${tempIdentityAnyCount}`)] = anyStr;
      } else {
        if (tempIdentity.indexOf(`ANY_${tempIdentityAnyCount}`) >= 0) {
          tempIdentity.splice(tempIdentity.indexOf(`ANY_${tempIdentityAnyCount}`), 1);
        }
      }

      config.tempIdentity = [...tempIdentity, ...selectedIdentity];
      config.identity = [...selectedIdentity];
    }

    if (
      config.address.length === 1 &&
      config.address.map((o) => o.type).includes('ANY_1') &&
      address !== 'ANY_1'
    ) {
      const tempAddress = [{ ...config.address[0], type: address }];
      config.address = [...tempAddress];
    }

    if (
      config.court.length === 1 &&
      config.court.map((o) => o.type).includes('ANY_1') &&
      court !== 'ANY_1'
    ) {
      const tempCourt = [{ ...config.court[0], type: court }];
      config.court = [...tempCourt];
    }

    if (['TOTAL', 'SPECIFIC', 'SPECIFIC_LOCK'].includes(config?.referenceType)) {
      const tempRef = [...refArr];
      config.reference = [...tempRef];
    }

    let arr = [];
    if (
      selectedPackage['customizeConfig'] &&
      selectedPackage['customizeConfig'][props.selectedIndex]
    ) {
      arr = [...selectedPackage['customizeConfig']];
    }

    arr[props.selectedIndex] = config;

    props.handleCustomizedConfigSubmit({ ...selectedPackage, customizeConfig: [...arr] });
  };

  const renderConfig = () => {
    return refArr.map((item, index) => {
      return (
        <Box mt={2} mb={2} key={index}>
          <Grid
            item
            lg={11}
            md={11}
            sm={11}
            xs={11}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {index === 0 ? (
              <>
                <Typography
                  component={'p'}
                  className={classes.checkTextConfig}
                >{`Last employment`}</Typography>
                <IncrementCounter
                  limit={refArr[0]}
                  totalRefCount={getTotalCount(selectedPackage.config.reference)}
                  value={item}
                  index={index}
                  valueChange={(value, index) => {
                    handleConfigChange(value, index);
                  }}
                />
              </>
            ) : (
              <>
                <Typography component={'p'} className={classes.checkTextConfig}>{`${ordinal(
                  index + 1
                )} Last employment`}</Typography>
                <IncrementCounter
                  limit={refArr[index]}
                  totalRefCount={getTotalCount(selectedPackage.config.reference)}
                  value={item}
                  index={index}
                  valueChange={(value, index) => {
                    handleConfigChange(value, index);
                  }}
                />
              </>
            )}
          </Grid>
        </Box>
      );
    });
  };

  const isValid = !refError;

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby='audit-log-dialog-title'
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
      >
        {selectedPackage ? (
          <>
            <DialogTitle id='audit-log-dialog-title' packageName={title} onClose={handleClose}>
              {`Customize ${selectedPackage.subtypeName}`}
            </DialogTitle>
            <DialogContent>
              {/* Rendering Identity */}
              {identityArr && identityArr.length && getIdentityAnyCounter(identityArr) > 0 ? (
                <Box mt={2} mb={2}>
                  <Grid container>
                    <Grid item lg={1} md={1} sm={1} xs={1}>
                      <FontAwesomeIcon
                        icon={
                          PLAIN_CHECK_ICONS['identity']
                            ? PLAIN_CHECK_ICONS['identity'].icon
                            : 'faBan'
                        }
                        color='#2755FE'
                        style={{ fontSize: '16px' }}
                      />
                    </Grid>
                    <Grid item lg={11} md={11} sm={11} xs={11}>
                      <Typography component={'span'} className={classes.checkTextHeading}>
                        {'Identity'}
                      </Typography>

                      <Typography component={'p'} className={classes.checkTextDesc}>
                        {`Choose ${getIdentityAnyCounter(identityArr)} ID's`}
                      </Typography>

                      <FormControlLabel
                        control={
                          <Checkbox
                            size={'small'}
                            disabled={true}
                            checked={
                              selectedIdentity.length !== getIdentityAnyCounter(identityArr)
                                ? true
                                : false
                            }
                            name={'any_identity'}
                          />
                        }
                        label={
                          <Typography component={'span'} style={{ fontSize: '14px' }}>
                            {`Any ${
                              getIdentityAnyCounter(identityArr) - selectedIdentity.length || ''
                            } ID`}
                          </Typography>
                        }
                      />

                      {/* checks selection */}
                      {INDENTITES_FOR_PACKAGE_CONFIG.map((value, index) => {
                        return !selectedPackage?.config?.identity?.includes(value?.name) ? (
                          <Box key={`check_selection_${index}`}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={
                                    (selectedIdentity?.length ===
                                      getIdentityAnyCounter(identityArr) &&
                                      !selectedIdentity?.includes(value?.name)) ||
                                    (!isAadhaarAllowed && value?.name === 'AADHAAR')
                                  }
                                  checked={selectedIdentity?.includes(value?.name)}
                                  onChange={(e) => handleChange(e, value?.name)}
                                  name={value?.name}
                                />
                              }
                              label={value.label}
                            />
                          </Box>
                        ) : null;
                      })}

                      {/* checks included rendering using original config*/}
                      {selectedPackage?.config &&
                        selectedPackage?.config?.identity &&
                        selectedPackage?.config?.identity?.map((item, index) => {
                          return !item.includes('ANY_') ? (
                            <Box key={`check_included_${index}`}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    size={'small'}
                                    disabled={true}
                                    checked={true}
                                    name={`${item}_checkbox`}
                                  />
                                }
                                label={
                                  <Typography component={'span'} style={{ fontSize: '14px' }}>
                                    {`${IdentityArr[item]}`}
                                    &nbsp;&nbsp;
                                    <Typography
                                      component={'span'}
                                      style={{
                                        color: '#999999',
                                        fontWeight: 500,
                                        fontSize: '12px'
                                      }}
                                    >
                                      {'INCLUDED'}
                                    </Typography>
                                  </Typography>
                                }
                              />
                            </Box>
                          ) : null;
                        })}
                    </Grid>
                  </Grid>
                </Box>
              ) : null}

              {/* Rendering Address */}
              {selectedPackage.config &&
              selectedPackage.config.address &&
              selectedPackage.config.address.length &&
              selectedPackage.config.address.length === 1 &&
              selectedPackage.config.address.map((o) => o?.type).includes('ANY_1') ? (
                <Box mt={2} mb={2}>
                  <Grid container>
                    <Grid item lg={1} md={1} sm={1} xs={1}>
                      <FontAwesomeIcon
                        icon={
                          PLAIN_CHECK_ICONS['address'] ? PLAIN_CHECK_ICONS['address'].icon : 'faBan'
                        }
                        color='#2755FE'
                        style={{ fontSize: '16px' }}
                      />
                    </Grid>
                    <Grid item lg={11} md={11} sm={11} xs={11}>
                      <Typography component={'span'} className={classes.checkTextHeading}>
                        {'Address'}
                      </Typography>
                      <FormGroup>
                        <RadioGroup
                          aria-label='gender'
                          name='address'
                          value={address}
                          onChange={(e) => handleAddressChange(e.target.value)}
                        >
                          {ADDRESS_FOR_CUSTOMIZE_PACKAGE_CONFIG.map((value, index) => {
                            return (
                              <FormControlLabel
                                key={index}
                                value={value?.name}
                                control={<Radio />}
                                label={value?.label}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Box>
              ) : null}

              {/* Rendering Court */}
              {selectedPackage.config &&
              selectedPackage.config.court &&
              selectedPackage.config.court.length &&
              selectedPackage.config.court.length === 1 &&
              selectedPackage.config.court.map((o) => o?.type).includes('ANY_1') ? (
                <Box mt={2} mb={2}>
                  <Grid container>
                    <Grid item lg={1} md={1} sm={1} xs={1}>
                      <FontAwesomeIcon
                        icon={
                          PLAIN_CHECK_ICONS['court'] ? PLAIN_CHECK_ICONS['court'].icon : 'faBan'
                        }
                        color='#2755FE'
                        style={{ fontSize: '16px' }}
                      />
                    </Grid>
                    <Grid item lg={11} md={11} sm={11} xs={11}>
                      <Typography component={'span'} className={classes.checkTextHeading}>
                        {'Court'}
                      </Typography>
                      <FormGroup>
                        <RadioGroup
                          aria-label='gender'
                          name='court'
                          value={court}
                          onChange={(e) => handleCourtChange(e.target.value)}
                        >
                          {ADDRESS_FOR_CUSTOMIZE_PACKAGE_CONFIG.map((value, index) => {
                            return (
                              <FormControlLabel
                                key={index}
                                value={value?.name}
                                control={<Radio />}
                                label={value?.label}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Box>
              ) : null}

              {/* Rendering Reference */}
              {selectedPackage.config && selectedPackage.config.reference ? (
                <Box mt={2} mb={2}>
                  {selectedPackage.config.reference > 0 ||
                  selectedPackage.config.reference.length ? (
                    <Grid container>
                      <Grid item lg={1} md={1} sm={1} xs={1}>
                        <FontAwesomeIcon
                          icon={
                            PLAIN_CHECK_ICONS['reference']
                              ? PLAIN_CHECK_ICONS['reference'].icon
                              : 'faBan'
                          }
                          color='#2755FE'
                          style={{ fontSize: '16px' }}
                        />
                      </Grid>
                      <Grid item lg={11} md={11} sm={11} xs={11}>
                        <Typography component={'span'} className={classes.checkTextHeading}>
                          {'Reference'}
                        </Typography>
                        <Typography component={'p'} className={classes.checkTextDesc}>
                          {`Choose ${getTotalCount(selectedPackage.config.reference)} Reference`}
                        </Typography>

                        {renderConfig()}
                        {refError ? (
                          <Typography
                            component={'p'}
                            variant={'body2'}
                            className={classes.errorText}
                          >
                            {refError}
                          </Typography>
                        ) : null}
                      </Grid>
                    </Grid>
                  ) : null}
                </Box>
              ) : null}

              <Box style={{ textAlign: 'right' }}>
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  onClick={handleClose}
                  style={{ marginRight: '8px' }}
                >
                  {'Cancel'}
                </Button>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  disabled={!isValid}
                  className={!isValid ? classes.disabledBtn : null}
                  onClick={() => {
                    handleDoneClick();
                  }}
                >
                  {'Done'}
                </Button>
              </Box>
            </DialogContent>
          </>
        ) : null}
      </Dialog>
    </div>
  );
};
