import React from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  customTypo: {
    color: '#333333'
  }
}));

export default () => {
  const classes = useStyles();
  const curYear = new Date().getFullYear();
  return (
    <>
      <Typography variantMapping={{ body1: 'span' }} className={classes.customTypo}>
        &copy; SpringVerify {curYear}
      </Typography>
    </>
  );
};
