// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".p84faWXkm1t_P2chVTyn {\n  line-height: 24px;\n}\n.p84faWXkm1t_P2chVTyn .yJp0NScUpUBpIJ5TpKfR .ssgKOd6R15OXpTomQ3OD {\n  text-align: center;\n}\n.p84faWXkm1t_P2chVTyn .yJp0NScUpUBpIJ5TpKfR ._klBtSlmCBh4KNtRP865 {\n  padding-left: 0;\n}\n.p84faWXkm1t_P2chVTyn .UpuT5w4RY2s3DsyrqG3s {\n  font-weight: 600;\n  font-size: 16px;\n  color: #262626;\n}\n.p84faWXkm1t_P2chVTyn .A70_5oOLn2D30km2ZeVt {\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 22px;\n  color: #595959;\n}\n.p84faWXkm1t_P2chVTyn ._1wCKVnB2bUOXteMtvfB button {\n  border-radius: 2px;\n  padding: 5px 16px;\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 24px;\n}\n.p84faWXkm1t_P2chVTyn ._1wCKVnB2bUOXteMtvfB .zG4QM8ObV516DqECsPJR {\n  background-color: #d3455b;\n  color: #ffffff;\n  cursor: pointer;\n}\n.p84faWXkm1t_P2chVTyn ._1wCKVnB2bUOXteMtvfB .yQq7iWJQZPbbCm6BMjKc {\n  background-color: #2755fe;\n  color: #ffffff;\n  cursor: pointer;\n}\n.p84faWXkm1t_P2chVTyn ._1wCKVnB2bUOXteMtvfB .WEisxJ_RxJyetiGJMm9u {\n  background: #f5f5f5;\n  color: #bfbfbf;\n  border: 1px solid #d9d9d9;\n  cursor: not-allowed;\n}\n.p84faWXkm1t_P2chVTyn ._1wCKVnB2bUOXteMtvfB .kkyrjwkJGOezgGYHq4pu {\n  background: #ffffff;\n  color: #2755fe;\n  border: none;\n  box-shadow: none;\n}\n.p84faWXkm1t_P2chVTyn ._1wCKVnB2bUOXteMtvfB .hGzlHJPT8Nrbtvq9BIFQ {\n  background: #ffffff;\n  color: #2755fe;\n  border: none;\n  box-shadow: none;\n  cursor: not-allowed;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmationModal": "p84faWXkm1t_P2chVTyn",
	"confirModalBody": "yJp0NScUpUBpIJ5TpKfR",
	"questionImage": "ssgKOd6R15OXpTomQ3OD",
	"confirmTextContainer": "_klBtSlmCBh4KNtRP865",
	"confirmModalHeading": "UpuT5w4RY2s3DsyrqG3s",
	"confirmText": "A70_5oOLn2D30km2ZeVt",
	"confirmModalFooter": "_1wCKVnB2bUOXteMtvfB",
	"deleteBtn": "zG4QM8ObV516DqECsPJR",
	"activeConfirmBtn": "yQq7iWJQZPbbCm6BMjKc",
	"disabledConfirmBtn": "WEisxJ_RxJyetiGJMm9u",
	"cancelConfirmBtn": "kkyrjwkJGOezgGYHq4pu",
	"disabledCancelConfirmBtn": "hGzlHJPT8Nrbtvq9BIFQ"
};
export default ___CSS_LOADER_EXPORT___;
