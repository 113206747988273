import React, { useState } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  Button,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import { INSUFF_CHECK_STATUS_DROPDOWN } from '../../../../utils/Statuses';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h5'>{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  formControl: {
    width: '100%',
    marginTop: '16px'
  }
}));

export default (props) => {
  const classes = useStyles();
  const [status, setStatus] = useState('');

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        className={classes.root}
        fullWidth={true}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
      >
        <DialogTitle id='popup-title' onClose={props.handleClose}>
          {'Set check status'}
        </DialogTitle>
        <DialogContent>
          <Typography variant='subtitle2' component={'p'} color='textSecondary' gutterBottom>
            {'You have cleared all the insuff tickets for this check.'}
          </Typography>
          <Typography variant='subtitle2' component={'p'} color='textSecondary' gutterBottom>
            {'Please enter the status that you want the check to go into.'}
          </Typography>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <FormControl variant='outlined' className={classes.formControl} size='small'>
                <InputLabel id='status'>Select Status</InputLabel>
                <Select
                  labelId='status'
                  id='checkStatus'
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  label='Select Status'
                  fullWidth={true}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  {INSUFF_CHECK_STATUS_DROPDOWN.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color={'primary'} variant={'outlined'} onClick={() => props.handleClose()}>
            {'Cancel'}
          </Button>
          <Button
            color={'primary'}
            variant={'contained'}
            disabled={!status}
            onClick={() => props.handleCheckStatusModalSubmit(status)}
          >
            {'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
