// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uUhT1Ox6hTmBjo8Z8Iut {\n  padding: 20px 20px 0px 20px;\n}\n.uUhT1Ox6hTmBjo8Z8Iut .EDdjxkuWwEUtZAq2fCZh {\n  font-weight: 600 !important;\n  line-height: 26px;\n  font-size: 18px;\n  margin: 0 0 1rem 0;\n}\n\n.sxCa0IGYw6FP7j17YBXW {\n  border: 1px solid rgba(0, 0, 0, 0.125);\n  margin: 1rem 0;\n}\n\n._5FeILnpuRC6shYPnSiI {\n  border: 1px solid #2755fe !important;\n}\n\n.Ly2PU6FrcNQi3akWlRvG {\n  background-color: white;\n  border-bottom: none;\n  padding: 0.75rem 1rem;\n  border-radius: 4px;\n  cursor: pointer;\n}\n.Ly2PU6FrcNQi3akWlRvG .g1JM7xbeibOw1mERISLQ {\n  padding: 0rem;\n}\n\n.zSybk2kExcLhK798He6C {\n  font-weight: 500 !important;\n  line-height: 26px;\n  font-size: 16px;\n  color: #666666 !important;\n}\n\n.ODoTqI_ndlpDf7vQiZ5s {\n  font-weight: 600;\n  font-size: 20px;\n}\n\n.NE7B174nmc_kpIls12Pi {\n  color: #666666;\n  font-size: 14px;\n  font-weight: 600;\n}\n\n.GVu4_OjA5vCPWGvWwP_L {\n  background: #ffe3e3;\n  color: #666666;\n  font-size: 12px;\n  font-weight: 500;\n}\n\n.v91spS5C9nfOe9_JXrTP {\n  background: rgba(250, 173, 20, 0.2);\n  color: #666666;\n  font-size: 12px;\n  font-weight: 500;\n}\n\n.p8GnzyzHSCm4VyzYfbFe {\n  text-decoration: underline;\n  color: #2755fe;\n  font-weight: 600;\n  font-size: 12px;\n}\n\n.jsrUsPcUsJxlcORSuInj {\n  font-weight: 500;\n  color: #2755fe;\n}\n\n.d4FHvmXsrtomkfRDBHah {\n  padding: 0 0.75rem !important;\n}\n\n.SIGR0JFU9SqMp3cwipXD {\n  padding: 0 !important;\n}\n\n.ub6zAhCom6RK4XttHykj {\n  margin: 0 0 0.75rem 0 !important;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "uUhT1Ox6hTmBjo8Z8Iut",
	"containerHeader": "EDdjxkuWwEUtZAq2fCZh",
	"accordionCard": "sxCa0IGYw6FP7j17YBXW",
	"cardActive": "_5FeILnpuRC6shYPnSiI",
	"accordionHeader": "Ly2PU6FrcNQi3akWlRvG",
	"accordionTitle": "g1JM7xbeibOw1mERISLQ",
	"sectionSubTitle": "zSybk2kExcLhK798He6C",
	"sectionTitle": "ODoTqI_ndlpDf7vQiZ5s",
	"currentBalance": "NE7B174nmc_kpIls12Pi",
	"criticalPill": "GVu4_OjA5vCPWGvWwP_L",
	"lowPill": "v91spS5C9nfOe9_JXrTP",
	"viewCheck": "p8GnzyzHSCm4VyzYfbFe",
	"rechargeText": "jsrUsPcUsJxlcORSuInj",
	"tierCard": "d4FHvmXsrtomkfRDBHah",
	"tierContainer": "SIGR0JFU9SqMp3cwipXD",
	"tierCol": "ub6zAhCom6RK4XttHykj"
};
export default ___CSS_LOADER_EXPORT___;
