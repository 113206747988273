import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  getInsufficiency as getInsufficiencyAPI,
  getCompanyById as getCompanyByIdAPI
} from '../../../actions/admin';

const mapStateToProps = (state) => {
  return {
    InsufficiencyData: state.companies,
    companyById: state.updateCompanyData
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCompany: (compId) => dispatch(getCompanyByIdAPI(compId)),
    getInsufficiency: (
      limit,
      offset,
      companyId,
      status,
      candidateId,
      candidateName,
      companyName,
      checkType,
      orderAccordingToDays
    ) =>
      dispatch(
        getInsufficiencyAPI(
          limit,
          offset,
          companyId,
          status,
          candidateId,
          candidateName,
          companyName,
          checkType,
          orderAccordingToDays
        )
      )
  };
};
export default compose(connect(mapStateToProps, mapDispatchToProps));
