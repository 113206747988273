import * as Yup from 'yup';
import { RECHARGE } from '../../../../../../core-components/CommonConstants';
export default Yup.object().shape({
  amount: Yup.number().when(['tag'], {
    is: (tag) => tag,
    then: Yup.number()
      .required('Amount is required')
      .positive('Amount must be positive')
      .integer('Amount must be an integer')
      .max(5000000, 'Limit is 50L only.')
      .min(1)
  }),
  paymentReference: Yup.string().when(['tag'], {
    is: (tag) => tag && tag === RECHARGE,
    then: Yup.string().required('Paymnet reference is required')
  }),
  invoiceNumber: Yup.string().when(['tag'], {
    is: (tag) => tag && tag === RECHARGE,
    then: Yup.string().required('Invoice number is required')
  }),
  proformaNumber: Yup.string().when(['tag'], {
    is: (tag) => tag && tag === RECHARGE,
    then: Yup.string()
  }),
  additionalInfo: Yup.string().when(['tag'], {
    is: (tag) => tag && tag !== RECHARGE,
    then: Yup.string()
      .trim()
      .required('Additional Info is required')
      .matches(/^[^-\s]/, 'Please enter valid additional info')
  }),
  invoiceUrl: Yup.string().when(['tag'], {
    is: (tag) => tag && tag === RECHARGE,
    then: Yup.string().required('File is required')
  })
});
