import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, isEmpty } from 'lodash';
import { toast } from 'react-toastify';

import {
  getCompanyCategories as getCompanyCategoriesAPI,
  invalidateCompanyCategories as invalidateCompanyCategoriesAPI
} from '../../../actions/admin';
import {
  createCategory as createCategoryAPI,
  editCategory as editCategoryAPI
} from '../../../api/admin';
import validationSchema from './Categories.validation';
import { errToastMessage, getErrorMessage } from '../../../utils/Utlities';

const mapStateToProps = (state) => {
  return {
    companyCategories: (!isEmpty(state.companyCategories) && state.companyCategories) || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyCategories: (companyId, limit, offset, name) =>
      dispatch(getCompanyCategoriesAPI(companyId, limit, offset, name)),
    invalidateCompanyCategories: () => dispatch(invalidateCompanyCategoriesAPI())
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        categoryId: '',
        categoryName: '',
        companyId: new URLSearchParams(document.location.search).get('company_id') || null,
        isEdit: false
      };
      let propsValue = {};
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    handleSubmit: (
      values,
      { setFieldError, setStatus, setSubmitting, isSubmitting, dirty, resetForm, props }
    ) => {
      if (values.isEdit) {
        editCategoryAPI({
          company_id: values.companyId,
          name: values?.categoryName?.trim(),
          user_category_id: values.categoryId
        })
          .then((response) => {
            setSubmitting(false);
            toast.success('Category Updated Successfully.');
            values.handleAfterSubmit();
          })
          .catch((error) => {
            console.error(error);
            errToastMessage(error);
          });
      } else {
        createCategoryAPI({ company_id: values.companyId, name: values?.categoryName?.trim() })
          .then((response) => {
            setSubmitting(false);
            toast.info('Category Created Successfully.');
            values.handleAfterSubmit();
          })
          .catch((error) => {
            console.error(error);
            errToastMessage(error);
          });
      }
    },
    displayName: 'Categories'
  })
);
