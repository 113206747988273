import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';
const useStyles = makeStyles({
  bannerContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '.2rem',
    padding: '5px 10px',
    backgroundColor: '#e0fcff',
    width: '100%'
  }
});
const InternationalChargesBanner = () => {
  const classes = useStyles();
  return (
    <div className={classes.bannerContainer}>
      <InfoIcon style={{ fontSize: 16 }} />
      <span>Note: International charges are included in the package</span>
    </div>
  );
};

export default InternationalChargesBanner;
