import React, { useState, useEffect } from 'react';
import AppConstants from '../../../core-components/AppConstants';
import Breadcrumbs from '../../../core-components/BreadCrumbs';
import { Grid, Radio, Typography, Box, Button, InputAdornment } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { getADCConfig, setADCConfig, getCompanyById } from '../../../api/admin';
import ThreeDots from '../../../core-components/ThreeDotLoader';
import { isEmpty, isInteger } from 'lodash';
import SaveIcon from '@material-ui/icons/Save';
import { toast } from 'react-toastify';
import {
  convertFromIndianNumeration,
  convertToIndianNumeration,
  deepCheck,
  errToastMessage,
  getErrorMessage
} from '../../../utils/Utlities';
const useStyles = makeStyles((theme) => ({
  headingLabel: {
    fontWeight: '500',
    fontSize: '14px'
  },
  blueColor: {
    color: '#4D9BDF'
  },
  container: {
    border: '3px solid #d2dbe2',
    padding: '5px 0px 5px 20px',
    justifyContent: 'end',
    marginTop: '40px'
  },
  checkTypeContainer: {
    borderLeft: '3px solid #d2dbe2',
    borderRight: '3px solid #d2dbe2',
    borderBottom: '3px solid #d2dbe2',
    padding: '5px 0px 5px 20px',
    alignItems: 'center'
  },
  textfield: {
    '& input': {
      color: '#000000'
    },
    '& input:disabled': {
      backgroundColor: '#F7F9FA',
      borderColor: '#ff0000'
    }
  },
  loader: {
    textAlign: 'center'
  },
  activeBtn: {
    margin: theme.spacing(1),
    height: '44px',
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  disabledBtn: {
    margin: theme.spacing(1),
    height: '44px',
    color: '#ffffff',
    borderColor: '#eoeoeo',
    backgroundColor: '#eoeoeo',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#e0e0e0'
    }
  },
  skipBtn: {
    margin: theme.spacing(1),
    height: '44px',
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#1976D2',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#1976D2'
    }
  },
  rupeeSymbol: {
    fontFamily: 'Roboto'
  }
}));

const initialConfig = {
  data: {
    pass_through: {
      ID: {
        config: 'APPROVAL_REQUIRED',
        amount_limit: 0
      },
      EDUCATION_OFFICIAL: {
        config: 'APPROVAL_REQUIRED',
        amount_limit: 0
      },
      EDUCATION_REGIONAL: {
        config: 'APPROVAL_REQUIRED',
        amount_limit: 0
      },
      EDUCATION_HYBRID: {
        config: 'APPROVAL_REQUIRED',
        amount_limit: 0
      },
      COURT: {
        config: 'APPROVAL_REQUIRED',
        amount_limit: 0
      },
      EMPLOYMENT: {
        config: 'APPROVAL_REQUIRED',
        amount_limit: 0
      },
      REF: {
        config: 'APPROVAL_REQUIRED',
        amount_limit: 0
      },
      WORLD: {
        config: 'APPROVAL_REQUIRED',
        amount_limit: 0
      },
      ADDRESS: {
        config: 'APPROVAL_REQUIRED',
        amount_limit: 0
      },
      CIBIL: {
        config: 'APPROVAL_REQUIRED',
        amount_limit: 0
      },
      DRUG: {
        config: 'APPROVAL_REQUIRED',
        amount_limit: 0
      }
    },
    international: {
      ID: {
        config: 'APPROVAL_REQUIRED',
        amount: 1500
      },
      EDUCATION_OFFICIAL: {
        config: 'APPROVAL_REQUIRED',
        amount: 1500
      },
      EDUCATION_REGIONAL: {
        config: 'APPROVAL_REQUIRED',
        amount: 1500
      },
      EDUCATION_HYBRID: {
        config: 'APPROVAL_REQUIRED',
        amount: 1500
      },
      EMPLOYMENT: {
        config: 'APPROVAL_REQUIRED',
        amount: 1500
      },
      REF: {
        config: 'APPROVAL_REQUIRED',
        amount: 1500
      },
      WORLD: {
        config: 'APPROVAL_REQUIRED',
        amount: 0
      },
      ADDRESS: {
        config: 'APPROVAL_REQUIRED',
        amount: 1500
      },
      CIBIL: {
        config: 'APPROVAL_REQUIRED',
        amount: 0
      },
      DRUG: {
        config: 'APPROVAL_REQUIRED',
        amount: 0
      },
      COURT: {
        config: 'APPROVAL_REQUIRED',
        amount: 0
      }
    }
  }
};

const checkDisplayValueMapping = {
  ID: 'ID',
  COURT: 'Court',
  EDUCATION_OFFICIAL: 'Education (Official)',
  EDUCATION_REGIONAL: 'Education (Regional)',
  EDUCATION_HYBRID: 'Education (Hybrid)',
  EMPLOYMENT: 'Employment',
  REF: 'Reference',
  ADDRESS: 'Address',
  WORLD: 'World',
  CIBIL: 'Credit Check',
  DRUG: 'Drug'
};

const RadioButton = withStyles({
  colorSecondary: {
    '&$checked': {
      color: '#675FF5'
    }
  },
  checked: {}
})(Radio);

export default (props) => {
  const classes = useStyles();
  const [companyName, setCompanyName] = useState('Company Name');
  const [companyId, setCompanyId] = useState(
    new URLSearchParams(document.location.search).get('id') || null
  );
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);

  // const [passThroughConfig, setPassThroughConfig] = React.useState({});
  // const [internationalConfig, setInternationalConfig] = React.useState({});
  const [isCreateFlow, setCreateCompanyFlow] = useState(
    new URLSearchParams(document.location.search).get('create') || false
  );
  const [checks, setChecks] = useState({
    passThroughChecks: [],
    internationalChecks: []
  });
  const [configPI, setConfigPI] = useState({
    passThroughConfig: {},
    internationalConfig: {}
  });
  const [initConfig, setInitConfig] = useState({
    passThroughConfig: {},
    internationalConfig: {}
  });
  const [isDirty, setIsDirty] = useState(false);


  const handlePassThroughConfigChange = (checkType, event) => {
    const avlConfig =
      configPI.passThroughConfig[checkType] || initialConfig?.data?.pass_through[checkType];
    const configType = { ...avlConfig, config: event.target.value };
    setConfigPI({
      passThroughConfig: { ...configPI.passThroughConfig, [checkType]: configType },
      internationalConfig: configPI.internationalConfig
    });
  };
  const handleInternationalConfigChange = (checkType, event) => {
    const avlConfig =
      configPI.internationalConfig[checkType] || initialConfig?.data?.international[checkType];
    const configType = { ...avlConfig, config: event.target.value };
    setConfigPI({
      passThroughConfig: configPI.passThroughConfig,
      internationalConfig: { ...configPI.internationalConfig, [checkType]: configType }
    });
  };

  const isAlpha = (ch) => /^[A-Z]$/i.test(ch);

  const handlePassThroughTextChange = (checkType, event) => {
    event.target.value > 0 ? setError(false) : setError(true);
    const avlConfig =
      configPI.passThroughConfig[checkType] || initialConfig?.data?.pass_through[checkType];
    const isNumeric = !isAlpha(event.target.value);
    const amount = {
      ...avlConfig,
      amount_limit: isEmpty(event.target.value)
        ? 0
        : parseInt(isNumeric ? convertFromIndianNumeration(event.target.value) : 0, 10)
    };
    setConfigPI({
      passThroughConfig: { ...configPI.passThroughConfig, [checkType]: amount },
      internationalConfig: configPI.internationalConfig
    });
  };

  const handleInternationalTextChange = (checkType, event) => {
    event.target.value > 0 ? setError(false) : setError(true);
    const avlConfig =
      configPI.internationalConfig[checkType] || initialConfig.data.international[checkType];
    const amount = {
      ...avlConfig,
      amount: isEmpty(event.target.value)
        ? 0
        : parseInt(convertFromIndianNumeration(event.target.value), 10)
    };
    setConfigPI({
      passThroughConfig: configPI.passThroughConfig,
      internationalConfig: { ...configPI.internationalConfig, [checkType]: amount }
    });
  };

  //If have to remove or add any check then configure initialConfig accordingly
  const setConfig = (passThrough, international, checksConfig) => {
    let pass_through_checks = [];
    let intlChks = [];

    if (checksConfig && !isEmpty(checksConfig)) {
      intlChks = checksConfig?.international || [];
      pass_through_checks = checksConfig?.pass_through || [];
    }
    if (isEmpty(intlChks)) {
      intlChks = checks.internationalChecks;
    }
    if (isEmpty(pass_through_checks)) {
      pass_through_checks = checks.passThroughChecks;
    }

    let passThroughTemp = {};
    let internationalTemp = {};
    Object.keys(checkDisplayValueMapping).forEach((check) => {
      if (pass_through_checks.includes(check)) {
        passThroughTemp[check] = passThrough[check] || initialConfig.data.pass_through[check];
      }
      if (intlChks.includes(check)) {
        internationalTemp[check] = international[check] || initialConfig.data.international[check];
      }
    });
    setConfigPI({
      passThroughConfig: passThroughTemp,
      internationalConfig: internationalTemp
    });
    setInitConfig({
      passThroughConfig: passThroughTemp,
      internationalConfig: internationalTemp
    });
  };

  useEffect(() => {
    getCompanyById(companyId)
      .then((res) => {
        setCompanyName(res.data.data.name);
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  }, []);

  useEffect(() => {
    getADCConfig(companyId)
      .then((res) => {
        if (res.data && res.data.data && res.data.data.config) {
          if (
            res.data.data.config.international &&
            Array.isArray(res.data.data.config.international) &&
            res.data.data.config.pass_through &&
            Array.isArray(res.data.data.config.pass_through)
          ) {
            setChecks({
              passThroughChecks: res.data.data.config.pass_through,
              internationalChecks: res.data.data.config.international
            });
            if (isEmpty(res.data.data.pass_through) && isEmpty(res.data.data.international)) {
              setConfigPI({
                passThroughConfig: Object.fromEntries(
                  res.data.data.config.pass_through.map((check) => {
                    return [check, initialConfig.data.pass_through[check]];
                  })
                ),
                internationalConfig: Object.fromEntries(
                  res.data.data.config.international.map((check) => [
                    check,
                    initialConfig.data.international[check]
                  ])
                )
              });
              setLoading(false);
            }
          }
          if (!isEmpty(res.data.data.pass_through) && !isEmpty(res.data.data.international)) {
            setConfig(
              res.data.data.pass_through,
              res.data.data.international,
              res.data.data.config
            );
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  }, []);

  useEffect(() => {
    let isError = false;
    Object.entries(configPI.passThroughConfig).forEach(([key, { config, amount_limit }]) => {
      if (config === 'APPROVE_TILL_LIMIT' && (amount_limit < 1 || !isInteger(+amount_limit))) {
        isError = true;
      }
    });
    Object.entries(configPI.internationalConfig).forEach(([key, { config, amount }]) => {
      if (
        ['ALWAYS_APPROVE', 'ALWAYS_REJECT', 'APPROVAL_REQUIRED'].includes(config) &&
        (amount < 1 || !isInteger(+amount))
      ) {
        isError = true;
      }
    });
    setError(isError);
  }, [configPI]);

  useEffect(() => {
    const res = deepCheck(initConfig, configPI);

    setIsDirty(res);
  }, [initConfig, configPI]);

  let breadCrumbsArray = [
    { label: 'Home', href: `${AppConstants.baseURL}dashboard` },
    { label: 'Companies', href: `${AppConstants.baseURL}companies` },
    {
      label: companyName,
      href: `${AppConstants.baseURL}company/view?id=${companyId}`
    }
  ];

  const getPassThroughRadioButton = (checkType, configType) => {
    let configTypeValue = 'APPROVAL_REQUIRED'; // in case no value is present
    configTypeValue = configType.config;
    return (
      <Grid item md={4}>
        <Box display='flex' justifyContent='space-between' alignItems={'center'}>
          <Grid item className={classes.innerGrid}>
            <RadioButton
              checked={configTypeValue === 'ALWAYS_REJECT'}
              onChange={(event) => handlePassThroughConfigChange(checkType, event)}
              value='ALWAYS_REJECT'
              name='radio-buttons'
              inputProps={{ 'aria-label': 'A' }}
              style={{ width: 50 }}
            />
          </Grid>
          <Grid item className={classes.innerGrid}>
            <RadioButton
              checked={configTypeValue === 'ALWAYS_APPROVE'}
              onChange={(event) => handlePassThroughConfigChange(checkType, event)}
              value='ALWAYS_APPROVE'
              name='radio-buttons'
              inputProps={{ 'aria-label': 'A' }}
              style={{ width: 50 }}
            />
          </Grid>
          <Grid item className={classes.innerGrid}>
            <RadioButton
              checked={configTypeValue === 'APPROVAL_REQUIRED'}
              onChange={(event) => handlePassThroughConfigChange(checkType, event)}
              value='APPROVAL_REQUIRED'
              name='radio-buttons'
              inputProps={{ 'aria-label': 'A' }}
              style={{ width: 50 }}
            />
          </Grid>
          <Grid item className={classes.innerGrid}>
            <RadioButton
              checked={configTypeValue === 'APPROVE_TILL_LIMIT'}
              onChange={(event) => handlePassThroughConfigChange(checkType, event)}
              value='APPROVE_TILL_LIMIT'
              name='radio-buttons'
              inputProps={{ 'aria-label': 'A' }}
              style={{ width: 50 }}
            />
          </Grid>
          <Grid item className={classes.innerGrid} style={{ marginRight: '-15px' }}>
            <div className='inputAdornMent'>
              <TextField
                id='full-width-text-field'
                margin='normal'
                type='text'
                variant='outlined'
                label='Amount'
                className={classes.textfield}
                disabled={configType.config === 'APPROVE_TILL_LIMIT' ? false : true}
                error={
                  configType.config === 'APPROVE_TILL_LIMIT' &&
                    (configType.amount_limit < 1 || !isInteger(+configType.amount_limit))
                    ? true
                    : false
                }
                value={convertToIndianNumeration(configType.amount_limit)}
                onChange={(e) => handlePassThroughTextChange(checkType, e)}
                style={{ width: 80 }}
                size='small'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <span>&#8377;</span>
                    </InputAdornment>
                  )
                }}
              />
            </div>
          </Grid>
        </Box>
      </Grid>
    );
  };
  const getInternationalRadioButton = (checkType, configType) => {
    let configTypeValue;
    configTypeValue = configType.config;
    return (
      <Grid item md={4}>
        <Box display='flex' justifyContent='space-between' alignItems={'center'}>
          <Grid item className={classes.innerGrid}>
            <RadioButton
              checked={configTypeValue === 'INCLUDED_IN_PACKAGE'}
              onChange={(event) => handleInternationalConfigChange(checkType, event)}
              value='INCLUDED_IN_PACKAGE'
              name='radio-buttons'
              inputProps={{ 'aria-label': 'A' }}
              style={{ width: 50 }}
            />
          </Grid>
          <Grid item className={classes.innerGrid}>
            <RadioButton
              checked={configTypeValue === 'ALWAYS_REJECT'}
              onChange={(event) => handleInternationalConfigChange(checkType, event)}
              value='ALWAYS_REJECT'
              name='radio-buttons'
              inputProps={{ 'aria-label': 'A' }}
              style={{ width: 50 }}
            />
          </Grid>
          <Grid item className={classes.innerGrid}>
            <RadioButton
              checked={configTypeValue === 'ALWAYS_APPROVE'}
              onChange={(event) => handleInternationalConfigChange(checkType, event)}
              value='ALWAYS_APPROVE'
              name='radio-buttons'
              inputProps={{ 'aria-label': 'A' }}
              style={{ width: 50 }}
            />
          </Grid>
          <Grid item className={classes.innerGrid}>
            <RadioButton
              checked={configTypeValue === 'APPROVAL_REQUIRED'}
              onChange={(event) => handleInternationalConfigChange(checkType, event)}
              value='APPROVAL_REQUIRED'
              name='radio-buttons'
              inputProps={{ 'aria-label': 'A' }}
              style={{ width: 50 }}
            />
          </Grid>
          <Grid item className={classes.innerGrid} style={{ marginRight: '-15px' }}>
            <div className='inputAdornMent'>
              <TextField
                id='full-width-text-field'
                margin='normal'
                type='text'
                variant='outlined'
                label='Amount'
                className={classes.textfield}
                value={convertToIndianNumeration(configType.amount)}
                error={
                  ['ALWAYS_APPROVE', 'ALWAYS_REJECT', 'APPROVAL_REQUIRED'].includes(
                    configType.config
                  ) &&
                    (configType.amount < 1 || !isInteger(+configType.amount))
                    ? true
                    : false
                }
                disabled={configType.config === 'INCLUDED_IN_PACKAGE' ? true : false}
                style={{ width: 80 }} //assign the width as your requirement
                size='small'
                onChange={(e) => handleInternationalTextChange(checkType, e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <span className={classes.rupeeSymbol}>&#8377;</span>
                    </InputAdornment>
                  )
                }}
              />
            </div>
          </Grid>
        </Box>
      </Grid>
    );
  };

  const renderRadioButtons = () => {
    let radioButtonsRow = [];
    Object.entries(checkDisplayValueMapping).map(([key, value]) => {
      const isPassthrough = checks.passThroughChecks.includes(key);
      const isInternational = checks.internationalChecks.includes(key);
      const passThroughConfigValue =
        configPI.passThroughConfig[key] || initialConfig?.data?.pass_through[key];
      const internationalConfigValue =
        configPI.internationalConfig[key] || initialConfig.data.international[key];
      if (isPassthrough || isInternational) {
        radioButtonsRow.push(
          <Grid
            container
            key={`${passThroughConfigValue?.id}${internationalConfigValue?.id}`}
            className={classes.checkTypeContainer}
          >
            <Grid item md={2}>
              <Typography className={classes.headingLabel}>{value}</Typography>
            </Grid>
            {isPassthrough && passThroughConfigValue ? (
              getPassThroughRadioButton(key, passThroughConfigValue)
            ) : (
              <Grid item md={4}></Grid>
            )}
            <Grid item md={1}></Grid>
            {isInternational && internationalConfigValue ? (
              getInternationalRadioButton(key, internationalConfigValue)
            ) : (
              <Grid item md={4}></Grid>
            )}
          </Grid>
        );
      }
    });
    return radioButtonsRow;
  };

  const getValidity = (finalData) => {
    let error1 = false,
      error2 = false;
    for (const key in finalData) {
      if (key === 'pass_through') {
        for (const key2 in finalData[key]) {
          const cost1 = finalData[key][key2].amount_limit;
          const type = finalData[key][key2].config;
          if (
            ['APPROVE_TILL_LIMIT'].includes(type) &&
            (cost1 < 1 || !isInteger(+cost1) || cost1 > 50000)
          ) {
            error1 = true;
          }
        }
      } else if (key === 'international') {
        for (const key2 in finalData[key]) {
          const cost2 = finalData[key][key2].amount;
          const type = finalData[key][key2].config;
          if (
            ['ALWAYS_APPROVE', 'ALWAYS_REJECT', 'APPROVAL_REQUIRED'].includes(type) &&
            (cost2 < 1 || !isInteger(+cost2) || cost2 > 50000) &&
            !isCreateFlow
          ) {
            error2 = true;
          }
        }
      }
    }
    if (error1 || error2) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmitHandler = (name) => {
    if (isSaveButtonClicked) {
      return;
    }
    setIsSaveButtonClicked(true);

    const finalData = {
      company_id: companyId,
      pass_through: configPI.passThroughConfig,
      international: configPI.internationalConfig
    };

    const error = getValidity(finalData);

    if (name !== 'skip') {
      if (error) {
        errToastMessage('Amount entered must be an Positive Integer and less than 50,000');
        setError(true);
      } else {
        setADCConfig(finalData)
          .then((response) => {
            setError(false);
            setLoading(true);
            toast.success('Additional Cost Configuration Saved Successfully');
            if (response.data && isCreateFlow) {
              window.open(`${AppConstants.baseURL}companies`, '_self');
              return;
            }
            getADCConfig(companyId)
              .then((res) => {
                if (res.data && res.data.data && !isEmpty(res.data.data.pass_through) && !isEmpty(res.data.data.international)) {
                  setConfig(res.data.data.pass_through, res.data.data.international);
                  setLoading(false);
                  setError(false);
                  setInitConfig({
                    passThroughConfig: res.data.data.pass_through,
                    internationalConfig: res.data.data.international
                  });
                  setIsDirty(false);
                } else {
                  setConfigPI({
                    passThroughConfig: initialConfig?.data?.pass_through,
                    internationalConfig: initialConfig?.data?.international
                  });
                  setLoading(false);
                  setError(false);
                }
                setIsSaveButtonClicked(false);
              });
          })
          .catch((error) => {
            errToastMessage(error);
            setIsSaveButtonClicked(false);
          });
      }
    } else {
      window.open(`${AppConstants.baseURL}companies`, '_self');
      setIsSaveButtonClicked(false);
    }
  };

  return (
    <>
      <Breadcrumbs linksArray={breadCrumbsArray} current={'Additional Cost Config'} />
      {!loading && (
        <>
          <Grid container className={classes.container}>
            <Grid item md={2}></Grid>
            <Grid item md={4}>
              <Typography className={classes.headingLabel}>Pass Through</Typography>
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={4}>
              <Typography className={classes.headingLabel}>International</Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.checkTypeContainer}>
            <Grid item md={2}>
              <Typography className={classes.headingLabel}>Check</Typography>
            </Grid>
            <Grid item md={4}>
              <Box display='flex' justifyContent='space-between'>
                <Typography className={classes.headingLabel}>
                  Always<br></br>Reject
                </Typography>
                <Typography className={classes.headingLabel}>
                  Always<br></br>Approve
                </Typography>
                <Typography className={classes.headingLabel}>
                  Approval<br></br>Required
                </Typography>
                <Typography className={`${classes.headingLabel} ${classes.blueColor}`}>
                  Approve<br></br>upto
                </Typography>
                <Typography className={`${classes.headingLabel} ${classes.blueColor}`}>
                  Amount<br></br>upto
                </Typography>
              </Box>
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={4}>
              <Box display='flex' justifyContent='space-between'>
                <Typography className={classes.headingLabel}>
                  Always<br></br>Included
                </Typography>
                <Typography className={`${classes.headingLabel} ${classes.blueColor}`}>
                  Always<br></br>Reject
                </Typography>
                <Typography className={`${classes.headingLabel} ${classes.blueColor}`}>
                  Always<br></br>Approve
                </Typography>
                <Typography className={`${classes.headingLabel} ${classes.blueColor}`}>
                  Approval<br></br>Required
                </Typography>
                <Typography className={`${classes.headingLabel} ${classes.blueColor}`}>
                  Amount<br></br>Upto
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
      {!loading && renderRadioButtons()}
      {!loading && (
        <Grid container direction='column' alignItems='flex-end'>
          <Grid item md={4}>
            {isCreateFlow ? (
              <>
                <Button
                  variant='contained'
                  size='large'
                  type='submit'
                  className={classes.skipBtn}
                  // disabled={!isValidForm}
                  startIcon={<SaveIcon />}
                  onClick={() => handleSubmitHandler('skip')}
                >
                  Skip
                </Button>
                <Button
                  variant='contained'
                  size='large'
                  type='submit'
                  className={error ? classes.disabledBtn : classes.activeBtn}
                  disabled={error || isDirty || isSaveButtonClicked}
                  startIcon={<SaveIcon />}
                  onClick={() => handleSubmitHandler('finish')}
                >
                  Save
                </Button>
              </>
            ) : (
              <Button
                variant='contained'
                size='large'
                type='submit'
                className={error ? classes.disabledBtn : classes.activeBtn}
                disabled={error || isDirty || isSaveButtonClicked}
                startIcon={<SaveIcon />}
                onClick={() => handleSubmitHandler('save')}
              >
                Save
              </Button>
            )}
          </Grid>
        </Grid>
      )}

      {loading && (
        <div className={classes.loader}>
          <ThreeDots />
        </div>
      )}
    </>
  );
};
