import React, { useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, IconButton, Button, Card, CardContent, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PublicIcon from '@material-ui/icons/Public';
import LockIcon from '@material-ui/icons/Lock';

import { commentDateTimeFormat } from '../../../../core-components/CommonConstants';
import ConfirmationPopup from '../../../../core-components/ConfirmationPopup';
import AddInsuffComments from '../../AddInsuffComments';
import { errToastMessage, getErrorMessage } from '../../../../utils/Utlities';
import { setManualInsuffComment } from '../../../../api/admin';

const useStyles = makeStyles({
  headingContainer: {
    background: '#132743',
    color: '#f6f5f5',
    borderRadius: '4px',
    minHeight: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mainContainer: {
    marginBottom: 20
  },
  cardRoot: {
    backgroundColor: '#f6f5f5',
    paddingBottom: '16px !important'
  },
  unreviewBtn: {
    background: '#f05454',
    marginLeft: '8px',
    marginRight: '8px',
    padding: '3px 5px',
    borderRadius: '4px',
    cursor: 'default',
    color: '#ffffff',
    fontFamily: 'Poppins',
    fontSize: '14px'
  },
  iconBtn: {
    padding: '8px'
  },
  commentContainer: {
    maxHeight: '120px',
    overflowY: 'auto'
  },
  commentText: {
    maxHeight: 'inherit',
    lineHeight: '24px'
  }
});

export default (props) => {
  const classes = useStyles();
  const [displayAddInsuff, setDisplayAddInsuff] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [isEditComment, setIsEditComment] = useState(false);
  const [selecInsuffComment, setSelecInsuffComment] = useState(null);

  const handleCommentDelete = (value) => {
    setManualInsuffComment(value, 'DEL')
      .then((response) => {
        props.getInsuffReview(null, props.page, props.offset, true);
        setSelecInsuffComment(null);
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  };

  const handleSubmitAction = () => {
    props.getInsuffReview(null, props.page, props.offset, true);
  };

  const validateDelete = (status) => {
    if (['RESOLVED', 'UNRESOLVED'].includes(status)) {
      return 'not-allowed';
    } else {
      return 'pointer';
    }
  };

  const extractContentFromHtml = (html) => {
    return new DOMParser().parseFromString(html, 'text/html').documentElement.textContent;
  };

  return (
    <>
      <Box mb={2} mt={1} pt={1} pb={1} className={classes.headingContainer}>
        <Typography variant='subtitle2'>{'Unreviewed comments'}</Typography>
      </Box>
      {props?.comments?.length
        ? props?.comments.map((comment, index) => {
            return (
              <Grid
                container
                className={classes.mainContainer}
                key={`comments_${comment.uuid}_${index}`}
              >
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card variant={'outlined'} raised={true}>
                    <CardContent className={classes.cardRoot}>
                      <Grid
                        container
                        spacing={1}
                        style={{ alignItems: 'baseline', marginBottom: '20px' }}
                      >
                        <Grid item md={10} xs={10} lg={10} className={classes.commentContainer}>
                          <Typography
                            variant='subtitle2'
                            gutterBottom
                            className={classes.commentText}
                          >
                            {comment.comment
                              ? extractContentFromHtml(comment.comment) || comment.comment
                              : ''}
                          </Typography>
                        </Grid>
                        <Grid item md={2} xs={2} lg={2} style={{ textAlign: 'right' }}>
                          <IconButton
                            className={classes.iconBtn}
                            aria-label='edit'
                            onClick={() => {
                              setIsEditComment(true);
                              setSelecInsuffComment(comment);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            className={classes.iconBtn}
                            style={{
                              cursor: validateDelete(props.status)
                            }}
                            aria-label='delete'
                            onClick={() => {
                              setShowConfirmationPopup(true);
                              setSelecInsuffComment({
                                manualInsufficiencyCommentId: comment.id
                              });
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} style={{ alignItems: 'end' }}>
                        <Grid
                          item
                          lg={8}
                          md={8}
                          sm={6}
                          xs={6}
                          style={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <Button
                            size={'small'}
                            startIcon={comment.type === 'PUBLIC' ? <PublicIcon /> : <LockIcon />}
                          >
                            {comment.type === 'PUBLIC' ? 'Public' : 'Private'}
                          </Button>
                          <span
                            color='primary'
                            variant={'contained'}
                            size={'small'}
                            className={classes.unreviewBtn}
                          >
                            {'Unreviewed'}
                          </span>
                        </Grid>
                        <Grid item container lg={4} md={12} sm={6} xs={6}>
                          {comment.createdAt ? (
                            <Grid item>
                              <Typography variant='caption' display='block' gutterBottom>
                                {`Added by ${comment.addedByUser} - `}
                                {comment.createdAt
                                  ? moment(comment.createdAt).format(commentDateTimeFormat)
                                  : null}
                              </Typography>
                            </Grid>
                          ) : null}
                          {comment.updatedByUser ? (
                            <Grid item>
                              <Typography variant='caption' display='block' gutterBottom>
                                {`Updated by ${comment.updatedByUser} - `}
                                {comment.updatedAt
                                  ? moment(comment.updatedAt).format(commentDateTimeFormat)
                                  : null}
                              </Typography>
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            );
          })
        : null}

      {isEditComment ? (
        <AddInsuffComments
          tktDetails={props.tktDetails}
          open={isEditComment}
          isEdit={isEditComment}
          comments={{ ...selecInsuffComment, text: selecInsuffComment.comment }}
          action={handleSubmitAction}
          handleClose={() => {
            setIsEditComment(false);
            setSelecInsuffComment(null);
          }}
        />
      ) : null}

      {showConfirmationPopup ? (
        <ConfirmationPopup
          open={showConfirmationPopup}
          title={'Delete Comment'}
          content={'Are you sure want to delete comment ?'}
          handleSubmit={() => {
            handleCommentDelete(selecInsuffComment);
            setShowConfirmationPopup(false);
          }}
          handleClose={() => {
            setShowConfirmationPopup(false);
          }}
          buttonLabel={'Delete'}
        />
      ) : null}
    </>
  );
};
