import React, { useState, useEffect } from 'react';

import { has, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Typography, IconButton, Box, CircularProgress } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

import ConfirmationPopup from '../../../../../core-components/ConfirmationPopup';
import { assignCompanyToPackage } from '../../../../../api/admin';
import {
  getErrorMessage,
  toSnakeCase,
  mapConfig,
  errToastMessage
} from '../../../../../utils/Utlities';

import Step1 from './AssignCompanyStep1';
import Step2 from './AssignCompanyStep2';
import Step3 from './AssignCompanyStep3';
import Step2PostPaid from './AssignCompanyStep2PostPaid';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles((theme) => ({
  progressContainer: {
    textAlign: 'center',
    width: '100%'
  },
  maxWidthDialog: {
    maxWidth: 800
  },
  headingSubText: {
    fontSize: 16,
    color: theme.palette.text.secondary
  },
  dialogContent: {
    paddingBottom: theme.spacing(4)
  }
}));

export default (props) => {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(1);
  const [values, setValues] = useState(null);
  const [loading, setLoading] = useState(false);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [packageType, setPackageType] = useState(null);
  const [packageId, setPackageId] = useState(
    new URLSearchParams(document.location.search).get('id') || null
  );
  const { companyDetails, isAadhaarAllowed } = props;
  const [config, setConfig] = useState(null); // this is for step3 config for create company

  const handleStepChange = (step) => {
    setCurrentStep(step);
  };

  const handleClose = () => {
    props.handleClose();
  };

  const getFormattedConfig = (config = {}) => {
    let arr = [];

    arr = Object.keys(config).map((item) => {
      return { ...mapConfig(config[item]), subtypeId: item.toString() };
    });
    return arr;
  };

  // getting only assigned package text
  const getUpdatedPackageText = (packageText) => {
    return packageText.filter((val) => val.isAssigned);
  };
  // Handle final submit for company assignment
  const handleSubmit = () => {
    var where = {
      rechargeDetails: (values.step2 && values.step2.rechargeDetails) || [],
      packageText: (values.step2 && values.step2.packageText) || [],
      config: getFormattedConfig(values.step3) || []
    };
    const updatedPackageText = getUpdatedPackageText(where.packageText);
    //updating package text which are assigned
    where = {
      ...where,
      packageText: updatedPackageText
    };
    setDisplayConfirmation(false);
    if (props.title === 'Create Company' && props.usedAt !== 'companyPackageView') {
      where['packageId'] = values.step1 ? values.step1.id : null;
      where['packageName'] = values.step1 ? values.step1.packageName : null;
      where['subtypes'] = values.step1 ? values.step1.subtypes : null;

      props.handleClose(true, where, values);
    } else {
      where['packageId'] =
        props.usedAt !== 'companyPackageView'
          ? props.packageId
          : values.step1
          ? values.step1.id
          : null;
      where['companyId'] =
        props.usedAt !== 'companyPackageView'
          ? (values.step1 && parseInt(values.step1.id)) || null
          : props.companyId;
      where['companyName'] =
        props.usedAt !== 'companyPackageView'
          ? values.step1 && (values.step1.companyName || values.step1.name)
          : props.companyName;

      assignCompanyToPackage(toSnakeCase(where))
        .then(() => {
          toast.success('Package assigned Successfully');
          props.invalidatePackageCompanyList();
          // TODO:  Add company ID in case company package view
          props.usedAt !== 'companyPackageView'
            ? props.getPackageCompanyList({ packageId, limit: 10, offset: 0 })
            : props.afterSubmit();
          props.handleClose();
        })
        .catch((error) => {
          console.error('Error', error);
          errToastMessage(error);
        });
    }
  };

  const startSubmit = () => {
    setDisplayConfirmation(true);
  };

  const handleStepSubmit = (type, formValues, nextStep = null) => {
    let currentValues = { ...values };
    currentValues[type] = formValues;

    setValues(currentValues);

    if (nextStep && nextStep !== 'submit') {
      handleStepChange(nextStep);
    } else if (nextStep === 'submit') {
      startSubmit();
    }
  };

  // Fetching package config for step2 create company
  useEffect(() => {
    if (props.title === 'Create Company' && currentStep === 2) {
      props.invalidatePackageDetails();
      props.getPackageDetails(values.step1.id);
    }
  }, [currentStep]);

  useEffect(() => {
    if (props.title === 'Create Company' && props.packageDetails && props.packageDetails.data) {
      setConfig(props?.packageDetails?.data?.packageConfig);
    }
  }, [props.packageDetails]);

  // While creating company we have edit option for assign package so prefilling value here
  useEffect(() => {
    if (props.title === 'Create Company' && props.prefilledValue) {
      setValues(props.prefilledValue);
    }
    if (props && props.title === 'Create Company') {
      setPackageType(props.packageType);
    }
    if (
      has(props.packageDetails, 'data') &&
      !isEmpty(props.packageDetails.data) &&
      has(props.packageDetails.data, 'packageDetails') &&
      !isEmpty(props.packageDetails.data.packageDetails)
    ) {
      setPackageType(props?.packageDetails?.data?.packageDetails?.packageType);
    }
  }, []);

  const getFormBasedOnStep = (step) => {
    switch (step) {
      case 1:
        return (
          <Step1
            handleStepSubmit={(type, formValues, nextStep) =>
              handleStepSubmit(type, formValues, nextStep)
            }
            packageType={props.packageType}
            handleStepChange={(step) => handleStepChange(step)}
            parent={props.title === 'Create Company' ? 'COMPANY' : 'PACKAGE'}
            savedValues={values && values.step1 ? values.step1 : null}
          />
        );
      case 2:
        return packageType === 'PREPAID' ? (
          <Step2
            handleStepSubmit={(type, formValues, nextStep) =>
              handleStepSubmit(type, formValues, nextStep)
            }
            isAadhaarAllowed={
              values?.step1?.candidateBgvformConfig?.includeAadhaarInIdCheck
                ? values?.step1?.candidateBgvformConfig?.includeAadhaarInIdCheck === 1
                  ? true
                  : false
                : !isEmpty(companyDetails)
                ? companyDetails?.isAadhaarAllowed === 1
                  ? true
                  : false
                : isAadhaarAllowed
                ? true
                : false
            }
            handleStepChange={(step) => handleStepChange(step)}
            parent={props.title === 'Create Company' ? 'COMPANY' : 'PACKAGE'}
            pConfig={props.title === 'Create Company' ? config : props.pConfig}
            savedValues={values || null}
            packageBaseCost={
              parseInt(props?.packageDetails?.data?.packageDetails?.packageBaseCost, 10) >= 0
                ? props?.packageDetails?.data?.packageDetails?.packageBaseCost
                : ''
            }
          />
        ) : (
          <Step2PostPaid
            handleStepSubmit={(type, formValues, nextStep) =>
              handleStepSubmit(type, formValues, nextStep)
            }
            isAadhaarAllowed={
              values?.step1?.candidateBgvformConfig?.includeAadhaarInIdCheck
                ? values?.step1?.candidateBgvformConfig?.includeAadhaarInIdCheck === 1
                  ? true
                  : false
                : !isEmpty(companyDetails)
                ? companyDetails?.isAadhaarAllowed === 1
                  ? true
                  : false
                : isAadhaarAllowed
                ? true
                : false
            }
            handleStepChange={(step) => handleStepChange(step)}
            parent={props.title === 'Create Company' ? 'COMPANY' : 'PACKAGE'}
            pConfig={props.title === 'Create Company' ? config : props.pConfig}
            savedValues={values || null}
            packageBaseCost={
              parseInt(props?.packageDetails?.data?.packageDetails?.packageBaseCost, 10) >= 0
                ? props?.packageDetails?.data?.packageDetails?.packageBaseCost
                : ''
            }
          />
        );
      case 3:
        return (
          <Step3
            handleStepSubmit={(type, formValues, nextStep) =>
              handleStepSubmit(type, formValues, nextStep)
            }
            isAadhaarAllowed={
              values?.step1?.candidateBgvformConfig?.includeAadhaarInIdCheck
                ? values?.step1?.candidateBgvformConfig?.includeAadhaarInIdCheck === 1
                  ? true
                  : false
                : !isEmpty(companyDetails)
                ? companyDetails?.isAadhaarAllowed === 1
                  ? true
                  : false
                : isAadhaarAllowed
                ? true
                : false
            }
            handleStepChange={(step) => handleStepChange(step)}
            parent={props.title === 'Create Company' ? 'COMPANY' : 'PACKAGE'}
            pConfig={props.title === 'Create Company' ? config : props.pConfig}
            savedValues={values || null}
          />
        );
      default:
        return (
          <Step1
            handleStepSubmit={(type, formValues, nextStep) =>
              handleStepSubmit(type, formValues, nextStep)
            }
            parent={props.title === 'Create Company' ? 'COMPANY' : 'PACKAGE'}
            handleStepChange={(step) => handleStepChange(step)}
            savedValues={values && values.step1 ? values.step1 : null}
          />
        );
    }
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={handleClose}
        maxWidth='md'
        fullWidth
        aria-labelledby='assign-company-to-package'
      >
        <DialogTitle id='add-subType-dialog-title' onClose={handleClose}>
          <Typography variant='h5' gutterBottom>
            {props.title === 'Create Company'
              ? 'Assign package to company'
              : 'Add company to package'}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {!loading ? (
            <>{getFormBasedOnStep(currentStep)}</>
          ) : (
            <Box mt={3} mb={3} className={classes.progressContainer}>
              <CircularProgress size={30} />
            </Box>
          )}
        </DialogContent>
      </Dialog>

      {displayConfirmation ? (
        <ConfirmationPopup
          open={displayConfirmation}
          parent={
            props.title === 'Create Company'
              ? 'Confirm adding package to company'
              : 'Confirm adding company to package'
          }
          content={
            props.title === 'Create Company'
              ? 'Are you sure you want to add this package to this company ?'
              : 'Are you sure you want to add this company to this package ?'
          }
          handleSubmit={() => {
            handleSubmit();
          }}
          handleClose={() => {
            setDisplayConfirmation(false);
          }}
          buttonLabel={'Save'}
        />
      ) : null}
    </>
  );
};
