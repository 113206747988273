import React, { useState, useEffect } from 'react';
import styles from './ZohoBookDialog.module.scss';
import {
  TextField,
  DialogTitle,
  Grid,
  Dialog,
  Button,
  Typography,
  TextareaAutosize,
  InputAdornment
} from '@material-ui/core';
import {
  STATE_CODES,
  TAX_TYPES,
  ZOHO_BOOK_DIALOG_TITLE
} from '../../../core-components/CommonConstants';
import EditIcon from '@material-ui/icons/EditOutlined';
import { WarningOutlined } from '@material-ui/icons';
import FuzzySet from 'fuzzyset';
import { toast } from 'react-toastify';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import { getZohoBookDetails } from '../../../api/admin';
import moment from 'moment';
import { errToastMessage, getTrimmedValue } from '../../../utils/Utlities';

const ZohoBookDialog = (props) => {
  const {
    onClose,
    open,
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
    zohoBookID,
    autoFetch,
    handleAutoFetch
  } = props;
  const [isFetchDisabled, setFetchDisabled] = useState(false);
  const [isMismatchExists, setIsMismatchExists] = useState(true);
  const [isCriticalErrorExists, setIsCriticalErrorExists] = useState(false);
  const [isFetched, setIsFetched] = useState({
    fetch: false,
    fetchTime: null,
    loading: false
  });
  const [matchData, setMatchData] = useState({
    GST: 100,
    TAX: -1,
    COMPANY_NAME: -1,
    STATE: -1
  });
  const [isZohoFieldDisabled, setIsZohoFieldDisabled] = useState(zohoBookID ? true : false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);

  useEffect(() => {
    setIsSaveButtonDisabled(isSaveButtonClicked);
  }, [isSaveButtonClicked]);

  const handleSaveClick = () => {
    setIsSaveButtonClicked(true);
    handleSubmit();
  };

  const matchGstAndState = (MATCH_DATA_VALUES) => {
    /*
    CASE 1: ZB has GST // SV does not have GST
            Failure:

            GST Mismatch

            Place of supply won't be validated (but does not matter as GST has failed)

    CASE 2:ZB does not have GST // SV has GST

            Failure:

            GST Mismatch

            Place of supply will. be validated as well

    CASE 3: Both ZB and SV do not have GST

            GST will be validated and pass (since both are null)

            Place of Supply will not be validated

    CASE 4: Both ZB and SV have GST
            
            GST will be validated.

            Place of Supply will be validated.
     */

    let newMatchData = MATCH_DATA_VALUES;
    const svGST = values?.svGST?.trim();
    const zbGST = values?.zohoGST?.trim();
    const svState = values?.svState?.toLowerCase()?.trim();
    const zbState = values?.zohoState?.toLowerCase()?.trim();

    if (svGST === '') {
      if (zbGST === '') {
        /* CASE-3 */
        newMatchData.GST = 100;
        newMatchData.STATE = -10;
      } else {
        /* CASE-1 */

        newMatchData.GST = 0;
        newMatchData.STATE = -10;
      }
    } else if (zbGST === '') {
      /* CASE-2 */
      newMatchData.GST = 0;
      if (svState !== '' && zbState !== '') {
        if (zbState?.toLowerCase() !== svState?.toLowerCase()) newMatchData.STATE = 0;
        else newMatchData.STATE = 100;
      }
    } else {
      /* CASE-4 */
      if (zbGST !== svGST) newMatchData.GST = 0;
      else newMatchData.GST = 100;

      if (svState !== '' && zbState !== '') {
        if (zbState?.toLowerCase() !== svState?.toLowerCase()) newMatchData.STATE = 0;
        else newMatchData.STATE = 100;
      } else {
        newMatchData.STATE = 0;
      }
    }

    return newMatchData;
  };

  useEffect(() => {
    let MATCH_DATA_VALUES = matchData;

    MATCH_DATA_VALUES = matchGstAndState(MATCH_DATA_VALUES);

    if (values?.svTaxType !== '' && values.zohoTaxType !== '') {
      if (values.zohoTaxType !== values?.svTaxType) MATCH_DATA_VALUES.TAX = 0;
      else MATCH_DATA_VALUES.TAX = 100;
    }

    if (isFetched.fetch) {
      const svCompanyName = values?.svCompanyName?.toLowerCase().trim();
      const zohoCompanyName = values?.zohoCompanyName?.toLowerCase().trim();

      if (svCompanyName === '' || zohoCompanyName === '') {
        MATCH_DATA_VALUES.COMPANY_NAME = 0;
      } else if (svCompanyName === zohoCompanyName) {
        MATCH_DATA_VALUES.COMPANY_NAME = 100;
      } else {
        //** Fuzzy Match */
        var fuzzyMatchInstance = new FuzzySet();
        var matchResult = 0;
        fuzzyMatchInstance.add(svCompanyName);
        matchResult =
          fuzzyMatchInstance.get(zohoCompanyName, '', 0.01) &&
          fuzzyMatchInstance.get(zohoCompanyName, '', 0.01)[0][0] * 100;
        switch (true) {
          case matchResult < 25: {
            MATCH_DATA_VALUES.COMPANY_NAME = 0;
            break;
          }
          case matchResult === 100: {
            MATCH_DATA_VALUES.COMPANY_NAME = 100;
            break;
          }
          case matchResult >= 25: {
            MATCH_DATA_VALUES.COMPANY_NAME = 25;
            break;
          }
          default:
            MATCH_DATA_VALUES.COMPANY_NAME = 0;
            break;
        }
      }
    }

    setMatchData(MATCH_DATA_VALUES);
    setIsMismatchExists(
      Object.values(MATCH_DATA_VALUES).includes(0) || Object.values(MATCH_DATA_VALUES).includes(-1)
    );

    if (isFetched.fetch) {
      setIsCriticalErrorExists(Object.values(MATCH_DATA_VALUES).includes(0));
    }
  }, [values]);

  useEffect(() => {
    if (autoFetch) {
      fetchZohoBook();
    }
  }, [autoFetch]);

  const handleClose = () => {
    resetForm();
    setFetchDisabled(false);
    onClose();
  };

  const getFormattedAddress = (address) => {
    let zohoAddress = '';
    zohoAddress += address.address ? address.address + (address.street2 ? ', ' : '') : '';
    zohoAddress += address.street2 ? address.street2 + (address.city ? ', ' : '') : '';
    zohoAddress += address.city ? address.city + (address.state ? ', ' : '') : '';
    zohoAddress += address.state ? address.state + (address.country ? ', ' : '') : '';
    zohoAddress += address.country ? address.country + (address.zip ? ', ' : '') : '';
    zohoAddress += address.zip ? address.zip : '';
    return zohoAddress;
  };

  const clearZohoValues = () => {
    setFieldValue('zohoCompanyName', '');
    setFieldValue('zohoGST', '');
    setFieldValue('zohoTaxType', '');
    setFieldValue('zohoState', '');
    setFieldValue('zohoAddress', '');
    setFieldValue('zohoTaxTreatMent', '');
  };

  const fetchZohoBook = () => {
    setIsFetched({
      loading: true,
      fetch: false,
      fetchTime: null
    });
    clearZohoValues();
    setFetchDisabled(true);
    setIsZohoFieldDisabled(true);
    getZohoBookDetails(values?.zohoBookID?.trim())
      .then((res) => {
        //** DO NOT move this setIsFetched() method to last, it will cause concurrency problems
        setIsFetched({
          loading: false,
          fetch: true,
          fetchTime: moment().format('MMM Do YYYY, h:mm:ss a')
        });
        setFetchDisabled(false);
        setFieldValue('zohoCompanyName', res.data.company_name);
        setFieldValue('zohoGST', res.data.gst_no);
        setFieldValue('zohoTaxType', res.data.tax_percentage);
        if (res.data.place_of_supply !== null)
          setFieldValue('zohoState', STATE_CODES[res.data.place_of_supply]);
        setFieldValue('zohoAddress', getFormattedAddress(res.data.billing_address));
        setFieldValue('zohoTaxTreatMent', res.data.tax_treatment);
        handleAutoFetch();
      })
      .catch((err) => {
        setFetchDisabled(false);
        setIsZohoFieldDisabled(false);
        console.error(err);
        errToastMessage('Could not fetch Zoho Book information.');
        handleAutoFetch();
        setIsFetched({
          loading: false,
          fetched: false,
          fetchTime: null
        });
      });
  };

  /*
   * * @param: fieldName => 'GST', 'TAX', 'COMPANY_NAME', 'STATE'
   * * Match result values =>
   * * * '-1'  : Either one of the field zoho/sv has missing data
   * * * '100' : Perfect match
   * * * '0'   : Both zoho and sv field data do not match
   * * * '25'  : This case is only for Company Name fuzzy match works here, it won't be for other fields
   * * * '-10' : Should skip validation
   */
  const getCheckIcon = (fieldName) => {
    if (!isFetched.fetch || isFetched.loading) return null;

    const matchResult = matchData[fieldName];

    switch (true) {
      case matchResult === -1:
        return null;
      case matchResult === 100:
        return <CheckCircleOutline style={{ color: '#52C41A', fontSize: '30px' }} />;
      case matchResult === 0:
        return <WarningOutlined style={{ color: 'red', fontSize: '30px' }} />;
      case matchResult === 25:
        return <WarningOutlined style={{ color: '#E8833A', fontSize: '30px' }} />;
      default:
        return null;
    }
  };

  const getErrorText = (fieldName) => {
    if (!isFetched.fetch) return null;

    const matchResult = matchData[fieldName];
    const mismatchText =
      fieldName === 'COMPANY_NAME'
        ? 'Re-check. Low match score.'
        : 'Mismatch. Needs to be an exact match.';

    switch (true) {
      case matchResult === -1:
        return null;
      case matchResult === 0:
        return <p style={{ color: 'red' }}> {mismatchText} </p>;
      case matchResult === 25:
        return <p style={{ color: '#E8833A' }}> Careful. Name is not an exact match. </p>;
      default:
        return null;
    }
  };

  const getZohoTaxType = () => {
    let text = TAX_TYPES[values.zohoTaxTreatMent] + '(' + values.zohoTaxType + '%)';
    return text;
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      direction='row'
      fullWidth={true}
      maxWidth='md'
      disableBackdropClick={true}
    >
      <DialogTitle>{ZOHO_BOOK_DIALOG_TITLE}</DialogTitle>
      <form>
        <div className={styles.dialogForm}>
          <Grid item xs={6} className={styles.firstGrid}>
            <div className={styles.formField}>
              <label className={styles.fieldLabel}>
                Zoho Books ID
                <span style={{ color: 'red' }}> *</span>
              </label>
              <TextField
                variant='outlined'
                size='small'
                value={values?.zohoBookID?.trim()}
                onChange={handleChange}
                onBlur={(e) => {
                  const value = getTrimmedValue(e?.target?.value || '', false);
                  setFieldValue('zohoBookID', value || '');
                }}
                name='zohoBookID'
                disabled={isZohoFieldDisabled}
                InputProps={{
                  endAdornment: zohoBookID ? (
                    <InputAdornment position='end'>
                      <CheckCircleOutline style={{ color: '#52C41A', fontSize: '16px' }} />
                    </InputAdornment>
                  ) : isZohoFieldDisabled && !isFetchDisabled ? (
                    <InputAdornment position='end'>
                      <EditIcon
                        onClick={() => {
                          setIsZohoFieldDisabled(false);
                          setIsFetched({
                            loading: false,
                            fetch: false,
                            fetchTime: isFetched.fetchTime
                          });
                        }}
                        style={{ fontSize: '16px', color: '#000000', cursor: 'pointer' }}
                      />
                    </InputAdornment>
                  ) : null
                }}
              />
              {errors['zohoBookID'] && (
                <span className={styles.errors}>{errors['zohoBookID']}</span>
              )}
            </div>
          </Grid>
          <Grid item xs={6} className={styles.secondGrid}>
            <div className={styles.fetchBlock}>
              <Button
                variant='contained'
                color='primary'
                className={`${styles.fetchBtn} ${isFetched.loading ? styles.loading : ''}`}
                disabled={isFetchDisabled || !values.zohoBookID || errors.zohoBookID}
                onClick={fetchZohoBook}
              >
                Fetch
              </Button>
              <div className={styles.lastFetchedText}>
                {isFetched.fetch && (
                  <>
                    <Typography variant='caption' display='block'>
                      Last fetched:
                    </Typography>
                    <Typography variant='caption' display='block'>
                      {isFetched.fetchTime}
                    </Typography>
                  </>
                )}
              </div>
            </div>
          </Grid>
        </div>
        <div className={styles.dialogForm}>
          <Grid item xs={6} className={styles.firstGrid}>
            <span className={styles.formHeading}>Data on Zoho Books</span>
          </Grid>
          <Grid item xs={6} className={styles.secondGrid}>
            <span className={styles.formHeading}>Data on SpringVerify</span>
          </Grid>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.fieldInline}>
            <Grid item xs={6}>
              <div className={styles.formField}>
                <label className={styles.fieldLabel}>Company Name</label>
                <TextField
                  variant='outlined'
                  size='small'
                  name='zohoCompanyName'
                  value={values?.zohoCompanyName?.trim()}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled
                  helperText={getErrorText('COMPANY_NAME')}
                />
              </div>
            </Grid>
            <div
              className={`${styles.indication} ${getErrorText('COMPANY_NAME') ? styles.indicationError : styles.indicationSuccess
                }`}
            >
              {getCheckIcon('COMPANY_NAME')}
            </div>
            <Grid item xs={6}>
              <div className={styles.formField}>
                <label className={styles.fieldLabel}>Company Name</label>
                <TextField
                  variant='outlined'
                  size='small'
                  name='svCompanyName'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.svCompanyName}
                  disabled
                />
              </div>
            </Grid>
          </div>
          <div className={styles.fieldInline}>
            <Grid item xs={6}>
              <div className={styles.formField}>
                <label className={styles.fieldLabel}>GST</label>
                <TextField
                  variant='outlined'
                  size='small'
                  name='zohoGST'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.zohoGST?.trim()}
                  disabled
                  helperText={getErrorText('GST')}
                />
              </div>
            </Grid>
            <div
              className={`${styles.indication} ${getErrorText('GST') ? styles.indicationError : styles.indicationSuccess
                }`}
            >
              {getCheckIcon('GST')}
            </div>
            <Grid item xs={6}>
              <div className={styles.formField}>
                <label className={styles.fieldLabel}>GST</label>
                <TextField
                  variant='outlined'
                  size='small'
                  name='svGST'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.svGST}
                  disabled
                />
              </div>
            </Grid>
          </div>
          <div className={styles.fieldInline}>
            <Grid item xs={6}>
              <div className={styles.formField}>
                <label className={styles.fieldLabel}>Tax type</label>
                <TextField
                  variant='outlined'
                  size='small'
                  name='zohoTaxType'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.zohoTaxType !== '' ? getZohoTaxType() : null}
                  disabled
                  helperText={getErrorText('TAX')}
                />
              </div>
            </Grid>
            <div
              className={`${styles.indication} ${getErrorText('TAX') ? styles.indicationError : styles.indicationSuccess
                }`}
            >
              {getCheckIcon('TAX')}
            </div>
            <Grid item xs={6}>
              <div className={styles.formField}>
                <label className={styles.fieldLabel}>Tax type</label>
                <TextField
                  variant='outlined'
                  size='small'
                  name='svTaxType'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={`${values.svTaxType}%`}
                  disabled
                />
              </div>
            </Grid>
          </div>

          <div className={styles.fieldInline}>
            <Grid item xs={6}>
              <div className={styles.formField}>
                <label className={styles.fieldLabel}>(State) Place of Supply</label>
                <TextField
                  name='zohoState'
                  variant='outlined'
                  size='small'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.zohoState?.trim()}
                  disabled
                  helperText={getErrorText('STATE')}
                />
              </div>
            </Grid>
            <div
              className={`${styles.indication} ${getErrorText('STATE') ? styles.indicationError : styles.indicationSuccess
                }`}
            >
              {getCheckIcon('STATE')}
            </div>
            <Grid item xs={6}>
              <div className={styles.formField}>
                <label className={styles.fieldLabel}>State</label>
                <TextField
                  variant='outlined'
                  size='small'
                  name='svState'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.svState}
                  disabled
                />
              </div>
            </Grid>
          </div>
          <div className={styles.fieldInline}>
            <Grid item xs={6}>
              <div className={styles.formField}>
                <label className={styles.fieldLabel}>Billing Address</label>
                <TextareaAutosize
                  rowsMin={4}
                  aria-label='maximum height'
                  disabled
                  name='zohoAddress'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.zohoAddress?.trim()}
                />
              </div>
            </Grid>
            <div className={styles.indication}></div>
            <Grid item xs={6}>
              <div className={styles.formField}>
                <label className={styles.fieldLabel}>Address</label>
                <TextareaAutosize
                  rowsMin={4}
                  aria-label='maximum height'
                  name='svAddress'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.svAddress}
                  disabled
                />
              </div>
            </Grid>
          </div>
        </div>
        <div className={styles.footerBtn}>
          {zohoBookID && isCriticalErrorExists ? (
            <div style={{ color: 'red', width: '44%', fontSize: '.8rem' }}>
              Critical mismatch in data between Zoho Book & SpringVerify.
              <br /> Contact the finance team immediately with a screenshot
            </div>
          ) : null}
          <div style={{ width: '56%', textAlign: '-webkit-right' }}>
            <Button variant='contained' className={styles.cancelBtn} onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={
                (zohoBookID ? false : isMismatchExists ? true : isFetched.fetch ? false : true) || isSaveButtonClicked
              }
              variant='contained'
              color={!zohoBookID ? 'primary' : 'secondary'}
              className={styles.fetchBtn}
              onClick={handleSaveClick}
            >
              {!zohoBookID ? 'Save' : 'Disconnect'}
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default ZohoBookDialog;
