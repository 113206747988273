import React, { useState, useEffect } from 'react';
import { Box, Grid, Dialog, Typography, IconButton, Button } from '@material-ui/core';
import { toast } from 'react-toastify';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { getADCList } from '../../../../../api/admin';
import { errToastMessage, getErrorMessage } from '../../../../../utils/Utlities';
import { CHARGE_TYPE, ADDITIONAL_COST_REVIEW_STATUS } from '../../../../../utils/Statuses';
import Loader from '../../../../../core-components/ThreeDotLoader';
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex'
  },
  warningModal: {
    marginTop: '10px',
    borderStyle: 'dotted',
    borderColor: '#f4ca16'
  },
  warningText: {
    margin: '8px'
  },
  showADC: {
    marginTop: '24px',
    fontWeight: 600
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#D6DBDF'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h5' gutterBottom component='div'>
        Reject cost & close check?
      </Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  }
}))(MuiDialogContent);

function RejectCostAndCloseCheck(props) {
  const classes = useStyles();

  const [adcList, setAdcList] = useState([]);
  const [loader, setLoader] = useState(false);
  const submitForm = (e) => {
    e.preventDefault();
    props.rejectHandler();
  };
  useEffect(() => {
    const check = props.rejectValue.check;
    setLoader(true);
    getADCList(check.checkdata.id, check.checkType, 20, 0)
      .then((res) => {
        /* Do not show ADC which is to be rejected || AUTO_REJECTED and ADC's which are in Deleted status */
        const data = res?.data?.data?.rows.filter((adc) => {
          if (
            adc.status !== 'REJECTED' &&
            adc.status !== 'AUTO_REJECTED' &&
            check.checkdata.id !== adc.id &&
            !adc.payments_company_deleted_additional_charge
          ) {
            return adc;
          }
        });
        setAdcList(data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        errToastMessage(err);
      });
  }, []);

  const getAdcDate = (value) => {
    return moment(value.createdAt).utc('GMT+5:30').format('DD MMM YYYY');
  };
  const getChargeType = (value) => {
    return CHARGE_TYPE?.filter((val) => val?.value === value)[0]?.label;
  };
  const getStatus = (value) => {
    return ADDITIONAL_COST_REVIEW_STATUS?.filter((val) => val?.value === value)[0]?.label;
  };
  return (
    <div>
      <Dialog
        onClose={props.onClose}
        aria-labelledby='customized-dialog-title'
        open={true}
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle id='customized-dialog-title' onClose={props.onClose}></DialogTitle>

        <DialogContent>
          <>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant='subtitle2' display='block'>
                  This action will reject this additional cost request and change the check status
                  to CLOSED. This can only be done on the request of the client.
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {adcList && adcList.length >= 1 && (
                  <div className={classes.warningModal}>
                    <Typography variant='subtitle2' display='block' className={classes.warningText}>
                      WARNING: The following charges are either pending approval / were already
                      approved in the past. Please review them before closing this check. Escalate
                      to your supervisor if required.
                    </Typography>
                  </div>
                )}
              </Grid>
            </Grid>

            {loader && <Loader />}
            {adcList &&
              adcList?.length &&
              adcList.map((adc) => {
                return (
                  <div className={classes.showADC}>
                    <Grid container direction='row' alignItems='center'>
                      <Grid item lg={2} md={2} sm={2} xs={2}>
                        <div>{getAdcDate(adc.created_at)}</div>
                      </Grid>
                      <Grid item lg={7} md={7} sm={5} xs={7}>
                        <Grid container direction='column'>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <span>{getChargeType(adc.charge_type)}</span>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <span>
                              {adc.education_charged_for
                                ? adc.education_charged_for.toLowerCase()
                                : ''}
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={3} md={3} sm={3} xs={3}>
                        <Grid container direction='column'>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <span>{getStatus(adc.status)}</span>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Grid container direction='row'>
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <span>{`${adc.amount}.00`}</span>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
            <Box mt={2} mb={2} className={classes.row}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant='subtitle2' display='block'>
                  Are you sure?
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Box textAlign={'right'}>
                  <Button variant='text' color='primary' onClick={props.onClose}>
                    CANCEL
                  </Button>
                  <Button variant='text' color='secondary' onClick={submitForm}>
                    CLOSE CHECK
                  </Button>
                </Box>
              </Grid>
            </Box>
          </>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default RejectCostAndCloseCheck;
