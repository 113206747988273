// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".N2Ww14xbg_jW0bHs5tZc {\n  border-top: none;\n  padding: 0 24px 24px 24px;\n  border: none !important;\n}\n.N2Ww14xbg_jW0bHs5tZc .XcFsfy78PmvTslZszeHw {\n  min-width: 100%;\n  max-width: 100%;\n  padding: 25px 20px;\n  background: #ffffff;\n  border-radius: 8px;\n  margin-bottom: 48px;\n}\n.N2Ww14xbg_jW0bHs5tZc .fO5klIdiqLMFC4ibuKpQ {\n  display: flex;\n  justify-content: flex-end;\n}\n.N2Ww14xbg_jW0bHs5tZc .fO5klIdiqLMFC4ibuKpQ .Y_cwMDzGdBqLawynLei8 {\n  background: #2755fe;\n  border-radius: 5px;\n  font-family: Poppins, sans-serif;\n  font-size: 16px;\n  line-height: 20px;\n  text-align: center;\n  color: #ffffff;\n  width: 136px;\n  height: 48px;\n  margin-right: 0px;\n}\n.N2Ww14xbg_jW0bHs5tZc .fO5klIdiqLMFC4ibuKpQ .Y_cwMDzGdBqLawynLei8:hover {\n  background: #2755fe;\n  color: #ffffff;\n}\n.N2Ww14xbg_jW0bHs5tZc .fO5klIdiqLMFC4ibuKpQ .Y_cwMDzGdBqLawynLei8:disabled {\n  opacity: 0.5;\n  cursor: not-allowed;\n}\n.N2Ww14xbg_jW0bHs5tZc .fO5klIdiqLMFC4ibuKpQ .s9it5cA4gkZO5QfV34Mo {\n  margin-left: 15px;\n  background: #ffffff;\n  border: 1px solid #2755fe;\n  border-radius: 5px;\n  width: 136px;\n  font-family: Poppins, sans-serif;\n  font-size: 16px;\n  line-height: 20px;\n  color: #2755fe;\n  margin-right: 24px;\n  height: 48px;\n}\n\n.Wywg07tRvLZryv27ufAu {\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 20px;\n  color: #212529;\n  margin-bottom: 17px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"communicationCard": "N2Ww14xbg_jW0bHs5tZc",
	"communicationContainer": "XcFsfy78PmvTslZszeHw",
	"buttonGroup": "fO5klIdiqLMFC4ibuKpQ",
	"submitButton": "Y_cwMDzGdBqLawynLei8",
	"cancelButton": "s9it5cA4gkZO5QfV34Mo",
	"tabInfoText": "Wywg07tRvLZryv27ufAu"
};
export default ___CSS_LOADER_EXPORT___;
