import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith } from 'lodash';
import { toast } from 'react-toastify';

import AppConstants from '../../../core-components/AppConstants';
import {
  companyLogoUpload,
  createCompany as createCompanyAPI,
  fileUpload
} from '../../../api/admin';
import validationSchema from './CreateCompany.validation';
import { toSnakeCase, getErrorMessage, errToastMessage } from '../../../utils/Utlities';
import { invalidateCompanyDetail as invalidateCompanyDetailAPI } from '../../../actions/admin';
const mapDispatchToProps = (dispatch) => {
  return {
    invalidateCompanyDetail: () => dispatch(invalidateCompanyDetailAPI())
  };
};
export default compose(
  connect(null, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        name: '',
        friendlyName: '',
        companyUrl: '',
        linkedInUrl: '',
        candidateReportDocuments: 'external_links',
        lightLogoFile: '',
        lightLogoUrl: '',
        uploadedLogoFile: '',
        darkLogoFile: '',
        darkLogoUrl: '',
        uploadedDarkLogoFile: '',
        enableLogo: false,
        trialAccount: false,
        isEmailOptional: 0,
        isResumeOptional: 0,
        includeAadhaarInIdCheck: '0',
        discloseInfo: '',
        paymentMode: 'PREPAID',
        postpaidPaymentType: 'POSTPAID_CANDIDATE_COMPLETION',
        isOptedForAddressCheckSms: '0',
        isGenderOptional: 1,
        isUanMandatory: true,
        reminderViaEmail: 'CANDIDATE',
        consentType: 'CONSENT_LETTER',
        consentUndertakingLetterUrl: '',
        prefilledValue: null,
        packageToAssign: null,
        creditLimit: null,
        unlimitedCredit: null,
        daysToCompleteForm: 3,
        customOrDefault: 'default',
        customOrDefaultWarningNote: 'default',
        enabledHrNote: false,
        enabledHrWarningNote: false,
        hrNote: '',
        hrWarningNote: '',
        defaultHrNote: '',
        defaultHrWarningNote: '',
        defaultCustomHrNote: '',
        defaultCustomHrWarningNote: '',
        isUploading: false,
        enableDateInCandidateReport: false,
        referralEnabledFor: 'ALL',
        includeBrandNameInReport: false,
        nameConfigInReport: 'LEGAL_NAME_ONLY',
        selectedLogoType: ''
      };
      let propsValue = {};
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: async (values, { setSubmitting, dirty, props }) => {
      const uploadLogo = async (file) => {
        if (file) {
          let formData = new FormData();
          formData.append('logo', file);
          formData.append('fileType', 'COMPANY_LOGO');
          const response = await companyLogoUpload(formData);
          return response.data.data;
        }
      };

      const lightUrl = await uploadLogo(values.lightLogoFile, 'COMPANY_LOGO');
      const darkUrl = await uploadLogo(values.darkLogoFile, 'COMPANY_LOGO');

      const finalSaveObj = {
        name: values.name,
        friendlyName: values.friendlyName,
        companyUrl: values.companyUrl?.trim()?.toLowerCase(),
        linkedInUrl: values.linkedInUrl,
        candidateReportDocuments: values.candidateReportDocuments,
        company_logos: {
          dark: darkUrl ? darkUrl : values.darkLogoUrl,
          light: lightUrl ? lightUrl : ''
        },
        enableLogo: values.enableLogo,
        paymentMode:
          values.paymentMode === 'POSTPAID' ? 'POSTPAID_CANDIDATE_COMPLETION' : values.paymentMode,
        creditLimit: values.unlimitedCredit ? null : values.creditLimit,
        isEmailOptional: values.isEmailOptional,
        isResumeOptional: values.isResumeOptional,
        includeAadhaarInIdCheck: parseInt(values.includeAadhaarInIdCheck),
        discloseInfo: values.discloseInfo,
        isOptedForAddressCheckSms: values?.isOptedForAddressCheckSms,
        reminderViaEmail: values.reminderViaEmail || 'NO_EMAIL',
        consentType: values?.consentType,
        consentUndertakingLetterUrl: values?.consentUndertakingLetterUrl
          ? values?.consentUndertakingLetterUrl
          : null,
        hrNote: values?.hrNote?.trim(),
        hrWarningNote: values?.hrWarningNote?.trim(),
        enabledHrNote: values.enabledHrNote,
        enabledHrWarningNote: values.enabledHrWarningNote,
        daysToCompleteForm: values.daysToCompleteForm,
        show_bgv_period_in_report: values.enableDateInCandidateReport,
        isGenderOptional: values?.isGenderOptional,
        isUanMandatory: values?.isUanMandatory || false,
        referralEnabledFor: values?.referralEnabledFor,
        show_brand_name_in_report: values?.includeBrandNameInReport,
        selectedLogoType: values?.selectedLogoType,
        isTrialAccount: values?.trialAccount,
        name_config_in_report: values?.nameConfigInReport,
        ...values.packageToAssign
      };

      const packageType = finalSaveObj.paymentMode === 'PREPAID' ? 'PREPAID' : 'POSTPAID';
      if (
        !finalSaveObj.includeAadhaarInIdCheck &&
        finalSaveObj.packageText &&
        checkIDcountFive(finalSaveObj.packageText)
      ) {
        errToastMessage(
          'Cannot create company with package subtypes which has Identity count 5, while Aadhaar in ID check is not allowed'
        );
        setSubmitting(false);
        return;
      }
      createCompanyAPI(toSnakeCase(finalSaveObj))
        .then((response) => {
          setSubmitting(false);
          toast.success('Company Created Successfully');
          window.open(
            `${AppConstants.baseURL}company/additionalSettings/edit?create=true&package=${packageType}&company_id=${response.data.data.company_config.company_id}`,
            '_self'
          );
        })
        .catch((error) => {
          if (error && error?.response && error?.response?.status == 404) {
            setSubmitting(false);
            return errToastMessage('Error fetching logo for the provided domain');
          } else {
            setSubmitting(false);
            console.error(error);
            errToastMessage(error);
          }
        });
    },
    displayName: 'Create Company'
  })
);

const checkIDcountFive = (packages) => {
  if (packages.some((p) => p.configCount.identity === 5)) {
    return true;
  }
};
