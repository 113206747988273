import React from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Tooltip, Zoom } from '@material-ui/core';

import { VERIFICATION_CHECK_STATUS } from '../../../../utils/Statuses';
import { VERIFICATION_ICONS, IDENTITY_TYPE } from '../../../../utils/Verification';
import { getCustomCheckSVG } from '../../../../utils/Utlities';
import { getStatus } from './helper';
import Icon from '../../../../core-components/Icon/Icon';
import { capitalize, startCase } from 'lodash';

const useStyles = makeStyles({
  text: {
    margin: 0
  },
  status: {
    paddingLeft: 8,
    fontWeight: 600
  },
  icons: {
    display: 'inline',
    width: 'fit-content'
  }
});

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    margin: '8px 0',
    backgroundColor: '#9e9e9e',
    color: '#333333',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}))(Tooltip);

export default (props) => {
  const classes = useStyles();
  let verficationIconsArray = [];

  if (props?.type === 'identity' && props?.details && props?.details?.length) {
    props?.details.map((value, index) => {
      let { status } = value;
      status = getStatus(status);

      let tooltipTitle = (
        <>
          <p className={classes.text}>
            {IDENTITY_TYPE[value.type]} ({value.id})
            <span
              className={classes.status}
              style={{ color: VERIFICATION_CHECK_STATUS.values[status].textColor }}
            >
              {VERIFICATION_CHECK_STATUS.values[status].text}
            </span>
          </p>
        </>
      );

      verficationIconsArray.push(
        <CustomTooltip
          key={'id' + index}
          TransitionComponent={Zoom}
          title={tooltipTitle}
          arrow={true}
        >
          <div
            className={classes.icons}
            onClick={(e) => props.handleIconClick(e, props.type, index)}
          >
            <Icon
              icon={VERIFICATION_ICONS.values[0].icon}
              color={VERIFICATION_CHECK_STATUS.values[status].color}
              width={VERIFICATION_ICONS.values[0].width}
              height={VERIFICATION_ICONS.values[0].height}
            />
          </div>
        </CustomTooltip>
      );
    });
  }

  if (props?.type === 'address' && props?.details && props?.details?.length) {
    props?.details.map((value, index) => {
      let { status } = value;
      status = getStatus(status);

      let tooltipTitle = (
        <>
          <p className={classes.text}>
            {value.type}
            <span
              className={classes.status}
              style={{ color: VERIFICATION_CHECK_STATUS.values[status].textColor }}
            >
              ({value.id}) {VERIFICATION_CHECK_STATUS.values[status].text}
            </span>
          </p>
        </>
      );

      verficationIconsArray.push(
        <CustomTooltip
          key={'add' + index}
          TransitionComponent={Zoom}
          title={tooltipTitle}
          arrow={true}
        >
          <div
            className={classes.icons}
            onClick={(e) => props.handleIconClick(e, props.type, index)}
          >
            <Icon
              icon={VERIFICATION_ICONS.values[2].icon}
              color={VERIFICATION_CHECK_STATUS.values[status].color}
              width={VERIFICATION_ICONS.values[2].width}
              height={VERIFICATION_ICONS.values[2].height}
            />
          </div>
        </CustomTooltip>
      );
    });
  }

  if (props?.type === 'employment' && props?.details && props?.details?.length) {
    props?.details.map((value, index) => {
      let { status } = value;
      status = getStatus(status);

      let tooltipTitle = (
        <>
          <p className={classes.text}>
            {value.companyName || null} ({value.id})
            <span
              className={classes.status}
              style={{ color: VERIFICATION_CHECK_STATUS.values[status].textColor }}
            >
              {VERIFICATION_CHECK_STATUS.values[status].text}
            </span>
          </p>
        </>
      );

      verficationIconsArray.push(
        <CustomTooltip
          key={'emp' + index}
          TransitionComponent={Zoom}
          title={tooltipTitle}
          arrow={true}
        >
          <div
            className={classes.icons}
            onClick={(e) => props.handleIconClick(e, props.type, index)}
          >
            <Icon
              icon={VERIFICATION_ICONS.values[1].icon}
              color={VERIFICATION_CHECK_STATUS.values[status].color}
              width={VERIFICATION_ICONS.values[1].width}
              height={VERIFICATION_ICONS.values[1].height}
            />
          </div>
        </CustomTooltip>
      );
    });
  }

  if (props?.type === 'education' && props?.details && props?.details?.length) {
    props?.details.map((value, index) => {
      let { status } = value;
      status = getStatus(status);

      let tooltipTitle = (
        <>
          <p className={classes.text}>
            {value.type
              ? startCase(capitalize(value.type?.replace(/_Std(.*)$/i, '')))
              : 'No Education'}{' '}
            ({startCase(capitalize(value.id))})
            <span
              className={classes.status}
              style={{ color: VERIFICATION_CHECK_STATUS.values[status].textColor }}
            >
              {VERIFICATION_CHECK_STATUS.values[status].text}
            </span>
          </p>
        </>
      );

      verficationIconsArray.push(
        <CustomTooltip
          key={'edu' + index}
          TransitionComponent={Zoom}
          title={tooltipTitle}
          arrow={true}
        >
          <div
            className={classes.icons}
            onClick={(e) => props.handleIconClick(e, props.type, index)}
          >
            <Icon
              icon={VERIFICATION_ICONS.values[3].icon}
              color={VERIFICATION_CHECK_STATUS.values[status].color}
              width={VERIFICATION_ICONS.values[3].width}
              height={VERIFICATION_ICONS.values[3].height}
            />
          </div>
        </CustomTooltip>
      );
    });
  }

  if (props?.type === 'court' && props?.details && props?.details?.length) {
    props?.details.map((value, index) => {
      let { status } = value;
      status = getStatus(status);

      let tooltipTitle = (
        <>
          <p className={classes.text}>
            <span
              className={classes.status}
              style={{ color: VERIFICATION_CHECK_STATUS.values[status].textColor }}
            >
              ({value.id}) {VERIFICATION_CHECK_STATUS.values[status].text}
            </span>
          </p>
        </>
      );

      verficationIconsArray.push(
        <CustomTooltip
          key={'history' + index}
          TransitionComponent={Zoom}
          title={tooltipTitle}
          arrow={true}
        >
          <div
            className={classes.icons}
            onClick={(e) => props.handleIconClick(e, props.type, index)}
          >
            <Icon
              icon={VERIFICATION_ICONS.values[6].icon}
              color={VERIFICATION_CHECK_STATUS.values[status].color}
              width={VERIFICATION_ICONS.values[6].width}
              height={VERIFICATION_ICONS.values[6].height}
            />
          </div>
        </CustomTooltip>
      );
    });
  }

  if (props?.type === 'referenceCheck' && props?.details && props?.details?.length) {
    props?.details.map((value, index) => {
      let { status } = value;
      status = getStatus(status);

      let tooltipTitle = (
        <>
          <p className={classes.text}>
            <span
              className={classes.status}
              style={{ color: VERIFICATION_CHECK_STATUS.values[status].textColor }}
            >
              ({value.id}) {VERIFICATION_CHECK_STATUS.values[status].text}
            </span>
          </p>
        </>
      );

      verficationIconsArray.push(
        <CustomTooltip
          key={'ref' + index}
          TransitionComponent={Zoom}
          title={tooltipTitle}
          arrow={true}
        >
          <div
            className={classes.icons}
            onClick={(e) => props.handleIconClick(e, props.type, index)}
          >
            <Icon
              icon={VERIFICATION_ICONS.values[8].icon}
              color={VERIFICATION_CHECK_STATUS.values[status].color}
              width={VERIFICATION_ICONS.values[8].width}
              height={VERIFICATION_ICONS.values[8].height}
            />
          </div>
        </CustomTooltip>
      );
    });
  }

  if (props?.type === 'cibilCheck' && props?.details && props?.details?.length) {
    props?.details.map((value, index) => {
      let { status } = value;
      status = getStatus(status);
      let tooltipTitle = (
        <>
          <p className={classes.text}>
            <span
              className={classes.status}
              style={{ color: VERIFICATION_CHECK_STATUS.values[status].textColor }}
            >
              ({value.id}) {VERIFICATION_CHECK_STATUS.values[status].text}
            </span>
          </p>
        </>
      );

      verficationIconsArray.push(
        <CustomTooltip
          key={'credit' + index}
          TransitionComponent={Zoom}
          title={tooltipTitle}
          arrow={true}
        >
          <div
            className={classes.icons}
            onClick={(e) => props.handleIconClick(e, props.type, index)}
          >
            <Icon
              icon={VERIFICATION_ICONS.values[7].icon}
              color={VERIFICATION_CHECK_STATUS.values[status].color}
              width={VERIFICATION_ICONS.values[7].width}
              height={VERIFICATION_ICONS.values[7].height}
            />
          </div>
        </CustomTooltip>
      );
    });
  }

  if (props?.type === 'worldCheck' && props?.details && props?.details?.length) {
    props?.details.map((value, index) => {
      let { status } = value;
      status = getStatus(status);

      let tooltipTitle = (
        <>
          <p className={classes.text}>
            <span
              className={classes.status}
              style={{ color: VERIFICATION_CHECK_STATUS.values[status].textColor }}
            >
              ({value.id}) {VERIFICATION_CHECK_STATUS.values[status].text}
            </span>
          </p>
        </>
      );

      verficationIconsArray.push(
        <CustomTooltip
          key={'world' + index}
          TransitionComponent={Zoom}
          title={tooltipTitle}
          arrow={true}
        >
          <div
            className={classes.icons}
            onClick={(e) => props.handleIconClick(e, props.type, index)}
          >
            <Icon
              icon={VERIFICATION_ICONS.values[4].icon}
              color={VERIFICATION_CHECK_STATUS.values[status].color}
              width={VERIFICATION_ICONS.values[4].width}
              height={VERIFICATION_ICONS.values[4].height}
            />
          </div>
        </CustomTooltip>
      );
    });
  }

  if (props?.type === 'drugCheck' && props?.details && props?.details?.length) {
    props?.details.map((value, index) => {
      let { status } = value;
      status = getStatus(status);

      let tooltipTitle = (
        <>
          <p className={classes.text}>
            <span
              className={classes.status}
              style={{ color: VERIFICATION_CHECK_STATUS.values[status].textColor }}
            >
              ({value.id}) {VERIFICATION_CHECK_STATUS.values[status].text}
            </span>
          </p>
        </>
      );

      verficationIconsArray.push(
        <CustomTooltip
          key={'drug' + index}
          TransitionComponent={Zoom}
          title={tooltipTitle}
          arrow={true}
        >
          <div
            className={classes.icons}
            onClick={(e) => props.handleIconClick(e, props.type, index)}
          >
            <Icon
              icon={VERIFICATION_ICONS.values[5].icon}
              color={VERIFICATION_CHECK_STATUS.values[status].color}
              width={VERIFICATION_ICONS.values[5].width}
              height={VERIFICATION_ICONS.values[5].height}
            />
          </div>
        </CustomTooltip>
      );
    });
  }

  if (props?.type === 'customCheck' && props?.details && props?.details?.length) {
    props?.details.map((value, index) => {
      let { status } = value;
      status = typeof status === 'string' ? getStatus(+status) : getStatus(status);

      let tooltipTitle = (
        <>
          <p className={classes.text}>
            <span
              className={classes.status}
              style={{ color: VERIFICATION_CHECK_STATUS.values[status].textColor }}
            >
              ({value.id}) {VERIFICATION_CHECK_STATUS.values[status].text}
            </span>
          </p>
        </>
      );

      verficationIconsArray.push(
        <CustomTooltip
          key={'customCheck' + index}
          TransitionComponent={Zoom}
          title={tooltipTitle}
          arrow={true}
        >
          <div
            className={classes.icons}
            onClick={(e) => props.handleIconClick(e, props.type, index)}
          >
            {getCustomCheckSVG(
              VERIFICATION_CHECK_STATUS.values[status].color,
              20,
              VERIFICATION_ICONS.values[9].height,
              'pointer'
            )}
          </div>
        </CustomTooltip>
      );
    });
  }

  return verficationIconsArray;
};
