import React, { Suspense, lazy, useEffect } from 'react';
import CustomizedBreadcrumbs from '../../../../core-components/BreadCrumbs';
import { makeStyles } from '@material-ui/core/styles';

import AppConstants from '../../../../core-components/AppConstants';
import CreditBuyPackagePage from '../../frontend-common/CreditBuyPackagePage';
import ThreeDots from '../../../../core-components/ThreeDotLoader';
const useStyles = makeStyles((theme) => ({
  outerDiv: {
    margin: '40px auto'
  },
  heading: {
    fontSize: '20px',
    marginBottom: '50px'
  },
  innerDiv: {
    marginTop: '200px'
  }
}));
export default (props) => {
  const companyId = new URLSearchParams(document.location.search).get('company_id');
  const classes = useStyles();

  useEffect(() => {
    props.getCompanyById(companyId);
  }, []);

  const { companyDetails } = props;

  const breadCrumbsArray = [
    { label: 'Home', href: `${AppConstants.baseURL}dashboard` },
    { label: 'Companies', href: `${AppConstants.baseURL}companies` },
    {
      label: companyDetails?.name || 'Company Name',
      href: `${AppConstants.baseURL}company/view?id=${companyId}`
    }
  ];
  return (
    <>
      <Suspense
        fallback={
          <div style={{ textAlign: 'center', height: '100%' }}>
            <ThreeDots style={{ textAlign: 'center' }} />
          </div>
        }
      >
        <CustomizedBreadcrumbs linksArray={breadCrumbsArray} current={'Create Order'} />
        <div className={classes.outerDiv}>
          {/* <div className={classes.heading}>Create Order</div> */}
          <div className={classes.innerDiv}>
            <CreditBuyPackagePage isSA={true} {...props} />
          </div>
        </div>
      </Suspense>
    </>
  );
};
