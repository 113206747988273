import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith } from 'lodash';
import { getCompanyBgvConfig } from '../../../../../actions/admin';

const mapStateToProps = (state) => {
  return {
    formConfig: state.companyBgvConfig
  };
};

const mapDispatchToProps = (dispatch) => {
  return { getCompanyBgvConfig: (companyId) => dispatch(getCompanyBgvConfig(companyId)) };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      const { formConfig } = props;
      let storedValues = {
        friendlyName: '',
        isBrandEnabled: false,
        hrInviteNote: null,
        defaultHrInviteNote:
          'Please treat this with the utmost urgency. This is a pre-requisite for your employment with our organization.',
        hrInviteNoteDefaultOrCustom: '1',
        hrWarningNote: null,
        defaultHrWarningNote:
          'Not completing the background check request on-time will lead to internal escalations and may affect onboarding processes',
        hrWarningNoteDefaultOrCustom: '1',
        hrInviteNoteStatus: '',
        hrWarningNoteStatus: '',
        hasDualLogos: '',
        logoUrlId: '',
        companyLogos: [],
        formCompletionDays: '',
        logoEnabled: true,
        useLogoInInviteMail: false,
        isImgLoaded: true
      };
      const useLogoInInviteMail =
        formConfig?.company?.useLogoInInviteMail &&
        formConfig?.company?.logoUrlId &&
        formConfig?.company?.companyLogos?.find(
          (logo) => logo.id === formConfig?.company?.logoUrlId
        )?.logoUrl !== null;
      let propsValue = {
        friendlyName: formConfig?.company?.friendlyName,
        isBrandEnabled: formConfig?.isBrandEnabled,
        hrInviteNote: formConfig?.hrInviteNote,
        hrInviteNoteDefaultOrCustom:
          formConfig?.hrInviteNoteStatus && !formConfig?.hrInviteNote ? '1' : '2',
        hrWarningNote: formConfig?.hrWarningNote,
        hrWarningNoteDefaultOrCustom:
          formConfig?.hrWarningNoteStatus && !formConfig?.hrWarningNote ? '1' : '2',
        hrInviteNoteStatus: formConfig?.hrInviteNoteStatus,
        hrWarningNoteStatus: formConfig?.hrWarningNoteStatus,
        logoUrlId: formConfig?.company?.logoUrlId,
        companyLogos: formConfig?.company?.companyLogos?.reduce((logoObject, logo) => {
          return { ...logoObject, [logo.logoType]: logo };
        }, {}),
        formCompletionDays: formConfig?.formCompletionDays,
        isBrandEnbaled: formConfig?.isBrandEnabled,
        useLogoInInviteMail: useLogoInInviteMail
      };
      if (formConfig?.company?.useLogoInInviteMail)
        formConfig.company.useLogoInInviteMail = useLogoInInviteMail;
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    handleSubmit: () => {},
    displayName: 'Email Content'
  })
);
