import { connect } from 'react-redux';
import { compose } from 'redux';
import { isEmpty } from 'lodash';

const mapStateToProps = (state) => {
  return {
    addCandidateConfig: state.addCandidateConfig && !isEmpty(state.addCandidateConfig) ? state.addCandidateConfig : null
  };
};

export default compose(connect(mapStateToProps, null));
