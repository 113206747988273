import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, has, isEmpty, isString } from 'lodash';
import { toast } from 'react-toastify';
import { getGlobalAddonConfiguration as getGlobalAddonConfigurationAPI } from '../../../actions/admin';
import { updateAddonConfiguration } from '../../../api/admin';
import {
  validateOperation,
  errToastMessage,
  convertToIndianNumeration,
  convertFromIndianNumeration
} from '../../../utils/Utlities';
import validationSchema from './GlobalAddonConfig.validation';

const mapStateToProps = (state) => {
  return {
    globalAddonConfiguration: state.globalAddonConfiguration,
    profile: state.profile && !isEmpty(state.profile) ? state.profile : null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGlobalAddonConfiguration: (packageType) =>
      dispatch(getGlobalAddonConfigurationAPI(packageType))
  };
};

const updateCosts = (obj) => {
  for (let key in obj) {
    if (obj[key].hasOwnProperty('cost')) {
      if (isString(obj[key].cost)) obj[key].cost = convertFromIndianNumeration(obj[key].cost);
    }
  }
  return obj;
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        identity: {
          isAvailable: false,
          cost: 0
        },
        addressDigital: {
          isAvailable: false,
          cost: 0
        },
        addressPhysical: {
          isAvailable: false,
          cost: 0
        },
        addressPostal: {
          isAvailable: false,
          cost: 0
        },
        courtStandard: {
          isAvailable: false,
          cost: 0
        },
        courtStandardLawfirm: {
          isAvailable: false,
          cost: 0
        },
        employment: {
          isAvailable: false,
          cost: 0
        },
        education: {
          isAvailable: false,
          cost: 0
        },
        educationThirdParty: {
          isAvailable: false,
          cost: 0
        },
        educationHybrid: {
          isAvailable: false,
          cost: 0
        },
        reference: {
          isAvailable: false,
          cost: 0
        },
        cibil: {
          isAvailable: false,
          cost: 0
        },
        drug5Panel: {
          isAvailable: false,
          cost: 0
        },
        drug7Panel: {
          isAvailable: false,
          cost: 0
        },
        drug10Panel: {
          isAvailable: false,
          cost: 0
        },
        world: {
          isAvailable: false,
          cost: 0
        }
      };
      let propsValue = {};
      if (
        has(props.globalAddonConfiguration, 'loading') &&
        !props.globalAddonConfiguration.loading &&
        props.globalAddonConfiguration.data &&
        !isEmpty(props.globalAddonConfiguration.data)
      ) {
        let data = props.globalAddonConfiguration.data;

        if (data && !isEmpty(data)) {
          for (let key in data) {
            if (data[key]?.hasOwnProperty('cost')) {
              data[key].cost = convertToIndianNumeration(data[key]?.cost);
            }
          }
        }

        propsValue = { ...data, ...{ model_type: props.globalAddonConfiguration.packageType } };
      }
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    validationSchema,
    enableReinitialize: true,
    handleSubmit: (values, { setSubmitting, resetForm, props }) => {
      if (validateOperation(props.profile)) {
        let valuesCopy = { ...values };

        if (has(valuesCopy, 'isSameAsGlobal')) {
          delete valuesCopy.isSameAsGlobal;
        }

        valuesCopy = updateCosts(valuesCopy);

        updateAddonConfiguration(valuesCopy)
          .then(() => {
            setSubmitting(false);
            toast.success('Global Add-on Configuration saved successfully');
            resetForm();
            props.getGlobalAddonConfiguration(props.globalAddonConfiguration.packageType);
          })
          .catch((error) => {
            setSubmitting(false);
            errToastMessage(error);
          });
      } else {
        setSubmitting(false);
        errToastMessage('Only admin is allowed to perform this operation');
      }
    },
    displayName: 'Global Addon Configuration'
  })
);
