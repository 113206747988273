import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { IconButton, Tooltip, Zoom } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';

import Button from '../../../core-components/Button';
import AppConstants from '../../../core-components/AppConstants';
import { STATUSES_DROPDOWN, CANDIDATE_OVERALL_STATUS } from '../../../utils/Statuses';
import DropdownPopup from '../../../core-components/DropdownPopup';
import DetailsCard from '../../../core-components/DetailsCard';
import BreadCrumbs from '../../../core-components/BreadCrumbs';
import ConfirmationPopup from '../../../core-components/ConfirmationPopup';
import StatePopup from '../../../core-components/StatePopup';
import {
  deleteCandidate as deleteCandidateAPI,
  updateCandidateStatus as updateCandidateStatusAPI,
  getBGVLink as getBGVLinkAPI
} from '../../../api/admin';
import { errToastMessage, getErrorMessage } from '../../../utils/Utlities';

const tableData = [
  { label: 'Candidate Company', valueKey: 'companyName' },
  { label: 'Employee Id', valueKey: 'empId' },
  { label: 'Email', valueKey: 'email' },
  { label: 'Name', valueKey: 'name' },
  { label: 'Gender', valueKey: 'gender' },
  { label: 'Created At', valueKey: 'createdAt' },
  { label: 'Updated At', valueKey: 'updatedAt' },
  { label: 'Status', valueKey: 'status' }
];

const useStyles = makeStyles((theme) => ({
  disableBtn: {
    pointerEvents: 'unset !important',
    opacity: 0.7,
    '&:hover': {
      cursor: 'not-allowed !important'
    }
  }
}));

export default (props) => {
  const classes = useStyles();
  const [companyId, setCompanyId] = useState(
    new URLSearchParams(document.location.search).get('company_id')
  );
  const [candidateId, setCandidateId] = useState(
    new URLSearchParams(document.location.search).get('id')
  );
  const [showAddMsg, setShowAddMsg] = useState(
    new URLSearchParams(document.location.search).get('method') || null
  );
  const [popupStatus, setPopupStatus] = useState('');
  const [showStatusPopup, setShowStatusPopup] = useState(false);
  const [data, setData] = useState([]);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [companyName, setCompanyName] = useState(null);

  const breadCrumbsArray = [
    { label: 'Home', href: `${AppConstants.baseURL}dashboard` },
    {
      label: companyName || 'Company Name',
      href: `${AppConstants.baseURL}company/view?id=${companyId}`
    },
    {
      label: 'Candidates',
      href: `${AppConstants.baseURL}company/candidates?id=${companyId}`
    }
  ];

  useEffect(() => {
    props.invalidateViewCandidates();
    // setCandidateId(new URLSearchParams(document.location.search).get('id'));
    // setCompanyId(new URLSearchParams(document.location.search).get('company_id'));
  }, []);

  useEffect(() => {
    if (companyId && candidateId) {
      props.getViewCandidate(candidateId, companyId);
    }
  }, [companyId, candidateId]);

  useEffect(() => {
    if (!isEmpty(props.viewCandidateData)) {
      setData(formatData());
    }
    if (props?.viewCandidateData) {
      setCompanyName(props.viewCandidateData.companyName || null);
    }
  }, [props.viewCandidateData]);

  const getValue = (key, value) => {
    switch (key) {
      case 'companyName':
        return getCompanyLink();
      case 'status':
        return getStatusAction(value, candidateId, companyId);
      default:
        return value;
    }
  };

  const formatData = () => {
    let formattedData = [];
    formattedData = tableData.map((item) => {
      return {
        label: item.label,
        value: getValue(item.valueKey, props.viewCandidateData[item.valueKey])
      };
    });
    return formattedData;
  };

  const handleEdit = () => {
    // window.open(
    //   `${AppConstants.baseURL}candidate/update?id=${candidateId}&company_id=${companyId}`,
    //   '_self'
    // );
    props.history.push({
      pathname: '/candidate/update',
      search: `?id=${candidateId}&company_id=${companyId}`
    });
  };

  const handleDelete = () => {
    deleteCandidateAPI(candidateId, companyId)
      .then((response) => {
        toast.info(response.data.message);
        window.open(`${AppConstants.baseURL}company/candidates?id=${companyId}`, '_self');

        //TODO
        // props.history.push(`/company/candidates?id=${companyId}`);
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  };

  const updateStatus = (status) => {
    const data = {
      status,
      companyId,
      candidateId
    };
    updateCandidateStatusAPI(data)
      .then((response) => {
        if (!isEmpty(response.data.data.company_candidate_mapping)) {
          props.getViewCandidate(candidateId, companyId);
          toast.info('Status Updated Successfully');
        } else {
          errToastMessage('Unable to update status, Please try after sometime');
        }
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  };

  const getCompanyLink = () => {
    let companyUrl = `${AppConstants.baseURL}company/view?id=${companyId}`;
    return (
      <Button
        label={props?.viewCandidateData?.companyName}
        color={'link'}
        variant={'text'}
        onClick={() => {
          props.history.push({
            pathname: '/company/view',
            search: `?id=${companyId}`
          });
        }}
      />
    );
  };

  const getStatusAction = (status, candidateId = null, companyId = null) => {
    let label = (
      <span style={{ color: CANDIDATE_OVERALL_STATUS.values[status].color }}>
        {CANDIDATE_OVERALL_STATUS.values[status].text}
      </span>
    );
    return (
      <Button
        size={'small'}
        variant={'text'}
        label={label}
        onClick={() => {
          setPopupStatus(status);
          setShowStatusPopup(true);
        }}
      />
    );
  };

  const getActions = () => {
    return (
      <>
        <IconButton
          aria-label='edit'
          onClick={() => {
            handleEdit();
          }}
        >
          <Tooltip TransitionComponent={Zoom} title='Edit' arrow={true}>
            <EditIcon color='primary' />
          </Tooltip>
        </IconButton>
        <IconButton
          aria-label='delete'
          disabled={
            !isEmpty(props.viewCandidateData) &&
            props?.viewCandidateData?.status !== 3 &&
            props?.viewCandidateData?.status !== 10
          }
          onClick={() => {
            setShowConfirmationPopup(true);
          }}
          classes={{
            disabled: classes.disableBtn
          }}
        >
          <Tooltip TransitionComponent={Zoom} title='Delete' arrow={true}>
            <DeleteIcon color='secondary' />
          </Tooltip>
        </IconButton>
      </>
    );
  };

  const getAdditionalActions = () => {
    return (
      <>
        <Button
          label={'Verification Details'}
          color={'green'}
          onClick={() => {
            props.history.push({
              pathname: '/candidate/details',
              search: `?id=${candidateId}&company_id=${companyId}`
            });
          }}
        />
      </>
    );
  };

  return !isEmpty(props.viewCandidateData) ? (
    <>
      <BreadCrumbs linksArray={breadCrumbsArray} current={props.viewCandidateData.name} />
      <DetailsCard
        data={data}
        heading={props.viewCandidateData['name']}
        actions={getActions()}
        additionalActions={getAdditionalActions()}
      />
      {showStatusPopup ? (
        <DropdownPopup
          open={showStatusPopup}
          title={'Update Status'}
          label={'Statuses'}
          value={popupStatus} //need to add status value
          dropdownValues={STATUSES_DROPDOWN.values}
          handleSubmit={(status) => updateStatus(status)}
          handleClose={() => {
            setPopupStatus('');
            setShowStatusPopup(false);
          }}
          buttonLabel={'Update'}
        />
      ) : null}

      {showConfirmationPopup ? (
        <ConfirmationPopup
          open={showConfirmationPopup}
          title={'Delete Candidate'}
          content={'Are you sure want to delete candidate ?'}
          handleSubmit={() => {
            handleDelete();
          }}
          handleClose={() => {
            setShowConfirmationPopup(false);
          }}
          buttonLabel={'Delete'}
        />
      ) : null}

      {showAddMsg && (showAddMsg === 'invite' || showAddMsg === 'self') ? (
        <StatePopup
          open={showAddMsg === 'invite' || showAddMsg === 'self'}
          title={'Candidate Added'}
          popupState={'success'}
          content={
            showAddMsg === 'invite'
              ? `Invite has been sent successfully to the candidate's email address`
              : 'Candidate Added successfully '
          }
          handleSubmit={() => {
            setShowAddMsg(false);
          }}
          handleClose={() => {
            setShowAddMsg(false);
          }}
          buttonLabel={'Ok'}
        />
      ) : null}
    </>
  ) : null;
};
