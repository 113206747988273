import React from 'react';

import {
  Box,
  Grid,
  Dialog,
  Radio,
  RadioGroup,
  Typography,
  IconButton,
  FormControlLabel,
  FormControl,
  FormLabel,
  InputLabel
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Field } from 'formik';

import Button from '../../../../core-components/Button';
import FormInput from '../../../../core-components/FormInput';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex'
  },
  label: {
    margin: '10px 0 5px',
    color: '#333333'
  },
  radioGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h5'>Approval of Additional Charges</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    marginTop: '-30px'
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
    paddingTop: 2
  }
}))(MuiDialogActions);

export default function ApprovalOfCharges(props) {
  const classes = useStyles();

  const { values, setFieldValue, handleSubmit } = props;

  return (
    <div>
      <Dialog
        onClose={props.handleClose}
        aria-labelledby='customized-dialog-title'
        open={props.open}
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle id='customized-dialog-title' onClose={props.handleClose}></DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Box>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl component='fieldset' fullWidth>
                  <FormLabel component='legend'>Per check</FormLabel>
                  <RadioGroup
                    aria-label='additionalChargeSettingType'
                    name='additionalChargeSettingType'
                    value={values.additionalChargeSettingType}
                    onChange={(e) => {
                      setFieldValue('additionalChargeSettingType', e.target.value);
                    }}
                    className={classes.radioGroup}
                  >
                    <FormControlLabel
                      value={'ALWAYS'}
                      control={<Radio color='primary' />}
                      label='Always auto approve'
                    />
                    <FormControlLabel
                      value={'LIMIT'}
                      control={<Radio color='primary' />}
                      label='Auto approve till amount'
                    />
                    <FormControlLabel
                      value={'NEVER'}
                      control={<Radio color='primary' />}
                      label='Never auto approve'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {values.additionalChargeSettingType === 'LIMIT' && (
                <div className={classes.row}>
                  <Grid item lg={2} md={2} sm={2} xs={2}>
                    <InputLabel className={classes.label}>Limit :</InputLabel>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Field
                      fullWidth={true}
                      type='text'
                      name='additionalCharge'
                      component={FormInput}
                      variant='outlined'
                    />
                  </Grid>
                </div>
              )}
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color={'green'} label={'Save'} size={'large'}></Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
