import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import { AppBar, Toolbar, Typography, Button, Grid, Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import NavMenuListDesktop from './MenuList.jsx';
import { headerDropdown } from '../../../utils/header';
import MobileMenuList from './MobileMenuList.jsx';
import AppConstants from '../../../core-components/AppConstants';
import DropDownSearch from './DropdownSearch/Dropdownsearch.jsx';
import { HEADER_SEARCH_PARAMETERS } from '../../../core-components/CommonConstants.js';
import cn from 'classnames';
import classes from './Header.module.scss';

const formatSearchResult = (searchData) => {
  let finalArr = [];
  if (!isEmpty(searchData)) {
    for (let value of Object.values(searchData)) {
      finalArr.push({
        name: value?.candidate?.name,
        email: value?.candidate?.email,
        alternateEmail: value?.candidate?.alternateEmail,
        isPrimary: value?.isPrimary,
        phoneNumber: value?.phoneNumber,
        altPhoneNumber: value?.altPhoneNumber,
        employeeID: value?.employeeId,
        id: value?.candidate?.id,
        companyName: value?.company?.name,
        href: `${AppConstants.baseURL}candidate/details?id=${value.candidate.id}&company_id=${value.company.id}`
      });
    }
  }
  return finalArr;
};

export default (props) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [searchDropdownOptions, setSearchDropdownOptions] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [openDropdown, setOpenDropdown] = useState(false);
  const mobileMenuId = 'primary-search-account-menu-mobile';

  useEffect(() => {
    props.invalidateGlobalSearch();
  }, []);

  useEffect(() => {
    if (!isEmpty(props.searchData)) {
      setSearchData(props.searchData.search);
    }
  }, [props.searchData]);

  useEffect(() => {
    setSearchDropdownOptions(formatSearchResult(searchData));
    setOpenDropdown(true);
  }, [searchData]);

  const handleDrawerToggle = () => {
    setShowDrawer(!showDrawer);
  };

  const handleSearchClick = (href, value) => {
    window.open(href, '_self');
  };

  const getIdentifierField = (option) => {
    switch (props.searchData.field) {
      case 'email':
        return option.isPrimary ? option.email : `alt:${option.alternateEmail}`;
      case 'phone':
        return option.isPrimary ? option.phoneNumber : `alt:${option.altPhoneNumber}`;
      case 'svid':
        return `SV${option.id}`;
      case 'employee_id':
        return option.employeeID;
      default:
        return null;
    }
  };
  const getAutocomplete = () => {
    return (
      <Box className={classes.customSearchContainer}>
        <List component='nav' className={classes.root} aria-label='contacts'>
          {searchDropdownOptions.map((option, index) => {
            return (
              <React.Fragment key={`searchoutput_${index}`}>
                <ListItem button onClick={() => handleSearchClick(option.href, option.name)}>
                  <Grid container>
                    <Grid item xl={12} md={12} sm={12} xs={12}>
                      <Typography
                        component={'span'}
                        display={'inline'}
                        variant={'body2'}
                        className={cn(
                          classes.searchTypo,
                          props.searchData.field === 'name' ? classes.fontBold : ''
                        )}
                      >
                        {option.name}
                      </Typography>
                      <Typography
                        className={cn(classes.fontBold)}
                        variant={'body2'}
                        component={'span'}
                        display={'inline'}
                      >
                        &nbsp;&nbsp;
                        {props.searchData.candidateQuery && getIdentifierField(option)}
                      </Typography>
                    </Grid>
                    <Grid item xl={12} md={12} sm={12} xs={12}>
                      <Typography variant={'body2'} align={'left'}>
                        {option.companyName}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              </React.Fragment>
            );
          })}
        </List>
      </Box>
    );
  };

  const handleClickAway = () => {
    setOpenDropdown(false);
  };

  return (
    <div className={classes.grow}>
      <AppBar position='fixed' className={classes.customAppbar}>
        <Grid container>
          <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid>
          <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
            <Toolbar disableGutters>
              <div className={classes.customTypoContainer}>
                <Typography
                  className={classes.customTypo}
                  variant='h6'
                  onClick={() => {
                    if (window.location.pathname !== '/login') {
                      props?.historyProps?.history.push('/dashboard');
                    }
                  }}
                >
                  SpringVerify
                </Typography>
              </div>
              {window.location.pathname !== '/login' && window.location.pathname !== '/invite' ? (
                <>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <div className={classes.search}>
                      <DropDownSearch
                        dropdownValues={HEADER_SEARCH_PARAMETERS}
                        searchCandidate={props.searchCandidate}
                        onClickAway={handleClickAway}
                      />
                      {openDropdown && searchDropdownOptions.length > 0 && getAutocomplete()}
                    </div>
                  </ClickAwayListener>

                  <div className={classes.sectionDesktop}>
                    {headerDropdown(props.user).map((item, index) => {
                      return item.child && item.child.length ? (
                        <React.Fragment key={`${item}_menu-container_${index}`}>
                          <NavMenuListDesktop item={item} index={index} />
                        </React.Fragment>
                      ) : (
                        <Button
                          color='inherit'
                          key={`${item}_menu-container_${index}`}
                          onClick={(e) => {
                            if (e.ctrlKey) {
                              window.open(
                                `${AppConstants.baseURL.slice(0, -1)}${item.href}`,
                                '_blank'
                              );
                            } else {
                              props?.historyProps?.history.push(item.href);
                            }
                          }}
                          style={{ fontSize: '13px' }}
                        >
                          {item.title}
                        </Button>
                      );
                    })}
                  </div>
                  <div className={classes.sectionMobile}>
                    <IconButton
                      aria-label='show more'
                      aria-controls={mobileMenuId}
                      aria-haspopup='true'
                      onClick={handleDrawerToggle}
                      color='inherit'
                    >
                      <MenuIcon />
                    </IconButton>
                    <Drawer
                      anchor={'right'}
                      open={showDrawer}
                      onClose={handleDrawerToggle}
                      classes={{
                        paperAnchorTop: classes.paperAnchorTop,
                        modal: classes.drawerModal
                      }}
                    >
                      <MobileMenuList
                        headerDropdown={headerDropdown}
                        user={props.user}
                        handleDrawerToggle={handleDrawerToggle}
                      />
                    </Drawer>
                  </div>
                </>
              ) : null}
            </Toolbar>
          </Grid>
          <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid>
        </Grid>
      </AppBar>
    </div>
  );
};
