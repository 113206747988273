import React, { useEffect, useState } from 'react';
import {
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles
} from '@material-ui/core';
import { Field } from 'formik';
import FormInput from '../../../../../../core-components/FormInput';
import DateField from '../../../../../../core-components/PickerOrTextDate';

import {
  PAYMENT_MODE,
  RECHARGE,
  CREDIT_REFUND,
  FREE_CREDITS,
  CREDIT_DEDUCTION,
  BRIDGE_CREDITS,
  FREE_CREDITS_TRIAL,
  CREDIT_DEDUCTION_BRIDGE_CREDITS,
  REFUND_MODE_OBJ
} from '../../../../../../core-components/CommonConstants';
import Button from '../../../../../../core-components/Button';
import Dropdown from '../../../../../../core-components/DropdownInput';
import { PAYMENT_MODE_DROPDOWN, TDS_VALUES } from '../../../CreditCompany/Shared/commonUtils';
import {
  convertFromIndianNumeration,
  convertToIndianNumeration,
  getTrimmedValue
} from '../../../../../../utils/Utlities';

const useStyles = makeStyles(() => ({
  rupeeSymbol: {
    fontFamily: 'Roboto'
  },
  paddingBottom: {
    paddingBottom: '2px'
  }
}));

export const renderSecondRow = ({
  type,
  labelClass,
  values,
  requiredField,
  styles,
  onClickViewInvoiceHandler,
  isFetched,
  errorOnFields,
  setFieldValue,
  errors
}) => {
  let result = '';
  const [amountT1, setAmountT1] = useState(false);

  useEffect(() => {
    setAmountT1(false);
  }, [type]);
  const classes = useStyles();

  switch (type) {
    case RECHARGE:
      result = (
        <>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <p className={styles.invoiceDataText}> Invoice Data</p>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputLabel className={labelClass}>
                Company Name <span className={requiredField}>*</span>
              </InputLabel>
              <Field
                disabled={true}
                fullWidth={true}
                type='text'
                name='companyName'
                component={FormInput}
                variant='outlined'
              />
              {errorOnFields.companyName.value != '' && (
                <div
                  className={
                    errorOnFields.companyName.color === 'red'
                      ? styles.errorMessageRed
                      : styles.errorMessageYellow
                  }
                >
                  {errorOnFields.companyName.value}
                </div>
              )}
            </Grid>
            <Grid item container spacing={3}>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <div className={styles.viewInvoiceButtonDiv}>
                  <Button
                    disabled={!isFetched}
                    className={styles.viewInvoiceButton}
                    label={'View Invoice'}
                    variant='outlined'
                    onClick={onClickViewInvoiceHandler}
                  />
                </div>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <InputLabel className={labelClass}>
                  Invoice Date <span className={requiredField}>*</span>
                </InputLabel>
                <Field
                  disabled={true}
                  fullWidth={true}
                  type='text'
                  name='invoiceDate'
                  component={FormInput}
                  variant='outlined'
                />
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <InputLabel className={labelClass}>Proforma Number</InputLabel>
                <Field
                  disabled={true}
                  fullWidth={true}
                  type='text'
                  name='proformaNumber'
                  component={FormInput}
                  variant='outlined'
                />
              </Grid>
            </Grid>
            <Grid item container spacing={3}>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <InputLabel className={labelClass}>
                  Payment Mode <span className={requiredField}>*</span>
                </InputLabel>
                <FormControl variant='outlined' disabled={true} fullWidth={true}>
                  <Select
                    className={styles.customTdsDropdown}
                    disabled={true}
                    value={values.paymentMode}
                  >
                    {PAYMENT_MODE_DROPDOWN.map((item, index) => {
                      return <MenuItem value={item.value}>{item.label}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <InputLabel className={labelClass}>
                  <div style={{ display: 'flex' }}>
                    Payment Date <span className={requiredField}>*</span>
                  </div>
                </InputLabel>
                <Field
                  disabled={true}
                  fullWidth={true}
                  type='text'
                  name='paymentDate'
                  component={FormInput}
                  variant='outlined'
                />
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <InputLabel className={labelClass}>
                  <div style={{ display: 'flex' }}>
                    Payment Reference <span className={requiredField}>*</span>
                  </div>
                </InputLabel>
                <Field
                  disabled={true}
                  fullWidth={true}
                  type='text'
                  name='paymentReference'
                  component={FormInput}
                  variant='outlined'
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      );
      break;

    case FREE_CREDITS:
    case BRIDGE_CREDITS:
    case FREE_CREDITS_TRIAL:
    case CREDIT_DEDUCTION_BRIDGE_CREDITS:
    case CREDIT_DEDUCTION:
      let amount = '';
      result = (
        <>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <InputLabel className={labelClass}>
              Amount <span className={requiredField}>*</span>
            </InputLabel>
            <Field
              className='inputAdornMent'
              fullWidth={true}
              type='text'
              name='amount'
              isShowInIndianCurrency={true}
              startAdornment={
                values?.amount && (
                  <InputAdornment position='start'>
                    <span className={classes.rupeeSymbol}>&#8377;</span>
                  </InputAdornment>
                )
              }
              component={FormInput}
              onBlur={(e) => {
                setAmountT1(true);
                let val = getTrimmedValue(e?.target?.value || '', false);

                if (val) val = convertFromIndianNumeration(val);

                setFieldValue('amount', val || '');
              }}
              variant='outlined'
              required={true}
            />
            {errors?.amount && amountT1 && <div className={styles.errMsg}>{errors?.amount}</div>}
          </Grid>
        </>
      );
      break;
    default:
      break;
  }
  return result;
};

export const renderThirdRow = ({
  type,
  labelClass,
  values,
  requiredField,
  customAmountInputBox,
  styles,
  errorOnFields,
  isDisabled,
  setFieldValue,
  errors
}) => {
  let result = '';
  const [amountT2, setAmountT2] = useState(false);

  useEffect(() => {
    setAmountT2(false);
  }, [type]);

  const classes = useStyles();
  switch (type) {
    case RECHARGE:
      result = (
        <>
          <Grid container spacing={3}>
            <Grid className='inputAdornMent' item lg={4} md={4} sm={12} xs={12}>
              <InputLabel className={labelClass}>
                Amount <span className={requiredField}>*</span>
              </InputLabel>

              <Input
                disableUnderline={true}
                startAdornment={
                  <InputAdornment position='start'>
                    <span className={`${classes.rupeeSymbol} ${classes.paddingBottom}`}>
                      &#8377;
                    </span>
                  </InputAdornment>
                }
                className={customAmountInputBox}
                disabled={true}
                fullWidth={true}
                type='text'
                value={convertToIndianNumeration(values?.amount)}
                variant='outlined'
              />
              {values?.amount && errors?.amount && <div className={styles.errMsg}>{errors?.amount}</div>}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <InputLabel className={labelClass}>
                Tax (%)<span className={requiredField}>*</span>
              </InputLabel>
              <Input
                disableUnderline={true}
                className={customAmountInputBox}
                disabled={true}
                fullWidth={true}
                type='text'
                value={values.tax !== '' ? `${values?.tax}%` : ''}
                variant='outlined'
              />
              {errorOnFields.tax != '' && (
                <div className={styles.errorMessageRed}>{errorOnFields.tax}</div>
              )}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <InputLabel className={labelClass}>
                TDS (%)<span className={requiredField}>*</span>
              </InputLabel>
              <FormControl variant='outlined' disabled={true} fullWidth={true}>
                <Select className={styles.customTdsDropdown} disabled={true} value={values.tds}>
                  {TDS_VALUES.map((item, index) => {
                    return <MenuItem value={item.value}>{item.label}</MenuItem>;
                  })}
                </Select>
              </FormControl>
              {errorOnFields.tds != '' && (
                <div className={styles.errorMessageRed}>{errorOnFields.tds}</div>
              )}
            </Grid>
          </Grid>
        </>
      );

      break;
    case CREDIT_REFUND:
      result = (
        <Grid container spacing={3}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <InputLabel className={labelClass}>
              Amount <span className={requiredField}>*</span>
            </InputLabel>
            <Field
              className='inputAdornMent'
              fullWidth={true}
              type='text'
              name='amount'
              startAdornment={
                values?.amount && (
                  <InputAdornment position='start'>
                    <span className={classes.rupeeSymbol}>&#8377;</span>
                  </InputAdornment>
                )
              }
              isShowInIndianCurrency={true}
              component={FormInput}
              variant='outlined'
              disabled={isDisabled}
              onBlur={(e) => {
                setAmountT2(true);
                let val = getTrimmedValue(e?.target?.value || '', false);

                if (val) val = convertFromIndianNumeration(val);
                setFieldValue('amount', val || '');
              }}
            />
            {errors?.amount && amountT2 && <div className={styles.errMsg}>{errors?.amount}</div>}
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <InputLabel className={labelClass}>
              Tax (%)<span className={requiredField}>*</span>
            </InputLabel>
            <TextField
              name='tax'
              aria-readonly
              disabled
              value={`${values?.tax}%`}
              variant='outlined'
              type='text'
              fullWidth
              size='small'
            />
            {errorOnFields.tax != '' && (
              <div className={styles.errorMessageRed}>{errorOnFields.tax}</div>
            )}
          </Grid>
        </Grid>
      );
      break;
    default:
      break;
  }
  return result;
};
