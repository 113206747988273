import { toast } from 'react-toastify';

import { getCompanyUserList } from '../../../../../api/admin';
import { errToastMessage, getErrorMessage } from '../../../../../utils/Utlities';

/* Common function used in Prepaid & Postpaid usage logs */
export const getUsersList = (source, companyId, setData) => {
  let payload = { source: source, field: 'USER', companyId: companyId };
  let data = [];

  getCompanyUserList(payload)
    .then((resp) => {
      data = resp?.data?.data;

      setData(data);
    })
    .catch((err) => {
      errToastMessage(err);
    });
};

export const INVOICE_DATA_STATUSES = {
  NOT_FETCHED: 'NOT_FETCHED',
  NOT_FOUND: 'NOT_FOUND',
  NOT_PAID: 'NOT_PAID',
  SUCCESS: 'SUCCESS'
};

export const PAYMENT_MODE = {
  'Bank Transfer': 'NEFT_OR_UPI',
  Razorpay: 'PAYMENT_GATEWAY'
};

export const PAYMENT_MODE_DROPDOWN = [
  { value: 'NEFT_OR_UPI', label: 'Bank Transfer' },
  { value: 'PAYMENT_GATEWAY', label: 'Payment Gateway' }
];

export const TDS_VALUES = [
  { value: 2, label: '2%' },
  { value: 10, label: '10%' },
  { value: 0, label: '0%' },
  { value: 'BOTH', label: '2% & 10%' },
  { value: 'NONE', label: 'None' }
];
