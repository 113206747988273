import React, { useState, useEffect } from 'react';
import { forEach, isEmpty, has } from 'lodash';

import { Field } from 'formik';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Typography,
  CircularProgress,
  Grid,
  InputLabel,
  FormControl,
  MenuItem,
  Select
} from '@material-ui/core';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  OutlinedInput,
  Button,
  Box
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import FormInput from '../../../core-components/FormInput';
import { getServices as getServicesAPI } from '../../../api/admin';
import AppConstants from '../../../core-components/AppConstants';
import BreadCrumbs from '../../../core-components/BreadCrumbs';
import { getErrorMessage } from '../../../utils/Utlities';

const useStyles = makeStyles((theme) => ({
  heading: {
    padding: 20,
    paddingLeft: 0
  },
  headingSubText: {
    color: '#8c8c8c',
    fontSize: '18px'
  },
  // packageField: {
  //     marginTop: '30px',
  // },
  activeBtn: {
    marginTop: '30px',
    margin: theme.spacing(1),
    width: '240px',
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  customPadding: {
    paddingTop: 10,
    paddingBottom: 10
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '60vh',
    alignItems: 'center'
  }
}));

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f9f9f9'
    },
    '&:hover': {
      backgroundColor: '#F7F8FA'
    }
  }
}))(TableRow);

export default function ViewCompanySettings(props) {
  const classes = useStyles();
  const [servicesData, setServicesData] = useState([]);
  const [globalServices, setGlobalServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [companyId, setCompanyId] = useState(
    new URLSearchParams(document.location.search).get('id') || null
  );
  const [companyName, setCompanyName] = useState('');

  let breadCrumbsArray = [
    { label: 'Home', href: `${AppConstants.baseURL}dashboard` },
    // { label: 'Companies', href: `${AppConstants.baseURL}company/index` },
    { label: 'Companies', href: `${AppConstants.baseURL}companies` },
    {
      label: companyName || 'Company Name',
      href: `${AppConstants.baseURL}company/view?id=${companyId}`
    }
  ];

  useEffect(() => {
    //Call API here
    getServicesAPI(10, 0, null, null, null, null, 'active')
      .then((response) => {
        if (
          response.data &&
          response.data.data &&
          response.data.data.count &&
          response.data.data.services.length > 0
        ) {
          //props.setFieldValue('globalServices', response.data.data.services);
          setGlobalServices(response.data.data.services);
          props.invalidateAssignedServices();
          props
            .getAssignedServices(companyId)
            .then((response) => { })
            .catch((error) => {
              console.error(error);
            });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (
      props.assignedServices &&
      !props.assignedServices.loading &&
      !isEmpty(props.assignedServices)
    ) {
      const assignedServices = props.assignedServices;
      let serviceData = [];
      setLoading(false);
      for (let i = 0; i < globalServices.length; i++) {
        let serviceValue = globalServices[i];
        let service = {};
        service.companyId = companyId;
        service.serviceId = serviceValue.id;
        service.serviceName = serviceValue.name;
        service.serviceCost = serviceValue.price ? +serviceValue.price : serviceValue.price;
        service.companyServiceName = serviceValue.name;
        service.companyServicePrice = serviceValue.price ? +serviceValue.price : serviceValue.price;
        if (has(assignedServices, serviceValue.id.toString())) {
          service.defaultService = assignedServices[serviceValue.id].defaultService ? 1 : 0;
          service.available = 1;
          service.companyServiceName = assignedServices[serviceValue.id].companyServiceName
            ? assignedServices[serviceValue.id].companyServiceName
            : serviceValue.name;
          service.companyServicePrice = assignedServices[serviceValue.id].companyServicePrice
            ? +assignedServices[serviceValue.id].companyServicePrice
            : serviceValue.price;
        } else {
          service.defaultService = 0;
          service.available = 0;
        }
        serviceData.push(service);
      }
      props.setFieldValue('assignedServices', serviceData);
      props.setFieldValue('packageText', props.assignedServices.company.packageText);
      props.setFieldValue('companyUuid', props.assignedServices.company.uuid);
      props.setFieldValue(
        'packageQuantity',
        props.assignedServices.company.packageQuantity ||
          props.assignedServices.company.packageQuantity === 0 ||
          props.assignedServices.company.packageQuantity === '0'
          ? props.assignedServices.company.packageQuantity
          : ''
      );
      props.setFieldValue('packageType', props.assignedServices.company.packageType || '');
      props.setFieldValue(
        'creditPeriod',
        props.assignedServices.company.creditPeriod ||
          props.assignedServices.company.creditPeriod === 0 ||
          props.assignedServices.company.creditPeriod === '0'
          ? props.assignedServices.company.creditPeriod
          : ''
      );
      props.setFieldValue(
        'walletBalance',
        props.assignedServices.company.walletBalance ||
          props.assignedServices.company.walletBalance === 0 ||
          props.assignedServices.company.walletBalance === '0'
          ? props.assignedServices.company.walletBalance
          : ''
      );
      props.setFieldValue(
        'priceOfPackage',
        props.assignedServices.company.priceOfPackage !== null ||
          props.assignedServices.company.priceOfPackage === 0 ||
          props.assignedServices.company.priceOfPackage === '0'
          ? props.assignedServices.company.priceOfPackage
          : ''
      );
      props.setFieldValue('model', props.assignedServices.company.model || '');
      setServicesData(serviceData);
      setCompanyName(assignedServices.company && assignedServices.company.name);
    }
  }, [props.assignedServices]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    props.setFieldValue('assignedServices', servicesData);
    props.handleSubmit();
  };

  const handleChange = (value, fieldName, index) => {
    const servicesDataCopy = [...servicesData];
    if (fieldName === 'companyServicePrice') {
      servicesDataCopy[index][fieldName] = value ? +value : null;
    } else {
      servicesDataCopy[index][fieldName] = value;
    }
    setServicesData(servicesDataCopy);
  };

  const {
    handleSubmit,
    isSubmitting,
    handleBlur,
    values,
    errors,
    setFieldValue,
    setFieldError,
    setTouched,
    touched,
    setFieldTouched,
    setErrors
  } = props;

  return (
    <>
      {!loading && servicesData && servicesData.length ? (
        <>
          <BreadCrumbs linksArray={breadCrumbsArray} current={'Assign Services'} />
          <Box mt={3} mb={3}>
            <Typography variant='h5' component='h5'>
              {'Assign Services '}
            </Typography>
            <Typography className={classes.headingSubText} variant='caption' component='h6'>
              {companyName || 'Company Name'}
            </Typography>
          </Box>
          <form onSubmit={handleFormSubmit}>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Available</TableCell>
                    <TableCell>Default</TableCell>
                    <TableCell>Service Name</TableCell>
                    <TableCell>Service Name for this company</TableCell>
                    <TableCell>Service Cost</TableCell>
                    <TableCell>Service Cost for this company</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {servicesData?.length
                    ? servicesData.map((service, index) => {
                      return (
                        <StyledTableRow key={service.serviceId}>
                          <TableCell>
                            <Checkbox
                              checked={service.available ? true : false}
                              color='primary'
                              inputProps={{
                                'aria-label': 'secondary checkbox'
                              }}
                              onChange={(e) => {
                                handleChange(e.target.checked ? 1 : 0, 'available', index);
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              checked={service.defaultService ? true : false}
                              disabled={!service.available}
                              color='primary'
                              inputProps={{
                                'aria-label': 'secondary checkbox'
                              }}
                              onChange={(e) => {
                                handleChange(e.target.checked ? 1 : 0, 'defaultService', index);
                              }}
                            />
                          </TableCell>
                          <TableCell>{service.serviceName}</TableCell>
                          <TableCell>
                            <OutlinedInput
                              variant='outlined'
                              value={service.companyServiceName}
                              disabled={!service.available}
                              classes={{ input: classes.customPadding }}
                              onChange={(e) => {
                                handleChange(e.target.value, 'companyServiceName', index);
                              }}
                            />
                          </TableCell>
                          <TableCell>{service.serviceCost}</TableCell>
                          <TableCell>
                            <OutlinedInput
                              variant='outlined'
                              value={service.companyServicePrice}
                              classes={{ input: classes.customPadding }}
                              type='number'
                              disabled={!service.available}
                              onChange={(e) => {
                                handleChange(e.target.value, 'companyServicePrice', index);
                              }}
                            />
                          </TableCell>
                        </StyledTableRow>
                      );
                    })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <Box mt={4}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Field
                    type='text'
                    name='packageText'
                    label='Package Text'
                    component={FormInput}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Field
                    type='text'
                    name='packageQuantity'
                    label='Package Quantity'
                    component={FormInput}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Field
                    type='text'
                    name='packageType'
                    label='Package Type'
                    component={FormInput}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <FormControl size='small' fullWidth style={{ marginBottom: '16px' }}>
                    <InputLabel id='modelLabel'>Model</InputLabel>
                    <Select
                      labelId='modelLabel'
                      id='model'
                      value={values.model || ''}
                      onChange={(e) => {
                        setFieldValue('model', e.target.value);
                      }}
                      fullWidth={true}
                    >
                      <MenuItem value={''}>{'None'}</MenuItem>
                      {[
                        { label: 'Prepaid', value: 'prepaid' },
                        { label: 'Prepaid-Recharge', value: 'prepaid-recharge' },
                        { label: 'Postpaid-Initiation', value: 'postpaid-initiation' },
                        { label: 'Postpaid-Completion', value: 'postpaid-completion' }
                      ].map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.value}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Field
                    type='text'
                    name='creditPeriod'
                    label='Credit Period'
                    component={FormInput}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Field
                    type='text'
                    name='walletBalance'
                    label='Wallet Balance'
                    component={FormInput}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Field
                    type='text'
                    name='priceOfPackage'
                    label='Price of Package'
                    component={FormInput}
                  />
                </Grid>
              </Grid>
            </Box>
            <Button
              variant='contained'
              size='large'
              type='submit'
              className={classes.activeBtn}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </form>
        </>
      ) : (
        <Grid container spacing={2} className={classes.spinnerContainer}>
          <CircularProgress />
        </Grid>
      )}
    </>
  );
}
