import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { Button, MenuItem, Menu, ListItemText } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  anchorText: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  }
}));

export default ({ item, index }) => {
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment key={`${item}_menu-container_${index}`}>
      <Button
        aria-controls={open ? `${item.title}_styled-menu_${index}` : undefined}
        aria-haspopup='true'
        onClick={handleMenu}
        style={{ fontSize: '13px' }}
        color='inherit'
        key={`${item}_menu-button_${index}`}
      >
        {item.title}
        <ArrowDropDownIcon />
      </Button>
      <StyledMenu
        id={`${item.title}_styled-menu_${index}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        key={`${item.title}_styled-menu_${index}`}
      >
        {item?.child.map((item1, index1) => {
          return (
            <StyledMenuItem key={`${item1}_item-text_${index1}`} onClick={() => handleClose()}>
              {item1.text === 'Find the Right Package' ? (
                <div
                  className={classes.anchorText}
                  onClick={() => {
                    window.open(item1.href, '_blank');
                  }}
                >
                  <ListItemText primary={item1.text} />
                </div>
              ) : (
                <Link to={item1.href ? item1.href : '#'} className={classes.anchorText}>
                  <ListItemText primary={item1.text} />
                </Link>
              )}
            </StyledMenuItem>
          );
        })}
      </StyledMenu>
    </React.Fragment>
  );
};
