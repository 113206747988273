import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  makeStyles
} from '@material-ui/core';
import { forEach, has, isEmpty, values } from 'lodash';
import moment from 'moment';
import 'react-dates/initialize';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Table from '../../../../../core-components/Table';
import styles from './RechargeLog.module.scss';
import './Datepicker.scss';
import {
  PAYMENT_MODE_OBJ,
  RECHARGE_LOGS_EVENT
} from '../../../../../core-components/CommonConstants';
import { getUsersList } from '../../CreditCompany/Shared/commonUtils';
import { Button } from 'react-bootstrap';
import ViewUpdateLogsModal from '../ViewUpdateLogsModal';
import { getCreditInRupee } from '../../../../../utils/Utlities';
import { getToken } from '../../../../../api/storage';
import AppConstants from '../../../../../core-components/AppConstants';

const useStyles = makeStyles((theme) => ({
  costContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  rupeeSymbol: {
    fontFamily: 'Roboto'
  }
}));

//this is for each row the heading creation  with whatever id for Headcells

export default function RechargeLog(props) {
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  //FiltersStateVariable
  const [txnValue, setTXNValue] = useState('ALL');
  const [userValue, setUserValue] = useState('ALL');
  const [dateRangeShow, setDateRangeShow] = useState({ startDate: null, endDate: null });
  const [companyUserList, setCompanyUserList] = useState([]);
  const [isDocPaymentCol, setIsDocPaymentCol] = useState(false);
  const [modalData, setModalData] = useState({});
  const [isOpenViewModal, setIsOpenViewModal] = useState(false);
  const [isModalViewOnly, setIsModalViewOnly] = useState(true);
  const [headCells, setHeadCells] = useState([
    {
      id: 'txnDate',
      label: 'TXN Date',
      align: 'center'
    },
    {
      id: 'txnType',
      label: 'TXN Type',
      align: 'left'
    },
    {
      id: 'payMode',
      label: 'Payment Mode',
      align: 'left'
    },
    {
      id: 'doc',
      label: 'Document',
      align: 'right'
    },
    {
      id: 'user',
      label: 'User',
      align: 'left'
    },
    {
      id: 'amount',
      label: 'Amount',
      align: 'right'
    },
    {
      id: 'info',
      label: 'Additional Info / Actions',
      align: 'center'
    }
  ]);
  const { companyId } = props;
  const packageType = new URLSearchParams(document.location.search).get('type') || null;
  const classes = useStyles();
  const token = getToken();
  const DocPayColumns = [
    {
      id: 'docDate',
      label: 'Document Date',
      align: 'center'
    },
    {
      id: 'payDate',
      label: 'Payment Date',
      align: 'center'
    }
  ];
  useEffect(() => {
    getUsersList('RECHARGE_LOGS', props?.companyId, setCompanyUserList);
  }, []);
  useEffect(() => {
    setLoading(true);
    getRechargeLogs(rowsPerPage, offset, userValue, dateRangeShow, txnValue, isDocPaymentCol);
  }, [props.companyId]);

  useEffect(() => {
    if (has(props.companyRechargeLogs, 'rechargeLogs')) {
      let rowsData =
        props?.companyRechargeLogs &&
        props?.companyRechargeLogs?.rechargeLogs &&
        props?.companyRechargeLogs?.rechargeLogs?.length &&
        getRowData(props.companyRechargeLogs.rechargeLogs);
      setRows(rowsData || []);
      setTotalCount(props.companyRechargeLogs.count);
    }
  }, [props.companyRechargeLogs]);

  const getRechargeLogs = (
    limit,
    offsetValue,
    userFilterValue,
    dateFilterValue,
    filteredTag,
    docPaymentDate = isDocPaymentCol
  ) => {
    let fromDate = null;
    let toDate = null;

    if (dateFilterValue.startDate && dateFilterValue.endDate) {
      fromDate = moment(dateFilterValue.startDate).utc('GMT+5:30').format('YYYY-MM-DD');
      toDate = moment(dateFilterValue.endDate).utc('GMT+5:30').format('YYYY-MM-DD');
    }
    setLoading(true);
    const query = {
      company_id: props.companyId,
      limit,
      offset: offsetValue,
      user_id: userFilterValue === 'ALL' ? undefined : userFilterValue,
      from_date: fromDate,
      to_date: toDate,
      tag: filteredTag === 'ALL' ? undefined : filteredTag,
      doc_and_payment_date: docPaymentDate ? docPaymentDate : undefined
    };
    props
      .getCompanyRechargeLogs(query)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const getPendingState = (value) => {
    return value.source === 'CREDIT_REFUND' &&
      value?.paymentsCompanyPrepaidInvoice &&
      !value?.paymentsCompanyPrepaidInvoice?.creditNoteNumber
      ? true
      : false;
  };
  const getPaymentMode = (value) => {
    return getPendingState(value) ? (
      <span className={styles.redColorText}>PENDING</span>
    ) : (
      PAYMENT_MODE_OBJ[value?.paymentsCompanyPrepaidInvoice?.paymentMode] || '-'
    );
  };
  const getDocument = (value) => {
    const isPending = getPendingState(value);

    if (isPending) {
      return <span className={styles.redColorText}>PENDING</span>;
    } else {
      if (
        value?.paymentsCompanyPrepaidInvoice?.creditNoteNumber ||
        (value?.paymentsCompanyPrepaidInvoice?.invoiceUrl &&
          value?.paymentsCompanyPrepaidInvoice?.invoiceNumber)
      ) {
        return (
          <a
            className={styles.blueColorText}
            href={`${AppConstants.baseURL}invoice/document?invoice_id=${value?.paymentsCompanyPrepaidInvoice?.rechargeLogId}&company_type=${packageType}&company_id=${companyId}`}
            target='_blank'
          >
            {value?.paymentsCompanyPrepaidInvoice?.creditNoteNumber ||
              value?.paymentsCompanyPrepaidInvoice?.invoiceNumber}
          </a>
        );
      } else {
        return '-';
      }
    }
  };
  const getDocumentDate = (value) => {
    return getPendingState(value) ? (
      <span className={styles.redColorText}>PENDING</span>
    ) : value?.paymentsCompanyPrepaidInvoice?.documentDate ? (
      moment(value?.paymentsCompanyPrepaidInvoice?.documentDate).utc().format('DD-MM-YYYY')
    ) : (
      '-'
    );
  };
  const getPaymentDate = (value) => {
    return getPendingState(value) ? (
      <span className={styles.redColorText}>PENDING</span>
    ) : value?.paymentsCompanyPrepaidInvoice?.paymentDate ? (
      moment(value?.paymentsCompanyPrepaidInvoice?.paymentDate).utc().format('DD-MM-YYYY')
    ) : (
      '-'
    );
  };
  const getButton = (value) => {
    return (
      <Button
        size='sm'
        onClick={() => {
          setModalData(value);
          setIsModalViewOnly(!getPendingState(value));
          setIsOpenViewModal(true);
        }}
      >
        {getPendingState(value) ? 'UPDATE' : 'VIEW'}
      </Button>
    );
  };
  const getUser = (value) => {
    switch (value.source) {
      case 'RECHARGE':
        return value.user
          ? value.isCompanyAdmin === 1
            ? `CA: ${value.user.name} (${value.user?.id})`
            : `SA: ${value.user.name} (${value.user?.id})`
          : 'External User';
      default:
        return value.user
          ? value.isCompanyAdmin === 1
            ? `CA: ${value.user.name} (${value.user?.id})`
            : `SA: ${value.user.name} (${value.user?.id})`
          : '-';
    }
  };

  const getRowData = (values) => {
    let rechargeLogs = [];
    forEach(values, function (value, key) {
      if (!isEmpty(value)) {
        let rowData = createData(
          moment(value.createdAt).utc().format('DD-MM-YYYY'),
          getDocumentDate(value),
          getPaymentDate(value),
          getTag(value.source),
          getPaymentMode(value),
          getDocument(value),
          getUser(value),
          getCreditInRupee(value.creditsAmount, '-'),
          getButton(value)
        );
        rechargeLogs.push(rowData);
      }
    });
    return rechargeLogs;
  };

  const getTag = (source) => {
    return RECHARGE_LOGS_EVENT.find((tag) => tag.value === source).label || 'N/A';
  };
  const handleChangePage = (newPage) => {
    setOffset(newPage);
    setLoading(true);
    getRechargeLogs(rowsPerPage, newPage, userValue, dateRangeShow, txnValue);
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setOffset(0);
    setLoading(true);
    getRechargeLogs(parseInt(value, 10), 0, userValue, dateRangeShow, txnValue);
  };

  const handleDateFilter = (updatedDate) => {
    const startDate = updatedDate[0];
    const endDate = updatedDate[1];
    setDateRangeShow((prevState) => ({
      ...prevState,
      startDate: startDate,
      endDate: endDate
    }));
    paginate(0);
    if (startDate && endDate) {
      getRechargeLogs(rowsPerPage, 0, userValue, { startDate, endDate }, txnValue);
    } else if (!startDate & !endDate) {
      getRechargeLogs(rowsPerPage, 0, userValue, { startDate: null, endDate: null }, txnValue);
    }
  };

  const handleTXNDropdownFilter = (e) => {
    setTXNValue(e.target.value);
    paginate(0);
    getRechargeLogs(rowsPerPage, 0, userValue, dateRangeShow, e.target.value);
  };
  const handleUserDropdownFilter = (e) => {
    setUserValue(e.target.value);
    paginate(0);
    getRechargeLogs(rowsPerPage, 0, e.target.value, dateRangeShow, txnValue);
  };
  const paginate = (offset) => {
    setOffset(offset);
  };
  const handleDocPaymentCheck = (e) => {
    setIsDocPaymentCol(e.target.checked);
    getRechargeLogs(rowsPerPage, 0, e.target.value, dateRangeShow, txnValue, e.target.checked);
    if (!e.target.checked) {
      const withoutDocPay = headCells.filter(
        (cells) => cells.id !== 'docDate' && cells.id !== 'payDate'
      );
      setHeadCells(withoutDocPay);
    } else {
      headCells.splice(1, 0, ...DocPayColumns);
    }
  };

  const createData = (txnDate, docDate, payDate, txnType, payMode, doc, user, amount, info) => {
    if (isDocPaymentCol) {
      return { txnDate, docDate, payDate, txnType, payMode, doc, user, amount, info };
    } else {
      return { txnDate, txnType, payMode, doc, user, amount, info };
    }
  };

  const handleSuccess = () => {
    getRechargeLogs(rowsPerPage, offset, userValue, dateRangeShow, txnValue, isDocPaymentCol);
    setIsOpenViewModal(false);
  };
  return (
    <>
      <Grid container alignItems='center' md={12} spacing={2}>
        <Grid item md={8}>
          <Grid container spacing={2}>
            <Grid item>
              <div className={styles.filter}>
                <label>Date Filter</label>
                <DatePicker
                  wrapperClassName={styles.myDatePicker}
                  selectsRange={true}
                  startDate={dateRangeShow.startDate}
                  endDate={dateRangeShow.endDate}
                  onChange={(update) => {
                    handleDateFilter(update);
                  }}
                  isClearable={true}
                />
              </div>
            </Grid>
            <Grid item md={4}>
              <div className={styles.filter}>
                <label>Filter by TXN Type</label>
                <FormControl variant='outlined' size='small'>
                  <Select
                    labelId='demo-simple-select-outlined-label'
                    id='demo-simple-select-outlined'
                    value={txnValue}
                    onChange={handleTXNDropdownFilter}
                  >
                    <MenuItem value='ALL'>All</MenuItem>
                    {RECHARGE_LOGS_EVENT.map((event) => (
                      <MenuItem value={event.value}>{event.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid item md={4}>
              <div className={styles.filter}>
                <label>Filter by User</label>
                <FormControl variant='outlined' size='small'>
                  <Select
                    labelId='demo-simple-select-outlined-label'
                    id='demo-simple-select-outlined'
                    value={userValue}
                    onChange={handleUserDropdownFilter}
                  >
                    <MenuItem value='ALL'>All</MenuItem>
                    {companyUserList.map((user) => (
                      <MenuItem value={user.user_id}>
                        {`${user.isCompanyAdmin ? 'CA' : 'SA'}: ${user.user_name} (${
                          user.user_id
                        })`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={isDocPaymentCol}
                onChange={handleDocPaymentCheck}
                name='isDocPaymentCol'
                color='primary'
              />
            }
            label='Show Document & Payment Date Column'
          />
        </Grid>
      </Grid>
      <Table
        headCells={headCells}
        rowData={rows}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={offset}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        loading={loading}
      />
      {isOpenViewModal ? (
        <ViewUpdateLogsModal
          open={isOpenViewModal}
          isModalViewOnly={isModalViewOnly}
          data={modalData}
          handleClose={() => setIsOpenViewModal(false)}
          handleSuccess={handleSuccess}
        />
      ) : null}
    </>
  );
}
