import * as Yup from 'yup';
import { getPackageOrSubtypeNameValidationSchema } from '../../../../../../utils/ValidationSchema';

export default Yup.object().shape({
  companyName: Yup.string().when('isCompany', {
    is: false,
    then: Yup.string().required('Please enter Company name')
  }),
  packageName: Yup.string().when('isCompany', {
    is: true,
    then: getPackageOrSubtypeNameValidationSchema({ type: 'Package', isRequired: true })
  }),

  rechargeDetails: Yup.array().of(
    Yup.object().shape({
      rechargeUnits: Yup.number()
        .integer('Please enter a valid unit')
        .min(1, `Unit can't be 0`)
        .required('Please enter a valid unit')
        .typeError('Unit must be Number'),
      costPerCandidate: Yup.number()
        .integer('Please enter a valid cost')
        .min(1, `Cost can't be 0`)
        .required('Please enter a valid cost')
        .typeError('Cost must be Number')
    })
  )
});
