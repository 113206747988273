import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';

import ContentArea from '../ContentArea';
import { useIdleTimer } from 'react-idle-timer';
import Logout from '../../components/Logout/Logout';

import 'react-widgets/dist/css/react-widgets.css';
import { exemptUserFromAutoLogout } from '../../../src/utils/Utlities';
import { getAuthToken, jwtParser } from '../../api/storage';

import history from '../../utils/history';

export default () => {
  const [isLogout, setIsLogout] = useState(false);
  const [userId, serUserId] = useState(false);
  useEffect(() => {
    console.log(
      `%c Don't you dare`,
      'font-weight: bold; font-size: 40px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) ,  6px 6px 0 rgb(245,221,8) , 9px 9px 0 rgb(2,135,206) ,  12px 12px 0 rgb(42,21,113), 15px 15px 0 rgba(2,135,206,0)'
    );
    const token = getAuthToken();
    if (token) {
      const user = jwtParser(token);
      serUserId(user.data.userId);
    }
  }, []);

  const onIdle = () => {
    setIsLogout(true);
  };

  // eslint-disable-next-line no-empty-pattern
  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 60 * 24,
    promptTimeout: 0,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: true,
    syncTimers: 0
  });

  return (
    <React.Fragment>
      <Router history={history}>
        <ContentArea />
        {!exemptUserFromAutoLogout(userId) && getAuthToken() && isLogout && (
          <Logout history={history} />
        )}
      </Router>
    </React.Fragment>
  );
};
