import { connect } from 'react-redux';
import { compose } from 'redux';

import { getPostpaidCreditCompanies as getPostpaidCreditCompaniesAPI } from '../../../../actions/admin';

const mapStateToProps = (state) => {
  return {
    list: state.postpaidCreditCompanies || null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPostpaidCreditCompanies: (limit, offset, companyName) =>
      dispatch(getPostpaidCreditCompaniesAPI(limit, offset, companyName))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
