import { isEmpty } from 'lodash';
import moment from 'moment';

import { connect } from 'react-redux';
import { compose } from 'redux';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
