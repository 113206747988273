import { connect } from 'react-redux';
import { compose } from 'redux';

import { getPrepaidUsageLogs as getPrepaidUsageLogsAPI } from '../../../../../actions/admin';

const mapStateToProps = (state) => {
  return {
    prepaidUsageLogs: state.prepaidUsageLogs || null,
    companyCreditBalance: state.companyCreditBalance || null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPrepaidUsageLogs: (
      companyId,
      limit,
      offset,
      event,
      from_date,
      to_date,
      candidate_name,
      user_name,
      invoice_number,
      credit_type,
      user_id
    ) =>
      dispatch(
        getPrepaidUsageLogsAPI(
          companyId,
          limit,
          offset,
          event,
          from_date,
          to_date,
          candidate_name,
          user_name,
          invoice_number,
          credit_type,
          user_id
        )
      )
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
