import { connect } from 'react-redux';
import { compose } from 'redux';
import { isEmpty } from 'lodash';
import { invalidateCompanySettings } from '../../../../actions/admin';

const mapStateToProps = (state) => {
    return {
        profile: state.profile && !isEmpty(state.profile) ? state.profile : null
    };
};
const mapDispatchToProps = (dispatch) => {
  return {
    invalidateCompanySettings: () => dispatch(invalidateCompanySettings())
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
