import React, { useEffect, useState } from 'react';
import { forEach, isEmpty, has } from 'lodash';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, Zoom, Typography } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Grid, Box } from '@material-ui/core';

import Table from '../../../core-components/Table';
import SearchInput from '../../../core-components/SearchInput';
import Button from '../../../core-components/Button';
import BreadCrumbs from '../../../core-components/BreadCrumbs';
import Dropdown from '../../../core-components/DropdownInput';
import AppConstants from '../../../core-components/AppConstants';
import ActionModal from './OpsStatusAction';

//this is for each row the heading creation  with whatever id for headcells
function createData(index, id, statusName, statusDetails, createdAt, updatedAt, action) {
  return { index, id, statusName, statusDetails, createdAt, updatedAt, action };
}

const useStyles = makeStyles({
  editBtn: {
    color: '#3788ac',
    cursor: 'pointer'
  }
});

const headCells = [
  {
    id: 'index',
    label: '#',
    align: 'left'
  },
  {
    id: 'id',
    label: 'ID',
    align: 'left'
  },
  {
    id: 'statusName',
    label: 'Status Name',
    align: 'center'
  },

  {
    id: 'statusDetails',
    label: 'Is Active',
    align: 'center'
  },

  {
    id: 'createdAt',
    label: 'Created At',
    align: 'center'
  },
  {
    id: 'updatedAt',
    label: 'Updated At',
    align: 'center'
  },
  {
    id: 'action',
    label: '',
    align: 'left'
  }
];

export default function EnhancedTable(props) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [title, setTitle] = useState(null);
  const [statusDetails, setStatusDetails] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [name, setName] = useState('');
  const [status, setStatus] = useState('all');

  const dropdownValues = [
    { label: 'All', value: 'all' },
    { label: 'Active', value: 'active' },
    { label: 'In Active', value: 'inactive' }
  ];

  const breadCrumbsArray = [{ label: 'Home', href: `${AppConstants.baseURL}dashboard` }];

  //action  modal
  const [openActionModal, setOpenActionModal] = useState(false);

  const handleOpenActionModal = () => {
    setOpenActionModal(true);
  };
  const handleCloseActionModal = () => {
    setOpenActionModal(false);
    props.getOpsStatus(rowsPerPage, offset);
  };

  useEffect(() => {
    setLoading(true);
    props.getOpsStatus(rowsPerPage, offset);
  }, []);

  useEffect(() => {
    if (has(props.opsStatus, 'rows')) {
      let rowsData =
        props?.opsStatus &&
        props?.opsStatus?.rows &&
        props?.opsStatus?.rows?.length &&
        getRowData(props.opsStatus.rows.sort((a, b) => a.id - b.id));
      setLoading(false);
      setRows(rowsData || []);
      setTotalCount(props.opsStatus.count);
    }
  }, [props.opsStatus]);

  const getRowData = (values) => {
    let opsStatusRow = [];
    forEach(values, function (value, key) {
      if (!isEmpty(value)) {
        let rowData = createData(
          key + 1 + offset * rowsPerPage,
          value.id,
          value.statusName,
          value.isActive === '1' ? 'Yes' : 'No',
          moment(value.createdAt).format('DD-MMM-YYYY HH:mm A'),
          moment(value.updatedAt).format('DD-MMM-YYYY HH:mm A'),
          buttonEdit(value)
        );
        opsStatusRow.push(rowData);
      }
    });
    return opsStatusRow;
  };

  const buttonEdit = (statusDetails) => {
    return (
      <Tooltip TransitionComponent={Zoom} title='Edit' arrow={true}>
        <EditOutlinedIcon
          className={classes.editBtn}
          onClick={() => {
            setTitle('Edit');
            setStatusDetails(statusDetails);
            handleOpenActionModal();
          }}
        />
      </Tooltip>
    );
  };

  //these are filters
  const getNameFilter = () => {
    return (
      <SearchInput
        placeholder={'Search Name'}
        value={name}
        handleSearch={(name) => {
          applyNameFilter(name?.trim());
        }}
      />
    );
  };

  const getStatusFilter = () => {
    return (
      <Dropdown
        dropdownValues={dropdownValues}
        label={'Status'}
        value={status}
        handleSelect={(value) => {
          applyStatusFilter(value);
        }}
      />
    );
  };

  const applyNameFilter = (name) => {
    setLoading(true);
    setName(name);
    setOffset(0);
    props
      .getOpsStatus(rowsPerPage, 0, name === '' ? null : name, status === 'all' ? null : status)
      .then(() => {
        setLoading(false);
      });
  };

  const applyStatusFilter = (value) => {
    setLoading(true);
    if (value === 'all') {
      setStatus('all');
      setOffset(0);
      props.getOpsStatus(rowsPerPage, 0, name === '' ? null : name, null).then(() => {
        setLoading(false);
      });
    } else {
      setStatus(value);
      setOffset(0);
      props.getOpsStatus(rowsPerPage, 0, name === '' ? null : name, value).then(() => {
        setLoading(false);
      });
    }
  };

  const handleChangePage = (newPage) => {
    setOffset(newPage);
    setLoading(true);
    props
      .getOpsStatus(
        rowsPerPage,
        newPage,
        name === '' ? null : name,
        status === 'all' ? null : status
      )
      .then(() => {
        setLoading(false);
      });
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setOffset(0);
    setLoading(true);
    props
      .getOpsStatus(
        parseInt(value, 10),
        0,
        name === '' ? null : name,
        status === 'all' ? null : status
      )
      .then(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <BreadCrumbs linksArray={breadCrumbsArray} current={'Ops Status'} />
      <Typography variant='h4' component='h3'>
        {'Ops Status'}
      </Typography>
      <Button
        label={'Add Status'}
        color={'primary'}
        onClick={() => {
          setTitle('Add New');
          setStatusDetails(null);
          handleOpenActionModal();
        }}
      />
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item lg={3} md={4} sm={4} xs={10}>
            {getNameFilter()}
          </Grid>
          <Grid item lg={3} md={4} sm={4} xs={10}>
            {getStatusFilter()}
          </Grid>
        </Grid>
      </Box>
      <Table
        headCells={headCells}
        rowData={rows}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={offset}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        loading={loading}
      />
      <ActionModal
        open={openActionModal}
        title={title}
        details={statusDetails}
        handleClose={handleCloseActionModal}
      />
    </>
  );
}
