import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, FormControlLabel, Checkbox, RadioGroup, Radio } from '@material-ui/core';
import {
  ADDON_CONFIG_MAPPING,
  ADDRESSES_FOR_PACKAGE_CONFIG,
  courtVerificationMediumDropdown
} from '../../../../../../core-components/CommonConstants';
import { addressVerificationMediumDropdown } from '../../../../../../core-components/CommonConstants';
import { FormLabel } from 'react-bootstrap';
import _ from 'lodash';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginLeft: '68px',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  formControl: {
    width: '100%'
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  required: {
    color: '#db3131'
  }
}));

/**
 * cPkgDetails: Company PackageDetails
 *  CA selected package detailsInfo
 * aPkgDetails: Addon's allowed config/available options in addons
 * onValueChange: Func to save the values to parent state
 * values: Addons values as they changes
 */
export default (props) => {
  const classes = useStyles();

  //   Refer DocBlock above for detailed variable usage
  const { cPkgDetails, onValueChange, values, isCourt, addonsConfig } = props;

  const handleChange = (event, name) => {
    let dummy = [...values.config];
    if (event.target.checked) {
      if (!dummy?.includes(name)) {
        dummy.push(name);
        onValueChange('config', dummy);
      }
    } else {
      const index = dummy.indexOf(name);
      dummy.splice(index, 1);
      onValueChange('config', dummy);
    }
    if (isCourt ? getCourtConfiguration().length === 1 : getAddressConfiguration().length === 1) {
      onValueChange(
        'verification_type',
        isCourt ? getCourtConfiguration()[0] : getAddressConfiguration()[0]
      );
    }
    // automatically select verification type based on package configuration | Omit if add-on config for the same is not present
    if (cPkgDetails.length === 1) {
      let addressValues = { ...values };
      if (
        addressValues.config.length > 0 &&
        Object.keys(addonsConfig).includes(ADDON_CONFIG_MAPPING[cPkgDetails[0].verificationType])
      ) {
        addressValues.verification_type = cPkgDetails[0].verificationType;
        onValueChange('verification_type', addressValues.verification_type);
      }
    }
    if (values.config.length === 0) {
      onValueChange('verification_type', '');
    }
  };

  const hasValue = (name) => {
    return values.config.includes(name);
  };

  const isDisabled = () => {
    return values.config.length === props.limit;
  };
  const handleVerificationType = (value) => {
    let addressValues = { ...values };
    if (addressValues.config.length > 0) {
      addressValues.verification_type = value;
      onValueChange('verification_type', addressValues.verification_type);
    }
  };
  const getAddressRadioOptions = () => {
    if (cPkgDetails.length === 1) {
      return addressVerificationMediumDropdown.filter(
        (ob) => ob.value === cPkgDetails[0].verificationType
      );
    } else {
      return addressVerificationMediumDropdown;
    }
  };
  const getCourtRadioOptions = () => {
    if (cPkgDetails.length === 1) {
      return courtVerificationMediumDropdown.filter(
        (ob) => ob.value === cPkgDetails[0].verificationType
      );
    } else {
      return courtVerificationMediumDropdown;
    }
  };
  const getAddressConfiguration = () => {
    let addrConfig = [];

    if (addonsConfig?.addressDigital) {
      addrConfig.push('DIGITAL');
    }
    if (addonsConfig?.addressPostal) {
      addrConfig.push('POSTAL');
    }
    if (addonsConfig?.addressPhysical) {
      addrConfig.push('PHYSICAL');
    }
    return addrConfig;
  };
  const getCourtConfiguration = () => {
    let addrConfig = [];

    if (addonsConfig?.courtStandard) {
      addrConfig.push('STANDARD');
    }
    if (addonsConfig?.courtStandardLawfirm) {
      addrConfig.push('STANDARD_LAWFIRM');
    }
    return addrConfig;
  };
  return (
    <>
      <div className={classes.root}>
        {ADDRESSES_FOR_PACKAGE_CONFIG.map((value, index) => {
          return (
            <React.Fragment key={`Package_Configure_${index}`}>
              <Grid container className={cPkgDetails?.length === 0 ? 'mt-2' : ''}>
                <Grid item xl={4} md={4} lg={4} sm={4} xs={4}>
                  {!cPkgDetails.map((o) => o.type)?.includes(value?.name) ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!hasValue(value?.name) && isDisabled()}
                          checked={hasValue(value?.name)}
                          onChange={(e) => handleChange(e, value?.name)}
                          name={value?.name}
                        />
                      }
                      label={value?.label}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </React.Fragment>
          );
        })}
        {cPkgDetails.length !== 2 ? (
          <div className='mt-2'>
            <FormLabel component='legend'>
              Choose verification type: <span className={classes.required}>*</span>
            </FormLabel>
            <RadioGroup
              aria-label='verification_type'
              name='verification_type'
              value={values?.verification_type}
              onChange={(e) => handleVerificationType(e.target.value)}
            >
              <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                {isCourt &&
                  getCourtRadioOptions().map(
                    (ob) =>
                      Object.keys(addonsConfig).includes(ADDON_CONFIG_MAPPING[ob.value]) && (
                        <FormControlLabel
                          value={ob.value}
                          control={
                            <Radio
                              value={ob.value}
                              color='primary'
                              checked={values?.verification_type === ob.value}
                            />
                          }
                          label={ob.label}
                          disabled={
                            values?.config.length === 0 ||
                            !Object.keys(addonsConfig).includes(ADDON_CONFIG_MAPPING[ob.value])
                          }
                        />
                      )
                  )}
                {!isCourt &&
                  getAddressRadioOptions().map(
                    (ob) =>
                      Object.keys(addonsConfig).includes(ADDON_CONFIG_MAPPING[ob.value]) && (
                        <FormControlLabel
                          value={ob.value}
                          control={
                            <Radio
                              value={ob.value}
                              color='primary'
                              checked={values?.verification_type === ob.value}
                            />
                          }
                          label={ob.label}
                          disabled={
                            values?.config.length === 0 ||
                            !Object.keys(addonsConfig).includes(ADDON_CONFIG_MAPPING[ob.value])
                          }
                        />
                      )
                  )}
              </Grid>
            </RadioGroup>
          </div>
        ) : null}
      </div>
    </>
  );
};
