import { connect } from 'react-redux';
import { getCompanyBgvConfig } from '../../../../actions/admin';

const mapStateToProps = (state) => {
  return {
    formConfig: state.companyBgvConfig
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyBGVConfig: (companyId) => dispatch(getCompanyBgvConfig(companyId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
