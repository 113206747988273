import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  RadioGroup,
  Radio
} from '@material-ui/core';
import Dropdown from '../../../core-components/DropdownInput';
import { ADDRESSES_FOR_PACKAGE_CONFIG, PLAIN_CHECK_ICONS, addressVerificationMediumDropdown, courtVerificationMediumDropdown } from '../../CommonConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { Col, FormLabel, Row } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  formControl: {
    marginBottom: theme.spacing(3),
    width: '100%'
  },
  required: {
    color: '#db3131'
  }
}));
export default (props) => {
  const classes = useStyles();
  const { values, limit, addressChange, isCourt, isUnassigned } = props;
  const handleChange = (event, name) => {
    let dummy = [...values.config];
    if (event.target.checked) {
      if (!dummy.includes(name)) {
        dummy.push({ type: name, verificationType: values.verificationType ? values.verificationType : '' });
        addressChange('config', dummy);
        addressChange('any', values.any - 1);
      }
    } else {
      const index = _.findIndex(dummy, function (o) { return o.type === name; });
      dummy.splice(index, 1);
      addressChange('config', dummy);
      addressChange('any', values.any + 1);
    }
  };
  const handleMedium = (eventValue, index) => {
    let addressValues = JSON.parse(JSON.stringify(values));
    addressValues.config[index].verificationType = eventValue;
    addressChange('config', addressValues.config);
  }
  const handleVerificationType = (value) => {
    let addressValues = { ...values };
    addressChange('verificationType', value);
    if (addressValues.config.length > 0) {
      addressValues.config[0].verificationType = value;
      addressChange('config', addressValues.config);
    }
  }
  const hasValue = (name) => {
    return values.config.map(v => v.type).includes(name);
  };

  const isDisabled = () => {
    return values.config.length === props.limit;
  };

  const getConfigIndex = (value) => {
    const index = _.findIndex(values.config, o => o.type === value);
    return index;
  }
  return (
    <>
      <div className={classes.root}>
        <Grid className={classes.root} item container>
          <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
            <Typography variant='h6' style={{ fontSize: '18px' }}>
              <span style={{ marginRight: '10px' }} title={'address'}>
                <FontAwesomeIcon
                  icon={
                    PLAIN_CHECK_ICONS[isCourt ? 'court' : 'address']
                      ? PLAIN_CHECK_ICONS[isCourt ? 'court' : 'address'].icon
                      : faBan
                  }
                  color='#222'
                />
              </span>
              {isCourt ? 'Court' : 'Address'}
              {!limit ? (
                <span style={{ marginRight: '10px', fontSize: '12px', color: '#312c51' }}>
                  <i>{` (*Not Selected)`}</i>
                </span>
              ) : null}
            </Typography>
          </Grid>
          <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
            <FormControl component='fieldset' className={classes.formControl}>
              <Box mt={2} mb={1}>
                <b>
                  <i>{`${isCourt ? 'Court' : 'Address'} Count: `}</i>
                </b>
                {limit === 2 ? `0` : `${values.any} `}
              </Box>
              <FormGroup>
                {ADDRESSES_FOR_PACKAGE_CONFIG.map((value, index) => {
                  if (limit === 2) {
                    return (
                      <Row>
                        <Col>
                          <FormControlLabel
                            key={`Package_Configure_${index}`}
                            control={
                              <Checkbox
                                disabled={true}
                                checked={true}
                                name={value?.name}
                              />
                            }
                            label={value?.label}
                          />
                        </Col>
                        <Col>
                          <FormControl variant='outlined' className={classes.formControl} size='small'>
                            <Dropdown
                              dropdownValues={isCourt ? courtVerificationMediumDropdown : addressVerificationMediumDropdown}
                              label={'Choose verification type'}
                              required={!isUnassigned}
                              value={values.config[getConfigIndex(value.name)]?.verificationType}
                              disabled={!hasValue(value?.name) || isUnassigned}
                              handleSelect={(v) => {
                                handleMedium(v, getConfigIndex(value.name));
                              }}
                            />
                          </FormControl>
                        </Col>
                      </Row>
                    );
                  } else {
                    return (
                      <>
                        <Row>
                          <Col>
                            <FormControlLabel
                              key={`Package_Configure_${index}`}
                              control={
                                <Checkbox
                                  disabled={!hasValue(value?.name) && isDisabled() || isUnassigned}
                                  checked={hasValue(value?.name)}
                                  onChange={(e) => handleChange(e, value?.name)}
                                  name={value?.name}
                                />
                              }
                              label={value?.label}
                            />
                          </Col>
                        </Row>
                      </>
                    );
                  }
                })}
              </FormGroup>
              <FormHelperText>{`Subtype config count: ${props.limit}`}</FormHelperText>
              {
                limit !== 2 ?
                  (<div className='mt-2'>
                    <FormLabel component="legend">Choose verification type:  <span className={classes.required}>*</span></FormLabel>
                    <RadioGroup aria-label="verificationType" name="verificationType" value={values?.verificationType} onChange={(e) => handleVerificationType(e.target.value)}>
                      <Grid item xl={12} md={12} lg={12} sm={12} xs={12} >
                        {
                          isCourt && courtVerificationMediumDropdown.map(ob => (
                            <FormControlLabel value={ob.value} disabled={isUnassigned} control={<Radio value={ob.value} color='primary' checked={values?.verificationType === ob.value} />} label={ob.label} />
                          ))
                        }
                        {
                          !isCourt && addressVerificationMediumDropdown.map(ob => (
                            <FormControlLabel value={ob.value} disabled={isUnassigned} control={<Radio value={ob.value} color='primary' checked={values?.verificationType === ob.value} />} label={ob.label} />
                          ))
                        }
                      </Grid>
                    </RadioGroup>
                  </div>)
                  : null
              }
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
