import axios from 'axios';
import AppConstants from '../core-components/AppConstants';
import { getToken, removeAuthToken, removeCookie } from './storage';
import history from '../utils/history';

const http = axios.create({
  baseURL: AppConstants.developmentAPIURL,
  headers: {
    Accept: 'application/json',
    'Cache-Control': 'no-cache'
  },
  proxy: {
    port: 4444
  },
  maxContentLength: 1024 * 1024 * 20
});

http.interceptors.request.use(function (config) {
  const token = getToken();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      (error && error?.response && error?.response?.status === 403) ||
      error?.response?.status === 401
    ) {
      forceLogout();
    }
    return Promise.reject(error);
  }
);

const forceLogout = () => {
  const lastRedirectPathname = localStorage.getItem('last-redirect-pathname');
  removeAuthToken();
  removeCookie('isExpirySet');
  localStorage.clear();
  localStorage.setItem('last-redirect-pathname', lastRedirectPathname);
  history.push({
    pathname: '/login',
    state: { from: history.location }
  });
};

export default http;
