import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import EmptyLayout from '../SuperAdmin/layouts/EmptyLayout';
import AuthRoute from '../MiddleWare/AuthRoute';
import ProtectedRoute from '../MiddleWare/ProtectedRoute';

import Login from '../Login';
import InvitePage from '../InvitePage';
import createCandidate from '../SuperAdmin/CreateCandidate';
import CandidateView from '../SuperAdmin/CandidateView';
import Insufficiency from '../SuperAdmin/Insufficiencies';
import RefCheckQuestions from '../SuperAdmin/RefCheckQuestions';
import CustomNotFound from '../NotFound';
import Services from '../SuperAdmin/Services';
import CreateCompany from '../SuperAdmin/CreateCompany';
import UpdateCompany from '../SuperAdmin/UpdateCompany';
import viewAddtionalSettings from '../SuperAdmin/CompanySettings/View';
import Categories from '../SuperAdmin/Categories';
import Admin from '../SuperAdmin/Admin';
import CompanyLogs from '../SuperAdmin/CompanyLogs';
import editAddtionalSettings from '../SuperAdmin/CompanySettings/Edit';
import financeConfig from '../SuperAdmin/CompanySettings/financeConfig';
import CompanyAdmin from '../SuperAdmin/CompanyAdmin';
import Settings from '../SuperAdmin/CompanySettings/Settings';
import CompanyAssignedServices from '../SuperAdmin/CompanyAssignedServices';
import OpsStatus from '../SuperAdmin/OpsStatus';
import AllUsers from '../SuperAdmin/AllUsers';
import Vendors from '../SuperAdmin/Vendors';
import PasswordChange from '../SuperAdmin/Authentication/PasswordChange';
import DefaultRefCheckQuestions from '../SuperAdmin/DefaultRefCheckQuestions';
import Feedback from '../SuperAdmin/Feedback';
import Logout from '../Logout';
import UnAuthorized from '../UnAuthorized';
import InsuffReview from '../SuperAdmin/InsuffReview';
import InsuffTicketListPage from '../SuperAdmin/InsuffTicketListPage';
import CreatePackage from '../SuperAdmin/Packages/CreatePackage';
import PrepaidCreditCompanies from '../SuperAdmin/Credits/Prepaid';
import CreditCompany from '../SuperAdmin/Credits/CreditCompany';
import AddCandidate from '../SuperAdmin/AddCandidate';
import ReviewAdditionalCharges from '../SuperAdmin/Packages/ReviewAdditionalCharges';
import InsuffFundsForChecks from '../SuperAdmin/InsuffFundsForChecks';
import OverallPackageCreditLogs from '../SuperAdmin/OverallPackageCreditLogs';
import GlobalAddonConfiguartion from '../SuperAdmin/GlobalAddonConfiguartion';
import CandidateConsent from '../SuperAdmin/ViewCandidateDetails/CandidateConsent';
import PostpaidCreditCompanies from '../SuperAdmin/Credits/Postpaid';
import AdditionalCostConfig from '../SuperAdmin/AdditionalCostConfig/';
import ThreeDots from '../../core-components/ThreeDotLoader';
import CreditBuyPackagePage from '../SuperAdmin/CompanySettings/CreditBuyPackagePage';
import GoogleRedirection from '../Login/GoogleRedirection';
import Analytics from '../SuperAdmin/Analytics';
import InsufficiencyComments from '../SuperAdmin/InsufficiencyComments/InsufficiencyComments';
const AlumniVerificationWrapper = lazy(() =>
  import('../SuperAdmin/CompanySettings/AlumniVerificationWrapper')
);
const CompanyInvoiceListing = lazy(() =>
  import('../SuperAdmin/Packages/Postpaid/Billing/CompanyInvoiceListing')
);
const CAddons = lazy(() => import('../SuperAdmin/Packages/Company/Addons'));
const Billing = lazy(() => import('../SuperAdmin/Packages/Postpaid/Billing'));
const InternalReport = lazy(() => import('../SuperAdmin/frontend-common/Reports/InternalReport'));
const CompanyPackageList = lazy(() =>
  import('../SuperAdmin/Packages/PackageList/CompanyPackageList')
);
const PostpaidUnbilled = lazy(() => import('../SuperAdmin/PostpaidUnbilled'));
const AddBulkCandidate = lazy(() => import('../SuperAdmin/AddBulkCandidate'));
const ViewCandidateDetails = lazy(() => import('../SuperAdmin/ViewCandidateDetails'));
const ViewPackageDetails = lazy(() => import('../SuperAdmin/Packages/Company/ViewPackageDetails'));
const PackageDetails = lazy(() => import('../SuperAdmin/Packages/Prepaid/PackageDetails'));
const RecentCandidates = lazy(() => import('../SuperAdmin/RecentCandidates'));
const CandidateList = lazy(() => import('../SuperAdmin/CompanyCandidateList'));
const Companies = lazy(() => import('../SuperAdmin/Companies'));
const CompanyView = lazy(() => import('../SuperAdmin/ViewCompanyDetails'));
const PackageList = lazy(() => import('../SuperAdmin/Packages/PackageList'));
const RenderLink = lazy(() => import('../../core-components/RenderLink'));
const RenderDocuments = lazy(() => import('../../core-components/RenderDocuments/RenderDocuments'));
const CandidateAnalytics = lazy(() => import('../SuperAdmin/CompanySettings/CandidateAnalytics'));

export default (props) => {
  /*  
    ### NOTE ###
    AVOID CREATING ROUTES THAT START WITH v1 OR v1/* IN CA AND SA. WE'RE KEEPING THESE RESERVED FOR A NEW FRONTEND (sv-in-frontend) REPOSITORY.
  */

  const NotFound = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <EmptyLayout>
            <CustomNotFound {...matchProps} />
          </EmptyLayout>
        )}
      />
    );
  };

  return (
    <Suspense
      fallback={
        <div style={{ textAlign: 'center', height: '100%' }}>
          <ThreeDots style={{ textAlign: 'center' }} />
        </div>
      }
    >
      <Switch>
        {/* PHP Routes */}
        <AuthRoute exact path='/admin/company/recentcandidates' component={RecentCandidates} />
        <AuthRoute exact path='/admin/company/viewcandidatelists' component={CandidateList} />
        <AuthRoute exact path='/admin/candidate/view' component={CandidateView} />
        <AuthRoute exact path='/admin/company/index' component={Companies} />
        <AuthRoute exact path='/admin/company/view' component={CompanyView} />
        <AuthRoute exact path='/admin/candidate/create' component={createCandidate} />
        <AuthRoute exact path='/admin/candidate/update' component={createCandidate} />
        <AuthRoute exact path='/admin/candidates-insufficiency/index' component={Insufficiency} />
        <AuthRoute
          exact
          path='/admin/candidates-insufficiency/tickets'
          component={InsuffTicketListPage}
        />
        <AuthRoute
          exact
          path='/admin/questions/company-config-view'
          component={RefCheckQuestions}
        />
        <AuthRoute exact path='/admin/company/create' component={CreateCompany} />
        <AuthRoute exact path='/admin/company/update' component={UpdateCompany} onlyAdmin={true} />
        <AuthRoute
          exact
          path='/admin/company-additional-settings/index'
          component={viewAddtionalSettings}
        />
        <AuthRoute exact path='/admin/admin-categories/index' component={Categories} />
        <AuthRoute exact path='/admin/admin/index' component={Admin} />
        <AuthRoute exact path='/admin/company/admins' component={CompanyAdmin} />
        <AuthRoute exact path='/admin/company/logs' component={CompanyLogs} />
        <AuthRoute
          exact
          path='/admin/company-additional-settings/edit'
          component={editAddtionalSettings}
        />
        <AuthRoute exact path='/admin/company/servicelists' component={CompanyAssignedServices} />
        <AuthRoute exact path='/admin/ops-status' component={OpsStatus} />
        <AuthRoute exact path='/admin/user/index' component={AllUsers} />
        <AuthRoute exact path='/admin/vendor' component={Vendors} />
        <AuthRoute exact path='/admin/account/changepassword' component={PasswordChange} />{' '}
        <AuthRoute
          exact
          path='/admin/questions/list-default-questions'
          component={DefaultRefCheckQuestions}
        />
        <AuthRoute exact path='/admin/service' component={Services} />
        {/* Not in use / ADO-7353 */}
        <AuthRoute exact path='/admin/candidates/insufficiency/review' component={InsuffReview} />
        <ProtectedRoute exact path='/admin/site/login' component={Login} />
        <ProtectedRoute path='/admin/invite/unauthorized' component={UnAuthorized} />
        <ProtectedRoute exact path='/admin/invite/acceptrequest' component={InvitePage} />
        <AuthRoute exact path='/admin/feedback/index' component={Feedback} />
        <Route exact path='/admin/company/reports' component={InternalReport} />
        <AuthRoute exact path='/admin/company/viewcandidate' component={ViewCandidateDetails} />
        <AuthRoute exact path='/admin/s3/consent' component={CandidateConsent} />
        {/* React Routes */}
        <ProtectedRoute exact path='/login' component={Login} />
        <Route exact path='/google-redirect' component={GoogleRedirection} />
        <ProtectedRoute exact path='/invite' component={InvitePage} />
        <AuthRoute exact path='/logout' component={Logout} />
        <AuthRoute exact path='/changePassword' component={PasswordChange} />
        {/* Candidate routes */}
        <AuthRoute exact path='/candidate/view' component={CandidateView} />
        <AuthRoute exact path='/candidate/create' component={createCandidate} />
        <AuthRoute exact path='/candidate/update' component={createCandidate} />
        <AuthRoute exact path='/candidate/details' component={ViewCandidateDetails} />
        <AuthRoute exact path='/candidate/insufficiency' component={Insufficiency} />
        <AuthRoute exact path='/candidates/insufficiency/review' component={InsuffReview} />
        <Route exact path='/candidate/internal-report' component={InternalReport} />
        <Route exact path='/candidate/report/html' component={InternalReport} />
        <AuthRoute exact path='/candidate/report' component={RenderLink} />
        <AuthRoute exact path='/candidate/view/report' component={InternalReport} />
        <AuthRoute exact path='/candidate/resume' component={RenderLink} />
        <AuthRoute exact path='/candidate/consent' component={RenderLink} />
        <AuthRoute exact path={'/verification-request'} component={RenderLink} />
        <AuthRoute exact path='/candidate/document' component={RenderDocuments} />
        <AuthRoute exact path='/invoice/document' component={RenderDocuments} />
        {/* Not in use / ADO-7353 */}
        <AuthRoute
          exact
          path='/candidates/insufficiency/tickets'
          component={InsuffTicketListPage}
        />
        <AuthRoute
          exact
          path='/candidates/insufficiency/comments'
          component={InsufficiencyComments}
        />
        <AuthRoute exact path='/candidates/insufficiency/review' component={InsuffReview} />
        {/* Company routes */}
        <AuthRoute exact path='/companies' component={Companies} />
        <AuthRoute exact path='/company/view' component={CompanyView} />
        <AuthRoute exact path='/company/candidates' component={CandidateList} />
        <AuthRoute
          exact
          path='/company/additionalSettings/view'
          component={viewAddtionalSettings}
        />
        <AuthRoute exact path='/company/logs' component={CompanyLogs} />
        <AuthRoute exact path='/company/refCheckQuestions' component={RefCheckQuestions} />
        <AuthRoute exact path='/company/assignedServices' component={CompanyAssignedServices} />
        <AuthRoute exact path='/company/create' component={CreateCompany} onlyAdmin={true} />
        <AuthRoute exact path='/company/update' component={UpdateCompany} onlyAdmin={true} />
        <AuthRoute exact path='/company/admins' component={CompanyAdmin} />
        <AuthRoute exact path='/company/financeConfig/view' component={financeConfig} />
        <AuthRoute exact path='/company/settings' component={Settings} />
        <AuthRoute exact path='/company/buy' component={CreditBuyPackagePage} />
        <AuthRoute
          exact
          path='/company/additionalSettings/edit'
          component={editAddtionalSettings}
        />
        <AuthRoute
          exact
          path='/company/additionalCostConfig/view'
          component={AdditionalCostConfig}
        />
        {/* Other routes */}
        <AuthRoute exact path='/dashboard' component={RecentCandidates} />
        <AuthRoute exact path='/opsStatus' component={OpsStatus} />
        <AuthRoute exact path='/defaultQuestions' component={DefaultRefCheckQuestions} />
        <AuthRoute exact path='/feedback' component={Feedback} />
        <AuthRoute exact path='/vendors' component={Vendors} />
        <AuthRoute exact path='/services' component={Services} />
        <AuthRoute exact path='/categories' component={Categories} />
        <AuthRoute exact path='/admin/list' component={Admin} />
        <AuthRoute exact path='/users' component={AllUsers} />
        {/* Package Routes - S-10 Packages */}
        <AuthRoute exact path='/package/company/details' component={ViewPackageDetails} />
        <AuthRoute path='/package/create' component={CreatePackage} onlyAdmin={true} />
        <AuthRoute exact path='/package/details' component={PackageDetails} />
        <AuthRoute exact path='/package/list' component={PackageList} />
        <AuthRoute exact path='/prepaid/credits/list' component={PrepaidCreditCompanies} />
        <AuthRoute exact path='/credits/balance/company' component={CreditCompany} />
        <AuthRoute exact path='/company/candidate/create' component={AddCandidate} />
        <AuthRoute exact path='/company/candidate/bulk-upload' component={AddBulkCandidate} />
        <AuthRoute exact path='/package/company/view' component={CompanyPackageList} />
        <AuthRoute exact path='/admin/additional-charges' component={ReviewAdditionalCharges} />
        <AuthRoute exact path='/insuff/checks' component={InsuffFundsForChecks} />
        <AuthRoute exact path='/package/credit/logs' component={OverallPackageCreditLogs} />
        <AuthRoute exact path='/globalAddon/configuration' component={GlobalAddonConfiguartion} />
        <AuthRoute exact path='/company/addonConfiguration' component={CAddons} />
        <AuthRoute exact path='/company/analytics' component={Analytics} />
        {/* S-11 Routes */}
        <AuthRoute exact path='/postpaid/billing' component={Billing} onlyAdmin={true} />
        <AuthRoute exact path='/postpaid/credits/list' component={PostpaidCreditCompanies} />
        <AuthRoute exact path='/postpaid/unbilled/candidates' component={PostpaidUnbilled} />
        <AuthRoute exact path='/postpaid/company/invoice/list' component={CompanyInvoiceListing} />
        {/* alumni verification routes */}
        <AuthRoute exact path='/company/alumniVerification' component={AlumniVerificationWrapper} />
        <AuthRoute exact path='/company/candidateAnalytics' component={CandidateAnalytics} />
        {/* Not found page routes */}
        <ProtectedRoute path='/unauthorized' component={UnAuthorized} />
        <Redirect exact from='/' to='/dashboard' />
        <Route path='*' component={NotFound} />
        <Route path='/notFound' component={NotFound} />
        {/*  ### NOTE ### 
              AVOID CREATING ROUTES THAT START WITH v1 OR v1/* IN CA AND SA. WE'RE KEEPING
              THESE RESERVED FOR A NEW FRONTEND (sv-in-frontend) REPOSITORY.  
        */}
      </Switch>
    </Suspense>
  );
};
