import React, { useEffect, useState } from 'react';
import { forEach, isEmpty, has, debounce } from 'lodash';
import { getTrimmedValue } from '../../../../src/utils/Utlities';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Box, Grid } from '@material-ui/core';

import Table from '../../../core-components/Table';
import BreadCrumbs from '../../../core-components/BreadCrumbs';
import Dropdown from '../../../core-components/DropdownInput';
import AppConstants from '../../../core-components/AppConstants';
import SearchInput from '../../../core-components/SearchInput';
import { CHECK_TYPE } from '../../../core-components/CommonConstants';
import { getCompanyLink, getCandidateNameAsLink } from '../../../utils/Utlities';
//this is for each row the heading creation  with whatever id for headcells
function createData(id, candidateId, candidateName, checkType, identifier, status, company, days) {
  return { id, candidateId, candidateName, checkType, identifier, status, company, days };
}

const useStyles = makeStyles({
  btnDiv: {
    display: 'flex'
  },
  infoBtn: {
    color: '#467eac',
    cursor: 'pointer'
  },
  editBtn: {
    color: '#3788ac',
    cursor: 'pointer'
  }
});

const headCells = [
  {
    id: 'id',
    label: '#',
    align: 'center'
  },
  {
    id: 'candidateId',
    label: 'Candidate Id',
    align: 'center'
  },
  {
    id: 'candidateName',
    label: 'Name',
    align: 'center'
  },
  {
    id: 'checkType',
    label: 'Check Type',
    align: 'left'
  },
  {
    id: 'identifier',
    label: 'Identifier',
    align: 'left'
  },

  {
    id: 'status',
    label: 'Status',
    align: 'left'
  },

  {
    id: 'company',
    label: 'Company',
    align: 'center'
  },
  {
    id: 'days',
    label: 'Days  In Insufficiency',
    align: 'center',
    isSort: true
  }
];

export default function EnhancedTable(props) {
  const [compId, setCompId] = useState(null);
  const [breadCrumbArray2, setBreadCrumbArray2] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [candidateName, setCandidateName] = useState('');
  const [candidateId, setCandidateId] = useState('');
  const [rows, setRows] = useState([]);
  const [sortCheck, setSortChek] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [status, setStatus] = useState(null);
  const [checkType, setCheckType] = useState(null);
  const classes = useStyles();

  const dropdownValues = [
    { label: 'All', value: 'all' },
    { label: 'Active', value: 'active' },
    { label: 'Cleared', value: 'cleared' }
  ];
  const checkTypeValues = [
    { label: 'All', value: 'all' },
    { label: 'Identity', value: 'identity' },
    { label: 'Address', value: 'address' },
    { label: 'Employment', value: 'employment' },
    { label: 'Education', value: 'education' },
    { label: 'Court', value: 'court' },
    { label: 'Drug', value: 'drugs' },
    { label: 'Reference Check', value: 'referencecheck' },
    { label: 'World Check', value: 'worldcheck' },
    { label: 'Credit Check', value: 'creditcheck' }
  ];

  const breadCrumbsArray = [{ label: 'Home', href: `${AppConstants.baseURL}dashboard` }];

  useEffect(() => {
    setLoading(true);
    setStatus('active');
    if (props?.location?.search) {
      let id = props?.location?.search.split('=');
      setCompId(id[1]);

      props.getCompany(id[1]);
      props
        .getInsufficiency(
          rowsPerPage,
          offset,
          id[1],
          'active',
          candidateId,
          candidateName,
          companyName,
          checkType,
          'asc'
        )
        .then(() => {
          setLoading(false);
        });
    } else {
      props
        .getInsufficiency(
          rowsPerPage,
          offset,
          compId,
          'active',
          candidateId,
          candidateName,
          companyName,
          checkType,
          'asc'
        )
        .then(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    // we should save company data in rows

    let rowsData =
      props?.InsufficiencyData &&
      props?.InsufficiencyData?.candidates &&
      props?.InsufficiencyData?.candidates?.length &&
      getRowData(props.InsufficiencyData.candidates);
    setRows(rowsData || []);
    setTotalCount(props.InsufficiencyData.count);
  }, [props.InsufficiencyData]);

  useEffect(() => {
    if (has(props.companyById, 'name')) {
      setBreadCrumbArray2([
        { label: 'Home', href: `${AppConstants.baseURL}dashboard` },
        { label: props.companyById.name, href: `${AppConstants.baseURL}company/view?id=${compId}` }
      ]);
    }
  }, [props.companyById]);

  //dropdown handling
  const handleDropdownSelect = (value) => {
    setLoading(true);
    if (value === 'all') {
      setStatus(null);
      setOffset(0);
      props
        .getInsufficiency(
          rowsPerPage,
          0,
          compId,
          null,
          candidateId,
          candidateName,
          companyName,
          checkType,
          sortCheck
        )
        .then(() => {
          setLoading(false);
        });
    } else {
      setStatus(value);
      setOffset(0);
      props
        .getInsufficiency(
          rowsPerPage,
          0,
          compId,
          value,
          candidateId,
          candidateName,
          companyName,
          checkType,
          sortCheck
        )
        .then(() => {
          setLoading(false);
        });
    }
  };
  const handleCheckTypeDropdownSelect = (value) => {
    setLoading(true);
    if (value === 'all') {
      setCheckType(null);
      setOffset(0);
      props
        .getInsufficiency(
          rowsPerPage,
          0,
          compId,
          status,
          candidateId,
          candidateName,
          companyName,
          null,
          sortCheck
        )
        .then(() => {
          setLoading(false);
        });
    } else {
      setCheckType(value);
      setOffset(0);
      props
        .getInsufficiency(
          rowsPerPage,
          0,
          compId,
          status,
          candidateId,
          candidateName,
          companyName,
          value,
          sortCheck
        )
        .then(() => {
          setLoading(false);
        });
    }
  };

  const getRowData = (values) => {
    let insuffCandidateRow = [];
    //here data is getting processed
    // instead of candidate name use company name , status candidate lists and the icons
    //button actions for all buttons  we neeed to have 2 button one for candidate lists And other for edit and settings button
    forEach(values, function (value, key) {
      if (!isEmpty(value)) {
        let rowData = createData(
          key + 1 + offset * rowsPerPage,
          value.candidateId,
          value?.companyId && value?.candidateId
            ? getCandidateNameAsLink(value?.name, value?.candidateId, value?.companyId)
            : value?.name, // will convert candidatename to link
          CHECK_TYPE[value.checkType] || '',
          value.identifier,
          value.status,
          value?.companyName && value?.companyId
            ? getCompanyLink(value?.companyId, value?.companyName)
            : value?.companyName,
          value.timeInDays === 1 ? value.timeInDays + '  day' : value.timeInDays + '  days'
        );
        insuffCandidateRow.push(rowData);
      }
    });
    return insuffCandidateRow;
  };

  const getCompanyFilter = () => {
    return (
      <SearchInput
        name='companyName'
        placeholder={'Search Company'}
        onBlur={handleInputBlur}
        handleSearch={debounce((companyName) => {
          applyCompanyFilter(companyName?.trim());
        }, 500)}
      />
    );
  };

  const getCandidateIdFilter = () => {
    return (
      <SearchInput
        name='candidateId'
        placeholder={'Search id'}
        onBlur={handleInputBlur}
        handleSearch={debounce((candidateId) => {
          applyCandidateIdFilter(candidateId?.trim());
        }, 500)}
        type='id'
      />
    );
  };

  const getCandidateNameFilter = () => {
    return (
      <SearchInput
        name='candidateName'
        placeholder={'search Candidate'}
        onBlur={handleInputBlur}
        handleSearch={debounce((candidateName) => {
          applyCandidateNameFilter(candidateName?.trim());
        }, 500)}
      />
    );
  };
  const getCheckTypeFilter = () => {
    return (
      <Dropdown
        dropdownValues={checkTypeValues}
        label={'check type'}
        value={'all'}
        handleSelect={(value) => {
          handleCheckTypeDropdownSelect(value);
        }}
      />
    );
  };

  const sortHandler = (val) => {
    if (val) {
      setSortChek('desc');
      setLoading(true);
      props
        .getInsufficiency(
          rowsPerPage,
          0,
          compId,
          status,
          candidateId,
          candidateName,
          companyName,
          checkType,
          'desc'
        )
        .then(() => {
          setLoading(false);
        });
    } else {
      setSortChek('asc');
      setLoading(true);
      props
        .getInsufficiency(
          rowsPerPage,
          0,
          compId,
          status,
          candidateId,
          candidateName,
          companyName,
          checkType,
          'asc'
        )
        .then(() => {
          setLoading(false);
        });
    }
  };

  const applyCompanyFilter = (name) => {
    setCompanyName(name);
    setOffset(0);
    setLoading(true);
    props
      .getInsufficiency(
        rowsPerPage,
        0,
        compId,
        status,
        candidateId,
        candidateName,
        getTrimmedValue(name),
        checkType,
        sortCheck
      )
      .then(() => {
        setLoading(false);
      });
  };

  const applyCandidateIdFilter = (id) => {
    setCandidateId(id);
    setOffset(0);
    setLoading(true);
    props
      .getInsufficiency(
        rowsPerPage,
        0,
        compId,
        status,
        getTrimmedValue(id),
        candidateName,
        companyName,
        checkType,
        sortCheck
      )
      .then(() => {
        setLoading(false);
      });
  };

  const applyCandidateNameFilter = (candName) => {
    setCandidateName(candName);
    setLoading(true);
    setOffset(0);
    props
      .getInsufficiency(
        rowsPerPage,
        0,
        compId,
        status,
        candidateId,
        getTrimmedValue(candName),
        companyName,
        checkType,
        sortCheck
      )
      .then(() => {
        setLoading(false);
      });
  };
  const handleInputBlur = (event) => {
    const { name, value } = event.target;
    const trimmedValue = getTrimmedValue(value);

    switch (name) {
      case 'companyName':
        setCompanyName(trimmedValue);
        break;
      case 'candidateId':
        setCandidateId(trimmedValue);
        break;
      case 'candidateName':
        setCandidateName(trimmedValue);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (newPage) => {
    setOffset(newPage);
    setLoading(true);
    props
      .getInsufficiency(
        rowsPerPage,
        newPage,
        compId,
        status,
        candidateId,
        candidateName,
        companyName,
        checkType,
        sortCheck
      )
      .then(() => {
        setLoading(false);
      });
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setOffset(0);
    setLoading(true);
    props
      .getInsufficiency(
        parseInt(value, 10),
        0,
        compId,
        status,
        candidateId,
        candidateName,
        companyName,
        checkType,
        sortCheck
      )
      .then(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {compId && breadCrumbArray2 ? (
        <BreadCrumbs linksArray={breadCrumbArray2} current={'Insufficiency'} />
      ) : (
        <BreadCrumbs linksArray={breadCrumbsArray} current={'Insufficiency'} />
      )}
      <Typography variant='h4' component='h3'>
        Insufficiencies
      </Typography>
      <Dropdown
        dropdownValues={dropdownValues}
        label={'Status'}
        value={'active'}
        handleSelect={(value) => {
          handleDropdownSelect(value);
        }}
      />
      <Box mt={3}>
        <Grid container spacing={2}>
          {compId ? (
            <>
              <Grid item lg={2} md={3} sm={4} xs={10}>
                {getCandidateIdFilter()}
              </Grid>
              <Grid item lg={2} md={3} sm={4} xs={10}>
                {getCandidateNameFilter()}
              </Grid>
              <Grid item lg={2} md={3} sm={4} xs={10}>
                {getCheckTypeFilter()}
              </Grid>
            </>
          ) : (
            <>
              <Grid item lg={2} md={3} sm={4} xs={10}>
                {getCandidateIdFilter()}
              </Grid>
              <Grid item lg={2} md={3} sm={4} xs={10}>
                {getCandidateNameFilter()}
              </Grid>
              <Grid item lg={2} md={3} sm={4} xs={10}>
                {getCompanyFilter()}
              </Grid>
              <Grid item lg={2} md={3} sm={4} xs={10}>
                {getCheckTypeFilter()}
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <Table
        headCells={headCells}
        rowData={rows}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={offset}
        sortHandler={sortHandler}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        loading={loading}
        // filterArr={filterArr}
      />
    </>
  );
}
