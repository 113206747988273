import React, { useEffect, useState } from 'react';
import { forEach, isEmpty, has } from 'lodash';
import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles';
import {
  Tooltip,
  Zoom,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  Select,
  IconButton
} from '@material-ui/core';
import moment from 'moment';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LinkIcon from '@material-ui/icons/Link';

import {
  deleteCompanyAdmin as deleteCompanyAdminAPI,
  updateCompanyAdmin as updateCompanyAdminAPI
} from '../../../api/admin';
import Table from '../../../core-components/Table';
import DeletePopup from '../../../core-components/ConfirmationPopup';
import Button from '../../../core-components/Button';
import BreadCrumbs from '../../../core-components/BreadCrumbs';
import AppConstants from '../../../core-components/AppConstants';
import { ADMIN_COMMUNICATION } from '../../../core-components/CommonConstants';
import { errToastMessage, getErrorMessage } from '../../../utils/Utlities';

import EmailSettings from './EmailSettings';
import CreateModal from './CreateAdmin';
import EmailBouncedIcon from '../../../images/EmailBouncedIcon.svg';
import DeleteWarningModal from './Modals/DeleteWarningModal';
import EmailPreferencesModal from './EmailPreferencesModal/EmailPreferencesModal';
import { useLocation, useHistory } from 'react-router-dom';

//this is for each row the heading creation  with whatever id for headcells
function createData(
  index,
  id,
  name,
  email,
  mobile,
  designation,
  accessLevel,
  category,
  communication,
  actions
) {
  return {
    index,
    id,
    name,
    email,
    mobile,
    designation,
    accessLevel,
    category,
    communication,
    actions
  };
}

const useStyles = makeStyles((theme) => ({
  btnDiv: {
    display: 'flex'
  },
  deleteBtn: {
    color: '#467eac',
    cursor: 'pointer'
  },
  editBtn: {
    color: '#3788ac',
    cursor: 'pointer'
  },
  linkBtn: {
    cursor: 'pointer'
  },
  heading: {
    paddingBottom: theme.spacing(2)
  },
  headingSubText: {
    color: theme.palette.text.secondary
  },
  linkedInIcon: {
    textDecoration: 'none'
  },
  emailSettingsDisabled: {
    color: '#a1a1a1'
  },
  buttonDelete: {
    padding: 0,
    border: 'none',
    background: 'transparent',
    '&:disabled': {
      cursor: 'not-allowed',
      '& svg': {
        opacity: 0.5,
        filter: 'grayscale(100%)',
        cursor: 'not-allowed'
      }
    }
  }
}));

const headCells = [
  {
    id: 'index',
    label: '#',
    align: 'left'
  },
  {
    id: 'id',
    label: 'ID',
    align: 'center'
  },
  {
    id: 'name',
    label: ' Name',
    align: 'center'
  },

  {
    id: 'email',
    label: 'Email',
    align: 'center'
  },
  {
    id: 'mobile',
    label: 'Mobile',
    align: 'center'
  },
  {
    id: 'designation',
    label: 'Designation',
    align: 'center'
  },
  {
    id: 'accessLevel',
    label: 'Acess Level',
    align: 'center'
  },
  {
    id: 'category',
    label: 'Category',
    align: 'center'
  },
  {
    id: 'actions',
    label: 'Actions',
    align: 'left'
  }
];

export default (props) => {
  const location = useLocation();
  const history = useHistory();

  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [title, setTitle] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [adminId, setAdminId] = useState(null);
  const [adminDetails, setAdminDetails] = useState(null);
  const [adminName, setAdminName] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [adminCategory, setAdminCategory] = useState([]);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [displaySettingsModal, setDisplaySettingsModal] = useState(false);
  const [isCommunicationEdit, setIsCommunicationEdit] = useState(false);
  const [isDeleteWarningModalOpen, setIsDeleteWarningModalOpen] = useState(false);
  const [openEmailPreferences, setOpenEmailPreferences] = useState(false);
  const [editEmailPrefData, setEditEmailPrefData] = useState({});
  const [userIdAndDeletionAllowed, setUserIdAndDeletionAllowed] = useState({});
  const classes = useStyles();

  const breadCrumbsArray = [
    { label: 'Home', href: `${AppConstants.baseURL}dashboard` },
    { label: 'Companies', href: `${AppConstants.baseURL}companies` },
    { label: companyName, href: `${AppConstants.baseURL}company/view?id=${companyId}` }
  ];

  //create  modal
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const handleClickOpenCreateModal = () => {
    setOpenCreateModal(true);
  };
  const handleCloseCreateModal = () => {
    const params = new URLSearchParams(location.search);
    // Remove specified parameters
    params.delete('_openAddUserModal');
    params.delete('isNewUserEdit');
    // Create new search string
    const newSearchString = params.toString();
    // Construct new URL
    const newUrl = `${location.pathname}?${newSearchString}`;
    // Update the URL
    history.replace(newUrl);
    setOpenCreateModal(false);
  };

  //delete modal
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleClickOpenDeleteModal = (isAdmin) => {
    const isAdminAndDisableDeleteOption = isAdmin && props?.adminData?.onlyAdminsCount === 1;
    isAdminAndDisableDeleteOption ? setIsDeleteWarningModalOpen(true) : setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = () => {
    deleteCompanyAdminAPI(companyId, adminId)
      .then((response) => {
        props.getCompanyAdmin(companyId, rowsPerPage, offset);
        handleCloseDeleteModal();
        setDeleteSuccess(true);
        setOpenDeleteModal(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // on mount call API
  useEffect(() => {
    setLoading(true);
    let Id = new URLSearchParams(document.location.search).get('id');
    setCompanyId(Id);
    props.getCompanyAdmin(Id, rowsPerPage, offset);

    // Open create user modal when '_openAddUserModal' param present in the URL
    const _openAddUserModal = new URLSearchParams(document.location.search).get(
      '_openAddUserModal'
    );
    setOpenCreateModal(_openAddUserModal ? true : false);
  }, []);

  useEffect(() => {
    // we should save company data in rows
    if (has(props.adminData, 'admins')) {
      setCompanyName(props.adminData.company?.name);

      let rowsData =
        props?.adminData &&
        props?.adminData?.admins &&
        props?.adminData?.admins?.length &&
        getRowData(props.adminData.admins);
      setLoading(false);
      setRows(rowsData || []);
      setTotalCount(props.adminData.count);
    }
  }, [props.adminData]);

  const getUserCategories = (categories) => {
    let userCategory = categories.map((c) => {
      return ` ${c.userCategory.categoryName} `;
    });
    return userCategory;
  };

  const getUserCategoriesID = (categories) => {
    let userCategory = categories.map((c) => {
      return c.userCategory.id.toString();
    });
    return userCategory;
  };

  const getRowData = (values) => {
    let companyRow = [];
    let userIdAndDeletionAllowed = {};
    //this is the first row
    //here data is getting processed
    forEach(values, function (value, key) {
      if (!isEmpty(value) && !isEmpty(value.user)) {
        userIdAndDeletionAllowed[value.user.id] = value.isAdminDeletionAllowed;
        let rowData = createData(
          key + 1 + offset * rowsPerPage,
          value.user.id,
          value.user.name,
          getEmail(value),
          value.user.mobile,
          value.user.designation,
          value.accessLevel === 5 ? 'Admin' : 'HR',
          isEmpty(value.user.userCategoryMappings)
            ? `(not set)`
            : getUserCategories(value.user.userCategoryMappings).join(),
          getCommunication(value),
          buttonInfoEdit(value.user.id, value.user.name, value, value.accessLevel === 5)
        );
        companyRow.push(rowData);
      }
    });

    setUserIdAndDeletionAllowed(userIdAndDeletionAllowed);
    return companyRow;
  };

  const getEmail = (value) => {
    return (
      <>
        {value.user.email}
        {value.user.emailRejectType !== 'NA' && value.user.emailRejectType !== '' ? (
          <Tooltip
            TransitionComponent={Zoom}
            title={`Email has bounced`}
            arrow={true}
            placement='top'
          >
            <IconButton>
              <EmailBouncedIcon />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </>
    );
  };

  const copyToClipBoard = (value) => {
    var dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = value;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    toast.info('Copied to Clipboard');
  };

  const buttonInfoEdit = (adminId, adminName, adminDetails, isAdmin) => {
    const minutesDiff = moment().diff(moment(adminDetails?.createdAt), 'minutes');
    return (
      <Grid container spacing={1}>
        {adminDetails.linkedInUrl ? (
          <Grid item>
            <a
              href={adminDetails.linkedInUrl || ''}
              target='_blank'
              className={classes.linkedInIcon}
            >
              <LinkedInIcon className={classes.editBtn} />
            </a>
          </Grid>
        ) : null}
        <Grid item>
          <Tooltip TransitionComponent={Zoom} title='Edit' arrow={true}>
            <EditOutlinedIcon
              className={classes.editBtn}
              onClick={() => {
                setTitle('Edit');
                setAdminId(adminId);
                setAdminName(adminName);
                isEmpty(adminDetails.user.userCategoryMappings)
                  ? setAdminCategory([])
                  : setAdminCategory(getUserCategoriesID(adminDetails.user.userCategoryMappings));
                setAdminDetails(adminDetails);
                handleClickOpenCreateModal();
              }}
            />
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip
            TransitionComponent={Zoom}
            title={
              adminDetails?.isAdminDeletionAllowed
                ? 'Delete'
                : `User deletion is not permitted because important notifications are enabled for this user.`
            }
            arrow={true}
          >
            <button
              className={classes.buttonDelete}
              disabled={!adminDetails?.isAdminDeletionAllowed}
              onClick={() => {
                setAdminId(adminId);
                setAdminName(adminName);
                setAdminDetails(adminDetails);

                setDeleteSuccess(false);
                handleClickOpenDeleteModal(isAdmin);
              }}
            >
              <DeleteOutlineIcon className={classes.deleteBtn} />
            </button>
          </Tooltip>
        </Grid>
        {getEmailSettingAction(adminDetails)}
        {adminDetails?.user?.inviteLink &&
          (minutesDiff >= 15 ? (
            <Grid item>
              <Tooltip TransitionComponent={Zoom} title='Invite Link' arrow={true}>
                <LinkIcon
                  className={classes.linkBtn}
                  onClick={() => {
                    copyToClipBoard(`${adminDetails.user.inviteLink}`);
                  }}
                />
              </Tooltip>
            </Grid>
          ) : (
            <Grid item>
              <LinkIcon style={{ color: '#a1a1a1' }} />
            </Grid>
          ))}
      </Grid>
    );
  };

  const getEmailSettingAction = (details) => {
    return (
      <Grid item>
        <Tooltip TransitionComponent={Zoom} title='Email Notification Settings' arrow={true}>
          <MailOutlineIcon
            className={classes.deleteBtn}
            onClick={() => {
              setAdminDetails(details);
              setEditEmailPrefData({
                id: details?.user?.id,
                name: details?.user?.name,
                email: details?.user?.email,
                role: details?.user?.accessLevel === 5 ? 'admin' : 'hr'
              });
              setOpenEmailPreferences(true);
            }}
          />
        </Tooltip>
      </Grid>
    );
  };
  const getCategoriesData = (data) => {
    let categories = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      categories.push(element.userCategory.id);
    }
    return categories;
  };

  const setCommunication = (details, value) => {
    setIsCommunicationEdit(true);
    let finalValues = {
      companyId: companyId,
      adminId: details.user.id,
      name: details.user.name,
      mobile: details.user.mobile,
      designation: details.user.designation,
      communication: value || 'none',
      email: details.user.email,
      userType: details.user.accessLevel === 5 ? 'admin' : 'hr',
      userCategoryIds: details.user.userCategoryMappings
        ? getCategoriesData(details.user.userCategoryMappings)
        : []
    };
    if (details.user.linkedInUrl) {
      finalValues.linkedInUrl = details.user.linkedInUrl;
    }

    updateCompanyAdminAPI(finalValues)
      .then(() => {
        setIsCommunicationEdit(false);
        props.getCompanyAdmin(companyId, rowsPerPage, offset);
        toast.info('Updated Successfully');
      })
      .catch((error) => {
        setIsCommunicationEdit(false);
        console.error(error);
        errToastMessage(error);
      });
  };
  const getCommunication = (details) => {
    return (
      <Grid item container>
        <FormControl size='small'>
          <Select
            labelId='communicationLabel'
            id='communication'
            value={details.communication || 'none'}
            onChange={(e) => {
              setCommunication(details, e.target.value);
            }}
            fullWidth={true}
            disabled={isCommunicationEdit}
          >
            {ADMIN_COMMUNICATION.map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const handleChangePage = (newPage) => {
    //need to call API with new limit and offset
    setOffset(newPage);
    setLoading(true);
    props.getCompanyAdmin(companyId, rowsPerPage, newPage).then(() => {
      setLoading(false);
    });
  };

  const handleChangeRowsPerPage = (value) => {
    //need to call API with new limit and offset
    setRowsPerPage(parseInt(value, 10));
    setLoading(true);
    setOffset(0);
    props.getCompanyAdmin(companyId, parseInt(value, 10), 0).then(() => {
      setLoading(false);
    });
  };

  return (
    <>
      <BreadCrumbs linksArray={breadCrumbsArray} current={'Admins list'} />
      <div className={classes.heading}>
        <Typography variant='h5' component='h5'>
          {'Admin List'}
        </Typography>
        <Typography className={classes.headingSubText} variant='h6' component='h6'>
          {companyName || 'Company Name'}
        </Typography>
      </div>
      <div>
        <Button
          label={'Add User'}
          color={'primary'}
          onClick={() => {
            setTitle('Create');
            setAdminId(null);
            setAdminCategory([]);
            setAdminDetails(null);
            handleClickOpenCreateModal();
          }}
        />
        <Button
          label={'Company Settings'}
          color={'primary'}
          onClick={() => {
            props.history.push({
              pathname: '/company/view',
              search: `?id=${companyId}`
            });
          }}
        />
        <Button
          label={'Candidates List'}
          color={'primary'}
          onClick={() => {
            props.history.push({
              pathname: '/company/candidates',
              search: `?id=${companyId}`
            });
          }}
        />
        <Button
          label={'Categories'}
          color={'primary'}
          onClick={() => {
            props.history.push({
              pathname: '/categories',
              search: `?company_id=${companyId}`
            });
          }}
        />
      </div>
      <Table
        headCells={headCells}
        rowData={rows}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={offset}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        loading={loading}
      />
      {openCreateModal && (
        <CreateModal
          open={openCreateModal}
          title={title}
          adminId={adminId}
          isAccessLevelChangeAllowed={
            !isEmpty(userIdAndDeletionAllowed) && title === 'Edit'
              ? userIdAndDeletionAllowed?.[adminId]
              : true
          }
          selectedCategory={adminCategory}
          companyId={companyId}
          offset={offset}
          rowsPerPage={rowsPerPage}
          details={adminDetails}
          handleClose={handleCloseCreateModal}
          activeAdminsCount={props?.adminData?.onlyAdminsCount}
          setEmailPrefModal={setOpenEmailPreferences}
          setEditEmailPrefData={setEditEmailPrefData}
          categoriesCount={props?.adminData?.categoriesCount}
        />
      )}

      {openDeleteModal && (
        <DeletePopup
          title={deleteSuccess ? 'Deleted Successfully' : 'Delete  Admin'}
          content={
            deleteSuccess
              ? 'Member has been deleted successfully'
              : `Are you sure you want to delete this admin "${adminName}"?`
          }
          buttonLabel={deleteSuccess ? 'OK' : 'Delete'}
          buttonColor={deleteSuccess ? 'primary' : 'secondary'}
          open={openDeleteModal}
          handleSubmit={() => {
            deleteSuccess ? handleCloseDeleteModal() : handleDelete();
          }}
          handleClose={handleCloseDeleteModal}
        />
      )}

      {
        <DeleteWarningModal
          open={isDeleteWarningModalOpen}
          setIsDeleteWarningModalOpen={setIsDeleteWarningModalOpen}
        />
      }
      {openEmailPreferences && (
        <EmailPreferencesModal
          open={openEmailPreferences}
          handleOnClose={() => {
            setOpenEmailPreferences(false);
            setEditEmailPrefData({});
            props.getCompanyAdmin(companyId, rowsPerPage, offset);
          }}
          companyId={companyId}
          editEmailPrefData={editEmailPrefData}
        />
      )}

      {displaySettingsModal && (
        <EmailSettings
          open={displaySettingsModal}
          title={title}
          companyId={companyId || ''}
          details={adminDetails}
          handleClose={() => {
            setDisplaySettingsModal(false);
            setAdminDetails(null);
          }}
        />
      )}
    </>
  );
};
