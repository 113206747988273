// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".APXVHCnf6mCKXr8PudiZ {\n  margin-bottom: 10px;\n}\n\n.IZ7ssSNGwOmwvw513mtT {\n  border: none;\n  padding: 0;\n  display: flex;\n  align-items: center;\n  background-color: inherit;\n}\n\n.PDc7fbUGGtxCugsuTp7j {\n  margin-right: 8px;\n}\n\n.phFSFMBTBBrUXv3OdfZk {\n  margin-right: 8px;\n  height: 10px;\n  width: 14px;\n}\n\n.jYWvGSWF5XV2nJiWDOhT {\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 21px;\n  color: #333333;\n}\n\n.fUC5mQoELBVpu21bEWFt {\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 21px;\n  color: #555555;\n}\n\n.XQya6t2cU5W404GmP8m_ {\n  display: flex;\n  align-items: center;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkListContainer": "APXVHCnf6mCKXr8PudiZ",
	"listGroupItem": "IZ7ssSNGwOmwvw513mtT",
	"checkSvg": "PDc7fbUGGtxCugsuTp7j",
	"checkItemSvg": "phFSFMBTBBrUXv3OdfZk",
	"checkTextHeading": "jYWvGSWF5XV2nJiWDOhT",
	"checkText": "fUC5mQoELBVpu21bEWFt",
	"listingText": "XQya6t2cU5W404GmP8m_"
};
export default ___CSS_LOADER_EXPORT___;
