import React, { useEffect, useState } from 'react';
import { forEach, isEmpty, has, debounce } from 'lodash';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  Tooltip,
  Zoom
} from '@material-ui/core';
import { Box, Grid } from '@material-ui/core';
import PublicIcon from '@material-ui/icons/Public';
import LockIcon from '@material-ui/icons/Lock';

import Table from '../../../core-components/Table';
import BreadCrumbs from '../../../core-components/BreadCrumbs';
import Dropdown from '../../../core-components/DropdownInput';
import AppConstants from '../../../core-components/AppConstants';
import SearchInput from '../../../core-components/SearchInput';
import { CHECK_TYPE, INSUFF_STATUSES } from '../../../core-components/CommonConstants';

//this is for each row the heading creation  with whatever id for headcells
function createData(
  id,
  candidateId,
  candidateName,
  checkType,
  identifier,
  // titleOfTicket,
  peopleInvolved,
  status,
  company,
  comments,
  days
) {
  return {
    id,
    candidateId,
    candidateName,
    checkType,
    identifier,
    // titleOfTicket,
    peopleInvolved,
    status,
    company,
    comments,
    days
  };
}

const useStyles = makeStyles({
  btnDiv: {
    display: 'flex'
  },
  infoBtn: {
    color: '#467eac',
    cursor: 'pointer'
  },
  editBtn: {
    color: '#3788ac',
    cursor: 'pointer'
  },
  linkText: {
    display: 'inline',
    width: 'fit-content',
    color: '#366CF3',
    fontSize: '14px',
    fontWeight: '500',
    cursor: 'pointer'
  }
});

const headCells = [
  {
    id: 'id',
    label: '#',
    align: 'center'
  },
  {
    id: 'candidateId',
    label: 'Candidate Id',
    align: 'center'
  },
  {
    id: 'company',
    label: 'Company',
    align: 'center'
  },
  {
    id: 'candidateName',
    label: 'Name',
    align: 'center'
  },
  {
    id: 'checkType',
    label: 'Check Type',
    align: 'center'
  },
  {
    id: 'identifier',
    label: 'Identifier',
    align: 'center'
  },
  {
    id: 'peopleInvolved',
    label: 'People Involved',
    align: 'center'
  },
  {
    id: 'status',
    label: 'Status',
    align: 'center'
  },
  {
    id: 'comments',
    label: 'Comments',
    align: 'center'
  },
  {
    id: 'days',
    label: 'Days  In Insufficiency',
    align: 'center',
    isSort: true
  }
];

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    margin: '8px 0 0 -4px',
    backgroundColor: '#333333',
    color: '#ffffff',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #333333'
  }
}))(Tooltip);

export default function EnhancedTable(props) {
  const [totalCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  //API call variables
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState('OPEN_L1');
  const [onlyPrivate, setOnlyPrivate] = useState(0);
  const [candidateName, setCandidateName] = useState(null);
  const [candidateId, setCandidateId] = useState(null);
  const [checkType, setCheckType] = useState(null);
  const [sortByInsuffTime, setSortByInsuffTime] = useState('asc');
  const [companyName, setCompanyName] = useState(null);

  const classes = useStyles();

  const checkTypeValues = [
    { label: 'All', value: 'all' },
    { label: 'Identity', value: 'identity' },
    { label: 'Address', value: 'address' },
    { label: 'Employment', value: 'employment' },
    { label: 'Education', value: 'education' },
    { label: 'Court', value: 'court' },
    { label: 'Drug', value: 'drug' },
    { label: 'Reference Check', value: 'reference' },
    { label: 'World Check', value: 'world' },
    { label: 'Credit Check', value: 'credit' }
  ];

  const breadCrumbsArray = [{ label: 'Home', href: `${AppConstants.baseURL}dashboard` }];

  const getInsuffListAPICall = (
    limit = 10,
    offset = 0,
    status = null,
    onlyPrivate = null,
    candidateName = null,
    candidateId = null,
    checkType = null,
    sortByInsuffTime = 'asc',
    companyName = null
  ) => {
    let data = {
      limit,
      offset,
      status,
      onlyPrivate,
      candidateName,
      candidateId,
      checkType,
      sortByInsuffTime,
      companyName
    };
    props.getInsuffList(data).then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    props.invalidateInsuffList();
    setLoading(true);

    getInsuffListAPICall(rowsPerPage, offset, status, onlyPrivate);
  }, []);

  useEffect(() => {
    // we should save company data in rows
    if (
      !isEmpty(props.insuffListData) &&
      has(props.insuffListData, 'loading') &&
      props?.insuffListData?.loading === false
    ) {
      let rowsData =
        props?.insuffListData &&
        props?.insuffListData?.data &&
        props?.insuffListData?.data?.length &&
        getRowData(props.insuffListData.data);
      setRows(rowsData || []);
      props.insuffListData &&
        props.insuffListData.data &&
        setTotalCount(props.insuffListData.count);
    }
  }, [props.insuffListData]);

  //dropdown handling
  const handleDropdownStatusSelect = (value) => {
    setLoading(true);
    setOffset(0);
    if (value === 'all') {
      setStatus(null);
      getInsuffListAPICall(
        rowsPerPage,
        0,
        null,
        onlyPrivate,
        candidateName,
        candidateId,
        checkType,
        sortByInsuffTime,
        companyName
      );
    } else {
      setStatus(value);
      getInsuffListAPICall(
        rowsPerPage,
        0,
        value,
        onlyPrivate,
        candidateName,
        candidateId,
        checkType,
        sortByInsuffTime,
        companyName
      );
    }
  };

  const handleCheckTypeDropdownSelect = (value) => {
    setLoading(true);
    setOffset(0);

    if (value === 'all') {
      setCheckType(null);

      getInsuffListAPICall(
        rowsPerPage,
        0,
        status,
        onlyPrivate,
        candidateName,
        candidateId,
        null,
        sortByInsuffTime,
        companyName
      );
    } else {
      setCheckType(value);

      getInsuffListAPICall(
        rowsPerPage,
        0,
        status,
        onlyPrivate,
        candidateName,
        candidateId,
        value,
        sortByInsuffTime,
        companyName
      );
    }
  };

  const getRowData = (values) => {
    let insuffCandidateRow = [];

    forEach(values, function (value, key) {
      if (!isEmpty(value)) {
        let rowData = createData(
          key + 1 + offset * rowsPerPage,
          value.candidateId,
          value.candidateName || null,
          CHECK_TYPE[value.checkType.toLowerCase()] || '',
          value.identifier || '',
          // value.title || '',
          (value.addedByUser && value.addedByUser.length && getAddedByUser(value.addedByUser)) ||
            '',
          value.status,
          value.companyName,
          value.comments && getCommentsText(value),
          value.daysInInsufficiency === 1
            ? value.daysInInsufficiency + '  day'
            : value.daysInInsufficiency + '  days'
        );
        insuffCandidateRow.push(rowData);
      }
    });
    return insuffCandidateRow;
  };

  const getAddedByUser = (addedByUser = []) => {
    let count = 0;

    const text = addedByUser.map((user, index) => {
      if (count === 2) {
        count = 0;
      }
      if (index <= 3) {
        count++;
        return (
          <>
            <span>{`${Object.values(user).join('')}`}</span>
            {count < 2 && addedByUser.length > 1 ? ', ' : <br />}
          </>
        );
      }
    });

    const getTooltip = (arr = []) => {
      const title = arr.map((user, index) => {
        return <p>{`${Object.values(user).join('')}`}</p>;
      });

      return (
        <CustomTooltip
          key={'subtypes'}
          TransitionComponent={Zoom}
          title={title}
          arrow={true}
          placement={'left-start'}
        >
          <div className={classes.linkText}>{`+${arr.length} others`}</div>
        </CustomTooltip>
      );
    };

    if (addedByUser.length > 4) {
      text.push(getTooltip(addedByUser.splice(4, addedByUser.length - 4)));
    }

    return text;
  };

  const getCommentsText = ({ comments }) => {
    return (
      <>
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <PublicIcon style={{ fontSize: '20px' }} />
          &nbsp;
          <span component={'span'} style={{ fontSize: '14px' }}>{`(${comments.publicCount})`}</span>
          &nbsp;
          <LockIcon style={{ fontSize: '20px' }} />
          &nbsp;
          <span
            component={'span'}
            style={{ fontSize: '14px' }}
          >{`(${comments.privateCount})`}</span>
          &nbsp;
        </Box>
      </>
    );
  };

  const getCompanyFilter = () => {
    return (
      <SearchInput
        placeholder={'Search Company'}
        handleSearch={debounce((companyName) => {
          applyCompanyFilter(companyName?.trim());
        }, 500)}
      />
    );
  };

  const getCandidateIdFilter = () => {
    return (
      <SearchInput
        placeholder={'Search id'}
        handleSearch={debounce((candidateId) => {
          applyCandidateIdFilter(candidateId?.trim());
        }, 500)}
        type='id'
      />
    );
  };

  const getCandidateNameFilter = () => {
    return (
      <SearchInput
        placeholder={'search Candidate'}
        handleSearch={debounce((candidateName) => {
          applyCandidateNameFilter(candidateName?.trim());
        }, 500)}
      />
    );
  };
  const getCheckTypeFilter = () => {
    return (
      <Dropdown
        dropdownValues={checkTypeValues}
        label={'check type'}
        value={'all'}
        handleSelect={(value) => {
          handleCheckTypeDropdownSelect(value);
        }}
      />
    );
  };

  const sortHandler = (val) => {
    if (val) {
      setSortByInsuffTime('desc');
      setLoading(true);
      getInsuffListAPICall(
        rowsPerPage,
        0,
        status,
        onlyPrivate,
        candidateName,
        candidateId,
        checkType,
        'desc',
        companyName
      );
    } else {
      setSortByInsuffTime('asc');
      setLoading(true);
      getInsuffListAPICall(
        rowsPerPage,
        0,
        status,
        onlyPrivate,
        candidateName,
        candidateId,
        checkType,
        'asc',
        companyName
      );
    }

    //trtue desc and false asc
  };

  const applyCompanyFilter = (name) => {
    setCompanyName(name || null);
    setOffset(0);
    setLoading(true);
    getInsuffListAPICall(
      rowsPerPage,
      0,
      status,
      onlyPrivate,
      candidateName,
      candidateId,
      checkType,
      sortByInsuffTime,
      name || null
    );
  };

  const applyCandidateIdFilter = (id) => {
    setCandidateId(id || null);
    setOffset(0);
    setLoading(true);
    getInsuffListAPICall(
      rowsPerPage,
      0,
      status,
      onlyPrivate,
      candidateName,
      id || null,
      checkType,
      sortByInsuffTime,
      companyName
    );
  };

  const applyCandidateNameFilter = (candName) => {
    setCandidateName(candName || null);
    setLoading(true);
    setOffset(0);
    getInsuffListAPICall(
      rowsPerPage,
      0,
      status,
      onlyPrivate,
      candName || null,
      candidateId,
      checkType,
      sortByInsuffTime,
      companyName
    );
  };

  const handleChangePage = (newPage) => {
    //need to call API with new limit and offset
    setOffset(newPage);
    setLoading(true);
    getInsuffListAPICall(
      rowsPerPage,
      newPage,
      status,
      onlyPrivate,
      candidateName,
      candidateId,
      checkType,
      sortByInsuffTime,
      companyName
    );
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setOffset(0);
    setLoading(true);
    getInsuffListAPICall(
      parseInt(value, 10),
      0,
      status,
      onlyPrivate,
      candidateName,
      candidateId,
      checkType,
      sortByInsuffTime,
      companyName
    );
  };

  return (
    <>
      <BreadCrumbs linksArray={breadCrumbsArray} current={'Insuff Ticket List'} />
      <Typography variant='h4' component='h3'>
        Insuff Ticket List
      </Typography>
      <Dropdown
        dropdownValues={INSUFF_STATUSES}
        label={'Status'}
        value={'OPEN_L1'}
        handleSelect={(value) => {
          handleDropdownStatusSelect(value);
        }}
      />
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item lg={2} md={3} sm={4} xs={10}>
            {getCandidateIdFilter()}
          </Grid>
          <Grid item lg={2} md={3} sm={4} xs={10}>
            {getCandidateNameFilter()}
          </Grid>
          <Grid item lg={2} md={3} sm={4} xs={10}>
            {getCompanyFilter()}
          </Grid>
          <Grid item lg={2} md={3} sm={4} xs={10}>
            {getCheckTypeFilter()}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <FormControl component='fieldset' className={classes.formControl}>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    onChange={(e) => {
                      setOnlyPrivate(e.target.checked ? 1 : 0);
                      getInsuffListAPICall(
                        rowsPerPage,
                        0,
                        status,
                        e.target.checked ? 1 : 0,
                        candidateName,
                        candidateId,
                        checkType,
                        sortByInsuffTime,
                        companyName
                      );
                    }}
                    checked={onlyPrivate ? true : false}
                    name={'onlyPrivate'}
                    value={onlyPrivate ? true : false}
                  />
                }
                label={'Only Private Comment'}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Table
        headCells={headCells}
        rowData={rows}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={offset}
        sortHandler={sortHandler}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        loading={loading}
        // filterArr={filterArr}
      />
    </>
  );
}
