const TOKEN_STRING = 'token';
const TYPE = 'type';
const ACCESS_LEVEL = 'accessLevel';

export default {
  setToken: (token, type) => {
    localStorage.setItem(TYPE, type);
    localStorage.setItem(TOKEN_STRING, token);
  },
  getToken: () => localStorage.getItem(TOKEN_STRING),
  getType: () => localStorage.getItem(TYPE),
  deleteToken: () => localStorage.removeItem(TOKEN_STRING),
  clearStorage: () => localStorage.clear(),
  setAccessLevel: (accessLevel) => {
    localStorage.setItem(ACCESS_LEVEL, accessLevel);
  },
  getAccessLevel: () => localStorage.getItem(ACCESS_LEVEL),
};
