import * as React from 'react';
// import { styled } from '@material-ui/styles';
import styled from '@emotion/styled';
import Switch from '@material-ui/core/Switch';

const IOSSwitch = styled(
  (props) => <Switch focusVisibleClassName='.Mui-focusVisible' {...props} />,
  {
    shouldForwardProp: (prop) => prop !== 'color'
  }
)(({ theme, color }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme?.palette?.mode === 'dark' ? '#2ECA45' : color,
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme?.palette?.mode === 'light' ? theme?.palette?.grey[100] : theme?.palette?.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme?.palette?.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme?.palette?.mode === 'light' ? '#E9E9EA' : '#39393D',
    // opacity: 1
    transition: theme?.transitions?.create(['background-color'], {
      duration: 500
    })
  }
}));

export default function CustomizedSwitch(props) {
  return (
    <IOSSwitch
      sx={{ m: 1 }}
      checked={props?.checked}
      onChange={props?.handleChange}
      color={props?.color}
      disabled={props?.disabled}
    />
  );
}

CustomizedSwitch.defaultProps = {
  checked: false,
  color: '#65C466',
  disabled: false
};
