import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  getInsuffTicketList as getInsuffTicketListAPI,
  invalidateInsuffList as invalidateInsuffListAPI
} from '../../../actions/admin';

const mapStateToProps = (state) => {
  return {
    insuffListData: state.insuffListData || {}
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    invalidateInsuffList: () => dispatch(invalidateInsuffListAPI()),
    getInsuffList: (data) => dispatch(getInsuffTicketListAPI(data))
  };
};
export default compose(connect(mapStateToProps, mapDispatchToProps));
