import { connect } from 'react-redux';
import { compose } from 'redux';

import { getCompanyLogs as getCompanyLogsAPI, invalidateCompanyLogs } from '../../../actions/admin';

const mapStateToProps = (state) => {
  return {
    logs: state.companyLogs
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyLogs: (companyUuid, limit, offset, tableName) =>
      dispatch(getCompanyLogsAPI(companyUuid, limit, offset, tableName)),
    invalidateCompanyLogs: () => dispatch(invalidateCompanyLogs())
  };
};
export default compose(connect(mapStateToProps, mapDispatchToProps));
