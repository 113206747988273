import React, { useState, useEffect } from 'react';
import { forEach, isEmpty } from 'lodash';
import moment from 'moment';

import { Typography } from '@material-ui/core';

import Table from '../../../../../core-components/Table';
import ModalWrapper from '../../../../../core-components/ModalWrapper';
import { displayDateFormat } from '../../../../../core-components/CommonConstants';
import { getPostpaidUnbilledCandidates } from '../../../../../api/admin';
import { toCamelCase } from '../../../../../utils/Utlities';
import AppConstants from '../../../../../core-components/AppConstants';

function createData(date, candidate, packageName) {
  return { date, candidate, packageName };
}

const headCells = [
  {
    id: 'date',
    label: 'Date',
    align: 'center'
  },
  {
    id: 'candidate',
    label: 'Candidate',
    align: 'center'
  },
  {
    id: 'packageName',
    label: 'Package name',
    align: 'center'
  }
];
export default function UnbilledCandidates(props) {
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);

  const { open, handleClose } = props;

  useEffect(() => {
    getCandidates(rowsPerPage, offset);
  }, []);

  const setCandidates = (candidates) => {
    if (candidates) {
      let rows = candidates.rows && candidates.rows.length ? getRowData(candidates.rows) : [];
      setData(rows);
      setCount(candidates.count || 0);
    }
    setLoading(false);
  };

  const getCandidates = (rowsPerPage, offset) => {
    setLoading(true);
    getPostpaidUnbilledCandidates(props.companyId || 0, rowsPerPage, offset, false)
      .then((response) => {
        setCandidates(toCamelCase(response.data));
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  const getCandidateLink = (candidate) => {
    return (
      <Typography variant={'subtitle2'}>
        <a
          href={`${AppConstants.baseURL}candidate/details?id=${candidate.id}&company_id=${props.companyId}`}
          target='_blank'
        >
          {candidate?.name}
        </a>
      </Typography>
    );
  };

  const getRowData = (values) => {
    let logsRow = [];
    forEach(values, function (value) {
      if (!isEmpty(value)) {
        let rowData = createData(
          value.initiatedDate && moment(value.initiatedDate).isValid()
            ? moment(value.initiatedDate).format(displayDateFormat)
            : null,
          getCandidateLink(value.candidate),
          value.candidate.packageName
        );
        logsRow.push(rowData);
      }
    });
    return logsRow;
  };

  const handleChangePage = (newPage) => {
    setOffset(newPage);
    getCandidates(rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (value) => {
    setOffset(0);
    setRowsPerPage(parseInt(value, 10));
    getCandidates(parseInt(value, 10), 0);
  };

  return (
    <ModalWrapper open={open} onClose={handleClose} title={'Unbilled Candidates'}>
      <Table
        headCells={headCells}
        rowData={data}
        totalCount={count}
        rowsPerPage={rowsPerPage}
        page={offset}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        loading={loading}
      />
    </ModalWrapper>
  );
}
