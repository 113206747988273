import * as Yup from 'yup';

const ID_ERROR = 'Please enter valid Check Id';
const CANDIDATE_ID_ERROR = 'Please enter valid Canidate Id';
export default Yup.object().shape({
  checkType: Yup.string().required('Check type is required field'),
  eventType: Yup.string().required('Event type is required field'),
  checkId: Yup.number()
    .required('Please enter check Id')
    .integer()
    .positive(ID_ERROR)
    .typeError(ID_ERROR),
  candidateId: Yup.number()
    .required('Candidate ID is required field')
    .integer()
    .positive(CANDIDATE_ID_ERROR)
    .typeError(CANDIDATE_ID_ERROR),
  amount: Yup.number()
    .required('Amount is required field')
    .integer()
    .positive('Amount must be positive value')
    .max(5000000, 'Amount should be less than 50,00000')
    .typeError('Please enter valid Amout'),
  additionalInfo: Yup.string().required('Addition Info is required field')
});
