import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useFormDirty } from '../utils/Utlities';
import { useEffect } from 'react';
import { useState } from 'react';
import { isEmpty } from 'lodash';

export default (props) => {
  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        maxWidth={props.maxWidth || 'sm'}
        fullWidth={true}
      >
        <DialogTitle id='create-edit-dialog-title'>{props.title}</DialogTitle>
        <DialogContent>{props.children}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={props.handleClose} variant={'outlined'}>
            Cancel
          </Button>
          <Button
            // type='submit'
            onClick={props.handleSubmit}
            color='primary'
            disabled={!props.enableBtn}
            variant={'contained'}
          >
            {props.edit ? 'Update' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
