import React, { useState, useEffect } from 'react';

import {
  Box,
  Grid,
  Typography,
  InputLabel,
  FormHelperText,
  Button,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import { isInt } from '../../../utils/Utlities';

import FormInput from '../../../core-components/FormInput';
import Dropdown from '../../../core-components/DropdownInput';
import ModalWrapper from '../../../core-components/ModalWrapper';

const useStyles = makeStyles((theme) => ({
  label: {
    margin: '10px 0 5px',
    color: '#333333'
  },
  dropdownError: {
    color: '#f44336',
    margin: '4px 14px 0'
  },
  activeBtn: {
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  }
}));

const TAGS = [
  { label: 'Discount', value: 'DISCOUNT' },
  { label: 'Free credits', value: 'FREE_CREDITS' }
];

export default (props) => {
  const classes = useStyles();
  const [error, setError] = useState('');

  const { values, setFieldValue, errors, handleSubmit, open, handleClose, handleModalSubmit } =
    props;

  const [creditLimit, setCreditLimit] = useState('');
  const [isDirty, setIsDirty] = useState(true);

  useEffect(() => {
    setFieldValue('postpaidUnlimitedCredit', values.unlimitedCredit);
    if (values.unlimitedCredit) {
      setFieldValue('postpaidCreditLimit', null);
    }
    if (!isInt(values.creditLimit) && values.creditLimit !== null && !values.unlimitedCredit) {
      setError('Amount must contains only Integer Value');
    } else if (values.creditLimit < 0 && !values.unlimitedCredit) {
      setError('Amount must be positive number');
    } else {
      setFieldValue('postpaidCreditLimit', values.creditLimit);
      setError('');
    }

    return () => {
      setFieldValue('postpaidCreditLimit', creditLimit);
      setCreditLimit('');
      setIsDirty(true);
    };
  }, []);

  useEffect(() => {
    // Check if creditLimit or values.postpaidCreditLimit are empty
    const isEmpty =
      creditLimit === '' ||
      values.postpaidCreditLimit === undefined ||
      values.postpaidCreditLimit === null;

    // If creditLimit or values.postpaidCreditLimit are empty, set creditLimit to values.postpaidCreditLimit
    if (isEmpty) {
      setCreditLimit(values.postpaidCreditLimit);
    }

    // Set isDirty to true if creditLimit and values.postpaidCreditLimit are equal, false otherwise
    setIsDirty(
      creditLimit === values.postpaidCreditLimit &&
        values.postpaidUnlimitedCredit === values.unlimitedCredit
    );
  }, [values?.postpaidCreditLimit, values.postpaidUnlimitedCredit, creditLimit]);

  return (
    <>
      <ModalWrapper
        open={open}
        onClose={handleClose}
        title={'Update Credit Limit'}
        modalSize={'sm'}
      >
        <form onSubmit={handleModalSubmit}>
          <Typography>Current Balance: {props.balance}</Typography>
          <Box>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputLabel className={classes.label}>Set new Credit Limit :</InputLabel>
              <Field
                fullWidth={true}
                type='number'
                value={values.postpaidCreditLimit}
                name='postpaidCreditLimit'
                component={FormInput}
                variant='outlined'
                disabled={values.postpaidUnlimitedCredit}
              />
              <FormHelperText id='creditLimit' error>
                {error}
              </FormHelperText>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.postpaidUnlimitedCredit}
                    onChange={(e) => {
                      setFieldValue('postpaidUnlimitedCredit', e.target.checked);
                      if (e.target.checked) {
                        setError('');
                        setFieldValue('postpaidCreditLimit', null);
                      }
                    }}
                    name='postpaidUnlimitedCredit'
                    color='primary'
                  />
                }
                label='Unlimited Credit'
              />
            </Grid>
          </Box>
          <Button
            variant='contained'
            type='submit'
            disabled={
              isDirty ||
              error ||
              (!values.postpaidUnlimitedCredit && values.postpaidCreditLimit <= 0)
                ? true
                : false
            }
            className={classes.activeBtn}
          >
            Save
          </Button>
        </form>
      </ModalWrapper>
    </>
  );
};
