import React, { useState } from 'react';

import {
  Button,
  withStyles,
  Typography,
  IconButton,
  Box,
  makeStyles,
  TextField,
  FormControl
} from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { getTrimmedValue } from '../utils/Utlities';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import { CUSTOM_CHECK_RULES } from '../utils/ValidationSchema';
import { CUSTOM_CHECK_NAMES } from './CommonConstants';
import FloatingLabelSelect from './FloatingLabelSelect/FloatingLabelSelect';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles((theme) => ({
  activeBtn: {
    margin: theme.spacing(1),
    height: '44px',
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  disabledBtn: {
    cursor: 'not-allowed',
    margin: theme.spacing(1),
    height: '44px',
    color: '#dfdfdf',
    borderColor: '#d9d9d9',
    backgroundColor: '#f5f5f5',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  cancelButton: {
    height: '44px',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2
  },
  formControl: {
    marginBottom: 24
  },
  relativeContainer: {
    position: 'relative'
  },
  floatLabel: {
    position: 'absolute',
    background: '#fff',
    padding: 5,
    left: '10px',
    top: '0px'
  }
}));

export default (props) => {
  const classes = useStyles();
  const [error, setError] = useState('');
  const [checkType, setCheckType] = useState('');
  const [checkName, setCheckName] = useState('');

  const handleClose = () => {
    props.handleClose();
  };
  const handleSubmit = () => {
    props.handleSubmit({ checkName, checkType });
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={handleClose}
        maxWidth={props.maxWidth || 'sm'}
        fullWidth={true}
      >
        <DialogTitle id='create-edit-dialog-title' onClose={handleClose}>
          <Typography variant='h5' gutterBottom>
            {props.title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} variant='outlined' className={classes.formControl}>
            <FloatingLabelSelect
              placeholder='Custom check name'
              menuPosition={'fixed'}
              textFieldProps={{
                label: 'Label',
                InputLabelProps: {
                  shrink: true
                }
              }}
              styles={{
                control: (base) => ({ ...base, height: 56, minHeight: 56 }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 })
              }}
              value={CUSTOM_CHECK_NAMES.find((c) => c.value === checkType)}
              onChange={(data) => {
                const { value, label } = data || {};
                setCheckType(value || '');
                setCheckName(value !== 'OTHER' ? label || '' : '');
              }}
              isClearable
              options={CUSTOM_CHECK_NAMES}
            />
          </FormControl>

          {checkType === 'OTHER' && (
            <TextField
              fullWidth
              id='outlined-customCheck'
              label='Enter custom check name'
              variant='outlined'
              value={checkName}
              onChange={(e) => {
                setCheckName(e.target.value);
                const val = getTrimmedValue(e.target.value, true) || '';
                const result = val
                  ? CUSTOM_CHECK_RULES.test(val) && val[0] !== '.' && val[val.length - 1] !== '.'
                  : true;

                let errorMessage = '';

                if (!result) {
                  errorMessage = 'Please enter valid check name';
                } else if (val?.length > 160) {
                  errorMessage = 'Max character limit (160) reached';
                }

                setError(errorMessage);
              }}
              onBlur={(e) => {
                const trimmedValue = getTrimmedValue(e.target.value, true);
                setCheckName(trimmedValue);
              }}
              error={error || null}
              helperText={error || null}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Box mb={2}>
            <Button
              data-testid='cancel-btn'
              onClick={handleClose}
              className={classes.cancelButton}
              variant={'outlined'}
            >
              Cancel
            </Button>
            <Button
              data-testid='update-btn'
              onClick={handleSubmit}
              color='primary'
              className={checkName && checkType ? classes.activeBtn : classes.disabledBtn}
              disabled={!checkName || !checkType || error}
              variant={'contained'}
              startIcon={<SaveIcon />}
            >
              {props.edit ? 'Update' : 'Add'}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
