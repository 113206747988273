import React, { useState } from 'react';

import { isEmpty } from 'lodash';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Badge,
  Box
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import Alert from '@material-ui/lab/Alert';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import AddIcon from '@material-ui/icons/Add';

import {
  ADDON_CONFIG_MAPPING,
  CHECKS_LIMIT,
  EDUCATION_TYPES_ADDONS,
  PLAIN_CHECK_ICONS
} from '../../../../../core-components/CommonConstants';
import Identity from './Verfications/Identity';
import Address from './Verfications/Address';
import EduEmp from './Verfications/EduEmp';
import Education from './Verfications/Education';
import AllEducationTypes from './Verfications/AllEducationTypes';
import BooleanField from './Verfications/BooleanField';
import { getTotalCheckCount } from '../../CustomizePackage/helper';
import { convertToIndianNumeration } from '../../../../../utils/Utlities';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    fontFamily: 'Poppins'
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  headingGrid: {
    backgroundColor: '#f5f4f4',
    padding: '20px 18px',
    marginBottom: '18px',
    cursor: 'pointer',
    borderRadius: '2px'
  },
  priceText: {
    fontFamily: 'roboto',
    color: '#333333',
    fontSize: '14px'
  },
  costContainer: {
    display: 'flex'
  },
  rupeeSymbol: {
    fontFamily: 'Roboto'
  }
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#389E0D',
    padding: '0px 0px',
    height: '16px',
    minWidth: '16px'
  }
}))(Badge);

export default (props) => {
  const classes = useStyles(),
    { values, setFieldValue, addCandidateConfig, pDetails, packageType, isAadhaarAllowed } = props;
  const initEduAddOnPrice =
    addCandidateConfig.addons.education?.price ||
    addCandidateConfig.addons.educationThirdParty?.price ||
    addCandidateConfig.addons.educationHybrid?.price;
  const [showAddons, setShowAddons] = useState(false);
  const [drugSelected, setDrugSelected] = useState(0);
  const [eduAdcPrice, setEduAdcPrice] = useState(initEduAddOnPrice);

  const IDCount = isAadhaarAllowed ? CHECKS_LIMIT.ID : CHECKS_LIMIT.ID - 1;
  const changeEduAdcPrice = (type) => {
    const addOns = addCandidateConfig.addons;
    const eduTypeName = EDUCATION_TYPES_ADDONS[type];
    const { price } = addOns[eduTypeName];
    setEduAdcPrice(price);
  };

  const getHeadingText = ({ check, heading, price }) => {
    let priceText = '';

    const getCostComponent = (price) => {
      return (
        <span className={classes.costContainer}>
          <span className={classes.rupeeSymbol}>&#8377;</span>
          <span>{convertToIndianNumeration(price)} per check</span>
        </span>
      );
    };

    switch (check) {
      case 'address':
        if (pDetails?.config?.address?.length === 0) {
          if (sortAddressPrice().length === 1) {
            priceText = getCostComponent(sortAddressPrice()[0]);
          } else {
            if (values.address?.verification_type) {
              priceText = getCostComponent(
                addCandidateConfig.addons[ADDON_CONFIG_MAPPING[values.address?.verification_type]]
                  ?.price
              );
            } else {
              priceText = `Starts from ₹${addressAddonPrice}`;
            }
          }
        } else if (pDetails?.config?.address?.length === 1) {
          priceText = getCostComponent(
            addCandidateConfig.addons[
              ADDON_CONFIG_MAPPING[pDetails?.config?.address[0]?.verificationType]
            ]?.price
          );
        }
        break;
      case 'court':
        if (pDetails?.config?.court?.length === 0) {
          if (sortCourtPrice().length === 1) {
            priceText = getCostComponent(sortCourtPrice()[0]);
          } else {
            if (values.court?.verification_type) {
              priceText = getCostComponent(
                addCandidateConfig.addons[ADDON_CONFIG_MAPPING[values.court?.verification_type]]
                  ?.price
              );
            } else {
              priceText = `Starts from ₹${courtAddonPrice}`;
            }
          }
        } else if (pDetails?.config?.court?.length === 1) {
          priceText = getCostComponent(
            addCandidateConfig.addons[
              ADDON_CONFIG_MAPPING[pDetails?.config?.court[0]?.verificationType]
            ]?.price
          );
        }
        break;
      case 'education':
        if (pDetails?.config?.education === 0 || pDetails?.config?.education?.length === 0) {
          if (sortEducationPrice().length === 1) {
            priceText = getCostComponent(sortEducationPrice()[0]);
          } else {
            if (values?.education?.method) {
              priceText = getCostComponent(
                addCandidateConfig.addons[ADDON_CONFIG_MAPPING[values.education?.method]]?.price
              );
            } else {
              priceText = `Starts from ₹${educationAddonPrice}`;
            }
          }
        } else {
          priceText = getCostComponent(
            addCandidateConfig.addons[ADDON_CONFIG_MAPPING[pDetails?.config?.educationType]]?.price
          );
        }
        break;
      default:
        priceText = getCostComponent(price);
        break;
    }
    return (
      <Grid container>
        <Grid item lg={1} md={1} sm={1} xs={1}>
          <StyledBadge color='secondary' badgeContent={<AddIcon style={{ fontSize: '10px' }} />}>
            <span title={check}>
              <FontAwesomeIcon
                icon={PLAIN_CHECK_ICONS[check] ? PLAIN_CHECK_ICONS[check].icon : faBan}
                color='rgba(39, 85, 254, 0.5)'
                size='lg'
              />
            </span>
          </StyledBadge>
        </Grid>
        <Grid item lg={8} md={8} sm={8} xs={8}>
          <Typography variant='subtitle2' gutterBottom>
            {heading}
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Typography
            variant='body2'
            className={classes.priceText}
            color='textSecondary'
            gutterBottom
          >
            {priceText}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const handleConfigChange = (type, name, value) => {
    let valuesCopy = { ...values };
    if (name) {
      valuesCopy[type][name] = value;
    } else {
      valuesCopy[type] = value;
    }
    setFieldValue(valuesCopy);
  };

  const handleMultipleConfigChange = (type, value) => {
    const valuesCopy = { ...values };

    for (let i = 0; i < type.length; i++) {
      valuesCopy[type[i]] = value[type[i]];
    }

    setFieldValue(valuesCopy);
  };

  if (addCandidateConfig && addCandidateConfig.addons && isEmpty(addCandidateConfig.addons)) {
    return null;
  }

  const handleMultipleConfigValueChange = (type, name, value) => {
    const valuesCopy = { ...values };
    for (let i = 0; i < name.length; i++) {
      valuesCopy[type][name[i]] = value[i];
    }
    setFieldValue(valuesCopy);
  };

  const enableDrugAddOn = (value) => {
    if (drugSelected !== value && drugSelected !== 0) {
      return false;
    }
    return true;
  };
  const isAddonExist = (type) => {
    switch (type) {
      case 'ADDRESS':
        return (
          addCandidateConfig?.addons.hasOwnProperty('addressDigital') ||
          addCandidateConfig?.addons.hasOwnProperty('addressPhysical') ||
          addCandidateConfig?.addons.hasOwnProperty('addressPostal')
        );
      case 'COURT':
        return (
          addCandidateConfig?.addons.hasOwnProperty('courtStandard') ||
          addCandidateConfig?.addons.hasOwnProperty('courtStandardLawfirm')
        );
      default:
        break;
    }
  };
  const showAddressAddon = () => {
    if (
      addCandidateConfig.addons &&
      isAddonExist('ADDRESS') &&
      pDetails?.config?.address?.length !== 2
    ) {
      if (
        pDetails?.config?.address?.length === 1 &&
        addCandidateConfig?.addons.hasOwnProperty(
          ADDON_CONFIG_MAPPING[pDetails?.config?.address[0].verificationType]
        )
      ) {
        return true;
      } else if (pDetails?.config?.address?.length === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const showCourtAddon = () => {
    if (
      addCandidateConfig.addons &&
      isAddonExist('COURT') &&
      pDetails?.config?.court?.length !== 2
    ) {
      if (
        pDetails?.config?.court?.length === 1 &&
        addCandidateConfig?.addons.hasOwnProperty(
          ADDON_CONFIG_MAPPING[pDetails?.config?.court[0].verificationType]
        )
      ) {
        return true;
      } else if (pDetails?.config?.court?.length === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const sortEducationPrice = () => {
    const educationAddonObject = {
      education: addCandidateConfig?.addons?.education
        ? addCandidateConfig?.addons?.education.price
        : undefined,
      educationThirdParty: addCandidateConfig?.addons?.educationThirdParty
        ? addCandidateConfig?.addons?.educationThirdParty.price
        : undefined,
      educationHybrid: addCandidateConfig?.addons?.educationHybrid
        ? addCandidateConfig?.addons?.educationHybrid.price
        : undefined
    };
    return Object.values(educationAddonObject)
      .sort()
      .filter((v) => v);
  };
  const sortAddressPrice = () => {
    const addressAddonObject = {
      addressDigital: addCandidateConfig?.addons?.addressDigital
        ? addCandidateConfig?.addons?.addressDigital.price
        : undefined,
      addressPostal: addCandidateConfig?.addons?.addressPostal
        ? addCandidateConfig?.addons?.addressPostal.price
        : undefined,
      addressPhysical: addCandidateConfig?.addons?.addressPhysical
        ? addCandidateConfig?.addons?.addressPhysical.price
        : undefined
    };
    return Object.values(addressAddonObject)
      .sort()
      .filter((v) => v);
  };
  const sortCourtPrice = () => {
    const courtAddonObject = {
      courtStandard: addCandidateConfig?.addons?.courtStandard
        ? addCandidateConfig?.addons?.courtStandard.price
        : undefined,
      courtStandardLawfirm: addCandidateConfig?.addons?.courtStandardLawfirm
        ? addCandidateConfig?.addons?.courtStandardLawfirm.price
        : undefined
    };
    return Object.values(courtAddonObject)
      .sort()
      .filter((v) => v);
  };
  const addressAddonPrice = sortAddressPrice().length > 0 ? sortAddressPrice()[0] : '';
  const courtAddonPrice = sortCourtPrice().length > 0 ? sortCourtPrice()[0] : '';
  const educationAddonPrice = sortEducationPrice().length > 0 ? sortEducationPrice()[0] : '';

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid
          item
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.headingGrid}
          onClick={() => {
            setShowAddons(!showAddons);
          }}
        >
          <Grid item lg={11} md={11} sm={11} xs={11}>
            <Typography variant='subtitle2'>Add-ons</Typography>
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
            style={{ display: 'flex', flexDirection: 'row-reverse' }}
          >
            {showAddons ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Grid>
        </Grid>
        {showAddons ? (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box mb={1}>
              {packageType === 'PREPAID' ? (
                <Alert severity='info'>{`The add-ons will be charged using the credit balance of ₹${
                  addCandidateConfig.credits &&
                  addCandidateConfig.credits.paymentsCompanyCreditBalance
                    ? addCandidateConfig.credits.paymentsCompanyCreditBalance.creditBalance
                    : null
                }`}</Alert>
              ) : null}
            </Box>

            {/* Identity Addon */}
            {addCandidateConfig.addons && addCandidateConfig.addons.identity ? (
              <Accordion disabled={pDetails?.config?.identity?.includes('ALL')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel2a-content'
                  id='panel2a-header'
                >
                  {getHeadingText({
                    check: 'identity',
                    heading: 'Identity',
                    price: addCandidateConfig.addons.identity.price || ''
                  })}
                </AccordionSummary>
                <AccordionDetails>
                  <Identity
                    cPkgDetails={pDetails.config.identity}
                    sPkgDetails={null}
                    aPkgDetails={null}
                    limit={IDCount - getTotalCheckCount(pDetails.config.identity)}
                    values={values.identity}
                    onValueChange={(name, value) => {
                      handleConfigChange('identity', name, value);
                    }}
                    isAadhaarAllowed={isAadhaarAllowed}
                  />
                </AccordionDetails>
              </Accordion>
            ) : null}
            {showAddressAddon() ? (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel2a-content'
                  id='panel2a-header'
                >
                  {getHeadingText({
                    check: 'address',
                    heading: 'Address',
                    price: addressAddonPrice
                  })}
                </AccordionSummary>
                <AccordionDetails>
                  <Address
                    isCourt={false}
                    cPkgDetails={pDetails.config.address}
                    sPkgDetails={null}
                    aPkgDetails={null}
                    limit={CHECKS_LIMIT.ADD - pDetails.config.address.length}
                    values={values.address}
                    disableAny={pDetails?.config?.address?.includes('ANY_1')}
                    onValueChange={(name, value) => {
                      handleConfigChange('address', name, value);
                    }}
                    addonsConfig={addCandidateConfig?.addons}
                  />
                </AccordionDetails>
              </Accordion>
            ) : null}
            {/* Court Addon */}
            {showCourtAddon() ? (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel2a-content'
                  id='panel2a-header'
                >
                  {getHeadingText({
                    check: 'court',
                    heading: 'Court record',
                    price: courtAddonPrice
                  })}
                </AccordionSummary>
                <AccordionDetails>
                  <Address
                    isCourt={true}
                    cPkgDetails={pDetails.config.court}
                    sPkgDetails={null}
                    aPkgDetails={null}
                    limit={CHECKS_LIMIT.COURT - pDetails.config.court.length}
                    values={values.court}
                    disableAny={pDetails?.config?.court?.includes('ANY_1')}
                    onValueChange={(name, value) => {
                      handleConfigChange('court', name, value);
                    }}
                    addonsConfig={addCandidateConfig?.addons}
                  />
                </AccordionDetails>
              </Accordion>
            ) : null}
            {/* Employment Addon */}
            {addCandidateConfig.addons.employment && addCandidateConfig.addons.employment ? (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel2a-content'
                  id='panel2a-header'
                >
                  {getHeadingText({
                    check: 'employment',
                    heading: 'Employment (last)',
                    price: addCandidateConfig.addons.employment.price || ''
                  })}
                </AccordionSummary>
                <AccordionDetails>
                  <EduEmp
                    cPkgDetails={null}
                    sPkgDetails={null}
                    aPkgDetails={null}
                    limit={CHECKS_LIMIT.EMP - pDetails.config.employment}
                    type={'emp'}
                    values={values.employment}
                    onValueChange={(value) => {
                      if (addCandidateConfig.addons.reference) {
                        let arr = [...values.reference.config];

                        if (
                          value &&
                          values?.reference?.config &&
                          values?.reference?.config?.length
                        ) {
                          if (value < values?.reference?.config?.length) {
                            arr.pop();
                          } else if (value > values?.reference?.config?.length) {
                            arr.push(0);
                          }
                        } else {
                          /**
                           changes done =  assign empty array to arr variable , this might change in future as currently reference addons is disabled
                           previous line of code was creating bug that , inspite of reference addons disabled 
                            it was being send in payload and ref check was created
                           *  */
                          arr = [];
                        }

                        handleMultipleConfigChange(['employment', 'reference'], {
                          employment: value,
                          reference: { count: 0, config: arr }
                        });
                      } else {
                        handleConfigChange('employment', null, value);
                      }
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            ) : null}

            {/* Education Addon */}
            {pDetails &&
            addCandidateConfig &&
            addCandidateConfig.addons &&
            addCandidateConfig.addons.educationThirdParty &&
            pDetails.config.education &&
            pDetails.config.educationType === 'REGIONAL_PARTNER' ? (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel2a-content'
                  id='panel2a-header'
                >
                  {getHeadingText({
                    check: 'education',
                    heading: `Education ${
                      pDetails.config.education && Array.isArray(pDetails.config.education)
                        ? ''
                        : '(highest)'
                    }`,
                    price: educationAddonPrice || ''
                  })}
                </AccordionSummary>
                <AccordionDetails>
                  {pDetails.config.education && Array.isArray(pDetails.config.education) ? (
                    <Education
                      cPkgDetails={pDetails.config.education}
                      sPkgDetails={null}
                      aPkgDetails={null}
                      values={values.educationRegionalPartner}
                      onValueChange={(name, value) => {
                        handleConfigChange('educationRegionalPartner', name, value);
                      }}
                      educationType={pDetails.config.educationType}
                    />
                  ) : (
                    <EduEmp
                      cPkgDetails={null}
                      sPkgDetails={null}
                      aPkgDetails={null}
                      limit={4}
                      type={'edu'}
                      values={values.educationRegionalPartner.count}
                      onValueChange={(value) => {
                        handleConfigChange('educationRegionalPartner', 'count', value);
                      }}
                      educationType={pDetails.config.educationType}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            ) : null}

            {/* Education Addon */}
            {pDetails &&
            addCandidateConfig &&
            addCandidateConfig.addons &&
            addCandidateConfig.addons.educationHybrid &&
            pDetails.config.education &&
            pDetails.config.educationType === 'HYBRID' ? (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel2a-content'
                  id='panel2a-header'
                >
                  {getHeadingText({
                    check: 'education',
                    heading: `Education ${
                      pDetails.config.education && Array.isArray(pDetails.config.education)
                        ? ''
                        : '(highest)'
                    }`,
                    price: educationAddonPrice || ''
                  })}
                </AccordionSummary>
                <AccordionDetails>
                  {pDetails.config.education && Array.isArray(pDetails.config.education) ? (
                    <Education
                      cPkgDetails={pDetails.config.education}
                      sPkgDetails={null}
                      aPkgDetails={null}
                      values={values.educationHybrid}
                      onValueChange={(name, value) => {
                        handleConfigChange('educationHybrid', name, value);
                      }}
                      educationType={pDetails.config.educationType}
                    />
                  ) : (
                    <EduEmp
                      cPkgDetails={null}
                      sPkgDetails={null}
                      aPkgDetails={null}
                      limit={4}
                      type={'edu'}
                      values={values.educationHybrid.count}
                      onValueChange={(value) => {
                        handleConfigChange('educationHybrid', 'count', value);
                      }}
                      educationType={pDetails.config.educationType}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            ) : null}

            {/* Education Addon */}
            {pDetails &&
            addCandidateConfig &&
            addCandidateConfig.addons &&
            addCandidateConfig.addons.education &&
            pDetails.config.education &&
            pDetails.config.educationType === 'OFFICIAL' ? (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel2a-content'
                  id='panel2a-header'
                >
                  {getHeadingText({
                    check: 'education',
                    heading: `Education  ${
                      pDetails.config.education && Array.isArray(pDetails.config.education)
                        ? ''
                        : '(highest)'
                    }`,
                    price: educationAddonPrice || ''
                  })}
                </AccordionSummary>
                <AccordionDetails>
                  {pDetails.config.education && Array.isArray(pDetails.config.education) ? (
                    <Education
                      cPkgDetails={pDetails.config.education}
                      sPkgDetails={null}
                      aPkgDetails={null}
                      values={values.educationOfficial}
                      onValueChange={(name, value) => {
                        handleConfigChange('educationOfficial', name, value);
                      }}
                      educationType={pDetails.config.educationType}
                    />
                  ) : (
                    <EduEmp
                      cPkgDetails={null}
                      sPkgDetails={null}
                      aPkgDetails={null}
                      limit={4}
                      type={'edu'}
                      values={values.educationOfficial.count}
                      onValueChange={(value) => {
                        handleConfigChange('educationOfficial', 'count', value);
                      }}
                      educationType={pDetails.config.educationType}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            ) : null}
            {/* Education Addon */}
            {pDetails &&
            addCandidateConfig &&
            addCandidateConfig.addons &&
            (addCandidateConfig.addons.education ||
              addCandidateConfig.addons.educationThirdParty ||
              addCandidateConfig.addons.educationHybrid) &&
            (!pDetails.config.education ||
              (!pDetails.config.education && isEmpty(pDetails.config.education)) ||
              pDetails.config.educationType === null) ? (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel2a-content'
                  id='panel2a-header'
                >
                  {getHeadingText({
                    check: 'education',
                    heading: 'Education',
                    price: educationAddonPrice || ''
                  })}
                </AccordionSummary>
                <AccordionDetails>
                  <AllEducationTypes
                    cPkgDetails={{
                      orderType: pDetails.config.educationOrderType,
                      educationType: pDetails.config.educationType
                    }}
                    sPkgDetails={null}
                    aPkgDetails={null}
                    values={values.education}
                    addonDetails={addCandidateConfig.addons || null}
                    eduPriceToggler={changeEduAdcPrice}
                    onValueChange={(name, value) => {
                      handleMultipleConfigValueChange('education', name, value);
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            ) : null}

            {/* World Addon */}
            {addCandidateConfig.addons.world && !pDetails.config.world ? (
              <BooleanField
                cPkgDetails={null}
                sPkgDetails={null}
                aPkgDetails={null}
                value={values.world}
                price={addCandidateConfig.addons.world.price}
                type={'world'}
                heading={`World Check`}
                onValueChange={(value) => {
                  handleConfigChange('world', null, value);
                }}
              />
            ) : null}

            {/* Drug Addon */}
            {pDetails &&
            addCandidateConfig.addons.drug5Panel &&
            enableDrugAddOn(5) &&
            pDetails.config.drug === null ? (
              <BooleanField
                cPkgDetails={null}
                sPkgDetails={null}
                aPkgDetails={null}
                value={values.drug5Panel}
                price={addCandidateConfig.addons.drug5Panel.price}
                type={'drug'}
                heading={`Drug (5 Panel)`}
                onValueChange={(value) => {
                  drugSelected === 5 ? setDrugSelected(0) : setDrugSelected(5);
                  handleConfigChange('drug5Panel', null, value);
                }}
              />
            ) : null}
            {/* Drug Addon */}
            {pDetails &&
            addCandidateConfig.addons.drug7Panel &&
            enableDrugAddOn(7) &&
            pDetails.config.drug === null ? (
              <BooleanField
                cPkgDetails={null}
                sPkgDetails={null}
                aPkgDetails={null}
                value={values.drug7Panel}
                price={addCandidateConfig.addons.drug7Panel.price}
                type={'drug'}
                heading={`Drug (7 Panel)`}
                onValueChange={(value) => {
                  drugSelected === 7 ? setDrugSelected(0) : setDrugSelected(7);
                  handleConfigChange('drug7Panel', null, value);
                }}
              />
            ) : null}

            {/* Drug Addon */}
            {pDetails &&
            addCandidateConfig.addons.drug10Panel &&
            enableDrugAddOn(10) &&
            pDetails.config.drug === null ? (
              <BooleanField
                cPkgDetails={null}
                sPkgDetails={null}
                aPkgDetails={null}
                value={values.drug10Panel}
                price={addCandidateConfig.addons.drug10Panel.price}
                type={'drug'}
                heading={`Drug (10 Panel)`}
                onValueChange={(value) => {
                  drugSelected === 10 ? setDrugSelected(0) : setDrugSelected(10);
                  handleConfigChange('drug10Panel', null, value);
                }}
              />
            ) : null}

            {/* Cibil Addon */}
            {addCandidateConfig.addons.cibil && !pDetails.config.credit ? (
              <BooleanField
                cPkgDetails={null}
                sPkgDetails={null}
                aPkgDetails={null}
                value={values.cibil}
                price={addCandidateConfig.addons.cibil.price}
                type={'credit'}
                heading={`Credit Check`}
                onValueChange={(value) => {
                  handleConfigChange('cibil', null, value);
                }}
              />
            ) : null}
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};
