import React, { useEffect, useRef, useState } from 'react';
import { isEmpty, xorBy } from 'lodash';
import { Field } from 'formik';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  Button,
  Box,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  TextField,
  CircularProgress
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Accordion, Form } from 'react-bootstrap';
import './BasicDetails.scss';

import FormInput from '../../../../core-components/FormInput';
import FileInput from '../../../../core-components/FileInput';
import CustomValidatedSelect from '../../../../core-components/CustomValidatedSelect';
import { candidateExistanceCheck as existanceAPI } from '../../../../api/admin';
import {
  validResumeTypes,
  validConsentTypes,
  isValidPhoneNumber,
  getTrimmedValue,
  validDocTypes,
  validateCategoriesAndTags
} from '../../../../utils/Utlities';
import countryNames from '../../../../utils/countryNamesEn.json';
import countryOptions from '../../../../utils/countryCodesEn.json';
import { UPLOAD_FILE_TYPES } from '../../../../core-components/CommonConstants';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    display: 'flex'
  },
  paper: {
    padding: theme.spacing(4),
    margin: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  formControl: {
    marginBottom: theme.spacing(3)
  },
  activeBtn: {
    margin: theme.spacing(1),
    height: '44px',
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  disabledBtn: {
    cursor: 'not-allowed',
    margin: theme.spacing(1),
    height: '44px',
    color: '#dfdfdf',
    borderColor: '#d9d9d9',
    backgroundColor: '#f5f5f5',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  errorMsg: {
    fontSize: '0.75rem',
    color: '#f44336',
    fontWeight: '400',
    lineHeight: '1.66',
    margin: 0,
    marginTop: theme.spacing(-4),
    marginBottom: theme.spacing(3)
  },
  required: {
    color: '#db3131'
  },
  buttonGrid: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  chip: {
    margin: 2
  },
  marginB: {
    marginBottom: '12px'
  },
  error: {
    fontSize: '14px',
    color: '#f44336',
    margin: 0,
    marginTop: theme.spacing(-2)
  },
  accordionContainer: {
    marginBottom: '24px',
    marginTop: '24px'
  },
  cardToggle: {
    width: '100%',
    textAlign: 'left',
    padding: '1rem 1.25rem',
    fontWeight: '500',
    lineHeight: '1.2',
    border: '1px solid #dee2e6 !important',
    borderRadius: '0.375rem',
    backgroundColor: '#f8f9fa !important'
  },
  cardBody: {
    padding: 0
  },
  accordionHeader: {
    backgroundColor: '#F8F9FA'
  },
  inviteMailHeading: {
    fontWeight: '600',
    fontSize: '14px',
    color: '#646C9A',
    fontFamily: 'Poppins'
  },
  accordionTextarea: {
    resize: 'none',
    position: 'relative',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#4c4c4c'
  },
  textareaCharacterLimit: {
    fontWeight: '500',
    fontSize: '10px',
    textAlign: 'end',
    color: '#c5c5c5'
  },
  typographyStyles: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: '4px'
  },
  phoneErrorMsg: {
    fontSize: '12px',
    color: '#f44336',
    marginBottom: '1rem'
  }
}));

export default (props) => {
  const ctErrorRef = useRef(null);
  const classes = useStyles();
  const theme = useTheme();
  const [isUploading, setIsUploading] = useState(null);
  const [isConsentUploading, setIsConsentUploading] = useState(null);
  const [isCategoriesLoading, setCategoriesLoading] = useState(false);
  const [isTagsLoading, setTagsLoading] = useState(false);
  const [deletedTags, setDeletedTags] = useState([]);
  const [deletedCategoryId, setDeletedCategoryId] = useState(null);
  const [deletedCategoryError, setDeletedCategoryError] = useState(null);
  const [deletedTagsError, setDeletedTagsError] = useState(null);
  const [categoriesList, setCategoriesList] = useState([]);
  const [tagsList, setTagsList] = useState([]);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250
      }
    }
  };
  function getStyles(name, tag, theme) {
    return {
      fontWeight:
        tag.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium
    };
  }

  const {
    handleSubmit,
    isSubmitting,
    setSubmitting,
    values,
    errors,
    setFieldValue,
    getAddCandidateConfig,
    companyId,
    addCandidateConfig,
    ctError,
    setCtError
  } = props;
  const charCount = values?.hrInviteNote?.trim()?.length;

  useEffect(() => {
    setCategoriesList(addCandidateConfig?.categories || []);
    setTagsList(addCandidateConfig?.tags || []);
  }, [addCandidateConfig]);

  useEffect(() => {
    if (!isEmpty(ctError)) {
      if (ctError?.tagsError) {
        setDeletedTags(ctError?.deletedTags || []);
        setDeletedTagsError(ctError.tagsError);
      }
      if (ctError?.categoryError) {
        setDeletedCategoryId(ctError.deletedCategoryId);
        setDeletedCategoryError(ctError.categoryError);
      }
      if (ctErrorRef.current) {
        ctErrorRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      setTimeout(() => {
        setCtError({});
      }, 500);
    }
  }, [ctError]);

  useEffect(() => {
    if (values.email) {
      setFieldValue('isConsentCanBeAdded', true);
      setFieldValue('consent', '');
    } else {
      setFieldValue('isConsentCanBeAdded', false);
    }
  }, [values.email]);

  useEffect(() => {
    var fileName = values.resumeName;
    if (fileName !== '' && validResumeTypes(fileName)) {
      setFieldValue('validResumeFormat', true);
    } else {
      setFieldValue('validResumeFormat', false);
    }
  }, [values.resumeName]);

  const handleResumeValidation = (isValid) => {
    setFieldValue('validResumeFormat', isValid);
  };
  const handleConsentValidation = (isValid) => {
    setFieldValue('validConsentFormat', isValid);
  };
  useEffect(() => {
    var fileName = values.consentName;
    if (fileName !== '' && validConsentTypes(fileName)) {
      setFieldValue('validConsentFormat', true);
    } else {
      setFieldValue('validConsentFormat', false);
    }
  }, [values.consentName]);

  const setValue = (fieldname, value, withSpace) => {
    const trimmedVal = getTrimmedValue(value, withSpace);
    setFieldValue(fieldname, trimmedVal);
    return trimmedVal;
  };
  let isValidForm =
    !isSubmitting &&
    isEmpty(errors) &&
    !deletedCategoryError &&
    !deletedTagsError &&
    values.isValidPhone &&
    (values?.isAltValidPhone ? true : values?.isAltValidPhone) &&
    ((values.email && values.isConsentCanBeAdded) ||
      values.consentLink ||
      values?.isConsentUndertakingLetter === 2) &&
    !isUploading &&
    !isConsentUploading &&
    (!values?.formConfig?.isResumeOptional ? values?.validResumeFormat : true) &&
    (values?.resumeLink ? values?.validResumeFormat : true) &&
    (values?.hrInviteNote?.trim() ? (charCount >= 10 && charCount <= 160 ? true : false) : true);

  if (values.consentName !== '') {
    isValidForm &&= values.validConsentFormat;
  }

  const getConfigData = async () => {
    try {
      const config = await getAddCandidateConfig(companyId);
      return config;
    } catch (err) {
      console.error('err: ', err);
    }
  };

  const handleContinue = async () => {
    const selectedCategoryId = values?.category?.id;
    const selectedTags = values?.tags;
    if (!selectedCategoryId && (!selectedTags || selectedTags.length === 0)) {
      handleSubmit();
      return;
    }
    try {
      setSubmitting(true);
      const config = await getAddCandidateConfig(companyId);
      const categoriesList = config?.categories;
      const tags = config?.tags;
      const res = validateCategoriesAndTags(values?.category, values.tags, categoriesList, tags);
      if (isEmpty(res)) {
        handleSubmit();
        return;
      }
      if (res?.tagsError) {
        setDeletedTags(res?.deletedTags || []);
        setDeletedTagsError(res.tagsError);
      }
      if (res?.categoryError) {
        setDeletedCategoryId(res.deletedCategoryId);
        setDeletedCategoryError(res.categoryError);
      }
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
      console.error('handleContinue Error: ', err);
    }
  };

  const validateCategoryOnChange = (value) => {
    if (!value || (value && value !== deletedCategoryId)) {
      setDeletedCategoryError(null);
    }
  };

  const validateTagsOnChange = (value) => {
    if (!value || deletedTags.length <= 0) {
      setDeletedTagsError(null);
      return;
    }
    if (value && deletedTags.length > 0) {
      const selectedIds = value.map((t) => t.id);
      const res = deletedTags.filter((dt) => selectedIds.some((st) => dt.id === st));
      if (res.length <= 0) setDeletedTagsError(null);
    }
  };

  const onOpenCategoryMenu = async () => {
    setCategoriesLoading(true);
    await getConfigData();
    setCategoriesLoading(false);
  };

  const onOpenTagsMenu = async () => {
    setTagsLoading(true);
    await getConfigData();
    setTagsLoading(false);
    const st = values?.tags;
    setFieldValue('tags', st);
  };

  return (
    <>
      <Grid className={classes.root} container direction={'row'}>
        <Grid data-testid='candidate-details' item xl={4} md={4} lg={4} sm={false} xs={false}>
          <Typography variant='h4' gutterBottom style={{ padding: '20px' }} color='textSecondary'>
            Add Candidate details
          </Typography>
        </Grid>
        <Grid item xl={8} md={8} lg={8} sm={12} xs={12}>
          <form data-testid='form-field' onSubmit={handleSubmit}>
            <Field
              type='text'
              name='name'
              requiredfield={1}
              label='Candidate Name (as per ID document)'
              inputProps={{ 'data-testid': 'name-field' }}
              component={FormInput}
              onBlur={(e) => {
                props.handleBlur(e);
                setValue('name', values.name, true);
              }}
            />
            <Field
              type='text'
              name='email'
              label='Email'
              requiredfield={props.addCandidateConfig?.isEmailOptional ? 0 : 1}
              onBlur={(e) => {
                props.handleBlur(e);
                setFieldValue('emailExists', false);
                setValue('email', values.email, false);
                if (values.email && !errors.email && !values.isEdit) {
                  let request = {
                    companyId: values.companyId,
                    params: {
                      email: values.email
                    }
                  };
                  existanceAPI(request)
                    .then((response) => {
                      if (response.data.isExist) {
                        setFieldValue('emailExists', true);
                      } else {
                        setFieldValue('emailExists', false);
                      }
                    })
                    .catch((error) => {
                      console.error(error);
                      setFieldValue('emailExists', false);
                    });
                }
              }}
              component={FormInput}
              // Force lowercase for email
              inputProps={{ 'data-testid': 'email-field' }}
            />
            <Field
              type='text'
              name='alternateEmail'
              label='Alt. Email'
              inputProps={{ 'data-testid': 'alt-email-field' }}
              component={FormInput}
              onBlur={(e) => {
                props.handleBlur(e);
                setValue('alternateEmail', values.alternateEmail, false);
              }}
            />
            <Typography component='legend' className={classes.typographyStyles}>
              Phone Number <span className={classes.required}>*</span>
            </Typography>
            <Grid container spacing={2} className={classes.marginB}>
              <Grid item xl={2} md={2} lg={2} sm={2} xs={12}>
                <Field
                  type='text'
                  name='countryCode'
                  defaultValue={values.countryCode}
                  options={countryNames}
                  filterLabel={countryOptions}
                  component={CustomValidatedSelect}
                  onChange={(e) => {
                    setFieldValue('isValidPhone', true);
                    if (values.phone && e) {
                      let intlPhoneNumber = `${e + '' + values.phone}`;
                      if (!isValidPhoneNumber(intlPhoneNumber)) {
                        setFieldValue('isValidPhone', false);
                      } else {
                        setFieldValue('isValidPhone', true);
                      }
                    }
                    if (values.phone && e) {
                      let intlPhoneNumber = `${e + '' + values.phone}`;
                      let request = {
                        companyId: values.companyId,
                        params: {
                          phone: intlPhoneNumber
                        }
                      };
                      existanceAPI(request)
                        .then((response) => {
                          if (response.data.isExist) {
                            setFieldValue('phoneExists', true);
                          } else {
                            setFieldValue('phoneExists', false);
                          }
                        })
                        .catch((error) => {
                          console.error(error);
                          setFieldValue('phoneExists', false);
                        });
                    }
                  }}
                />
              </Grid>
              <Grid item xl={10} md={10} lg={10} sm={10} xs={12}>
                <Field
                  type='text'
                  name='phone'
                  inputProps={{ 'data-testid': 'phone-field' }}
                  variant='outlined'
                  component={FormInput}
                  fullWidth
                  onBlur={(e) => {
                    props.handleBlur(e);
                    const trimmedVal = setValue('phone', values.phone, false);
                    setFieldValue('phoneExists', false);
                    setFieldValue('isValidPhone', true);
                    if (trimmedVal && values.countryCode) {
                      let intlPhoneNumber = `${values.countryCode + '' + trimmedVal}`;
                      if (!isValidPhoneNumber(intlPhoneNumber)) {
                        setFieldValue('isValidPhone', false);
                      } else {
                        setFieldValue('isValidPhone', true);
                      }
                    }
                    if (trimmedVal && values.countryCode && !values.isEdit) {
                      let intlPhoneNumber = `${values.countryCode + '' + trimmedVal}`;
                      let request = {
                        companyId: values.companyId,
                        params: {
                          phone: intlPhoneNumber
                        }
                      };
                      existanceAPI(request)
                        .then((response) => {
                          if (response.data.isExist) {
                            setFieldValue('phoneExists', true);
                          } else {
                            setFieldValue('phoneExists', false);
                          }
                        })
                        .catch((error) => {
                          console.error(error);
                          setFieldValue('phoneExists', false);
                        });
                    }
                  }}
                />
                {!errors.phone && !values.isValidPhone && (
                  <div className={classes.phoneErrorMsg}>
                    {!values.isValidPhone ? 'Please add a valid Phone Number' : ''}
                  </div>
                )}
              </Grid>
            </Grid>
            <Typography component='legend' className={classes.typographyStyles}>
              Alt. Phone Number
            </Typography>
            <Grid container spacing={2}>
              <Grid item xl={2} md={2} lg={2} sm={2} xs={12}>
                <Field
                  type='text'
                  name='altCountryCode'
                  defaultValue={values.altCountryCode}
                  options={countryNames}
                  filterLabel={countryOptions}
                  component={CustomValidatedSelect}
                  onChange={(e) => {
                    setFieldValue('isAltValidPhone', true);
                    if (values.altPhone && e) {
                      let intlPhoneNumber = `${e + '' + values.altPhone}`;
                      if (!isValidPhoneNumber(intlPhoneNumber)) {
                        setFieldValue('isAltValidPhone', false);
                      } else {
                        setFieldValue('isAltValidPhone', true);
                      }
                    }
                  }}
                />
              </Grid>
              <Grid item xl={10} md={10} lg={10} sm={10} xs={12}>
                <Field
                  type='text'
                  name='altPhone'
                  inputProps={{ 'data-testid': 'alt-phone-field' }}
                  component={FormInput}
                  variant='outlined'
                  fullWidth
                  onBlur={(e) => {
                    props.handleBlur(e);
                    const trimmedVal = setValue('altPhone', values.altPhone, false);
                    setFieldValue('isAltValidPhone', true);
                    if (trimmedVal && values.altCountryCode) {
                      let intlPhoneNumber = `${values.altCountryCode + '' + trimmedVal}`;
                      if (!isValidPhoneNumber(intlPhoneNumber)) {
                        setFieldValue('isAltValidPhone', false);
                      } else {
                        setFieldValue('isAltValidPhone', true);
                      }
                    }
                  }}
                />
                {!values.isAltValidPhone && (
                  <div className={classes.phoneErrorMsg}>
                    {!values.isAltValidPhone ? 'Please add a valid Phone Number' : ''}
                  </div>
                )}
              </Grid>
            </Grid>

            <Field
              type='text'
              name='employeeId'
              label='Employee ID'
              component={FormInput}
              inputProps={{ 'data-testid': 'emp-id' }}
              onBlur={(e) => {
                props.handleBlur(e);
                setValue('employeeId', values.employeeId, true);
                setFieldValue('employeeIdExists', false);
                if (values.employeeId && !errors.employeeId && !values.isEdit) {
                  let request = {
                    companyId: values.companyId,
                    params: {
                      employeeId: values.employeeId
                    }
                  };
                  existanceAPI(request)
                    .then((response) => {
                      if (response.data.isExist) {
                        setFieldValue('employeeIdExists', true);
                      } else {
                        setFieldValue('employeeIdExists', false);
                      }
                    })
                    .catch((error) => {
                      console.error(error);
                      setFieldValue('employeeIdExists', false);
                    });
                }
              }}
            />
            <Field
              type='text'
              name='uanNumber'
              inputProps={{ 'data-testid': 'uan-no-field' }}
              label='Universal Account Number'
              component={FormInput}
              onBlur={(e) => {
                props.handleBlur(e);
                setValue('uanNumber', values.uanNumber, false);
              }}
            />

            <FormControl fullWidth className={classes.formControl} innerRef={ctErrorRef}>
              <Autocomplete
                options={
                  isCategoriesLoading
                    ? [{ id: '_disabled', categoryName: 'Loading...' }]
                    : categoriesList
                }
                getOptionLabel={(option) => option.categoryName || ''}
                value={values.category}
                getOptionDisabled={(option) => option.id === '_disabled'}
                loading={isCategoriesLoading}
                noOptionsText='No Categories'
                onOpen={onOpenCategoryMenu}
                onChange={(event, newValue) => {
                  setFieldValue('category', newValue);
                  validateCategoryOnChange(newValue?.id);
                }}
                renderInput={(params) => <TextField {...params} label='Category' />}
              />
              {deletedCategoryError && (
                <div
                  className={classes.phoneErrorMsg}
                  dangerouslySetInnerHTML={{ __html: deletedCategoryError }}
                ></div>
              )}
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
              <Autocomplete
                options={isTagsLoading ? [{ id: '_disabled', category: 'Loading...' }] : tagsList}
                multiple
                getOptionLabel={(option) => option.category || ''}
                value={values.tags}
                loading={isTagsLoading}
                disableCloseOnSelect
                onOpen={onOpenTagsMenu}
                noOptionsText='No Tags'
                getOptionDisabled={(option) => option.id === '_disabled'}
                onChange={(event, newValue) => {
                  setFieldValue('tags', newValue);
                  validateTagsOnChange(newValue);
                }}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => <TextField {...params} label='Tags' />}
              />
              {deletedTagsError && (
                <div
                  className={classes.phoneErrorMsg}
                  dangerouslySetInnerHTML={{ __html: deletedTagsError }}
                ></div>
              )}
            </FormControl>

            <FormControl
              data-testid='resume-field'
              component='fieldset'
              fullWidth
              className={classes.formControl}
            >
              <FormLabel component='legend' className={classes.marginB}>
                Upload Resume{' '}
                {values.formConfig ? (
                  !values.formConfig.isResumeOptional ? (
                    <span className={classes.required}>*</span>
                  ) : null
                ) : null}
              </FormLabel>
              <Field
                name='resume'
                component={FileInput}
                accept={validDocTypes.resume}
                candidateId={props.companyId}
                setIsUploading={setIsUploading}
                uploadToServer={'s3Upload'}
                fileName={values.resumeName || ''}
                nameField={'resumeName'}
                linkFieldName={'resumeLink'}
                defaultField={false}
                setValidation={handleResumeValidation}
                uploadFileType={UPLOAD_FILE_TYPES.RESUME}
              />
              {!values.validResumeFormat && values.resumeName !== '' && (
                <div className={classes.error}>
                  Please provide a valid resume format ({validDocTypes.resume})
                </div>
              )}
            </FormControl>

            {/* Consent */}
            <FormControl component='fieldset' fullWidth className={classes.formControl}>
              <FormLabel component='legend'>
                Consent<span className={classes.required}>*</span>
              </FormLabel>
              {props?.addCandidateConfig?.consentType === 'UNDERTAKING_LETTER' && (
                <RadioGroup
                  row
                  aria-label='isConsentUndertakingLetter'
                  name='isConsentUndertakingLetter'
                  value={values.isConsentUndertakingLetter}
                  onChange={(e) => {
                    setFieldValue('isConsentUndertakingLetter', parseInt(e.target.value));
                  }}
                >
                  <FormControlLabel
                    value={2}
                    control={<Radio color='primary' />}
                    style={{ color: '#7166F6', textDecoration: 'underline' }}
                    label={`Consent Undertaking Letter by ${values.companyName}`}
                  />
                  <div>
                    Through this undertaking, the client (you) hereby acknowledges and agrees that
                    it is solely responsible for collecting consent and has collected the same from
                    the candidate before proceeding/initiating the background check. By proceeding
                    further you also acknowledge that
                    <br />
                    <br />
                    a) SpringVerify will not collect consent from the candidate or the client (as
                    outlined in the consent undertaking letter)
                    <br />
                    b) The candidate is aware of the verification activity to be performed on them
                    <br />
                    c) The candidate has provided their written consent to the client for the same
                  </div>
                  <FormControlLabel
                    value={1}
                    control={<Radio color='primary' />}
                    label='Ignore the Consent Undertaking Letter'
                  />
                </RadioGroup>
              )}
            </FormControl>
            {values.isConsentUndertakingLetter === 1 ? (
              <Box mb={5} mt={2} style={{ marginTop: '-20px' }}>
                <Grid container spacing={2}>
                  <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!values.email}
                          checked={values.isConsentCanBeAdded}
                          onChange={(e) => {
                            setFieldValue('isConsentCanBeAdded', e.target.checked);
                          }}
                          name='consentCheck'
                          color='primary'
                        />
                      }
                      label={
                        <>
                          <Typography variant='subtitle1'>Ask candidate for consent</Typography>
                          {!values.email ? (
                            <Typography variant='caption' display='block'>
                              (You cannot ask candidate for consent as you have not added an email
                              address)
                            </Typography>
                          ) : null}
                        </>
                      }
                    />
                  </Grid>

                  {!values.isConsentCanBeAdded || (!values.email && values.isConsentCanBeAdded) ? (
                    <Grid item container spacing={2} xl={12} md={12} lg={12} sm={12} xs={12}>
                      <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                        <Typography variant='subtitle1' display='block'>
                          Upload Manual Consent Document
                        </Typography>
                      </Grid>
                      <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                        <Field
                          name='consent'
                          component={FileInput}
                          candidateId={props.companyId}
                          setIsUploading={setIsConsentUploading}
                          uploadToServer={'s3Upload'}
                          linkFieldName={'consentLink'}
                          defaultField={false}
                          fileName={values.consentName || ''}
                          nameField={'consentName'}
                          accept={validDocTypes.consent}
                          setValidation={handleConsentValidation}
                        />
                        {!values.validConsentFormat && values.consentName !== '' && (
                          <div className={classes.error}>
                            Please provide a valid consent format ({validDocTypes.consent})
                          </div>
                        )}
                        {/* <Field name='consent' component={FileInput} defaultField={false} /> */}
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Box>
            ) : null}

            <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
              <FormControl component='fieldset'>
                <FormLabel component='legend'>
                  Who will fill the BGV form? <span className={classes.required}>*</span>
                </FormLabel>
                <RadioGroup
                  row
                  aria-label='position'
                  name='position'
                  defaultValue='top'
                  onChange={(e) => {
                    setFieldValue('invite', e.target.value);
                  }}
                  value={values.invite}
                >
                  <FormControlLabel
                    value={'true'}
                    control={<Radio color='primary' />}
                    label='Ask candidate'
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </form>
          <Grid className='accordionContainer'>
            <div className={classes.accordionContainer}>
              <Accordion>
                <Accordion.Toggle eventKey='0' className={classes.cardToggle}>
                  <span className={classes.inviteMailHeading}>
                    Add Note to Candidate (in Invite Email)
                  </span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='0' className={classes.cardBody}>
                  <>
                    <Form.Group controlId='inviteEmail'>
                      <Form.Control
                        as='textarea'
                        rows={3}
                        name='hrInviteNote'
                        placeholder='Type a note...'
                        value={values?.hrInviteNote}
                        className={classes.accordionTextarea}
                        onChange={(e) => {
                          setFieldValue('hrInviteNote', e.target.value);
                        }}
                        style={{ boxShadow: 'none' }}
                      />
                      <div
                        className={classes.textareaCharacterLimit}
                        style={
                          charCount > 160 || (charCount < 10 && charCount > 0)
                            ? { color: '#DC3545' }
                            : null
                        }
                      >
                        Characters {charCount} out of 160
                      </div>
                    </Form.Group>
                  </>
                </Accordion.Collapse>
              </Accordion>
            </div>
          </Grid>
          <Grid item xl={12} md={12} lg={12} sm={12} xs={12} className={classes.buttonGrid}>
            <Box mt={2}>
              <Button
                variant='contained'
                size='large'
                type='button'
                onClick={handleContinue}
                className={isValidForm ? classes.activeBtn : classes.disabledBtn}
                disabled={!isValidForm}
                endIcon={<NavigateNextIcon />}
              >
                {'Save & Next'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
