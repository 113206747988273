import * as Yup from 'yup';

const CUSTOM_NUMBER_SCHEMA = Yup.object().shape({
  cost: Yup.string()
    .test('cost', 'Cost is Required', function (value) {
      return this.parent.isAvailable && value < 1 ? false : true;
    })
});

export default Yup.object().shape({
  model_type: Yup.string().required('Please enter valid package type'),
  identity: CUSTOM_NUMBER_SCHEMA,
  addressDigital: CUSTOM_NUMBER_SCHEMA,
  addressPhysical: CUSTOM_NUMBER_SCHEMA,
  addressPostal: CUSTOM_NUMBER_SCHEMA,
  courtStandard: CUSTOM_NUMBER_SCHEMA,
  courtStandardLawfirm: CUSTOM_NUMBER_SCHEMA,
  employment: CUSTOM_NUMBER_SCHEMA,
  education: CUSTOM_NUMBER_SCHEMA,
  educationThirdParty: CUSTOM_NUMBER_SCHEMA,
  educationHybrid: CUSTOM_NUMBER_SCHEMA,
  cibil: CUSTOM_NUMBER_SCHEMA,
  drug5Panel: CUSTOM_NUMBER_SCHEMA,
  drug7Panel: CUSTOM_NUMBER_SCHEMA,
  drug10Panel: CUSTOM_NUMBER_SCHEMA,
  world: CUSTOM_NUMBER_SCHEMA,
  reference: CUSTOM_NUMBER_SCHEMA
});
