import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, has } from 'lodash';
import { toast } from 'react-toastify';

import validationSchema from './UpdateBalance.validation';

import { updateCompanyCreditBalance as updateCompanyCreditBalanceAPI } from '../../../../../../api/admin';
import {
  getCompanyCreditBalance as getCompanyCreditBalanceAPI,
  getCompanyRechargeLogs as getCompanyRechargeLogsAPI
} from '../../../../../../actions/admin';

import {
  errToastMessage,
  getErrorMessage,
  getFormattedFinalValues
} from '../../../../../../utils/Utlities';
import {
  CREDIT_REFUND,
  CREDIT_DEDUCTION,
  CREDIT_DEDUCTION_BRIDGE_CREDITS
} from '../../../../../../core-components/CommonConstants';

const mapStateToProps = (state) => {
  return {
    companyDetails: state.updateCompanyData ? state.updateCompanyData : {},
    companyCreditBalance: state.companyCreditBalance || null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyCreditBalance: (companyId) => dispatch(getCompanyCreditBalanceAPI(companyId)),
    getCompanyRechargeLogs: (companyId, limit, offset) =>
      dispatch(getCompanyRechargeLogsAPI(companyId, limit, offset))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        tag: '',
        amount: '',
        paymentMode: '',
        paymentReference: '',
        invoiceNumber: '',
        proformaNumber: '',
        additionalInfo: '',
        invoiceUrl: '',
        disabled: false,
        tax: '',
        tds: '',
        companyName: '',
        invoiceDate: '',
        paymentDate: ''
      };

      let propsValue = {};
      propsValue.tax = props?.tax;
      propsValue.tds = props?.companyDetails?.tds;
      if (has(props, 'companyId')) {
        propsValue.companyId = props.companyId;
      } else {
        propsValue = {};
      }

      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    validationSchema,
    enableReinitialize: true,
    handleSubmit: (
      values,
      { setFieldError, setSubmitting, setFieldValue, resetForm, setErrors, props }
    ) => {
      // creating copy of values to update cost to -ve if required
      const valuesCopy = { ...values };

      // in case of CREDIT_REFUND,CREDIT_DEDUCTION send amounnt as -ve
      if (
        [CREDIT_REFUND, CREDIT_DEDUCTION, CREDIT_DEDUCTION_BRIDGE_CREDITS].includes(valuesCopy.tag)
      ) {
        valuesCopy.amount = parseInt(valuesCopy.amount) * -1;
      } else {
        valuesCopy.amount = parseInt(valuesCopy.amount);
      }

      // getting company id
      valuesCopy.companyId = parseInt(valuesCopy.companyId);

      if (props.id === 'PENDING_ORDER') {
        valuesCopy.orderId = props.orderId;
      }
      if (valuesCopy.amount === 0) {
        setFieldError('amount', 'Amount cannot be 0');
        setSubmitting(false);
      } else {
        // this funtion refactor the values object and remove keys which are empty
        const finalValues = getFormattedFinalValues(valuesCopy);

        updateCompanyCreditBalanceAPI(finalValues)
          .then((apiResponse) => {
            setSubmitting(false);

            if (props.id === 'PENDING_ORDER') {
              toast.info('Order marked as Paid');
            } else if (apiResponse?.data?.message) {
              toast.info(apiResponse?.data?.message);
            } else {
              toast.info('Credits updated successfully');
            }
            //update balance
            props.getCompanyCreditBalance({ companyId: props.companyId, onlyNegative: false });
            resetForm();
            props.handleClose();
          })
          .catch((error) => {
            setSubmitting(false);
            setFieldValue('disabled', false);
            errToastMessage(error);
          });
      }
    },
    displayName: 'Add Balance'
  })
);
