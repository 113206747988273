import { connect } from 'react-redux';
import { compose } from 'redux';
import { withFormik } from 'formik';
import { mergeWith } from 'lodash';
import { toast } from 'react-toastify';

import validationSchema from './AssignCompanyStep2.validation';
import { errToastMessage } from '../../../../../../utils/Utlities';
const mapStateToProps = (state) => {
  return {
    packageDetails: state.packageDetails
  };
};
export default compose(
  connect(mapStateToProps, null),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        companyName: '',
        packageName: '',
        isCompany: props.parent === 'COMPANY' ? true : false, // to validate if its called from CREATE COMPANY
        rechargeDetails: [{ rechargeUnits: '0', costPerCandidate: '0' }],
        packageText: []
      };

      let propsValues = {};

      if (props.savedValues) {
        propsValues = {
          companyName: (props.savedValues.step1 && props.savedValues.step1.name) || '',
          packageName:
            (props.parent === 'COMPANY' ? props.savedValues.step1.packageName : '') || '',
          rechargeDetails: getRechargeDetails(props.savedValues),
          packageText: getpackageText(props.savedValues)
        };
      }

      if (props.pConfig && props.pConfig.length && !propsValues.packageText.length) {
        propsValues['packageText'] = [];

        for (let value of props.pConfig) {
          propsValues['packageText'].push({
            subtypeId: value.id,
            subtypePackageText: '',
            subtypeName: value.subtypeName,
            isAssigned: false,
            configCount: value.configCount
          });
        }
      }
      return mergeWith({}, storedValues, propsValues, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (values, { setFieldError, setStatus, setSubmitting, resetForm, props }) => {
      const finalData = { ...values };
      isAnySubtypeWithFiveIds(finalData.packageText)
        .map((subtypes) => subtypes.subtypeName)
        .join(' & ');
      if (!props.isAadhaarAllowed && isAnySubtypeWithFiveIds(finalData.packageText).length > 0) {
        errToastMessage(
          `${isAnySubtypeWithFiveIds(finalData.packageText)
            .map((subtypes) => subtypes.subtypeName)
            .join(
              ' & '
            )} has ID count 5 and aadhaar is not allowed for the company. Allow aadhaar in ID check or do not assign the subtype to move forward.`
        );
      } else {
        props.handleStepSubmit('step2', finalData, 3);
      }
    },
    displayName: 'Step1'
  })
);

const isAnySubtypeWithFiveIds = (configData) => {
  return configData.filter((t) => {
    return t.isAssigned && t.configCount.identity === 5;
  });
};

const getRechargeDetails = (savedValues) => {
  return savedValues.step2 && savedValues.step1.packageName === savedValues.step2.packageName
    ? savedValues.step2.rechargeDetails
    : [{ rechargeUnits: '0', costPerCandidate: '0' }];
};
const getpackageText = (savedValues) => {
  return savedValues.step2 && savedValues.step1.packageName === savedValues.step2.packageName
    ? savedValues.step2.packageText
    : [];
};
