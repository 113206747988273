import { connect } from 'react-redux';
import { compose } from 'redux';
import { isEmpty } from 'lodash';

import {
  getCompanyCreditBalance as getCompanyCreditBalanceAPI,
  getCompanyRechargeLogs as getCompanyRechargeLogsAPI,
  getPostpaidCreditCompany as getPostpaidCreditCompanyAPI,
  invalidateCreditBalance as invalidateCreditBalanceAPI,
  invalidatePostpaidCreditCompany as invalidatePostpaidCreditCompanyAPI,
  getCompanyPendingOrders as getCompanyPendingOrdersAPI,
  invalidateCompanyPendingOrder as invalidateCompanyPendingOrderAPI
} from '../../../../actions/admin';

const mapStateToProps = (state) => {
  return {
    companyCreditBalance: state.companyCreditBalance || null,
    companyPendingOrders: state.companyPendingOrders || null,
    profile: state.profile && !isEmpty(state.profile) ? state.profile : null,
    postpaidCreditCompany: state.postpaidCreditCompany || null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyCreditBalance: (companyId) => dispatch(getCompanyCreditBalanceAPI(companyId)),
    invalidateCreditBalanceAPI: () => dispatch(invalidateCreditBalanceAPI()),
    invalidatePostpaidCreditCompanyAPI: () => dispatch(invalidatePostpaidCreditCompanyAPI()),
    getCompanyRechargeLogs: (companyId, limit, offset) =>
      dispatch(getCompanyRechargeLogsAPI(companyId, limit, offset)),
    getPostpaidCreditCompany: (companyId) => dispatch(getPostpaidCreditCompanyAPI(companyId)),
    getCompanyPendingOrders: (data, onlyCount) =>
      dispatch(getCompanyPendingOrdersAPI(data, onlyCount)),
    invalidateCompanyPendingOrder: () => dispatch(invalidateCompanyPendingOrderAPI())
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
