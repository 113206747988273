import React, { useEffect, useRef } from 'react';
import ReactApexChart from 'react-apexcharts';
import './GraphComponent.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faDownload, faLink } from '@fortawesome/free-solid-svg-icons';
import html2canvas from 'html2canvas';
import { toast } from 'react-toastify';

const GraphComponent = ({
  chartOptions,
  graphData,
  key,
  setAllChartsReferenceMap,
  downloadChart,
  index
}) => {
  const chartRef = useRef(null);
  useEffect(() => {
    setAllChartsReferenceMap((prev) => {
      return {
        ...prev,
        [index]: chartRef
      };
    });
  }, [chartRef, index]);

  const copyChart = async () => {
    const chartContainer = chartRef.current;
    if (chartContainer) {
      try {
        const canvas = await html2canvas(chartContainer);
        const dataURL = canvas.toDataURL();
        const blob = await fetch(dataURL).then((res) => res.blob());
        await navigator.clipboard.write([
          new ClipboardItem({
            [blob.type]: blob
          })
        ]);
        toast.success('Chart copied to clipboard');
      } catch (error) {
        console.error('Failed to copy chart to clipboard:', error);
      }
    }
  };

  const state = {
    series: graphData.series,
    options: {
      legend: {
        markers: {
          fillColors: chartOptions.fillColors
        }
      },
      chart: {
        type: 'bar',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: `${chartOptions.columnWidth}%`,
          endingShape: 'rounded',
          borderRadius: 4,
          borderRadiusApplication: 'end'
        }
      },
      dataLabels: {
        enabled: chartOptions.datalabels
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: graphData.options.xAxis.categories
      },
      yaxis: {
        title: {
          text: chartOptions.yAxisTitle
        }
      },
      fill: {
        opacity: chartOptions.fillOpacity,
        colors: chartOptions.fillColors
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + ' days';
          }
        }
      }
    }
  };

  const downloadCharHandler = () => {
    downloadChart(chartRef, true, graphData);
  };

  return (
    <div
      className='chartContainer'
      style={{
        width: `${chartOptions.width}%`,
        backgroundColor: `${chartOptions.backgroundColor}`
      }}
      key={key}
    >
      <div className='chartHeader'>
        <div className='chartTitle'>{graphData.options.title.text}</div>
        <div className='downloadOptions'>
          <div className='copyChart' onClick={copyChart}>
            <FontAwesomeIcon icon={faCopy} />
          </div>
          <div className='downloadChart' onClick={downloadCharHandler}>
            <FontAwesomeIcon icon={faDownload} />
          </div>
        </div>
      </div>
      <div ref={chartRef}>
        <ReactApexChart
          options={state.options}
          series={state.series}
          type='bar'
          height={chartOptions.height}
        />
      </div>
    </div>
  );
};

export default GraphComponent;
