import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { PLAIN_CHECK_ICONS } from '../../CommonConstants';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  formControl: {
    margin: theme.spacing(3)
  }
}));
export default (props) => {
  const { value } = props;
  const classes = useStyles();
  return (
    <>
      <div>
        {value?.map((custom_check, index) => (
          <div key={index}>
            <Grid className={classes.root} item container>
              <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                <Typography variant='h6' style={{ fontSize: '18px' }} gutterBottom>
                  <span style={{ marginRight: '10px' }} title={'custom'}>
                    {PLAIN_CHECK_ICONS['custom_check']
                      ? <VerifiedUserIcon fontSize={'large'} style={{ fontSize: '20px' }} />
                      : null}
                  </span>
                  {custom_check?.customCheckName || custom_check?.checkName}
                </Typography>
              </Grid>
              <Grid
                item
                container
                xl={12}
                md={12}
                lg={12}
                sm={12}
                xs={12}
                style={{ marginLeft: '20px', marginTop: '12px', marginBottom: '24px' }}
              >
                <FormControlLabel
                  disabled
                  control={<Checkbox checked={props?.value ? true : false} name='checkedE' />}
                  label={'Enabled'}
                />
              </Grid>
            </Grid>
          </div>
        ))}
      </div>
    </>
  );
};
