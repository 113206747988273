import { connect } from 'react-redux';
import { compose } from 'redux';

import { logout as logoutAPI } from '../../api/admin';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (data) => dispatch(logoutAPI(data))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
