import React, { useState, useEffect } from 'react';

import {
  Grid,
  Typography,
  Box,
  Paper,
  FormControl,
  FormLabel,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  TextField,
  Card,
  CardContent,
  Button
} from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import FormInput from '../../../core-components/FormInput';
import FileInput from '../../../core-components/FileInput';
import { makeStyles } from '@material-ui/core/styles';
import useDebounce from '../../../core-components/debounce';
import AppConstants from '../../../core-components/AppConstants';
import DropdownInput from '../../../core-components/DropdownInput';
import { COMPANY_STATUSES } from '../../../utils/Statuses';

import { getFuzzySearch as getFuzzySearchAPI } from '../../../api/admin';
import { validDocTypes, getTrimmedValue } from '../../../utils/Utlities';
import { UPLOAD_FILE_TYPES } from '../../../core-components/CommonConstants';
import { validateFriendlyName } from '../../../utils/ValidationSchema';
import ImageUpload from '../frontend-common/AlumniVerification/BrandSettingsPage/ImageUpload';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0, 2, 0),
    color: theme.palette.text.secondary
  },

  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },

  required: {
    color: '#db3131'
  },
  customFormLabel: {
    marginBottom: 10
  },
  customImgWidth: {
    maxWidth: 180
  },
  error: {
    fontSize: '12px',
    color: '#f44336',
    margin: '0 0 10px 0'
  },
  logoContainer: {
    display: 'flex',
    gap: '12px',
    marginBottom: '20px'
  },
  logoGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px'
  },
  trialCheckbox: {
    marginLeft: '14px'
  },
  trialCheckboxContainer: {
    border: '1px solid rgba(0, 0, 0, 0.3)',
    padding: '12px 0px',
    position: 'relative',
    borderRadius: '3px',
    marginLeft: '7px'
  },
  trialCheckboxTitle: {
    fontSize: '14px',
    position: 'absolute',
    background: 'white',
    top: '-11px',
    left: '12px'
  }
}));
export default (props) => {
  const classes = useStyles();
  const { values, setFieldValue } = props;
  const [handleCard, setHandleCard] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [nameFieldError, setNameFieldError] = useState(false);
  const [companyNames, setCompanyNames] = useState([]);
  const debounceCompanySearchTerm = useDebounce(values?.name, 1000);
  const [ownLogoAdded, setOwnLogoAdded] = useState(false);
  const { handleBlur: formikHandleBlur } = useFormikContext();
  const [nameLogoError, setNameLogoError] = useState(false);

  const reportDocument = [
    { label: 'External Links', value: 'external_links' },
    { label: 'Internal Images', value: 'internal_images' }
  ];

  useEffect(() => {
    setFieldValue('isUploading', isUploading);
  }, [isUploading]);

  // api call to get the list of companies with the springverify DB
  useEffect(() => {
    if (debounceCompanySearchTerm.length > 4) {
      getFuzzySearchAPI(values.name).then((response) => {
        if (response?.data?.data) {
          setCompanyNames(response?.data?.data);
        }
      });
    } else {
      setCompanyNames([]);
    }
  }, [debounceCompanySearchTerm]);

  const isValidImageUrl = async (url) => {
    if (!url) return null;

    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        resolve(img.height > 60);
      };
      img.onerror = () => {
        resolve(false);
      };
    });
  };

  const handleBlur = async (e) => {
    const trimmedValue = getTrimmedValue(e.target.value, false);
    let companyUrl = e.target.value;
    companyUrl.includes('https://')
      ? (companyUrl = companyUrl.replace('https://', 'www.'))
      : companyUrl.includes('http://')
      ? (companyUrl = companyUrl.replace('http://', 'www.'))
      : (companyUrl = companyUrl);
    // call API for fetching the logo
    props.handleBlur(e);
    formikHandleBlur(e);
    setFieldValue('companyUrl', trimmedValue);

    const logoUrl = `https://logo.clearbit.com/${companyUrl}`;
    const valid = await isValidImageUrl(logoUrl);
    if (valid) {
      if (!ownLogoAdded) {
        setFieldValue('darkLogoUrl', logoUrl);
      }
    } else {
      if (!ownLogoAdded) {
        const textlogoUrl = `https://place-hold.it/250x50/666/fff.png/000?text=${values?.name?.replaceAll(
          ' ',
          '%20'
        )}&bold`;
        setFieldValue('darkLogoUrl', textlogoUrl);
        setNameLogoError('Upload an image of size more than 60px');
      }
    }
  };

  const setLogoError = async () => {
    const darkError = await isValidImageUrl(values.darkLogoUrl);
    const lightError = await isValidImageUrl(values.lightLogoUrl);
    if (darkError === false || lightError === false) {
      setNameLogoError('Upload an image of size more than 60px');
    } else {
      setNameLogoError(false);
    }
  };

  useEffect(() => {
    if (!values.darkLogoUrl.startsWith('https://place-hold.it/')) setLogoError();
  }, [values.darkLogoUrl, values.lightLogoUrl]);

  return (
    <Paper className={classes.paper} variant='outlined' elevation={5}>
      <TextField
        error={nameFieldError}
        type='text'
        name='name'
        required={'true'}
        label='Company Name'
        id='name'
        autoComplete='off'
        variant='standard'
        style={{ width: props.title === 'Update Company' ? '60%' : '80%' }}
        helperText={nameFieldError ? 'Company Name cannot be blank' : null}
        value={values.name}
        onChange={(e) => {
          setFieldValue('name', e.target.value);
          if (e.target.value.trim()) {
            setHandleCard(true);
            setNameFieldError(false);
          } else {
            setHandleCard(false);
            setNameFieldError(true);
          }
        }}
        onBlur={(e) => {
          const trimmedValue = getTrimmedValue(e.target.value, true);
          setFieldValue('name', trimmedValue || '');
          if (!trimmedValue) {
            setNameFieldError(true);
          } else {
            setTimeout(() => {
              setHandleCard(false);
            }, 250);
          }
        }}
      />
      {props.title === 'Update Company' ? (
        <DropdownInput
          label={'Status'}
          value={props.values.status ? COMPANY_STATUSES.values[props.values.status]?.value : null}
          required={'true'}
          dropdownValues={COMPANY_STATUSES.values.slice(1)}
          handleSelect={(value) => {
            setFieldValue(
              'status',
              COMPANY_STATUSES.values.indexOf(
                COMPANY_STATUSES.values.slice(1).find((val) => val.value === value)
              )
            );
          }}
        />
      ) : (
        ''
      )}
      <span className={classes.trialCheckboxContainer}>
        <span className={classes.trialCheckboxTitle}>Account</span>
        <FormControlLabel
          className={classes.trialCheckbox}
          control={
            <Checkbox
              color='primary'
              onChange={(e) => setFieldValue('trialAccount', e.target.checked ? true : false)}
              checked={values.trialAccount}
              name={'trial'}
              value={values.trialAccount}
            />
          }
          label={'Trial '}
        />
      </span>

      {/* card to show the companies within the springverify */}
      {handleCard ? (
        <Card style={{ width: '60%' }}>
          <CardContent>
            <Typography variant='h6' style={{ fontSize: '18px', color: '#293845' }}>
              Similar Companies within Springverify
            </Typography>
            {companyNames.map((company, index) => {
              return (
                <div className={classes.cardElement}>
                  <Button
                    key={index}
                    size='small'
                    target={'_blank'}
                    style={{ justifyContent: 'left' }}
                    href={`${AppConstants.baseURL}company/view?id=${company.id}`}
                    onClick={() => {
                      setHandleCard(false);
                    }}
                  >
                    {company?.name} {company?.friendly_name ? `(${company?.friendly_name})` : null}
                  </Button>
                </div>
              );
            })}
          </CardContent>
        </Card>
      ) : null}
      <Field
        type='text'
        name='friendlyName'
        label='BrandName'
        error={props.errors.friendlyName}
        component={FormInput}
        validate={validateFriendlyName}
        onChange={(e) => {
          setFieldValue('friendlyName', e.target.value);
        }}
        onBlur={(e) => {
          const trimmedValue = getTrimmedValue(e.target.value, true);
          setFieldValue('friendlyName', trimmedValue || '');
          if (props?.validateField) props?.validateField('friendlyName');
        }}
      />
      <Field
        type='text'
        name='companyUrl'
        requiredfield={'true'}
        label='Company Url'
        component={FormInput}
        onBlur={handleBlur}
      />
      <Field
        type='text'
        name='linkedInUrl'
        label='LinkedIn URL'
        component={FormInput}
        onChange={(e) => {
          const val = getTrimmedValue(e.target.value, false);
          setFieldValue('linkedInUrl', val);
        }}
      />
      <Grid item lg={6} md={6} sm={8} xs={12}>
        <FormControl component='fieldset' fullWidth className={classes.formControl}>
          <InputLabel id='external-report'>Candidate External Report Documents </InputLabel>
          <Select
            name={'candidateReportDocuments'}
            labelId='external-report-label'
            value={values.candidateReportDocuments}
            onChange={(e) => {
              setFieldValue('candidateReportDocuments', e.target.value);
            }}
            displayEmpty
          >
            {reportDocument.map((item, index) => {
              return (
                <MenuItem key={index} value={item?.value}>
                  {item?.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Typography variant={'subtitle2'}>Company Logo</Typography>

      <Grid container>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <div className={classes.logoContainer}>
            <div className={classes.logoGroup}>
              <ImageUpload
                style={{
                  background: '#ebedf2',
                  border: '1px dashed #cbd5e1'
                }}
                title={'Upload dark logo'}
                subtitle={'(To use on light BG)'}
                setLogo={(e) => {
                  setFieldValue('darkLogoUrl', e);
                  setOwnLogoAdded(true);
                }}
                logo={values?.darkLogoUrl}
                setLogoFile={(e) => {
                  setFieldValue('darkLogoFile', e);
                }}
                setLogoError={() => {}}
                isSa={true}
                onLogoDelete={() => {
                  setFieldValue('darkLogoFile', '');
                  setFieldValue('darkLogoUrl', '');
                  setFieldValue('uploadedLogoError', '');
                  setOwnLogoAdded(false);
                  if (values.selectedLogoType === 'dark') {
                    setFieldValue('selectedLogoType', '');
                    setFieldValue('enableLogo', false);
                  }
                }}
                skipValidation={true}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={values.selectedLogoType === 'dark' && !nameLogoError}
                    inputProps={{
                      'aria-label': 'secondary checkbox'
                    }}
                    disabled={
                      nameLogoError || values.darkLogoUrl === '' || values.uploadedLogoError
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFieldValue('selectedLogoType', 'dark');
                        setFieldValue('enableLogo', true);
                      } else {
                        setFieldValue('selectedLogoType', '');
                        setFieldValue('enableLogo', false);
                      }
                    }}
                  />
                }
                label='Enable dark logo in invite email and candidate report'
              />
            </div>
            <div className={classes.logoGroup}>
              <ImageUpload
                style={{ background: '#6a778b', color: 'white' }}
                title={'Upload light logo'}
                subtitle={'(To use on dark BG)'}
                setLogo={(e) => {
                  setFieldValue('lightLogoUrl', e);
                }}
                logo={values?.lightLogoUrl}
                setLogoFile={(e) => {
                  setFieldValue('lightLogoFile', e);
                }}
                setLogoError={() => {}}
                isSa={true}
                onLogoDelete={() => {
                  setFieldValue('uploadedLogoError', '');
                  setFieldValue('lightLogoFile', '');
                  setFieldValue('lightLogoUrl', '');
                  if (values.selectedLogoType === 'light') {
                    setFieldValue('selectedLogoType', '');
                    setFieldValue('enableLogo', false);
                  }
                }}
                skipValidation={true}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={values.selectedLogoType === 'light' && !nameLogoError}
                    inputProps={{
                      'aria-label': 'secondary checkbox'
                    }}
                    disabled={
                      nameLogoError || values.lightLogoUrl === '' || values.uploadedLogoError
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFieldValue('selectedLogoType', 'light');
                        setFieldValue('enableLogo', true);
                      } else {
                        setFieldValue('selectedLogoType', '');
                        setFieldValue('enableLogo', false);
                      }
                    }}
                  />
                }
                label='Enable light logo in invite email and candidate report'
              />
            </div>
          </div>
          {values?.uploadedLogoError && (
            <div className={classes.error}>{values?.uploadedLogoError}</div>
          )}
          {!values?.uploadedLogoError && nameLogoError && (
            <div className={classes.error}>{nameLogoError}</div>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};
