import React, { useState } from 'react';
import { forEach, isEmpty, has } from 'lodash';

import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { Tooltip, Zoom, IconButton } from '@material-ui/core';
import { Box, Grid } from '@material-ui/core';

import Table from '../../../core-components/Table';
import DropdownInput from '../../../core-components/DropdownInput';
import AppConstants from '../../../core-components/AppConstants';
import Button from '../../../core-components/Button';
import BreadCrumbs from '../../../core-components/BreadCrumbs';
import CreateQuestion from './CreateQuestion';
import ConfirmationPopup from '../../../core-components/ConfirmationPopup';
import { deleteDefaultQuestion as deleteDefaultQuestionAPI } from '../../../api/admin';
import { errToastMessage, getErrorMessage } from '../../../utils/Utlities';
import { statusFilters } from '../../../core-components/CommonConstants';

const useStyles = makeStyles({
  heading: {
    paddingTop: '20px',
    paddingBottom: '20px'
  },
  headingSubText: {
    color: '#8c8c8c',
    fontSize: '18px'
  },
  customBtnStyle: {
    margin: '16px 0px 0px'
  }
});

function createData(incrementId, question, status, createdAt, updatedAt, deleteAction) {
  return { incrementId, question, status, createdAt, updatedAt, deleteAction };
}

const headCells = [
  {
    id: 'incrementId',
    label: '#',
    align: 'left'
  },
  {
    id: 'question',
    label: 'Questions',
    align: 'left'
  },
  {
    id: 'status',
    label: 'Status',
    align: 'center'
  },
  {
    id: 'createdAt',
    label: 'Created At',
    align: 'center'
  },
  {
    id: 'updatedAt',
    label: 'Updated At',
    align: 'center'
  },
  {
    id: 'deleteAction',
    label: 'Actions',
    align: 'center'
  }
];

export default function DefaultRefCheckQuestions(props) {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState('All');
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [questionUuid, setQuestionUuid] = useState('');

  // TODO
  // const breadCrumbsArray = [{ label: 'Home', href: 'dashboard' }];
  const breadCrumbsArray = [{ label: 'Home', href: `${AppConstants.baseURL}dashboard` }];

  //Call API here

  React.useEffect(() => {
    props.invalidateDefaultQuestions();
    getQuestions(rowsPerPage, offset);
  }, []);

  React.useEffect(() => {
    if (
      !isEmpty(props.questions) &&
      has(props.questions, 'loading') &&
      props?.questions?.loading === false
    ) {
      let rows =
        props?.questions?.all[offset] &&
        props?.questions?.all[offset]?.rows &&
        props?.questions?.all[offset]?.rows?.length
          ? getRowData(props.questions.all[offset].rows)
          : [];
      setData(rows);
      setCount(props.questions.all[offset].count);
      setLoading(props.questions.loading);
    }
  }, [props.questions]);

  const getQuestions = (rowsPerPage, offset, status = null) => {
    setLoading(true);
    const statusToSend = status === 'All' ? null : status;
    props
      .getDefaultQuestions(rowsPerPage, offset, statusToSend)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const getRowData = (values) => {
    let questionsRow = [];
    forEach(values, function (value, key) {
      if (!isEmpty(value)) {
        let rowData = createData(
          key + 1 + offset * rowsPerPage,
          value.questionText,
          value.status ? 'Active' : 'In-Active',
          // value.createdAt,
          (value.createdAt &&
            moment(value.createdAt).isValid() &&
            moment(value.createdAt).format('DD MMM YYYY hh:mm A')) ||
            null,
          (value.updatedAt &&
            moment(value.updatedAt).isValid() &&
            moment(value.updatedAt).format('DD MMM YYYY hh:mm A')) ||
            null,
          deleteAction(value.id, value.status)
        );
        questionsRow.push(rowData);
      }
    });
    return questionsRow;
  };

  const getStatusFilter = () => {
    return (
      <DropdownInput
        label={'Statuses'}
        value={status}
        dropdownValues={statusFilters}
        handleSelect={(status) => {
          applyStatusFilter(status);
        }}
      />
    );
  };

  const deleteAction = (uuid = null, status = false) => {
    return (
      <IconButton
        aria-label='delete'
        disabled={!status ? true : false}
        onClick={() => {
          setQuestionUuid(uuid);
          setShowConfirmationPopup(true);
        }}
      >
        <Tooltip TransitionComponent={Zoom} title='Delete' arrow={true}>
          <DeleteOutlineIcon color={!status ? 'disabled' : 'primary'} />
        </Tooltip>
      </IconButton>
    );
  };

  const applyStatusFilter = (status) => {
    setStatus(status);
    setOffset(0);
    getQuestions(rowsPerPage, 0, status);
    //apply status filter, call API
  };

  const handleChangePage = (newPage) => {
    //need to call API with new limit and offset
    setOffset(newPage);
    getQuestions(rowsPerPage, newPage, status);
  };

  const handleChangeRowsPerPage = (value) => {
    //need to call API with new limit and offset
    setOffset(0);
    setRowsPerPage(parseInt(value, 10));
    getQuestions(parseInt(value, 10), 0, status);
  };

  return (
    <>
      <BreadCrumbs linksArray={breadCrumbsArray} current={'Default Questions'} />
      <div className={classes.heading}>
        <Typography variant='h5' component='h5'>
          {'Default Questions'}
        </Typography>
      </div>
      <>
        <Button
          label={'Add New Question'}
          color={'primary'}
          variant={'contained'}
          onClick={() => {
            setShowCreatePopup(true);
          }}
          className={classes.customBtnStyle}
          startIcon={<ControlPointIcon />}
        />
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item lg={3} md={4} sm={4} xs={10}>
              {getStatusFilter()}
            </Grid>
          </Grid>
        </Box>
        <Table
          headCells={headCells}
          rowData={data}
          totalCount={count}
          rowsPerPage={rowsPerPage}
          page={offset}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          loading={loading}
        />
      </>
      {showCreatePopup && (
        <CreateQuestion
          open={showCreatePopup}
          title={'Add New Question'}
          label={'Statuses'}
          pageDetails={{ rowsPerPage, offset, status, getQuestions }}
          handleClose={() => {
            setShowCreatePopup(false);
          }}
        />
      )}
      {showConfirmationPopup && (
        <ConfirmationPopup
          open={showConfirmationPopup}
          title={'Are you sure ?'}
          content={'Are you sure you want to disable this question ?'}
          handleSubmit={() => {
            deleteDefaultQuestionAPI(questionUuid)
              .then((response) => {
                setQuestionUuid('');
                toast.info('Question disabled successfully');
                setShowConfirmationPopup(false);
                getQuestions(rowsPerPage, offset, status);
              })
              .catch((error) => {
                setQuestionUuid('');
                console.error(error);
                errToastMessage(error);
                setShowConfirmationPopup(false);
              });
          }}
          handleClose={() => {
            setShowConfirmationPopup(false);
          }}
          buttonLabel={'Confirm'}
        />
      )}
    </>
  );
}
