import React, { useState, useEffect, useRef } from 'react';

import { isEmpty } from 'lodash';
import {
  Typography,
  Button,
  Grid,
  Box,
  Paper,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormGroup,
  InputAdornment
} from '@material-ui/core';
import { Field, FieldArray } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import FormInput from '../../../../../../core-components/FormInput';
import { getTrimmedValue } from '../../../../../../utils/Utlities';
import ConfirmationPackage from '../../../../../../core-components/ConfirmationPackage';
import InternationalChargesBanner from '../../../../UtilsComponents/InternationalChargesBanner';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex'
  },
  formControl: {
    marginBottom: theme.spacing(3),
    marginLeft: '8px'
  },
  activeBtn: {
    margin: theme.spacing(1),
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  disabledBtn: {
    cursor: 'not-allowed',
    margin: theme.spacing(1),
    color: '#dfdfdf',
    borderColor: '#d9d9d9',
    backgroundColor: '#f5f5f5',
    fontSize: 14,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  required: {
    color: '#DB3131'
  },
  notRequired: {
    color: '#FFA500'
  },
  progressContainer: {
    textAlign: 'center',
    width: '100%'
  },
  paymentMethod: {
    display: 'flex',
    padding: '20px 16px 20px 16px !important',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    marginBottom: '8px',
    borderRadius: '4px'
  },
  costPerCandidateContainer: {
    padding: '20px 16px 20px 16px !important',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderLeft: 'unset',
    marginBottom: '8px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px'
  },
  marginBottom16: {
    marginBottom: '16px'
  },
  marginTop16: {
    marginTop: '16px'
  },
  rupeeSymbol: {
    fontFamily: 'Roboto'
  }
}));

function AssignCompanyStep2(props) {
  const classes = useStyles();

  const { handleSubmit, values, errors, setFieldValue } = props;

  const [subtypes, setSubtypes] = useState(values.packageText);
  const myRef = useRef(null);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  // For smooth scroll
  const scrollToRef = (ref) => {
    ref.current.scrollIntoView(true);
  };

  useEffect(() => {
    scrollToRef(myRef);
  }, []);

  useEffect(() => {
    setSubtypes(values.packageText);
  }, [values.packageText]);

  const handleStep2 = (isBack = false) => {
    props.handleStepSubmit('step2', values, isBack ? 1 : 3);
  };

  const handleFormSubmit = (e) => {
    if (e) e.preventDefault();

    const hasEmptyPackageText = values.packageText.some(
      (item) => item.isAssigned && !item.subtypePackageText.trim()
    );
    if (hasEmptyPackageText) {
      setShowConfirmationPopup(true);
    } else {
      props.handleSubmit();
    }
  };
  const handleChange = (event, subtypeId) => {
    // getting copy of all subtypes
    const tempArr = [...subtypes];
    // getting index and subtype which is clicked
    const index = tempArr.findIndex((sub) => sub.subtypeId === subtypeId);

    // updating subtype disable property and updating subtypes array
    tempArr[index].isAssigned = !tempArr[index].isAssigned;

    setSubtypes(tempArr);
  };

  const validatePackageText = (subtypesArr) => {
    const assigned = subtypesArr.filter((val) => val.isAssigned);
    return assigned.length === 0 ? false : true;
  };
  const isValidForm = isEmpty(errors) && validatePackageText(subtypes);
  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <Grid ref={myRef} className={classes.root} container direction={'row'}>
          <Grid item lg={8} md={8} sm={10} xs={10}>
            {props.parent === 'COMPANY' ? (
              <Field
                type='text'
                name='packageName'
                requiredfield={'true'}
                label='Package Name'
                component={FormInput}
                variant={'outlined'}
                fullWidth
                disabled={true}
              />
            ) : (
              <Field
                type='text'
                name='companyName'
                requiredfield={'true'}
                label='Company Name'
                component={FormInput}
                variant={'outlined'}
                fullWidth
                disabled={true}
              />
            )}
            <Typography
              variant={'subtitle2'}
              gutterBottom
              style={{
                marginBottom: '16px',
                marginTop: '16px',
                padding: '10px',
                backgroundColor: '#FAEBD7'
              }}
            >
              Package base price : {props.packageBaseCost}
            </Typography>
            {props.packageDetails?.loading === false &&
              props.packageDetails?.data?.packageDetails?.internationalChargesIncluded && (
                <InternationalChargesBanner />
              )}

            {/* Recharge details section */}
            <Box mt={2} mb={2}>
              <Typography variant={'subtitle2'} gutterBottom style={{ marginBottom: '16px' }}>
                {'Usage Configuration'}
              </Typography>

              <Grid container style={{ alignItems: 'center' }}>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                  <Paper elevation={10} variant={'outlined'} className={classes.paymentMethod}>
                    <Typography align='center' style={{ padding: '8px' }}>
                      Cost (per candidate) <span className={classes.required}>*</span>
                    </Typography>
                    <div className='inputAdornMent'>
                      <Field
                        id={`rechargeDetails_cost_Per_Candidate}`}
                        name={`rechargeDetails.costPerCandidate`}
                        placeholder={'500'}
                        component={FormInput}
                        variant={'outlined'}
                        isShowInIndianCurrency={true}
                        startAdornment={
                          <InputAdornment position='start'>
                            <span className={classes.rupeeSymbol}>&#8377;</span>
                          </InputAdornment>
                        }
                        style={{ marginLeft: '8px', width: '200px' }}
                      />
                    </div>
                  </Paper>
                </Grid>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                  <Paper elevation={3} variant={'outlined'} className={classes.paymentMethod}>
                    <ErrorOutlineIcon /> <Typography> Payment Method : On Completion</Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Box>

            {/* Package text section */}
            <Box mt={2} mb={2}>
              <Typography
                variant={'subtitle2'}
                gutterBottom
                className={`${classes.marginBottom16} ${
                  props.parent === 'COMPANY' ? classes.marginTop16 : ''
                }`}
              >
                Package text for Sub-types <span className={classes.notRequired}>*</span>
              </Typography>

              <Grid container spacing={2}>
                {subtypes && subtypes.length ? (
                  <FieldArray
                    name='packageText'
                    render={() =>
                      subtypes.map((item, index) => {
                        const handleBlur = (e) => {
                          const trimmedValue = getTrimmedValue(e.target.value, true);
                          setFieldValue(
                            `packageText.${index}.subtypePackageText`,
                            trimmedValue || ''
                          );
                          if (!e.target.value) {
                            setFieldValue(`packageText.${index}.isAssigned`, false);
                          }
                        };
                        return (
                          <Grid item lg={12} md={12} sm={12} xs={12} key={`questions_${index + 1}`}>
                            <Field
                              type='text'
                              id={`packageText_${index + 1}`}
                              name={`packageText.${index}.subtypePackageText`}
                              placeholder={'Package text'}
                              label={item.subtypeName}
                              component={FormInput}
                              variant={'outlined'}
                              onBlur={handleBlur}
                            />
                            <FormControl component='fieldset' className={classes.formControl}>
                              <FormGroup>
                                <FormControlLabel
                                  key={item.subtypeId}
                                  control={
                                    <Checkbox
                                      checked={item.isAssigned}
                                      onChange={(e) => handleChange(e, item.subtypeId)}
                                      name={item.subtypeName}
                                    />
                                  }
                                  label={'Assign'}
                                />
                              </FormGroup>
                            </FormControl>
                          </Grid>
                        );
                      })
                    }
                  />
                ) : null}
              </Grid>
            </Box>
          </Grid>
        </Grid>

        {/* Footer */}
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Button
              variant='outlined'
              color={'primary'}
              size='large'
              onClick={() => handleStep2(true)}
              startIcon={<ArrowBackIosIcon />}
            >
              {'Back'}
            </Button>
            <Button
              type={'submit'}
              variant='contained'
              size='large'
              className={isValidForm ? classes.activeBtn : classes.disabledBtn}
              disabled={!isValidForm}
              endIcon={<ArrowForwardIosIcon />}
            >
              {'Save & Next'}
            </Button>
          </Grid>
        </Grid>
      </form>
      {showConfirmationPopup ? (
        <ConfirmationPackage
          open={showConfirmationPopup}
          handleClose={() => {
            setShowConfirmationPopup(false);
          }}
          handleSubmit={() => {
            props.handleSubmit();
          }}
          buttonLabel={'Yes,Save'}
          title='No package text assigned'
          content='Are you sure you want to proceed without package text?'
        />
      ) : null}
    </>
  );
}

export default AssignCompanyStep2;
