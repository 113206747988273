import React, { useState } from 'react';
import { forEach, isEmpty, has } from 'lodash';

import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { Tooltip, Zoom, IconButton } from '@material-ui/core';
import { Box, Grid } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import Table from '../../../core-components/Table';
import DropdownInput from '../../../core-components/DropdownInput';
import AppConstants from '../../../core-components/AppConstants';
import Button from '../../../core-components/Button';
import BreadCrumbs from '../../../core-components/BreadCrumbs';
import CreateVendor from './CreateVendor';
import { getErrorMessage } from '../../../utils/Utlities';
import { statusFilters } from '../../../core-components/CommonConstants';

const useStyles = makeStyles({
  heading: {
    paddingTop: '20px',
    paddingBottom: '20px'
  },
  headingSubText: {
    color: '#8c8c8c',
    fontSize: '18px'
  },
  customBtnStyle: {
    margin: '16px 0px 0px'
  },
  editBtn: {
    cursor: 'pointer'
  }
});

function createData(incrementId, name, status, createdAt, updatedAt, deleteAction) {
  return { incrementId, name, status, createdAt, updatedAt, deleteAction };
}

const headCells = [
  {
    id: 'incrementId',
    label: '#',
    align: 'left'
  },
  {
    id: 'name',
    label: 'Vendor Name',
    align: 'left'
  },
  {
    id: 'status',
    label: 'Status',
    align: 'center'
  },
  {
    id: 'createdAt',
    label: 'Created At',
    align: 'center'
  },
  {
    id: 'updatedAt',
    label: 'Updated At',
    align: 'center'
  },
  {
    id: 'deleteAction',
    label: 'Actions',
    align: 'center'
  }
];

export default function Vendors(props) {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState(null);
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [vendorDetails, setVendorDetails] = useState('');

  // TODO
  // const breadCrumbsArray = [{ label: 'Home', href: 'dashboard' }];
  const breadCrumbsArray = [{ label: 'Home', href: `${AppConstants.baseURL}dashboard` }];

  //Call API here

  React.useEffect(() => {
    props.invalidateVendors();
    getVendors(rowsPerPage, offset);
  }, []);

  React.useEffect(() => {
    if (!isEmpty(props.vendors) && has(props.vendors, 'loading') && !props?.vendors?.loading) {
      setLoading(props.vendors.loading);
      let rows =
        props?.vendors?.all[offset] &&
        props?.vendors?.all[offset]?.rows &&
        props?.vendors?.all[offset]?.rows?.length
          ? getRowData(props.vendors.all[offset].rows)
          : [];
      setData(rows);
      setCount(props.vendors.all[offset].count);
    }
  }, [props.vendors]);

  const getVendors = (rowsPerPage, offset, status) => {
    setLoading(true);
    props.getVendors(rowsPerPage.toString(), offset.toString(), status).then(() => {});
  };

  const getRowData = (values) => {
    let vendorsRow = [];
    forEach(values, function (value, key) {
      if (!isEmpty(value)) {
        let rowData = createData(
          key + 1 + offset * rowsPerPage,
          value.name,
          value.status ? 'Active' : 'In-Active',
          (value.createdAt &&
            moment(value.createdAt).isValid() &&
            moment(value.createdAt).format('DD MMM YYYY hh:mm A')) ||
            null,
          (value.updatedAt &&
            moment(value.updatedAt).isValid() &&
            moment(value.updatedAt).format('DD MMM YYYY hh:mm A')) ||
            null,
          editAction(value)
        );
        vendorsRow.push(rowData);
      }
    });
    return vendorsRow;
  };

  const getStatusFilter = () => {
    return (
      <DropdownInput
        label={'Statuses'}
        value={status}
        dropdownValues={statusFilters}
        handleSelect={(status) => {
          applyStatusFilter(status);
        }}
      />
    );
  };

  const editAction = (data) => {
    return (
      <Tooltip TransitionComponent={Zoom} title='Edit' arrow={true}>
        <EditOutlinedIcon
          className={classes.editBtn}
          color={'primary'}
          onClick={() => {
            setVendorDetails(data);
            setIsEdit(true);
            setShowCreatePopup(true);
          }}
        />
      </Tooltip>
    );
  };

  const applyStatusFilter = (status) => {
    setStatus(status);
    setOffset(0);
    getVendors(rowsPerPage, 0, status);
    //apply status filter, call API
  };

  const handleChangePage = (newPage) => {
    //need to call API with new limit and offset
    setOffset(newPage);
    getVendors(rowsPerPage, newPage, status);
  };

  const handleChangeRowsPerPage = (value) => {
    //need to call API with new limit and offset
    setOffset(0);
    setRowsPerPage(parseInt(value, 10));
    getVendors(parseInt(value, 10), 0, status);
  };

  return (
    <>
      <BreadCrumbs linksArray={breadCrumbsArray} current={'Vendors'} />
      <div className={classes.heading}>
        <Typography variant='h5' component='h5'>
          {'Vendors'}
        </Typography>
      </div>
      <Button
        label={'Add New Vendor'}
        color={'primary'}
        variant={'contained'}
        onClick={() => {
          setShowCreatePopup(true);
        }}
        className={classes.customBtnStyle}
        startIcon={<ControlPointIcon />}
      />
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item lg={3} md={4} sm={4} xs={10}>
            {getStatusFilter()}
          </Grid>
        </Grid>
      </Box>
      <Table
        headCells={headCells}
        rowData={data}
        totalCount={count}
        rowsPerPage={rowsPerPage}
        page={offset}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        loading={loading}
      />
      {showCreatePopup && (
        <CreateVendor
          open={showCreatePopup}
          title={isEdit ? 'Edit Vendor' : 'Add Vendor'}
          label={'Statuses'}
          isEdit={isEdit}
          details={isEdit ? vendorDetails : null}
          pageDetails={{ rowsPerPage, offset, getVendors }}
          handleClose={() => {
            setShowCreatePopup(false);
            setIsEdit(false);
            setVendorDetails(null);
          }}
        />
      )}
    </>
  );
}
