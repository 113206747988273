import { connect } from 'react-redux';
import { compose } from 'redux';

import { getOverallPaymentLogs as getOverallPaymentLogsAPI } from '../../../actions/admin';

const mapStateToProps = (state) => {
  return {
    overallPaymentLogs: state.overallPaymentLogs
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOverallPaymentLogs: (limit, offset, packageType) =>
      dispatch(getOverallPaymentLogsAPI(limit, offset, packageType))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
