import * as Yup from 'yup';

function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg || 'Path must be the same as Reference',
    params: {
      reference: ref.path
    },
    test: function(value) {
      return value === this.resolve(ref);
    }
  });
}
Yup.addMethod(Yup.string, 'equalTo', equalTo);

export default Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password must be atleast 8 characters')
    .required('Password cannot be blank'),
  confirmPassword: Yup.string()
    .equalTo(Yup.ref('password'), 'Passwords must match')
    .required('Password cannot be blank')
});
