import { connect } from 'react-redux';
import { getBrandSettings, editBrandSettings } from '../../../../actions/company';

const mapStateToProps = (state) => {
  return {
    brandSettings: state.brandSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBrandSettings: (data) => dispatch(getBrandSettings(data)),
    editBrandSettings: (data) => dispatch(editBrandSettings(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
