import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Field, ErrorMessage, isEmptyArray } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  CardActions,
  Card,
  CardContent,
  Input
} from '@material-ui/core';

import { validDocTypes } from '../../../utils/Utlities';
import { getFileMimeType } from '../frontend-common/core-components/Utilities/utilities';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2)
  },
  documentPaper: {
    margin: theme.spacing(2),
    display: 'flex'
  },
  documentCard: {
    margin: theme.spacing(1)
  },
  error: {
    fontSize: '12px',
    color: '#f44336',
    margin: 0
  }
}));

export default (props) => {
  const classes = useStyles();

  const DOCUMENT_TYPES = [
    { label: 'Master Service Agreement', value: 'master_service_agreement' },
    { label: 'Letter Of Authorization', value: 'letter_of_authorization' },
    { label: 'Letter of Consent', value: 'letter_of_consent' },
    { label: 'Pricing', value: 'pricing' },
    { label: 'Scope Of Work', value: 'scope_of_work' },
    { label: 'Other', value: 'other' }
  ];
  const [documentError, setDocumentError] = useState(false);
  const handleFileChange = (e, agreementIndex, docIndex, fieldName) => {
    if (fieldName === 'file') {
      const blob = e.currentTarget.files[0];
      let fileType = '';
      var fileReader = new FileReader();
      fileReader.onloadend = () => {
        var arr = new Uint8Array(fileReader.result).subarray(0, 4);
        var header = '';
        for (var i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }
        // Check the file signature against known types
        fileType = getFileMimeType(header, blob);
        if (validDocTypes.agreement.includes(fileType)) {
          props.documentsChange(blob, agreementIndex, docIndex, fieldName);
          setDocumentError(false);
        } else {
          setDocumentError(true);
        }
      };
      if (blob instanceof Blob) {
        {
          /** To avoid TyperError issue reported by sentry, need to check if param is an Blob type or not */
        }
        fileReader.readAsArrayBuffer(blob);
      } else {
        console.error(
          `Failed to execute 'readAsArrayBuffer' on 'FileReader': parameter is not of type 'Blob'`
        );
      }
    } else {
      props.documentsChange(e, agreementIndex, docIndex, fieldName);
    }
  };

  return (
    <Grid
      item
      xl={4}
      md={4}
      lg={4}
      sm={4}
      key={`document_container_${props.agreementIndex}_${props.docIndex}`}
    >
      <Card variant='outlined' className={classes.documentCard}>
        <CardContent>
          <Grid item>
            <FormControl className={classes.formControl} style={{ width: '100%' }}>
              <InputLabel id='document-type'>Document Type</InputLabel>
              <Select
                labelId='document-type-label'
                value={props.documentData.documentType}
                onChange={(e) => {
                  handleFileChange(
                    e.target.value,
                    props.agreementIndex,
                    props.docIndex,
                    'documentType'
                  );
                }}
                displayEmpty
              >
                {DOCUMENT_TYPES.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <input
            accept={validDocTypes.agreement}
            type='file'
            onChange={(e) => handleFileChange(e, props.agreementIndex, props.docIndex, 'file')}
          />
          {documentError && (
            <div className={classes.error}>
              Please provide a valid agreement format ({validDocTypes.agreement})
            </div>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};
