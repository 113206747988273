import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, has } from 'lodash';
import { toast } from 'react-toastify';

import validationSchema from './CreditLimit.validation';

import { updateCompanyCreditLimit as updateCompanyCreditLimitAPI } from '../../../../../../api/admin';

import {
  getCompanyCreditBalance as getCompanyCreditBalanceAPI,
  getCompanyRechargeLogs as getCompanyRechargeLogsAPI,
  getPostpaidCreditCompany as getPostpaidCreditCompanyAPI
} from '../../../../../../actions/admin';

import { errToastMessage, getErrorMessage } from '../../../../../../utils/Utlities';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyCreditBalance: (companyId) => dispatch(getCompanyCreditBalanceAPI(companyId)),
    getCompanyRechargeLogs: (companyId, limit, offset) =>
      dispatch(getCompanyRechargeLogsAPI(companyId, limit, offset)),
    getPostpaidCreditCompany: (companyId) => dispatch(getPostpaidCreditCompanyAPI(companyId))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        companyId: '',
        isUnlimited: false,
        creditLimit: '',
        disabled: false,
        errorHandler: ''
      };

      let propsValue = {};

      if (has(props, 'companyId')) {
        propsValue.companyId = props.companyId;
      } else {
        propsValue = {};
      }

      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    validationSchema,
    enableReinitialize: true,
    handleSubmit: (values, { setFieldError, setSubmitting, resetForm, props }) => {
      values.creditLimit = values.isUnlimited ? 'UNLIMITED' : parseInt(values.creditLimit);
      const packageType = new URLSearchParams(document.location.search).get('type') || null;
      if (values.creditLimit === 0) {
        setFieldError('creditLimit', 'Please enter valid credit limit');
      } else {
        updateCompanyCreditLimitAPI(values)
          .then(() => {
            setSubmitting(false);
            toast.info('Credits Limits updated successfully');
            packageType === 'PREPAID'
              ? props.getCompanyCreditBalance({ companyId: props.companyId, onlyNegative: false })
              : props.getPostpaidCreditCompany(props.companyId);
            props.setCreditLimit(values.creditLimit);
            resetForm();
            props.handleClose();
          })
          .catch((error) => {
            console.log(error);
            errToastMessage(error);
            resetForm();
            values.errorHandler();
          });
      }
    },
    displayName: 'Update Balance'
  })
);
