import React from 'react';
import { ICONS } from './Icons';

export default function Icon(props) {
  const styles = {
    svg: {
      display: 'inline-block',
      verticalAlign: 'middle',
      cursor: 'pointer'
    },
    path: {
      fill: props.color
    }
  };

  return (
    <svg
      style={props.style ? { ...styles.svg, ...props.style } : { ...styles.svg }}
      width={`${props.width || props.size}px`}
      height={`${props.height || props.size}px`}
      onClick={props.onClick}
      data-testid='icon-svg'
    >
      <path style={styles.path} d={ICONS[props.icon]} />
    </svg>
  );
}

Icon.defaultProps = {
  width: 20, //width of icon
  height: 20, //height of icon
  size: 20, //size of icon, applied only in case when height width is not provided
  color: '#AAAAAA', //color of icon
  icon: '', //name of icon present in Icons.js file
  onClick: null //onClick function
};
