import React, { useState, useEffect } from 'react';
import { forEach, isEmpty, has } from 'lodash';

import { Grid, Typography, Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Field } from 'formik';
import { errToastMessage, getTrimmedValue } from '../../../utils/Utlities';
import BreadCrumbs from '../../../core-components/BreadCrumbs';
import AppConstants from '../../../core-components/AppConstants';
import Button from '../../../core-components/Button';
import Table from '../../../core-components/Table';
import {
  getCompanyById as getCompanyByIdAPI,
  deleteCategories as deleteCategoriesAPI
} from '../../../api/admin';
import { getErrorMessage } from '../../../utils/Utlities';
import SearchInput from '../../../core-components/SearchInput';
import ConfirmationPopup from '../../../core-components/ConfirmationPopup';
import CreateEditDialog from '../../../core-components/CreateEditDialog';
import FormInput from '../../../core-components/FormInput';

const useStyles = makeStyles((theme) => ({
  createCandBtn: {
    borderRadius: 5,
    color: '#ffffff',
    borderColor: '#0277bd',
    backgroundColor: '#1976d2',
    fontSize: 14,
    marginTop: 16,
    '&:hover': {
      borderColor: '#0277bd',
      backgroundColor: '#1565c0'
    }
  },
  customBtnStyle: {
    margin: '16px 0px 0px'
  }
}));

const createData = (count, categoryName, actions) => {
  return { count, categoryName, actions };
};

const headCells = [
  {
    id: 'count',
    label: '#',
    align: 'left'
  },
  {
    id: 'categoryName',
    label: 'Categories',
    align: 'left'
  },
  {
    id: 'actions',
    label: 'Actions',
    align: 'center'
  }
];

export default (props) => {
  const classes = useStyles();
  const companyId = new URLSearchParams(document.location.search).get('company_id') || null;
  const [companyName, setCompanyName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [categoryId, setCategoryId] = useState(null);
  const [categoryName, setCategoryName] = useState('');
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('Create Category');
  const [isEdit, setIsEdit] = useState(false);
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);
  const [initCategory, setInitCategory] = useState('');

  const breadCrumbsArray = [
    { label: 'Home', href: `${AppConstants.baseURL}dashboard` },
    // { label: 'Companies', href: `${AppConstants.baseURL}company/index` },
    { label: 'Companies', href: `${AppConstants.baseURL}companies` },
    { label: companyName, href: `${AppConstants.baseURL}company/view?id=${companyId}` },
    { label: 'Admins', href: `${AppConstants.baseURL}company/admins?id=${companyId}` }
  ];

  // Component Did Mount to fetch categories and company details
  useEffect(() => {
    setLoading(true);
    getCompanyByIdAPI(companyId)
      .then((response) => {
        setCompanyName(response.data.data.name);
        getCategories(companyId, rowsPerPage, offset, null);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        errToastMessage(error);
      });

    props.setFieldValue('handleAfterSubmit', handleAfterSubmit);
  }, []);

  //when there will be change in company categories
  useEffect(() => {
    if (
      !isEmpty(props.companyCategories) &&
      has(props.companyCategories, 'loading') &&
      props?.companyCategories?.loading === false
    ) {
      let rowsData =
        (props?.companyCategories &&
          props?.companyCategories?.all &&
          props?.companyCategories?.all?.length &&
          props?.companyCategories?.all[offset]?.rows &&
          getRowData(props.companyCategories.all[offset].rows)) ||
        [];

      setData(rowsData);
      setCount(
        props.companyCategories.all[offset] && props.companyCategories.all[offset].count
          ? props.companyCategories.all[offset].count
          : 0
      );
    }
  }, [props.companyCategories]);

  //get categories function
  const getCategories = (companyId, limit, offset, categoryName) => {
    setLoading(true);
    props.invalidateCompanyCategories();
    props
      .getCompanyCategories(companyId, limit, offset, categoryName)
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getRowData = (values) => {
    let categoryRow = [];
    forEach(values, function (value, key) {
      if (!isEmpty(value)) {
        let rowData = createData(
          key + 1 + offset * rowsPerPage,
          value.categoryName || '',
          buttonActions(companyId, value.id, value.categoryName)
        );
        categoryRow.push(rowData);
      }
    });
    return categoryRow;
  };

  const buttonActions = (companyId = null, categoryId = null, categoryName = null) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <IconButton
          aria-label='delete'
          color='primary'
          onClick={() => {
            handleEdit(categoryId, categoryName);
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label='delete'
          color='secondary'
          onClick={() => {
            setCategoryId(categoryId);
            setShowConfirmationPopup(true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    );
  };

  const getNameFilter = () => {
    return (
      <SearchInput
        placeholder={'Search by Name'}
        value={categoryName}
        handleSearch={(name) => {
          applyNameFilter(name);
        }}
      />
    );
  };

  const applyNameFilter = (name) => {
    setCategoryName(name);
    setOffset(0);
    getCategories(companyId, rowsPerPage, 0, name);
  };

  const handleChangePage = (newPage) => {
    setOffset(newPage);
    getCategories(companyId, rowsPerPage, newPage, categoryName);
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    getCategories(companyId, parseInt(value, 10), offset, categoryName);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setInitCategory('');
    setIsEdit(false);
    setDialogTitle('Create Category');
    props.setFieldValue('categoryName', '');
    setFieldValue('isEdit', false);
  };

  const handleAfterSubmit = () => {
    handleCloseDialog();
    setOffset(0);
    getCategories(companyId, rowsPerPage, 0, null);
  };

  const handleDelete = () => {
    if (deleteButtonDisabled) {
      return;
    }

    setDeleteButtonDisabled(true);

    deleteCategoriesAPI(companyId, categoryId)
      .then((response) => {
        setCategoryId(null);
        setShowConfirmationPopup(false);
        getCategories(companyId, rowsPerPage, offset, null);
        toast.success('Category Deleted Successfully');
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      })
      .finally(() => {
        setTimeout(() => {
          setDeleteButtonDisabled(false);
        }, 100);
      });
  };

  const handleEdit = (categoryId, categoryName) => {
    setIsEdit(true);
    setDialogTitle('Edit Category');
    setFieldValue('categoryName', categoryName);
    setInitCategory(categoryName);
    setFieldValue('categoryId', categoryId);
    setFieldValue('isEdit', true);
    setTimeout(() => {
      props.setTouched({ categoryName: true });
      setOpenDialog(true);
    }, 400);
  };

  const handleCategoryInputChange = (e) => {
    e.preventDefault();
    setFieldValue('categoryName', e.target.value);
    props.setSubmitting(false);
    setTimeout(() => {
      props.setTouched({ categoryName: true });
    }, 400);
  };

  const { isSubmitting, handleSubmit, values, setFieldValue, setFieldError } = props;
  const isValidForm =
    !isSubmitting &&
    !!values.categoryName &&
    isEmpty(props?.errors) &&
    initCategory !== values?.categoryName?.trim();

  return (
    <>
      <BreadCrumbs linksArray={breadCrumbsArray} current={'Manage Categories'} />
      <Typography variant={'h6'} gutterBottom>
        {companyName}
      </Typography>
      <Button
        label={'Create Category'}
        color={'primary'}
        variant={'contained'}
        onClick={() => {
          setFieldValue('isEdit', false);
          props.setTouched({ categoryName: false });
          setOpenDialog(true);
        }}
        className={classes.customBtnStyle}
      />
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item lg={2} md={4} sm={4} xs={10}>
            {getNameFilter()}
          </Grid>
        </Grid>
      </Box>
      <Table
        headCells={headCells}
        rowData={data}
        totalCount={count}
        rowsPerPage={rowsPerPage}
        page={offset}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        loading={loading}
      />
      <ConfirmationPopup
        open={showConfirmationPopup}
        title={'Delete Category'}
        content={'Are you sure want to delete category ?'}
        handleSubmit={() => {
          handleDelete();
        }}
        handleClose={() => {
          setShowConfirmationPopup(false);
        }}
        buttonLabel={'Delete'}
        buttonDisabled={deleteButtonDisabled}
      />
      {openDialog ? (
        <CreateEditDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          title={dialogTitle}
          maxWidth={'sm'}
          edit={isEdit}
          handleSubmit={handleSubmit}
          enableBtn={isValidForm}
          categoryName={values.categoryName}
        >
          <Field
            type='text'
            name='categoryName'
            requiredField={'true'}
            label='Category Name'
            onChange={handleCategoryInputChange}
            onBlur={(e) => {
              const trimmedValue = getTrimmedValue(e.target.value, true);
              setFieldValue('categoryName', trimmedValue);
            }}
            component={FormInput}
          />
        </CreateEditDialog>
      ) : null}
    </>
  );
};
