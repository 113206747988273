import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Field, ErrorMessage, isEmptyArray } from 'formik';
import { Grid } from '@material-ui/core';
import { getTrimmedValue } from '../../../../utils/Utlities';
import Button from '../../../../core-components/Button';
import FormInput from '../../../../core-components/FormInput';
import Dropdown from '../../../../core-components/DropdownInput';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, title, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant='h6'>{title} Services</Typography>
            {onClose ? (
                <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default (props) => {
    const statusValues = [
        { label: 'Pending', value: 0 },
        { label: 'Active', value: 1 },
        { label: 'Custom-Service', value: 2 },
        { label: 'Automated-Service', value: 3 },
    ];
    const {
        handleSubmit,
        isSubmitting,
        handleBlur,
        values,
        dirty,
        isValid,
        errors,
        setFieldValue,
        setFieldError,
        setTouched,
        touched,
        setFieldTouched,
        setErrors,
    } = props;

    const handleDropdown = (value) => {
        setFieldValue('status', value);
    };

    return (
        <div>
            <Dialog onClose={props.handleClose} aria-labelledby='customized-dialog-title' open={props.open}>
                <DialogTitle id='customized-dialog-title' title={props.title} onClose={props.handleClose}></DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Field
                                    type='text'
                                    name='name'
                                    fullWidth={false}
                                    requiredfield={'true'}
                                    label='Service Name'
                                    component={FormInput}
                                    onBlur={(e) => {
                                        const trimmedValue = getTrimmedValue(e.target.value, true);
                                        setFieldValue('name', trimmedValue);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    type='text'
                                    name='price'
                                    label='Price'
                                    fullWidth={false}
                                    requiredfield={'true'}
                                    component={FormInput}
                                    onBlur={(e) => {
                                        const trimmedValue = getTrimmedValue(e.target.value);
                                        setFieldValue('price', trimmedValue);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Dropdown
                                    dropdownValues={statusValues}
                                    name='status'
                                    label={'Status'}
                                    value={props.values.status}
                                    handleSelect={(value) => {
                                        handleDropdown(value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color={'green'} label={'Save'} disabled={!dirty || !isValid} size={'large'}></Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
