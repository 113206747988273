import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import { Typography, Grid, Card, CardContent, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CreditCardIcon from '@material-ui/icons/CreditCard';
import EditIcon from '@material-ui/icons/Edit';

import CreditLimit from './CreditLimit';
import UpdateBalance from './UpdateBalance';
import UnbilledCandidates from './../UnbilledCandidates';
import {
  getCreditInRupee,
  validateOperation,
  errToastMessage,
  convertToIndianNumeration
} from '../../../../../utils/Utlities';
import AppConstants from '../../../../../core-components/AppConstants';
import { getAccessLevel } from '../../../../../api/storage';

import DeductionTransaction from '../../Prepaid/Balances/DeductionTransaction';
import AppHelper from '../../../../../core-components/AppHelper';

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    paddingLeft: '8px'
  },
  balanceContainer: {
    display: 'flex',
    marginBottom: '25px'
  },
  viewCandCard: {
    backgroundColor: '#ebecf1'
  },
  viewCandContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  viewCandButton: {
    marginLeft: '144px'
  },
  bold: {
    fontWeight: 'bold'
  },
  redashLink: {
    textDecoration: 'none',
    fontSize: '14px'
  },
  rupeeSymbol: {
    fontFamily: 'Roboto'
  },
  costContainer: {
    display: 'flex'
  },
  rupeeSymbol: {
    fontFamily: 'Roboto'
  }
}));

export default function Balances(props) {
  const classes = useStyles();
  const [updateBalanceModal, setUpdateBalanceModal] = useState(false);
  const [openUnbilledModal, setOpenUnbilledModal] = useState(false);
  const [creditModal, setCreditModal] = useState(false);
  const [creditLimit, setCreditLimit] = useState(props.credits.creditLimit);
  const [dedecutionTransactionModal, setDedecutionTransactionModal] = useState(false);
  const companyId = new URLSearchParams(document.location.search).get('id');
  useEffect(() => {
    if (props.credits.creditLimit !== creditLimit) {
      setCreditLimit(props.credits.creditLimit);
    }
  }, [props.credits.creditLimit]);

  const showUnBilledCandidates = () => {
    setOpenUnbilledModal(!openUnbilledModal);
  };

  const balanceModalAction = () => {
    setUpdateBalanceModal(!updateBalanceModal);
  };
  const creditModalAction = () => {
    if (validateOperation(props.profile)) {
      setCreditModal(!creditModal);
    } else {
      errToastMessage('Only admin is allowed to perform this operation');
    }
  };
  const accessLevelCheck = () => {
    const accessLevel = getAccessLevel();
    if (accessLevel == 11) {
      errToastMessage('Only admin is allowed to perform this operation');
    } else {
      window.open(
        `${AppConstants.baseURL}postpaid/unbilled/candidates?id=${props.companyId}`,
        '_self'
      );
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Button
          color='primary'
          onClick={() => {
            if (validateOperation(props.profile)) {
              setDedecutionTransactionModal(true);
            } else {
              errToastMessage('Only admin is allowed to perform this operation');
            }
          }}
        >
          Create Deduction Transaction in Usage Log
        </Button>

        <Grid container item md={12}>
          <Grid item md={6} className={classes.titleContainer}>
            <div className={classes.balanceContainer}>
              <Grid item md={6}>
                <Typography className={classes.costContainer} variant='subtitle1'>
                  <span>{'Available Credit Balance:'} </span>
                  &nbsp;
                  {creditLimit === 'UNLIMITED'
                    ? 'Unlimited'
                    : getCreditInRupee(props?.credits?.totalAvailableBalance, 0)}
                </Typography>
                <Typography variant='subtitle1'>
                  {'Total Unpaid Amount:'}{' '}
                  {getCreditInRupee(Math.abs(props?.credits?.totalUnpaidAmount, 0))}
                </Typography>
                <Typography variant='subtitle1'>
                  {'Total Unbilled Amount:'}{' '}
                  {getCreditInRupee(Math.abs(props?.credits?.totalUnbilledAmount, 0))}
                </Typography>{' '}
              </Grid>
              <Grid item md={6}>
                <Typography className={classes.costContainer} variant='subtitle1'>
                  <span>{'Lifetime Alumni Credits Earned:'} </span>
                  &nbsp; <span className={classes.rupeeSymbol}>&#8377;</span>
                  {convertToIndianNumeration(props?.credits?.totalAlumniCredits)}
                </Typography>
                <Typography variant='subtitle1'>
                  {'Lifetime Alumni Credits Redeemed:'}{' '}
                  <span className={classes.rupeeSymbol}>&#8377;</span>
                  {convertToIndianNumeration(
                    parseInt(props?.credits?.totalAlumniCredits) -
                      parseInt(props?.credits?.availableAlumniCredits)
                  )}
                </Typography>
                <Typography variant='subtitle1' className={classes.bold}>
                  {'Alumni Credits Available:'} <span className={classes.rupeeSymbol}>&#8377;</span>
                  {convertToIndianNumeration(parseInt(props?.credits?.availableAlumniCredits))}
                </Typography>
                {AppHelper.isProduction() && (
                  <a
                    aria-disabled={true}
                    href={`https://app.appsmith.com/app/springverify-india/credit-transactions-6672cac6269b6c5f9873bec6?companyId=${props.companyId}`}
                    target='_blank'
                    className={classes.redashLink}
                  >
                    See more details
                  </a>
                )}
              </Grid>
            </div>
            <Card className={classes.viewCandCard}>
              <CardContent className=''>
                <Typography variant='subtitle1'>
                  Cases eligible for billing: {Math.abs(props?.credits?.candidatesNotBilled) || 0}
                </Typography>
                <Button color='primary' className='' onClick={() => accessLevelCheck()}>
                  View Cases
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid item md={6} className=''>
          <Card className={classes.viewCandCard}>
            <CardContent className={classes.viewCandContainer}>
              <CreditCardIcon style={{ marginRight: '4px' }} />
              <Typography variant='subtitle1'>
                Credit limit (total): {getCreditInRupee(props.credits.creditLimit, 0)}{' '}
              </Typography>
              <IconButton
                onClick={creditModalAction}
                size='small'
                className={classes.viewCandButton}
              >
                <EditIcon />
              </IconButton>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {creditModal ? (
        <CreditLimit
          companyId={props.companyId}
          open={creditModal}
          balance={props.credits.totalAvailableBalance || 0}
          handleClose={creditModalAction}
          setCreditLimit={(limit) => setCreditLimit(limit)}
        />
      ) : null}

      {updateBalanceModal ? (
        <UpdateBalance
          companyId={props.companyId}
          open={updateBalanceModal}
          creditLimit={
            creditLimit === 'UNLIMITED'
              ? 'Unlimited'
              : creditLimit -
                  (props.credits.totalUnbilledAmount + Number(props.credits.totalUnpaidAmount)) || 0
          }
          handleClose={balanceModalAction}
        />
      ) : null}

      {openUnbilledModal ? (
        <UnbilledCandidates
          open={openUnbilledModal}
          companyId={props.companyId}
          handleClose={showUnBilledCandidates}
        />
      ) : null}
      {dedecutionTransactionModal ? (
        <DeductionTransaction
          open={dedecutionTransactionModal}
          title='Deduction Transaction in Usage Log'
          handleClose={() => setDedecutionTransactionModal(false)}
          companyId={props.companyId}
        />
      ) : null}
    </>
  );
}
