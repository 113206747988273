import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import './editor.css';

const Editor = ({ chartOptions, setChartOptions, defaultOptions }) => {
  const keyHeader = {
    height: 'Height',
    columnWidth: 'Column Width',
    datalabels: 'Data Labels',
    yAxisTitle: 'Y Axis Title',
    fillOpacity: 'Fill Opacity',
    fillColors: 'Fill Colors',
    width: 'Width',
    backgroundColor: 'Background Color',
    chartTitle: 'Chart Title'
  };

  const keyMinMax = {
    height: { min: 100, max: 600 },
    columnWidth: { min: 30, max: 100 },
    fillOpacity: { min: 0, max: 1 },
    width: { min: 50, max: 100 }
  };

  const handleChange = (property, value) => {
    setChartOptions({ ...chartOptions, [property]: value });
  };

  const handleUndo = (propertyName) => {
    setChartOptions({
      ...chartOptions,
      [propertyName]: defaultOptions[propertyName]
    });
  };

  const handleFillColorChange = (e, index) => {
    const colors = [...chartOptions.fillColors];
    colors[index] = e.target.value;
    handleChange('fillColors', colors);
  };

  const excludedKeys = ['fillColors'];

  return (
    <div className='editor'>
      <h2>
        Chart Editor{' '}
        <button onClick={() => setChartOptions({ ...defaultOptions })} className='masterUndo'>
          <FontAwesomeIcon icon={faUndo} />
        </button>
      </h2>
      {Object.keys(defaultOptions).map((key) => {
        if (excludedKeys.includes(key)) {
          return null;
        }
        return (
          <div key={key} className='input-container'>
            <label>{keyHeader[key]}:</label>
            {typeof chartOptions[key] === 'boolean' ? (
              <input
                type='checkbox'
                checked={chartOptions[key]}
                onChange={(e) => handleChange(key, e.target.checked)}
              />
            ) : typeof chartOptions[key] === 'number' ? (
              <input
                type='range'
                min={keyMinMax[key].min}
                max={keyMinMax[key].max}
                step={key !== 'fillOpacity' ? 1 : 0.1}
                value={chartOptions[key]}
                onChange={(e) =>
                  handleChange(
                    key,
                    key === 'height' || key === 'width'
                      ? parseInt(e.target.value)
                      : parseFloat(e.target.value)
                  )
                }
              />
            ) : key === 'backgroundColor' ? (
              <input
                type='color'
                value={chartOptions[key]}
                onChange={(e) => handleChange(key, e.target.value)}
              />
            ) : (
              <input
                type='text'
                value={chartOptions[key]}
                onChange={(e) => handleChange(key, e.target.value)}
              />
            )}
            {typeof chartOptions[key] === 'number' ? <span>{chartOptions[key]}</span> : null}
            <button onClick={() => handleUndo(key)} className='undoButton'>
              <FontAwesomeIcon icon={faUndo} />
            </button>
          </div>
        );
      })}
      <div className='input-container' style={{ display: 'flex' }}>
        <label>Fill Colors:</label>
        <div className='colors-container'>
          {chartOptions.fillColors.map((color, index) => (
            <div key={index} className='color-input'>
              <input type='color' value={color} onChange={(e) => handleFillColorChange(e, index)} />
            </div>
          ))}
        </div>
        <button onClick={() => handleUndo('fillColors')} className='undoButton'>
          <FontAwesomeIcon icon={faUndo} />
        </button>
      </div>
    </div>
  );
};

export default Editor;
