import React, { useEffect, useState } from 'react';
import { isEmpty, camelCase, _ } from 'lodash';
import { Field } from 'formik';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Grid,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Button,
  RadioGroup,
  Radio,
  FormControl,
  Switch,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { truncateString } from '../../../../utils/Utlities';

import FormInput from '../../../../core-components/FormInput';
import BreadCrumbs from '../../../../core-components/BreadCrumbs';
import AppConstants from '../../../../core-components/AppConstants';
import InputWithIncrement from '../../../../core-components/Packages/InputWithIncrement';
import { PLAIN_CHECK_ICONS } from '../../../../core-components/CommonConstants';
import ModalWithInput from '../../../../core-components/ModalWithInput';
import {
  getErrorMessage,
  getCustomCheckSVG,
  getTrimmedValue,
  convertToIndianNumeration,
  errToastMessage
} from '../../../../utils/Utlities';
import useDebounce from '../../../../core-components/debounce';

import { getPackagePresent } from '../../../../api/admin';

import { configValues as config } from './configBase';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex'
  },
  paper: {
    padding: theme.spacing(4),
    margin: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  formControl: {
    marginBottom: theme.spacing(3)
  },
  activeBtn: {
    margin: theme.spacing(1),
    height: '44px',
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  disabledBtn: {
    cursor: 'not-allowed',
    margin: theme.spacing(1),
    height: '44px',
    color: '#dfdfdf',
    borderColor: '#d9d9d9',
    backgroundColor: '#f5f5f5',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  booleanField: {
    marginLeft: '22px'
  },
  saveButtonGrid: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  customCheckButton: {
    fontSize: 12,
    padding: '0px 0px'
  },
  progressContainer: {
    textAlign: 'center',
    width: '100%'
  },
  eduOff: {
    borderTop: '1px dashed #b8b0b0',
    borderLeft: '1px dashed #b8b0b0',
    borderRight: '1px dashed #b8b0b0'
  },
  edureg: {
    borderLeft: '1px dashed #b8b0b0',
    borderRight: '1px dashed #b8b0b0'
  },
  eduHyb: {
    borderBottom: '1px dashed #b8b0b0',
    borderLeft: '1px dashed #b8b0b0',
    borderRight: '1px dashed #b8b0b0'
  },
  basePriceContainer: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  rupeeSymbol: {
    paddingBottom: '23px',
    paddingRight: '0px',
    fontFamily: 'Roboto'
  }
}));

export default (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [packageNameTerm, setPackageNameTerm] = useState('');
  const [emptyPackageName, setEmptyPackageName] = useState(false);
  const [validPackageName, setValidPackageName] = useState(false);
  const [numberOfChecksSelected, setNumberOfChecksSelected] = useState(0);
  const [checksSelected, setChecksSelected] = useState([]);

  const updateChecksSelected = (value, index) => {
    const updatedChecksSelected = [...checksSelected];
    updatedChecksSelected[index] = value;
    setChecksSelected(updatedChecksSelected);
    const countGreaterThanZero = updatedChecksSelected.filter((val) => val > 0).length;
    setNumberOfChecksSelected(countGreaterThanZero);
  };

  const warningBox = (text) => {
    return (
      <Typography style={{ color: 'red', fontWeight: '500' }} align='right'>
        {text}
      </Typography>
    );
  };

  const breadCrumbsArray = [
    { label: 'Home', href: `${AppConstants.baseURL}dashboard` },
    {
      label: 'Packages',
      href: `${AppConstants.baseURL}package/list`
    }
  ];

  const {
    handleSubmit,
    isSubmitting,
    handleBlur,
    values,
    errors,
    setFieldValue,
    setFieldError,
    setTouched,
    touched,
    setFieldTouched,
    setErrors
  } = props;

  const getBooleanField = (value, index) => {
    return (
      <Box className={classes.booleanField}>
        <FormControlLabel
          value={values[value.check.toLowerCase()] || false}
          control={<Checkbox color='primary' />}
          label={'Enabled'}
          onChange={(e) => {
            setFieldValue(value.check.toLowerCase(), e.target.checked);
            updateChecksSelected(e.target.checked, index);
          }}
          labelPlacement='end'
        />
      </Box>
    );
  };

  const getCustomCheckBooleanField = () => {
    return (
      <Box className={classes.booleanField}>
        <FormControlLabel
          disabled
          control={<Checkbox color='primary' checked />}
          label={'Enabled'}
          onChange={(e) => {}}
          labelPlacement='end'
        />
      </Box>
    );
  };

  const addCustomCheck = (value) => {
    let customCheckCopy = [...values.customCheckNames];
    if (
      value &&
      !customCheckCopy.some((c) => c?.checkName?.toLowerCase() === value.checkName?.toLowerCase())
    ) {
      customCheckCopy.push(value);
      setFieldValue('customCheckNames', customCheckCopy);
      setFieldValue('custom', true);
      setOpenDialog(false);
    } else {
      errToastMessage('Check already exists');
    }
  };

  const delCustomCheck = (index) => {
    let customCheckCopy = [...values.customCheckNames];
    customCheckCopy.splice(index, 1);
    setFieldValue('customCheckNames', customCheckCopy);
  };

  const handlePackagePresent = (e) => {
    processChange(e.target.value);
  };

  const processChange = debounce((val) => saveInput(val));

  function debounce(func, timeout = 2000) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  function saveInput(value) {
    getPackagePresent(value)
      .then((res) => {
        let val = res.data;
        setFieldValue('packageNameAvailable', val.isAvailable);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function searchPackageCharacters(search) {
    return getPackagePresent(search);
  }

  const debouncedPackagePresentSearch = useDebounce(packageNameTerm, 1000);

  const packageNameOnChange = (e) => {
    const trimmedValue = getTrimmedValue(e.target.value, true);
    e.target.value = trimmedValue || '';
    setFieldValue('name', trimmedValue);
    setPackageNameTerm(e.target.value);
    if (e.target.value.length === 0) {
      setEmptyPackageName(true);
      setValidPackageName(false);
    } else {
      setEmptyPackageName(false);
      if (/^[^-\s][a-zA-Z0-9_\s-\+\-\=\.\,\:\(\)\[\]\<\>\&\#\!\|\"\/\\]+$/.test(e.target.value)) {
        setValidPackageName(false);
      } else {
        setValidPackageName(true);
      }
    }
  };

  useEffect(() => {
    if (debouncedPackagePresentSearch) {
      searchPackageCharacters(debouncedPackagePresentSearch)
        .then((response) => {
          let flag = response.data;
          setFieldValue('packageIsAvailable', flag.isAvailable);
        })
        .catch((error) => console.error(error));
    }
  }, [debouncedPackagePresentSearch]);

  const isValidForm = !isSubmitting && isEmpty(errors) && values.packageIsAvailable;

  const pkgConfigurationForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <Field
          type='text'
          name='name'
          requiredfield={'true'}
          label='Package Name'
          onBlur={(e) => packageNameOnChange(e)}
          component={FormInput}
        />
        {!values.packageIsAvailable ? (
          <Typography variant={'caption'} style={{ color: 'red' }}>
            {' '}
            Package Already Exists!{' '}
          </Typography>
        ) : null}
        {emptyPackageName ? (
          <Typography variant={'caption'} style={{ color: 'red' }}>
            {' '}
            Please enter a Package name{' '}
          </Typography>
        ) : null}
        {validPackageName ? (
          <Typography variant={'caption'} style={{ color: 'red' }}>
            {' '}
            Please enter a Valid Package name{' '}
          </Typography>
        ) : null}
        <Field
          type='text'
          name='subtypeName'
          requiredfield={'true'}
          label='Default Subtype Name'
          component={FormInput}
          onBlur={(e) => {
            const trimmedValue = getTrimmedValue(e.target.value, true);
            setFieldValue('subtypeName', trimmedValue);
          }}
        />
        <Box mt={2}>
          <Typography variant='h6' gutterBottom>
            {'Configure Package'}
          </Typography>
        </Box>

        <Paper className={classes.paper} variant='outlined' elevation={2}>
          {config &&
            config?.checksConfig &&
            config?.checksConfig.map((value, index) => {
              return (
                <Grid
                  className={classes.root}
                  item
                  container
                  spacing={1}
                  key={`check_grid_${index}`}
                >
                  <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    <Typography variant='h6' style={{ fontSize: '18px' }} gutterBottom>
                      <span
                        style={{ marginRight: '10px' }}
                        title={value.title || 'Check name'}
                        key={`selected_checks_span_${index}`}
                      >
                        <FontAwesomeIcon
                          icon={
                            PLAIN_CHECK_ICONS[value.check.toLowerCase()]
                              ? PLAIN_CHECK_ICONS[value.check.toLowerCase()].icon
                              : faBan
                          }
                          key={`check_icons_${index}`}
                          color='#222'
                        />
                      </span>
                      {value.title || 'Check name'}
                    </Typography>
                  </Grid>
                  <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    {value.type === 'integer' ? (
                      <Field
                        name={value.check ? camelCase(value.check.toLowerCase()) : 'checkName'}
                        component={InputWithIncrement}
                        limit={value.limit}
                        toggleNumberOfChecksSelected={() => {}}
                        handleChange={(e) => {
                          updateChecksSelected(e, index);
                        }}
                      />
                    ) : null}
                    {value.type === 'boolean' ? getBooleanField(value, index) : null}
                  </Grid>
                </Grid>
              );
            })}
          {values?.customCheckNames &&
            values?.customCheckNames.map((value, index) => {
              return (
                <Grid
                  className={classes.root}
                  item
                  container
                  spacing={1}
                  key={`check_grid_${index}`}
                >
                  <Grid item xl={6} md={6} lg={6} sm={6} xs={6}>
                    <Typography variant='h6' style={{ fontSize: '18px' }} gutterBottom>
                      <span
                        style={{ marginRight: '10px' }}
                        title={value?.checkName || 'Custom Check'}
                        key={`custom_checks_span_${index}`}
                      >
                        {getCustomCheckSVG('#222', '18px')}
                      </span>
                      {truncateString(value?.checkName) || 'Check name'}
                    </Typography>
                    <Typography
                      variant='body2'
                      style={{ marginLeft: '36px', fontSize: '11px' }}
                      fontStyle='italic'
                    >
                      {'Custom Check'}
                    </Typography>
                  </Grid>
                  <Grid item xl={6} md={6} lg={6} sm={6} xs={6}>
                    <IconButton
                      onClick={() => {
                        delCustomCheck(index);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    {getCustomCheckBooleanField()}
                  </Grid>
                </Grid>
              );
            })}
          <Box mt={3}>
            <Button
              color='primary'
              className={classes.customCheckButton}
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              Add Custom Check
            </Button>
          </Box>
        </Paper>
        <Box className={classes.booleanField}>
          <Box mb={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={values.launchOnCreate}
                  onChange={(e) => {
                    setFieldValue('launchOnCreate', e.target.checked);
                  }}
                  name='launchOnCreate'
                  color='primary'
                />
              }
              label='Launch on creation ?'
              labelPlacement='start'
            />
          </Box>
          <FormControl component='fieldset'>
            <RadioGroup
              row
              aria-label='packageType'
              name='type'
              onChange={(e) => {
                setFieldValue('type', e.target.value);
              }}
              value={values.type}
            >
              <FormControlLabel
                value='PREPAID'
                control={<Radio color='primary' />}
                label='Prepaid'
                labelPlacement='end'
              />
              <FormControlLabel
                value='POSTPAID'
                control={<Radio color='primary' />}
                label='Postpaid'
                labelPlacement='end'
              />
            </RadioGroup>
          </FormControl>
          <Box>
            <FormControlLabel
              value={values['internationalChargesIncluded'] || false}
              control={<Checkbox color='primary' />}
              label={'International Charges Included'}
              onChange={(e) => {
                setFieldValue('internationalChargesIncluded', e.target.checked);
              }}
              labelPlacement='end'
            />
          </Box>
          <div className={classes.basePriceContainer}>
            <span className={classes.rupeeSymbol}> &#8377;</span>
            <Field
              name='basePrice'
              requiredfield={'true'}
              label='Package base price'
              component={FormInput}
              isCost={true}
              onBlur={(e) => {
                const trimmedValue = getTrimmedValue(e.target.value.replace(/,/g, ''));
                setFieldValue(
                  'basePrice',
                  trimmedValue ? convertToIndianNumeration(parseInt(trimmedValue)) : ''
                );
              }}
              onChange={(e) => {
                if (e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) {
                  setFieldValue('basePrice', e.target.value);
                }
              }}
              onFocus={(e) => {
                setFieldValue('basePrice', e.target.value.replace(/,/g, ''));
              }}
            />
          </div>
        </Box>
        {!numberOfChecksSelected
          ? warningBox('Please add atleast one check to the package!')
          : null}
        {numberOfChecksSelected && parseInt(String(values?.basePrice)?.replace(/,/g, ''), 10) < 10
          ? warningBox('Please enter a Base price greater than or equal to 10')
          : null}
        <Grid className={classes.saveButtonGrid} item container>
          <Button
            variant='contained'
            size='large'
            type='submit'
            className={isValidForm ? classes.activeBtn : classes.disabledBtn}
            disabled={!isValidForm || !numberOfChecksSelected}
            startIcon={<SaveIcon />}
          >
            {'Create Package'}
          </Button>
        </Grid>
      </form>
    );
  };

  return (
    <>
      <BreadCrumbs linksArray={breadCrumbsArray} current={'Create Package '} />
      {!loading ? (
        <Grid className={classes.root} container direction={'row'}>
          <Grid item xl={1} md={1} lg={1} sm={false} xs={false}></Grid>
          <Grid item xl={9} md={9} lg={9} sm={12} xs={12}>
            <Paper className={classes.paper} variant='outlined' elevation={3}>
              <Typography variant='h4' gutterBottom>
                {'Create Package'}
              </Typography>
              {pkgConfigurationForm()}
            </Paper>
          </Grid>
          <Grid item xl={1} md={1} lg={1} sm={false} xs={false}></Grid>
        </Grid>
      ) : (
        <Box mt={3} mb={3} className={classes.progressContainer}>
          <CircularProgress size={30} />
        </Box>
      )}

      {openDialog ? (
        <ModalWithInput
          open={openDialog}
          handleClose={() => {
            setOpenDialog(false);
          }}
          title={'Add Custom Check'}
          maxWidth={'sm'}
          handleSubmit={(value) => {
            addCustomCheck(value);
          }}
        >
          <Field
            type='text'
            name='customCheck.checkName'
            requiredfield={'true'}
            label='Custom Check Name'
            component={FormInput}
          />
        </ModalWithInput>
      ) : null}
    </>
  );
};
