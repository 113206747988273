import React from 'react';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { ADMIN_ACCESS_LEVEL } from '../../../../utils/Statuses';
import DetailsCard from '../../../../core-components/DetailsCard';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, title, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{title} </Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

export default (props) => {
  const data = [
    {
      label: 'Name',
      value: (props.adminDetails && props.adminDetails.name) || ''
    },
    {
      label: 'Email',
      value: (props.adminDetails && props.adminDetails.email) || ''
    },
    {
      label: 'Access level',
      value:
        (props.adminDetails && props.adminDetails.accessLevel
          ? ADMIN_ACCESS_LEVEL[props.adminDetails.accessLevel]
          : '') || ''
    },
    {
      label: 'Mobile',
      value: (props.adminDetails && props.adminDetails.mobile) || ''
    },
    {
      label: 'Created At ',
      value:
        (props.adminDetails &&
          moment(props.adminDetails.createdAt).utc().format('DD MMM YYYY hh:mm A')) ||
        ''
    }
  ];
  return (
    <div>
      <Dialog
        onClose={props.handleClose}
        maxWidth='lg'
        aria-labelledby='customized-dialog-title'
        open={props.open}
      >
        <DialogTitle
          id='customized-dialog-title'
          title={'Admin Details'}
          onClose={props.handleClose}
        ></DialogTitle>
        <DialogContent>
          <DetailsCard data={data} heading={'Admin Info'} />
        </DialogContent>
      </Dialog>
    </div>
  );
};
