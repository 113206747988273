import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import { Typography, Box } from '@material-ui/core';

import Button from '../../../../../core-components/Button';
import UpdateBalance from './UpdateBalance';
import ConvertToPackage from './ConvertToPackage';

import {
  convertToIndianNumeration,
  errToastMessage,
  getExcessBridgeCreditValue,
  validateOperation
} from '../../../../../utils/Utlities';
import DeductionTransaction from './DeductionTransaction';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({}));
export default function Balances(props) {
  const useStyles = makeStyles((theme) => ({
    orangeColorText: {
      color: '#ffa500',
      paddingLeft: '10px'
    },
    redColorText: {
      color: '#ff0000',
      paddingLeft: '10px'
    },
    excessBridgeText: {
      textAlign: 'start'
    },
    lockedText: {
      color: '#e45353',
      display: 'flex'
    },
    costContainer: {
      display: 'flex'
    },
    rupeeSymbol: {
      fontFamily: 'Roboto'
    }
  }));
  const [updateBalanceModal, setUpdateBalanceModal] = useState(false);
  const [convertToPackageModal, setConvertToPackageModal] = useState(false);
  const [dedecutionTransactionModal, setDedecutionTransactionModal] = useState(false);
  const ADMIN_ACCESS_ERROR_MSG = 'Only admin is allowed to perform this operation';
  const classes = useStyles();

  const { companyCreditBalance } = props;

  return (
    <>
      <Button
        color={'link'}
        variant={'text'}
        label={'Update Credit Balance'}
        onClick={() => {
          if (validateOperation(props.profile)) {
            setUpdateBalanceModal(true);
          } else {
            errToastMessage(ADMIN_ACCESS_ERROR_MSG);
          }
        }}
      />
      <Button
        color={'link'}
        variant={'text'}
        label={'Convert to Package'}
        onClick={() => {
          if (validateOperation(props.profile)) {
            setConvertToPackageModal(true);
          } else {
            errToastMessage(ADMIN_ACCESS_ERROR_MSG);
          }
        }}
      />
      <Button
        color={'link'}
        variant={'text'}
        label={'Create Deduction Transaction in Usage Log'}
        onClick={() => {
          if (validateOperation(props.profile)) {
            setDedecutionTransactionModal(true);
          } else {
            errToastMessage(ADMIN_ACCESS_ERROR_MSG);
          }
        }}
      />
      <br />
      <br />

      <Box pl={1}>
        <Typography variant='h6'>
          {'Available Credit Balance:'}
          <span style={{ fontFamily: 'Roboto' }}>
            {' '}
            <span>{props?.credits < 0 ? '- ' : ''}</span>
            {`₹${convertToIndianNumeration(
              props.credits < 0 ? props.credits * -1 : props.credits
            )}`}
          </span>
        </Typography>
      </Box>
      <Box pl={1}>
        {parseInt(companyCreditBalance.excessBridgeCredits) !== 0 && (
          <div className={classes.excessBridgeText}>
            Excess Bridge Credits:{' '}
            <span
              className={
                getExcessBridgeCreditValue(companyCreditBalance?.excessBridgeCredits).isNegative
                  ? classes.redColorText
                  : classes.orangeColorText
              }
            >
              <span>
                {getExcessBridgeCreditValue(companyCreditBalance?.excessBridgeCredits).isNegative
                  ? '-'
                  : ''}
              </span>
              <span className={classes.rupeeSymbol}>&#8377;</span>
              <span>
                {convertToIndianNumeration(
                  getExcessBridgeCreditValue(companyCreditBalance?.excessBridgeCredits).isNegative
                    ? getExcessBridgeCreditValue(companyCreditBalance?.excessBridgeCredits).value *
                        -1
                    : getExcessBridgeCreditValue(companyCreditBalance?.excessBridgeCredits).value
                )}
              </span>
            </span>
          </div>
        )}
      </Box>
      {props?.companyCreditBalance?.lockedCompanyCredits && (
        <Box p={1}>
          <div className={classes.lockedText}>
            <span> {'Credits  Locked: '}</span>
            <span>&nbsp;&#8377;</span>
            <span>
              {convertToIndianNumeration(props?.companyCreditBalance?.lockedCompanyCredits)}
            </span>
          </div>
        </Box>
      )}

      {updateBalanceModal ? (
        <UpdateBalance
          companyId={props.companyId}
          open={updateBalanceModal}
          balance={props.credits}
          handleClose={() => setUpdateBalanceModal(false)}
          companyName={props?.companyCreditBalance?.credits?.company?.name}
          tax={props?.companyCreditBalance?.credits?.company?.tax}
        />
      ) : null}

      {convertToPackageModal ? (
        <ConvertToPackage
          companyId={props.companyId}
          open={convertToPackageModal}
          balance={props.credits}
          handleClose={() => setConvertToPackageModal(false)}
        />
      ) : null}

      {dedecutionTransactionModal ? (
        <DeductionTransaction
          open={dedecutionTransactionModal}
          title='Deduction Transaction in Usage Log'
          handleClose={() => setDedecutionTransactionModal(false)}
          companyId={props.companyId}
        />
      ) : null}
    </>
  );
}
