import React from 'react';

import { Box, Paper, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import BuyPackage from '../../../../../images/BuyPackage.svg';
import AppConstants from '../../../../../core-components/AppConstants';

const useStyles = makeStyles((theme) => ({
  packageNotFoundContainer: {
    textAlign: 'center',
    marginTop: '24px',
    minHeight: '400px',
    padding: '16px'
  },
  heading: {
    color: '#333333',
    fontSize: '24px'
  },
  description: {
    color: '#666666',
    fontSize: '14px'
  }
}));

function NoPackageFound(props) {
  const classes = useStyles();
  const companyId = new URLSearchParams(document.location.search).get('company_id');

  return (
    <Paper className={classes.packageNotFoundContainer} data-testid= "package-not-found-container"elevation={3}>
      <Box mb={2}>
        <BuyPackage />
      </Box>
      <Box mb={2}>
        <Typography component={'p'} className={classes.heading} data-testid="buy-package-image">
          {'Buy verification packages to verify candidates'}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography component={'p'} className={classes.description}>
          {
            'To add and verify candidates, you need to buy verification packages containing background checks.'
          }
        </Typography>
      </Box>
      <Box mb={2}>
        <Button
          color={'primary'}
          variant={'contained'}
          onClick={() =>
            window.open(`${AppConstants.baseURL}package/company/view?id=${companyId}`, '_self')
          }
          data-testid="assign-packages-button"
        >
          {'Assign Packages'}
        </Button>
      </Box>
    </Paper>
  );
}

export default NoPackageFound;
