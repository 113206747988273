import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { getAuthToken } from '../../../api/storage';
import MainLayout from '../../SuperAdmin/layouts/MainLayout';

export default ({ component: Comp, path, noMatchFound = false, ...rest }) => {
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return !getAuthToken() ? (
          noMatchFound ? (
            <Redirect
              to={{
                pathname: '/login'
              }}
            />
          ) : (
            <MainLayout>
              <Comp {...props} />
            </MainLayout>
          )
        ) : (
          <>
            {path !== '/invite' ? (
              <Redirect
                to={{
                  pathname: '/dashboard'
                }}
              />
            ) : (
              <Redirect
                to={{
                  pathname: '/unAuthorized'
                }}
              />
            )}
          </>
        );
      }}
    />
  );
};
