import React, { useState, useEffect } from 'react';
import _, { isEmpty, has } from 'lodash';

import {
  Typography,
  IconButton,
  Button,
  Grid,
  Box,
  FormControl,
  Select,
  MenuItem,
  Paper
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SaveIcon from '@material-ui/icons/Save';

import Config from './Config';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex'
  },
  paper: {
    padding: theme.spacing(4),
    color: theme.palette.text.secondary
  },
  formControl: {
    marginBottom: theme.spacing(3),
    width: '100%'
  },
  activeBtn: {
    margin: theme.spacing(1),
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  disabledBtn: {
    cursor: 'not-allowed',
    margin: theme.spacing(1),
    color: '#dfdfdf',
    borderColor: '#d9d9d9',
    backgroundColor: '#f5f5f5',
    fontSize: 14,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  booleanField: {
    marginLeft: '22px'
  },
  saveButtonGrid: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  customCheckButton: {
    fontSize: 12,
    padding: '0px 0px'
  },
  progressContainer: {
    textAlign: 'center',
    width: '100%'
  },
  configContainer: {
    minHeight: '200px'
  }
}));

function AssignCompanyStep3(props) {
  const classes = useStyles();

  const [subtype, setSubtype] = useState(Object.keys(props.values)[0]); // setting value for initial render
  const [subtypeIndex, setSubtypeIndex] = useState(0);
  const [subtypeDropdown, setSubtypeDropdown] = useState([]);
  const [config, setConfig] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [assignedSubtypes, setAssignedSubtypes] = useState([])

  const { handleSubmit, isSubmitting, values, setFieldValue } = props;

  const handleStep3 = (isBack = false) => {
    props.handleStepSubmit('step3', values, isBack ? 2 : 'submit');
  };

  const getFormatDropdownList = (dropdownArr = []) => {
    const dropdown = [];
    const temp = [];

    dropdownArr.forEach((item) => {
      const menu = props.savedValues.step2.packageText.filter((val) => val.subtypeId === item.id);
      const assign = menu[0].isAssigned ? true : false;
      if (assign) {
        dropdown.push({
          label: item.subtypeName,
          value: item.id,
          config: item.config || item.configCount
        });
      } else {
        temp.push({
          label: item.subtypeName,
          value: item.id,
          config: item.config || item.configCount
        });
      }
    });
    return [...dropdown, ...temp];
  };

  useEffect(() => {
    let formatDropdown =
      props.parent === 'COMPANY'
        ? getFormatDropdownList(props.pConfig)
        : getFormatDropdownList(props.pConfig);

    setSubtypeDropdown(formatDropdown);
    setConfig((formatDropdown[0] && formatDropdown[0].config) || null);
    setSubtype((formatDropdown[0] && formatDropdown[0].value) || '');
    setSubtypeIndex(_.findIndex(props.pConfig, function (o) { return o.id == formatDropdown[0].value; }));
    setAssignedSubtypes(getAssignedSubtypes())
  }, []);

  const handleSubtypeChange = (value, config) => {
    setSubtype(value);
    setSubtypeIndex(_.findIndex(props.pConfig, function (o) { return o.id == value; }))
    setConfig(config.props.config);
  };

  const handleConfigChange = (type, name, value, id) => {
    if (name) {
      setFieldValue(`${id}[${type}[${name}]]`, value);
    } else {
      setFieldValue(`${id}[${type}]`, value);
    }
  };
  const handleSourceChange = (type, name, value, id) => {
    if (name) {
      setFieldValue(`${id}[${type}[${name}]]`, value);
    } else {
      setFieldValue(`${id}[${type}]`, value);
    }
  };

  const validateAndSubmit = () => {
    setIsButtonDisabled(true);
    handleSubmit();
  };

  const validateEducation = () => {
    const isEduValid = Object.values(filteredAssingedValues()).map(ob => {
      const eduValues = ob.education;
      if (eduValues.count > 0 && ((eduValues.type === '' || eduValues.source === '')
        || (eduValues.type === 'SPECIFIC' &&
          eduValues.config.length !== eduValues.count))) {
        return false;
      } else {
        return true
      }
    })
    return isEduValid.every(x => x)
  };

  const validateReference = () => {
    const isRefValid = Object.values(filteredAssingedValues()).map(ob => {
      const refValues = ob.reference;
      if (ob.count > 0 &&
        (refValues.type === 'SPECIFIC' ||
          refValues.type === 'SPECIFIC_LOCK')
      ) {
        let addedRefSum = refValues.config.reduce(
          (a, b) => a + b
        );

        if (addedRefSum !== ob.count) {
          return false;
        } else {
          return true;
        }
      } else {
        return true
      }
    })
    return isRefValid.every(x => x)
  };
  const validateAddress = () => {
    const isAddressValid = Object.values(filteredAssingedValues()).map(ob => {
      const addressValues = ob.address;
      if (addressValues.any > 0 && !addressValues.verificationType) {
        return false;
      } else if (addressValues.config.some(addr => addr.verificationType === '')) {
        return false;
      } else {
        return true
      }
    })

    return isAddressValid.every(x => x)
  };
  const validateCourt = () => {
    const isCourtValid = Object.values(filteredAssingedValues()).map(ob => {
      const courtValues = ob.court;
      if (courtValues.any > 0 && !courtValues.verificationType) {
        return false;
      } else if (courtValues.config.some(court => court.verificationType === '')) {
        return false;
      } else {
        return true
      }
    })
    return isCourtValid.every(x => x)
  };
  const validateDrug = () => {
    const isDrugValid = Object.values(filteredAssingedValues()).map(ob => {
      const drugValues = ob.drug;
      if (drugValues === '') {
        return false;
      } else {
        return true
      }
    });
    return isDrugValid.every(x => x)
  };
  const isValid = (type = '') => {

    switch (type) {
      case 'education':
        return validateEducation();
      case 'reference':
        return validateReference();
      case 'address':
        return validateAddress();
      case 'court':
        return validateCourt();
      case 'drug':
        return validateDrug();
      default:
        break;
    }
  };
  const validateAddressConfigIndex = () => {
    if (
      values[props.pConfig[subtypeIndex].id].address.count > 0 &&
      !values[props.pConfig[subtypeIndex].id].address.verificationType) {
      return true;
    } else if (values[props.pConfig[subtypeIndex].id].address.config.some(addr => addr.verificationType === '')) {
      return true;
    } else {
      return false;
    }
  };
  const validateCourtConfigIndex = () => {
    if (
      values[props.pConfig[subtypeIndex].id].court.count > 0 &&
      !values[props.pConfig[subtypeIndex].id].court.verificationType) {
      return true;
    } else if (values[props.pConfig[subtypeIndex].id].court.config.some(crt => crt.verificationType === '')) {
      return true;
    } else {
      return false;
    }
  };
  const validateEduConfigIndex = () => {
    const eduLimit = config.education || 0;
    if (
      values[props.pConfig[subtypeIndex].id].education.count > 0 &&
      ((values[props.pConfig[subtypeIndex].id].education.type === '' ||
        values[props.pConfig[subtypeIndex].id].education.source === '') ||
        (values[props.pConfig[subtypeIndex].id].education.type === 'SPECIFIC' &&
          values[props.pConfig[subtypeIndex].id].education.config.length !== eduLimit))
    ) {
      return true;
    } else {
      return false;
    }
  }
  const validateEduConfigSpecificIndex = () => {
    const eduLimit = config.education || 0;
    if (
      values[props.pConfig[subtypeIndex].id].education.count > 0 &&
      values[props.pConfig[subtypeIndex].id].education.type === 'SPECIFIC' &&
      values[props.pConfig[subtypeIndex].id].education.config.length !== eduLimit
    ) {
      return true;
    } else {
      return false;
    }
  }
  const validateRefConfigIndex = () => {
    const refLimit = config.reference || 0;
    if (
      values[props.pConfig[subtypeIndex].id].reference.count > 0 &&
      values[props.pConfig[subtypeIndex].id].reference.type === 'SPECIFIC' ||
      values[props.pConfig[subtypeIndex].id].reference.type === 'SPECIFIC_LOCK'
    ) {
      let addedRefSum = values[props.pConfig[subtypeIndex].id].reference.config.reduce(
        (a, b) => a + b
      );

      if (addedRefSum !== refLimit) {
        return true;
      } else {
        return false;
      }
    } else {
      return false
    }
  }
  const validateDrugConfigIndex = () => {
    const drugLimit = config.drug || 0;
    if (drugLimit > 0 && values[props.pConfig[subtypeIndex].id].drug === '') {
      return true
    } else {
      return false
    }
  }
  const getDisableItem = (menuItem) => {
    const menu = props.savedValues.step2.packageText.filter(
      (val) => val.subtypeId === menuItem.value
    );
    return menu[0].isAssigned ? false : true;
  };

  const getAssignedSubtypes = () => {
    let assignedSubtypes = []
    props.pConfig.forEach(item => {
      const menu = props.savedValues.step2.packageText.filter((val) => val.subtypeId === item.id);
      const assign = menu[0].isAssigned ? true : false;
      if (assign) {
        assignedSubtypes.push(
          item.id
        )
      }
    });
    return assignedSubtypes;
  }
  const filteredAssingedValues = () => {
    const filteredValue = Object.keys(values).reduce((acc, key) => {
      if (assignedSubtypes.includes(parseInt(key))) {
        acc[key] = values[key];
      }
      return acc;
    }, {});
    return filteredValue;
  };

  const isValidForm = !isSubmitting && isValid('education') && isValid('reference') && isValid('address') && isValid('court') && isValid('drug');
  return (
    <>
      {!isEmpty(props.pConfig) ? (
        <>
          <Box>
            <Grid container spacing={2} className={classes.dropdownContainer}>
              <Grid item lg={4} md={4} sm={4} xs={6}>
                <FormControl variant='outlined' className={classes.formControl} size='small'>
                  <Select
                    labelId='packageSubtype'
                    id='packageSubtype'
                    value={subtype}
                    onChange={(e, config) => {
                      handleSubtypeChange(e.target.value, config);
                    }}
                    fullWidth={true}
                    disabled={validateRefConfigIndex() || validateEduConfigIndex() || validateAddressConfigIndex() || validateCourtConfigIndex() || validateDrugConfigIndex()}
                  >
                    {subtypeDropdown &&
                      subtypeDropdown.map((item, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={item.value}
                            config={item.config}
                            index={index}
                            disabled={getDisableItem(item)}
                          >
                            {item.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Typography style={{ fontSize: '20px' }} gutterBottom>
            {'Configure package'}
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box mt={2} mb={2} className={classes.configContainer}>
                  <Paper elevation={2} variant={'outlined'} className={classes.paper}>
                    <Config
                      config={config}
                      pConfig={props.pConfig}
                      subtypeIndex={subtypeIndex}
                      values={values[subtype]}
                      isAadharInIdCheck={props.isAadhaarAllowed}
                      handleConfigChange={(type, name, value) =>
                        handleConfigChange(type, name, value, subtype)
                      }
                      handleSourceChange={(type, name, value) =>
                        handleSourceChange(type, name, value, subtype)
                      }
                      educationError={validateEduConfigSpecificIndex()}
                      referenceError={validateRefConfigIndex()}
                    />
                  </Paper>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Button
                  variant='outlined'
                  color={'primary'}
                  size='large'
                  onClick={() => handleStep3(true)}
                  startIcon={<ArrowBackIosIcon />}
                >
                  {'Back'}
                </Button>
                <Button
                  type={'button'}
                  variant='contained'
                  size='large'
                  className={isValidForm ? classes.activeBtn : classes.disabledBtn}
                  disabled={isButtonDisabled || !isValidForm}
                  onClick={validateAndSubmit}
                  startIcon={<SaveIcon />}
                >
                  {'Save'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      ) : null}
    </>
  );
}

export default AssignCompanyStep3;
