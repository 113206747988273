// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".f3WL63uUHPmnNfue598P {\n  display: flex;\n}\n.f3WL63uUHPmnNfue598P .hGIM3GMyxUzT4Rjph7LE {\n  font-size: 14px;\n  margin-right: 55px;\n  margin-top: 8px;\n}\n\n.el5euk3L6rbmaEgItfQU {\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 21px;\n  color: #333333;\n  margin-right: 8px;\n}\n\n.MaCyvEaPxB67EEnlo62L {\n  font-weight: 600;\n  font-size: 18px;\n  line-height: 24px;\n}\n\n.GC6Q3R83JFqh8xj60mmQ {\n  font-size: 10.9px;\n  margin-top: -20px;\n  margin-bottom: 15px;\n  color: red;\n}\n\n.Iirle5K1rrie_tL_UqIv {\n  background: #2755fe;\n  border-radius: 5px;\n  font-family: Poppins, sans-serif;\n  font-size: 14px;\n  line-height: 20px;\n  text-align: center;\n  color: #ffffff;\n  width: 136px;\n  margin-right: 0px;\n}\n.Iirle5K1rrie_tL_UqIv:hover {\n  background: #2755fe;\n  color: #ffffff;\n}\n\n.xQ3VXxPYvQh9incjhoGt {\n  background: #f5f5f5;\n  border-radius: 5px;\n  font-family: Poppins, sans-serif;\n  font-size: 14px;\n  line-height: 20px;\n  text-align: center;\n  color: #ffffff;\n  width: 136px;\n  margin-right: 0px;\n}\n\n.ItsIwZPpkHCYmdviBU1J {\n  border: 1px solid #2755fe;\n  box-sizing: border-box;\n  background: #ffffff;\n  border-radius: 5px;\n  font-family: Poppins, sans-serif;\n  font-size: 14px;\n  line-height: 20px;\n  text-align: center;\n  color: #2755fe;\n  width: 136px;\n  margin-right: 15px;\n}\n.ItsIwZPpkHCYmdviBU1J:hover {\n  background: #ffffff;\n  color: #2755fe;\n}\n.ItsIwZPpkHCYmdviBU1J:active {\n  background-color: #ffffff !important;\n  color: #2755fe !important;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "f3WL63uUHPmnNfue598P",
	"footerLabel": "hGIM3GMyxUzT4Rjph7LE",
	"title": "el5euk3L6rbmaEgItfQU",
	"subtypeName": "MaCyvEaPxB67EEnlo62L",
	"errorMsg": "GC6Q3R83JFqh8xj60mmQ",
	"submitButton": "Iirle5K1rrie_tL_UqIv",
	"disableBtn": "xQ3VXxPYvQh9incjhoGt",
	"cancelButton": "ItsIwZPpkHCYmdviBU1J"
};
export default ___CSS_LOADER_EXPORT___;
