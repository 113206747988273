import React, { Suspense, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CustomizedBreadcrumbs from '../../../../core-components/BreadCrumbs';
import AppConstants from '../../../../core-components/AppConstants';
import SecuritySettings from '../../frontend-common/SecuritySettings';
import BrandSettings from '../BrandSettings';
import ThreeDots from '../../../../core-components/ThreeDotLoader';
import TabComponent from '../../frontend-common/core-components/TabComponent/TabComponent';
import { Card } from 'react-bootstrap';
import AlumniVerification from '../../frontend-common/Settings/AlumniVerification';
import Communication from '../Communication';

const useStyles = makeStyles((theme) => ({
  tabsInner: {
    padding: '20px'
  },
  tab: {
    padding: '10px 20px',
    fontWeight: 'normal'
  },

  threeDotLoader: {
    textAlign: 'center',
    height: '100%'
  }
}));

const TABS = {
  BRAND: 'Brand',
  COMMUNICATION: 'Communication',
  SECURITY_SETTINGS: 'Security Settings',
  ALUMNI_VERIFICATION: 'Alumni Verification'
};

export default (props) => {
  const companyId = new URLSearchParams(document.location.search).get('company_id');
  const classes = useStyles();
  const searchParams = new URLSearchParams(props?.location?.search);
  const defaultTab = searchParams?.get('tab') || 'BRAND';

  useEffect(() => {
    props.getCompanyById(companyId);
  }, []);

  const { companyDetails } = props;

  const [currentTab, setCurrentTab] = useState(TABS[defaultTab] || 'BRAND');

  const handleOnChangeTab = (tab) => {
    setCurrentTab(tab);
    const queryParam = Object.keys(TABS).find((key) => TABS[key] === tab) || 'BRAND';
    searchParams.set('tab', queryParam);
    props.history.push({
      pathname: props.location.pathname,
      search: searchParams.toString()
    });
  };

  const renderTab = () => {
    return (
      <div className={classes.tab}>
        <TabComponent
          tabItems={Object.values(TABS)}
          activeTab={currentTab}
          handleTabChange={handleOnChangeTab}
        />
        <Card className={classes.tabsInner}>
          {(() => {
            switch (currentTab) {
              case 'Brand':
                return <BrandSettings companyId={companyId} />;
              case 'Communication':
                return <Communication isSA={true} {...props} />;
              case 'Security Settings':
                return <SecuritySettings isSA={true} {...props} />;
              case 'Alumni Verification':
                return <AlumniVerification companyId={companyId} isSA={true} />;
              default:
                return <BrandSettings companyId={companyId} />;
            }
          })()}
        </Card>
      </div>
    );
  };

  const breadCrumbsArray = [
    { label: 'Home', href: `${AppConstants.baseURL}dashboard` },
    { label: 'Companies', href: `${AppConstants.baseURL}companies` },
    {
      label: companyDetails?.name || 'Company Name',
      href: `${AppConstants.baseURL}company/view?id=${companyId}`
    }
  ];

  return (
    <>
      <Suspense
        fallback={
          <div className={classes.threeDotLoader}>
            <ThreeDots style={{ textAlign: 'center' }} />
          </div>
        }
      >
        <CustomizedBreadcrumbs linksArray={breadCrumbsArray} current={'Settings'} />
        {renderTab()}
      </Suspense>
    </>
  );
};
