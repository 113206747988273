import { connect } from 'react-redux';
import { compose } from 'redux';

import { invalidateInsuffFundsForChecks as invalidateInsuffFundsForChecksAPI, getInsuffFundsForChecks as getInsuffFundsForChecksAPI } from '../../../actions/admin';

const mapStateToProps = (state) => {
  return { insuffFundsForChecks: state.insuffFundsForChecks || null };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInsuffFundsForChecks: (limit, offset, status, candidateName, companyName) => dispatch(getInsuffFundsForChecksAPI(limit, offset, status, candidateName, companyName)),
    invalidateInsuffFundsForChecks: () => dispatch(invalidateInsuffFundsForChecksAPI()),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
