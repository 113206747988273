import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, sortBy, isArray, forEach, isEmpty } from 'lodash';
import { toast } from 'react-toastify';

import validationSchema from './CreateVendor.validation';

import { addVendor as addVendorAPI } from '../../../../api/admin';
import { errToastMessage, getErrorMessage } from '../../../../utils/Utlities';

export default compose(
  connect(null, null),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        // userUuid: props.userUuid || '77a4c0c5-a08e-4818-b8dd-8296c05c1014',
        name: '',
        status: true
      };
      let propsValue = {};
      if (props?.isEdit) {
        propsValue = {
          vendorId: props?.details?.id || null,
          name: props?.details?.name || null,
          status: props?.details?.status ? true : false
        };
      }
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (values, { setFieldError, setStatus, setSubmitting, resetForm, props }) => {
      //Call API here and call handleclose here
      let requestData = { ...values, status: values.status ? 'active' : 'inactive' };
      addVendorAPI(requestData)
        .then((response) => {
          setSubmitting(false);
          toast.success(props.isEdit ? 'Vendor Edited successfully' : 'Vendor Added successfully');
          props?.pageDetails?.getVendors &&
            props?.pageDetails.getVendors(props.pageDetails.rowsPerPage, 0);
          props.handleClose();
        })
        .catch((error) => {
          setSubmitting(false);
          errToastMessage(error);
          console.error(error);
        });
    },
    displayName: 'Add new vendor'
  })
);
