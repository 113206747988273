import React, { useEffect, useState } from 'react';
import { forEach, isEmpty, has } from 'lodash';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, Zoom, Typography } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid, Box } from '@material-ui/core';

import { COMPANY_STATUSES } from '../../../utils/Statuses';
import Dropdown from '../../../core-components/DropdownInput';
import Table from '../../../core-components/Table';
import DeletePopup from '../../../core-components/ConfirmationPopup';
import SearchInput from '../../../core-components/SearchInput';
import Button from '../../../core-components/Button';
import BreadCrumbs from '../../../core-components/BreadCrumbs';
import AppConstants from '../../../core-components/AppConstants';
import { SERVICES_STATUS } from '../../../utils/Statuses';
import { deleteServices as deleteServicesAPI } from '../../../api/admin';

//modals
import CreateModal from './CreateServicesModal';

// function descendingComparator(a, b, orderBy) {
//     if (orderBy && b[orderBy] < a[orderBy]) {
//         return -1;
//     }
//     if (orderBy && b[orderBy] > a[orderBy]) {
//         return 1;
//     }
//     return 0;
// }

// function getComparator(order, orderBy) {
//     return order === 'desc'
//         ? (a, b) => descendingComparator(a, b, orderBy)
//         : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function stableSort(array, comparator) {
//     const stabilizedThis = array.map((el, index) => [el, index]);
//     stabilizedThis.sort((a, b) => {
//         const order = comparator(a[0], b[0]);
//         if (order !== 0) return order;
//         return a[1] - b[1];
//     });
//     return stabilizedThis.map((el) => el[0]);
// }

//this is for each row the heading creation  with whatever id for headcells
function createData(index, id, name, status, price, createdAt, updatedAt, actions) {
  return { index, id, name, status, price, createdAt, updatedAt, actions };
}

const useStyles = makeStyles({
  btnDiv: {
    display: 'flex'
  },
  deleteBtn: {
    color: '#467eac',
    cursor: 'pointer'
  },
  editBtn: {
    color: '#3788ac',
    cursor: 'pointer'
  }
});

const headCells = [
  {
    id: 'index',
    label: '#',
    align: 'left'
  },
  {
    id: 'id',
    label: 'ID',
    align: 'center'
  },
  {
    id: 'name',
    label: ' Name',
    align: 'center'
  },
  {
    id: 'status',
    label: 'Status',
    align: 'center'
  },
  {
    id: 'price',
    label: 'Price',
    align: 'center'
  },

  {
    id: 'createdAt',
    label: 'Created At',
    align: 'center'
  },
  {
    id: 'updatedAt',
    label: 'Updated At',
    align: 'center'
  },
  {
    id: 'actions',
    label: '',
    align: 'center'
  }
];

export default function EnhancedTable(props) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [title, setTitle] = useState(null);
  const [serviceName, setServiceName] = useState(null);
  const [serviceId, setServiceId] = useState(null);
  const [serviceDetails, setServiceDetails] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [name, setName] = useState(null);
  const [orderId, setOrderId] = useState('asc');
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [price, setPrice] = useState(null);
  const [id, setId] = useState(null);
  const [status, setStatus] = useState(null);
  const classes = useStyles();
  const [filterArr, setFilterArr] = useState([]);

  const dropdownValues = [
    { label: 'All', value: 'all' },
    { label: 'Active', value: 'active' },
    { label: 'Pending', value: 'pending' },
    { label: 'Custom-Services', value: 'custom' },
    { label: 'Automated-Services', value: 'automatic' }
  ];

  //create  modal
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const handleClickOpenCreateModal = () => {
    setOpenCreateModal(true);
  };
  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
  };

  //delete modal

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleClickOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = () => {
    deleteServicesAPI(serviceId)
      .then((response) => {
        props.getServices(rowsPerPage, offset, orderId);
        handleCloseDeleteModal();
        setDeleteSuccess(true);
        handleClickOpenDeleteModal();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const breadCrumbsArray = [{ label: 'Home', href: `${AppConstants.baseURL}dashboard` }];

  //on mount call API
  useEffect(() => {
    setLoading(true);
    setServiceDetails(null);
    props.getServices(rowsPerPage, offset, orderId);
  }, []);

  useEffect(() => {
    // we should save company data in rows
    if (has(props.servicesData, 'services')) {
      let rowsData =
        props.servicesData &&
        props.servicesData.services &&
        props.servicesData.services.length &&
        getRowData(props.servicesData.services);
      setLoading(false);
      setRows(rowsData || []);
      setTotalCount(props.servicesData.count);
      //need to set totalCount
      // setRowsPerPage(rowsData.length < 10 ? rowsData.length : 10);
    }
  }, [props.servicesData]);

  // const handleRequestSort = (event, property) => {
  //     const isAsc = orderBy === property && order === 'asc';
  //     setOrder(isAsc ? 'desc' : 'asc');
  //     setOrderBy(property);
  // };

  const getRowData = (values) => {
    let companyRow = [];
    forEach(values, function (value, key) {
      if (!isEmpty(value)) {
        let rowData = createData(
          key + 1 + offset * rowsPerPage,
          value.id || null,
          value.name,
          SERVICES_STATUS.values[value.status].label,
          value.price,
          moment(value.createdAt).format('DD-MMM-YYYY h:mm A'),
          moment(value.updatedAt).format('DD-MMM-YYYY h:mm A'),
          buttonInfoEdit(value.id, value)
        );
        companyRow.push(rowData);
      }
    });
    return companyRow;
  };

  //these are filters
  const getIdFilter = () => {
    return (
      <SearchInput
        placeholder={'Search Id'}
        value={id}
        handleSearch={(id) => {
          applyIdFilter(id?.trim());
        }}
        type='id'
      />
    );
  };

  const getNameFilter = () => {
    return (
      <SearchInput
        placeholder={'Search Name'}
        value={name}
        handleSearch={(name) => {
          applyNameFilter(name?.trim());
        }}
      />
    );
  };

  const getPriceFilter = () => {
    return (
      <SearchInput
        placeholder={'Search Price'}
        value={price}
        handleSearch={(price) => {
          applyPriceFilter(price?.trim());
        }}
      />
    );
  };

  const buttonInfoEdit = (serviceId, serviceDetails) => {
    return (
      <div className={classes.btnDiv}>
        <Tooltip TransitionComponent={Zoom} title='Edit' arrow={true}>
          <EditOutlinedIcon
            className={classes.editBtn}
            onClick={() => {
              setTitle('Edit');
              setServiceDetails(serviceDetails);
              handleClickOpenCreateModal();
            }}
          />
        </Tooltip>
        <Tooltip TransitionComponent={Zoom} title='Delete' arrow={true}>
          <DeleteOutlineIcon
            className={classes.deleteBtn}
            onClick={() => {
              setServiceId(serviceId);
              setServiceName(serviceDetails.name);
              setDeleteSuccess(false);
              handleClickOpenDeleteModal();
            }}
          />
        </Tooltip>
      </div>
    );
  };

  const getStatusAction = (status, candidateId = null, companyId = null) => {
    let label = (
      <span style={{ color: COMPANY_STATUSES.values[status].color }}>
        {COMPANY_STATUSES.values[status].value}
      </span>
    );

    return (
      <>
        <Button variant={'text'} label={label} size={'small'}></Button>
      </>
    );
  };

  const handleDropdownSelect = (value) => {
    setLoading(true);
    if (value === 'all') {
      setStatus(null);
      setOffset(0);
      props.getServices(rowsPerPage, 0, orderId, id, price, name, null).then(() => {
        setLoading(false);
      });
    } else {
      setStatus(value);
      setOffset(0);
      props.getServices(rowsPerPage, 0, orderId, id, price, name, value).then(() => {
        setLoading(false);
      });
    }
  };

  const applyIdFilter = (Id) => {
    setId(Id);
    setLoading(true);
    setOffset(0);
    if (Id) {
      props.getServices(rowsPerPage, 0, orderId, Id, price, name, status).then(() => {
        setLoading(false);
      });
    } else {
      setRowsPerPage(10);
      setId(null);
      props.getServices(10, 0, orderId, null, price, name, status).then(() => {
        setLoading(false);
      });
    }
  };

  const applyNameFilter = (n) => {
    setName(n);
    setLoading(true);
    setOffset(0);
    if (n) {
      props.getServices(rowsPerPage, 0, orderId, id, price, n, status).then(() => {
        setLoading(false);
      });
    } else {
      setRowsPerPage(10);
      setName(null);
      props.getServices(10, 0, orderId, id, price, null, status).then(() => {
        setLoading(false);
      });
    }
  };
  const applyPriceFilter = (p) => {
    setPrice(p);
    setOffset(0);
    setLoading(true);
    if (p) {
      props.getServices(rowsPerPage, 0, orderId, id, p, name, status).then(() => {
        setLoading(false);
      });
    } else {
      setRowsPerPage(10);
      setPrice(null);
      props.getServices(10, 0, orderId, id, null, name, status).then(() => {
        setLoading(false);
      });
    }
  };

  const handleChangePage = (newPage) => {
    //need to call API with new limit and offset
    setOffset(newPage);

    setLoading(true);
    props.getServices(rowsPerPage, newPage, orderId, id, price, name, status).then(() => {
      setLoading(false);
    });
  };

  const handleChangeRowsPerPage = (value) => {
    //need to call API with new limit and offset
    setRowsPerPage(parseInt(value, 10));
    setLoading(true);

    props.getServices(parseInt(value, 10), offset, orderId, id, price, name, status).then(() => {
      setLoading(false);
    });
  };

  return (
    <>
      {/* update breadcrumbs */}
      <BreadCrumbs linksArray={breadCrumbsArray} current={'Services'} />
      <Typography variant='h4' component='h3'>
        Services
      </Typography>
      <Button
        label={'Create Services'}
        color={'primary'}
        onClick={() => {
          setTitle('Create');
          setServiceDetails(null);
          handleClickOpenCreateModal();
        }}
      />
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item lg={2} md={3} sm={3} xs={3}>
            {getIdFilter()}
          </Grid>

          <Grid item lg={2} md={3} sm={3} xs={3}>
            {getNameFilter()}
          </Grid>
          <Grid item lg={2} md={3} sm={3} xs={3}>
            {getPriceFilter()}
          </Grid>
          <Grid item lg={2} md={3} sm={3} xs={3}>
            <Dropdown
              dropdownValues={dropdownValues}
              label={'Status'}
              value={'all'}
              handleSelect={(value) => {
                handleDropdownSelect(value);
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Table
        headCells={headCells}
        rowData={rows}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={offset}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        loading={loading}
        // filterArr={filterArr}
      />
      <CreateModal
        title={title}
        details={serviceDetails}
        open={openCreateModal}
        handleClose={handleCloseCreateModal}
      />
      <DeletePopup
        title={deleteSuccess ? 'Deleted Successfully' : 'Delete  Service'}
        // serviceId={serviceId}
        content={
          deleteSuccess
            ? 'Service has been deleted successfully '
            : `Are you sure you want to delete ${serviceName} service?`
        }
        buttonLabel={deleteSuccess ? 'OK' : 'Delete'}
        buttonColor={deleteSuccess ? 'primary' : 'secondary'}
        open={openDeleteModal}
        handleSubmit={() => {
          deleteSuccess ? handleCloseDeleteModal() : handleDelete();
        }}
        handleClose={handleCloseDeleteModal}
      />
    </>
  );
}
