import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const BlueButton = withStyles(() => ({
  root: {
    borderRadius: 5,
    color: '#ffffff !important',
    borderColor: '#1565c0',
    backgroundColor: '#1976d2',
    fontSize: 14,
    lineHeight: 1,
    margin: '0 4px',
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#1565c0'
    }
  },
  disabled: {
    pointerEvents: 'unset !important',
    cursor: 'not-allowed !important'
  }
}))(Button);

const GreenButton = withStyles(() => ({
  root: {
    borderRadius: 5,
    color: '#ffffff  !important',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    lineHeight: 1,
    margin: '0 4px',
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  disabled: {
    pointerEvents: 'unset !important',
    cursor: 'not-allowed !important'
  }
}))(Button);

const LinkButton = withStyles(() => ({
  root: {
    borderRadius: 5,
    color: '#366CF3',
    borderColor: '#366CF3',
    backgroundColor: 'transparent',
    fontSize: 14,
    lineHeight: 1,
    margin: '0 4px',
    textTransform: 'inherit',
    '&:hover': {
      color: '#366CF3',
      backgroundColor: '#eef5fe'
    }
  },
  disabled: {
    pointerEvents: 'unset !important',
    cursor: 'not-allowed !important'
  }
}))(Button);

const AlertButton = withStyles(() => ({
  root: {
    borderRadius: 5,
    color: '#ffffff  !important',
    borderColor: '#c2185b',
    backgroundColor: '#d81b60',
    fontSize: 14,
    lineHeight: 1,
    margin: '0 4px',
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#c2185b'
    }
  },
  disabled: {
    pointerEvents: 'unset !important',
    cursor: 'not-allowed !important'
  }
}))(Button);

const SmallButton = withStyles(() => ({
  root: {
    borderRadius: 5,
    fontSize: 14,
    lineHeight: 1,
    margin: '0 4px',
    textTransform: 'inherit',
    padding: 0
  },
  disabled: {
    pointerEvents: 'unset !important',
    cursor: 'not-allowed !important'
  }
}))(Button);

const useStyles = makeStyles({
  button: {
    borderRadius: 5,
    lineHeight: 2,
    margin: '16px 8px',
    textTransform: 'inherit'
  },
  disabled: {
    pointerEvents: 'unset !important',
    cursor: 'not-allowed !important'
  }
});

export default function CustomizedButtons(props) {
  const classes = useStyles();

  return (
    <>
      {props.color === 'blue' ? (
        <BlueButton
          variant={props.variant}
          disabled={props.disabled}
          endIcon={props.endIcon}
          startIcon={props.startIcon}
          fullWidth={props.fullWidth}
          href={props.href}
          target={props.target}
          onClick={props.onClick}
        >
          {props.label}
        </BlueButton>
      ) : props.color === 'green' ? (
        <GreenButton
          variant={props.variant}
          disabled={props.disabled}
          size={props.size}
          endIcon={props.endIcon}
          startIcon={props.startIcon}
          fullWidth={props.fullWidth}
          href={props.href}
          target={props.target}
          onClick={props.onClick}
        >
          {props.label}
        </GreenButton>
      ) : props.color === 'link' ? (
        <LinkButton
          variant={props.variant}
          disabled={props.disabled}
          endIcon={props.endIcon}
          startIcon={props.startIcon}
          fullWidth={props.fullWidth}
          href={props.href}
          target={props.target}
          onClick={props.onClick}
        >
          {props.label}
        </LinkButton>
      ) : props.color === 'alert' ? (
        <AlertButton
          variant={props.variant}
          disabled={props.disabled}
          endIcon={props.endIcon}
          startIcon={props.startIcon}
          fullWidth={props.fullWidth}
          href={props.href}
          target={props.target}
          onClick={props.onClick}
        >
          {props.label}
        </AlertButton>
      ) : props.size === 'small' ? (
        <SmallButton
          variant={props.variant}
          disabled={props.disabled}
          endIcon={props.endIcon}
          startIcon={props.startIcon}
          fullWidth={props.fullWidth}
          href={props.href}
          target={props.target}
          onClick={props.onClick}
        >
          {props.label}
        </SmallButton>
      ) : (
        <Button
          className={props.className || classes.button}
          classes={{ disabled: classes.disabled }}
          color={props.color}
          variant={props.variant}
          disabled={props.disabled}
          endIcon={props.endIcon}
          startIcon={props.startIcon}
          fullWidth={props.fullWidth}
          href={props.href}
          target={props.target}
          onClick={props.onClick}
        >
          {props.label}
        </Button>
      )}
    </>
  );
}

CustomizedButtons.defaultProps = {
  label: '', //provide label for Button
  color: 'primary', //blue -> BlueButton | green -> GreenButton | link -> LinkButton | primary | secondary | default
  variant: 'contained', //(contained || outlined || text -> SmallButton | Normal Button ), (text || outlined -> LinkButton ), (contained || outlined -> BlueButton | GreenButton | AlertButton )
  disabled: false, //true -> if want disabled
  endIcon: null, //displays icon in end
  startIcon: null, //displays icon in start
  fullWidth: false, //true -> will take full width of its container
  href: '', //provide href
  target: '_self', //'_self' | '_blank' (if providing href)
  onClick: null, //provide onClick method
  size: 'medium' //small->SmallButton || (large || medium -> Normal Button)
};
