import React, { useEffect } from 'react';
import { has } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  FormControl,
  FormGroup,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox
} from '@material-ui/core';
import {
  EDUCATION_TYPES_FOR_PACKAGE_CONFIG,
  EDUCATION_TYPES,
  EDUCATION_TYPES_ADDONS
} from '../../../../../../core-components/CommonConstants';
import IncrementCounter from '../../../../../../core-components/Packages/IncrementCounter';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    color: 'rgba(0, 0, 0, 0.54)',
    marginLeft: '68px'
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  required: {
    color: '#db3131'
  }
}));

/**
 * Company PackageDetails
 * sPkgDetails: Customized/CA selected package detailsInfo
 * aPkgDetails: Addon's allowed config/available options in addons
 * onValueChange: Func to save the values to parent state
 * values: Addons values as they changes
 */
export default (props) => {
  const classes = useStyles();

  //   Refer DocBlock above for detailed variable usage
  const { onValueChange, values, eduPriceToggler, addonDetails } = props;
  const setMethodnOptions = (e, name) => {
    const countValue = values.count > 0 ? values.count : 0;
    if (e?.target?.value) {
      onValueChange([name, 'count', 'config'], [e.target.value, countValue, []]);
    } else {
      onValueChange([name, 'count', 'config'], [e, countValue, []]);
    }
  };

  useEffect(() => {
    const filteredEducationTypes = EDUCATION_TYPES.filter((type) => {
      if (has(props?.addonDetails, EDUCATION_TYPES_ADDONS[type.name])) {
        return type;
      }
    });

    if (values.count === 1 && values.method === '') {
      setMethodnOptions('', 'method');
      setMethodnOptions('', 'type');
    } else if (values.count === 0) {
      setMethodnOptions('', 'method');
      setMethodnOptions('', 'type');
    }
  }, [values.count, values.type]);

  const handleConfigChangeForAllTypes = (event, value) => {
    if (event) {
      let dummy = [...values.config];
      if (event.target.checked) {
        if (!dummy?.includes(value)) {
          dummy.push(value);
          onValueChange(['config'], [dummy]);
        }
      } else {
        dummy.splice(dummy.indexOf(value), 1);
        onValueChange(['config'], [dummy]);
      }
    } else {
      if (getEduConfiguration().length === 1 && value !== 0) {
        onValueChange(['method'], [getEduConfiguration()[0]]);
      }
      onValueChange(['count', 'config'], [value, []]);
    }
  };

  const hasValue = (name) => {
    return values?.config?.includes(name);
  };
  const getEduConfiguration = () => {
    let eudConfig = [];

    if (addonDetails?.education) {
      eudConfig.push('OFFICIAL');
    }
    if (addonDetails?.educationHybrid) {
      eudConfig.push('HYBRID');
    }
    if (addonDetails?.educationThirdParty) {
      eudConfig.push('REGIONAL_PARTNER');
    }
    return eudConfig;
  };
  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
            <Grid item xl={6} md={6} lg={6} sm={6} xs={6}>
              <Typography variant='subtitle2' component='span' style={{ marginRight: '12px' }}>
                <i>{`Edu's :`}</i>
              </Typography>
              <IncrementCounter
                limit={5}
                totalCount={values.count}
                value={values.count}
                index={null}
                valueChange={(value) => {
                  handleConfigChangeForAllTypes(null, value);
                }}
              />
            </Grid>
            <FormControl component='fieldset' style={{ marginBottom: '18px', marginTop: '40px' }}>
              <FormLabel component='legend'>
                Choose verfication type: <span className={classes.required}>*</span>
              </FormLabel>
              <RadioGroup
                row
                aria-label='position'
                name='position'
                value={values.method || (EDUCATION_TYPES.length === 1 ? EDUCATION_TYPES[0]?.name : '')}
                onChange={(e) => setMethodnOptions(e, 'method')}
              >
                {EDUCATION_TYPES.map((type, index) => (
                  <React.Fragment key={index}>
                    {has(props.addonDetails, EDUCATION_TYPES_ADDONS[type.name]) ? (
                      <FormControlLabel
                        value={type?.name}
                        control={<Radio color='primary' />}
                        label={type?.label}
                        disabled={values.count === 0 && values.type !== 'SPECIFIC'}
                        onChange={() => {
                          eduPriceToggler(type?.name);
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </React.Fragment>
                ))}
              </RadioGroup>
            </FormControl>
            <Grid
              item
              container
              xl={12}
              md={12}
              lg={12}
              sm={12}
              xs={12}
              style={{ marginBottom: '18px', marginTop: '10px' }}
            >
              <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                <Typography variant='subtitle2' component='span' gutterBottom color='textSecondary'>
                  {'Customize your options'} <span className={classes.required}>*</span>
                </Typography>
              </Grid>
              <Grid item xl={12} md={12} lg={12} sm={12} xs={12} style={{ marginBottom: '18px' }}>
                <FormControl component='fieldset'>
                  <RadioGroup
                    row
                    aria-label='position'
                    name='position'
                    value={values.type}
                    onChange={(e) => setMethodnOptions(e, 'type')}
                  >
                    {[
                      { label: 'Chronology', name: 'CHRONOLOGICAL' },
                      { label: 'Specific', name: 'SPECIFIC' }
                    ].map((type, index) => (
                      <FormControlLabel
                        key={index}
                        value={type.name}
                        disabled={values.count === 0 && values.type !== 'SPECIFIC'}
                        control={<Radio color='primary' />}
                        label={type.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              {values.type === 'SPECIFIC' && (
                <Grid item xl={6} md={6} lg={6} sm={6} xs={6}>
                  <FormControl component='fieldset'>
                    <FormGroup aria-label='position'>
                      {EDUCATION_TYPES_FOR_PACKAGE_CONFIG.map((value, index) => {
                        return (
                          <FormControlLabel
                            key={`Package_Configure_${index}`}
                            control={
                              <Checkbox
                                checked={hasValue(value?.name)}
                                disabled={
                                  !hasValue(value?.name) && values.count === values.config.length
                                }
                                onChange={(e) => handleConfigChangeForAllTypes(e, value?.name)}
                                name={value?.name}
                              />
                            }
                            label={value?.label}
                          />
                        );
                      })}
                    </FormGroup>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
