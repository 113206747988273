import React, { useState, useEffect, createContext } from 'react';

import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core/';

import BreadCrumbs from '../../../core-components/BreadCrumbs';
import AppConstants from '../../../core-components/AppConstants';

import BasicDetails from './BasicDetails';
import PackageListing from './PackageListing';
import SelectedPackage from './SelectedPackage';
import Step3Values from './Step3IntialJson';

import {
  createCandidate as createCandidateAPI,
  getCompanyById as getCompanyByIdAPI
} from '../../../api/admin';

import { candidatesBulkUpload as candidatesBulkUploadAPI } from '../../../api/company';
import {
  errToastMessage,
  getErrorMessage,
  validateCategoriesAndTags
} from '../../../utils/Utlities';

import mapper from './AddCandidate.mapper';
import { AddressArr } from '../../../core-components/CommonConstants';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => ({
  progressContainer: {
    textAlign: 'center',
    width: '100%'
  },
  maxWidthDialog: {
    maxWidth: 800
  },
  headingSubText: {
    fontSize: 16,
    color: theme.palette.text.secondary
  }
}));

export const SubmitContext = createContext();

export default (props) => {
  const companyId = new URLSearchParams(document.location.search).get('company_id');
  const classes = useStyles();
  const [currentStep, setCurrentStep] = React.useState(1);
  const [values, setValues] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categoriesOrTagsDeleted, setCategoriesOrTagsDeleted] = useState({});
  const [categoryTypeId, setCategoryTypeId] = useState(null);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [fileId, setFileId] = useState(null);
  const { companyDetails } = props;
  useEffect(() => {
    props.getCompanyById(companyId);
    props.invalidateAddCandidateConfig();
    props.getAddCandidateConfig(companyId).catch((error) => {
      console.error(error);
    });

    setValues({ step3: JSON.parse(JSON.stringify(Step3Values)) });

    if (props && props?.finalData?.isBulkUpload && props?.finalData?.step === 2) {
      const currentStepFromBulkUpload = props && props?.finalData?.step;
      const categoryTypeId = props && props?.finalData?.categoryTypeId;
      const tableData = props && props?.finalData?.tableData;
      const fileId = props?.fileId || null;
      currentStepFromBulkUpload ? setCurrentStep(currentStepFromBulkUpload) : setCurrentStep(1);
      setCategoryTypeId(categoryTypeId);
      setIsBulkUpload(true);
      setTableData(tableData);
      setFileId(fileId);
    }
  }, []);

  useEffect(() => {
    setValues({ ...values, step3: JSON.parse(JSON.stringify(Step3Values)) });
  }, [selectedIndex]);

  useEffect(() => {
    if (isBulkUpload) {
      if (isSubmitting) {
        props?.setMsg({
          load: true,
          msg: 'Adding Candidates...'
        });
      } else {
        props?.setMsg({
          load: false,
          msg: ''
        });
      }
    }
  }, [isSubmitting]);

  const handleStepChange = (step) => {
    setCurrentStep(step);
  };

  const startSubmit = () => {
    const where = mapper(
      values,
      props.addCandidateConfig,
      companyDetails?.isAdhaarAllowed === 1 ? true : false
    );
    setIsSubmitting(true);

    if (
      values.step2.customizeConfig &&
      values.step2.customizeConfig[selectedIndex]?.address?.length > 0
    ) {
      where.package.config.address = JSON.parse(
        JSON.stringify(values.step2.customizeConfig[selectedIndex].address)
      ).map((ob) => ob.type);
    } else {
      where.package.config.address = [...values.step2?.config?.address?.map((ad) => ad.type)];
    }
    if (
      values.step2.customizeConfig &&
      values.step2.customizeConfig[selectedIndex]?.court?.length > 0
    ) {
      where.package.config.court = JSON.parse(
        JSON.stringify(values.step2.customizeConfig[selectedIndex].court)
      ).map((ob) => ob.type);
    } else {
      where.package.config.court = [...values.step2?.config?.court?.map((ad) => ad.type)];
    }
    if (
      values.step2.customizeConfig &&
      values.step2.customizeConfig[selectedIndex]?.reference?.length > 0
    ) {
      where.package.config.reference = values.step2.customizeConfig[selectedIndex].reference;
    }
    if (values.step2.customizeConfig && where?.package?.config?.tempIdentity?.length) {
      where.package.config.identity = [...where.package.config.tempIdentity];
      delete where['package']['customizeConfig'];
    }
    if (
      where.package?.config?.address?.length === 1 &&
      where?.package?.config?.address[0].type === 'ANY_1' &&
      values?.step3?.address?.config?.length === 1
    ) {
      where.package.config.address = [
        ...Object.keys(AddressArr).filter((ob) => ob !== values.step3.address.config[0].type)
      ];
    }
    if (
      where?.package?.config?.court?.length === 1 &&
      where?.package?.config?.court[0]?.type === 'ANY_1' &&
      values?.step3?.court?.config?.length === 1
    ) {
      where.package.config.court = [
        ...Object.keys(AddressArr).filter((ob) => ob !== values.step3.court.config[0].type)
      ];
    }

    delete where['candidate']['formConfig'];
    if (isBulkUpload) {
      where['candidates'] = tableData;
      where['category_id'] = categoryTypeId;
      where['invite'] = true;
      where['company_id'] = companyId;
      if (fileId) {
        where['file_id'] = fileId;
      }
      where['added_by_sa'] = true;
      delete where['candidate'];
      candidatesBulkUploadAPI(where)
        .then((response) => {
          toast.success('Candidates created successfully');
          props.history.replace(location.pathname, null);
          props.history.push({
            pathname: '/company/candidates',
            search: `?id=${companyId}`
          });
        })
        .catch((error) => {
          errToastMessage(error);
          setIsSubmitting(false);
        });
    } else {
      checkCategoriesAndTagsAreValid(where);
    }
  };

  const checkCategoriesAndTagsAreValid = async (payload) => {
    const values = payload?.candidate;
    const selectedCategory = values?.category;
    const selectedTags = values?.tags;

    if (
      (!selectedCategory || !selectedCategory?.id) &&
      (!selectedTags || selectedTags.length === 0)
    ) {
      submitIndividualCandidate(payload);
      setIsSubmitting(false);
      return;
    }

    try {
      const config = await props.getAddCandidateConfig(companyId);
      const categoriesList = config?.categories;
      const tags = config?.tags;
      const res = validateCategoriesAndTags(selectedCategory, selectedTags, categoriesList, tags);
      if (isEmpty(res)) {
        submitIndividualCandidate(payload);
        setIsSubmitting(true);
        return;
      }
      setCategoriesOrTagsDeleted(res);
      handleStepChange(1);
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
      console.error('checkCategoriesAndTagsAreValid Error: ', err);
    }
  };

  const submitIndividualCandidate = (where) => {
    if (where?.candidate?.altPhone) {
      where.candidate['phone_numbers'] = [];
      where.candidate['phone_numbers'].push({
        belongs_to: 'CANDIDATE',
        data: where?.candidate?.altPhone
      });
      delete where?.candidate?.altPhone;
      delete where?.candidate?.altCountryCode;
    }

    if (where?.candidate?.category?.id) {
      where['candidate']['category_id'] = where['candidate']['category']['id'];
    }
    delete where['candidate']['category'];
    createCandidateAPI(where)
      .then((response) => {
        toast.success('Candidate created successfully');
        let candidateId = response?.data?.data?.candidate?.id;

        props.history.push({
          pathname: '/candidate/view',
          search: `?id=${candidateId}&company_id=${companyId}`
        });
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
        setIsSubmitting(false);
      });
  };

  const handleSelectedIndex = (index = null) => {
    setSelectedIndex(index);
  };

  const handleStepSubmit = (type, formValues, nextStep = null) => {
    let currentValues = { ...values };
    for (let index = 0; index < type.length; index++) {
      currentValues[type[index]] = formValues[index];
    }
    setValues(currentValues);
    if (nextStep && nextStep !== 'submit' && currentStep !== nextStep) {
      handleStepChange(nextStep);
    } else if (nextStep === 'submit') {
      startSubmit();
    }
  };

  const getMappedConfig = (config = {}) => {
    const conf = Object.assign({}, config);

    if (config.tempIdentity && config.tempIdentity.length) {
      conf.identity = config.tempIdentity;
    }
    return conf;
  };

  const breadCrumbsArray = [
    { label: 'Home', href: `${AppConstants.baseURL}dashboard` },
    { label: 'Companies', href: `${AppConstants.baseURL}companies` },
    {
      label: companyDetails?.name || 'Company Name',
      href: `${AppConstants.baseURL}company/view?id=${companyId}`
    },
    {
      label: 'Candidates',
      href: `${AppConstants.baseURL}company/candidates?id=${companyId}`
    }
  ];

  const getFormBasedOnStep = (step) => {
    switch (step) {
      case 1:
        return (
          <BasicDetails
            companyId={companyId}
            ctError={categoriesOrTagsDeleted}
            setCtError={setCategoriesOrTagsDeleted}
            handleStepSubmit={(type, formValues, nextStep) =>
              handleStepSubmit(type, formValues, nextStep)
            }
            handleStepChange={(step) => handleStepChange(step)}
            savedValues={values && values.step1 ? values.step1 : null}
          />
        );
      case 2:
        return (
          <PackageListing
            handleStepSubmit={(type, formValues, nextStep) =>
              handleStepSubmit(type, formValues, nextStep)
            }
            handleStepChange={(step) => handleStepChange(step)}
            savedValues={values && values.step2 ? values.step2 : null}
            packageList={props.addCandidateConfig?.packages || []}
            handleSelectedIndex={handleSelectedIndex}
            selectedIndex={selectedIndex}
            companyName={companyDetails?.name}
            isAadhaarAllowed={companyDetails?.isAdhaarAllowed === 1 ? true : false}
            companyBillingType={props?.addCandidateConfig?.billingType}
            isBulkUpload={isBulkUpload}
            history={props.history}
            goBackToBulkUpload={() => {
              props?.finalData?.setCurrentStep(2);
            }}
          />
        );
      case 3:
        return (
          <SubmitContext.Provider value={{ startSubmit, isSubmitting }}>
            <SelectedPackage
              handleStepSubmit={(type, formValues, nextStep) =>
                handleStepSubmit(type, formValues, nextStep)
              }
              handleStepChange={(step) => handleStepChange(step)}
              selectedIndex={selectedIndex}
              savedValues={values && values.step3 ? values.step3 : null}
              step1Values={values && values.step1 ? values.step1 : null}
              step2Values={
                values &&
                values.step2 &&
                values.step2.customizeConfig &&
                values.step2.customizeConfig[selectedIndex]
                  ? {
                      ...values.step2,
                      config: getMappedConfig(values.step2.customizeConfig[selectedIndex])
                    }
                  : values && values.step2
                  ? values.step2
                  : null
              }
              isAadhaarAllowed={companyDetails?.isAdhaarAllowed === 1 ? true : false}
              tax={companyDetails.tax}
              originalConfig={(values && values.step2) || null}
              initialValStep3={Step3Values}
              companyBillingType={props?.addCandidateConfig?.billingType}
              isBulkUpload={isBulkUpload}
              bulkCandidates={tableData?.length}
            />
          </SubmitContext.Provider>
        );
      default:
        return (
          <BasicDetails
            handleStepSubmit={(type, formValues, nextStep) =>
              handleStepSubmit(type, formValues, nextStep)
            }
            handleStepChange={(step) => handleStepChange(step)}
            savedValues={values && values.step1 ? values.step1 : null}
          />
        );
    }
  };

  return (
    <>
      {!props?.finalData?.isBulkUpload && (
        <BreadCrumbs linksArray={breadCrumbsArray} current={'Create Candidate '} />
      )}
      {props.addCandidateConfig ? (
        <>{getFormBasedOnStep(currentStep)}</>
      ) : (
        <Box mt={3} mb={3} className={classes.progressContainer}>
          <CircularProgress data-testid='circularProgress' size={30} />
        </Box>
      )}
    </>
  );
};
