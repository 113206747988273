import { connect } from 'react-redux';
import { compose } from 'redux';

import { getAdmins as getUsersAPI } from '../../../actions/admin';

const mapStateToProps = (state) => {
    return {
        usersData: state.admins,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUsers: (limit, offset, includeCompanyAdmins, name, email, accessLevel, status) =>
            dispatch(getUsersAPI(limit, offset, includeCompanyAdmins, name, email, accessLevel, status)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
