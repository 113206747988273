import React, { useEffect } from 'react';
import { errToastMessage, getErrorMessage } from '../../utils/Utlities';
import { removeAuthToken, removeCookie } from '../../api/storage';
import { logout as logoutAPI } from '../../api/admin';

export default (props) => {
  const { history } = props;
  useEffect(() => {
    logoutAPI()
      .then((response) => {
        removeAuthToken();
        removeCookie('isExpirySet');
        localStorage.clear();
        props.history.push({
          pathname: '/login',
          state: { from: history.location }
        });
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(getErrorMessage(error));
      });
  }, []);

  return null;
};
