import React, { useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import styles from './Communication.module.scss';
import Button from '../../../../core-components/Button';
import EmailContent from './EmailContent';
import EmailPreview from './EmailPreview/EmailPreview';
import { toast } from 'react-toastify';
import { errToastMessage, getErrorMessage } from './../../../../utils/Utlities';

import { editEmailCommunication } from '../../../../api/company';

const Communication = (props) => {
  const [emailContentData, setEmailContentData] = useState('');
  const [isValidForm, setIsValidForm] = useState(false);
  const [resetFormData, setResetFormData] = useState(false);
  const companyId = new URLSearchParams(document.location.search).get('company_id');

  const handleSubmit = () => {
    const data = {
      bgvConfigId: props?.formConfig?.id,
      companyName: props?.formConfig?.company?.name,
      companyUuid: props?.formConfig?.company?.uuid,
      companyId: companyId,
      hrNote:
        emailContentData?.hrInviteNoteDefaultOrCustom == 1
          ? emailContentData?.defaultHrInviteNote
          : emailContentData?.hrInviteNote,
      hrWarningNote:
        emailContentData?.hrWarningNoteDefaultOrCustom == 1
          ? emailContentData?.defaultHrWarningNote
          : emailContentData?.hrWarningNote,
      friendlyName: emailContentData?.friendlyName,
      isBrandEnabled: emailContentData?.isBrandEnabled,
      logoUrlId: emailContentData?.logoUrlId,
      hrNoteStatus: emailContentData?.hrInviteNoteStatus ? 1 : 0,
      hrWarningNoteStatus: emailContentData?.hrWarningNoteStatus ? 1 : 0,
      useLogoInInviteMail: emailContentData?.useLogoInInviteMail
    };

    editEmailCommunication(data)
      .then((resp) => {
        props.getCompanyBGVConfig(companyId);
        toast.success('Details Updated Successfully');
      })
      .catch((error) => {
        setIsValidForm(true);
        errToastMessage(error);
      });
  };

  return (
    <Card className={styles.communicationCard}>
      <div className={styles.tabInfoText}>
        Customize the background check invitation and consent reminder emails that we send to your
        candidates to align with your company's requirements.
      </div>
      <Row>
        <Col>
          <EmailContent
            setEmailContentData={(values) => setEmailContentData(values)}
            isValidForm={(value) => setIsValidForm(value)}
            resetFormData={resetFormData}
            clearResetFormData={setResetFormData}
          />
        </Col>
        <Col>
          <EmailPreview emailContentData={emailContentData} company={props.formConfig?.company} />
        </Col>
      </Row>
      <div className={styles.buttonGroup}>
        <Button
          className={styles.cancelButton}
          onClick={() => {
            setResetFormData(true);
          }}
          label='Cancel'
        />
        <Button
          className={styles.submitButton}
          disabled={!isValidForm}
          onClick={() => {
            setIsValidForm(false);
            handleSubmit();
          }}
          label='Save'
        />
      </div>
    </Card>
  );
};

export default Communication;
