import React, { useState } from 'react';

import { toast } from 'react-toastify';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { IconButton, Dialog, Input, Button, Typography, Box } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

//Icons
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { fileUpload as fileUploadAPI } from '../api/admin';
import { errToastMessage, getErrorMessage, validDocTypes } from '../utils/Utlities';
import { SubmitButton, CancelButton } from './FormButtons';
import { getFileMimeType } from '../components/SuperAdmin/frontend-common/core-components/Utilities/utilities';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  activeBtn: {
    margin: theme.spacing(1),
    width: '180px',
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  cancelBtn: {
    margin: theme.spacing(1),
    width: '160px',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit'
  },
  input: {
    display: 'none'
  },
  filename: { margin: theme.spacing(1) },
  error: {
    fontSize: '12px',
    color: '#f44336',
    margin: 0
  }
}));

export default (props) => {
  const classes = useStyles();
  const [fileValue, setFileValue] = useState(null);
  const [fileName, setFileName] = useState('');

  const companyId = new URLSearchParams(document.location.search).get('company_id') || null;
  const candidateId = new URLSearchParams(document.location.search).get('id') || null;

  const [uploading, setUploading] = useState(false);
  const [uploadfileError, setUploadfileError] = useState(false);
  const [url, setUrl] = useState('');

  const handleFileChange = (event) => {
    setUploading(true);
    setFileValue(event.currentTarget.files[0]);
    setFileName(event.currentTarget.files[0].name);
    let fileType = '';
    var blob = event.currentTarget.files[0];
    var fileReader = new FileReader();
    fileReader.onloadend = () => {
      var arr = new Uint8Array(fileReader.result).subarray(0, 4);
      var header = '';
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      // Check the file signature against known types
      fileType = getFileMimeType(header, blob);
      if (!validDocTypes.invoice.includes(fileType)) {
        setUploadfileError(true);
        setUploading(false);
      } else {
        setUploadfileError(false);
        fileUploadAPI({
          candidateId,
          companyId,
          file: blob,
          fileType: props.uploadFileType
        })
          .then((response) => {
            setUrl(response.data.data);
            setUploading(false);
          })
          .catch((error) => {
            console.error(error);
            errToastMessage(error);
            setUploading(false);
          });
      }
    };

    // copy here
    // copy end
    if (blob instanceof Blob) {
      {
        /** To avoid TyperError issue reported by sentry, need to check if param is an Blob type or not */
      }
      fileReader.readAsArrayBuffer(blob);
    } else {
      console.error(
        `Failed to execute 'readAsArrayBuffer' on 'FileReader': parameter is not of type 'Blob'`
      );
    }
  };
  //function to handle generate consent
  const handleSubmitHandler = () => {
    props.submit(url, fileName);
  };
  const handleClose = () => {
    setFileValue(null);
    setFileName('');
    setUrl('');
    props.handleClose();
  };

  const getFileName = (fileUrl, fileNameUrl) => {
    return (
      <a href={fileUrl} target='_blank'>
        {fileNameUrl}
      </a>
    );
  };

  const isValidForm = !uploading && fileValue && !uploadfileError;

  return (
    <Dialog onClose={handleClose} aria-labelledby='popup-title' open={props.open} fullWidth={true}>
      <DialogTitle id='popup-title' onClose={handleClose}>
        {props.title}
      </DialogTitle>
      <DialogContent>
        <Input
          inputProps={{ accept: validDocTypes.invoice }}
          className={classes.input}
          id='contained-button-file'
          onChange={handleFileChange}
          type='file'
        />
        <label htmlFor='contained-button-file'>
          <Button
            variant='contained'
            color='default'
            startIcon={<CloudUploadIcon />}
            component='span'
          >
            {props.label}
          </Button>
          <span className={classes.filename}>
            {fileName
              ? getFileName(url, fileName)
              : props.fileUrl
              ? getFileName(props.fileUrl, props.fileName)
              : 'No file chosen'}
          </span>
        </label>
        {uploading ? (
          <Box mt={1}>
            <Typography>File Uploading...</Typography>
          </Box>
        ) : null}
        {uploadfileError && (
          <div className={classes.error}>
            Please provide a valid format ({validDocTypes.invoice})
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <CancelButton handleClose={handleClose} />
        <SubmitButton
          isValidForm={isValidForm}
          label={props.submitButtonLabel}
          type='button'
          handleClick={handleSubmitHandler}
        />
      </DialogActions>
    </Dialog>
  );
};
