import React from 'react';
import { getIn } from 'formik';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  IconButton,
  Tooltip,
  Zoom,
  OutlinedInput,
  FormControl,
  FormHelperText
} from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles((theme) => ({
  titleGrid: {
    display: 'flex',
    alignItems: 'center'
  },
  required: {
    color: '#db3131'
  },
  disabled: {
    pointerEvents: 'unset !important',
    cursor: 'not-allowed !important'
  },
  iconButton: {
    padding: 4
  }
}));
export default (props) => {
  const classes = useStyles();

  const { field, form, handleChange, toggleNumberOfChecksSelected } = props;

  const handleOnClick = (type) => {
    let value = parseInt(field.value);
    if (type === 'ADD') {
      handleInputValueChange(value + 1);
      toggleNumberOfChecksSelected(type);
    } else if (type === 'SUB') {
      handleInputValueChange(value - 1);
      toggleNumberOfChecksSelected(type);
    }
  };

  const handleInputValueChange = (value) => {
    const newValue = value ? String(value).replace(/^0+|[^0-9]/g, '') || '0' : '0';

    form.setFieldValue(field.name, newValue);
    handleChange && handleChange(newValue);
  };

  return (
    <Grid container spacing={1} direction={'row'}>
      <Grid item container md={1} xl={1} lg={1} sm={1} xs={1}></Grid>
      <Grid item container md={6} xl={6} lg={6} sm={6} xs={6}>
        <FormControl
          variant='outlined'
          fullWidth={true}
          error={
            form && getIn(form.errors, field.name) && getIn(form.touched, field.name) ? true : false
          }
        >
          <Grid item container spacing={1}>
            <Grid item md={2} xl={2} lg={2} sm={2} xs={2} className={classes.titleGrid}>
              <Typography variant='body1'>Total:</Typography>
            </Grid>
            <Grid item xl={3} md={3} lg={3} sm={4} xs={4}>
              <OutlinedInput
                {...field}
                {...props}
                type={'text'}
                classes={{ disabled: classes.disabled }}
                value={field.value || 0}
                onChange={(event) => {
                  handleInputValueChange(event.target.value || 0);
                }}
              />
            </Grid>
            <Grid
              item
              container
              xl={2}
              md={2}
              lg={2}
              sm={4}
              xs={4}
              direction={'column'}
              alignItems={'flex-start'}
            >
              <IconButton
                aria-label='Increment'
                color={'primary'}
                onClick={() => {
                  handleOnClick('ADD');
                }}
                classes={{
                  disabled: classes.disabled
                }}
                disabled={
                  (props.limit ? parseInt(field.value) >= parseInt(props.limit) : false) ||
                  props.disabled
                }
                className={classes.iconButton}
              >
                <Tooltip title='Increment' arrow={true} TransitionComponent={Zoom}>
                  <KeyboardArrowUpIcon style={{ fontSize: 18 }} />
                </Tooltip>
              </IconButton>
              <IconButton
                aria-label='Decrement'
                color={'primary'}
                onClick={() => {
                  handleOnClick('SUB');
                }}
                disabled={parseInt(field.value) <= 0}
                className={classes.iconButton}
                classes={{
                  disabled: classes.disabled
                }}
              >
                <Tooltip title='Decrement' arrow={true} TransitionComponent={Zoom}>
                  <KeyboardArrowDownIcon style={{ fontSize: 18 }} />
                </Tooltip>
              </IconButton>
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xl={1} md={1} lg={1} sm={1} xs={1}></Grid>
            <Grid item xl={10} md={10} lg={10} sm={10} xs={10}>
              <FormHelperText id='component-error-text'>
                {form && getIn(form.errors, field.name) && getIn(form.touched, field.name)
                  ? form && getIn(form.errors, field.name)
                  : null}
              </FormHelperText>
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
};
