import React, { useEffect, useState } from 'react';
import { forEach, isEmpty, has, isEqual } from 'lodash';
import moment from 'moment';

import { Typography, makeStyles } from '@material-ui/core';

import Table from '../../../core-components/Table';
import BreadCrumbs from '../../../core-components/BreadCrumbs';
import AppConstants from '../../../core-components/AppConstants';
import { getCompanyNameHyperlink, convertToIndianNumeration } from '../../../utils/Utlities';

//this is for each row the heading creation  with whatever id for headcells
function createData(date, user, company, details) {
  return { date, user, company, details };
}

const headCells = [
  {
    id: 'date',
    label: 'Timestamp',
    align: 'center'
  },
  {
    id: 'user',
    label: 'User',
    align: 'center'
  },
  {
    id: 'company',
    label: 'Company',
    align: 'center'
  },
  {
    id: 'details',
    label: 'Details',
    align: 'center'
  }
];

const useStyles = makeStyles((theme) => ({
  rupeeSymbol: {
    fontFamily: 'Roboto'
  }
}));

export default function OverallPackageCreditLogs(props) {
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const { overallPaymentLogs } = props;
  const breadCrumbsArray = [{ label: 'Home', href: `${AppConstants.baseURL}dashboard` }];
  const packageType = new URLSearchParams(document.location.search).get('package_type');
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    getOverallPaymentLogs(rowsPerPage, offset);
  }, [new URLSearchParams(document.location.search).get('package_type')]);
  useEffect(() => {
    if (has(overallPaymentLogs, 'data')) {
      let rowsData =
        overallPaymentLogs?.data?.response &&
        overallPaymentLogs?.data?.response?.length &&
        getRowData(overallPaymentLogs.data.response);
      setLoading(overallPaymentLogs.loading);
      setRows(rowsData || []);
      setTotalCount(overallPaymentLogs.data.count);
    }
  }, [overallPaymentLogs]);

  const getRowData = (values) => {
    let logsRow = [];
    forEach(values, function (value, key) {
      if (!isEmpty(value)) {
        let rowData = createData(
          moment(value.createdAt).format('DD MMM YYYY hh:mm A'),
          value.users && value.users.role
            ? `${value.users.role}: ${value.users.name} (${value.users.id})`
            : '-',
          getCompanyNameHyperlink(value.company?.name, value.company?.id),
          (packageType === 'PREPAID'
            ? getPrepaidDetails(value?.details)
            : getPostpaidDetails(value?.details)) || '-'
        );
        logsRow.push(rowData);
      }
    });
    return logsRow;
  };

  const getPostpaidDetails = (inputStr) => {
    const patternCreditLimit = /Change credit limit \((\d+|\bUNLIMITED\b) -> (\d+|\bUNLIMITED\b)\)/;
    const patternGlobalAddon =
      /Change postpaid global addon configuration \(([^)]+) (\d+) -> (\d+)\)/;
    const costPerCanidate = /Cost per candidate /;
    const checkCost = /check cost /;

    const matchesCreditLimit = inputStr.match(patternCreditLimit);
    const matchesGlobalAddon = inputStr.match(patternGlobalAddon);
    const matchesCostPerCandidate = inputStr.match(costPerCanidate);
    const matchesCheckCost = inputStr.match(checkCost);

    if (matchesCreditLimit) {
      const amount1 = matchesCreditLimit[1];
      const amount2 = matchesCreditLimit[2];

      return (
        <span>
          Change credit limit (
          {!isEqual(amount1, 'UNLIMITED') ? (
            <span className={classes.rupeeSymbol}>&#8377;</span>
          ) : (
            ''
          )}
          {isEqual(amount1, 'UNLIMITED')
            ? 'UNLIMITED'
            : convertToIndianNumeration(parseInt(amount1))}{' '}
          -&gt;{' '}
          {!isEqual(amount2, 'UNLIMITED') ? (
            <span className={classes.rupeeSymbol}>&#8377;</span>
          ) : (
            ''
          )}
          {isEqual(amount2, 'UNLIMITED')
            ? 'UNLIMITED'
            : convertToIndianNumeration(parseInt(amount2))}
          )
        </span>
      );
    } else if (matchesGlobalAddon || matchesCostPerCandidate || matchesCheckCost) {
      const output = inputStr.replace(/(\d+)/g, (match, minusSign, number) => {
        if (minusSign === '-') {
          return `${minusSign}<span>&#8377;</span>${convertToIndianNumeration(parseInt(number))}`;
        } else {
          return `<span>&#8377;</span>${convertToIndianNumeration(parseInt(number))}`;
        }
      });

      return <span dangerouslySetInnerHTML={{ __html: output }} class={classes.rupeeSymbol} />;
    } else {
      return inputStr;
    }
  };

  const getPrepaidDetails = (inputStr) => {
    const pattern = /Change credit balance /;
    const patternTwo = /Cost Per Candidate :/;
    const patternThree = /(cost )(\d+)( -> )(\d+)/g;
    const patternFour = /Credits deducted: /;
    const patternFive = /Credits added: /;
    const patternSix = /Recharge for (\d+) credits./;
    const patternSeventh = /bought with (\d+) credits/;

    if (
      !(
        pattern.test(inputStr) ||
        patternTwo.test(inputStr) ||
        patternThree.test(inputStr) ||
        patternFour.test(inputStr) ||
        patternFive.test(inputStr) ||
        patternSix.test(inputStr) ||
        patternSeventh.test(inputStr)
      )
    ) {
      return inputStr;
    }

    let replacedStr = '';

    if (pattern.test(inputStr)) {
      replacedStr = inputStr.replace(/(-?)(\d+)/g, (match, minusSign, number) => {
        if (minusSign === '-') {
          return `${minusSign}<span>&#8377;</span>${convertToIndianNumeration(parseInt(number))}`;
        } else {
          return `<span>&#8377;</span>${convertToIndianNumeration(parseInt(number))}`;
        }
      });
      return (
        <span dangerouslySetInnerHTML={{ __html: replacedStr }} className={classes.rupeeSymbol} />
      );
    }

    replacedStr = inputStr.replace(patternThree, (match, prefix, number1, arrow, number2) => {
      return `${prefix}<span>&#8377;</span>${convertToIndianNumeration(
        parseInt(number1)
      )}${arrow}<span>&#8377;</span>${convertToIndianNumeration(parseInt(number2))}`;
    });

    replacedStr = replacedStr.replace(
      /Cost Per Candidate : (\d+)/g,
      (match, number) =>
        `Cost Per Candidate : <span>&#8377;</span>${convertToIndianNumeration(parseInt(number))}`
    );

    replacedStr = replacedStr.replace(
      /Credits deducted: (-?)(\d+)/g,
      (match, minusSign, number) => {
        if (minusSign === '-') {
          return `Credits deducted: (${minusSign}<span>&#8377;</span>${convertToIndianNumeration(
            parseInt(number)
          )})`;
        } else {
          return `Credits deducted: (<span>&#8377;</span>${convertToIndianNumeration(
            parseInt(number)
          )})`;
        }
      }
    );

    replacedStr = replacedStr.replace(/Credits added: (\d+)/g, (match, minusSign, number) => {
      return `Credits added: <span>&#8377;</span>${convertToIndianNumeration(parseInt(number))}`;
    });

    if (patternSix.test(inputStr)) {
      replacedStr = replacedStr.replace(/(\d+)/g, (match, minusSign, number) => {
        return `<span>&#8377;</span>${convertToIndianNumeration(parseInt(number))}`;
      });
    }

    if (patternSeventh.test(inputStr)) {
      replacedStr = replacedStr.replace(/with (\d+)/, (match, minusSign, number) => {
        return `with <span>&#8377;</span>${convertToIndianNumeration(parseInt(number))}`;
      });
    }

    return (
      <span dangerouslySetInnerHTML={{ __html: replacedStr }} className={classes.rupeeSymbol} />
    );
  };

  const getOverallPaymentLogs = (rowsPerPage, offset) => {
    setLoading(true);
    props
      .getOverallPaymentLogs(rowsPerPage, offset, packageType)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const handleChangePage = (newPage) => {
    setOffset(newPage);
    setLoading(true);
    getOverallPaymentLogs(rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setOffset(0);
    setLoading(true);
    getOverallPaymentLogs(parseInt(value, 10), 0);
  };

  return (
    <>
      <BreadCrumbs linksArray={breadCrumbsArray} current={'Overall Payment Log'} />
      <Typography variant='h4' component='h3'>
        {`Overall Package / Credit Logs (${packageType === 'PREPAID' ? 'Prepaid' : 'Postpaid'})`}
      </Typography>
      <Table
        headCells={headCells}
        rowData={rows}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={offset}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        loading={loading || overallPaymentLogs.loading}
      />
    </>
  );
}
