import React, { useEffect, useState } from 'react';
import { forEach, isEmpty, has, debounce } from 'lodash';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, Zoom, Typography } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Grid, Box } from '@material-ui/core';
import { getCompanyNameHyperlink, getTrimmedValue } from '../../../utils/Utlities';
import { COMPANY_STATUSES } from '../../../utils/Statuses';
import Table from '../../../core-components/Table';
import DeletePopup from '../../../core-components/ConfirmationPopup';
import SearchInput from '../../../core-components/SearchInput';
import Button from '../../../core-components/Button';
import BreadCrumbs from '../../../core-components/BreadCrumbs';
import AppConstants from '../../../core-components/AppConstants';
import Dropdown from '../../../core-components/DropdownInput';

import ViewModal from './ViewUser';
import CreateModal from './CreateUser';

//this is for each row the heading creation  with whatever id for headcells
function createData(index, company, id, name, email, mobile, createdAt) {
  return { index, company, id, name, email, mobile, createdAt };
}

const useStyles = makeStyles({
  btnDiv: {
    display: 'flex'
  },
  deleteBtn: {
    color: '#467eac',
    cursor: 'pointer'
  },
  editBtn: {
    color: '#3788ac',
    cursor: 'pointer'
  }
});

const headCells = [
  {
    id: 'index',
    label: '#',
    align: 'left'
  },
  {
    id: 'company',
    label: 'Company',
    align: 'center'
  },
  {
    id: 'id',
    label: 'User ID',
    align: 'center'
  },
  {
    id: 'name',
    label: ' Name',
    align: 'center'
  },

  {
    id: 'email',
    label: 'Email',
    align: 'center'
  },
  {
    id: 'mobile',
    label: 'Mobile',
    align: 'center'
  },
  {
    id: 'createdAt',
    label: 'Created At',
    align: 'center'
  }
];

export default function EnhancedTable(props) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const [title, setTitle] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [userName, setUserName] = useState(null);

  const [includeCompanyAdmins, setIncludeCompanyAdmins] = useState(true);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [accessLevel, setAccessLevel] = useState(null);
  const [status, setStatus] = useState(null);

  const classes = useStyles();

  const accessLevelValues = [
    { label: 'All', value: 'all' },
    { label: 'Admin', value: 5 },
    { label: 'HR', value: 10 }
  ];

  //create  modal
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const handleClickOpenCreateModal = () => {
    setOpenCreateModal(true);
  };
  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleClickOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const deleteService = () => {};

  //view modal
  const [openViewModal, setOpenViewModal] = useState(false);

  const handleClickOpenViewModal = () => {
    setOpenViewModal(true);
  };
  const handleCloseViewModal = () => {
    setOpenViewModal(false);
  };

  const breadCrumbsArray = [{ label: 'Home', href: `${AppConstants.baseURL}dashboard` }];

  //on mount call API
  useEffect(() => {
    setLoading(true);
    props.getUsers(rowsPerPage, offset, includeCompanyAdmins, name, email, accessLevel, status);
  }, []);

  useEffect(() => {
    if (has(props.usersData, 'rows')) {
      let rowsData =
        props.usersData &&
        props.usersData.rows &&
        props.usersData.rows.length &&
        getRowData(props.usersData.rows);
      setLoading(false);
      setRows(rowsData || []);
      setTotalCount(props.usersData.count);
    }
  }, [props.usersData]);

  const getRowData = (values) => {
    let companyRow = [];
    forEach(values, function (value, key) {
      if (!isEmpty(value)) {
        let rowData = createData(
          key + 1 + offset * rowsPerPage,
          getCompanyNameHyperlink(
            value.companyUserMapping?.company?.name,
            value.companyUserMapping?.companyId
          ),
          value.id,
          value.name,
          value.email,
          value.mobile,
          value.createdAt
            ? moment(value.createdAt).utc('GMT+5:30').format('DD MMM YYYY hh:mm A')
            : ''
        );
        companyRow.push(rowData);
      }
    });
    return companyRow;
  };

  //these are filters
  const getNameFilter = () => {
    return (
      <SearchInput
        placeholder={'Search Name'}
        handleSearch={debounce((name) => {
          applyNameFilter(name?.trim());
        }, 500)}
      />
    );
  };

  const getEmailFilter = () => {
    return (
      <SearchInput
        placeholder={'Search Email'}
        handleSearch={debounce((email) => {
          applyEmailFilter(email?.trim());
        }, 500)}
        type='email'
      />
    );
  };
  const getAccessLevelFilter = () => {
    return (
      <Dropdown
        dropdownValues={accessLevelValues}
        label={'Access Level'}
        value={'all'}
        handleSelect={(value) => {
          handleAccessLevelDropdownSelect(value);
        }}
      />
    );
  };

  const buttonInfoEdit = (userId, userName, userDetails) => {
    return (
      <div className={classes.btnDiv}>
        <Tooltip TransitionComponent={Zoom} title='Edit' arrow={true}>
          <EditOutlinedIcon
            className={classes.editBtn}
            onClick={() => {
              setTitle('Edit');
              setUserId(userId);
              setUserName(userName);
              setUserDetails(userDetails);
              handleClickOpenCreateModal();
            }}
          />
        </Tooltip>
        <Tooltip TransitionComponent={Zoom} title='Delete' arrow={true}>
          <DeleteOutlineIcon
            className={classes.deleteBtn}
            onClick={() => {
              setUserId(userId);
              setUserName(userName);
              setUserDetails(userDetails);
              handleClickOpenDeleteModal();
            }}
          />
        </Tooltip>
      </div>
    );
  };

  const applyNameFilter = (n) => {
    setName(n);
    setOffset(0);
    setLoading(true);
    props.getUsers(rowsPerPage, 0, includeCompanyAdmins, n, email, accessLevel, status).then(() => {
      setLoading(false);
    });
  };
  const applyEmailFilter = (e) => {
    setEmail(e);
    setOffset(0);
    setLoading(true);
    props.getUsers(rowsPerPage, 0, includeCompanyAdmins, name, e, accessLevel, status).then(() => {
      setLoading(false);
    });
  };

  const handleAccessLevelDropdownSelect = (value) => {
    setLoading(true);
    switch (value) {
      case 5:
        setAccessLevel(5);
        props.getUsers(rowsPerPage, 0, includeCompanyAdmins, name, email, 5, status).then(() => {
          setLoading(false);
        });

        break;
      case 10:
        setAccessLevel(10);
        props.getUsers(rowsPerPage, 0, includeCompanyAdmins, name, email, 10, status).then(() => {
          setLoading(false);
        });

        break;

      default:
        setAccessLevel(null);
        props.getUsers(rowsPerPage, 0, includeCompanyAdmins, name, email, null, status).then(() => {
          setLoading(false);
        });
        break;
    }
  };

  const handleChangePage = (newPage) => {
    setOffset(newPage);
    setLoading(true);
    props
      .getUsers(rowsPerPage, newPage, includeCompanyAdmins, name, email, accessLevel, status)
      .then(() => {
        setLoading(false);
      });
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setOffset(0);
    setLoading(true);
    props
      .getUsers(parseInt(value, 10), 0, includeCompanyAdmins, name, email, accessLevel, status)
      .then(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <BreadCrumbs linksArray={breadCrumbsArray} current={'All Users'} />
      <Typography variant='h4' component='h3'>
        All Users
      </Typography>
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item lg={3} md={4} sm={4} xs={10}>
            {getNameFilter()}
          </Grid>
          <Grid item lg={3} md={4} sm={4} xs={10}>
            {getEmailFilter()}
          </Grid>
        </Grid>
      </Box>
      <Table
        headCells={headCells}
        rowData={rows}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={offset}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        loading={loading}
        // filterArr={filterArr}
      />
      <ViewModal
        open={openViewModal}
        handleClose={handleCloseViewModal}
        title={userName}
        userDetails={userDetails}
      />
      <CreateModal
        open={openCreateModal}
        title={title}
        details={userDetails}
        handleClose={handleCloseCreateModal}
      />
      <DeletePopup
        title={'Delete  Admin'}
        // serviceId={serviceId}
        content={`Are you sure you want to delete ${userName}?`}
        buttonLabel={'Delete'}
        buttonColor={'secondary'}
        open={openDeleteModal}
        handleSubmit={deleteService}
        handleClose={handleCloseDeleteModal}
      />
    </>
  );
}
