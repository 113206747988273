import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { mergeWith, isEmpty } from 'lodash';
import { compose } from 'redux';
import { setCompanyDetail } from '../../../../../../actions/admin';
import validationSchema from './GSTModal.validation';
import { updateFinanceConfig } from '../../../../../../api/admin';
import { errToastMessage, getErrorMessage, toSnakeCase } from '../../../../../../utils/Utlities';
import { toast } from 'react-toastify';
const mapStateToProps = (state) => {
  return {
    updateCompanyData: (!isEmpty(state.updateCompanyData) && state.updateCompanyData) || {}
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCompanyDetails: () => dispatch(setCompanyDetail())
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        isGstNotRegistered: '',
        gstId: '',
        tax: 18,
        gstLegalName: '',
        gstTradeName: '',
        gstState: '',
        gstAddress: '',
        svCompanyName: '',
        svTradeCompanyName: '',
        svState: '',
        svAddress: ''
      };
      let propsValue = {};

      const companyData = { ...props.updateCompanyData };
      let companyAddress = '';
      if (companyData?.billingAddress) {
        companyAddress = Object.entries(companyData?.billingAddress)
          .filter((addr) => addr[0] !== 'landmark' && addr[1] !== '')
          .map((r) => r[1])
          .join(', ');
      }
      propsValue = {
        isGstNotRegistered: props.gstId ? false : true,
        gstId: props.gstId,
        tax: companyData?.tax,
        gstLegalName: '',
        gstTradeName: '',
        gstState: '',
        gstAddress: '',
        svCompanyName: companyData?.name?.trim(),
        svTradeCompanyName: companyData?.name?.trim(),
        svState: companyData?.billingAddress?.state?.trim(),
        svAddress: companyAddress
      };

      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (values, { setFieldError, setStatus, setSubmitting, resetForm, props }) => {
      // Need to call after response in API is 200
      let companyState = props.updateCompanyData;
      let gstId = values?.gstId === '' ? null : values?.gstId;
      companyState['gstin'] = values.gstId;
      companyState['tax'] = values.tax;
      const payload = {
        companyId: props.updateCompanyData.id,
        gstin: gstId,
        tax: values.tax
      };
      updateFinanceConfig(toSnakeCase(payload), true)
        .then(() => {
          toast.success('Saved successfully.');
          props.setCompanyDetails(companyState);
          props.onClose(true);
          resetForm();
        })
        .catch((error) => {
          errToastMessage(error);
        });
    }
  })
);
