import { connect } from 'react-redux';
import { compose } from 'redux';

import { isEmpty } from 'lodash';

import {
    globalSearch as searchCandidateAPI,
    invalidateGlobalSearch as invalidateGlobalSearchAPI,
} from '../../../actions/admin';

const mapStateToProps = (state) => {
    return {
        searchData: (!isEmpty(state.search) && state.search) || {},
        user: !isEmpty(state.profile) ? { ...state.profile} : null
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        searchCandidate: (data) => dispatch(searchCandidateAPI(data)),
        invalidateGlobalSearch: () => dispatch(invalidateGlobalSearchAPI()),
    };
};
export default compose(connect(mapStateToProps, mapDispatchToProps));
