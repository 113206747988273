export const VERIFICATION_ICONS = {
  values: [
    { value: 'Identity Verification', icon: 'Identity', width: 28, height: 16 },
    { value: 'Employment Verification', icon: 'Employment', width: 29, height: 16 },
    { value: 'Address Verification', icon: 'Address', width: 21, height: 16 },
    { value: 'Education Verification', icon: 'Education', width: 35, height: 16 },
    { value: 'World Verification', icon: 'World', width: 23, height: 16 },
    { value: 'Drug Verification', icon: 'Drug', width: 22, height: 16 },
    { value: 'Court Record Verification', icon: 'Court_Record', width: 26, height: 16 },
    { value: 'Credit Check', icon: 'CIBIL_Credit_Score', width: 28, height: 16 },
    { value: 'Reference Verification', icon: 'Reference', width: 30, height: 16 },
    { value: 'Custom Check Verification', icon: 'Custom', width: 30, height: 16 }
  ]
};

export const VERIFICATION_ICONS_CHECKS = {
  identity: { value: 'Identity Verification', icon: 'Identity', width: 28, height: 16 },
  employment: { value: 'Employment Verification', icon: 'Employment', width: 29, height: 16 },
  address: { value: 'Address Verification', icon: 'Address', width: 21, height: 16 },
  education: { value: 'Education Verification', icon: 'Education', width: 35, height: 16 },
  world: { value: 'World Verification', icon: 'World', width: 23, height: 16 },
  drug: { value: 'Drug Verification', icon: 'Drug', width: 22, height: 16 },
  court: { value: 'Court Record Verification', icon: 'Court_Record', width: 26, height: 16 },
  cibil: { value: 'Credit Check', icon: 'CIBIL_Credit_Score', width: 28, height: 16 },
  reference: { value: 'Reference Verification', icon: 'Reference', width: 30, height: 16 },
  custom: { value: 'Custom Check Verification', icon: 'Custom', width: 30, height: 16 }
};

export const IDENTITY_TYPE = {
  1: 'Any',
  2: 'PAN Card',
  3: 'Driving Licence',
  4: 'Passport',
  5: 'Voter ID',
  6: 'Aadhaar'
};
