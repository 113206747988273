import { connect } from 'react-redux';
import { compose } from 'redux';

import { getFeedback as getFeedbackAPI, invalidateFeedback } from '../../../actions/admin';

const mapStateToProps = (state) => {
  return {
    feedback: state.feedback
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getFeedback: (limit, offset, companyId) => dispatch(getFeedbackAPI(limit, offset, companyId)),
    invalidateFeedback: () => dispatch(invalidateFeedback())
  };
};
export default compose(connect(mapStateToProps, mapDispatchToProps));
