import React, { useState } from 'react';
import { forEach, isEmpty, has } from 'lodash';

import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { Tooltip, Zoom, IconButton } from '@material-ui/core';
import { Box, Grid } from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';

import Table from '../../../core-components/Table';
import DropdownInput from '../../../core-components/DropdownInput';
import AppConstants from '../../../core-components/AppConstants';
import Button from '../../../core-components/Button';
import BreadCrumbs from '../../../core-components/BreadCrumbs';
import CreateQuestion from './CreateQuestion';
import ConfirmationPopup from '../../../core-components/ConfirmationPopup';
import {
  deleteQuestion as deleteQuestionAPI,
  importDefaultQuestions as importDefaultQuestionsAPI
} from '../../../api/admin';
import { errToastMessage, getErrorMessage } from '../../../utils/Utlities';

const useStyles = makeStyles({
  heading: {
    paddingTop: '20px',
    paddingBottom: '20px'
  },
  headingSubText: {
    color: '#8c8c8c',
    fontSize: '18px'
  },
  customBtnStyle: {
    margin: '16px 0px 0px'
  }
});

function createData(incrementId, question, status, createdAt, updatedAt, deleteAction) {
  return { incrementId, question, status, createdAt, updatedAt, deleteAction };
}

const headCells = [
  {
    id: 'incrementId',
    label: '#',
    align: 'left'
  },
  {
    id: 'question',
    label: 'Questions',
    align: 'left'
  },
  {
    id: 'status',
    label: 'Status',
    align: 'center'
  },
  {
    id: 'createdAt',
    label: 'Created At',
    align: 'center'
  },
  {
    id: 'updatedAt',
    label: 'Updated At',
    align: 'center'
  },
  {
    id: 'deleteAction',
    label: 'Actions',
    align: 'center'
  }
];

export default function RefCheckQuestions(props) {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [companyUuId, setCompanyUuId] = useState(
    new URLSearchParams(document.location.search).get('company_uuid') || null
  );
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState('-1');
  const [filterArr, setFilterArr] = useState([]);
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [companyId, setCompanyId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [questionUuid, setQuestionUuid] = useState('');
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);
  const [isDeleteButtonClicked, setIsDeleteButtonClicked] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const breadCrumbsArray = [
    { label: 'Home', href: `${AppConstants.baseURL}dashboard` },
    { label: 'Companies', href: `${AppConstants.baseURL}companies` },
    {
      label: companyName || 'Company Name',
      href: `${AppConstants.baseURL}company/view?id=${companyId}`
    }
  ];

  React.useEffect(() => {
    props.invalidateRefQuestions();
    getQuestions(rowsPerPage, offset);
  }, []);

  React.useEffect(() => {
    if (
      !isEmpty(props.questions) &&
      has(props.questions, 'loading') &&
      props?.questions?.loading === false
    ) {
      let rows =
        props?.questions?.all[offset] &&
          props?.questions?.all[offset]?.companyQuestions?.rows &&
          props?.questions?.all[offset]?.companyQuestions?.rows?.length
          ? getRowData(props.questions.all[offset].companyQuestions.rows)
          : [];
      setData(rows);
      setCount(props.questions.all[offset].companyQuestions.count);
      setLoading(props.questions.loading);
      setCompanyId(
        props.questions &&
        props.questions.all[offset] &&
        props.questions.all[offset].companyData &&
        props.questions.all[offset].companyData.id
      );
      setCompanyName(
        props.questions &&
        props.questions.all[offset] &&
        props.questions.all[offset].companyData &&
        props.questions.all[offset].companyData.name
      );
    }
  }, [props.questions]);

  const getQuestions = (rowsPerPage, offset, status = null) => {
    setLoading(true);
    const statusToSend = status === '-1' ? null : status;
    props
      .getRefQuestions(companyUuId, rowsPerPage.toString(), offset.toString(), statusToSend)
      .then(() => { });
  };

  const getRowData = (values) => {
    let questionsRow = [];
    forEach(values, function (value, key) {
      if (!isEmpty(value)) {
        let rowData = createData(
          key + 1 + offset * rowsPerPage,
          value.questionText,
          value.status ? 'Yes' : 'No',
          // value.createdAt,
          (value.createdAt &&
            moment(value.createdAt).isValid() &&
            moment(value.createdAt).format('DD MMM YYYY hh:mm A')) ||
          null,
          (value.updatedAt &&
            moment(value.updatedAt).isValid() &&
            moment(value.updatedAt).format('DD MMM YYYY hh:mm A')) ||
          null,
          deleteAction(value.uuid, value.status)
        );
        questionsRow.push(rowData);
      }
    });
    return questionsRow;
  };

  const getStatusFilter = () => {
    return (
      <DropdownInput
        label={'Statuses'}
        value={status}
        dropdownValues={[
          { label: 'All', value: '-1' },
          { label: 'Yes', value: 1 },
          { label: 'No', value: 0 }
        ]}
        handleSelect={(status) => {
          applyStatusFilter(status);
        }}
      />
    );
  };

  const deleteAction = (uuid = null, status = false) => {
    return (
      <IconButton
        aria-label='delete'
        disabled={!status ? true : false}
        onClick={() => {
          setQuestionUuid(uuid);
          setShowConfirmationPopup(true);
        }}
      >
        <Tooltip TransitionComponent={Zoom} title='Delete' arrow={true}>
          <DeleteOutlineIcon color={!status ? 'disabled' : 'primary'} />
        </Tooltip>
      </IconButton>
    );
  };

  const applyStatusFilter = (status) => {
    setStatus(status);
    setOffset(0);
    getQuestions(rowsPerPage, 0, status === '-1' ? '' : status);
  };

  const handleChangePage = (newPage) => {
    setOffset(newPage);
    getQuestions(rowsPerPage, newPage, status);
  };

  const handleChangeRowsPerPage = (value) => {
    setOffset(0);
    setRowsPerPage(parseInt(value, 10));
    getQuestions(parseInt(value, 10), 0, status);
  };

  const importQuestions = () => {
    if (offset === 0 && count === 0) {
      importDefaultQuestionsAPI({ companyUuid: companyUuId })
        .then((response) => {
          getQuestions(rowsPerPage, offset, status);
        })
        .catch((error) => {
          console.error(error);
          errToastMessage(error);
        });
    }
  };
  return (
    <>
      <BreadCrumbs linksArray={breadCrumbsArray} current={'Reference check configuration'} />
      <div className={classes.heading}>
        <Typography variant='h5' component='h5'>
          {'Reference check configuration'}
        </Typography>
        <Typography className={classes.headingSubText} variant='caption' component='h6'>
          {companyName || 'Company Name'}
        </Typography>
      </div>
      {offset === 0 && !loading && count === 0 && status === '' ? (
        <>
          <Typography className={classes.headingSubText} variant='h6' component='h6'>
            {'Start configuring Questions'}
          </Typography>
          <Button
            label={'Import default Questions'}
            color={'primary'}
            variant={'contained'}
            onClick={() => {
              importQuestions();
            }}
            className={classes.customBtnStyle}
            startIcon={<ImportExportIcon />}
          />
        </>
      ) : (
        <>
          <Button
            label={'Add New Question'}
            color={'primary'}
            variant={'contained'}
            onClick={() => {
              setShowCreatePopup(true);
            }}
            className={classes.customBtnStyle}
            startIcon={<ControlPointIcon />}
          />
          <Box mt={3}>
            <Grid container spacing={2}>
              <Grid item lg={3} md={4} sm={4} xs={10}>
                {getStatusFilter()}
              </Grid>
            </Grid>
          </Box>
          <Table
            headCells={headCells}
            rowData={data}
            totalCount={count}
            rowsPerPage={rowsPerPage}
            page={offset}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            loading={loading}
          // filterArr={filterArr}
          />
        </>
      )}
      <CreateQuestion
        open={showCreatePopup}
        title={'Add New Question'}
        label={'Statuses'}
        companyId={companyId}
        companyUuid={companyUuId}
        handleClose={() => {
          setShowCreatePopup(false);
          getQuestions(rowsPerPage, offset, status);
        }}
      />
      <ConfirmationPopup
        open={showConfirmationPopup}
        title={'Are you sure ?'}
        content={'Are you sure you want to disable this question ?'}
        handleSubmit={() => {
          if (isDeleting) return;
          setIsDeleting(true);
          deleteQuestionAPI(questionUuid, companyUuId)
            .then((response) => {
              setQuestionUuid('');
              toast.success('Question disabled successfully');
              setShowConfirmationPopup(false);
              getQuestions(rowsPerPage, offset, status);
            })
            .catch((error) => {
              setQuestionUuid('');
              console.error(error);
              toast.error(getErrorMessage(error));
              setShowConfirmationPopup(false);
            })
            .finally(() => {
              setTimeout(() => {
                setDeleteButtonDisabled(false);
              }, 100);
            });
        }}
        handleClose={() => {
          setShowConfirmationPopup(false);
          setIsDeleteButtonClicked(false);
        }}
        buttonLabel={'Confirm'}
        buttonDisabled={deleteButtonDisabled}
      />
    </>
  );
}
