import React, { useEffect, useState } from 'react';
import { forEach, isEmpty, has, debounce } from 'lodash';

import { Checkbox, FormControlLabel, Typography, makeStyles } from '@material-ui/core';
import { Box, Grid } from '@material-ui/core';

import Table from '../../../../core-components/Table';
import SearchInput from '../../../../core-components/SearchInput';
import Button from '../../../../core-components/Button';
import BreadCrumbs from '../../../../core-components/BreadCrumbs';
import AppConstants from '../../../../core-components/AppConstants';
import { getCompanyNameHyperlink, getCreditInRupee } from '../../../../utils/Utlities';

//this is for each row the heading creation  with whatever id for headcells
function createData(company, credits, actions) {
  return { company, credits, actions };
}

const headCells = [
  {
    id: 'company',
    label: 'Company Name',
    align: 'center'
  },
  {
    id: 'credits',
    label: 'Credit Balance',
    align: 'center'
  },
  {
    id: 'actions',
    label: 'Actions',
    align: 'center'
  }
];

export default function PrepaidCreditList(props) {
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [companyName, setCompanyName] = useState('');
  const [onlyNegative, setOnlyNegative] = useState(false);

  const breadCrumbsArray = [{ label: 'Home', href: `${AppConstants.baseURL}dashboard` }];

  useEffect(() => {
    setLoading(true);
    let where = {
      rowsPerPage,
      offset,
      companyName,
      onlyNegative
    };
    props.getCreditBalance(where).then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    // we should save packages data in rows
    if (has(props.creditBalance, 'credits')) {
      let rowsData =
        props?.creditBalance &&
        props?.creditBalance?.credits &&
        props?.creditBalance?.credits?.length &&
        getRowData(props.creditBalance.credits);
      setRows(rowsData || []);
      setTotalCount(props.creditBalance.count);
    }
  }, [props.creditBalance]);

  const getRowData = (values) => {
    let creditRow = [];
    forEach(values, function (value, key) {
      if (!isEmpty(value)) {
        let rowData = createData(
          getCompanyNameHyperlink(value.company?.name, value.company?.id),
          getCreditInRupee(value.balance, 0),
          viewButton(value)
        );
        creditRow.push(rowData);
      }
    });
    return creditRow;
  };

  const viewButton = (value) => {
    return (
      <Button
        label={'View'}
        color={'green'}
        onClick={() => {
          props?.history.push({
            pathname: '/credits/balance/company',
            search: `?id=${value.company.id}&type=PREPAID`,
            state: {
              details: value || null
            }
          });
        }}
      />
    );
  };

  //these are filters
  const getCompanyFilter = () => {
    return (
      <SearchInput
        placeholder={'Search Company'}
        handleSearch={debounce((companyName) => {
          applyCompanyFilter(companyName?.trim() || '');
        }, 500)}
      />
    );
  };

  const getOnlyNegativeFilter = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            color='primary'
            onChange={(e) => applyOnlyNegativeFilter(e.target.checked)}
            checked={onlyNegative}
          />
        }
        label={'Companies with negative balance'}
      />
    );
  };

  const getCreditBalance = (limit, offset, companyName, onlyNegative) => {
    setLoading(true);
    props
      .getCreditBalance({ limit, offset, companyName, onlyNegative })
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const applyCompanyFilter = (companyName) => {
    setCompanyName(companyName);
    setOffset(0);
    getCreditBalance(rowsPerPage, 0, companyName, onlyNegative);
  };

  const applyOnlyNegativeFilter = (onlyNegative) => {
    setOnlyNegative(onlyNegative);
    setOffset(0);
    getCreditBalance(rowsPerPage, 0, companyName, onlyNegative);
  };

  const handleChangePage = (newPage) => {
    setOffset(newPage);
    setLoading(true);
    getCreditBalance(rowsPerPage, newPage, companyName, onlyNegative);
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setOffset(0);
    setLoading(true);
    getCreditBalance(parseInt(value, 10), 0, companyName, onlyNegative);
  };

  return (
    <>
      <BreadCrumbs linksArray={breadCrumbsArray} current={'Credits'} />
      <Typography variant='h4' component='h3'>
        Credit Balance
      </Typography>
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item lg={4} md={6} sm={4} xs={10}>
            {getCompanyFilter()}
          </Grid>
          <Grid item lg={4} md={6} sm={4} xs={10}>
            {getOnlyNegativeFilter()}
          </Grid>
        </Grid>
      </Box>
      <Table
        headCells={headCells}
        rowData={rows}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={offset}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        loading={loading}
      />
    </>
  );
}
