import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import { getAddressGeocode } from '../api/admin';
import { getErrorMessage, isValidPhoneNumber, getTrimmedValue } from '../utils/Utlities';
import validator from 'validator';

const NULLABLE = Yup.string().nullable(true);
const REQUIRED = (message) => {
  if (!message) return Yup.string().required('This is a required field.');

  return Yup.string().required(`Please enter ${message}`);
};

/**
 * * Regular expressions
 */
const INDIAN_PIN_CODE = /^[1-9][0-9]{5}$/;
const BRAND_NAME_VALIDATION = /^[a-zA-Z0-9 &().]*$/;
const NOTE_VALIDATION = /^[A-Za-z0-9\s.:;,!()?'"_/-]*$/;
const ALPHANUMERIC_STRICT = /^[a-zA-Z0-9]/;
const FOR_STRING = /^[a-zA-Z][a-zA-Z0-9_\s-]+$/;
const ALPHABETICAL_STRING =
  /(?!\.)(?!\-)(?!.*\.$)(?!.*\-$)(?!\s)(?!.*\.\.)(?!.*\-\-)(?!.*\-\.)(?!.*\.\-)(?!.*\-\s)^(([a-zA-Z]-?)*[a-zA-Z\.\s]){2,}$/;
const ALPHANUMERIC_STRING = /^[^-\s][a-zA-Z0-9_\s-]+$/;
const SIMPLE_STRING = /^[^-\s]/;
const FOR_EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.[A-Za-z]{2,63})+(\.[A-Za-z]{2,63})?$/;
const FOR_UAN = /(?!0{12})^\d\d{11}$/;
const FOR_URL = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i'
);
const FOR_PKG_OR_SUBTYPE_NAME = /^[^-\s][a-zA-Z0-9_\s-\+\-\=\.\,\:\(\)\[\]\<\>\&\#\!\|\"\/\\]+$/;
const FOR_GST = /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}Z[0-9a-zA-Z]{1}$/;
const CIBIL_SCORE = new RegExp('^(-)?(N/A|n/a|[Nn][Aa])?([0-9]+)?$', 'i');
export const PINCODE_RULES_FOR_OTHER_COUNTRIES_THAN_INDIA =
  /^(?!0+$)[0-9A-Za-z]{1,5}(\s?[0-9A-Za-z]{1,5})*$/;

export const PINCODE_RULES_FOR_INDIA = /^[1-9][0-9]{5}$/;

export const CUSTOM_CHECK_RULES = /^(?!.*\.\.)[a-zA-Z0-9().\s\-\\[\]/]+$/;
const USER_CATEGORY_REGEX = /^[a-zA-Z0-9 \-_/().]*$/;
/**
 * Rules for LinkedIn URL validation:
 *
 * The following regular expression validates LinkedIn profile URLs based on the provided rules:
 *
 * - linkedin\.com/in : Match the literal "linkedin.com/in" substring.
 *
 * Valid LinkedIn profile URLs should contain "linkedin.com/in" anywhere in the URL.
 *
 * Invalid URLs would not include "linkedin.com/in" in the URL.
 */
export const LINKEDIN_URL_VALIDATION = /^(?:https?:\/\/)?(?:www\.)?linkedin\.com\/in/;

export const validateFriendlyName = (value) => {
  if (!BRAND_NAME_VALIDATION.test(value)) {
    return 'Please provide a valid brand name';
  }
};

export const validateNote = (value) => {
  if (!NOTE_VALIDATION.test(value)) {
    return 'Please provide a valid note.';
  }
};

const STRING_CHECK = (message) => {
  return Yup.string()
    .trim()
    .transform((currentValue) => {
      return getTrimmedValue(currentValue, true);
    })
    .matches(SIMPLE_STRING, `Please enter a valid ${message}`);
};

/**
 * Rules:
 * * No special character allowed.
 * * Trailing and leading white-spaces or integers not valid.
 * * Only alphanumeric characters allowed.
 */
const STRICT_STRING_CHECK = (message) => {
  return Yup.string()
    .trim()
    .transform((currentValue) => getTrimmedValue(currentValue, true))
    .matches(FOR_STRING, `Please enter a valid ${message}`);
};

const ALPHANUMERIC_STRING_CHECK = (message) => {
  return Yup.string()
    .trim()
    .transform((currentValue) => getTrimmedValue(currentValue, true))
    .matches(ALPHANUMERIC_STRING, `Please enter a valid ${message}`);
};

const ALPHABETICAL_STRING_CHECK = (message) => {
  return Yup.string()
    .trim()
    .transform((currentValue) => getTrimmedValue(currentValue, true))
    .matches(ALPHABETICAL_STRING, `Please enter a valid ${message}`);
};

const LINKEDIN_CHECK = Yup.string()
  .transform((currentVal) => getTrimmedValue(currentVal, false))
  .matches(LINKEDIN_URL_VALIDATION, 'Please provide a valid LinkedIn URL.');

const EMAIL_CHECK = Yup.string().trim().matches(FOR_EMAIL, 'Please enter a valid email address');

const UAN_CHECK = Yup.string()
  .trim()
  .transform((currentValue) => getTrimmedValue(currentValue, false))
  .matches(FOR_UAN, 'Please enter a valid UAN');

const concatenateSchema = ({ schema, isNullable, isRequired, isRequiredMessage }) => {
  if (isRequired)
    return schema.concat(isRequiredMessage ? REQUIRED(isRequiredMessage) : REQUIRED());

  if (isNullable) {
    return schema.concat(NULLABLE);
  }

  return schema;
};
/**
 * * getNameValidationSchema() : To validate field without leading whitespace.
 * * getStrictNameValidationSchema() : To validate field without any special characters.
 * * getEmailValidationSchema() : To validate email field.
 * * getPhoneValidationSchema() : To validate phone number fields.
 * * getUANValidationSchema() : To validate UAN number.
 * * getURLValidationSchema() : To validate URL fields.
 * * getPackageOrSubtypeNameValidationSchema() : To validate package or Subtype names.
 * * getGSTValidationSchema() : To validate GST fields.
 * * getAlphabeticalStringValidationSchema() : To validate all the name fields.(not allowing dot as first charater and consecutive dot)
 */

/**
 * @param {Custom message to show at the end of warning message} message
 * @param {Set to true if field is required} isRequired
 * @param {Set to true if field is nullable} isNullable
 */
export const getNameValidationSchema = ({ message, isRequired, isRequiredMessage, isNullable }) => {
  let schema = STRING_CHECK(message);

  schema = concatenateSchema({
    schema: schema,
    isNullable: isNullable,
    isRequired: isRequired,
    isRequiredMessage: message
  });

  return schema;
};

export const getNameValidationObject = ({ message, isRequired, isNullable }) => {
  return Yup.object().shape({
    value: getNameValidationSchema({
      message: message,
      isRequired: isRequired,
      isNullable: isNullable
    })
  });
};

export const getStrictNameValidationSchema = ({ message, isRequired, isNullable }) => {
  let schema = STRICT_STRING_CHECK(message);

  schema = concatenateSchema({
    schema: schema,
    isNullable: isNullable,
    isRequired: isRequired,
    isRequiredMessage: message
  });

  return schema;
};

export const getAlphabeticalStringValidationSchema = ({ message, isRequired, isNullable }) => {
  let schema = ALPHABETICAL_STRING_CHECK(message);

  schema = concatenateSchema({
    schema: schema,
    isNullable: isNullable,
    isRequired: isRequired,
    isRequiredMessage: message
  });

  return schema;
};

export const getEmailValidationSchema = ({ isRequired, isNullable }) => {
  let schema = Yup.string()
    .trim()
    .transform((currentValue) => getTrimmedValue(currentValue, false))
    .test({
      message: 'Please enter a valid email address',
      test: (value) => {
        if (value) {
          const isEmailValid = validator.isEmail(value);
          const isFirstCharAlphaNumeric = value[0].match(ALPHANUMERIC_STRICT) !== null;
          const isLastCharOfUsernameAlphaNumeric = isEmailValid
            ? value[value.search(/@/) - 1].match(ALPHANUMERIC_STRICT) !== null
            : false;
          return isEmailValid && isFirstCharAlphaNumeric && isLastCharOfUsernameAlphaNumeric;
        }
        return true;
      }
    });

  schema = concatenateSchema({ schema: schema, isNullable: isNullable, isRequired: isRequired });

  return schema;
};

// Export as a Yup object
export const getEmailValidationObject = ({ isRequired, isNullable }) => {
  return Yup.object().shape({
    value: getEmailValidationSchema({
      isRequired: isRequired,
      isNullable: isNullable
    })
  });
};

export const getPhoneValidationSchema = ({ fieldName, isNullable, isRequired }) => {
  let schema = Yup.string()
    .trim()
    .transform((currentValue) => getTrimmedValue(currentValue, false))
    .test({
      name: fieldName,
      message: 'Please add a valid Phone number',
      test: (value) => {
        if (value) {
          return isValidPhoneNumber(value);
        } else {
          return true;
        }
      }
    });

  schema = concatenateSchema({ schema: schema, isNullable: isNullable, isRequired: isRequired });

  return schema;
};

export const getUANValidationSchema = ({ isRequired, isNullable }) => {
  let schema = UAN_CHECK;
  schema = concatenateSchema({ schema: schema, isNullable: isNullable, isRequired: isRequired });
  return schema;
};

export const getURLValidationSchema = ({ isRequired, isNullable, isRequiredMessage }) => {
  let schema = Yup.string().lowercase().trim().matches(FOR_URL, {
    message: 'Enter a valid website.',
    excludeEmptyString: false
  });

  schema = concatenateSchema({
    schema: schema,
    isNullable: isNullable,
    isRequired: isRequired,
    isRequiredMessage: isRequiredMessage
  });

  return schema;
};

/**
 * @param {It can be either Package or Subtype} type
 */
export const getPackageOrSubtypeNameValidationSchema = ({ isRequired, type }) => {
  let schema = Yup.string().trim().matches(FOR_PKG_OR_SUBTYPE_NAME, `Please enter a ${type} name`);

  schema = concatenateSchema({
    schema: schema,
    isRequired: isRequired,
    isRequiredMessage: type
  });

  return schema;
};

export const getGSTValidationSchema = () => {
  return Yup.string().trim().matches(FOR_GST, 'Please enter a valid GSTIN');
};
export const getCibilScoreValidationSchema = ({ message }) => {
  return Yup.string().trim().matches(CIBIL_SCORE, `Please enter a valid ${message}`);
};

export const getAlphaNumericStringValidationSchema = ({ message, isRequired, isNullable }) => {
  let schema = ALPHANUMERIC_STRING_CHECK(message);

  schema = concatenateSchema({
    schema: schema,
    isNullable: isNullable,
    isRequired: isRequired,
    isRequiredMessage: message
  });

  return schema;
};

/* Use this validation method only when state, pinCode, country and city fields are there  */
export const getPinCodeValidation = ({ country, pinCode, state, setState, setFieldValue }) => {
  /* If pin-code is of India we first check it through the regex rules */
  if (country === 'India') {
    const regexForIndianPinCode = INDIAN_PIN_CODE;
    const isValidIndianPinCode = regexForIndianPinCode.test(pinCode);

    setState(isValidIndianPinCode);
  }

  /**Commented the below code as it was used at EditSettings & Add DP only where we implemented
   * auto-fill of city and state based on pincode. But now we do not need
   */

  // const data = {
  //   postalCode: pinCode.trim(),
  //   country: country,
  // };

  // getAddressGeocode(data)
  //   .then((res) => {
  //     let city = res?.data?.results[0].address_components.filter((add) =>
  //       add.types.includes('locality')
  //     );
  //     if (isEmpty(city)) {
  //       city = res?.data?.results[0].address_components.filter((add) =>
  //         add.types.includes('administrative_area_level_2')
  //       );
  //     }

  //     if (city?.length)
  //       setFieldValue('city', city[0]?.long_name)

  //     setState(true);
  //   })
  //   .catch((err) => {
  //     setState(true);
  //   });
};

/**
 * Validation Schema for Linkedin URL fields.
 */
export const getLinkedinUrlValidation = ({ isRequired = false, isNullable = true }) => {
  let schema = LINKEDIN_CHECK;
  schema = concatenateSchema({ schema: schema, isNullable: isNullable, isRequired: isRequired });
  return schema;
};

/**
 * Validation Schema for Brand Name fields.
 */
export const getBrandNameValidationSchema = ({ isRequired = false, isNullable = true }) => {
  let schema = Yup.string()
    .trim()
    .transform((currentValue) => getTrimmedValue(currentValue, true))
    .matches(BRAND_NAME_VALIDATION, 'Please provide a valid brand name');

  schema = concatenateSchema({ schema: schema, isNullable: isNullable, isRequired: isRequired });
  return schema;
};

export const getCustomCheckNameValidation = ({ isRequired = false, isNullable = true }) => {
  let schema = Yup.string()
    .matches(CUSTOM_CHECK_RULES, 'Please enter valid check name')
    .test('required', 'This is a required field.', (value) => {
      return value && value.trim() !== '';
    })
    .test('no-leading-trailing-dots', 'Please enter valid check name', (value) => {
      return value && value[0] !== '.' && value[value.length - 1] !== '.';
    });

  return schema;
};

const USER_CATEGORY_CHECK = (message) => {
  return Yup.string()
    .transform((currentVal) => getTrimmedValue(currentVal, true))
    .matches(USER_CATEGORY_REGEX, `Please enter a valid ${message}`);
};

export const userCategoryValidationSchema = ({ message, isRequired, isNullable }) => {
  let schema = USER_CATEGORY_CHECK(message);
  schema = concatenateSchema({
    schema: schema,
    isNullable: isNullable,
    isRequired: isRequired,
    isRequiredMessage: message
  });
  return schema;
};

export const allowOnlyNumbersRegex = /[^\d]+/g;
