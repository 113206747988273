// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".G72BNBp1zgRDWXoTqajR {\n  line-height: 24px;\n}\n.G72BNBp1zgRDWXoTqajR .CqZzx8MGcfXK14DsmO4A .T_4K7jpr9aIZPLFISULk {\n  text-align: center;\n}\n.G72BNBp1zgRDWXoTqajR .CqZzx8MGcfXK14DsmO4A .iUCBb4YZM5LfAocPSAYs {\n  padding-left: 0;\n}\n.G72BNBp1zgRDWXoTqajR .CqZzx8MGcfXK14DsmO4A .IJu21Zu06XX70uvLVXcL {\n  padding-left: 0;\n  margin-top: 10px;\n  font-size: 14px;\n  color: #999999;\n}\n.G72BNBp1zgRDWXoTqajR .CqZzx8MGcfXK14DsmO4A .TdxFyqovbdr5JtbOO6Ux {\n  padding-left: 0;\n  font-size: 14px;\n  margin-top: 10px;\n  color: #e97070;\n}\n.G72BNBp1zgRDWXoTqajR .CqZzx8MGcfXK14DsmO4A .mq63EpO32qZNkMklLqZ8 {\n  margin-top: 18px;\n}\n.G72BNBp1zgRDWXoTqajR .d3qKqslyOoeFmG8QQuCo {\n  font-weight: 600;\n  font-size: 16px;\n  color: #262626;\n}\n.G72BNBp1zgRDWXoTqajR ._L65N5Dqb_rsreXxfKwa {\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 22px;\n  color: #595959;\n}\n.G72BNBp1zgRDWXoTqajR .FrnZ6ZkU7wnC5aJ9rcrL button {\n  border-radius: 2px;\n  padding: 5px 16px;\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 24px;\n}\n.G72BNBp1zgRDWXoTqajR .FrnZ6ZkU7wnC5aJ9rcrL .YuhxohUvsmuCwTyPIwf2 {\n  background-color: #2755fe;\n  color: #ffffff;\n  cursor: pointer;\n}\n.G72BNBp1zgRDWXoTqajR .FrnZ6ZkU7wnC5aJ9rcrL .INiTIYad7_ubfAYrGf28 {\n  background: #f5f5f5;\n  color: #bfbfbf;\n  border: 1px solid #d9d9d9;\n  cursor: not-allowed;\n}\n.G72BNBp1zgRDWXoTqajR .FrnZ6ZkU7wnC5aJ9rcrL .A8ddPeyCDbj9EInrpJQ8 {\n  background: #ffffff;\n  color: #2755fe;\n  border: none;\n  box-shadow: none;\n}\n.G72BNBp1zgRDWXoTqajR .FrnZ6ZkU7wnC5aJ9rcrL .JizgaO13VkfZTy2PB2d2 {\n  background: #ffffff;\n  color: #2755fe;\n  border: none;\n  box-shadow: none;\n  cursor: not-allowed;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmationModal": "G72BNBp1zgRDWXoTqajR",
	"confirModalBody": "CqZzx8MGcfXK14DsmO4A",
	"questionImage": "T_4K7jpr9aIZPLFISULk",
	"confirmTextContainer": "iUCBb4YZM5LfAocPSAYs",
	"confirmTextBody": "IJu21Zu06XX70uvLVXcL",
	"confirmTextBodyRed": "TdxFyqovbdr5JtbOO6Ux",
	"consent": "mq63EpO32qZNkMklLqZ8",
	"confirmModalHeading": "d3qKqslyOoeFmG8QQuCo",
	"confirmText": "_L65N5Dqb_rsreXxfKwa",
	"confirmModalFooter": "FrnZ6ZkU7wnC5aJ9rcrL",
	"activeConfirmBtn": "YuhxohUvsmuCwTyPIwf2",
	"disabledConfirmBtn": "INiTIYad7_ubfAYrGf28",
	"cancelConfirmBtn": "A8ddPeyCDbj9EInrpJQ8",
	"disabledCancelConfirmBtn": "JizgaO13VkfZTy2PB2d2"
};
export default ___CSS_LOADER_EXPORT___;
