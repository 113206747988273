import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

export default (props) => {
  //Lib Link : https://tinymce.github.io/tinymce-react/
  const handleEditorChange = (content, editor) => {
    props.textChange(content);
  };

  return (
    //Currently using API KEY from GANESH.SHIVAKOTI@SPRINGROLE.COM
    <Editor
      apiKey='fa7qt9dodd3rth5fdzs53218hyzo4vlinofkuxveejwfjfca'
      value={props.textValue || ''}
      init={{
        height: 210,
        menubar: false,
        branding: false,
        plugins: ['advlist  lists ', ' paste  wordcount'],
        toolbar:
          'undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat '
      }}
      onEditorChange={handleEditorChange}
    />
  );
};
