import { connect } from 'react-redux';
import { compose } from 'redux';

import { getCompanyRechargeLogs as getCompanyRechargeLogsAPI } from '../../../../../actions/admin';

const mapStateToProps = (state) => {
  return {
    companyRechargeLogs: state.companyRechargeLogs
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyRechargeLogs: (query) => dispatch(getCompanyRechargeLogsAPI(query))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
