import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Link } from '@material-ui/core';
// import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  body: {
    backgroundColor: '#ebecf1',
    padding: '4px 16px',
    margin: '8px 0',
    borderRadius: 8,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.16)'
  },
  activeLink: {
    color: '#2162AB',
    fontWeight: 500,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  text: {
    color: '#757575',
    cursor: 'default'
  }
}));
export default function CustomizedBreadcrumbs(props) {
  const classes = useStyles();
  const handleLinkOnclick = (link) => {
    let isClickEnabled = true;
    if (props?.showPrompt) {
      isClickEnabled = window.confirm('Are you sure you want to leave, there are unsaved changes?');
    }
    if (isClickEnabled) window.location.href = link;
  };
  return (
    <Breadcrumbs
      className={classes.body}
      aria-label='breadcrumb'
      maxItems={4}
      itemsAfterCollapse={2}
      separator={<ArrowRightIcon />}
    >
      {props?.linksArray.map((link, index) => {
        return (
          <Link
            component={'a'}
            key={index}
            className={classes.activeLink}
            onClick={(e) => {
              e.preventDefault();
              handleLinkOnclick(link.href)
            }}
            href={link.href}
          >
            {link.label}
          </Link>
        );
      })}
      <Typography variant='body1' className={classes.text}>
        {props.current}
      </Typography>
      {props?.FutureCrumbsArray
        ? props?.FutureCrumbsArray.map((link, index) => {
          return (
            <Typography key={index} className={classes.text}>
              {link.label}
            </Typography>
          );
        })
        : null}
    </Breadcrumbs>
  );
}
CustomizedBreadcrumbs.defaultProps = {
  current: 'Home', //give current Page Label, should be string
  linksArray: [] //array of object, should contain {label, href} or {label, onClick}, onClick must be a function
};
