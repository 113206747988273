// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fQRdwJWBexeXJ_RzjsqN {\n  margin: 1rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 9999;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "fQRdwJWBexeXJ_RzjsqN"
};
export default ___CSS_LOADER_EXPORT___;
