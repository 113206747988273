import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, sortBy, isArray, forEach, isEmpty, has } from 'lodash';
import { toast } from 'react-toastify';
import validationSchema from './CreateAllUser.validation';

import {
  createAdmin as createAdminAPI,
  updateAdmin as updateAdminAPI
} from '../../../../api/admin';
import { getAdmins as getAdminsAPI } from '../../../../actions/admin';

import { errToastMessage, getErrorMessage } from '../../../../utils/Utlities';

const mapDispatchToProps = (dispatch) => {
  return {
    getAdmins: (limit, offset, includeCompanyAdmins, name, email, accessLevel, status) =>
      dispatch(getAdminsAPI(limit, offset, includeCompanyAdmins, name, email, accessLevel, status))
  };
};

export default compose(
  connect(null, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let propsValue = {};

      if (has(props.details, 'name')) {
        propsValue = props.details;
      } else {
        propsValue = {};
      }
      let storedValues = {
        name: '',
        email: '',
        mobile: '',
        accessLevel: 5
      };

      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    validationSchema,
    enableReinitialize: true,
    handleSubmit: (values, { setFieldError, setStatus, setSubmitting, resetForm, props }) => {
      if (props.title === 'Edit') {
        let finalValue = {
          adminId: props?.details?.id,
          name: values.name,
          email: values.email,
          mobile: values.mobile,
          accessLevel: values.accessLevel
        };
        updateAdminAPI(finalValue)
          .then(() => {
            setSubmitting(false);
            toast.info('User updated successfully');
            props.getAdmins(10, 0, true);
            resetForm();
            props.handleClose();
          })
          .catch((error) => {
            console.error(error);
            errToastMessage(error);
          });
      } else {
        createAdminAPI(values)
          .then(() => {
            setSubmitting(false);
            toast.info('User created successfully');
            props.getAdmins(10, 0, true);
            resetForm();
            props.handleClose();
          })
          .catch((error) => {
            console.error(error);
            errToastMessage(error);
          });
      }
    },
    displayName: 'Create Admins'
  })
);
