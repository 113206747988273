import { connect } from 'react-redux';
import { compose } from 'redux';
import { isEmpty } from 'lodash';

import {
  getPDPackageConfiguration as getPDPackageConfigurationAPI,
  getPackageCompanyList as getPackageCompanyListAPI,
  invalidatePackageCompanyList,
  getPackageDetails as getPackageDetailsAPI,
  invalidatePackageDetails
} from '../../../../../actions/admin';

const mapStateToProps = (state) => {
  return {
    packageConfig: state.packageDetailsPackageConfig,
    packageDetails:
      state.packageDetails && !isEmpty(state.packageDetails) ? state.packageDetails : {},
    companyDetails:
      state && state?.packageList?.all && state.packageList.all.length > 0
        ? state.packageList.all[0].company
        : {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPackageConfig: (packageId) => dispatch(getPDPackageConfigurationAPI(packageId, null)),
    invalidatePackageCompanyList: () => dispatch(invalidatePackageCompanyList()),
    getPackageCompanyList: (data) => dispatch(getPackageCompanyListAPI(data)),
    invalidatePackageDetails: () => dispatch(invalidatePackageDetails()),
    getPackageDetails: (packageId) => dispatch(getPackageDetailsAPI(packageId))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
