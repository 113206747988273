import React, { useEffect, useState } from 'react';
import { Field } from 'formik';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { has, isEmpty, capitalize } from 'lodash';

import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Button,
  Grid,
  CircularProgress,
  InputAdornment
} from '@material-ui/core';

import SaveIcon from '@material-ui/icons/Save';

import FormInput from '../../../core-components/FormInput';
import BreadCrumbs from '../../../core-components/BreadCrumbs';
import AppConstants from '../../../core-components/AppConstants';
import {
  convertFromIndianNumeration,
  convertToIndianNumeration,
  getTrimmedValue
} from '../../../utils/Utlities';

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f9f9f9'
    },
    '&:hover': {
      backgroundColor: '#F7F8FA'
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  activeBtn: {
    marginTop: '30px',
    margin: theme.spacing(1),
    width: '240px',
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  disabledBtn: {
    cursor: 'not-allowed',
    margin: theme.spacing(1),
    height: '44px',
    color: '#dfdfdf',
    borderColor: '#d9d9d9',
    backgroundColor: '#f5f5f5',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '60vh',
    alignItems: 'center'
  },
  saveButtonGrid: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  rupeeSymbol: {
    fontFamily: 'Roboto'
  }
}));

export default function GlobalAddonConfiguration(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [packageType, setPackageType] = useState(null);
  const breadCrumbsArray = [{ label: 'Home', href: `${AppConstants.baseURL}dashboard` }];

  const { values, setFieldValue, handleSubmit, errors, isSubmitting } = props;

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    setPackageType(new URLSearchParams(document.location.search).get('package_type'));
  }, [new URLSearchParams(document.location.search).get('package_type')]);
  useEffect(() => {
    if (packageType) {
      props.getGlobalAddonConfiguration(packageType);
    }
  }, [packageType]);
  useEffect(() => {
    if (has(props.globalAddonConfiguration, 'loading') && !props.globalAddonConfiguration.loading) {
      setLoading(false);
    }
  }, [props.globalAddonConfiguration]);
  //Created array so checks can displayed in order
  const checkNames = [
    'identity',
    'addressDigital',
    'addressPhysical',
    'addressPostal',
    'courtStandard',
    'courtStandardLawfirm',
    'employment',
    'education',
    'educationThirdParty',
    'educationHybrid',
    'reference',
    'drug5Panel',
    'drug7Panel',
    'drug10Panel',
    'cibil',
    'world'
  ];

  const checkHeadings = {
    identity: 'ID Check',
    addressDigital: 'Address Check (Digital)',
    addressPhysical: 'Address Check (Physical)',
    addressPostal: 'Address Check (Postal)',
    courtStandard: 'Court Check (Standard)',
    courtStandardLawfirm: 'Court Check (Standard (Letterhead))',
    employment: 'Employment Check',
    education: 'Education (Official)',
    educationThirdParty: 'Education (Regional)',
    educationHybrid: 'Education (Hybird)',
    reference: 'Reference Check',
    cibil: 'Credit Check',
    drug5Panel: 'Drug Test (5 panel)',
    drug7Panel: 'Drug Test (7 panel)',
    drug10Panel: 'Drug Test (10 panel)',
    world: 'World Check'
  };

  const isValidForm = !isSubmitting && isEmpty(errors);

  return (
    <>
      <BreadCrumbs linksArray={breadCrumbsArray} current={'Global Add-on Configuration'} />
      <Typography variant='h4' component='h3' gutterBottom>
        Global Add-on Configuration ({capitalize(packageType)})
      </Typography>
      {!loading ? (
        <>
          <form onSubmit={handleSubmit}>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Add-on Name</TableCell>
                    <TableCell>Available by default </TableCell>
                    <TableCell>Cost</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {checkNames?.length
                    ? checkNames.map((checkName, index) => {
                        return (
                          <StyledTableRow key={`GAddons_${index}`}>
                            <TableCell>{checkHeadings[checkName] || 'Check Name'}</TableCell>
                            <TableCell>
                              <Checkbox
                                checked={values[checkName]?.isAvailable}
                                color='primary'
                                inputProps={{
                                  'aria-label': 'secondary checkbox'
                                }}
                                onChange={(e) => {
                                  setFieldValue(`${checkName}.isAvailable`, e.target.checked);
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <div className='inputAdornMent'>
                                <Field
                                  disabled={values[checkName]?.isAvailable ? false : true}
                                  name={`${checkName}.cost`}
                                  label='Cost'
                                  variant='outlined'
                                  fieldwidth={'30%'}
                                  component={FormInput}
                                  startAdornment={
                                    <InputAdornment position='start'>
                                      <span className={classes.rupeeSymbol}>&#8377;</span>
                                    </InputAdornment>
                                  }
                                  onChange={(e) => {
                                    let val = getTrimmedValue(e.target.value, false);

                                    if (val) {
                                      val = convertFromIndianNumeration(val);
                                    }

                                    setFieldValue(
                                      `${checkName}.cost`,
                                      convertToIndianNumeration(val)
                                    );
                                  }}
                                />
                              </div>
                            </TableCell>
                          </StyledTableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid className={classes.saveButtonGrid} item container>
              <Button
                variant='contained'
                size='large'
                type='submit'
                className={isValidForm || props.dirty ? classes.activeBtn : classes.disabledBtn}
                disabled={!isValidForm || !props.dirty}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Grid>
          </form>
        </>
      ) : (
        <Grid container spacing={2} className={classes.spinnerContainer}>
          <CircularProgress />
        </Grid>
      )}
    </>
  );
}
