import React from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

export default (props) => {
  const { isUnassigned } = props;
  const handleIncrement = () => {
    props.valueChange(props.value + 1, props.index);
  };

  const handleDecrement = () => {
    props.valueChange(props.value - 1, props.index);
  };

  const displayCounter = props.value > 0;

  return (
    <ButtonGroup size='small' aria-label='small outlined button group'>
      <Button
        onClick={handleIncrement}
        disabled={props.limit <= props.totalCount || props.disabled || isUnassigned}
      >
        <AddIcon fontSize={'small'} />
      </Button>
      {displayCounter && (
        <Button disabled>
          <b>{props.value}</b>
        </Button>
      )}
      {displayCounter && (
        <Button onClick={handleDecrement} disabled={!props.value || isUnassigned}>
          <RemoveIcon fontSize={'small'} />
        </Button>
      )}
    </ButtonGroup>
  );
};
