import React, { useState, useEffect, useRef } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import FileInput from '../../../../../core-components/FileInput';
import styles from './EmailContent.module.scss';
import { getTrimmedValue, getTrimmedValueWithNewLines } from './../../../../../utils/Utlities';
import Image from '../../../../SuperAdmin/frontend-common/core-components/Image/Image';
import { Field } from 'formik';
import Checkbox from '../../../frontend-common/core-components/Checkbox/Checkbox';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
export default function EmailContent(props) {
  const companyId = new URLSearchParams(document.location.search).get('company_id');
  const { values, setFieldValue, formConfig, resetForm, handleChange, dirty } = props;

  const previousStateValues = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isCorrectLogoFormat, setIsCorrectLogoFormat] = useState(true);
  const [brandNameError, setBrandNameError] = useState('');
  const [clearFileInput, setClearFileInput] = useState(false);
  const [isLogosGood, setIsLogosGood] = useState(false);

  const isValidImageUrl = async (url) => {
    if (!url) return null;

    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.src = url;
      img.onload = () => {
        resolve(img.height > 60);
      };
      img.onerror = () => {
        resolve(false);
      };
    });
  };

  const setLogoErrors = async () => {
    const logos = Object.values(values.companyLogos).map((e) => e.logoUrl);
    const isValid = await Promise.all(logos.map(isValidImageUrl));
    if (isValid.includes(false)) {
      setIsLogosGood(false);
    } else {
      setIsLogosGood(true);
    }
  };

  useEffect(() => {
    setLogoErrors();
  }, [values]);

  const hrNoteCount =
    values?.hrInviteNoteDefaultOrCustom == 1
      ? values?.defaultHrInviteNote?.trim()?.length
      : values?.hrInviteNote
      ? values?.hrInviteNote?.trim()?.length
      : values?.defaultHrInviteNote?.trim()?.length;
  const hrWarningNoteCount =
    values?.hrWarningNoteDefaultOrCustom == 1
      ? values?.defaultHrWarningNote?.trim()?.length
      : values?.hrWarningNote
      ? values?.hrWarningNote?.trim()?.length
      : values?.defaultHrWarningNote?.trim()?.length;

  const [hrInviteNoteRadioOptions, setHrInviteNoteRadioOptions] = useState([
    { value: '1', label: 'Default', disable: true },
    { value: '2', label: 'Custom', disable: true }
  ]);
  const [hrWarningNoteRadioOptions, setHrWarningNoteRadioOptions] = useState([
    { value: '1', label: 'Default', disable: true },
    { value: '2', label: 'Custom', disable: true }
  ]);

  const lastSettingValidation = () => {
    return (
      formConfig?.isBrandEnabled === values?.isBrandEnabled &&
      formConfig?.hrInviteNote === values?.hrInviteNote &&
      formConfig?.hrWarningNote === values?.hrWarningNote &&
      formConfig?.hrInviteNoteStatus === values?.hrInviteNoteStatus &&
      formConfig?.hrWarningNoteStatus === values?.hrWarningNoteStatus &&
      (formConfig?.company?.logoUrlId === values?.logoUrlId ||
        (!formConfig?.company?.logoUrlId && !values?.logoUrlId)) &&
      (formConfig?.company?.useLogoInInviteMail === values?.useLogoInInviteMail ||
        (!formConfig?.company?.useLogoInInviteMail && !values?.useLogoInInviteMail)) &&
      previousStateValues?.current?.hrInviteNoteDefaultOrCustom ==
        values?.hrInviteNoteDefaultOrCustom &&
      previousStateValues?.current?.hrWarningNoteDefaultOrCustom ==
        values?.hrWarningNoteDefaultOrCustom
    );
  };
  const isValidForm =
    hrNoteCount >= 10 &&
    hrNoteCount <= 160 &&
    hrWarningNoteCount >= 10 &&
    hrWarningNoteCount <= 160 &&
    !isUploading &&
    values?.isImgLoaded &&
    !brandNameError &&
    !lastSettingValidation();

  useEffect(() => {
    props.getCompanyBgvConfig(companyId);
  }, []);

  useEffect(() => {
    const prevValue = {
      hrInviteNoteDefaultOrCustom:
        formConfig?.hrInviteNoteStatus && !formConfig?.hrInviteNote ? '1' : '2',
      hrWarningNoteDefaultOrCustom:
        formConfig?.hrWarningNoteStatus && !formConfig?.hrWarningNote ? '1' : '2'
    };
    previousStateValues.current = prevValue;
  }, [props.formConfig]);

  useEffect(() => {
    const selectedLogo =
      Object.values(values?.companyLogos)?.find((logo) => logo.id === values?.logoUrlId)?.logoUrl ||
      null;
    props.setEmailContentData({ ...values, logoUrl: selectedLogo });
    props.isValidForm(isValidForm);
    const url = selectedLogo;

    if (url) {
      const img = new window.Image();
      img.src = url;
      img.onload = () => {
        const { naturalWidth: w, naturalHeight: h } = img;
        setFieldValue('logoUrl', w < 60 || h < 60 ? null : values.logoUrl);
      };
    }

    if (values.hrInviteNoteStatus) {
      setHrInviteNoteRadioOptions([
        { value: '1', label: 'Default' },
        { value: '2', label: 'Custom' }
      ]);
    } else {
      //To remove the default selected, I am making the values as 3 and 4
      setFieldValue('hrInviteNoteDefaultOrCustom', '2');
      setHrInviteNoteRadioOptions([
        { value: '3', label: 'Default', disable: true },
        { value: '4', label: 'Custom', disable: true }
      ]);
    }

    if (values.hrWarningNoteStatus) {
      setHrWarningNoteRadioOptions([
        { value: '1', label: 'Default' },
        { value: '2', label: 'Custom' }
      ]);
    } else {
      //To remove the default selected, I am making the values as 3 and 4
      setFieldValue('hrWarningNoteDefaultOrCustom', '2');
      setHrWarningNoteRadioOptions([
        { value: '3', label: 'Default', disable: true },
        { value: '4', label: 'Custom', disable: true }
      ]);
    }
  }, [values, isValidForm]);

  useEffect(() => {
    if (props?.resetFormData) {
      resetForm();
      setClearFileInput(true);
      props.clearResetFormData(false);
    }
  }, [props?.resetFormData]);
  const handleInviteNoteState = (e) => {
    setFieldValue('hrInviteNoteStatus', e.target.checked);
    if (e.target.checked) {
      setFieldValue('hrInviteNoteDefaultOrCustom', '1');
    } else {
      setFieldValue('hrInviteNoteDefaultOrCustom', '2');
    }
  };
  const handleWarningNoteState = (e) => {
    setFieldValue('hrWarningNoteStatus', e.target.checked);
    if (e.target.checked) {
      setFieldValue('hrWarningNoteDefaultOrCustom', '1');
    } else {
      setFieldValue('hrWarningNoteDefaultOrCustom', '2');
    }
  };
  const getTextAreaValue = (TEXT_AREA) => {
    switch (TEXT_AREA) {
      case 'HR_INVITE_NOTE':
        if (values.hrInviteNoteStatus && values.hrInviteNoteDefaultOrCustom === '2') {
          return values.hrInviteNote ? values.hrInviteNote : values.defaultHrInviteNote;
        }
        return values.defaultHrInviteNote;
      case 'HR_WARNING_NOTE':
        if (values.hrWarningNoteStatus && values.hrWarningNoteDefaultOrCustom === '2') {
          return values.hrWarningNote ? values.hrWarningNote : values.defaultHrWarningNote;
        }
        return values.defaultHrWarningNote;
      default:
        break;
    }
  };
  return (
    <div className={styles.emailContentWrapper}>
      <Form>
        <div className={styles.sectionHeader}>
          Brand Name
          <OverlayTrigger
            key={`password_protected`}
            placement='top'
            style
            overlay={
              <Tooltip id={`password_protected`} style={{ width: '200px' }}>
                <p>To edit the brand name please go to Brand settings tab.</p>
              </Tooltip>
            }
          >
            <Image name='info.svg' className={styles.infoIcon} />
          </OverlayTrigger>
        </div>
        <div style={{ position: 'relative' }}>
          <div className={styles.fields}>
            <div className={styles.input}>
              <Form.Control value={values?.friendlyName || ''} placeholder='Brand Name' disabled />
            </div>
          </div>
        </div>
        <Field
          custom
          name='isBrandEnabled'
          value={values.isBrandEnabled}
          onChange={handleChange}
          className={styles.customRadioAndCheckGroup + ' custom-checkbox-style-1'}
          component={Checkbox}
          label='Enable'
        />
        <hr />

        <div style={{ position: 'relative' }}>
          <Form.Group controlId='hrInviteNote'>
            <div className={styles.sectionHeader}>HR Note (In all invite emails)</div>
            <Form.Control
              as='textarea'
              rows={3}
              name='hrInviteNote'
              style={{ resize: 'none' }}
              onInput={(event) => {
                if (event.target.value?.trim()?.length > 160) {
                  event.target.value = event.target.value?.trim()?.slice(0, 160);
                }
              }}
              onBlur={(e) => {
                setFieldValue('hrInviteNote', getTrimmedValueWithNewLines(e.target.value, true));
              }}
              value={getTextAreaValue('HR_INVITE_NOTE')}
              disabled={!values.hrInviteNoteStatus || values.hrInviteNoteDefaultOrCustom != 2}
              placeholder='Type a note...'
              onChange={(e) => {
                setFieldValue('hrInviteNote', e.target.value);
              }}
            />
          </Form.Group>
          <div
            className={styles.hrNoteCharacterLimit}
            style={
              hrNoteCount == 160 || (hrNoteCount < 10 && hrNoteCount > 0)
                ? { color: '#DC3545' }
                : null
            }
          >
            Characters {hrNoteCount} out of 160
          </div>
        </div>

        <div className={styles.controlContainer}>
          <Field
            custom
            name='hrInviteNoteStatus'
            value={values.hrInviteNoteStatus}
            className={styles.customRadioAndCheckGroup + ' custom-checkbox-style-1'}
            component={Checkbox}
            onChange={(e) => handleInviteNoteState(e)}
            label='Enable'
          />
          <RadioGroup
            aria-label='hr-note'
            name='hrInviteNoteDefaultOrCustom'
            value={values.hrInviteNoteDefaultOrCustom}
            className={styles.customRadioAndCheckGroup}
            onChange={handleChange}
          >
            {hrInviteNoteRadioOptions.map((value, index) => {
              return (
                <FormControlLabel
                  key={index}
                  value={value?.value}
                  control={<Radio color='primary' />}
                  label={value?.label}
                />
              );
            })}
          </RadioGroup>
        </div>
        <hr />
        <div style={{ position: 'relative' }}>
          <Form.Group controlId='hrWarningNote'>
            <div className={styles.sectionHeader}>Overdue Note (In all overdue emails)</div>
            <Form.Control
              as='textarea'
              rows={3}
              name='hrWarningNote'
              style={{ resize: 'none' }}
              onInput={(event) => {
                if (event.target.value?.trim()?.length > 160) {
                  event.target.value = event.target.value?.trim()?.slice(0, 160);
                }
              }}
              onBlur={(e) => {
                setFieldValue('hrWarningNote', getTrimmedValueWithNewLines(e.target.value, true));
              }}
              value={getTextAreaValue('HR_WARNING_NOTE')}
              disabled={values.hrWarningNoteDefaultOrCustom != 2}
              placeholder='Type a note...'
              onChange={(e) => {
                setFieldValue('hrWarningNote', e.target.value);
              }}
            />
          </Form.Group>
          <div
            className={styles.hrNoteCharacterLimit}
            style={
              hrWarningNoteCount == 160 || (hrWarningNoteCount < 10 && hrWarningNoteCount > 0)
                ? { color: '#DC3545' }
                : null
            }
          >
            Characters {hrWarningNoteCount} out of 160
          </div>
        </div>

        <div className={styles.controlContainer}>
          <Field
            custom
            name='hrWarningNoteStatus'
            value={values.hrWarningNoteStatus}
            onChange={(e) => handleWarningNoteState(e)}
            className={styles.customRadioAndCheckGroup + ' custom-checkbox-style-1'}
            component={Checkbox}
            label='Enable'
          />
          <RadioGroup
            aria-label='hr-note'
            name='hrWarningNoteDefaultOrCustom'
            value={values.hrWarningNoteDefaultOrCustom}
            className={styles.customRadioAndCheckGroup}
            onChange={handleChange}
          >
            {hrWarningNoteRadioOptions.map((value, index) => {
              return (
                <FormControlLabel
                  key={index}
                  value={value?.value}
                  control={<Radio color='primary' />}
                  label={value?.label}
                />
              );
            })}
          </RadioGroup>
        </div>
        <hr />
        <div className={styles.companyLogo}>
          <div className={styles.heading}>
            Company logo
            <OverlayTrigger
              key={`password_protected`}
              placement='top'
              style
              overlay={
                <Tooltip id={`password_protected`} style={{ width: '200px' }}>
                  <p>To edit the company logo please go to Brand settings tab.</p>
                </Tooltip>
              }
            >
              <Image name='info.svg' className={styles.infoIcon} />
            </OverlayTrigger>
          </div>
          <div className={styles.subheading}>
            Include logo in invite email and candidate reports
          </div>
          <div className={styles.useLogoInInviteMail}>
            <Field
              custom
              name='useLogoInInviteMail'
              className={'custom-checkbox-style-1 ' + styles.cBoxMargin}
              component={Checkbox}
              disabled={
                !isLogosGood ||
                Object.values(values.companyLogos).every((logo) => logo.logoUrl === null)
              }
              label='Enable'
              onChange={(e) => {
                if (e.target.checked) {
                  const selectedLogo =
                    Object.values(values?.companyLogos)?.find(
                      (logo) => logo.id === values?.logoUrlId
                    )?.logoUrl || null;
                  if (!selectedLogo) {
                    const firstKeyWithValue = Object.keys(values?.companyLogos)?.find(
                      (key) => values.companyLogos[key].logoUrl
                    );
                    setFieldValue('logoUrlId', values?.companyLogos[firstKeyWithValue]?.id);
                  }
                  setFieldValue('useLogoInInviteMail', true);
                } else {
                  setFieldValue('useLogoInInviteMail', false);
                  setFieldValue('logoUrlId', formConfig?.company?.logoUrlId);
                }
              }}
            />
          </div>
          {isLogosGood && (
            <div className={styles.companyLogos}>
              {Object.keys(values.companyLogos)
                .sort()
                .map((key) => {
                  return (
                    values.companyLogos[key].logoUrl && (
                      <div
                        className={`${styles.logoContainer} ${
                          values.useLogoInInviteMail &&
                          values.logoUrlId === values.companyLogos[key].id
                            ? styles.active
                            : ''
                        }`}
                        style={{
                          background:
                            values.companyLogos[key].logoType === 'dark' ? '#F2F2F2' : '#6a778b',
                          position: 'relative',
                          pointerEvents: values.useLogoInInviteMail ? 'auto' : 'none'
                        }}
                        onClick={() => {
                          setFieldValue('logoUrlId', values.companyLogos[key].id);
                        }}
                      >
                        <div className={styles.select}>
                          {values.useLogoInInviteMail &&
                          values.logoUrlId === values.companyLogos[key].id ? (
                            <TickCircle />
                          ) : null}
                        </div>
                        <div className={styles.logo}>
                          <img src={values.companyLogos[key].logoUrl} />
                        </div>
                      </div>
                    )
                  );
                })}
            </div>
          )}
        </div>
        {!isCorrectLogoFormat && (
          <div className={styles.imageSizeText}>
            Please provide a valid company logo format (.png, .jpeg, .jpg)
          </div>
        )}
      </Form>
    </div>
  );
}

const TickCircle = () => (
  <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12 22a9.97 9.97 0 0 0 7.071-2.929A9.97 9.97 0 0 0 22 12a9.969 9.969 0 0 0-2.929-7.071A9.969 9.969 0 0 0 12 2a9.969 9.969 0 0 0-7.071 2.929A9.969 9.969 0 0 0 2 12a9.969 9.969 0 0 0 2.929 7.071A9.969 9.969 0 0 0 12 22Z'
      fill='#2755FE'
    />
    <path
      d='m8 12 3 3 6-6'
      stroke='#fff'
      strokeWidth={2.167}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
