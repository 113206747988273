import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Field } from 'formik';
import { getTrimmedValue } from '../../../../utils/Utlities';
import Button from '../../../../core-components/Button';
import FormInput from '../../../../core-components/FormInput';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    title: {
        marginRight: theme.spacing(20),
        flex: 1,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles((theme) => ({
    formControl: {
        display: 'block',
    },
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, title, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant='h6' className={classes.title}>{title} Ops Status</Typography>
            {onClose ? (
                <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default (props) => {
    const classes = useStyles();
    const { isSubmitting, dirty, handleSubmit, values, setFieldValue } = props;

    return (
        <div>
            <Dialog onClose={props.handleClose} aria-labelledby='customized-dialog-title' open={props.open}>
                <DialogTitle id='customized-dialog-title' title={props.title} onClose={props.handleClose}></DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <Field
                            type='text'
                            name='name'
                            fullWidth={true}
                            requiredfield={'true'}
                            label='Status Name'
                            component={FormInput}
                            onBlur={(event) => {
                                const trimmedValue = getTrimmedValue(event.target.value, true);
                                setFieldValue('name', trimmedValue);
                            }}
                        />
                        <FormControl component='fieldset' className={classes.formControl}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color='primary'
                                        onChange={(e) => setFieldValue('isActive', e.target.checked)}
                                        checked={values.isActive}
                                        name={'isActive'}
                                        value={values.isActive}
                                    />
                                }
                                label={'Is Active'}
                            />
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color={'green'} label={'Save'} disabled={!dirty} size={'large'}></Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
