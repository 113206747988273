import * as Yup from 'yup';
import {
  getAlphabeticalStringValidationSchema,
  getEmailValidationSchema,
  getLinkedinUrlValidation,
  getNameValidationSchema,
  getPhoneValidationSchema
} from '../../../../utils/ValidationSchema';

export default Yup.object().shape({
  name: getAlphabeticalStringValidationSchema({ message: 'name of admin', isRequired: true }),
  email: getEmailValidationSchema({ isRequired: true }),
  designation: getNameValidationSchema({ message: 'designation' }),
  linkedInUrl: getLinkedinUrlValidation({isRequired: false, isNullable: true}),
});
