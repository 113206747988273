import React from 'react';
import {
  Grid,
  Typography,
  Paper,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox
} from '@material-ui/core';
import { Field } from 'formik';
import { getTrimmedValue } from '../../../utils/Utlities';
import { makeStyles } from '@material-ui/core/styles';
import Dropdown from '../../../core-components/DropdownInput';
import FormInput from '../../../core-components/FormInput';

const dropdownValues = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3 (Default)', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 }
];

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0, 2, 0),
    color: theme.palette.text.secondary
  },
  typo: {
    marginBottom: '10px',
    fontSize: '1rem',
    color: 'initial'
  },
  typo2: {
    marginTop: '25px'
  },
  typoCount: {
    marginBottom: '12px',
    fontSize: '12px'
  },
  typoError: {
    color: 'red'
  },
  enabledHrNote: {
    marginLeft: '20px'
  },
  noteField: {
    width: '500px'
  },
  fieldBox: {
    alignItems: 'end'
  }
}));
export default function CreateCompanyInviteEmail(props) {
  const { values, errors, setFieldValue } = props;

  const classes = useStyles();
  const handleBlur = (e) => {
    const trimmedValue = getTrimmedValue(e.target.value, true);
    setFieldValue('hrNote', trimmedValue);
  };
  const handleWarningBlur = (e) => {
    const trimmedValue = getTrimmedValue(e.target.value, true);
    setFieldValue('hrWarningNote', trimmedValue);
  };
  return (
    <>
      <Typography variant={'h6'}>Candidate Invite Email</Typography>
      <Paper className={classes.paper} variant='outlined' elevation={5}>
        <Typography className={classes.typo}>Days to complete form</Typography>
        <Dropdown
          dropdownValues={dropdownValues}
          label={'Select'}
          value={values.daysToCompleteForm}
          handleSelect={(value) => {
            setFieldValue('daysToCompleteForm', value);
          }}
        />
        <Typography className={`${classes.typo} ${classes.typo2}`}>Note from HR team</Typography>
        <Grid container>
          <Grid item>
            <Grid container className={classes.fieldBox} direction='column' alignItems='end' item>
              <Field
                type='text'
                name='hrNote'
                variant='outlined'
                fullWidth={true}
                label='HR note'
                multiline
                showError={false}
                value={values.hrNote}
                disabled={
                  !values.enabledHrNote ||
                  (values.enabledHrNote && values.customOrDefault === 'default')
                }
                className={classes.noteField}
                rows={3}
                component={FormInput}
                onChange={(e) => {
                  setFieldValue('hrNote', e.target.value);
                }}
                onBlur={handleBlur}
              />
              <Grid item>
                <Typography
                  className={`${classes.typoCount}  ${errors.hrNote ? classes.typoError : ''}`}
                >
                  {`Characters ${values?.hrNote?.trim().length | 0} out of 160`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction='column' className={classes.enabledHrNote} item>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={values.enabledHrNote}
                    inputProps={{
                      'aria-label': 'secondary checkbox'
                    }}
                    onChange={(e) => {
                      setFieldValue('enabledHrNote', !values.enabledHrNote);
                      setFieldValue('customOrDefault', 'default');
                      setFieldValue('hrNote', values.defaultHrNote);
                    }}
                  />
                }
                label='Enabled'
              />
              <Grid>
                <FormControl component='fieldset' fullWidth>
                  <RadioGroup
                    row
                    aria-label='customOrDefault'
                    name='customOrDefault'
                    value={values.customOrDefault}
                    onChange={(e) => {
                      setFieldValue('customOrDefault', e.target.value);
                      switch (e.target.value) {
                        case 'default':
                          setFieldValue('hrNote', values.defaultHrNote);
                          return;
                        case 'custom':
                          setFieldValue('hrNote', values.defaultCustomHrNote);
                          return;
                      }
                    }}
                  >
                    <FormControlLabel
                      value={'default'}
                      disabled={!values.enabledHrNote}
                      control={<Radio color='primary' />}
                      label={'Default'}
                    />
                    <FormControlLabel
                      value={'custom'}
                      disabled={!values.enabledHrNote}
                      control={<Radio color='primary' />}
                      label={'Custom'}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Typography className={`${classes.typo} ${classes.typo2}`}>
          Overdue note from HR team (Included in the email after due date)
        </Typography>
        <Grid container>
          <Grid item>
            <Grid container className={classes.fieldBox} direction='column' alignItems='end' item>
              <Field
                type='text'
                name='hrWarningNote'
                variant='outlined'
                fullWidth={true}
                label='HR Overdue note'
                multiline
                showError={false}
                value={values.hrWarningNote}
                disabled={
                  !values.enabledHrWarningNote ||
                  (values.enabledHrWarningNote && values.customOrDefaultWarningNote === 'default')
                }
                className={classes.noteField}
                rows={3}
                component={FormInput}
                onChange={(e) => {
                  setFieldValue('hrWarningNote', e.target.value);
                }}
                onBlur={handleWarningBlur}
              />
              <Grid item>
                <Typography
                  className={`${classes.typoCount}  ${
                    errors.hrWarningNote ? classes.typoError : ''
                  }`}
                >{`Characters ${values?.hrWarningNote?.trim().length | 0} out of 160`}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction='column' className={classes.enabledHrNote} item>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={values.enabledHrWarningNote}
                    inputProps={{
                      'aria-label': 'secondary checkbox'
                    }}
                    onChange={(e) => {
                      setFieldValue('enabledHrWarningNote', !values.enabledHrWarningNote);
                      setFieldValue('customOrDefaultWarningNote', 'default');
                      setFieldValue('hrWarningNote', values.defaultHrWarningNote);
                    }}
                  />
                }
                label='Enabled'
              />
              <Grid>
                <FormControl component='fieldset' fullWidth>
                  <RadioGroup
                    row
                    aria-label='customOrDefaultWarningNote'
                    name='customOrDefaultWarningNote'
                    value={values.customOrDefaultWarningNote}
                    onChange={(e) => {
                      setFieldValue('customOrDefaultWarningNote', e.target.value);
                      switch (e.target.value) {
                        case 'default':
                          setFieldValue('hrWarningNote', values.defaultHrWarningNote);
                          return;
                        case 'custom':
                          setFieldValue('hrWarningNote', values.defaultCustomHrWarningNote);
                          return;
                      }
                    }}
                  >
                    <FormControlLabel
                      value={'default'}
                      disabled={!values.enabledHrWarningNote}
                      control={<Radio color='primary' />}
                      label={'Default'}
                    />
                    <FormControlLabel
                      value={'custom'}
                      disabled={!values.enabledHrWarningNote}
                      control={<Radio color='primary' />}
                      label={'Custom'}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
