import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  getAuthToken,
  getCookieValue,
  removeAuthToken,
  setAuthToken,
  setCookieValue,
  removeCookie,
  getAccessLevel
} from '../../../api/storage';
import MainLayout from '../../SuperAdmin/layouts/MainLayout';

export default ({ component: Component, redirect = '/login', onlyAdmin = false, ...rest }) => {
  const [token, setToken] = useState(getAuthToken() || null);
  const companyDetails = useSelector((state) => state.updateCompanyData);

  useEffect(() => {
    let lastRedirectPathname = 'dashboard';
    if (rest?.location && rest?.location?.pathname !== '/logout') {
      lastRedirectPathname = `${rest?.location?.pathname}${rest?.location?.search}`;
    }
    localStorage.setItem('last-redirect-pathname', lastRedirectPathname);
  }, []);

  /**
   * Setting updated expiry date for token, if user is active
   * if User is inactive before 30days, user will logout
   *
   * isExpirySet is set to avoid updating multiple times in same session
   */
  const setTokenExpiryDate = () => {
    const isExpirySet = getCookieValue('isExpirySet');
    if (!isExpirySet) {
      setAuthToken(token, 30);
      setCookieValue('isExpirySet', true);
    }
  };
  const getComponentLayout = (props) => {
    if (
      rest.path === '/candidate/report' ||
      rest.path === '/candidate/resume' ||
      rest.path === '/candidate/consent' ||
      rest.path === '/candidate/internal-report' ||
      rest.path === '/candidate/document' ||
      rest.path === '/invoice/document' ||
      rest.path === '/verification-request' ||
      rest.path === '/candidate/view/report'
    ) {
      return <Component {...props} />;
    } else {
      return (
        <MainLayout companyDetails={companyDetails} pathname={rest.path}>
          <Component {...props} />
        </MainLayout>
      );
    }
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <>
            {setTokenExpiryDate()}

            {onlyAdmin ? (
              getAccessLevel() === 5 ? (
                getComponentLayout(props)
              ) : (
                <Redirect to={{ pathname: '/notFound', state: { from: props.location } }} />
              )
            ) : (
              getComponentLayout(props)
            )}
          </>
        ) : (
          <>
            {removeAuthToken()}
            {removeCookie('isExpirySet')}
            {localStorage.clear()}
            <Redirect to={{ pathname: redirect, state: { from: props.location } }} />
          </>
        )
      }
    />
  );
};
