import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, isEmpty } from 'lodash';
import { disconnectZohoBook, updateFinanceConfig } from '../../../api/admin';

import validationSchema from './ZohoBookDialog.validation';
import { toast } from 'react-toastify';
import { errToastMessage, getErrorMessage, toSnakeCase } from '../../../utils/Utlities';
import { setCompanyDetail } from '../../../actions/admin';

const mapStateToProps = (state) => {
  return {
    updateCompanyData: (!isEmpty(state.updateCompanyData) && state.updateCompanyData) || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCompanyDetails: () => dispatch(setCompanyDetail())
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        zohoBookID: '',
        zohoCompanyName: '',
        zohoGST: '',
        zohoTaxType: '',
        zohoState: '',
        zohoAddress: '',
        zohoTaxTreatMent: '',
        svCompanyName: '',
        svGST: '',
        svTaxType: 18,
        svState: '',
        svAddress: ''
      };
      let propsValue = {};
      const companyData = { ...props.updateCompanyData };
      let companyAddress = '';
      if (companyData?.billingAddress) {
        companyAddress = Object.entries(companyData?.billingAddress)
          .filter((addr) => addr[0] !== 'landmark' && addr[1] !== '')
          .map((r) => r[1])
          .join(', ');
      }
      propsValue = {
        zohoBookID: props.zohoBookID,
        zohoCompanyName: '',
        zohoGST: '',
        zohoTaxType: '',
        zohoState: '',
        zohoAddress: '',
        zohoTaxTreatMent: '',
        svCompanyName: companyData?.name?.trim() || '',
        svGST: companyData?.gstin?.trim(),
        svTaxType: companyData?.tax,
        svState: companyData?.billingAddress?.state?.trim() || '',
        svAddress: companyAddress.trim() || ''
      };

      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (values, { setFieldError, setStatus, setSubmitting, resetForm, props }) => {
      let companyState = props.updateCompanyData;
      if (!props.zohoBookID) {
        // API Call for Zoho book
        const payload = {
          companyId: props.updateCompanyData.id,
          zohoBooksContactId: values.zohoBookID
        };
        updateFinanceConfig(toSnakeCase(payload), false)
          .then(() => {
            companyState['zohoBooksContactId'] = values.zohoBookID;
            props.setCompanyDetails(companyState);
            props.onClose();
            resetForm();
            toast.success('Saved successfully.');
          })
          .catch((error) => {
            errToastMessage(error);
          });
        // Ends here
      } else {
        disconnectZohoBook(props?.updateCompanyData.id)
          .then((res) => {
            companyState['zohoBooksContactId'] = '';
            props.setCompanyDetails(companyState);
            props.onClose();
            resetForm();
            toast.success('Zoho Book disconnected successfully');
          })
          .catch((error) => {
            console.log(error);
            errToastMessage(error);
          });
      }
    },
    displayName: 'ZohoBook Dialog'
  })
);
