import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox
} from '@material-ui/core';
import { INDENTITES_FOR_PACKAGE_CONFIG } from '../../../../../../core-components/CommonConstants';
import IncrementCounter from '../../../../../../core-components/Packages/IncrementCounter';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginLeft: '68px',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
}));

/**
 * cPkgDetails: Company PackageDetails
 * sPkgDetails: Customized/CA selected package detailsInfo
 * aPkgDetails: Addon's allowed config/available options in addons
 * onValueChange: Func to save the values to parent state
 * values: Addons values as they changes
 */
export default (props) => {
  const classes = useStyles();

  //   Refer DocBlock above for detailed variable usage
  const { cPkgDetails, sPkgDetails, aPkgDetails, onValueChange, values, isAadhaarAllowed, limit } =
    props;

  const handleChange = (event, name) => {
    let dummy = [...values.config];
    if (event.target.checked) {
      if (!dummy?.includes(name)) {
        dummy.push(name);
        onValueChange('config', dummy);
        if (values.config.length + values.any > props.limit) {
          onValueChange('any', values.any - 1);
        }
      }
    } else {
      dummy.splice(dummy.indexOf(name), 1);
      onValueChange('config', dummy);
    }
  };

  const hasValue = (name) => {
    return values?.config?.includes(name);
  };

  const isDisabled = () => {
    return values.config.length + values.any === props.limit;
  };

  const handleConfigChange = (value, index) => {
    onValueChange('any', value);
  };

  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
            <FormControl component='fieldset' className={classes.formControl}>
              <FormGroup>
                {INDENTITES_FOR_PACKAGE_CONFIG.map((value, index) => {
                  return (
                    <React.Fragment key={`Package_Configure_${index}`}>
                      {!cPkgDetails?.includes(value?.name) ? (
                        <FormControlLabel
                          key={`Package_Configure_${index}`}
                          control={
                            <Checkbox
                              disabled={
                                (!hasValue(value.name) && isDisabled()) ||
                                (value.name === 'AADHAAR' && !isAadhaarAllowed)
                              }
                              checked={hasValue(value.name)}
                              onChange={(e) => handleChange(e, value?.name)}
                              name={value?.name}
                            />
                          }
                          label={value.label}
                        />
                      ) : null}
                    </React.Fragment>
                  );
                })}
                <Box mt={2} style={{ display: 'flex', verticalAlign: 'middle' }}>
                  <Typography
                    variant='subtitle2'
                    component='span'
                    style={{ marginRight: '12px', verticalAlign: 'middle' }}
                  >
                    <i>{'Any ID :'}</i>
                  </Typography>
                  <IncrementCounter
                    limit={limit}
                    totalCount={values.config.length + values.any} //TODO: Need to pass to total identity
                    value={values.any}
                    index={null}
                    valueChange={(value, index) => {
                      handleConfigChange(value, index);
                    }}
                  />
                </Box>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
