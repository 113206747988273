import React from 'react';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Field, ErrorMessage, isEmptyArray } from 'formik';

import Button from '../../../../core-components/Button';
import DetailsCard from '../../../../core-components/DetailsCard';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, title, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant='h6'>{title} </Typography>
            {onClose ? (
                <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default (props) => {
    const data = [
        {
            label: 'Name',
            value: (props.userDetails && props.userDetails.name) || '',
        },
        {
            label: 'Email',
            value: (props.userDetails && props.userDetails.email) || '',
        },
        {
            label: 'Mobile',
            value: (props.userDetails && props.userDetails.mobile) || '',
        },
        {
            label: 'Access Level',
            value:
                (props.userDetails &&
                props.userDetails.companyUserMapping &&
                props.userDetails.companyUserMapping.acessLevel === 5
                    ? 'Admin'
                    : 'HR') || '',
        },
        {
            label: 'Created At ',
            value: (props.userDetails && moment(props.userDetails.createdAt).utc().format('DD MMM YYYY hh:mm A')) || '',
        },
    ];
    return (
        <div>
            <Dialog
                onClose={props.handleClose}
                maxWidth='lg'
                aria-labelledby='customized-dialog-title'
                open={props.open}
            >
                <DialogTitle
                    id='customized-dialog-title'
                    title={'User Details'}
                    onClose={props.handleClose}
                ></DialogTitle>
                <DialogContent>
                    <DetailsCard data={data} heading={'Admin Info'} />
                </DialogContent>
            </Dialog>
        </div>
    );
};
