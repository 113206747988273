import { connect } from 'react-redux';
import { compose } from 'redux';

import { getVendors as getVendorsAPI, invalidateVendors } from '../../../actions/admin';

const mapStateToProps = (state) => {
    return {
        vendors: state.vendors,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getVendors: (limit, offset, status) => dispatch(getVendorsAPI(limit, offset, status)),
        invalidateVendors: () => dispatch(invalidateVendors()),
    };
};
export default compose(connect(mapStateToProps, mapDispatchToProps));
