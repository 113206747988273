export const headerDropdown = (user) => {
  if (user?.accessLevel === 11) {
    return [
      {
        title: 'Redash',
        child: [
          { text: 'Find the Right Package', href: 'https://redash.springworks.in/queries/564' }
        ]
      },
      {
        title: 'Company',
        href: `/companies`,
        child: []
      },
      {
        title: 'Candidates',
        child: [
          {
            text: 'Recent Candidates',
            href: `/dashboard`
          },
          {
            text: 'Insufficiency List',
            href: `/candidate/insufficiency`
          },
          {
            text: 'Insuff Ticket List',
            href: '/candidates/insufficiency/tickets'
          },
          {
            text: 'Insuff Review',
            href: '/candidates/insufficiency/review'
          }
        ]
      },
      {
        title: user ? user.name : 'User',
        child: [
          {
            text: 'Change Password',
            href: `/changePassword`
          },
          {
            text: 'Logout',
            href: `/logout`
          }
        ]
      }
    ];
  } else if (user?.accessLevel === 5) {
    return [
      {
        title: 'Redash',
        child: [
          { text: 'Find the Right Package', href: 'https://redash.springworks.in/queries/564' }
        ]
      },
      {
        title: 'Logs',
        child: [
          {
            text: 'Overall Package / Credit Logs (Prepaid)',
            href: `/package/credit/logs?package_type=PREPAID`
          },
          {
            text: 'Overall Package / Credit Logs (Postpaid)',
            href: `/package/credit/logs?package_type=POSTPAID`
          }
        ]
      },
      {
        title: 'Credits',
        child:
          user?.accessLevel === 5
            ? [
                {
                  text: 'Postpaid Credit',
                  href: '/postpaid/credits/list'
                },
                {
                  text: 'Prepaid Credit',
                  href: '/prepaid/credits/list'
                },
                {
                  text: 'Postpaid Billing',
                  href: '/postpaid/billing'
                }
              ]
            : [
                {
                  text: 'Postpaid Credit',
                  href: '/postpaid/credits/list'
                },
                {
                  text: 'Prepaid Credit',
                  href: '/prepaid/credits/list'
                }
              ]
      },
      {
        title: 'Packages',
        href: `/package/list`,
        child: []
      },
      {
        title: 'Company',
        href: `/companies`,
        child: []
      },
      {
        title: 'Users',
        child: [
          {
            text: 'Admin Users',
            href: `/admin/list`
          },
          {
            text: 'All Users',
            href: `/users`
          }
        ]
      },
      {
        title: 'Candidates',
        child: [
          {
            text: 'Recent Candidates',
            href: `/dashboard`
          },
          {
            text: 'Insufficiency List',
            href: `/candidate/insufficiency`
          },
          // Not in use / ADO-7353
          {
            text: 'Insuff Ticket List',
            href: '/candidates/insufficiency/tickets'
          },
          {
            text: 'Insuff Review',
            href: '/candidates/insufficiency/review'
          },
          {
            text: 'Additional Cost Review',
            href: `/admin/additional-charges`
          },
          {
            text: 'BGV Form Feedback',
            href: `/feedback`
          },
          {
            text: 'Insuff funds for checks',
            href: `/insuff/checks`
          },
          {
            text: 'Insufficiency Comments',
            href: '/candidates/insufficiency/comments'
          }
        ]
      },
      {
        title: 'Settings',
        child: [
          {
            text: 'Services',
            href: `/services`
          },
          {
            text: 'Default Questions',
            href: `/defaultQuestions`
          },
          {
            text: 'Ops-Status',
            href: `/opsStatus`
          },
          {
            text: 'Global Addons Config (Prepaid)',
            href: `/globalAddon/configuration?package_type=PREPAID`
          },
          {
            text: 'Global Addons Config (Postpaid)',
            href: `/globalAddon/configuration?package_type=POSTPAID`
          }
        ]
      },
      {
        title: user ? user.name : 'User',
        child: [
          {
            text: 'Change Password',
            href: `/changePassword`
          },
          {
            text: 'Logout',
            href: `/logout`
          }
        ]
      }
    ];
  } else {
    return [
      {
        title: 'Redash',
        child: [
          { text: 'Find the Right Package', href: 'https://redash.springworks.in/queries/564' }
        ]
      },
      {
        title: 'Candidates',
        child: [
          {
            text: 'Recent Candidates',
            href: `/dashboard`
          },
          {
            text: 'Insufficiency List',
            href: `/candidate/insufficiency`
          },
          {
            text: 'Insuff Ticket List',
            href: '/candidates/insufficiency/tickets'
          },
          {
            text: 'Insuff Review',
            href: '/candidates/insufficiency/review'
          }
        ]
      },
      {
        title: user ? user.name : 'User',
        child: [
          {
            text: 'Change Password',
            href: `/changePassword`
          },
          {
            text: 'Logout',
            href: `/logout`
          }
        ]
      }
    ];
  }
};
