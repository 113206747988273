// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JiWv_a0bQio5aevl4gEc {\n  background: #ffffff;\n  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);\n  border-radius: 4px;\n  margin: -190px auto 190px;\n  font-family: Poppins, sans-serif;\n  min-height: 375px;\n  max-width: 504px;\n}\n.JiWv_a0bQio5aevl4gEc .tuB_J3UNuQSAcMcSNSmJ {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n.JiWv_a0bQio5aevl4gEc .tuB_J3UNuQSAcMcSNSmJ .gMPfzsjKydZP2IwjcXgC {\n  font-weight: 600;\n  font-size: 24px;\n  color: #2755fe;\n  margin-top: 24px;\n  line-height: 32px;\n}\n.JiWv_a0bQio5aevl4gEc .tuB_J3UNuQSAcMcSNSmJ .oEJPanmskqOOmgvW00Cc {\n  font-weight: 600;\n  font-size: 16px;\n  color: #262626;\n  margin-top: 8px;\n  line-height: 32px;\n  font-family: roboto;\n}\n.JiWv_a0bQio5aevl4gEc .tuB_J3UNuQSAcMcSNSmJ .M_nPHRaOF0wVJ3wXjOfi button {\n  width: auto;\n  min-width: 130px;\n  font-size: 14px;\n  margin-right: 8px;\n  margin-top: 56px;\n}\n.JiWv_a0bQio5aevl4gEc .tuB_J3UNuQSAcMcSNSmJ .LV_weFwq2XUfugF55f7B {\n  margin-top: 50px;\n}\n.JiWv_a0bQio5aevl4gEc .tuB_J3UNuQSAcMcSNSmJ .LV_weFwq2XUfugF55f7B a {\n  color: #2755fe;\n  font-weight: normal;\n  padding: inherit;\n  font-size: inherit;\n  text-decoration: none;\n  cursor: pointer;\n}\n\n.F9nfTsAtC0AVwz925e3a {\n  display: flex;\n  flex-direction: row-reverse;\n  align-items: flex-end;\n  margin-top: 10px;\n  margin-right: 10px;\n}\n.F9nfTsAtC0AVwz925e3a:hover {\n  color: red;\n  cursor: pointer;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "JiWv_a0bQio5aevl4gEc",
	"body": "tuB_J3UNuQSAcMcSNSmJ",
	"payment_title": "gMPfzsjKydZP2IwjcXgC",
	"status_description": "oEJPanmskqOOmgvW00Cc",
	"btnContainer": "M_nPHRaOF0wVJ3wXjOfi",
	"help": "LV_weFwq2XUfugF55f7B",
	"crossBtn": "F9nfTsAtC0AVwz925e3a"
};
export default ___CSS_LOADER_EXPORT___;
