import React, { useEffect, useState } from 'react';
import { has } from 'lodash';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Header from '../Header';
import Footer from '../Footer';

const drawerWidth = 240;

const shouldShowRibbon = (pathname) => {
  const ribbonPaths = [
    '/company/view',
    '/package/company',
    '/credits/balance/company',
    '/company/candidateAnalytics',
    '/company/alumniVerification',
    '/postpaid/unbilled/candidates',
    '/company/additionalCostConfig/view',
    '/company/financeConfig/view',
    '/company/admins',
    '/company/refCheckQuestions',
    '/company/addonConfiguration',
    '/company/settings',
    '/company/logs',
    '/company/analytics',
    '/company/update',
    '/company/candidates',
    '/package/company/view',
    '/company/buy',
    '/company/additionalSettings/view',
    '/postpaid/company/invoice/list',
    '/company/additionalSettings/edit',
    '/company/update',
    '/company/candidate/bulk-upload',
    '/company/candidate/create',
    '/candidate/details'
  ];
  return ribbonPaths.includes(pathname);
};

const useStyles = makeStyles((theme) => ({
  '@keyframes pop': {
    '0%': {
      transform: 'scale(1.0)',
      opacity: 0.7
    },
    '70%': {
      transform: 'scale(1.03)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 1
    }
  },
  notMigrationRibbon: {
    width: '90%',
    backgroundColor: '#ffcccc',
    background: 'linear-gradient(45deg, #ffcccc, #ff6666)',
    color: 'black',
    fontWeight: 'bold',
    padding: '15px',
    textAlign: 'center',
    marginBottom: '10px',
    boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
    borderRadius: '5px',
    animation: '$pop 0.5s ease-in-out'
  },
  root: {
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    marginLeft: theme.spacing(9),
    padding: theme.spacing(3),
    marginTop: theme.spacing(7),
    overflowX: 'hidden'
  },
  contentShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  headerContainer: {
    background: theme.headerBackground
  },
  loginBodyContainer: {
    marginBottom: '65px'
  },
  ribbonContainer: {
    marginTop: '65px',
    display: 'flex',
    justifyContent: 'center'
  },
  customFooterContainer: {
    minHeight: '60px',
    marginTop: 'auto',
    background: '#f5f5f5',
    border: '1px solid #ddd'
  },
  customFooterContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px'
  },
  reportContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

export default (props) => {
  const classes = useStyles();
  const { children, companyDetails } = props;
  const [showRibbon, setShowRibbon] = useState(false);

  useEffect(() => {
    let timer;
    if (
      companyDetails &&
      shouldShowRibbon(props.pathname) &&
      has(companyDetails, 'paymentMode') &&
      !companyDetails.paymentMode
    ) {
      timer = setTimeout(() => {
        setShowRibbon(true);
      }, 500);
    } else {
      setShowRibbon(false);
    }
    return () => clearTimeout(timer);
  }, [companyDetails, props?.pathname]);

  return (
    <Grid container direction='column' style={{ minHeight: '100vh' }}>
      {/* Header Section */}
      <Grid item container data-testid='header-section' className={classes.headerContainer}>
        <Header historyProps={props?.children?.props} />
      </Grid>

      <Grid
        item
        container
        className={classes.ribbonContainer}
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        direction='row'
      >
        {showRibbon ? (
          <div className={classes.notMigrationRibbon}>
            This company is not migrated to packages. Please reach out to your supervisor before
            taking any action.
          </div>
        ) : null}
      </Grid>
      {/* Body Section */}
      <Grid
        item
        container
        data-testid='body-section'
        className={classes.loginBodyContainer}
        direction='row'
      >
        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid>
        <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
          {children}
        </Grid>
        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid>
      </Grid>
      {/* Footer Section */}
      <Grid item container data-testid='footer-section' className={classes.customFooterContainer}>
        <Grid item xl={1} lg={1} md={1} sm={1} xs={false}></Grid>
        <Grid item xl={10} lg={10} md={10} sm={10} xs={12} className={classes.customFooterContent}>
          <Footer />
        </Grid>
        <Grid item xl={1} lg={1} md={1} sm={1} xs={false}></Grid>
      </Grid>
    </Grid>
  );
};
