import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith } from 'lodash';
import { toast } from 'react-toastify';

import { updatePassword as updatePasswordAPI } from '../../../../api/admin';
import validationSchema from './PasswordChange.validation';
import { toSnakeCase, getErrorMessage, errToastMessage } from '../../../../utils/Utlities';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        email: '',
        existingPassword: '',
        password: '',
        confirmPassword: '',
        isExistingPasswordError: false
      };
      let propsValue = {};
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (
      values,
      { setFieldError, setStatus, setSubmitting, resetForm, setFieldValue, props }
    ) => {
      const finalSaveObj = {
        email: values.email,
        existingPassword: values.existingPassword,
        password: values.password,
        confirmPassword: values.confirmPassword
      };

      updatePasswordAPI(toSnakeCase(finalSaveObj))
        .then((response) => {
          toast.info('Password Updated Successfully.');
          setFieldValue('isExistingPasswordError', false);
          setSubmitting(false);
          resetForm();
        })
        .catch((error) => {
          console.error(error);
          setSubmitting(false);
          if (getErrorMessage(error) === 'Wrong password') {
            errToastMessage('Existing password is not valid');
            setFieldValue('isExistingPasswordError', true);
          } else {
            errToastMessage(error);
          }
        });
    },
    displayName: 'Update Password'
  })
);
