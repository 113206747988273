import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppConstants from '../../../core-components/AppConstants';
import ThreeDots from '../../../core-components/ThreeDotLoader';
import { makeStyles } from '@material-ui/core';
import { errToastMessage } from '../../../utils/Utlities';

const useStyles = makeStyles(() => ({
  threeDotLoader: {
    textAlign: 'center',
    height: '100%'
  }
}));

const GoogleRedirection = ({ location, googleLogin, history }) => {
  const classes = useStyles();

  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const error = queryParams.get('error');

  useEffect(() => {
    const handleLogin = async (code) => {
      try {
        const res = await fetch(`${AppConstants.developmentAPIURL}auth/google-login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ code })
        });
        const data = await res.json();
        if (data.data) {
          googleLogin(data.data);
          const redirectUrl = constructRedirectUrl();
          history?.push('/' + redirectUrl);
          localStorage.removeItem('last-redirect-pathname');
          // window.open(redirectUrl, '_self');
        } else {
          errToastMessage(data.message);
          history?.push('/login');
        }
      } catch (error) {
        errToastMessage(error.message || 'An error occurred');
        history?.push('/login');
      }
    };

    const constructRedirectUrl = () => {
      const lastUrl = localStorage.getItem('last-redirect-pathname');
      const from = location.state?.from?.pathname;
      if (from && from !== '/logout') {
        return `${from.startsWith('/') ? from.substring(1) : from}${location.state?.from?.search}`;
      } else if (lastUrl) {
        return `${lastUrl.startsWith('/') ? lastUrl.substring(1) : lastUrl}`;
      } else {
        return `dashboard`;
      }
    };

    if (code) {
      handleLogin(code);
    } else {
      history?.push('/login');
    }
  }, [code, googleLogin, location.state]);

  useEffect(() => {
    if (error) {
      if (error !== 'access_denied') errToastMessage(error);
      history.push('/login');
    }
  }, [error]);

  return (
    <div className={classes.threeDotLoader}>
      <ThreeDots />
    </div>
  );
};

export default withRouter(GoogleRedirection);
