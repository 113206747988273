import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Grid, Card, CardContent, CircularProgress } from '@material-ui/core';

import { displayDateTimeFormat } from '../../../../core-components/CommonConstants';
import VerificationIconDetails from '../../ViewCandidateDetails/VerificationIconDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Poppins'
  },
  mainContainer: {
    cursor: 'pointer',
    marginBottom: 20
  },
  cardRoot: {
    padding: '0px',
    paddingBottom: '16px !important',
    '&:hover': {
      backgroundColor: '#f6f5f5'
    }
  },
  selectedInsuff: {
    backgroundColor: '#aaaaaa !important',
    color: '#ffffff'
  },
  title: {
    padding: 14,
    backgroundColor: '#132743',
    color: '#f6f5f5',
    display: 'flex'
  },
  bodyContent: { padding: 14, paddingBottom: 0 },
  infoBtn: {
    color: '#ffffff',
    borderColor: '#388e3c',
    background: 'linear-gradient(to right, #0844a6 0%, #086590 48.96%, #088077 100%)',
    fontSize: 14,
    textTransform: 'inherit',
    '&:hover': {
      background: 'linear-gradient(to right, #0844a6 0%, #086590 48.96%, #088077 100%)'
    }
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '60vh',
    alignItems: 'center'
  },
  link: {
    '&:hover': {
      textDecoration: 'none'
    }
  },
  disabled: {
    background: 'inherit',
    color: 'inherit',
    pointerEvents: 'unset !important',
    cursor: 'not-allowed !important',
    '&:hover': {
      background: 'inherit'
    }
  }
}));

const TEMP_CONST = {
  IDENTITY: 'identity',
  ADDRESS: 'address',
  EMPLOYMENT: 'employment',
  EDUCATION: 'education',
  REFERENCE: 'referenceCheck',
  WORLD: 'worldCheck',
  CREDIT: 'cibilCheck',
  COURT: 'court',
  DRUG: 'drugCheck'
};

export default (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [showPublishPopup, setShowPublishPopup] = useState(false);
  const [selectedInsuff, setSelectedInsuff] = useState(null);

  useEffect(() => {
    setSelectedInsuff(null);
  }, [props.selectedIndex]);

  const getCheckDetails = (checkName = null, uuid = null) => {
    const verificationArr = props?.candidateVerifications[checkName];
    const details =
      verificationArr.length &&
      verificationArr.filter((verification) => {
        return verification.uuid === uuid;
      });

    const getHeading = () => {
      switch (checkName) {
        case 'identity':
          return `ID check - ${details[0]?.identityType?.identityName} (${details[0]?.id})`;
        case 'address':
          return `Address check - ${details[0]?.type} (${details[0]?.id})`;
        case 'employment':
          return `Employment check - (${details[0]?.id})`;
        case 'education':
          return `Education check - (${details[0]?.id})`;
        case 'court':
          return `Court check - (${details[0]?.id})`;
        case 'referenceCheck':
          return `Reference check - (${details[0]?.id})`;
        case 'cibilCheck':
          return `Credit Check - (${details[0]?.id})`;
        case 'worldCheck':
          return `World check - (${details[0]?.id})`;
        case 'drugCheck':
          return `Drug check - (${details[0]?.id})`;
        default:
          return `Insuff Ticket`;
      }
    };

    return (
      <Box className={classes.title}>
        <VerificationIconDetails type={checkName} details={details} handleIconClick={() => {}} />
        <Typography gutterBottom component={'span'}>
          {getHeading(checkName)}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      {!loading ? (
        <Box mt={2} mb={2} className={classes.root}>
          <Grid container>
            {/* Tickets lists */}
            {props?.details &&
              props?.details?.length &&
              props?.details.map((value, index) => {
                return (
                  <Grid
                    item
                    container
                    className={classes.mainContainer}
                    key={`insuffTkts-${index}`}
                    onClick={() => {
                      setSelectedInsuff(index);
                      props.setComments({
                        index,
                        heading: value.heading,
                        status: value.status
                      });
                    }}
                  >
                    <Grid item md={12} xs={12} lg={12}>
                      <Card variant={'outlined'} raised={true}>
                        <CardContent
                          className={`${classes.cardRoot} ${
                            index === selectedInsuff ? classes.selectedInsuff : null
                          }`}
                        >
                          {getCheckDetails(TEMP_CONST[value.checkType], value.checkId)}
                          <Grid container className={classes.bodyContent} spacing={2}>
                            <Grid item md={7} xs={7} lg={7}>
                              <Typography variant='subtitle2' gutterBottom>
                                {value.title || ''}
                              </Typography>
                            </Grid>
                            <Grid item md={5} xs={5} lg={5}>
                              <Typography
                                variant='caption'
                                display='block'
                                gutterBottom
                                align={'right'}
                              >
                                {`Status: ${value.status || ''}`}
                              </Typography>
                            </Grid>
                            <Grid item md={7} xs={7} lg={7}>
                              <Typography variant='caption' display='block' gutterBottom>
                                {value.updatedAt
                                  ? ` Last updated: ${moment(value.updatedAt).format(
                                      displayDateTimeFormat
                                    )}`
                                  : null}
                              </Typography>
                            </Grid>
                            <Grid item md={5} xs={5} lg={5}>
                              <Typography
                                variant='caption'
                                display='block'
                                gutterBottom
                                align={'right'}
                              >
                                {value.openFor ? `Open for: ${value.openFor} Days` : null}
                              </Typography>
                            </Grid>
                            <Grid item container md={12} xs={12} lg={12} spacing={1}>
                              <Grid item>
                                <Typography variant='subtitle2' gutterBottom>
                                  {`Total comments: ${
                                    value?.publicComments + value?.privateComments
                                      ? value?.publicComments + value?.privateComments
                                      : '-'
                                  }`}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant='subtitle2' gutterBottom>
                                  |
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant='subtitle2' gutterBottom>
                                  {`Public comments: ${
                                    value.publicComments ? value.publicComments : '0'
                                  }`}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant='subtitle2' gutterBottom>
                                  |
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant='subtitle2' gutterBottom>
                                  {`Private comments: ${
                                    value.privateComments ? value.privateComments : '0'
                                  }`}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid item md={12} xs={12} lg={12}>
                              <Typography variant='subtitle2' gutterBottom>
                                {`Comments to review: ${
                                  value.unreviewedComments ? value.unreviewedComments : '0'
                                }`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      ) : (
        <Grid container spacing={2} className={classes.spinnerContainer}>
          <CircularProgress />
        </Grid>
      )}
    </>
  );
};
