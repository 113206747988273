import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import { getTrimmedValue } from '../../../../../utils/Utlities';
import CloseIcon from '@material-ui/icons/Close';
import styles from './ViewUpdateLogsModal.module.scss';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  PAYMENT_MODE,
  PAYMENT_MODE_OBJ,
  transactionRevampDate,
  TRANSACTION_TYPE
} from '../../../../../core-components/CommonConstants';
import FileUpload from '../../../../../core-components/FileUploadToS3';
import DateField from '../../../../../core-components/PickerOrTextDate';
import { isEmpty } from 'lodash';
const useStyles = makeStyles(() => ({
  refundActiveBtn: {
    color: '#ffffff',
    borderColor: '#e8833a',
    backgroundColor: '#e8833a',
    fontSize: 14,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#db6d1d'
    }
  },
  label: {
    margin: '10px 0 5px',
    color: '#333333',
    fontSize: '14px',
    fontWeight: '500'
  },
  green: {
    color: '#1AAE9F',
    border: '#1AAE9F 1px solid'
  },
  blue: {
    color: '#0000ff',
    border: '#0000ff 1px solid'
  },
  requiredField: {
    color: 'red'
  }
}));
const taxRelatedTXN = ['RECHARGE', 'CREDIT_REFUND'];
const ViewUpdateLogsModal = ({
  open,
  handleClose,
  isModalViewOnly,
  data,
  values,
  setFieldValue,
  handleSubmit,
  handleChange,
  errors,
  handleBlur,
  touched,
  isSubmitting,
  setFieldTouched
}) => {
  const classes = useStyles();
  const [uploadModal, setUploadModal] = useState(false);
  const [fileName, setFileName] = useState('');

  const getTitle = () => {
    let rechargeByUser = 'External User';
    let rechargeText = ``;
    if (data?.user?.name) {
      rechargeByUser = data?.user?.name;
      rechargeText = `by ${data?.user?.companyUserMapping ? 'CA' : 'SA'}`;
    }
    switch (data?.source) {
      case 'RECHARGE':
        return `Purchase (Recharge) ${rechargeText} : ${rechargeByUser}`;
      case 'CREDIT_REFUND':
        return `${isModalViewOnly ? '' : 'Update'} Refund to Customer`;
      case 'CREDIT_DEDUCTION':
        return `Credit Adjustment`;
      case 'FREE_CREDITS':
        return `Free Credits`;
      case 'FREE_CREDITS_TRIAL':
        return `Free Credits Trial`;
      case 'BRIDGE_CREDITS':
        return `Bridge Credits`;
      case 'CREDIT_DEDUCTION_BRIDGE_CREDITS':
        return `Bridge Credits Deduction`;
      default:
        break;
    }
  };
  const getGrandTotal = () => {
    let total = data?.creditsAmount;
    if (values?.tax) {
      total += (values?.tax / 100) * total;
    }
    if (data?.paymentsCompanyPrepaidInvoice?.tdsAmount) {
      total -= data?.paymentsCompanyPrepaidInvoice?.tdsAmount;
    }
    if (data?.source === 'CREDIT_REFUND') {
      return parseFloat(total * -1).toFixed(2);
    }
    return parseFloat(total).toFixed(2);
  };
  const getTotal = () => {
    let total = values?.tax
      ? data?.creditsAmount + data?.creditsAmount * (values?.tax / 100)
      : data?.creditsAmount;
    if (data?.source === 'CREDIT_REFUND') {
      total = parseFloat(total * -1).toFixed(2);
    }
    return parseFloat(total).toFixed(2);
  };
  const getTax = () => {
    let tax = values?.tax ? parseFloat(data?.creditsAmount * (values?.tax / 100)) : '0.00';
    if (data?.source === 'CREDIT_REFUND') {
      tax = parseFloat(tax * -1).toFixed(2);
    }
    return parseFloat(tax).toFixed(2);
  };
  const handleFileUpload = () => {
    setUploadModal(!uploadModal);
  };
  const checkIfFieldMissing = () => {
    let isAnyFieldMissing = false;
    if (moment(data?.createdAt).utc() < moment(transactionRevampDate).utc()) {
      if (data?.source === 'RECHARGE') {
        if (
          !(
            data?.paymentsCompanyPrepaidInvoice?.paymentDate &&
            data?.paymentsCompanyPrepaidInvoice?.documentDate &&
            values?.tds !== null
          )
        ) {
          isAnyFieldMissing = true;
        }
      } else if (data?.source === 'CREDIT_REFUND') {
        if (
          !(
            data?.paymentsCompanyPrepaidInvoice?.paymentDate &&
            data?.paymentsCompanyPrepaidInvoice?.documentDate
          )
        ) {
          isAnyFieldMissing = true;
        }
      }
    }
    return isAnyFieldMissing;
  };
  const showInvoiceFields = () => {
    return taxRelatedTXN.includes(data?.source) ? true : false;
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      maxWidth='md'
    >
      <DialogTitle id='alert-dialog-title'>
        <div className={styles.dialogTitle}>
          {getTitle()}
          <CloseIcon onClick={handleClose} />
        </div>
        <div className={`${styles.dialogTitle} ${styles.transactionDetails}`}>
          <span>TXN Date: {moment(data?.createdAt).utc().format('DD MMM YYYY')}</span>
          {taxRelatedTXN.includes(data?.source) ? (
            <span>TXN ID: {data?.paymentsCompanyPrepaidInvoice?.rechargeLogId}</span>
          ) : null}
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {data?.source === 'CREDIT_REFUND' && !isModalViewOnly ? (
            <Grid container spacing={3} alignItems='flex-end' justify='flex-end'>
              <Grid item sm={8}>
                <div>
                  <InputLabel className={classes.label}>
                    Transaction type<span className={classes.requiredField}>*</span>
                  </InputLabel>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <Select name='tag' fullWidth disabled variant='outlined' value={data?.source}>
                      {TRANSACTION_TYPE.map((tr, index) => (
                        <MenuItem value={tr.value}>{tr.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item sm={4}>
                <Button
                  variant='outlined'
                  size='large'
                  className={values?.creditNoteUrl ? classes.green : classes.blue}
                  fullWidth
                  onClick={() => setUploadModal(true)}
                >
                  {values?.creditNoteUrl ? (
                    'CN Uploaded'
                  ) : (
                    <>
                      Upload CN<span className={classes.requiredField}>*</span>
                    </>
                  )}
                </Button>
              </Grid>
            </Grid>
          ) : null}
          {showInvoiceFields() ? (
            <Grid container spacing={3} alignItems='flex-start' justify='flex-end'>
              <Grid item sm={4}>
                <InputLabel className={classes.label}>Invoice Number</InputLabel>
                <TextField
                  fullWidth
                  type='text'
                  variant='outlined'
                  value={values?.invoiceNumber}
                  onChange={handleChange}
                  name='invoiceNumber'
                  size='small'
                  onBlur={handleBlur}
                  disabled={isModalViewOnly || data?.source === 'RECHARGE'}
                />
              </Grid>
              <Grid item sm={4}>
                <>
                  <InputLabel className={classes.label}>
                    {data?.source === 'RECHARGE' ? (
                      'Invoice Date'
                    ) : (
                      <>
                        Credit Note Number<span className={classes.requiredField}>*</span>
                      </>
                    )}
                  </InputLabel>
                  {data?.source === 'RECHARGE' ? (
                    <TextField
                      fullWidth
                      type='text'
                      variant='outlined'
                      disabled
                      value={
                        data?.paymentsCompanyPrepaidInvoice?.documentDate
                          ? moment(data?.paymentsCompanyPrepaidInvoice?.documentDate).format(
                            'DD-MMM-YYYY'
                          )
                          : ''
                      }
                      name='invoiceDate'
                      size='small'
                      placeholder='yyyy-mm-dd'
                    />
                  ) : (
                    <TextField
                      fullWidth
                      type='text'
                      variant='outlined'
                      value={values.creditNoteNumber}
                      name='creditNoteNumber'
                      onChange={handleChange}
                      size='small'
                      onBlur={handleBlur}
                      disabled={isModalViewOnly}
                      error={errors?.creditNoteNumber && touched?.creditNoteNumber}
                      helperText={touched?.creditNoteNumber && errors?.creditNoteNumber}
                    />
                  )}
                </>
              </Grid>
              <Grid item sm={4}>
                <InputLabel className={classes.label}>
                  {data?.source === 'RECHARGE' ? (
                    'Proforma Number'
                  ) : (
                    <>
                      Credit Note Date<span className={classes.requiredField}>*</span>
                    </>
                  )}
                </InputLabel>
                {data?.source === 'RECHARGE' ? (
                  <TextField
                    fullWidth
                    type='text'
                    variant='outlined'
                    disabled
                    value={data?.paymentsCompanyPrepaidInvoice?.proformaNumber}
                    name='proformaNumber'
                    size='small'
                  />
                ) : (
                  <DateField
                    value={values.creditNoteDate}
                    name={'creditNoteDate'}
                    id='creditNoteDate'
                    format={'YYYY-MM-DD'}
                    required={true}
                    canHaveDiffFormats={true}
                    disableFuture={true}
                    onChange={(value) => {
                      setFieldValue('creditNoteDate', value || '');
                      setFieldTouched('creditNoteDate', true);
                    }}
                    disabled={isModalViewOnly}
                    onBlur={(e) => {
                      setFieldValue('creditNoteDate', e.target.value);
                    }}
                    errMessage={
                      touched?.creditNoteDate && !values.creditNoteDate ? 'Field is required' : ''
                    }
                  />
                )}
              </Grid>
            </Grid>
          ) : null}
          {showInvoiceFields() ? (
            <Grid container spacing={3} alignItems='flex-start' justify='flex-end'>
              <Grid item sm={4}>
                <InputLabel className={classes.label}>
                  {data?.source === 'RECHARGE' ? (
                    'Payment Mode'
                  ) : (
                    <>
                      Refund Mode<span className={classes.requiredField}>*</span>
                    </>
                  )}
                </InputLabel>
                <FormControl variant='outlined' size='small' fullWidth>
                  <Select
                    name={data?.source === 'RECHARGE' ? 'paymentMode' : 'refundMode'}
                    fullWidth
                    disabled={isModalViewOnly}
                    variant='outlined'
                    value={data?.source === 'RECHARGE' ? values?.paymentMode : values?.refundMode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {PAYMENT_MODE.map((pm, index) => (
                      <MenuItem key={index} value={pm.value}>
                        {pm.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors?.refundMode && touched?.refundMode && (
                    <FormHelperText error>{errors?.refundMode}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <InputLabel className={classes.label}>
                  {data?.source === 'RECHARGE' ? (
                    'Payment Date'
                  ) : (
                    <>
                      Refund Reference<span className={classes.requiredField}>*</span>
                    </>
                  )}
                </InputLabel>
                {data?.source === 'RECHARGE' ? (
                  <TextField
                    fullWidth
                    type='text'
                    variant='outlined'
                    disabled
                    value={
                      data?.paymentsCompanyPrepaidInvoice?.paymentDate
                        ? moment(data?.paymentsCompanyPrepaidInvoice?.paymentDate).format(
                          'DD-MMM-YYYY'
                        )
                        : ''
                    }
                    name='paymentDate'
                    size='small'
                    placeholder='yyyy-mm-dd'
                  />
                ) : (
                  <TextField
                    fullWidth
                    type='text'
                    variant='outlined'
                    value={values?.refundReference}
                    name='refundReference'
                    onChange={handleChange}
                    size='small'
                    onBlur={handleBlur}
                    error={errors?.refundReference && touched?.refundReference}
                    helperText={touched?.refundReference && errors?.refundReference}
                    disabled={isModalViewOnly}
                  />
                )}
              </Grid>
              <Grid item sm={4}>
                <InputLabel className={classes.label}>
                  {data?.source === 'RECHARGE' ? (
                    'Payment Reference'
                  ) : (
                    <>
                      Refund Date<span className={classes.requiredField}>*</span>
                    </>
                  )}
                </InputLabel>
                {data?.source === 'RECHARGE' ? (
                  <TextField
                    fullWidth
                    type='text'
                    variant='outlined'
                    disabled
                    value={data?.paymentsCompanyPrepaidInvoice?.paymentReference}
                    name='paymentReference'
                    size='small'
                  />
                ) : (
                  <DateField
                    value={values.refundDate}
                    name={'refundDate'}
                    id='refundDate'
                    format={'YYYY-MM-DD'}
                    required={true}
                    canHaveDiffFormats={true}
                    disableFuture={true}
                    onChange={(value) => {
                      setFieldValue('refundDate', value || '');
                      setFieldTouched('refundDate', true);
                    }}
                    disabled={isModalViewOnly}
                    onBlur={(e) => {
                      setFieldValue('refundDate', e.target.value);
                    }}
                    errMessage={
                      touched?.refundDate && !values.refundDate ? 'Field is required' : ''
                    }
                  />
                )}
              </Grid>
            </Grid>
          ) : null}
          <Grid container spacing={3} alignItems='flex-start' justify='flex-start'>
            <Grid item sm={4}>
              <InputLabel className={classes.label}>
                Amount
                {data?.source === 'CREDIT_REFUND' ? (
                  <span className={classes.requiredField}>*</span>
                ) : (
                  ''
                )}
              </InputLabel>
              <TextField
                fullWidth
                type='text'
                variant='outlined'
                disabled
                value={values?.amount}
                name='amount'
                size='small'
              />
            </Grid>
            {showInvoiceFields() ? (
              <Grid item sm={4}>
                <InputLabel className={classes.label}>
                  Tax(%)
                  {data?.source === 'CREDIT_REFUND' ? (
                    <span className={classes.requiredField}>*</span>
                  ) : (
                    ''
                  )}
                </InputLabel>
                <TextField
                  fullWidth
                  type='text'
                  variant='outlined'
                  disabled
                  value={`${data?.paymentsCompanyPrepaidInvoice?.tax
                      ? data?.paymentsCompanyPrepaidInvoice?.tax
                      : 0
                    }%`}
                  name='tax'
                  size='small'
                />
              </Grid>
            ) : null}
            <Grid item sm={4}>
              {data?.source === 'RECHARGE' ? (
                <>
                  <InputLabel className={classes.label}>TDS(%)</InputLabel>
                  <TextField
                    fullWidth
                    type='text'
                    variant='outlined'
                    disabled
                    value={`${data?.paymentsCompanyPrepaidInvoice?.tds
                        ? data?.paymentsCompanyPrepaidInvoice?.tds
                        : 0
                      }%`}
                    name='tds'
                    size='small'
                  />
                </>
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems='center' justify='flex-end'>
            <Grid item sm={8}>
              <InputLabel className={classes.label}>
                Additional Info (Public Comment)
                {data?.source === 'CREDIT_REFUND' ? (
                  <span className={classes.requiredField}>*</span>
                ) : (
                  ''
                )}
              </InputLabel>
              <TextField
                name='additionalInfo'
                multiline
                fullWidth={true}
                rows={4}
                value={values?.additionalInfo}
                variant='outlined'
                onChange={handleChange}
                onBlur={(event) => {
                  const trimmedValue = getTrimmedValue(event.target.value, true);
                  setFieldValue('additionalInfo', trimmedValue);
                  handleBlur(event);
                }}
              />
              {errors?.additionalInfo && touched?.additionalInfo && (
                <FormHelperText id='component-error-text' error>
                  {errors?.additionalInfo}
                </FormHelperText>
              )}
            </Grid>
            <Grid item sm={4} className='mt-4'>
              <div className={styles.finance}>
                <span>Amount:</span>
                <span>
                  {data?.source === 'CREDIT_REFUND'
                    ? parseFloat(data?.creditsAmount * -1).toFixed(2)
                    : parseFloat(data?.creditsAmount).toFixed(2)}
                </span>
              </div>
              <div className={styles.finance}>
                {showInvoiceFields() ? (
                  <>
                    <span>{data?.source === 'CREDIT_REFUND' ? 'Tax' : 'GST'}</span>
                    <span>{getTax()}</span>
                  </>
                ) : null}
              </div>
              <div className={styles.finance}>
                <span className={styles.bold}>Total:</span>
                <span className={styles.bold}>{getTotal()}</span>
              </div>
              {data?.source === 'RECHARGE' ? (
                <div className={styles.finance}>
                  <span className={`${styles.bold} ${styles.orange}`}>TDS:</span>
                  <span className={`${styles.bold} ${styles.orange}`}>
                    -
                    {data?.paymentsCompanyPrepaidInvoice?.tdsAmount
                      ? parseFloat(data?.paymentsCompanyPrepaidInvoice?.tdsAmount).toFixed(2)
                      : '0.00'}
                  </span>
                </div>
              ) : null}
              <div className={styles.finance}>
                <span
                  className={`${styles.bold} ${data?.source === 'RECHARGE' ? styles.green : styles.orange
                    }`}
                >
                  {data?.source === 'RECHARGE' ? 'Grand' : 'Net'} Total:
                </span>
                <span
                  className={`${styles.bold} ${data?.source === 'RECHARGE' ? styles.green : styles.orange
                    }`}
                >
                  {getGrandTotal()}
                </span>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems='flex-end' justify='flex-end'>
            <Grid item sm={8}>
              {checkIfFieldMissing() ? (
                <span className={styles.fieldMissing}>
                  Some data might not be available as this transaction is older than{' '}
                  {moment(transactionRevampDate).format('DD MMM YYYY')}
                </span>
              ) : null}
            </Grid>
            <Grid item sm={4} className='mt-4'>
              {data?.source === 'CREDIT_REFUND' && !isModalViewOnly ? (
                <Button
                  variant='contained'
                  fullWidth
                  type='submit'
                  className={classes.refundActiveBtn}
                  disabled={
                    !values?.creditNoteNumber ||
                    !values?.creditNoteDate ||
                    !values?.refundDate ||
                    !values?.refundReference ||
                    !values?.creditNoteUrl ||
                    !values?.additionalInfo ||
                    !isEmpty(errors) ||
                    isSubmitting
                  }
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      {uploadModal ? (
        <FileUpload
          label='Browse'
          title='Upload File'
          fileUrl={values.creditNoteUrl}
          fileName={fileName}
          open={uploadModal}
          handleClose={handleFileUpload}
          submit={(url, fileNameUrl) => {
            setFieldValue('creditNoteUrl', url);
            setFileName(fileNameUrl);
            setUploadModal(false);
          }}
          submitButtonLabel='Upload'
        />
      ) : null}
    </Dialog>
  );
};

export default ViewUpdateLogsModal;
