import * as Yup from 'yup';
import { isValidPhoneNumber } from '../../../../utils/Utlities';
import { getEmailValidationSchema } from '../../../../utils/ValidationSchema';

export default Yup.object().shape({
  name: Yup.string().required('Please enter name of admin'),
  email: getEmailValidationSchema({ isRequired: true, isNullable: false }),
  mobile: Yup.string()
    .test({
      name: 'mobile',
      message: 'Please provide a valid Phone number',
      test: (value) => {
        if (value !== null) {
          return isValidPhoneNumber(value);
        }
      }
    })
    .required('Please provide a valid Phone number')
});
