import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, sortBy, isArray, forEach, isEmpty } from 'lodash';
import { toast } from 'react-toastify';

import validationSchema from './CreateQuestion.validation';

import { addDefaultQuestion as addDefaultQuestionAPI } from '../../../../api/admin';
import { errToastMessage, getErrorMessage } from '../../../../utils/Utlities';

export default compose(
  connect(null, null),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        status: true,
        name: ''
      };
      let propsValue = {};
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (values, { setFieldError, setStatus, setSubmitting, resetForm, props }) => {
      //Call API here and call handleclose here
      const questionStatus = values.status ? 'active' : 'inactive';
      addDefaultQuestionAPI({ name: values.name, status: questionStatus })
        .then((response) => {
          setSubmitting(false);
          toast.success('Question Added successfully');
          props?.pageDetails?.getQuestions &&
            props.pageDetails.getQuestions(
              props.pageDetails.rowsPerPage,
              0,
              props.pageDetails.status
            );
          props.handleClose();
        })
        .catch((error) => {
          setSubmitting(false);
          errToastMessage(error);
          console.error(error);
        });
    },
    displayName: 'Add new question'
  })
);
