import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  getRefQuestions as getRefQuestionsAPI,
  invalidateRefQuestions
} from '../../../actions/admin';

const mapStateToProps = (state) => {
  return {
    questions: state.refQuestions
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRefQuestions: (companyUuid, limit, offset, status) =>
      dispatch(getRefQuestionsAPI(companyUuid, limit, offset, status)),
    invalidateRefQuestions: () => dispatch(invalidateRefQuestions())
  };
};
export default compose(connect(mapStateToProps, mapDispatchToProps));
