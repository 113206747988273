import React, { useState, useEffect } from 'react';
import Input from '@material-ui/core/Input';
import { isEmpty } from 'lodash';
import {
  Typography,
  Paper,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Tooltip,
  Zoom,
  FormHelperText,
  Select,
  MenuItem,
  Grid,
  InputAdornment
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import moment from 'moment';
import { getToken } from '../../../api/storage';

import AppConstants from '../../../core-components/AppConstants';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import InfoIcon from '@material-ui/icons/Info';
import ConfirmationPopup from '../../../core-components/ConfirmationPopup';
import { fileUpload as fileUploadAPI } from '../../../api/admin';
import {
  errToastMessage,
  getErrorMessage,
  validConsentTypes,
  validDocTypes
} from '../../../utils/Utlities';
import { getFileMimeType } from '../frontend-common/core-components/Utilities/utilities';
import { UPLOAD_FILE_TYPES } from '../../../core-components/CommonConstants';
import InsuffEmailsCompanyAdminSelectionModal from './InsuffEmailsCompanyAdminSelectionModal';
import { CloseOutlined } from '@material-ui/icons';
import Dropdown from '../../../core-components/DropdownInput';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex'
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0, 2, 0),
    color: theme.palette.text.secondary
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  formControlNoBottomMargin: {
    marginTop: theme.spacing(2)
  },
  customTypo: {
    color: 'initial'
  },
  customTypoWithMarginBottom: {
    color: 'initial',
    marginBottom: theme.spacing(1)
  },
  fileInput: {
    opacity: 0,
    position: 'absolute',
    'z-index': -1
  },
  upload: {
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'center',
    'text-decoration': 'underline',
    color: 'blue',
    cursor: 'pointer'
  },
  errorContainer: {
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'center',
    marginTop: theme.spacing(-1)
  },
  error: {
    fontSize: '12px',
    color: '#f44336',
    margin: 0
  },
  tooltip: {
    marginLeft: theme.spacing(0.4)
  },
  consentContainer: {
    display: 'flex'
  },
  uploadContainer: {
    marginTop: '10px'
  },
  closeIcon: {
    marginRight: '15px',
    cursor: 'pointer'
  },
  errorMessage: {
    color: '#f44336',
    fontSize: '12px'
  }
}));

export default (props) => {
  const {
    values,
    setFieldValue,
    companyId,
    errors,
    setValidConsentFormat,
    setIsConsentUploaded,
    setIsAtleastOneAdminSelectedForInsuffEmails,
    countOfAdmins,
    validNameConfig,
    setValidNameConfig
  } = props;
  const classes = useStyles();
  const [fileName, setFileName] = useState('View');
  const [fileDate, setFileDate] = useState('');
  const [uploadedFile, setUploadedFile] = useState('');
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [uploadedFileError, setUploadedFileError] = useState(false);
  const [showAdminSelectionModal, setShowAdminSelectionModal] = useState(false);

  const handleDelete = () => {
    setDeleteConfirmation(!deleteConfirmation);
  };

  const handleChange = (e) => {
    setFieldValue('isUploading', true);
    if (e.currentTarget.files.length) {
      setFileName(e.currentTarget.files[0].name); //sets the file name
      setFileDate(moment()); //sets the current date when the file is uploaded
      setFieldValue('consentType', 'UNDERTAKING_LETTER'); // if files are provided it will automatically fetch radio button-2
      let fileType = '';
      var blob = e.currentTarget.files[0];
      var fileReader = new FileReader();
      fileReader.onloadend = () => {
        var arr = new Uint8Array(fileReader.result).subarray(0, 4);
        var header = '';
        for (var i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }
        // Check the file signature against known types
        fileType = getFileMimeType(header, blob);
        if (validDocTypes.consent.includes(fileType)) {
          setUploadedFileError(false);
          const where = {
            candidateId: companyId ? companyId : 1,
            companyId: companyId ? companyId : 1,
            file: blob,
            checkType: 'consent',
            fileType: UPLOAD_FILE_TYPES.CONSENT
          };
          fileUploadAPI(where)
            .then((response) => {
              setFieldValue('consentUndertakingLetterUrl', response.data.data);
              setUploadedFile(response.data.data);
              setFieldValue('isUploading', false);
            })
            .catch((error) => {
              console.error(error);
              errToastMessage(error);
              setFieldValue('isUploading', false);
            });
        } else {
          setUploadedFileError(true);
          setFieldValue('consentUndertakingLetterUrl', '');
          setUploadedFile('');
          setFieldValue('isUploading', false);
        }
      };
      if (blob instanceof Blob) {
        {
          /** To avoid TyperError issue reported by sentry, need to check if param is an Blob type or not */
        }
        fileReader.readAsArrayBuffer(blob);
      } else {
        console.error(
          `Failed to execute 'readAsArrayBuffer' on 'FileReader': parameter is not of type 'Blob'`
        );
      }
    }
    e.target.value = '';
  };

  const companyNameSettingDropdown = [
    { label: 'Legal name only', value: 'LEGAL_NAME_ONLY' },
    { label: 'Legal and Brand name both', value: 'LEGAL_AND_BRAND_NAME_BOTH' },
    { label: 'Brand name only', value: 'BRAND_NAME_ONLY' }
  ];
  const handleDropdown = (value) => {
    if (
      (value === 'LEGAL_AND_BRAND_NAME_BOTH' || value === 'BRAND_NAME_ONLY') &&
      (isEmpty(values.friendlyName) || !isEmpty(errors?.friendlyName))
    ) {
      setValidNameConfig(false);
    } else {
      setValidNameConfig(true);
      setFieldValue('nameConfigInReport', value);
    }
  };

  useEffect(() => {
    if (fileName !== 'View') setIsConsentUploaded(true);
    if (fileName === '' || validConsentTypes(fileName)) {
      setValidConsentFormat(true);
      setFieldValue('validConsentFormat', true);
    } else {
      setValidConsentFormat(false);
      setFieldValue('validConsentFormat', false);
    }
  }, [fileName]);

  useEffect(() => {
    if (isEmpty(values.friendlyName) || !isEmpty(errors?.friendlyName)) {
      setFieldValue('includeBrandNameInReport', false);
    }
    if (
      (values?.nameConfigInReport === 'LEGAL_AND_BRAND_NAME_BOTH' ||
        values?.nameConfigInReport === 'BRAND_NAME_ONLY') &&
      (isEmpty(values.friendlyName) || !isEmpty(errors?.friendlyName))
    ) {
      setValidNameConfig(false);
    } else {
      setValidNameConfig(true);
    }
  }, [values.friendlyName, errors?.friendlyName]);

  useEffect(() => {
    if (countOfAdmins === 0) {
      if (
        values.reminderViaEmail === 'HR' ||
        (values.reminderViaEmail === 'CANDIDATE' && values.isEmailOptional === 1) ||
        (!values.isEmailOptional && values.reminderViaEmail === '')
      )
        props?.setIsInsuffReminderCaseConditionsSatisfied(false);
      else props?.setIsInsuffReminderCaseConditionsSatisfied(true);
    }

    if (countOfAdmins) {
      if (values.isEmailOptional) {
        if (values.reminderViaEmail === 'HR') {
          props?.setIsInsuffReminderCaseConditionsSatisfied(true);
        } else {
          props?.setIsInsuffReminderCaseConditionsSatisfied(false);
        }
      } else {
        if (values.reminderViaEmail === 'CANDIDATE' || values.reminderViaEmail === 'HR') {
          props?.setIsInsuffReminderCaseConditionsSatisfied(true);
        } else {
          props?.setIsInsuffReminderCaseConditionsSatisfied(false);
        }
      }
    }
  }, [values]);

  return (
    <>
      <Typography variant={'h6'}>Candidate Details Configuration </Typography>
      <Paper className={classes.paper} variant='outlined' elevation={3}>
        <Typography className={classes.customTypo}>Basic Information</Typography>
        <FormControl component='fieldset' className={classes.formControl}>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                onChange={(e) => {
                  setFieldValue('isEmailOptional', e.target.checked ? 1 : 0);

                  /**
                   * If the admin count is zero,
                   * * If checked, set the reminderViaEmail to empty string
                   * * If unchecked, set the reminderViaEmail to 'CANDIDATE'
                   */
                  if (countOfAdmins === 0) {
                    if (e.target.checked && props?.isCreateCompanyFlow) {
                      setFieldValue('reminderViaEmail', '');
                    }

                    if (!e.target.checked) {
                      setFieldValue('reminderViaEmail', 'CANDIDATE');
                    }
                  }
                }}
                checked={values.isEmailOptional === 1 ? true : false}
                name={'isEmailOptional'}
                value={values.isEmailOptional}
              />
            }
            label={'Allow candidates without email'}
          />
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                onChange={(e) => setFieldValue('isResumeOptional', e.target.checked ? 1 : 0)}
                checked={values.isResumeOptional === 1 ? true : false}
                name={'isResumeOptional'}
                value={values.isResumeOptional}
              />
            }
            label={'Allow candidates without resume'}
          />{' '}
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                onChange={(e) => setFieldValue('isGenderOptional', e.target.checked ? 0 : 1)}
                checked={!values.isGenderOptional}
                name={'isGenderOptional'}
                value={values.isGenderOptional}
              />
            }
            label={'Sex (Gender) required'}
          />
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                onChange={(e) => setFieldValue('isUanMandatory', e.target.checked ? true : false)}
                checked={values.isUanMandatory}
                name={'isUanMandatory'}
                value={values.isUanMandatory}
              />
            }
            label={'Make UAN mandatory'}
          />
        </FormControl>
        <Typography variant={'body1'} className={classes.customTypo}>
          Aadhaar in ID Check?
        </Typography>
        <FormControl component='fieldset' fullWidth className={classes.formControl}>
          <RadioGroup
            row
            aria-label='includeAadhaarInIdCheck'
            name='includeAadhaarInIdCheck'
            value={values.includeAadhaarInIdCheck}
            onChange={(e) => {
              setFieldValue('includeAadhaarInIdCheck', e.target.value);
            }}
          >
            <FormControlLabel value={'1'} control={<Radio color='primary' />} label='Yes' />
            <FormControlLabel value={'0'} control={<Radio color='primary' />} label='No' />
          </RadioGroup>
        </FormControl>
        <Typography variant={'body1'} className={classes.customTypo}>
          Disclose Client Name for Work Experience
        </Typography>
        <FormControl component='fieldset' fullWidth className={classes.formControl}>
          <RadioGroup
            row
            aria-label='discloseInfo'
            name='discloseInfo'
            value={values.discloseInfo}
            onChange={(e) => {
              setFieldValue('discloseInfo', e.target.value);
            }}
          >
            <FormControlLabel value={'YES'} control={<Radio color='primary' />} label='Yes' />
            <FormControlLabel value={'NO'} control={<Radio color='primary' />} label='No' />
            <FormControlLabel
              value={'CASE'}
              control={<Radio color='primary' />}
              label='Case By Case'
            />
            <FormControlLabel value={'NA'} control={<Radio color='primary' />} label='Not Set' />
          </RadioGroup>
          {errors?.discloseInfo && (
            <FormHelperText id='disclose-helper-text' error>
              {errors?.discloseInfo}
            </FormHelperText>
          )}
        </FormControl>
        <Typography variant={'body1'} className={classes.customTypo}>
          Send SMS for address verification
        </Typography>
        <FormControl component='fieldset' fullWidth className={classes.formControl}>
          <RadioGroup
            row
            aria-label='isOptedForAddressCheckSms'
            name='isOptedForAddressCheckSms'
            value={values.isOptedForAddressCheckSms}
            onChange={(e) => {
              setFieldValue('isOptedForAddressCheckSms', e.target.value);
            }}
          >
            <FormControlLabel value={'1'} control={<Radio color='primary' />} label='Yes' />
            <FormControlLabel value={'0'} control={<Radio color='primary' />} label='No' />
          </RadioGroup>
        </FormControl>
        <Typography variant={'body1'} className={classes.customTypo}>
          {'Send Insufficiency emails and reminder with insuff comment'}
          {/**
           * Show the asterisk only if the reminderViaEmail is empty and the flow is not create company flow
           */}
          {!['CANDIDATE', 'HR'].includes(values.reminderViaEmail) && !props?.isCreateCompanyFlow ? (
            <span className={classes.errorMessage}>*</span>
          ) : null}
        </Typography>
        <FormControl component='fieldset' fullWidth className={classes.formControl}>
          <Grid lg={6} md={6} sm={8} xs={12}>
            {/**
             * Show this option only if the reminderViaEmail is empty and the flow is not create company flow
             */}
            {!['CANDIDATE', 'HR'].includes(values.reminderViaEmail) &&
            !props?.isCreateCompanyFlow ? (
              <span className={classes.errorMessage}>Select an option</span>
            ) : null}
            <Select
              fullWidth
              width={200}
              name={'reminderViaEmail'}
              value={values.reminderViaEmail}
              endAdornment={
                props?.isCreateCompanyFlow && !isEmpty(values.reminderViaEmail) ? (
                  <InputAdornment
                    className={classes.closeIcon}
                    onClick={() => {
                      setFieldValue('reminderViaEmail', '');
                    }}
                    position='end'
                  >
                    <CloseOutlined />
                  </InputAdornment>
                ) : null
              }
              onChange={(e) => {
                if (e.target.value === 'HR' && countOfAdmins) {
                  setShowAdminSelectionModal(true);
                }
                setFieldValue('reminderViaEmail', e.target.value);
              }}
            >
              <MenuItem value={'CANDIDATE'}>{'Candidate Only'} </MenuItem>

              <MenuItem value={'HR'}>{'CA Only'} </MenuItem>
            </Select>

            <div className={classes.errorMessage}>
              {values.reminderViaEmail === 'HR' &&
                countOfAdmins === 0 &&
                `Add at least one CA Admin to enable this option`}
              {values.reminderViaEmail === 'CANDIDATE' &&
                values.isEmailOptional === 1 &&
                `This option cannot be selected as Allow candidates without email check box is enabled`}
            </div>
          </Grid>
        </FormControl>
        <Typography variant={'body1'} className={classes.customTypo}>
          {'Candidate Consent'}
        </Typography>
        <FormControl component='fieldset' fullWidth className={classes.formControl}>
          <RadioGroup
            row
            aria-label='Candidate Consent'
            name='consentType'
            value={values.consentType}
            onChange={(e) => {
              setFieldValue('consentType', e.target.value);
            }}
          >
            <div>
              <FormControlLabel
                control={<Radio color='primary' />}
                label='Consent required for each candidate added'
                value={'CONSENT_LETTER'}
              />
            </div>
            <div className={classes.consentContainer}>
              <div>
                <FormControlLabel
                  control={<Radio color='primary' />}
                  label='Consent Undertaking Letter'
                  value={'UNDERTAKING_LETTER'}
                />
                {values?.consentUndertakingLetterUrl && (
                  <div className={classes.upload}>
                    <div
                      onClick={() => {
                        uploadedFile
                          ? window.open(uploadedFile, '_blank')
                          : window.open(
                              `${
                                AppConstants.developmentAPIURL
                              }auth/docs?company_id=${companyId}&type=consent_undertaking_letter_url&token=${getToken()}`,
                              '_blank'
                            );
                      }}
                    >
                      {fileName}
                      {values?.consentUndertakingLetterDate || fileDate !== ''
                        ? ' (' +
                          moment(fileDate ? fileDate : values?.consentUndertakingLetterDate)
                            .utc('GMT+5:30')
                            .format('YYYY-MM-DD') +
                          ')'
                        : ''}
                    </div>
                    <div onClick={handleDelete}>
                      <Tooltip TransitionComponent={Zoom} title='Delete' arrow={true}>
                        <DeleteIcon color='secondary' />
                      </Tooltip>
                    </div>
                  </div>
                )}

                {!values.validConsentFormat && fileName !== '' && fileName !== 'View' && (
                  <div className={classes.errorContainer}>
                    <span className={classes.error}>
                      Please provide a valid consent format ({validDocTypes.consent})
                    </span>
                  </div>
                )}
              </div>
              <div className={classes.uploadContainer}>
                {!values?.consentUndertakingLetterUrl && (
                  <label htmlFor={`upload`} className={classes.upload}>
                    Upload
                  </label>
                )}
              </div>
            </div>
            <Input
              inputProps={{ accept: props.accept || validDocTypes.consent }}
              className={classes.fileInput}
              id={'upload'}
              onChange={handleChange}
              type='file'
            />
            {uploadedFileError && (
              <div className={classes.error}>
                Please provide a valid consent format ({validDocTypes.consent})
              </div>
            )}
          </RadioGroup>
        </FormControl>
        <Typography variant={'body1'} className={classes.customTypo}>
          {'Candidate Report'}
        </Typography>
        <FormControl component='fieldset' className={classes.formControlNoBottomMargin}>
          <Typography variant={'body1'} className={classes.customTypoWithMarginBottom}>
            {'Show Company Name in Report'}
          </Typography>

          <Dropdown
            dropdownValues={companyNameSettingDropdown}
            name='companyNameSetting'
            label={'Select'}
            value={values?.nameConfigInReport}
            handleSelect={(value) => {
              handleDropdown(value);
            }}
          />
        </FormControl>
        <div className={classes.errorMessage}>
          {!validNameConfig && `Please provide a valid Brand Name to save this option`}
        </div>

        <FormControl component='fieldset' fullWidth className={classes.formControlNoBottomMargin}>
          <RadioGroup row aria-label='enableDateInCandidateReport'>
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  checked={values.enableDateInCandidateReport}
                  inputProps={{
                    'aria-label': 'secondary checkbox'
                  }}
                  onChange={(e) => {
                    setFieldValue(
                      'enableDateInCandidateReport',
                      !values.enableDateInCandidateReport
                    );
                  }}
                />
              }
              label={
                <div>
                  Enable Date of Submission and Date of Completion in the report.
                  <Tooltip
                    className={classes.tooltip}
                    TransitionComponent={Zoom}
                    placement='top'
                    title={
                      <div>
                        Enabling this checkbox will show START DATE and END DATE for
                        'Completed','Comp with Exception' and 'Closed' candidates.
                      </div>
                    }
                    arrow={true}
                  >
                    <InfoIcon />
                  </Tooltip>
                </div>
              }
            />
          </RadioGroup>
        </FormControl>
      </Paper>
      {deleteConfirmation ? (
        <ConfirmationPopup
          open={deleteConfirmation}
          title='Delete'
          content='Are you sure you want to delete the uploded file?'
          handleSubmit={() => {
            setFileName('');
            setFileDate('');
            setFieldValue('consentUndertakingLetterUrl', '');
            setFieldValue('consentType', 'CONSENT_LETTER');
          }}
          handleClose={() => {
            setDeleteConfirmation(!deleteConfirmation);
          }}
          buttonLabel={'Delete'}
          buttonColor={'secondary'}
        />
      ) : null}

      {showAdminSelectionModal ? (
        <InsuffEmailsCompanyAdminSelectionModal
          totalCountOfAdmins={countOfAdmins}
          show={showAdminSelectionModal}
          setFieldValue={setFieldValue}
          setIsAtleastOneAdminSelectedForInsuffEmails={setIsAtleastOneAdminSelectedForInsuffEmails}
          companyId={companyId}
          hide={() => {
            setShowAdminSelectionModal(false);
            setTimeout(() => {
              const body = document.querySelector('body');
              if (body) {
                body.style.overflow = 'auto';
              }
            }, 100);
          }}
        />
      ) : null}
    </>
  );
};
