import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faStar } from '@fortawesome/free-solid-svg-icons';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CheckIcon from '@material-ui/icons/Check';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ordinal from 'ordinal';

import {
  PLAIN_CHECK_ICONS,
  IdentityArr,
  AddressArr,
  EducationArr,
  DRUG_TEXT,
  addressVerificationMediumObject,
  courtVerificationMediumObject
} from '../../../../../core-components/CommonConstants';
import { getCustomCheckSVG, getFilteredChecks } from '../../../../../utils/Utlities';
import { isArray, isPlainObject } from 'lodash';

const useStyles = makeStyles((theme) => ({
  checkContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  checkTextHeading: {
    color: '#333333',
    fontSize: '16px !important',
    fontWeight: 500
  },
  root: {
    minWidth: '34px'
  },
  checkText: {
    color: '#555555',
    fontSize: '14px'
  },
  checksubText: {
    color: '#555555',
    fontSize: '12px',
    paddingLeft: '2.4rem'
  }
}));

const renderCreditWorld = (type = 'world', heading = 'World check') => {
  const classes = useStyles();
  return (
    <Grid item lg={6} md={6} sm={6} xs={12}>
      <List>
        <ListItem disableGutters style={{ padding: 0 }}>
          <ListItemIcon className={classes.root}>
            <FontAwesomeIcon
              icon={PLAIN_CHECK_ICONS[type] ? PLAIN_CHECK_ICONS[type].icon : faBan}
              color='#2755FE'
              style={{ fontSize: '16px' }}
            />
          </ListItemIcon>
          <Typography component={'span'} className={classes.checkTextHeading}>
            {heading}
          </Typography>
        </ListItem>
      </List>
    </Grid>
  );
};

const getFormattedChecks = (checkName, type, title, classes) => {
  return (
    <Grid item lg={6} md={6} sm={6} xs={12}>
      <List>
        <ListItem disableGutters style={{ padding: 0 }}>
          <ListItemIcon className={classes.root}>
            <FontAwesomeIcon
              icon={PLAIN_CHECK_ICONS[type] ? PLAIN_CHECK_ICONS[type].icon : faBan}
              color='#2755FE'
              style={{ fontSize: '16px' }}
            />
          </ListItemIcon>
          <Typography component={'span'} className={classes.checkTextHeading}>
            {title}
          </Typography>
        </ListItem>
        {checkName.map((check, index) => {
          const str = getFilteredChecks(checkName, type);
          const isAnyExist = isPlainObject(check) ? check.type.match(/ANY_/g) : check.match(/ANY_/g);
          return (
            <ListItem key={`${type}_${index}`} disableGutters style={{ padding: '0' }}>
              <div className={classes.checkContainer}>
                <ListItemIcon className={classes.root}>
                  <CheckIcon style={{ color: '#52C41A', fontSize: '16px' }} />
                </ListItemIcon>
                {isAnyExist ? (
                  <>
                    <Typography component={'span'} className={classes.checkText}>
                      {'Any'}
                    </Typography>
                    &nbsp;
                    <Typography
                      component={'span'}
                      className={classes.checkText}
                      variant={'subtitle2'}
                    >{`${isPlainObject(check) ? check.type.split('_')[1] : check.split('_')[1]}`}</Typography>

                    <Typography
                      component={'span'}
                      className={classes.checkText}
                      style={{ marginLeft: '8px' }}
                    >{`(CURRENT, PERMANENT)`}</Typography>
                  </>
                ) : (
                  <Typography component={'span'} className={classes.checkText}>
                    {AddressArr[check.type]}
                  </Typography>
                )}
              </div>
            </ListItem>
          );
        })}
      </List>
    </Grid>
  );
};

function ChecksIncluded({ config, isAadhaarAllowed }) {
  const classes = useStyles();
  return (
    <>
      {config?.identity && config?.identity?.length ? (
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <List>
            <ListItem disableGutters style={{ padding: 0 }}>
              <ListItemIcon className={classes.root}>
                <FontAwesomeIcon
                  icon={PLAIN_CHECK_ICONS['identity'] ? PLAIN_CHECK_ICONS['identity'].icon : faBan}
                  color='#2755FE'
                  style={{ fontSize: '16px' }}
                />
              </ListItemIcon>
              <Typography component={'span'} className={classes.checkTextHeading}>
                {'Identity'}
              </Typography>
            </ListItem>

            {config?.identity.map((check, index) => {
              let str = getFilteredChecks(config.identity, 'identity');
              if (str) {
                str = isAadhaarAllowed ? str : str.filter((ID) => ID !== 'AADHAAR');
              }
              const isAnyExist = check.match(/ANY_/g) ? true : false;
              const isAllExist = config?.identity?.includes('ALL') ? true : false;
              const allChecks = isAadhaarAllowed
                ? Object.values(IdentityArr)
                : Object.values(IdentityArr).filter((ID) => ID !== 'Aadhaar');
              return (
                <ListItem key={`identity_${index}`} disableGutters style={{ padding: 0 }}>
                  <ListItemIcon className={classes.root}>
                    <CheckIcon style={{ color: '#52C41A', fontSize: '16px' }} />
                  </ListItemIcon>
                  {isAllExist ? (
                    <>
                      <Typography component={'span'} className={classes.checkText}>
                        {'All'}
                      </Typography>
                      &nbsp;
                      <Typography
                        component={'span'}
                        className={classes.checkText}
                        style={{ marginLeft: '8px' }}
                      >{`(${allChecks.join(', ')})`}</Typography>
                    </>
                  ) : null}
                  {isAnyExist ? (
                    <>
                      <Typography component={'span'} className={classes.checkText}>
                        {'Any'}
                      </Typography>
                      &nbsp;
                      <Typography
                        component={'span'}
                        className={classes.checkText}
                        variant={'subtitle2'}
                      >{`${check.split('_')[1]}`}</Typography>
                      {str && (
                        <Typography
                          component={'span'}
                          className={classes.checkText}
                          style={{ marginLeft: '8px' }}
                        >{`(${str.join(', ')})`}</Typography>
                      )}
                    </>
                  ) : (
                    <Typography component={'span'} className={classes.checkText}>
                      {IdentityArr[check]}
                    </Typography>
                  )}
                </ListItem>
              );
            })}
          </List>
        </Grid>
      ) : null}

      {config?.address && config?.address?.length
        ? getFormattedChecks(
          config.address,
          'address',
          'Address',
          classes
        )
        : null}

      {config?.court && config?.court?.length
        ? getFormattedChecks(
          config.court,
          'court',
          'Court',
          classes
        )
        : null}

      {config?.employment && config?.employment > 0 ? (
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <List>
            <ListItem disableGutters style={{ padding: 0 }}>
              <ListItemIcon className={classes.root}>
                <FontAwesomeIcon
                  icon={
                    PLAIN_CHECK_ICONS['employment'] ? PLAIN_CHECK_ICONS['employment'].icon : faBan
                  }
                  color='#2755FE'
                  style={{ fontSize: '16px' }}
                />
              </ListItemIcon>
              <Typography component={'span'} className={classes.checkTextHeading}>
                {'Employment'}
              </Typography>
            </ListItem>
            <ListItem disableGutters style={{ padding: 0 }}>
              <ListItemIcon className={classes.root}>
                <CheckIcon style={{ color: '#52C41A', fontSize: '16px' }} />
              </ListItemIcon>
              <Typography component={'span'} className={classes.checkText}>
                {'Last'}
              </Typography>
              &nbsp;
              <Typography component={'span'} className={classes.checkText} variant={'subtitle2'}>
                {config.employment}
              </Typography>
            </ListItem>
          </List>
        </Grid>
      ) : null}

      {config?.education && (config?.education > 0 || config?.education?.length) ? (
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <List>
            <ListItem disableGutters style={{ padding: 0 }}>
              <ListItemIcon className={classes.root}>
                <FontAwesomeIcon
                  icon={
                    PLAIN_CHECK_ICONS['education_official']
                      ? PLAIN_CHECK_ICONS['education_official'].icon
                      : faBan
                  }
                  color='#2755FE'
                  style={{ fontSize: '16px' }}
                />
              </ListItemIcon>
              <Typography component={'span'} className={classes.checkTextHeading}>
                {'Education'}
              </Typography>
            </ListItem>

            {typeof config.education === 'number' ? (
              <ListItem disableGutters style={{ padding: 0 }}>
                <ListItemIcon className={classes.root}>
                  <CheckIcon style={{ color: '#52C41A', fontSize: '16px' }} />
                </ListItemIcon>
                <Typography component={'span'} className={classes.checkText}>
                  {'Highest'}
                </Typography>
                &nbsp;
                <Typography component={'span'} className={classes.checkText} variant={'subtitle2'}>
                  {config.education}
                </Typography>
              </ListItem>
            ) : (
              config?.education.map((edu, index) => {
                return (
                  <ListItem disableGutters key={index} style={{ padding: 0 }}>
                    <ListItemIcon className={classes.root}>
                      <CheckIcon style={{ color: '#52C41A', fontSize: '16px' }} />
                    </ListItemIcon>
                    <Typography component={'span'} className={classes.checkText}>
                      {EducationArr[edu]}
                    </Typography>
                  </ListItem>
                );
              })
            )}
          </List>
        </Grid>
      ) : null}

      {config?.reference && (config?.reference > 0 || config?.reference.length) ? (
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <List>
            <ListItem disableGutters style={{ padding: 0 }}>
              <ListItemIcon className={classes.root}>
                <FontAwesomeIcon
                  icon={
                    PLAIN_CHECK_ICONS['reference'] ? PLAIN_CHECK_ICONS['reference'].icon : faBan
                  }
                  color='#2755FE'
                  style={{ fontSize: '16px' }}
                />
              </ListItemIcon>
              <Typography component={'span'} className={classes.checkTextHeading}>
                {'Reference'}
              </Typography>
            </ListItem>
            {config?.referenceType === 'SPECIFIC' ||
              config?.referenceType === 'SPECIFIC_LOCK' ||
              (config?.referenceType === 'TOTAL' && isArray(config.reference))
              ? config?.reference.map((ref, index) => {
                if (ref > 0) {
                  return (
                    <ListItem disableGutters key={index} style={{ padding: 0 }}>
                      <ListItemIcon className={classes.root}>
                        <CheckIcon style={{ color: '#52C41A', fontSize: '16px' }} />
                      </ListItemIcon>
                      <Typography
                        component={'span'}
                        className={classes.checkText}
                        variant={'subtitle2'}
                      >
                        {ref}
                      </Typography>
                      &nbsp;
                      <Typography
                        component={'span'}
                        className={classes.checkText}
                      >{`references from ${index === 0 ? 'last employment' : `${ordinal(index + 1)} last employment`
                        } `}</Typography>
                    </ListItem>
                  );
                }
              })
              : null}
            {config?.referenceType === 'SPECIFIC' || config?.referenceType === 'TOTAL' ? (
              <ListItem disableGutters style={{ padding: 0 }}>
                <ListItemIcon className={classes.root}>
                  <FontAwesomeIcon icon={faStar} color='#eb3a34' style={{ fontSize: '16px' }} />
                </ListItemIcon>
                <Typography component={'span'} className={classes.checkTextHeading}>
                  {'Configurable'}
                </Typography>
              </ListItem>
            ) : null}
            {config?.referenceType === 'SPECIFIC_LOCK' ? (
              <ListItem disableGutters style={{ padding: 0 }}>
                <ListItemIcon className={classes.root}>
                  <FontAwesomeIcon icon={faStar} color='#eb3a34' style={{ fontSize: '16px' }} />
                </ListItemIcon>
                <Typography component={'span'} className={classes.checkTextHeading}>
                  {'Fixed'}
                </Typography>
              </ListItem>
            ) : null}
            {(config?.referenceType === 'TOTAL' && !isArray(config.reference)) ||
              config?.referenceType === 'NONE' ? (
              <ListItem disableGutters style={{ padding: 0 }}>
                <ListItemIcon className={classes.root}>
                  <CheckIcon style={{ color: '#52C41A', fontSize: '16px' }} />
                </ListItemIcon>
                <Typography component={'span'} className={classes.checkText}>
                  {'Total'}
                </Typography>
                &nbsp;
                <Typography component={'span'} className={classes.checkText} variant={'subtitle2'}>
                  {config?.reference}
                </Typography>
              </ListItem>
            ) : null}
          </List>
        </Grid>
      ) : null}

      {config?.world ? renderCreditWorld('world', 'World check') : null}

      {config?.credit ? renderCreditWorld('credit', 'Credit Check') : null}

      {config?.drug ? renderCreditWorld('drug', `Drug test (${DRUG_TEXT[config.drug]})`) : null}

      {config?.custom && config?.custom?.length ? (
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <List>
            <ListItem disableGutters style={{ padding: 0 }}>
              <ListItemIcon className={classes.root}>
                {getCustomCheckSVG('#2755FE', '20px')}
              </ListItemIcon>
              <Typography component={'span'} className={classes.checkTextHeading}>
                {'Custom check'}
              </Typography>
            </ListItem>
            {config?.custom.map((custom, index) => {
              return (
                <ListItem disableGutters key={index} style={{ padding: 0 }}>
                  <ListItemIcon className={classes.root}>
                    <CheckIcon style={{ color: '#52C41A', fontSize: '16px' }} />
                  </ListItemIcon>
                  <Typography component={'span'} className={classes.checkText}>
                    {custom}
                  </Typography>
                </ListItem>
              );
            })}
          </List>
        </Grid>
      ) : null}
    </>
  );
}

export default ChecksIncluded;
