import React, { useEffect, useState } from 'react';
import { has, isEmpty, isEqual } from 'lodash';
import { Field } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography, Button, Box, TextField } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import Select from 'react-select';

import SkipNextOutlinedIcon from '@material-ui/icons/SkipNextOutlined';
import FormInput from '../../../../core-components/FormInput';
import BreadCrumbs from '../../../../core-components/BreadCrumbs';
import AgreementListing from '../AgreementDetails';
import AppConstants from '../../../../core-components/AppConstants';
import {
  uploadAgreementDocs as uploadAgreementDocsAPI
} from '../../../../api/admin';
import {
  errToastMessage,
  getCityNameWithoutAsterisk,
  getTrimmedValue,
  handleFillStateAndCityValue,
  isStateValid
} from '../../../../utils/Utlities';

import countriesList from '../../../../utils/countryNames.json';

const stateAndCity = require('country-state-city');
const lookup = require('country-code-lookup');

import { UPLOAD_FILE_TYPES } from '../../../../core-components/CommonConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex'
  },
  paper: {
    padding: theme.spacing(4),
    margin: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  heading: {
    paddingBottom: theme.spacing(2)
  },
  activeBtn: {
    margin: theme.spacing(1),
    width: '240px',
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    },
    height: 'fitContent',
    marginTop: 'auto'
  },
  disabledBtn: {
    margin: theme.spacing(1),
    width: '240px',
    color: '#dfdfdf',
    borderColor: '#d9d9d9',
    backgroundColor: '#f5f5f5',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    },
    height: 'fitContent',
    marginTop: 'auto'
  },
  skipBtn: {
    marginTop: '30px',
    margin: theme.spacing(1),
    width: '240px',
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#1976D2',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#1976D2'
    }
  },
  saveButtonGrid: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  dropDown: {
    background: 'white',
    height: '40px',
    width: '100%',
    border: 'none',
    borderColor: 'hsl(0,0%,80%)',
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '1px',
    marginBottom: '11px'
  },
  label: { fontSize: '13px' },
  errorMessage: { fontSize: '15px', color: 'red', marginBottom: '10px' },
  customField: { marginBottom: '16px' },
  errorMsg: {
    fontSize: '13px',
    fontWeight: '500',
    marginLeft: '2px',
    color: '#f44336',
    marginBottom: '10px'
  }
}));

const getNames = (arr) => {
  return arr.map((obj) => obj.name);
};

export default (props) => {
  const classes = useStyles();
  const [companyId, setCompanyId] = useState(
    new URLSearchParams(document.location.search).get('company_id') || null
  );
  const [companyName, setCompanyName] = useState('');
  const [agreementDetailsArr, setAgreementDetailsArr] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const isCreateFlow = new URLSearchParams(document.location.search).get('create') || false;
  const packageType = new URLSearchParams(document.location.search).get('package');
  const breadCrumbsArray = [
    { label: 'Home', href: `${AppConstants.baseURL}dashboard` },
    { label: 'Companies', href: `${AppConstants.baseURL}companies` },
    {
      label: companyName || 'Company Name',
      href: `${AppConstants.baseURL}company/view?id=${companyId}`
    }
  ];
  const [isValidPinCode, setIsValidPinCode] = useState(true);
  const [initAggArr, setInitAggArr] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  const [cities, setCities] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const onBlurPinCodeHandler = () => {
    const pinCode = props.values.pinCode;
    if (isEmpty(props?.errors?.pinCode)) {
      setIsValidPinCode(false);
      handleFillStateAndCityValue(values, setFieldValue, pinCode, true);
      setIsValidPinCode(true);
    }
  };

  const clearAddressFields = () => {
    props.setFieldValue('pinCode', '');
    setTimeout(() => {
      props.setFieldValue('city', '');
      setSelectedCity(null);
    }, 1000);
  };
  const setValue = (fieldsName, value) => {
    props.setFieldValue(fieldsName, value);
  };

  useEffect(() => {
    const country = props.values.country;

    if (country) {
      const countryDetails = lookup.byCountry(country);
      let countryCode;

      if (countryDetails) {
        countryCode = countryDetails.iso2;
      }
      const val = getNames(stateAndCity.City.getCitiesOfCountry(countryCode));
      const cityOptions = val.map((city) => ({
        label: city,
        value: city
      }));
      setCities(cityOptions);
    }

    if (
      !isEmpty(props.values.city) &&
      !props.values.city.endsWith('*') &&
      checkValueExists(props.values.city)
    ) {
      setSelectedCity({ label: props.values.city, value: props.values.city });
    }

    if (isEmpty(props.values.city) && selectedCity !== null) {
      setSelectedCity(null);
    }
  }, [props.values]);

  useEffect(() => {
    if (selectedCity === null && !isEmpty(props.values.city)) {
      setSelectedCity({ label: props.values.city, value: props.values.city });
    }
  }, [cities]);

  useEffect(() => {
    if (props?.companySettings && !props?.companySettings?.loading) {
      setAgreementDetailsArr(props.companySettings.companyAgreements);
      if (props.companySettings.companyAgreements)
        setInitAggArr(JSON.parse(JSON.stringify(props.companySettings.companyAgreements)));
      setCompanyName(props.companySettings.name || null);
    }
  }, [props.companySettings]);

  useEffect(() => {
    props.invalidateCompanySettings();
    props.getCompanySettings(companyId);

    return () => {
      props.invalidateCompanySettings();
      setSelectedCity(null);
      props.resetForm();
    };
  }, []);

  useEffect(() => {
    setIsDirty(!isEqual(agreementDetailsArr, initAggArr));
  }, [agreementDetailsArr, initAggArr]);

  const handleAddDocument = (index) => {
    const agreementDetailsCopy = [...agreementDetailsArr];
    if (agreementDetailsCopy[index]['companyAgreementDocuments']) {
      agreementDetailsCopy[index]['companyAgreementDocuments'].push({
        documentType: 'master_service_agreement',
        file: ''
      });
    } else {
      agreementDetailsCopy[index]['companyAgreementDocuments'] = [];
      agreementDetailsCopy[index]['companyAgreementDocuments'].push({
        documentType: 'master_service_agreement',
        file: ''
      });
    }
    setAgreementDetailsArr([...agreementDetailsCopy]);
  };

  const handleAddAgreement = () => {
    const agreementDetailsCopy = [...agreementDetailsArr];
    agreementDetailsCopy.push({
      status: 'empty',
      expiryDate: null,
      executionDate: null,
      companyAgreementDocuments: []
    });
    setAgreementDetailsArr([...agreementDetailsCopy]);
  };

  const handleAgreementChanges = (value, fieldName, index) => {
    const agreementDetailsCopy = [...agreementDetailsArr];
    const initCopy = [...initAggArr];

    if (agreementDetailsCopy[index]) {
      if (fieldName === 'executionDate' || fieldName === 'expiryDate') {
        agreementDetailsCopy[index][fieldName] = value.format('YYYY-MM-DD');

        let executionDate = agreementDetailsCopy[index]['executionDate'] || null;
        let expiryDate = agreementDetailsCopy[index]['expiryDate'] || null;
        if (executionDate && expiryDate && moment(executionDate) > moment(expiryDate)) {
          if (index < initCopy.length) initCopy[index].errorMsg = null;

          agreementDetailsCopy[index].errorMsg = 'Execution Date must be lesser than Expiry Date';
        } else if (executionDate && !expiryDate) {
          if (index < initCopy.length) initCopy[index].errorMsg = null;

          agreementDetailsCopy[index].errorMsg = 'Expiry Date is required with Execution Date';
        } else if (!executionDate && expiryDate) {
          if (index < initCopy.length) initCopy[index].errorMsg = null;

          agreementDetailsCopy[index].errorMsg = 'Execution Date is required with Expiry Date';
        } else {
          if (index < initCopy.length) initCopy[index].errorMsg = null;

          agreementDetailsCopy[index].errorMsg = null;
        }

        if (has(initCopy, 'erroMsg')) setInitAggArr(initCopy);
      } else {
        agreementDetailsCopy[index][fieldName] = value;
      }
      setAgreementDetailsArr([...agreementDetailsCopy]);
    }
  };

  const handleDocChanges = (value, agreementIndex, docIndex, fieldName) => {
    const agreementDetailsCopy = [...agreementDetailsArr];
    if (fieldName === 'file') {
      setIsUploading(true);
      uploadAgreementDocsAPI({
        companyId: props.values.companyId,
        agreementDocumentFile: value,
        fileType: UPLOAD_FILE_TYPES.COMPANY_AGREEMENT
      })
        .then((response) => {
          setIsDirty(true);
          setIsUploading(false);
          if (response.data && response.data.data) {
            agreementDetailsCopy[agreementIndex]['companyAgreementDocuments'][docIndex][fieldName] =
              response.data.data;
          }
        })
        .catch((error) => {
          setIsUploading(false);
          console.error(error);
          errToastMessage(error);
          agreementDetailsCopy[agreementIndex]['companyAgreementDocuments'][docIndex][fieldName] =
            '';
        });
    } else {
      agreementDetailsCopy[agreementIndex]['companyAgreementDocuments'][docIndex][fieldName] =
        value;
    }
    setAgreementDetailsArr([...agreementDetailsCopy]);
  };

  const handleFormSubmit = (name) => {
    switch (name) {
      case 'skip':
        window.open(
          `${AppConstants.baseURL}company/addonConfiguration?create=true&id=${companyId}&package_type=${packageType}`,
          '_self'
        );
        break;
      case 'saveAndNext':
      default:
        props.setFieldValue('companyAgreements', [...agreementDetailsArr]);
        handleSubmit();
        break;
    }
  };

  const hasError = () => {
    let i;
    if (!agreementDetailsArr || !agreementDetailsArr.length) {
      return false;
    }
    for (i = 0; i < agreementDetailsArr.length; i++) {
      if (agreementDetailsArr[i].errorMsg) {
        return true;
      }
    }
    return false;
  };

  const handleAutoTrim = (fieldName, e) => {
    const trimmedValue = getTrimmedValue(e.target.value, true);
    props.setFieldValue(fieldName, trimmedValue);
  };

  const {
    handleSubmit,
    isSubmitting,
    values,
    errors,
    setFieldValue,
    dirty,
    handleChange
  } = props;
  const isValidForm = !isSubmitting && isEmpty(errors) && !hasError() && !isUploading;

  const checkValueExists = (value) => {
    if (isEmpty(value)) return true;

    if (value && !isEmpty(cities)) {
      for (let obj of cities) {
        if (obj.value === value) {
          return true;
        }
      }
    }
    return false;
  };

  const handleCityChange = (selectedOption) => {
    setFieldValue('city', selectedOption?.value || '');
    setSelectedCity(selectedOption);
  };

  return (
    <>
      {props?.companySettings && !props?.companySettings?.loading ? (
        <Grid className={classes.root} container direction={'row'}>
          <Grid item xl={1} md={1} lg={1} sm={false} xs={false}></Grid>
          <Grid item xl={10} md={10} lg={10} sm={12} xs={12}>
            <BreadCrumbs
              linksArray={breadCrumbsArray}
              current={isCreateFlow ? 'Additional Information' : 'Edit Additional Information'}
            />
            <Paper className={classes.paper} variant='outlined' elevation={3}>
              <div className={classes.heading}>
                <Typography variant='h5' component='h5'>
                  {isCreateFlow ? 'Additional Information' : 'Edit Additional Information'}
                </Typography>
                <Typography className={classes.headingSubText} variant='caption' component='h6'>
                  {companyName || 'Company Name'}
                </Typography>
              </div>
              <form>
                <Field
                  type='text'
                  name='pointPersonSales'
                  label='Sales POC'
                  error={errors.pointPersonSales}
                  component={FormInput}
                  onBlur={(e) => {
                    const trimmedValue = getTrimmedValue(e.target.value, true);
                    setFieldValue('pointPersonSales', trimmedValue || '');
                  }}
                />
                <Field
                  type='text'
                  name='accountExecutive'
                  label='Customer Success POC'
                  error={errors.accountExecutive}
                  component={FormInput}
                  onBlur={(e) => {
                    const trimmedValue = getTrimmedValue(e.target.value, true);
                    setFieldValue('accountExecutive', trimmedValue || '');
                  }}
                />
                <Field
                  type='text'
                  name='notes'
                  label='Notes'
                  multiline
                  rows={3}
                  component={FormInput}
                  onBlur={(e) => {
                    const trimmedValue = getTrimmedValue(e.target.value, true);
                    setFieldValue('notes', trimmedValue || '');
                  }}
                />
                <Field
                  type='text'
                  name='addressLine1'
                  label='Address Line 1'
                  component={FormInput}
                  onBlur={(e) => {
                    const trimmedValue = getTrimmedValue(e.target.value, true);
                    setFieldValue('addressLine1', trimmedValue || '');
                  }}
                />
                <Field
                  type='text'
                  name='addressLine2'
                  label='Address Line 2'
                  component={FormInput}
                  onBlur={(e) => {
                    const trimmedValue = getTrimmedValue(e.target.value, true);
                    setFieldValue('addressLine2', trimmedValue || '');
                  }}
                />
                {countriesList.countries.includes(values?.country) || isEmpty(values?.country) ? (
                  <div className={classes.label}>Country</div>
                ) : null}
                <Field
                  type='text'
                  label='Country'
                  name='country'
                  value={getCityNameWithoutAsterisk(props.values.country)}
                  className={
                    countriesList.countries.includes(values?.country) || isEmpty(values?.country)
                      ? classes.dropDown
                      : null
                  }
                  component={
                    countriesList.countries.includes(values?.country) || isEmpty(values?.country)
                      ? CountryDropdown
                      : FormInput
                  }
                  onChange={(e) => {
                    props.setFieldValue(
                      'country',
                      countriesList.countries.includes(values.country) || isEmpty(values.country)
                        ? e
                        : e.target.value
                    );

                    setValue('state', '');
                    clearAddressFields();
                  }}
                  onBlur={(e) => {
                    props.handleBlur(e);

                    if (
                      !isEmpty(values.country) &&
                      !countriesList.countries.includes(values.country)
                    ) {
                      if (values.country.endsWith('*')) {
                        props.setFieldValue('country', values.country);
                      } else {
                        props.setFieldValue('country', values.country + `*`);
                      }
                    }
                  }}
                  removeAsterisk={true}
                />
                <Field
                  type='text'
                  style={
                    !isValidPinCode && props.values.pinCode
                      ? { borderBottom: 'solid 1px red' }
                      : null
                  }
                  name='pinCode'
                  label='Pincode'
                  component={FormInput}
                  onFocus={() => setIsValidPinCode(false)}
                  onBlur={(e) => {
                    const trimmedValue = getTrimmedValue(e.target.value, false) || '';
                    setFieldValue('pinCode', trimmedValue);
                    if (!trimmedValue) {
                      setFieldValue('city', '');
                      setSelectedCity(null);
                      setFieldValue('state', '');
                    }
                    if (!errors.pinCode && trimmedValue) {
                      onBlurPinCodeHandler();
                    }

                    setIsValidPinCode(true);
                  }}
                />
                {has(props.errors, 'pinCode') && (
                  <div className={classes.errorMessage}>{errors?.pinCode}</div>
                )}
                {countriesList.countries.includes(values?.country) &&
                !isEmpty(values?.country) &&
                isStateValid(getCityNameWithoutAsterisk(values.state), values.country) ? (
                  <div className={classes.label}>State</div>
                ) : null}
                <Field
                  label='State'
                  className={
                    countriesList.countries.includes(values?.country) &&
                    !isEmpty(values?.country) &&
                    isStateValid(getCityNameWithoutAsterisk(values.state), values.country)
                      ? classes.dropDown
                      : null
                  }
                  disabled={isEmpty(props.values.country)}
                  country={props.values.country ? props.values.country : ''}
                  defaultOptionLabel={props.values.state ? props.values.state : 'Select State'}
                  value={
                    countriesList.countries.includes(values?.country) &&
                    !isEmpty(values?.country) &&
                    isStateValid(getCityNameWithoutAsterisk(values.state), values.country)
                      ? props.values.state
                      : getCityNameWithoutAsterisk(props.values.state)
                  }
                  onChange={(val) => {
                    if (!isEmpty(values.state)) {
                      setSelectedCity(null);
                      clearAddressFields();
                    }

                    countriesList.countries.includes(values?.country) &&
                    !isEmpty(values?.country) &&
                    isStateValid(getCityNameWithoutAsterisk(values.state), values.country)
                      ? setValue('state', val)
                      : setValue('state', val.target.value);
                  }}
                  type='text'
                  name='state'
                  component={
                    countriesList.countries.includes(values?.country) &&
                    !isEmpty(values?.country) &&
                    isStateValid(getCityNameWithoutAsterisk(values.state), values.country)
                      ? RegionDropdown
                      : FormInput
                  }
                  onBlur={(e) => {
                    props.handleBlur(e);

                    if (!isStateValid(getCityNameWithoutAsterisk(values.state), values.country)) {
                      const val = getTrimmedValue(values.state, true);

                      if (val.endsWith('*')) {
                        props.setFieldValue('state', val);
                      } else {
                        props.setFieldValue('state', val + `*`);
                      }
                    }
                  }}
                  removeAsterisk={true}
                />
                {/** City Drop-down */}
                <div className={classes.label}>City</div>
                {!isEmpty(cities) && checkValueExists(values?.city) ? (
                  <Field
                    as={Select}
                    type='text'
                    name='city'
                    label=''
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        zIndex: 10 // Adjust the z-index value as needed
                      })
                    }}
                    options={cities}
                    value={selectedCity}
                    onChange={handleCityChange}
                    isClearable
                    noOptionsMessage={() => 'Please type city name'}
                    placeholder={'Select or type city name'}
                    isSearchable
                    onBlur={(e) => {
                      props.handleBlur(e);

                      if (!isEmpty(e.target.value) && !checkValueExists(e.target.value)) {
                        setFieldValue('city', getTrimmedValue(e.target.value, true) + '*');
                        setSelectedCity(null);
                      } else {
                        if (e.target.value)
                          setFieldValue('city', getTrimmedValue(e.target.value, true));
                      }
                    }}
                  />
                ) : (
                  <TextField
                    error={errors?.city}
                    fullWidth
                    className={classes.customField}
                    id='city'
                    label=''
                    defaultValue=''
                    name='city'
                    helperText={errors?.city}
                    value={props.values.city ? getCityNameWithoutAsterisk(props.values.city) : ''}
                    onChange={handleChange}
                    onBlur={(e) => {
                      props.handleBlur(e);
                      if (!isEmpty(e.target.value)) {
                        setFieldValue('city', getTrimmedValue(e.target.value, true) + `*`);
                      } else {
                        setSelectedCity(null);
                        setFieldValue('city', '');
                      }
                    }}
                  />
                )}
                <Field
                  type='text'
                  name='landmark'
                  label='Landmark'
                  component={FormInput}
                  onBlur={(e) => {
                    const trimmedValue = getTrimmedValue(e.target.value, true);
                    setFieldValue('landmark', trimmedValue || '');
                  }}
                />
                <Field
                  type='text'
                  name='ats'
                  label='ATS'
                  component={FormInput}
                  onBlur={(e) => {
                    const trimmedValue = getTrimmedValue(e.target.value, true);
                    setFieldValue('ats', trimmedValue || '');
                  }}
                />
                <Field
                  type='text'
                  name='hrms'
                  label='HRMS'
                  component={FormInput}
                  onBlur={(e) => {
                    const trimmedValue = getTrimmedValue(e.target.value, true);
                    setFieldValue('hrms', trimmedValue || '');
                  }}
                />
                <Field
                  type='text'
                  name='communicationTool'
                  label='Communication Tool'
                  component={FormInput}
                  onBlur={(e) => {
                    const trimmedValue = getTrimmedValue(e.target.value, true);
                    setFieldValue('communicationTool', trimmedValue || '');
                  }}
                />
                <Grid container>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography variant={'h6'}>Agreement Details</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12} align={'right'}>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => handleAddAgreement()}
                      startIcon={<AddIcon />}
                    >
                      Add New Agreement
                    </Button>
                  </Grid>
                </Grid>
                <Box mt={2} mb={2}>
                  {agreementDetailsArr && agreementDetailsArr?.length
                    ? agreementDetailsArr.map((agreement, index) => {
                        return (
                          <AgreementListing
                            currentIndex={index}
                            agreementData={agreement}
                            agreementChanges={handleAgreementChanges}
                            addDoc={handleAddDocument}
                            documentsChange={handleDocChanges}
                            key={`agreement_container_${index}`}
                          />
                        );
                      })
                    : null}
                </Box>
                {isCreateFlow ? (
                  <Grid className={classes.saveButtonGrid} item container>
                    <Button
                      variant='contained'
                      size='large'
                      name='saveAndNext'
                      className={isValidForm ? classes.activeBtn : classes.disabledBtn}
                      onClick={(e) => {
                        handleFormSubmit('saveAndNext');
                      }}
                      disabled={
                        isDirty
                          ? false
                          : !dirty || !isValidForm || (props.values.pinCode && !isValidPinCode)
                      }
                      startIcon={<SaveIcon />}
                    >
                      {'Save & Next'}
                    </Button>
                    <Button
                      variant='contained'
                      size='large'
                      name='skip'
                      className={classes.skipBtn}
                      onClick={(e) => {
                        handleFormSubmit('skip');
                      }}
                      startIcon={<SkipNextOutlinedIcon />}
                    >
                      {'Skip'}
                    </Button>
                  </Grid>
                ) : (
                  <Button
                    variant='contained'
                    size='large'
                    className={
                      isDirty
                        ? classes.activeBtn
                        : !dirty || !isValidForm || (props.values.pinCode && !isValidPinCode)
                        ? classes.disabledBtn
                        : classes.activeBtn
                    }
                    disabled={
                      isDirty
                        ? false
                        : !dirty || !isValidForm || (props.values.pinCode && !isValidPinCode)
                    }
                    onClick={(e) => {
                      handleFormSubmit('save');
                    }}
                    startIcon={<SaveIcon />}
                  >
                    Save
                  </Button>
                )}
              </form>
            </Paper>
          </Grid>
          <Grid item xl={1} md={1} lg={1} sm={false} xs={false}></Grid>
        </Grid>
      ) : null}
    </>
  );
};
