import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, has } from 'lodash';
import { toast } from 'react-toastify';

import validationSchema from './ConvertToPackage.validation';

import { convertToPackageBalance as convertToPackageBalanceAPI } from '../../../../../../api/admin';
import {
  getCompanyCreditBalance as getCompanyCreditBalanceAPI,
  getCompanyUsageLogs as getCompanyUsageLogsAPI
} from '../../../../../../actions/admin';

import { errToastMessage, getErrorMessage } from '../../../../../../utils/Utlities';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyCreditBalance: (companyId) => dispatch(getCompanyCreditBalanceAPI(companyId)),
    getCompanyUsageLogs: (companyId, limit, offset) =>
      dispatch(getCompanyUsageLogsAPI(companyId, limit, offset))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let propsValue = {};

      if (has(props, 'companyId')) {
        propsValue.companyId = props.companyId;
      } else {
        propsValue = {};
      }
      let storedValues = {
        companyId: '',
        packageId: '',
        packageUuid: '',
        packageUnits: '',
        credits: '',
        totalCredits: ''
      };

      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    validationSchema,
    enableReinitialize: true,
    handleSubmit: (values, { setFieldError, setSubmitting, resetForm, props }) => {
      values.credits = parseInt(values.credits);
      values.packageUnits = parseInt(values.packageUnits);
      values.totalCredits = parseInt(values.totalCredits);
      if (values.totalCredits > props.balance) {
        setFieldError('totalCredits', 'Insufficient credit balance');
      } else {
        convertToPackageBalanceAPI(values)
          .then(() => {
            setSubmitting(false);
            toast.info('Converted to Package Balance successfully');
            //update balance
            props.getCompanyCreditBalance({ companyId: props.companyId, onlyNegative: false });
            props.getCompanyUsageLogs(props.companyId, 10, 0);
            resetForm();
            props.handleClose();
          })
          .catch((error) => {
            console.log(error);
            errToastMessage(error);
          });
      }
    },
    displayName: 'Convert to Package Balance'
  })
);
