import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, isEmpty } from 'lodash';
import { toast } from 'react-toastify';

import {
  getCompanyById as getCompanyByIdAPI,
  invalidateCompanyDetail as invalidateCompanyDetailAPI
} from '../../../actions/admin';
import { companyLogoUpload, updateCompany as updateCompanyAPI } from '../../../api/admin';
import validationSchema from './UpdateCompany.validation';
import { toSnakeCase, getErrorMessage, errToastMessage } from '../../../utils/Utlities';

const mapStateToProps = (state) => {
  return {
    updateCompanyData: (!isEmpty(state.updateCompanyData) && state.updateCompanyData) || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyById: (companyId) => dispatch(getCompanyByIdAPI(companyId)),
    invalidateCompanyDetail: () => dispatch(invalidateCompanyDetailAPI())
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        companyId: '',
        bgvConfigId: '',
        name: '',
        status: '',
        friendlyName: '',
        companyUrl: '',
        candidateReportDocuments: 'external_links',
        logoUrl: '',
        logoUrlId: '',
        linkedInUrl: '',
        uploadedLogoFile: '',
        lightLogoFile: '',
        darkLogoFile: '',
        darkLogoUrl: '',
        gstin: '',
        zohoId: '',
        tds: 'NONE',
        tax: '',
        useLogoInInviteMail: false,
        companyUuid: '',
        isEmailOptional: 0,
        isResumeOptional: 0,
        isGenderOptional: 1,
        isUanMandatory: false,
        trialAccount: false,
        includeAadhaarInIdCheck: '0',
        discloseInfo: '',
        isOptedForAddressCheckSms: '0',
        reminderViaEmail: 'CANDIDATE',
        consentType: 'CONSENT_LETTER',
        consentUndertakingLetterUrl: '',
        consentUndertakingLetterDate: '',
        daysToCompleteForm: 3,
        hrNote: '',
        enabledHrNote: true,
        defaultHrNote: '',
        customOrDefault: 'default',
        defaultCustomHrNote: '',
        hrWarningNote: '',
        enabledHrWarningNote: true,
        defaultHrWarningNote: '',
        customOrDefaultWarningNote: 'default',
        defaultCustomHrWarningNote: '',
        isUploading: false,
        enableDateInCandidateReport: false,
        uploadedLogoError: false,
        referralEnabledFor: 'ALL',
        includeBrandNameInReport: false,
        selectedLogoType: '',
        nameConfigInReport: 'LEGAL_NAME_ONLY'
      };
      let propsValue = {};
      if (!isEmpty(props?.updateCompanyData)) {
        const companyData = { ...props?.updateCompanyData };
        const company_logos = companyData.companyLogos?.reduce((logoObject, logo) => {
          return { ...logoObject, [logo.logoType]: logo };
        }, {});
        propsValue = {
          companyId: companyData?.id || '',
          bgvConfigId: companyData?.bgvformConfig?.id || '',
          name: companyData?.name || '',
          status: companyData?.status || '',
          friendlyName: companyData?.friendlyName || '',
          companyUrl: companyData?.companyUrl || '',
          linkedInUrl: companyData?.linkedInUrl || null,
          candidateReportDocuments: companyData?.candidateReportDocuments || 'external_links',
          lightLogoUrl: company_logos?.light?.logoUrl || '',
          darkLogoUrl: company_logos?.dark?.logoUrl || '',
          lightLogoUrlId: company_logos?.light?.id || '',
          trialAccount: !!companyData?.isTrialAccount,
          selectedLogoType: Object.values(company_logos)?.find(
            (logo) => logo.id === companyData?.logoUrlId && logo?.logoUrl
          )?.logoType,
          darkLogoUrlId: company_logos?.dark?.id || '',
          tds: companyData?.tds,
          zohoId: companyData?.zohoBooksContactId,
          gstin: companyData?.gstin,
          tax: companyData?.tax,
          enableLogo: companyData?.useLogoInInviteMail,
          companyUuid: companyData?.uuid,
          isEmailOptional: companyData?.bgvformConfig?.isEmailOptional,
          isResumeOptional: companyData?.bgvformConfig?.isResumeOptional,
          isGenderOptional: companyData?.bgvformConfig?.isGenderOptional,
          isUanMandatory: companyData?.bgvformConfig?.isUanMandatory ? true : false,
          includeAadhaarInIdCheck:
            companyData?.bgvformConfig?.includeAadhaarInIdCheck === true
              ? '1'
              : companyData?.bgvformConfig?.includeAadhaarInIdCheck === false
              ? '0'
              : `${companyData?.bgvformConfig?.includeAadhaarInIdCheck}`,
          discloseInfo: companyData?.bgvformConfig?.discloseInfo,
          isOptedForAddressCheckSms: companyData?.bgvformConfig?.isOptedForAddressCheckSms
            ? companyData?.bgvformConfig?.isOptedForAddressCheckSms.toString()
            : '0',
          reminderViaEmail: companyData?.insuffEmailReminderResp?.reminderViaEmail || 'NO_EMAIL',
          consentType: companyData?.bgvformConfig?.consentType || 'CONSENT_LETTER',
          consentUndertakingLetterUrl:
            companyData?.bgvformConfig?.consentUndertakingLetterUrl || '',
          consentUndertakingLetterDate: companyData?.bgvformConfig?.consentUndertakingLetterDate,
          hrNote: companyData?.bgvformConfig?.hrInviteNote
            ? companyData?.bgvformConfig?.hrInviteNote
            : companyData?.defaultCandidateInviteSettings?.hrNote,
          hrWarningNote: companyData?.bgvformConfig?.hrWarningNote
            ? companyData?.bgvformConfig?.hrWarningNote
            : companyData?.defaultCandidateInviteSettings?.hrWarningNote,
          enabledHrNote: companyData?.bgvformConfig?.hrInviteNoteStatus,
          enabledHrWarningNote: companyData?.bgvformConfig?.hrWarningNoteStatus,
          daysToCompleteForm: companyData?.bgvformConfig?.formCompletionDays,
          customOrDefault: companyData?.bgvformConfig?.hrInviteNote ? 'custom' : 'default',
          customOrDefaultWarningNote: companyData?.bgvformConfig?.hrWarningNote
            ? 'custom'
            : 'default',
          defaultHrNote: companyData?.defaultCandidateInviteSettings?.hrNote,
          defaultHrWarningNote: companyData?.defaultCandidateInviteSettings?.hrWarningNote,
          defaultCustomHrNote:
            companyData?.bgvformConfig?.hrInviteNote ||
            companyData?.defaultCandidateInviteSettings?.hrNote,
          defaultCustomHrWarningNote:
            companyData?.bgvformConfig?.hrWarningNote ||
            companyData?.defaultCandidateInviteSettings?.hrWarningNote,
          enableDateInCandidateReport: companyData?.showBgvPeriodInReport,
          nameConfigInReport: companyData?.nameConfigInReport,
          referralEnabledFor: companyData?.bgvformConfig?.referralEnabledFor,
          includeBrandNameInReport: companyData?.showBrandNameInReport
        };
      }
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: async (values, { setFieldError, setStatus, setSubmitting, resetForm, props }) => {
      const uploadLogo = async (file) => {
        if (file) {
          let formData = new FormData();
          formData.append('logo', file);
          formData.append('fileType', 'COMPANY_LOGO');
          const response = await companyLogoUpload(formData);
          return response.data.data;
        }
      };

      const lightUrl = await uploadLogo(values.lightLogoFile, 'COMPANY_LOGO');
      const darkUrl = await uploadLogo(values.darkLogoFile, 'COMPANY_LOGO');
      const finalSaveObj = {
        companyId: values.companyId,
        bgvConfigId: values.bgvConfigId,
        name: values.name,
        status: values.status,
        friendlyName: values.friendlyName,
        companyUrl: values.companyUrl.trim().toLowerCase(),
        linkedInUrl: values.linkedInUrl || null,
        candidateReportDocuments: values.candidateReportDocuments,
        company_logos: {
          dark: {
            id: values.darkLogoUrlId,
            logo_url: darkUrl ? darkUrl : values.darkLogoUrl,
            logo_type: 'dark'
          },
          light: {
            id: values.lightLogoUrlId,
            logo_url: lightUrl ? lightUrl : values.lightLogoUrl,
            logo_type: 'light'
          }
        },
        logoUrlId:
          (values?.selectedLogoType === 'light' ? values.lightLogoUrlId : values.darkLogoUrlId) ||
          null,
        zohoBooksContactId: values?.zohoId,
        gstin: values?.gstin,
        tds: values?.tds,
        tax: values?.tax,
        enableLogo: values.enableLogo,
        includeAadhaarInIdCheck: values.includeAadhaarInIdCheck
          ? parseInt(values.includeAadhaarInIdCheck)
          : 0,
        isEmailOptional: values.isEmailOptional,
        isResumeOptional: values.isResumeOptional,
        isGenderOptional: values.isGenderOptional,
        isUanMandatory: values?.isUanMandatory,
        discloseInfo: values.discloseInfo,
        companyUuid: values.companyUuid,
        isOptedForAddressCheckSms: parseInt(values.isOptedForAddressCheckSms),
        reminderViaEmail: values.reminderViaEmail,
        consentType: values.consentType || 'CONSENT_LETTER',
        consentUndertakingLetterUrl: values?.consentUndertakingLetterUrl
          ? values?.consentUndertakingLetterUrl
          : null,
        hrNote: values.hrNote.trim(),
        hrWarningNote: values.hrWarningNote.trim(),
        enabledHrNote: values.enabledHrNote,
        enabledHrWarningNote: values.enabledHrWarningNote,
        daysToCompleteForm: values.daysToCompleteForm,
        show_bgv_period_in_report: values.enableDateInCandidateReport,
        name_config_in_report: values?.nameConfigInReport,
        referralEnabledFor: values?.referralEnabledFor,
        show_brand_name_in_report: values.includeBrandNameInReport,
        isTrialAccount: values?.trialAccount
      };
      setSubmitting(true);
      updateCompanyAPI(toSnakeCase(finalSaveObj))
        .then((response) => {
          invalidateCompanyDetailAPI();
          toast.success('Details Updated Successfully');
          props?.history.push({
            pathname: '/company/view',
            search: `id=${props.updateCompanyData.id}`
          });
        })
        .catch((error) => {
          if (error && error?.response && error?.response?.status == 404) {
            setSubmitting(false);
            errToastMessage('Error fetching logo for the provided domain');
          } else {
            console.error(error);
            setSubmitting(false);
            errToastMessage(error);
          }
        });
    },
    displayName: 'Update Company'
  })
);
