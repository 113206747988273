import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  getCompanyRechargeLogs as getCompanyRechargeLogsAPI,
  getCompanyPendingOrders as getCompanyPendingOrdersAPI,
  invalidateCompanyPendingOrder as invalidateCompanyPendingOrderAPI
} from '../../../../../actions/admin';

const mapStateToProps = (state) => {
  return {
    companyCreditBalance: state.companyCreditBalance || null,
    companyPendingOrders: state.companyPendingOrders || null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyRechargeLogs: (query) => dispatch(getCompanyRechargeLogsAPI(query)),
    getCompanyPendingOrders: (companyId) => dispatch(getCompanyPendingOrdersAPI(companyId)),
    invalidateCompanyPendingOrder: () => dispatch(invalidateCompanyPendingOrderAPI())
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
