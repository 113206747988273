import * as Yup from 'yup';

export default Yup.object().shape({
    existingPassword: Yup.string().required('Existing Password cannot be blank'),
    password: Yup.string()
        .required('Password cannot be blank')
        .min(6, 'Your password must be longer than 6 characters.')
        .test('match', `New password can't be same as old password`, function (password) {
            return password !== this.parent.existingPassword;
        }),
    confirmPassword: Yup.string().when('password', {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password'), null], 'Password & confirm password should be same'),
    }),
});
