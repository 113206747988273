import React from 'react';
import { Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dropdown from '../../../core-components/DropdownInput';

const dropdownValues = [
  // { label: 'None', value: 'NONE' },
  { label: 'Both CA and Candidate', value: 'ALL' },
  { label: 'CA only', value: 'CA' },
  // { label: 'Candidate', value: 'CANDIDATE' }
];

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0, 2, 0),
    color: theme.palette.text.secondary
  },
  typo: {
    marginBottom: '10px',
    fontSize: '1rem',
    color: 'initial'
  }
}));
export default function ReferAndEarnConfig(props) {
  const { values, errors, setFieldValue } = props;

  const classes = useStyles();

  return (
    <>
      <Typography variant={'h6'}>Refer and Earn Configuration</Typography>
      <Paper className={classes.paper} variant='outlined' elevation={5}>
        <Typography className={classes.typo}>Enabled for</Typography>
        <Dropdown
          dropdownValues={dropdownValues}
          label={'Select'}
          value={values.referralEnabledFor}
          handleSelect={(value) => {
            setFieldValue('referralEnabledFor', value);
          }}
        />
      </Paper>
    </>
  );
}