import { connect } from 'react-redux';
import { compose } from 'redux';

import { getCompanyAdmin as getCompanyAdminAPI } from '../../../actions/admin';

const mapStateToProps = (state) => {
    return {
        adminData: state.companyAdmin,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCompanyAdmin: (companyId, limit, offset) => dispatch(getCompanyAdminAPI(companyId, limit, offset)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
