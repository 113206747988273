import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { has } from 'lodash';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  Button,
  Box,
  Grid,
  FormControlLabel,
  Typography,
  IconButton,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  MenuItem
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import {
  EMAIL_FREQUENCY_STATUS,
  EMAIL_INFORMATION_STATUS
} from '../../../../core-components/CommonConstants';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, title, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  headingSubText: {
    color: theme.palette.text.secondary
  },
  activeBtn: {
    margin: theme.spacing(1),
    width: '240px',
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  disabledBtn: {
    margin: theme.spacing(1),
    width: '240px',
    color: '#dfdfdf',
    borderColor: '#d9d9d9',
    backgroundColor: '#f5f5f5',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  container: { minHeight: 180 },
  ButtonEnd: {
    marginTop: 40
  },
  formControl: {
    width: '100%'
  },
  inputHeight: {
    height: 36
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '30vh',
    alignItems: 'center'
  }
}));

export default (props) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const { dirty } = props;

  useEffect(() => {
    setLoading(true);
    props.invalidateCompanyEmailSettings();
    let request = { adminId: values.adminId, companyId: values.companyId };
    props.getSettings(request);
  }, []);

  useEffect(() => {
    if (props.settings && has(props.settings, 'loading') && !props.settings.loading) {
      setLoading(false);
    }
  }, [props.settings]);

  const { handleSubmit, isSubmitting, values, errors, setFieldValue } = props;

  const isValidForm =
    !isSubmitting &&
    isEmpty(errors) &&
    ((values.digest && values.digestValues.frequency && values.digestValues.information) ||
      !values.digest);

  return (
    <div>
      <Dialog
        onClose={props.handleClose}
        aria-labelledby='customized-dialog-title'
        open={props.open}
        maxWidth={'sm'}
        fullWidth
      >
        <DialogTitle id='customized-dialog-title' onClose={props.handleClose}>
          <Typography variant='h6'>{'Email Notification Settings'}</Typography>
          {props.details && props.details.user && props.details.user.name ? (
            <Typography className={classes.headingSubText} variant='h6' component='h6'>
              {props.details.user.name}
            </Typography>
          ) : null}
        </DialogTitle>
        <DialogContent>
          {!loading ? (
            <Box mb={2} className={classes.container}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12} xl={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.bounceEmail}
                          onChange={(e) => {
                            setFieldValue('bounceEmail', e.target.checked);
                          }}
                          name='bounceEmail'
                          color='primary'
                        />
                      }
                      label='Bounce mails / Form Fill reminders'
                    />
                  </Grid>
                  <Grid item container>
                    <Grid item md={4} xs={4} xl={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.digest}
                            onChange={(e) => {
                              setFieldValue('digest', e.target.checked);
                              setFieldValue('digestValues.frequency', '');
                              setFieldValue('digestValues.information', '');
                            }}
                            name='digest'
                            color='primary'
                          />
                        }
                        label='Digest emails'
                      />
                    </Grid>
                    <Grid item container spacing={1} md={8} xs={8} xl={8}>
                      <Grid item md={6} xs={6} xl={6}>
                        <FormControl
                          variant='outlined'
                          className={classes.formControl}
                          size='small'
                        >
                          <InputLabel id='frequencySelectLabel'>Frequency</InputLabel>
                          <Select
                            labelId='frequencySelectLabel'
                            id='informationSelect'
                            value={values.digestValues.frequency}
                            onChange={(e) => {
                              setFieldValue('digestValues.frequency', e.target.value);
                            }}
                            label='Frequency'
                            fullWidth={true}
                          >
                            <MenuItem value=''>
                              <em>None</em>
                            </MenuItem>
                            {EMAIL_FREQUENCY_STATUS.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={6} xl={6}>
                        <FormControl
                          variant='outlined'
                          className={classes.formControl}
                          size='small'
                        >
                          <InputLabel id='informationSelectLabel'>Information</InputLabel>
                          <Select
                            labelId='informationSelectLabel'
                            id='informationSelect'
                            value={values.digestValues.information}
                            onChange={(e) => {
                              setFieldValue('digestValues.information', e.target.value);
                              // setFieldValue('status', e.target.value);
                            }}
                            label='Information'
                            fullWidth={true}
                          >
                            <MenuItem value=''>
                              <em>None</em>
                            </MenuItem>
                            {EMAIL_INFORMATION_STATUS.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Box align={'right'} className={classes.ButtonEnd}>
                  <Button
                    variant='contained'
                    type='submit'
                    className={isValidForm ? classes.activeBtn : classes.disabledBtn}
                    disabled={!isValidForm || !dirty}
                    startIcon={<SaveIcon />}
                  >
                    Save
                  </Button>
                </Box>
              </form>
            </Box>
          ) : (
            <Grid container spacing={2} className={classes.spinnerContainer}>
              <CircularProgress />
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
