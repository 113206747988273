import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel } from '@material-ui/core';
import { PLAIN_CHECK_ICONS, DRUG_PACKAGE_PANEL_TYPE } from '../../CommonConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  formControl: {
    margin: theme.spacing(3)
  },
  required: {
    color: '#db3131'
  }
}));

export default (props) => {
  const { isUnassigned } = props;
  const classes = useStyles();

  const handleChange = (value) => {
    props.drugChange(value);
  };

  return (
    <>
      <div className={classes.root}>
        <Grid className={classes.root} item container>
          <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
            <Typography variant='h6' style={{ fontSize: '18px' }} gutterBottom>
              <span style={{ marginRight: '10px' }} title={'Drug Test'}>
                <FontAwesomeIcon
                  icon={PLAIN_CHECK_ICONS['drug'] ? PLAIN_CHECK_ICONS['drug'].icon : faBan}
                  color='#222'
                />
              </span>
              {'Drug Test'}
            </Typography>
          </Grid>
          <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
            <FormControl component='fieldset' disabled={isUnassigned}>
              <FormLabel component="legend">Choose panel type:  <span className={classes.required}>*</span></FormLabel>
              <RadioGroup
                row
                aria-label='type'
                name='type'
                value={props.value}
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
                disabled={isUnassigned}
              >
                {
                  DRUG_PACKAGE_PANEL_TYPE.map(drg => {
                    return (
                      <FormControlLabel
                        value={drg.value}
                        control={<Radio color='primary' />}
                        label={drg.label}
                        labelPlacement='end'
                      />
                    )
                  })
                }
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
