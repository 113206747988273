import React from 'react';
import { has } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles((theme) => ({
  insuffBtn: {
    margin: theme.spacing(1),
    width: '180px',
    color: '#FFFFFF',
    borderColor: '#E8833A',
    backgroundColor: '#E8833A',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388E3C'
    }
  },
  activeBtn: {
    margin: theme.spacing(1),
    width: '180px',
    color: '#FFFFFF',
    borderColor: '#388E3C',
    backgroundColor: '#43A047',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388E3C'
    }
  },
  disabledBtn: {
    margin: theme.spacing(1),
    width: '180px',
    color: '#DFDFDF',
    borderColor: '#D9D9D9',
    backgroundColor: '#F5F5F5',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#F5F5F5'
    }
  },
  cancelBtn: {
    margin: theme.spacing(1),
    width: '160px',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit'
  }
}));

export const SubmitContinueButton = (props) => {
  const classes = useStyles();
  const isDirty = has(props, 'dirty') ? props?.dirty : true;
  const { loading } = props;
  const hasChanged = has(props, 'hasChanged') ? props?.hasChanged : false;

  return (
    <Button
      variant='contained'
      size='large'
      type={props.type || 'submit'}
      className={
        props.isInsuffInAddon
          ? classes.insuffBtn
          : props.isValidForm
            ? classes.activeBtn
            : classes.disabledBtn
      }
      disabled={!props.isValidForm || (!isDirty && !hasChanged) || loading}
      startIcon={<SaveIcon />}
      onClick={props.handleClick || null}
    >
      {has(props, 'label') ? props.label : 'Save'}
    </Button>
  );
}
export const SubmitButton = (props) => {
  const classes = useStyles();
  const isDirty = has(props, 'dirty') ? props?.dirty : true;

  return (
    <Button
      variant='contained'
      size='large'
      type={props.type || 'submit'}
      className={
        props.isInsuffInAddon
          ? classes.insuffBtn
          : props.isValidForm
            ? classes.activeBtn
            : classes.disabledBtn
      }
      disabled={!props.isValidForm || !isDirty}
      startIcon={<SaveIcon />}
      onClick={props.handleClick || null}
    >
      {has(props, 'label') ? props.label : 'Save'}
    </Button>
  );
};

export const CancelButton = (props) => {
  const classes = useStyles();
  return (
    <Button
      variant='outlined'
      size='large'
      color='primary'
      className={classes.cancelBtn}
      startIcon={<CancelIcon />}
      onClick={props.handleClose}
    >
      {'Cancel'}
    </Button>
  );
};