import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  getInsuffReview as getInsuffReviewAPI,
  invalidateInsuffReview
} from '../../../actions/admin';

const mapStateToProps = (state) => {
  return {
    insuffData: state.insuffData ? state.insuffData : null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInsuffReview: (data) => dispatch(getInsuffReviewAPI(data)),
    invalidateInsuffReview: (data) => dispatch(invalidateInsuffReview(data))
  };
};
export default compose(connect(mapStateToProps, mapDispatchToProps));
