import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith } from 'lodash';
import { toast } from 'react-toastify';

import { setPackageCount as createPackage } from '../../../../api/admin';
import {
  errToastMessage,
  getErrorMessage,
  convertFromIndianNumeration
} from '../../../../utils/Utlities';

import validationSchema from './CreatePackage.validation';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const formatForApi = (data) => {
  let dataCopy = { ...data };

  return {
    name: dataCopy.name,
    subtypeName: dataCopy.subtypeName,
    launchOnCreate: dataCopy.launchOnCreate,
    type: dataCopy.type,
    basePrice: dataCopy.basePrice
      ? convertFromIndianNumeration(dataCopy.basePrice)
      : dataCopy.basePrice,
    internationalChargesIncluded: dataCopy.internationalChargesIncluded,
    config: {
      identity: parseInt(dataCopy.identity),
      address: parseInt(dataCopy.address),
      court: parseInt(dataCopy.court),
      employment: parseInt(dataCopy.employment),
      education: parseInt(dataCopy.educationCheck),
      reference: parseInt(dataCopy.reference),
      world: dataCopy.world,
      credit: dataCopy.credit,
      drug: dataCopy.drug,
      custom: dataCopy.customCheckNames.length ? true : false,
      customCheckNames: [...dataCopy.customCheckNames]
    }
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        name: '',
        subtypeName: '',
        launchOnCreate: false,
        type: 'PREPAID',
        basePrice: '',
        identity: 0,
        address: 0,
        court: 0,
        employment: 0,
        educationCheck: 0,
        reference: 0,
        world: false,
        credit: false,
        drug: false,
        internationalChargesIncluded: false,
        customCheckNames: [],
        packageIsAvailable: true
      };
      let propsValue = {};
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (values, { setFieldError, setStatus, setSubmitting, resetForm, props }) => {
      createPackage(formatForApi(values))
        .then((response) => {
          toast.success('Package Created Successfully');
          setSubmitting(false);
          props.history.push('/package/list');
        })
        .catch((error) => {
          setSubmitting(false);
          console.error(error);
          errToastMessage(error);
        });
    },
    displayName: 'Create Package'
  })
);
