//------------------------------ ACTIONS FILE FOR COMMON FRONTEND REPO ONLY----------------------------//
import { toast } from 'react-toastify';
import { toCamelCase, getErrorMessage, errToastMessage } from '../utils/Utlities';
import {
  uploadCandidateFile,
  getSecuritySettings as getSecuritySettingsAPI,
  postSecuritySettings as postSecuritySettingsAPI,
  getWalletDetail as getWalletDetailAPI,
  getViewBuyPackages as getViewBuyPackagesAPI,
  getRequestPaymentUsers as getRequestPaymentUsersAPI,
  getAdministrators as getAdministratorsAPI,
  editBrandSettings as editBrandSettingsAPI,
  getBrandSettings as getBrandSettingsAPI,
  uploadExEmpCSV as uploadExEmpCSVAPI,
  getAlumniRequestTrends as getAlumniRequestTrendsAPI,
  getAlumniRecordTrends as getAlumniRecordTrendsAPI,
  getAlumniRequestOverview as getAlumniRequestOverviewAPI,
  getAlumniRecordOverview as getAlumniRecordOverviewAPI,
  getAlumniTableData as getAlumniTableDataAPI,
  getAlumniSettings as getAlumniSettingsAPI,
  editAlumniSettings as editAlumniSettingsAPI,
  getAlumniVerificationDetails as getAlumniVerificationDetailsAPI
} from '../api/company';
import { toCamelCaseTemp } from '../components/SuperAdmin/frontend-common/core-components/Utilities/utilities';
export const SET_BULK_UPLOAD_DATA = 'SET_BULK_UPLOAD_DATA';
export const INVALIDATE_BULK_UPLOAD_DATA = 'INVALIDATE_BULK_UPLOAD_DATA';
export const SET_DROPDOWN_VALUES_BULK_UPLOAD_DATA = 'SET_DROPDOWN_VALUES_BULK_UPLOAD_DATA';

//security settings
export const REQUEST_SECURITY_SETTINGS_CONFIGURATION = 'REQUEST_SECURITY_SETTINGS_CONFIGURATION';
export const SET_SECURITY_SETTINGS_CONFIGURATION = 'SET_SECURITY_SETTINGS_CONFIGURATION';
export const INVALIDATE_SECURITY_SETTINGS_CONFIGURATION =
  'INVALIDATE_SECURITY_SETTINGS_CONFIGURATION';

//Wallet Detail
export const REQUEST_WALLET_DETAIL = 'REQUEST_WALLET_DETAIL';
export const SET_WALLET_DETAIL = 'SET_WALLET_DETAIL';
export const INVALIDATE_WALLET_DETAIL = 'INVALIDATE_WALLET_DETAIL';

// Tier select
export const SET_SELECTED_TIERS = 'SET_SELECTED_TIERS';
export const INVALIDATE_SELECTED_TIERS = 'INVALIDATE_SELECTED_TIERS';
export const GET_SELECTED_TIERS = 'GET_SELECTED_TIERS';

export const INVALIDATE_VIEW_BUY_PACKAGES = 'INVALIDATE_VIEW_BUY_PACKAGES';
export const REQUEST_VIEW_BUY_PACKAGES = 'REQUEST_VIEW_BUY_PACKAGES';
export const SET_VIEW_BUY_PACKAGES = 'SET_VIEW_BUY_PACKAGES';

export const REQUEST_PAYMENT_DETAILS = 'REQUEST_PAYMENT_DETAILS';
export const SET_PAYMENT_DETAILS = 'SET_PAYMENT_DETAILS';

// payment request users
export const REQUEST_PAYMENT_USERS = 'REQUEST_PAYMENT_USERS';
export const SET_PAYMENT_USERS = 'SET_PAYMENT_USERS';
export const INVALIDATE_PAYMENT_USERS = 'INVALIDATE_PAYMENT_USERS';

// administrators
export const INVALIDATE_ADMINISTRATORS = 'INVALIDATE_ADMINISTRATORS';
export const REQUEST_ADMINISTRATORS = 'REQUEST_ADMINISTRATORS';
export const SET_ADMINISTRATORS = 'SET_ADMINISTRATORS';
export const UPDATE_ADMINISTRATORS = 'UPDATE_ADMINISTRATORS';
// Zoho connection
export const INVALIDATE_ZOHO_CONNECTION = 'INVALIDATE_ZOHO_CONNECTION';
export const SET_ZOHO_CONNECTION = 'SET_ZOHO_CONNECTION';
export const REQUEST_ZOHO_CONNECTION = 'REQUEST_ZOHO_CONNECTION';

//getBrandSettings
export const REQUEST_BRAND_SETTINGS = 'REQUEST_BRAND_SETTINGS';
export const SET_BRAND_SETTINGS = 'SET_BRAND_SETTINGS';

// Bulk Alumni

export const SET_ALUMNI_UPLOAD_DATA = 'SET_ALUMNI_UPLOAD_DATA';
export const INVALIDATE_ALUMNI_UPLOAD_DATA = 'INVALIDATE_ALUMNI_UPLOAD_DATA';

// Alumni Request trend
export const REQUEST_ALUMNI_REQUEST_TREND = 'REQUEST_ALUMNI_REQUEST_TREND';
export const SET_ALUMNI_REQUEST_TREND = 'SET_ALUMNI_REQUEST_TREND';

// Alumni Records trend
export const REQUEST_ALUMNI_RECORDS_TREND = 'REQUEST_ALUMNI_RECORDS_TREND';
export const SET_ALUMNI_RECORDS_TREND = 'SET_ALUMNI_RECORDS_TREND';

export const SET_CREDIT_TRANSACTION_DETAILS = 'SET_CREDIT_TRANSACTION_DETAILS';
export const INVALIDATE_CREDIT_TRANSACTION_DETAILS = 'INVALIDATE_CREDIT_TRANSACTION_DETAILS';

export const setSecuritySettingsConfiguration = (data) => {
  return {
    type: SET_SECURITY_SETTINGS_CONFIGURATION,
    data
  };
};

export const uploadCandidateCSV = (data) => (dispatch) => {
  return uploadCandidateFile(data);
};

export const invalidateSecuritySettingsConfiguration = (data) => {
  return {
    type: INVALIDATE_SECURITY_SETTINGS_CONFIGURATION,
    data
  };
};

export const requestSecuritySettingsConfiguration = (data) => {
  return {
    type: REQUEST_SECURITY_SETTINGS_CONFIGURATION,
    data
  };
};

export const requestWalletDetail = () => {
  return {
    type: REQUEST_WALLET_DETAIL
  };
};

export const setWalletDetail = (data) => {
  return {
    type: SET_WALLET_DETAIL,
    data
  };
};
export const invalidateWalletDetail = () => {
  return {
    type: INVALIDATE_WALLET_DETAIL
  };
};
export const invalidateSelectedTiers = () => {
  return {
    type: INVALIDATE_SELECTED_TIERS
  };
};
export const setSelectedTiers = (data) => {
  return {
    type: SET_SELECTED_TIERS,
    data
  };
};

export const invalidateViewBuyPackages = () => {
  return {
    type: INVALIDATE_VIEW_BUY_PACKAGES
  };
};

export const requestViewBuyPackages = () => {
  return {
    type: REQUEST_VIEW_BUY_PACKAGES
  };
};

export const setViewBuyPackages = (data) => {
  return {
    type: SET_VIEW_BUY_PACKAGES,
    data
  };
};

export const requestPaymentDetails = () => {
  return {
    type: REQUEST_PAYMENT_DETAILS
  };
};

export const setPaymentDetails = (data) => {
  return {
    type: SET_PAYMENT_DETAILS,
    data
  };
};

export const setPaymentCreditTransaction = (data) => {
  return {
    type: SET_CREDIT_TRANSACTION_DETAILS,
    data
  };
};
export const invalidatePaymentCreditTransaction = () => {
  return {
    type: INVALIDATE_CREDIT_TRANSACTION_DETAILS
  };
};

export const requestRequestPaymentUsers = () => {
  return {
    type: REQUEST_PAYMENT_USERS
  };
};

export const invalidateRequestPaymentUsers = () => {
  return {
    type: INVALIDATE_PAYMENT_USERS
  };
};

export const setRequestPaymentUsers = (data) => {
  return {
    type: SET_PAYMENT_USERS,
    data
  };
};

export const setAdministrators = (data) => {
  return {
    type: SET_ADMINISTRATORS,
    data
  };
};

export const updateAdministrators = (data) => {
  return {
    type: UPDATE_ADMINISTRATORS,
    data
  };
};

// zoho connection action creators
export const requestZohoConnection = () => {
  return {
    type: REQUEST_ZOHO_CONNECTION
  };
};

export const invalidateZohoZonnection = () => {
  return {
    type: INVALIDATE_ZOHO_CONNECTION
  };
};

export const setZohoConnection = (data) => {
  return {
    type: SET_ZOHO_CONNECTION,
    data
  };
};

export const requestBrandSettings = (data) => {
  return {
    type: REQUEST_BRAND_SETTINGS,
    data
  };
};

export const setBrandSettings = (data) => {
  return {
    type: SET_BRAND_SETTINGS,
    data
  };
};

export const setUploadExEmpData = (data) => {
  return {
    type: SET_ALUMNI_UPLOAD_DATA,
    data
  };
};

export const getSecuritySettings = (companyId) => (dispatch) => {
  dispatch(requestSecuritySettingsConfiguration());
  return getSecuritySettingsAPI(companyId)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setSecuritySettingsConfiguration(resp.data));
      return resp;
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};
export const postSecuritySettings = (data) => (dispatch) => {
  return postSecuritySettingsAPI(data)
    .then((response) => {
      const resp = toCamelCase(response.data);
      console.log(resp, resp.data);
      return resp.message;
    })
    .catch((error) => {
      throw error.response.data;
    });
};
/**
 *  funtion to set bulkupload data
 */
export const setBulkUploadData = (values) => (dispatch) => {
  const { type, data } = values;
  if (type === 'empty') {
    return dispatch({
      type: INVALIDATE_BULK_UPLOAD_DATA,
      data
    });
  } else if (type === 'rows') {
    return dispatch({
      type: SET_BULK_UPLOAD_DATA,
      data
    });
  } else if (type === 'dropDownValues') {
    return dispatch({
      type: SET_DROPDOWN_VALUES_BULK_UPLOAD_DATA,
      data
    });
  }
};

export const getWalletDetail = (companyId) => (dispatch) => {
  dispatch(requestWalletDetail());
  dispatch(requestPaymentDetails());
  return getWalletDetailAPI(companyId)
    .then((response) => {
      const walletDetails = toCamelCase(response.data);
      dispatch(
        setPaymentDetails({ tax: walletDetails?.company?.tax, tds: walletDetails?.company?.tds })
      );
      dispatch(
        setWalletDetail({
          ...walletDetails,
          customRecommendationOptions: response.data.custom_recommendation_options
        })
      );
    })
    .catch((err) => {
      errToastMessage(err);
      dispatch(invalidateWalletDetail());
    });
};

export const setActiveTiers = (data) => (dispatch) => {
  dispatch(setSelectedTiers(data));
};

export const getViewBuyPackages = (data) => (dispatch) => {
  dispatch(requestViewBuyPackages());

  return getViewBuyPackagesAPI(data)
    .then((response) => {
      const data = toCamelCaseTemp(response.data);
      dispatch(setViewBuyPackages(data.data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getCompanyPaymentDetails = () => (dispatch) => {
  dispatch(requestPaymentDetails());

  return getPaymentDetailsAPI()
    .then((response) => {
      const resp = toCamelCaseTemp(response.data.data);
      dispatch(setPaymentDetails(resp));
    })
    .catch((error) => {
      // errToastMessage(error);
    });
};

export const getRequestPaymentUsers = (companyId) => (dispatch) => {
  dispatch(requestRequestPaymentUsers());
  dispatch(requestZohoConnection());
  return getRequestPaymentUsersAPI(companyId)
    .then((res) => {
      const reqPaymentUsers = toCamelCase(res.data.data);
      dispatch(setRequestPaymentUsers(reqPaymentUsers.userDetails));
      dispatch(setZohoConnection(reqPaymentUsers.company));
    })
    .catch((err) => {
      errToastMessage(err);
      dispatch(invalidateRequestPaymentUsers());
    });
};

export const getAdministrators = (limit, offset, companyId) => (dispatch) => {
  dispatch({ type: REQUEST_ADMINISTRATORS });
  return getAdministratorsAPI(limit, offset, companyId)
    .then((response) => {
      const administrators = toCamelCase(response.data.data);
      //Mapping API response according to the CA side getAdmin API
      const admins = administrators?.admins.map((admin) => {
        return {
          id: admin?.user?.id,
          accessLevel: admin?.accessLevel,
          name: admin?.user?.name,
          email: admin?.user?.email
        };
      });
      dispatch(setAdministrators({ admins: admins }));
      dispatch(updateAdministrators({ admins: admins }));
    })
    .catch((error) => console.log(error));
};

export const invalidateAdminstrators = () => {
  return {
    type: INVALIDATE_ADMINISTRATORS
  };
};

/**
 * @param {Object} data
 * @returns Brand Settings of the company
 */
export const getBrandSettings = (data) => (dispatch) => {
  dispatch(requestBrandSettings());

  return getBrandSettingsAPI(data)
    .then((response) => {
      const resp = toCamelCase(response);
      dispatch(setBrandSettings(resp?.data));
      return resp?.data;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setBrandSettings({}));
      errToastMessage(error);
      throw error;
    });
};

export const editBrandSettings =
  ({ companyId, data }) =>
  (dispatch) => {
    return editBrandSettingsAPI({ data, companyId })
      .then((response) => {
        const resp = toCamelCase(response.data);
        toast.success('Details Updated Successfully');
        dispatch(setBrandSettings(resp.data));
        return resp;
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
        throw error;
      });
  };

export const uploadExEmpCSV = (data) => (dispatch) => {
  dispatch(setUploadExEmpData(data));
};

export const uploadExEmpCSVData = (data) => (dispatch) => {
  return uploadExEmpCSVAPI(data);
};
export const requestAlumniRequestTrend = (data) => {
  return {
    type: REQUEST_ALUMNI_REQUEST_TREND,
    data
  };
};

export const setAlumniRequestTrend = (data) => {
  return {
    type: SET_ALUMNI_REQUEST_TREND,
    data
  };
};

export const requestAlumniRecordsTrend = (data) => {
  return {
    type: REQUEST_ALUMNI_RECORDS_TREND,
    data
  };
};

export const setAlumniRecordsTrend = (data) => {
  return {
    type: SET_ALUMNI_RECORDS_TREND,
    data
  };
};

export const getAlumniRequestTrends = () => (dispatch) => {
  dispatch(requestAlumniRequestTrend());
  return getAlumniRequestTrendsAPI()
    .then((response) => {
      const resp = toCamelCase(response);
      dispatch(setAlumniRequestTrend(resp?.data));
      return resp?.data;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setAlumniRequestTrend({}));
      errToastMessage(error);
      throw error;
    });
};

// Alumni Request Overview
export const REQUEST_ALUMNI_REQUEST_OVERVIEW = 'REQUEST_ALUMNI_REQUEST_OVERVIEW';
export const SET_ALUMNI_REQUEST_OVERVIEW = 'SET_ALUMNI_REQUEST_OVERVIEW';

// Alumni Records Overview
export const REQUEST_ALUMNI_RECORD_OVERVIEW = 'REQUEST_ALUMNI_RECORD_OVERVIEW';
export const SET_ALUMNI_RECORD_OVERVIEW = 'SET_ALUMNI_RECORD_OVERVIEW';

export const requestAlumniRequestOverview = (data) => {
  return {
    type: REQUEST_ALUMNI_REQUEST_OVERVIEW,
    data
  };
};

export const setAlumniRequestOverview = (data) => {
  return {
    type: SET_ALUMNI_REQUEST_OVERVIEW,
    data
  };
};

export const requestAlumniRecordOverview = (data) => {
  return {
    type: REQUEST_ALUMNI_RECORD_OVERVIEW,
    data
  };
};

export const setAlumniRecordOverview = (data) => {
  return {
    type: SET_ALUMNI_RECORD_OVERVIEW,
    data
  };
};

export const getAlumniRecordsTrends = () => (dispatch) => {
  dispatch(requestAlumniRecordsTrend());
  return getAlumniRecordTrendsAPI()
    .then((response) => {
      const resp = toCamelCase(response);
      dispatch(setAlumniRecordsTrend(resp?.data));
      return resp?.data;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setAlumniRecordsTrend({}));
      errToastMessage(error);
      throw error;
    });
};

export const getAlumniRequestOverview = (data) => (dispatch) => {
  dispatch(requestAlumniRequestOverview());
  return getAlumniRequestOverviewAPI(data)
    .then((response) => {
      const resp = toCamelCase(response);
      dispatch(setAlumniRequestOverview(resp?.data));
      return resp?.data;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setAlumniRequestOverview({}));
      errToastMessage(error);
      throw error;
    });
};

export const getAlumniRecordOverview = () => (dispatch) => {
  dispatch(requestAlumniRecordOverview());
  return getAlumniRecordOverviewAPI()
    .then((response) => {
      const resp = toCamelCase(response);
      dispatch(setAlumniRecordOverview(resp?.data));
      return resp?.data;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setAlumniRecordOverview({}));
      errToastMessage(error);
      throw error;
    });
};

// Alumni Table Data
export const REQUEST_ALUMNI_TABLE_DATA = 'REQUEST_ALUMNI_TABLE_DATA';
export const SET_ALUMNI_TABLE_DATA = 'SET_ALUMNI_TABLE_DATA';

export const reqAlumniTableData = (data) => {
  return {
    type: REQUEST_ALUMNI_TABLE_DATA,
    data
  };
};

export const setAlumniTableData = (data) => {
  return {
    type: SET_ALUMNI_TABLE_DATA,
    data
  };
};

export const getAlumniTablesData = (data, tableType) => (dispatch) => {
  dispatch(reqAlumniTableData());
  return getAlumniTableDataAPI(data, tableType)
    .then((response) => {
      const resp = toCamelCase(response.data.data);
      dispatch(setAlumniTableData(resp));
      return resp;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setAlumniTableData({}));
      errToastMessage(error);
      throw error;
    });
};

//getAlumniSettings
export const REQUEST_ALUMNI_SETTINGS = 'REQUEST_ALUMNI_SETTINGS';
export const SET_ALUMNI_SETTINGS = 'SET_ALUMNI_SETTINGS';

export const requestAlumniSettings = (data) => {
  return {
    type: REQUEST_ALUMNI_SETTINGS,
    data
  };
};

export const setAlumniSettings = (data) => {
  return {
    type: SET_ALUMNI_SETTINGS,
    data
  };
};

export const getAlumniSettings = (data) => (dispatch) => {
  dispatch(requestAlumniSettings());
  return getAlumniSettingsAPI(data)
    .then((response) => {
      const resp = toCamelCase(response);
      dispatch(setAlumniSettings(resp?.data));
      return resp?.data;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setAlumniSettings({}));
      errToastMessage(error);
      throw error;
    });
};

export const editAlumniSettings = (data) => (dispatch) => {
  return editAlumniSettingsAPI(data)
    .then((response) => {
      const resp = toCamelCase(response.data);
      toast.success('Details Updated Successfully');
      dispatch(setAlumniSettings(resp.data));
      return resp;
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
      throw error?.response?.data;
    });
};

// Alumni Verification Details
export const REQUEST_ALUMNI_DETAILS = 'REQUEST_ALUMNI_DETAILS';
export const SET_ALUMNI_DETAILS = 'SET_ALUMNI_DETAILS';
export const INVALIDATE_ALUMNI_DETAILS = 'INVALIDATE_ALUMNI_DETAILS';

export const reqAlumniDetails = (data) => {
  return {
    type: REQUEST_ALUMNI_DETAILS,
    data
  };
};

export const setAlumniDetails = (data) => {
  return {
    type: SET_ALUMNI_DETAILS,
    data
  };
};

export const invalidateAlumniDetails = () => {
  return {
    type: INVALIDATE_ALUMNI_DETAILS
  };
};

export const getAlumniVerificationDetails = (data, tableType) => (dispatch) => {
  dispatch(reqAlumniDetails());
  return getAlumniVerificationDetailsAPI(data, tableType)
    .then((response) => {
      const resp = toCamelCase(response.data.data);
      dispatch(setAlumniDetails(resp));
      return resp;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setAlumniDetails({}));
      errToastMessage(error);
      throw error;
    });
};
