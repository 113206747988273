import { connect } from 'react-redux';
import { compose } from 'redux';

import { googleLogin as googleLoginAAPI } from '../../../actions/admin';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    googleLogin: (data) => dispatch(googleLoginAAPI(data))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
