import React, { useEffect, useState } from 'react';
import { has, isEmpty } from 'lodash';

import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { Dialog, Button, Box, Grid } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Field } from 'formik';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';

import FormInput from '../../../../core-components/FormInput';
import { getAllowedDomains } from '../../../../api/admin';
import CustomValidatedSelect from '../../../../core-components/CustomValidatedSelect';
import {
  isValidPhoneNumber,
  getTrimmedValue,
  compareArrays,
  errToastMessage
} from '../../../../utils/Utlities';
import countryNames from '../../../../utils/countryNamesEn.json';
import countryOptions from '../../../../utils/countryCodesEn.json';
import secureLocalStorage from 'react-secure-storage';

import { createCompanyAdmin as createCompanyAdminAPI } from '../../../../api/admin';
import { EMAIL_ADMIN_INITIAL_VALUES, EMAIL_HR_INITIAL_VALUES } from './constants';
import { toast } from 'react-toastify';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, title, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{title} Admin</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  error: {
    color: 'red',
    marginTop: '-30px',
    marginBottom: '35px'
  },
  errorMessage: {
    color: 'red',
    fontSize: '12px'
  },
  activeBtn: {
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  disabledBtn: {
    color: '#dfdfdf',
    borderColor: '#d9d9d9',
    backgroundColor: '#f5f5f5',
    fontSize: 14,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  domainErrorMsg: {
    color: '#dc3545',
    marginTop: '-14px'
  },
  typographyStyles: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: '4px'
  },
  phoneErrorMsg: {
    fontSize: '12px',
    color: '#f44336',
    marginBottom: '1rem'
  },
  marginT: {
    marginTop: '1.3rem'
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export default (props) => {
  const accessLevelValues = [
    { label: 'Admin', value: 5 },
    { label: 'HR', value: 10 }
  ];
  const {
    handleSubmit,
    isSubmitting,
    values,
    errors,
    setFieldValue,
    dirty,
    adminId,
    setEmailPrefModal,
    setEditEmailPrefData
  } = props;

  const handleDropdown = (value) => {
    setFieldValue('accessLevel', value);
  };

  const [names, setNames] = useState([]);

  const classes = useStyles();
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  const [whitelistedDomains, setWhitelistedDomains] = useState([]);
  const [whitelistedDomainsError, setWhitelistedDomainsError] = useState('');
  const companyId = new URLSearchParams(document.location.search).get('id') || null;
  const [initialState, setInitialState] = useState({});
  const [isDirty, setIsDirty] = useState(true);
  const [isAccessLevelDisabled, setIsAccessLevelDisabled] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const getIntArray = (cat) => {
    let c = cat.map(Number);
    return c;
  };

  const handleChange = (event) => {
    setPersonName(event.target.value);
    setFieldValue('userCategoryIds', getIntArray(event.target.value || []));
  };

  useEffect(() => {
    props
      .getCompanyCategories(props?.companyId, props?.categoriesCount || 10, 0, null)
      .then((categoriesData) => {
        if (categoriesData?.count) {
          let temp = {};
          for (const c of categoriesData.rows) {
            temp[c.id] = c.categoryName;
          }
          setNames(temp);
          setPersonName(props.selectedCategory);
          setInitialState({ userCategoryIds: props.selectedCategory });
          if (values?.userCategoryIds?.length) {
            const selectedIds = values?.userCategoryIds.map((v) => v.toString());
            setPersonName(selectedIds);
            setInitialState({ userCategoryIds: selectedIds });
          }
        }
      });
  }, []);

  useEffect(() => {
    if (!isEmpty(initialState)) {
      if (compareArrays(initialState.userCategoryIds, personName)) {
        setIsDirty(true);
      } else {
        setIsDirty(false);
      }
    }
  }, [personName]);

  useEffect(() => {
    fetchAllowedDomains();
  }, []);

  useEffect(() => {
    const _openAddUserModal =
      new URLSearchParams(document.location.search).get('_openAddUserModal') || null;
    const totalAdminCount = props?.activeAdminsCount;
    const currentAccessLevelOfUser = values?.accessLevel;
    const isEdit = props.title === 'Edit';

    if (!totalAdminCount && !_openAddUserModal) {
      // Atleast one CA admin should be there.
      setIsAccessLevelDisabled(true);
    }

    if (isEdit && totalAdminCount === 1 && currentAccessLevelOfUser === 5 && !_openAddUserModal) {
      // If only one admin is there in a company and current user is that one.
      setIsAccessLevelDisabled(true);
    }
  }, [props?.activeAdminsCount]);

  const fetchAllowedDomains = () => {
    let whitelistedDomains = [];
    getAllowedDomains(companyId).then((res) => {
      res.data.data.map((element) => {
        whitelistedDomains.push(element.domain);
      });
    });
    setWhitelistedDomains(whitelistedDomains);
  };

  const onKeyDownLinkedinUrl = (e) => {
    if (e.key && /\s/.test(e.key)) {
      // Prevent the default behavior if it is a whitespace character
      e.preventDefault();
    }
  };

  const handleBlurLinkedinUrl = (e) => {
    let currentValue = e.target.value;
    const newValue = getTrimmedValue(currentValue, false);
    setFieldValue('linkedInUrl', newValue);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setFieldValue('userCategoryIds', getIntArray(personName));
    handleSubmit();
  };

  const isValidForm =
    !isSubmitting &&
    isEmpty(errors) &&
    !whitelistedDomainsError &&
    values?.isValidPhone === true &&
    !(values.accessLevel === 10 && !props?.isAccessLevelChangeAllowed);

  const isEdit = props.title === 'Edit';

  const handleKeyDown = (e) => {
    // Prevent input of spaces
    if (e.key === ' ') {
      e.preventDefault();
    }
  };
  const handlePaste = (e) => {
    // Remove spaces from the pasted text
    const pastedText = e.clipboardData.getData('text/plain');
    const textWithoutSpaces = pastedText.replace(/\s/g, '');
    document.execCommand('insertText', false, textWithoutSpaces);
    e.preventDefault();
  };
  const setValue = (fieldname, value, withSpace) => {
    const trimmedVal = getTrimmedValue(value, withSpace);
    setFieldValue(fieldname, trimmedVal);
    return trimmedVal;
  };

  const handleRedirection = () => {
    if (!adminId) {
      let body = { ...values };
      body['user_type'] = body.accessLevel === 5 ? 'admin' : 'hr';
      body['company_id'] = companyId;
      body =
        body.accessLevel === 5
          ? { ...body, ...EMAIL_ADMIN_INITIAL_VALUES }
          : { ...body, ...EMAIL_HR_INITIAL_VALUES };

      createCompanyAdmin(body);
    }
  };

  const createCompanyAdmin = async (data) => {
    setLoading(true);
    try {
      const res = await createCompanyAdminAPI(data);
      const user = res?.data?.user;
      setEditEmailPrefData({
        id: user?.id,
        name: user?.name,
        email: user?.email,
        role: user?.accessLevel === 5 ? 'admin' : 'hr',
        flow: 'new'
      });
      props?.handleClose();
      setEmailPrefModal(true);
    } catch (err) {
      setLoading(false);
      errToastMessage(err);
      console.error('err: ', err);
    }
  };

  return (
    <div>
      <Dialog
        onClose={props.handleClose}
        aria-labelledby='customized-dialog-title'
        open={props.open}
      >
        <DialogTitle
          id='customized-dialog-title'
          title={props.title}
          onClose={props.handleClose}
        ></DialogTitle>
        <DialogContent>
          <Grid container>
            <form onSubmit={handleFormSubmit}>
              <Field
                type='text'
                name='name'
                requiredfield={'true'}
                label=' Name'
                component={FormInput}
                error={isEdit && errors.name}
                onBlur={(e) => {
                  const trimmedValue = getTrimmedValue(e.target.value, true);
                  setFieldValue('name', trimmedValue);
                }}
              />
              <Field
                type='email'
                name='email'
                label='Email'
                requiredfield={'true'}
                component={FormInput}
                disabled={
                  props.title === 'Edit' &&
                  (props?.details?.user?.emailRejectType === 'NA' ||
                    props?.details?.user?.emailRejectType === '')
                }
                onBlur={(e) => {
                  if (props.values.email && whitelistedDomains.length) {
                    const getDomain = '@' + props.values.email.split('@')[1];
                    if (!whitelistedDomains.includes(getDomain) && getDomain) {
                      setWhitelistedDomainsError('Enter the email with correct domain.');
                    } else {
                      setWhitelistedDomainsError('');
                    }
                  }
                }}
                onKeyDown={handleKeyDown}
                onPaste={handlePaste}
              />

              {whitelistedDomainsError && (
                <div className={classes.domainErrorMsg}>{whitelistedDomainsError}</div>
              )}

              <Field
                type='text'
                name='designation'
                label='Designation'
                component={FormInput}
                onBlur={(e) => {
                  const trimmedValue = getTrimmedValue(e.target.value, true);
                  setFieldValue('designation', trimmedValue);
                }}
              />
              <Grid item container spacing={4}>
                <Grid item md={8} xs={8} xl={8}>
                  <Typography component='legend' className={classes.typographyStyles}>
                    Mobile
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xl={5} md={5} lg={5} sm={5} xs={12}>
                      <Field
                        type='text'
                        name='countryCode'
                        defaultValue={values.countryCode}
                        options={countryNames}
                        filterLabel={countryOptions}
                        component={CustomValidatedSelect}
                        onChange={(e) => {
                          setFieldValue('isValidPhone', true);
                          if (values.mobile && e) {
                            let intlPhoneNumber = `${e + '' + values.mobile}`;
                            if (!isValidPhoneNumber(intlPhoneNumber)) {
                              setFieldValue('isValidPhone', false);
                            } else {
                              setFieldValue('isValidPhone', true);
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xl={7} md={7} lg={7} sm={7} xs={12}>
                      <Field
                        type='text'
                        name='mobile'
                        component={FormInput}
                        variant='outlined'
                        fullWidth
                        error={isEdit && errors.mobile}
                        onBlur={(e) => {
                          props.handleBlur(e);
                          const trimmedVal = setValue('mobile', values.mobile, false);
                          setFieldValue('isValidPhone', true);
                          if (trimmedVal && values.mobile && values?.countryCode) {
                            let intlPhoneNumber = `${values.countryCode + '' + trimmedVal}`;
                            if (!isValidPhoneNumber(intlPhoneNumber)) {
                              setFieldValue('isValidPhone', false);
                            } else {
                              setFieldValue('isValidPhone', true);
                            }
                          }
                        }}
                      />
                      {!values.isValidPhone && (
                        <div className={classes.phoneErrorMsg}>
                          {!values.isValidPhone ? 'Please add a valid Phone Number' : ''}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={4} xs={4} xl={4} className={classes.marginT}>
                  <FormControl size='small' fullWidth>
                    <InputLabel id='accessLevelLabel'>Access Level</InputLabel>
                    <Select
                      labelId='accessLevelLabel'
                      id='accessLevel'
                      value={values.accessLevel}
                      error={values.accessLevel === 10 && !props?.isAccessLevelChangeAllowed}
                      disabled={isAccessLevelDisabled}
                      onChange={(e) => {
                        handleDropdown(e.target.value);
                      }}
                      label='Access Level'
                    >
                      {accessLevelValues.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.value}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  {values.accessLevel === 10 && !props?.isAccessLevelChangeAllowed && (
                    <div className={classes.errorMessage}>
                      Access level cannot be changed to HR as Important notification are enabled for
                      ADMIN
                    </div>
                  )}
                </Grid>
              </Grid>
              <Field
                type='text'
                name='linkedInUrl'
                label='LinkedIn URL'
                component={FormInput}
                onKeyDown={onKeyDownLinkedinUrl}
                onBlur={handleBlurLinkedinUrl}
                error={errors.linkedInUrl}
              />
              <Grid item container>
                <Grid item md={8} xs={8} xl={8}>
                  <FormControl className={classes.formControl} fullWidth size='small'>
                    <InputLabel id='demo-mutiple-chip-label'>User Category</InputLabel>
                    <Select
                      labelId='demo-mutiple-chip-label'
                      id='demo-mutiple-chip'
                      multiple
                      fullWidth
                      value={personName}
                      onChange={handleChange}
                      disabled={isEmpty(names)}
                      input={<Input id='select-multiple-chip' />}
                      renderValue={(selected) => {
                        return (
                          <div className={classes.chips}>
                            {selected.map((value) => (
                              <Chip key={value} label={names[value]} className={classes.chip} />
                            ))}
                          </div>
                        );
                      }}
                    >
                      {Object.keys(names).map((id) => {
                        return (
                          <MenuItem key={id} value={id}>
                            {names[id]}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Box align={'right'} mt={2}>
                <Button
                  variant='contained'
                  type={adminId ? 'submit' : 'button'}
                  className={isValidForm || !isLoading ? classes.activeBtn : classes.disabledBtn}
                  disabled={isLoading ? true : !isValidForm || !dirty ? isEdit && isDirty : !dirty}
                  onClick={handleRedirection}
                >
                  {'Save'}
                </Button>
              </Box>
            </form>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};
