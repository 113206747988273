import React from 'react';

import Verified from '../SvgImages/Verified.svg';
import DataInsufficiency from '../SvgImages/DataInsufficiency.svg';
import AwaitingInput from '../SvgImages/AwaitingInput.svg';
import InProgress from '../SvgImages/InProgress.svg';
import Processing from '../SvgImages/Processing.svg';
import UnableToVerify from '../SvgImages/UnableToVerify.svg';
import Discrepancy from '../SvgImages/Discrepancy.svg';
import OnHold from '../SvgImages/OnHold.svg';
import Closed from '../SvgImages/Closed.svg';
import InsuffClosed from '../SvgImages/InsuffClosed.svg';
import FooterVerified from '../SvgImages/FooterVerified.svg';
import FooterUnableToVerify from '../SvgImages/FooterUnableToVerify.svg';
import FooterDiscrepancy from '../SvgImages/FooterDiscrepancy.svg';
import SettingsIcon from '@material-ui/icons/Settings';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export const SERVICES_STATUS = {
  values: [
    { label: 'Pending', value: 0 },
    { label: 'Active', value: 1 },
    { label: 'Custom-Services', value: 2 },
    { label: 'Automated-Services', value: 3 }
  ]
};

export const COMPANY_STATUSES = {
  values: [
    { label: 'All Statuses', value: 'All Statuses' },
    { label: 'Active', value: 'active', color: '#21d549' },
    { label: 'Inactive', value: 'inactive', color: 'red' },
    { label: 'Cancelled', value: 'cancelled', color: 'gray' }
  ]
};

export const STATUSES_DROPDOWN = {
  values: [
    { label: 'All Statuses', value: 'All Statuses' },
    { label: 'Awaiting Input', value: 3 },
    { label: 'Consent Missing', value: 11 },
    { label: 'Form Submitted', value: 4 },
    { label: 'In Progress', value: 0 },
    { label: 'Completed', value: 1 },
    { label: 'Completed with exception', value: 6 },
    { label: 'On Hold', value: 9 },
    { label: 'Cancelled', value: 10 },
    { label: 'Closed', value: 8 },
    { label: 'Insufficient Funds', value: 12 }
  ]
};

export const ADMIN_ACCESS_LEVEL = {
  5: 'Admin',
  8: 'Vendors',
  11: 'Operations',
  1: 'Operations: Misc',
  2: 'Operations: Employment',
  3: 'Operations: Education',
  4: 'Operations: Address'
};

export const STATUS_DROPDOWN = [
  { label: 'Ready to Initiate', value: '0' },
  { label: 'Verified', value: '1' },
  { label: 'Unable to Verify', value: '2' },
  // { label: 'Awaiting Input', value: '3' },
  { label: 'In Progress', value: '4' },
  { label: 'On Hold', value: '5' },
  { label: 'Closed', value: '6' },
  { label: 'N/A', value: '7' },
  { label: 'Cancelled', value: '8' },
  { label: 'Form Submitted', value: '9' },
  { label: 'Insufficiency', value: '-1' },
  { label: 'Discrepancy', value: '-2' },
  { label: 'Insuff-closed', value: '12' },
  { label: 'Completed(Auto)', value: '10' }
];

export const DF_STATUS = [
  { label: 'Select a Status', value: '0' },
  { label: 'Verified', value: '1' },
  { label: 'Unable to Verify', value: '2' },
  { label: 'N/A', value: '3' },
  { label: 'Discrepancy', value: '-2' }
];

export const EMP_DF_EXIT = [
  { label: 'Completed', value: 'COMPLETED' },
  { label: 'Not completed', value: 'NOT_COMPLETED' },
  { label: 'N/A', value: 'NA' }
];

export const EMP_DF_PERF = [
  { label: 'No issues', value: 'NO_ISSUES' },
  { label: 'Found issues', value: 'FOUND_ISSUES' },
  { label: 'N/A', value: 'NA' }
];

export const EMP_DF_REHIRE = [
  { label: 'Eligible', value: 'ELIGIBLE' },
  { label: 'Not eligible', value: 'NOT_ELIGIBLE' },
  { label: 'N/A', value: 'NA' }
];

//Address Data Provided Status => document type
export const DOCUMENT_TYPE = [
  { label: 'Driving License', value: 'Driving Licence' },
  { label: 'Aadhaar Card', value: 'Aadhaar Card' },
  { label: 'Voter ID', value: 'Voter Id' },
  { label: 'Passport', value: 'Passport' },
  { label: 'Vehicle Registration Certificate', value: 'Vehicle Registration Certificate' },
  { label: 'Utility Bill', value: 'Utility Bill' },
  { label: 'Rental Agreement', value: 'Rental Agreement' },
  { label: 'Ration Card', value: 'Ration Card' },
  { label: 'Bank Statement', value: 'Bank Statement' },
  { label: 'Other', value: 'Other' }
];

//Address Data Provided Status => Address Type
export const ADDRESS_TYPE = [
  { label: 'Permanent Address', value: 'Permanent Address' },
  { label: 'Current Address', value: 'Current Address' },
  { label: 'Other Address', value: 'Other Address' }
];

//Address Data Provided Status => Current reside
export const CURRENT_RESIDE = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' }
];

//Address Data Provided Status => Period of Stay
export const STAY_PERIOD = [
  { label: 'Under 6 months', value: 'Under 6 months' },
  { label: '6 months to 1 year', value: '6 months to 1 year' },
  { label: '1 year', value: '1 year' },
  { label: '1 years', value: '1 years' },
  { label: '2 years', value: '2 years' },
  { label: '3 years', value: '3 years' },
  { label: 'More than 3 years', value: 'More than 3 years' },
  { label: '4 years', value: '4 years' },
  { label: '5 years', value: '5 years' },
  { label: '6 years', value: '6 years' },
  { label: '7 years', value: '7 years' },
  { label: '7 years+', value: '7 years+' }
];

//Address Data Found Status => Verififcation type
export const VERIFICATION_TYPE = [
  { label: 'NONE', value: 'NONE' },
  { label: 'PHYSICAL', value: 'PHYSICAL' },
  { label: 'POSTAL', value: 'POSTAL' },
  { label: 'DIGITAL', value: 'DIGITAL' }
];

export const VERIFICATION_CHECK_STATUS = {
  values: {
    '-2': {
      label: <Discrepancy />,
      text: 'Discrepancy',
      color: '#e54700',
      textColor: '#e54700',
      value: -2
    },
    '-1': {
      label: <DataInsufficiency />,
      text: 'Insufficiency',
      color: '#e88c1c',
      textColor: '#e88c1c',
      value: -1
    },
    0: {
      label: <Processing />,
      text: 'Ready to Initiate',
      color: '#76c5fc',
      textColor: '#76c5fc',
      value: 0
    },
    1: {
      label: <Verified />,
      text: 'Verified',
      color: '#50da69',
      textColor: '#50da69',
      value: 1
    },
    2: {
      label: <UnableToVerify />,
      text: 'Unable to Verify',
      color: '#edd450',
      textColor: '#edd450',
      value: 2
    },
    3: {
      label: <AwaitingInput />,
      text: 'Awaiting Input',
      color: '#c2c2c2',
      textColor: '#c2c2c2',
      value: 3
    },
    4: {
      label: <InProgress />,
      text: 'In Progress',
      color: '#5d72c6',
      textColor: '#5d72c6',
      value: 4
    },
    5: { label: <OnHold />, text: 'On Hold', color: '#333333', textColor: '#333333', value: 5 },
    6: { label: <Closed />, text: 'Closed', color: '#979797', textColor: '#ffffff', value: 6 },
    7: { label: null, text: 'N/A', color: '#979797', textColor: '#ffffff', value: 7 },
    8: { label: null, text: 'Cancelled', color: '#979797', textColor: '#ffffff', value: 8 },
    9: {
      label: <Processing />,
      text: 'Form Submitted',
      color: '#76c5fc',
      textColor: '#76c5fc',
      value: 9
    },
    10: {
      label: null,
      text: 'Completed (Auto)',
      color: '#5d72c6',
      textColor: '#5d72c6',
      value: 10
    },
    11: { label: null, text: 'NA(Auto)', color: '#979797', textColor: '#353935', value: 11 },
    12: {
      label: <InsuffClosed />,
      text: 'Insuff-closed',
      color: '#979797',
      textColor: '#353935',
      value: 12
    },
    222: {
      label: <Discrepancy />,
      text: 'Discrepancy',
      color: '#e54700',
      textColor: '#e54700',
      value: -2
    },
    111: {
      label: <DataInsufficiency />,
      text: 'Insufficiency',
      color: '#e88c1c',
      textColor: '#e88c1c',
      value: -1
    },
    default: { text: 'Null', color: '#000000', textColor: '#000000' }
  }
};

export const DF_STATUS_REPORT = {
  0: { label: null },
  1: { label: <FooterVerified /> },
  2: { label: <FooterUnableToVerify /> },
  '-2': { label: <FooterDiscrepancy /> },
  3: { label: null }
};

export const INSUFF_CHECK_STATUS_DROPDOWN = [
  { label: 'Ready to Initiate', value: '0' },
  { label: 'Verified', value: '1' },
  { label: 'Unable to Verify', value: '2' },
  { label: 'Awaiting Input', value: '3' },
  { label: 'In Progress', value: '4' },
  { label: 'On Hold', value: '5' },
  { label: 'Closed', value: '6' },
  { label: 'N/A', value: '7' },
  { label: 'Cancelled', value: '8' },
  { label: 'Form Submitted', value: '9' },
  { label: 'Discrepancy', value: '-2' }
];

export const CANDIDATE_OVERALL_STATUS = {
  values: {
    0: { text: 'In Progress', color: '#0d47a1', textColor: '#0d47a1', value: 0 },
    1: { text: 'Completed', color: '#21d549', textColor: '#21d549', value: 1 },
    3: { text: 'Awaiting Input', color: '#c2c2c2', textColor: '#c2c2c2', value: 3 },
    4: { text: 'Form Submitted', color: '#0099cc', textColor: '#0099cc', value: 4 },
    5: { text: 'Discrepancy', color: '#ff8800', textColor: '#ff8800', value: 5 },
    6: { text: 'Completed With Exception', color: '#ff8800', textColor: '#ff8800', value: 6 },
    7: { text: 'Insufficiency', color: '#ff8800', textColor: '#ff8800', value: 7 },
    8: { text: 'Closed', color: '#979797', textColor: '#979797', value: 8 },
    9: { text: 'On Hold', color: '#979797', textColor: '#979797', value: 9 },
    10: { text: 'Cancelled', color: '#333333', textColor: '#333333', value: 10 },
    11: { text: 'Consent Missing', color: '#FF8800', textColor: '#FF8800', value: 11 },
    12: { text: 'Insufficient Funds', color: '#979797', textColor: '#353935', 979797: 12 }
  }
};
export const CHARGE_TYPE = [
  { label: 'Pass-Through Fee', value: 'PASS_THROUGH' },
  { label: 'Other Charge', value: 'OTHER' },
  { label: 'International Charge', value: 'INTERNATIONAL' },
  { label: 'Re-Initiation Charge', value: 'RE_INITIATION' }
];

export const COMPANY_TYPE = [
  { label: 'Prepaid', value: 'PREPAID' },
  { label: 'Postpaid', value: 'POSTPAID' }
];

export const DELETE_REASON = [
  {
    label: 'Other',
    value: 'OTHER'
  },
  {
    label: 'Wrong amount',
    value: 'WRONG_AMOUNT'
  },
  {
    label: 'Change in amount',
    value: 'CHANGE_IN_AMOUNT'
  },
  {
    label: `Checks closed as per client's request`,
    value: 'CLIENT_REQUEST'
  },
  {
    label: 'Required doc/data insufficiency not cleared',
    value: 'DATA_INSUFFICIENCY'
  }
];

export const ADDITIONAL_COST_REVIEW_STATUS = [
  { label: 'Cleared', value: 'APPROVED', icon: <CheckCircleOutlineIcon />, color: '#5EB09F' },
  // { label: 'Auto-approved', value: 'AUTO_APPROVED', icon: <SettingsIcon />, color: '#FF0000' },
  {
    label: 'Auto-approved (Insufficient Funds)',
    value: 'AUTO_APPROVED_INSUFF_FUNDS',
    icon: <SettingsIcon />,
    color: '#EF5E5E'
  },
  //  { label: 'Cleared', value: 'CLEARED', icon: <CheckCircleOutlineIcon />, color: '#5EB09F' },
  {
    label: 'Auto-Cleared',
    value: 'AUTO_APPROVED',
    icon: <CheckCircleOutlineIcon />,
    color: '#675FF5'
  },
  { label: 'Rejected', value: 'REJECTED', icon: <HighlightOffIcon />, color: '#788896' },
  { label: 'Auto Rejected', value: 'AUTO_REJECTED', icon: <HighlightOffIcon />, color: '#788896' },
  {
    label: 'Pending Approval',
    value: 'APPROVAL_PENDING',
    icon: <AccessTimeIcon />,
    color: '#F7C325'
  },
  {
    label: 'Deleted & Refunded',
    value: 'DELETED',
    icon: <DeleteIcon />,
    color: '#D8D8D8'
  }
];

export const ADDITIONAL_COST_A_ICONS = [
  {
    label: 'Pass-through fee is disabled for this client.',
    icon: <CancelOutlinedIcon style={{ fontSize: '16px', color: 'red' }}></CancelOutlinedIcon>
  },
  {
    label: 'International checks are disabled for this client.',
    icon: <CancelOutlinedIcon style={{ fontSize: '16px', color: 'red' }}></CancelOutlinedIcon>
  },
  {
    label:
      'International charge is included in the base price of the check for this client. Proceed with running the check.',
    icon: <InfoOutlinedIcon style={{ fontSize: '16px' }}></InfoOutlinedIcon>
  },
  {
    label:
      'International charge is included in the base price of the check for this client (package). Proceed with running the check.',
    icon: <InfoOutlinedIcon style={{ fontSize: '16px' }}></InfoOutlinedIcon>
  }
];

export const ADDITIONAL_COST_B_ICONS = [
  {
    label: 'Requires client approval. Client will be notified.',
    icon: <InfoOutlinedIcon style={{ fontSize: '16px', color: '#F7C325' }}></InfoOutlinedIcon>
  },
  {
    label: 'Input pass-through fee amount and click NEXT to change the check status to CLOSED.',
    icon: <InfoOutlinedIcon style={{ fontSize: '16px' }}></InfoOutlinedIcon>
  },
  {
    label: 'Pre-approved. Cost will be debited to the client.',
    icon: (
      <CheckCircleOutlinedIcon
        style={{ fontSize: '16px', color: '#2C88D9' }}
      ></CheckCircleOutlinedIcon>
    )
  },
  {
    label: 'Click NEXT to change the check status to CLOSED.',
    icon: <InfoOutlinedIcon style={{ fontSize: '16px' }}></InfoOutlinedIcon>
  }
];

// postpaidbilling status filter

export const POSTPAID_BILLING_STATUS = [
  {
    label: 'Default',
    key: 'DEFAULT'
  },
  {
    label: 'Paid',
    key: 'PAID'
  },
  {
    label: 'Partially Paid',
    key: 'PARTIALLY_PAID'
  },
  {
    label: 'Unpaid',
    key: 'UNPAID'
  },
  {
    label: 'Reviewing',
    key: 'REVIEWING'
  },
  {
    label: 'Reviewing + Paid',
    key: 'REVIEWING_PLUS_PAID'
  },
  {
    label: 'Reviewing + Partially Paid',
    key: 'REVIEWING_PLUS_PARTIALLY_PAID'
  },
  {
    label: 'Reviewing + Unpaid',
    key: 'REVIEWING_PLUS_UNPAID'
  }
];

export const RECHARGE_LOG_DETAILS = {
  CONVERTED_TO_CREDITS: 'CONVERTED TO CREDITS',
  PACKAGE_BOUGHT: 'PACKAGE BOUGHT',
  SA_EXCHANGE: 'PACKAGE CONVERTED TO CREDITS',
  PACKAGE_BOUGHT_CREDIT: 'PACKAGE BOUGHT CREDIT',
  PACKAGE_BOUGHT_CREDIT_PAYMENT: 'PACKAGE BOUGHT CREDIT PAYMENT',
  PACKAGE_BOUGHT_PAYMENT: 'PACKAGE BOUGHT PAYMENT',
  CANDIDATE_ADDED: 'CANDIDATE ADDED',
  CANDIDATE_DELETED: 'CANDIDATE CANCELLED'
};

export const HOLD_REASON = [
  {
    label: 'Info / Doc Unavailable',
    value: 'INFO_OR_DOC_UNAVAILABLE'
  },
  {
    label: 'Client Request',
    value: 'CLIENT_REQUEST'
  },
  { label: 'Issue with Source', value: 'ISSUE_WITH_SOURCE' },
  { label: 'Other', value: 'OTHER' }
];
// transaction log constants //////
export const TRANSACTION_EVENTS = [
  {
    label: 'Add-on bought',
    value: 'ADDON_BOUGHT'
  },
  {
    label: 'Check Refund',
    value: 'CHECK_REFUND'
  },
  {
    label: 'Additional Charge Refund',
    value: 'ADDITIONAL_CHARGE_REFUND'
  }
];

export const TRANSACTION_CHECK_TYPES = [
  {
    label: 'Address',
    value: 'ADDRESS'
  },
  {
    label: 'Credit Check',
    value: 'CIBIL'
  },
  {
    label: 'Court',
    value: 'COURT'
  },
  {
    label: 'Custom',
    value: 'CUSTOM'
  },
  {
    label: 'Drug',
    value: 'DRUG'
  },
  {
    label: 'Employment',
    value: 'EMPLOYMENT'
  },
  {
    label: 'Education',
    value: 'EDUCATION'
  },
  {
    label: 'Identity',
    value: 'ID'
  },
  {
    label: 'Reference',
    value: 'REF'
  },
  {
    label: 'World',
    value: 'WORLD'
  }
];
