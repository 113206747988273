import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, sortBy, isArray, forEach, isEmpty, has } from 'lodash';
import { toast } from 'react-toastify';

import { opsStatusAction as opsStatusActionAPI } from '../../../../api/admin';
import { errToastMessage, getErrorMessage } from '../../../../utils/Utlities';
import validationSchema from './OpsStatusAction.validation';

export default compose(
  connect(null, null),
  withFormik({
    mapPropsToValues: (props) => {
      let propsValue = {};

      if (props?.details) {
        propsValue = {
          isEdit: true,
          opsStatusId: props?.details?.id,
          name: props?.details?.statusName,
          isActive: props?.details?.isActive === '1' ? true : false
        };
      } else {
        propsValue = {};
      }
      let storedValues = {
        name: '',
        isActive: false,
        isEdit: false
      };

      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (values, { setFieldError, setStatus, setSubmitting, resetForm, props }) => {
      let value;
      if (!values.isEdit) {
        value = {
          name: values.name,
          status: values.isActive ? 'active' : 'inactive'
        };
      } else {
        value = {
          ops_status_id: values.opsStatusId,
          name: values.name,
          status: values.isActive ? 'active' : 'inactive'
        };
      }
      opsStatusActionAPI(value)
        .then((response) => {
          setSubmitting(false);
          toast.success(`Ops Status ${values.isEdit ? 'Edited' : 'Added'} successfully`);
          props.handleClose();
        })
        .catch((error) => {
          setSubmitting(false);
          errToastMessage(error);
          console.error(error);
        });
    },
    displayName: 'Ops Status Action'
  })
);
