import React, { useState } from 'react';
import { isEmpty } from 'lodash';

import {
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import FormInput from '../../core-components/FormInput';
import AppHelper from '../../core-components/AppHelper';
import { accessCookie, jwtParser } from '../../utils/Utlities';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0, 2, 0),
    color: theme.palette.text.secondary
  },
  activeBtn: {
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  disabledBtn: {
    color: '#dfdfdf',
    borderColor: '#d9d9d9',
    backgroundColor: '#f5f5f5',
    fontSize: 14,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  errorMsg: {
    fontSize: '0.75rem',
    color: '#f44336',
    fontWeight: '400',
    lineHeight: '1.66',
    margin: 0,
    marginTop: theme.spacing(-4),
    marginBottom: theme.spacing(3)
  },
  required: {
    color: '#db3131'
  },
  customFormLabel: {
    marginBottom: 10,
    marginTop: 10
  },
  customImgWidth: {
    maxWidth: 180
  },
  eye: {
    cursor: 'pointer'
  }
}));

export default (props) => {
  const classes = useStyles();
  const { isSubmitting, errors, setFieldValue } = props;
  const [passwordIsMasked, setPasswordIsMasked] = useState(true);
  const [passwordConfirmedIsMasked, setPasswordConfirmedIsMasked] = useState(true);
  const [passwordResetToken, setPasswordResetToken] = useState(null);
  const [uuid, setUuid] = useState(null);

  //password Reset Token and uuid
  useState(() => {
    setPasswordResetToken(
      new URLSearchParams(document.location.search).get('password_reset_token') || null
    );
    setUuid(new URLSearchParams(document.location.search).get('uuid') || null);
  }, []);

  const togglePasswordMask = (type) => {
    if (type === 'password') {
      setPasswordIsMasked(!passwordIsMasked);
    }
    if (type === 'confirmPassword') {
      setPasswordConfirmedIsMasked(!passwordConfirmedIsMasked);
    }
  };

  const handleFormSubmit = (e) => {
    setFieldValue('passwordResetToken', passwordResetToken);
    setFieldValue('uuid', uuid);
    e.preventDefault();
    setPasswordIsMasked(true);
    setPasswordConfirmedIsMasked(true);
    props.handleSubmit();
  };

  const isValidForm = !isSubmitting && isEmpty(errors);

  return (
    <Box mt={3} mb={3}>
      <Grid container direction='row'>
        <Grid item lg={2} md={2} sm={2} xs={2}></Grid>
        <Grid data-testid='invitepage-box' item lg={8} md={8} sm={8} xs={8}>
          <form onSubmit={handleFormSubmit}>
            <Typography variant={'h6'}>Set Password</Typography>
            <Typography>Please choose your password :</Typography>
            <Paper variant='outlined' elevation={5} className={classes.paper}>
              <Field
                type={passwordIsMasked ? 'password' : 'text'}
                name='password'
                label='Password'
                data-testid='password'
                component={FormInput}
                requiredfield={'true'}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton onClick={() => togglePasswordMask('password')} size='small'>
                      {passwordIsMasked ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Field
                type={passwordConfirmedIsMasked ? 'password' : 'text'}
                name='confirmPassword'
                label='Confirm Password'
                data-testid='confirm-password'
                component={FormInput}
                requiredfield={'true'}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton onClick={() => togglePasswordMask('confirmPassword')} size='small'>
                      {passwordConfirmedIsMasked ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Paper>
            <Button
              variant='contained'
              type='submit'
              data-testid='setpassword-btn'
              className={isValidForm ? classes.activeBtn : classes.disabledBtn}
              disabled={!isValidForm}
            >
              Set Password
            </Button>
          </form>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}></Grid>
      </Grid>
    </Box>
  );
};
