import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import Typography from '@material-ui/core/Typography';

import Button from './Button';

const styles = (theme) => ({
  root: {
    margin: 4,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    margin: '0 auto',
    padding: '4px 16px',
    minWidth: 240,
    textAlign: 'center'
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: '0 auto',
    padding: 0
  }
}))(MuiDialogActions);

const useStyles = makeStyles({
  successIcon: {
    fontSize: 54,
    color: '#33C759'
  },
  warningIcon: {
    fontSize: 54,
    color: '#ffd740'
  },
  errorIcon: {
    fontSize: 54,
    color: '#e53935'
  }
});

export default function StatePopup(props) {
  const classes = useStyles();

  const handleClose = () => {
    props.handleClose();
  };

  const handleSubmit = () => {
    props.handleSubmit();
    props.handleClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='popup-title'
      size={'sm'}
      fullWidth
      open={props.open}
    >
      <DialogTitle id='popup-title' onClose={handleClose}>
        <Typography variant='h6' gutterBottom>
          {props.title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {props.popupState === 'success' ? (
          <SuccessIcon className={classes.successIcon} />
        ) : props.popupState === 'warning' ? (
          <WarningIcon className={classes.warningIcon} />
        ) : props.popupState === 'error' ? (
          <ErrorIcon className={classes.errorIcon} />
        ) : null}
        <Typography variant='body2' gutterBottom color={'textSecondary'}>
          {props.content}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color={'primary'} label={props.buttonLabel} onClick={handleSubmit} />
      </DialogActions>
    </Dialog>
  );
}
