export const EMAIL_ADMIN_INITIAL_VALUES = {
  email_preferences: {
    DIGEST_OVERDUE_BGV_CANDIDATE: {
      is_checked: true,
      email_frequency: 'DAILY'
    },
    DIGEST_OVERDUE_BGV_ADMIN: {
      is_checked: true,
      email_frequency: 'DAILY'
    },
    DIGEST_CANDIDATE_BOUNCED: {
      is_checked: true,
      email_frequency: 'DAILY'
    },
    ENABLE_OTHER_IMPORTANT_NOTIFICATIONS: {
      is_checked: true
    },
    DIGEST_ADC_APPROVAL: {
      is_checked: true,
      email_frequency: 'DAILY'
    },
    DIGEST_INSUFFICIENCY: {
      is_checked: false,
      email_frequency: 'DAILY'
    },
    DIGEST_ONHOLD: {
      is_checked: false,
      email_frequency: 'DAILY'
    },
    DIGEST_CS_UPDATES: {
      is_checked: false,
      email_frequency: 'DAILY'
    },
    DIGEST_OS_UPDATES: {
      is_checked: false,
      email_frequency: 'DAILY'
    },
    INSUFF_CLOSED_ADDITIONAL_CHECKS_FOR_INSTANT_NOTIFICATIONS: {
      is_checked: false
    },
    DISCREPANT_ADDITIONAL_CHECKS_FOR_INSTANT_NOTIFICATIONS: {
      is_checked: false
    },
    UTV_ADDITIONAL_CHECKS_FOR_INSTANT_NOTIFICATIONS: {
      is_checked: false
    },
    CLOSED_ADDITIONAL_CHECKS_FOR_INSTANT_NOTIFICATIONS: {
      is_checked: false
    },
    LOOP_ME_INTO_CANDIDATE_INSUFF: {
      is_checked: false
    }
  },
  advanced_settings: {
    DAILY: 'MONDAY_SUNDAY',
    MONTHLY: 'FIRST_DAY'
  }
};

export const EMAIL_HR_INITIAL_VALUES = {
  email_preferences: {
    DIGEST_OVERDUE_BGV_CANDIDATE: {
      is_checked: false,
      email_frequency: 'DAILY'
    },
    DIGEST_OVERDUE_BGV_ADMIN: {
      is_checked: true,
      email_frequency: 'DAILY'
    },
    DIGEST_CANDIDATE_BOUNCED: {
      is_checked: true,
      email_frequency: 'DAILY'
    },
    ENABLE_OTHER_IMPORTANT_NOTIFICATIONS: {
      is_checked: false
    },
    DIGEST_ADC_APPROVAL: {
      is_checked: false,
      email_frequency: 'DAILY'
    },
    DIGEST_INSUFFICIENCY: {
      is_checked: false,
      email_frequency: 'DAILY'
    },
    DIGEST_ONHOLD: {
      is_checked: false,
      email_frequency: 'DAILY'
    },
    DIGEST_CS_UPDATES: {
      is_checked: false,
      email_frequency: 'DAILY'
    },
    DIGEST_OS_UPDATES: {
      is_checked: false,
      email_frequency: 'DAILY'
    },
    INSUFF_CLOSED_ADDITIONAL_CHECKS_FOR_INSTANT_NOTIFICATIONS: {
      is_checked: false
    },
    DISCREPANT_ADDITIONAL_CHECKS_FOR_INSTANT_NOTIFICATIONS: {
      is_checked: false
    },
    UTV_ADDITIONAL_CHECKS_FOR_INSTANT_NOTIFICATIONS: {
      is_checked: false
    },
    CLOSED_ADDITIONAL_CHECKS_FOR_INSTANT_NOTIFICATIONS: {
      is_checked: false
    },
    LOOP_ME_INTO_CANDIDATE_INSUFF: {
      is_checked: false
    }
  },
  advanced_settings: {
    DAILY: 'MONDAY_SUNDAY',
    MONTHLY: 'FIRST_DAY'
  }
};
