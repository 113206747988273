import React, { useEffect, useState } from 'react';
import { forEach, isEmpty } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';

import { Typography, makeStyles } from '@material-ui/core';

import Table from '../../../../core-components/Table';
import { displayDateFormat } from '../../../../core-components/CommonConstants';
import {
  toCamelCase,
  getErrorMessage,
  convertToIndianNumeration,
  errToastMessage
} from '../../../../utils/Utlities';
import { getPostpaidCreditDetailsAuditLogs } from '../../../../api/admin';
import AppConstants from '../../../../core-components/AppConstants';

const useStyles = makeStyles((theme) => ({
  rupeeSymbol: {
    fontFamily: 'Roboto'
  }
}));

function createData(date, user, details) {
  return { date, user, details };
}

const headCells = [
  {
    id: 'date',
    label: 'Date',
    align: 'center'
  },
  {
    id: 'user',
    label: 'User',
    align: 'center'
  },
  {
    id: 'details',
    label: 'Details'
  }
];

export default function AuditLogs(props) {
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    getMonthlyUsage(rowsPerPage, offset);
  }, [props.companyId]);

  const getMonthlyUsage = (rowsPerPage, offset) => {
    setLoading(true);
    getPostpaidCreditDetailsAuditLogs(props.companyId, rowsPerPage, offset)
      .then((response) => {
        const value = toCamelCase(response.data.data);
        const rowsData = value.response && value.count ? getRowData(value.response) : [];
        setRows(rowsData);
        setTotalCount(value.count);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        errToastMessage(error);
        console.error(error);
      });
  };

  const getUserLink = (user) => {
    return user?.companyUserMapping ? (
      <Typography variant={'subtitle2'}>
        {user?.companyUserMapping ? (
          <a href={`${AppConstants.baseURL}company/admins?id=${props.companyId}`} target='_blank'>
            {user?.name}
          </a>
        ) : (
          `SA : ${user?.name} (${user?.id})`
        )}
      </Typography>
    ) : (
      `SA : ${user?.name} (${user?.id})`
    );
  };

  const updateCreditLimit = (inputStr) => {
    const pattern1 = /Change credit limit \((UNLIMITED|\d+) -> (UNLIMITED|\d+)\)/;
    const pattern2 = /Cost per candidate /;

    const matches1 = inputStr.match(pattern1);
    const matches2 = inputStr.match(pattern2);

    if (matches1) {
      const amount1 = matches1[1];
      const amount2 = matches1[2];

      const outputStr = (
        <span>
          Change credit limit (
          {amount1 === 'UNLIMITED' ? (
            'UNLIMITED'
          ) : (
            <span>
              <span className={classes.rupeeSymbol}>&#8377;</span>
              {convertToIndianNumeration(parseInt(amount1))}
            </span>
          )}{' '}
          ->{' '}
          {amount2 === 'UNLIMITED' ? (
            'UNLIMITED'
          ) : (
            <span>
              <span className={classes.rupeeSymbol}>&#8377;</span>
              {convertToIndianNumeration(parseInt(amount2))}
            </span>
          )}
          )
        </span>
      );

      return outputStr;
    } else if (matches2) {
      const output = inputStr.replace(/(\d+)/g, (match, minusSign, number) => {
        if (minusSign === '-') {
          return `${minusSign}<span>&#8377;</span>${convertToIndianNumeration(parseInt(number))}`;
        } else {
          return `<span>&#8377;</span>${convertToIndianNumeration(parseInt(number))}`;
        }
      });

      return <span dangerouslySetInnerHTML={{ __html: output }} class={classes.rupeeSymbol} />;
    } else {
      return inputStr;
    }
  };

  const getRowData = (values) => {
    let data = [];
    forEach(values, function (value) {
      if (!isEmpty(value)) {
        let rowData = createData(
          value.createdAt && moment(value.createdAt).isValid()
            ? moment(value.createdAt).format(displayDateFormat)
            : null,
          getUserLink(value.users),
          updateCreditLimit(value.details) || '-'
        );
        data.push(rowData);
      }
    });
    return data;
  };

  const handleChangePage = (newPage) => {
    setOffset(newPage);
    getMonthlyUsage(rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setOffset(0);
    getMonthlyUsage(parseInt(value, 10), 0);
  };

  return (
    <>
      <Table
        headCells={headCells}
        rowData={rows}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={offset}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        loading={loading}
      />
    </>
  );
}
