import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Dialog, Grid, IconButton, InputLabel, TextField, makeStyles } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import styles from './PendingOrders.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { displayDateReportFormat } from '../../../../../core-components/CommonConstants';
import cn from 'classnames';
import { convertToIndianNumeration } from '../../../../../utils/Utlities';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-input': {
      height: '20px', // adjust the height as desired
      width: '190px',
      paddingTop: '6px', // vertically center the input text,
      border: '1px solid #d2d0d0 !important',
      borderBottom: '1px solid #d2d0d0 !important',
      backgroundColor: '#f5f4f4',
      borderRadius: '1px'
    },
    '& .MuiInputLabel-formControl': {
      top: '50%', // vertically center the label
      paddingLeft: '5px',
      transform: 'translateY(-50%)'
    }
  },
  rupeeSymbol: {
    fontFamily: 'Roboto',
    paddingTop: '3px'
  },
  costContainer: {
    display: 'flex'
  },
  cost: {
    alignItems: 'center'
  }
}));

export default (props) => {
  const { open, handleClose, order } = props;
  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const getTextFieldAndInput = (inputLabel, inputText) => {
    return (
      <Grid justifyContent='flex-end' item lg={5} md={5} sm={5} xs={5}>
        <div>
          <InputLabel className={styles.label}>{inputLabel}</InputLabel>
          <div></div>
          <TextField
            disabled
            type='text'
            label={inputText || '-'}
            name='requestorName'
            size='small'
            variant='filled'
            className={classes.root}
            InputProps={{ disableUnderline: true }}
          />
        </div>
      </Grid>
    );
  };

  const getRechargeAmount = (cp) => {
    return (
      <span className={`${styles?.email} ${classes.cost}`}>
        <span>{cp?.numberOfUnits} units * </span>
        <span className={classes.rupeeSymbol}>&nbsp;&#8377;</span>
        <span>{convertToIndianNumeration(cp?.pricePerUnit)} = </span>
        <span className={classes.rupeeSymbol}>&nbsp;&#8377;</span>
        <span>{convertToIndianNumeration(parseInt(cp?.numberOfUnits * cp?.pricePerUnit))}</span>
      </span>
    );
  };

  const getTagAndBounce = (accessLevel, forClassName = false) => {
    switch (accessLevel) {
      case 5:
        return forClassName ? styles.adminTags : 'Admin';
      case 10:
        return forClassName ? styles.hrOPsTags : 'HR';
      default:
        return '';
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth={'sm'}
      fullWidth={true}
      aria-labelledby='customized-dialog-title'
      onClose={handleClose}
    >
      <div className={styles.modalContainer}>
        <MuiDialogTitle closeButton>
          <div className={styles.modalTitle}>Payment Request</div>
          {handleClose ? (
            <IconButton aria-label='close' className={styles.closeIcon} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <Form onSubmit={handleSubmit}>
          <MuiDialogContent>
            <Grid container>
              {getTextFieldAndInput('Requestor', order.requestedBy.userName)}
              {getTextFieldAndInput(
                'Due Date (Pay-by-date)',
                order.dueDate ? moment(order.dueDate).utc().format(displayDateReportFormat) : '-'
              )}
            </Grid>
            <div className={styles.checkBoxTitle}>
              <InputLabel className={styles.label}>Selected contacts for payment</InputLabel>
            </div>
            <ul>
              {order.requesteeDetails &&
                order.requesteeDetails.length > 0 &&
                order.requesteeDetails.map((cp, i) => (
                  <li className='mt-2'>
                    {cp?.user?.name && (
                      <div>
                        <span className={styles.name}>{cp?.user?.name}</span>
                        <span
                          className={cn(styles.tags, getTagAndBounce(cp?.user?.accessLevel, true))}
                        >
                          {getTagAndBounce(cp?.user?.accessLevel)}
                        </span>
                      </div>
                    )}
                    {cp.email && (
                      <div>
                        <span className={cp?.user?.name ? styles.email : styles.name}>
                          {cp.email}
                        </span>
                      </div>
                    )}
                  </li>
                ))}
            </ul>
            <div className={styles.checkBoxTitle}>
              <InputLabel className={styles.label}>Order Summary</InputLabel>
            </div>
            <ul>
              {order.orderItems &&
                order.orderItems.length > 0 &&
                order.orderItems.map((cp, i) => (
                  <li className='mt-2'>
                    {cp.type == 'PACKAGE' ? (
                      <>
                        <div className={classes.costContainer}>
                          <span className={styles.name}>{'Package Recharge '}</span>
                          {cp?.paymentsPackagesNew?.name && (
                            <span
                              className={styles.name}
                            >{` (${cp?.paymentsPackagesNew?.name}) `}</span>
                          )}
                          {getRechargeAmount(cp)}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={classes.costContainer}>
                          <span className={styles?.name}>{'Credits'}</span>
                          <span className={`${classes.cost} ${styles.email}`}>
                            <span className={classes.rupeeSymbol}>&nbsp;&#8377;</span>
                            <span>{convertToIndianNumeration(parseInt(cp?.rechargeAmount))}</span>
                          </span>
                        </div>
                      </>
                    )}
                  </li>
                ))}
            </ul>
          </MuiDialogContent>
        </Form>
      </div>
    </Dialog>
  );
};
