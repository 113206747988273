import React, { useState } from 'react';
import { MenuItem, Typography, Popover } from '@material-ui/core';
import styles from './PendingOrders.module.scss';
import CancelOrder from './CancelOrder';
import DetailsOrder from './DetailsOrder';
import UpdateBalance from '../Balances/UpdateBalance';

export default function BasicMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [updateBalanceModal, setUpdateBalanceModal] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <Typography
          component={'span'}
          color={'primary'}
          className={styles.infoButton}
          onClick={handleClick}
        >
          {props.order.status == 'PENDING' ? (
            '[OPTIONS]'
          ) : (
            <div
              onClick={() => {
                setShowDetailsModal(true);
              }}
            >
              [VIEW]
            </div>
          )}
        </Typography>
        {props.order.status === 'PENDING' && (
          <Popover
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <MenuItem
              className={styles.details}
              onClick={() => {
                setShowDetailsModal(true);
              }}
            >
              View Details
            </MenuItem>
            <MenuItem
              className={styles.paid}
              onClick={() => {
                setUpdateBalanceModal(true);
              }}
            >
              Mark as Paid
            </MenuItem>
            <MenuItem
              className={styles.cancel}
              onClick={() => {
                setShowCancelModal(true);
              }}
            >
              Cancel Order
            </MenuItem>
          </Popover>
        )}
      </div>
      {showCancelModal ? (
        <CancelOrder
          open={showCancelModal}
          handleClose={(success) => {
            setShowCancelModal(false);
            if (success) {
              props.pendingOrderAPICall({ offset: 0, limit: 10, status: 'ALL' });
            }
          }}
          caList={props.caList}
          orderId={props.order.orderId}
          companyId={props.companyId}
        />
      ) : null}
      {showDetailsModal ? (
        <DetailsOrder
          open={showDetailsModal}
          handleClose={() => {
            setShowDetailsModal(false);
          }}
          order={props.order}
        />
      ) : null}
      {updateBalanceModal ? (
        <UpdateBalance
          companyId={props.companyId}
          balance={props.balance}
          tax={props?.tax}
          companyName={props.companyName}
          handleClose={() => setUpdateBalanceModal(false)}
          open={updateBalanceModal}
          id={'PENDING_ORDER'}
          orderId={props.order.orderId}
        />
      ) : null}
    </>
  );
}
