import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  anchorText: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  }
}));

export default ({ item, handleDrawerToggle }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClick = () => {
    setOpen(!open);
  };

  const ListItemLink = (props) => {
    return <ListItem button {...props} />;
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={item.title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {item?.child?.map((item, index) => {
            return item.text === 'Find the Right Package' ? (
              <ListItemLink
                onClick={() => {
                  window.open(item.href, '_blank');
                }}
              >
                <ListItemText secondary={item.text} />
              </ListItemLink>
            ) : (
              <ListItemLink
                key={`${item.text}_mobile-nested-text_${index}`}
                onClick={() => {
                  handleClick();
                  handleDrawerToggle();
                }}
              >
                <Link
                  to={item.href}
                  key={`${item.text}_mobile-nested-text_${index}`}
                  className={classes.anchorText}
                >
                  <ListItemText secondary={item.text} />
                </Link>
              </ListItemLink>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};
