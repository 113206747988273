import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box, Button } from '@material-ui/core';

import PackageList from './PackageList';
import NoPackageFound from './NoPackageFound';
import CustomizePackage from '../CustomizePackage';
import { getAddressAnyCounter, getIdentityAnyCounter } from '../CustomizePackage/helper';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3)
  },
  goBack: {
    height: '44px',
    fontSize: 14,
    lineHeight: 1
  }
}));

export default (props) => {
  const classes = useStyles();
  const [openCustomDialog, setOpenCustomDialog] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [packageList, setPackageList] = useState([]);
  const { companyBillingType, isAadhaarAllowed, isBulkUpload, history } = props;

  const packageType = companyBillingType === 'PREPAID' ? 'PREPAID' : 'POSTPAID';
  useEffect(() => {
    return () => {
      setSelectedPackage(null);
      setPackageList([]);
    };
  }, []);

  useEffect(() => {
    setPackageList(props.packageList);
  }, [props.packageList]);

  const handleCustomDialog = () => {
    setOpenCustomDialog(!openCustomDialog);

    if (openCustomDialog) {
      setSelectedPackage(null);
    }
  };

  const handleCustomizedConfigSubmit = (packageDetail = null) => {
    props.handleStepSubmit(['step2'], [packageDetail], 3);
    handleCustomDialog();
  };

  const handleChoose = (isCustomisable = false, packageDetail = null, index = 0) => {
    setSelectedPackage(packageDetail);
    props.handleSelectedIndex(index);

    if (isCustomisable) {
      handleCustomDialog();
    } else {
      props.handleStepSubmit(['step2'], [packageDetail], 3);
    }
  };

  const isCustomisable = (config = {}) => {
    const tempConfig = Object.assign({}, config);

    if (config.referenceType === 'SPECIFIC' || config.referenceType === 'TOTAL') {
      return true;
    }

    if (config.identity && config.identity.length && getIdentityAnyCounter(config.identity)) {
      return true;
    }

    if (
      (config.address &&
        config.address.length === 1 &&
        getAddressAnyCounter(config.address) === 1) ||
      (config.court && config.court.length === 1 && getAddressAnyCounter(config.court) === 1)
    ) {
      return true;
    }
  };

  const onClickHandler = () => {
    const companyId = new URLSearchParams(document.location.search).get('company_id') || '';
    if (isBulkUpload) {
      // history.push({
      //   pathname: '/company/candidate/bulk-upload',
      //   search: `?company_id=${companyId}`,
      //   state: { isBulkUpload: true, step: 2, companyId }
      // });
      props?.goBackToBulkUpload();
    } else {
      props.handleStepChange(1);
    }
  };

  return (
    <>
      {packageList && packageList.length ? (
        <Grid className={classes.root} container spacing={4}>
          <Grid item xl={4} md={4} lg={4} sm={12} xs={12}>
            <Typography variant='h4' gutterBottom color='textSecondary'>
              {'Choose a verification package'}
            </Typography>
          </Grid>
          <Grid item xl={8} md={8} lg={8} sm={12} xs={12}>
            {packageList.map((pkg, index) => {
              return (
                <PackageList
                  key={index}
                  index={index}
                  packageDetails={{ ...pkg, isCustomisable: isCustomisable(pkg.config) }}
                  selectedIndex={props.selectedIndex}
                  handleChoose={handleChoose}
                  showChoose={true}
                  showSelected={true}
                  isAadhaarAllowed={isAadhaarAllowed}
                  packageType={packageType}
                />
              );
            })}

            {/* Go back button */}
            <Box mt={2}>
              <Button
                color={'primary'}
                variant={'outlined'}
                onClick={onClickHandler}
                size={'small'}
                className={classes.goBack}
              >
                {'Go back'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <NoPackageFound companyName={props.companyName} />
      )}

      {openCustomDialog ? (
        <CustomizePackage
          open={openCustomDialog}
          handleClose={handleCustomDialog}
          selectedIndex={props.selectedIndex}
          isAadhaarAllowed={isAadhaarAllowed}
          updatedConfig={
            props?.savedValues &&
            props?.savedValues?.customizeConfig &&
            props?.savedValues?.customizeConfig[props.selectedIndex]
              ? props?.savedValues?.customizeConfig[props.selectedIndex]
              : null
          }
          selectedPackage={selectedPackage}
          handleCustomizedConfigSubmit={handleCustomizedConfigSubmit}
        />
      ) : null}
    </>
  );
};
