import React, { useState, useEffect } from 'react';
import { has, isString } from 'lodash';
import { getIn } from 'formik';

import { makeStyles, withStyles, fade } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputBase from '@material-ui/core/InputBase';
import {
  convertFromIndianNumeration,
  convertToIndianNumeration,
  removeAsterisks
} from '../utils/Utlities';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center'
  },
  input: {
    marginBottom: theme.spacing(2)
  },
  required: {
    color: '#db3131'
  },
  disabled: {
    pointerEvents: 'unset !important',
    cursor: 'not-allowed !important',
    backgroundColor: '#f5f4f4'
  },
  normalInputLabel: {
    fontSize: '21px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#333333',
    position: 'relative',
    lineHeight: 1
  }
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    '&.Mui-error input': {
      borderColor: '#f44336'
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    // transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      //   boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      //   borderColor: theme.palette.primary.main,
    },
    '&$disabled': {
      cursor: 'not-allowed !important'
    }
  },
  disabled: {}
}))(InputBase);

export default function FormInput(props) {
  const classes = useStyles();
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const { field, form, label, regex, max, handleChange, showError, error } = props;

  return (
    <>
      {has(props, 'variant') && props.variant === 'outlined' ? (
        <FormControl
          variant='outlined'
          fullWidth={has(props, 'fullWidth') ? props.fullWidth : false}
          size={'small'}
          error={
            form &&
            getIn(form.errors, field.name) &&
            (getIn(form.touched, field.name) || has(props, 'isTouchedNotRequired') || error)
              ? true
              : false
          }
          style={has(props, 'fieldwidth') ? { width: props.fieldwidth } : null}
        >
          <InputLabel htmlFor='component-outlined'>
            {label}
            {props.requiredfield ? <span className={classes.required}>*</span> : null}
          </InputLabel>
          <OutlinedInput
            {...field}
            {...props}
            classes={{ disabled: classes.disabled }}
            startAdornment={props?.startAdornment}
            value={
              props?.removeAsterisk && isString(field.value)
                ? removeAsterisks(field.value)
                : props?.isShowInIndianCurrency && field.value
                ? convertToIndianNumeration(parseInt(field.value))
                : field.value || ''
            }
            onChange={(event) => {
              if (props.onChange) {
                props.onChange(event);
              } else {
                form.setFieldValue(
                  field.name,
                  props.type === 'number'
                    ? parseInt(event.target.value || 0)
                    : props?.isShowInIndianCurrency && event.target.value
                    ? convertFromIndianNumeration(event.target.value)
                    : event.target.value.replace(regex, '').slice(0, max)
                );
                handleChange && props.type === 'number'
                  ? parseInt(event.target.value || 0)
                  : event.target.value.replace(regex, '').slice(0, max);
              }
            }}
          />
          {showError && (
            <FormHelperText id='component-error-text'>
              {form &&
              getIn(form.errors, field.name) &&
              (getIn(form.touched, field.name) || has(props, 'isTouchedNotRequired') || error)
                ? form && getIn(form.errors, field.name)
                : null}
            </FormHelperText>
          )}
        </FormControl>
      ) : has(props, 'variant') && props.variant === 'normal' ? (
        <FormControl
          className={classes.margin}
          fullWidth={has(props, 'fullWidth') ? props.fullWidth : false}
          size={'small'}
          error={
            form && getIn(form.errors, field.name) && (getIn(form.touched, field.name) || error)
              ? true
              : false
          }
          style={has(props, 'fieldwidth') ? { width: props.fieldwidth } : null}
        >
          <InputLabel shrink htmlFor='bootstrap-input' className={classes.normalInputLabel}>
            {label} {props.requiredfield ? <span className={classes.required}>*</span> : null}
            {props.description || null}
          </InputLabel>
          <BootstrapInput
            {...field}
            {...props}
            inputProps={{
              classes: {
                disabled: classes.disabled
              }
            }}
            value={field.value || ''}
            onChange={(event) => {
              if (props.onChange) {
                props.onChange(event);
              } else {
                form.setFieldValue(field.name, event.target.value.replace(regex, '').slice(0, max));
                handleChange && handleChange(event.target.value.replace(regex, '').slice(0, max));
              }
            }}
            disabled={props.disable}
          />
          <FormHelperText id='component-error-text'>
            {form && getIn(form.errors, field.name) && (getIn(form.touched, field.name) || error)
              ? form && getIn(form.errors, field.name)
              : null}
          </FormHelperText>
        </FormControl>
      ) : (
        <FormControl
          className={classes.input}
          fullWidth={has(props, 'fullWidth') ? props.fullWidth : true}
          error={
            form && (form.touched[field.name] || error) && !!form.errors[field.name] ? true : false
          }
        >
          <InputLabel>
            {label}
            {props.requiredfield ? <span className={classes.required}>*</span> : null}
          </InputLabel>
          <Input
            {...field}
            {...props}
            onChange={(event) => {
              if (props.onChange) {
                props.onChange(event);
              } else {
                form.setFieldValue(field.name, event.target.value.replace(regex, '').slice(0, max));
                handleChange && handleChange(event.target.value.replace(regex, '').slice(0, max));
              }
            }}
          />
          <FormHelperText id='component-error-text'>
            {form && (form.touched[field.name] || error) && !!form.errors[field.name]
              ? form && form.errors[field.name]
              : null}
          </FormHelperText>
        </FormControl>
      )}
    </>
  );
}

FormInput.defaultProps = {
  showError: true,
  removeAsterisk: false
};
