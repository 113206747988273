import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox
} from '@material-ui/core';
import { PLAIN_CHECK_ICONS } from '../../CommonConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import IncrementCounter from '../IncrementCounter';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  formControl: {
    margin: theme.spacing(3)
  }
}));

export default (props) => {
  const classes = useStyles();
  const { values, limit, referenceChange, empCount, isUnassigned } = props;

  const handleTypeChange = (value) => {
    referenceChange('type', value);
    if (value === 'NONE') {
      referenceChange('config', []);
    } else if (value === 'SPECIFIC' || value === 'SPECIFIC_LOCK') {
      referenceChange('config', new Array(limit > empCount ? limit : empCount).fill(0));
    } else {
      referenceChange('config', limit > empCount ? limit : empCount);
    }
  };

  const handleConfigChange = (value, index) => {
    let dummy = [...values.config];
    dummy[index] = value;
    referenceChange('config', dummy);
  };

  let totalRefCount =
    values.config && values.config.length ? values.config.reduce((a, b) => a + b, 0) : 0;

  return (
    <>
      <div className={classes.root}>
        <Grid className={classes.root} item container>
          <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
            <Typography variant='h6' style={{ fontSize: '18px' }} gutterBottom>
              <span style={{ marginRight: '10px' }} title={'reference'}>
                <FontAwesomeIcon
                  icon={
                    PLAIN_CHECK_ICONS['reference'] ? PLAIN_CHECK_ICONS['reference'].icon : faBan
                  }
                  color='#222'
                />
              </span>
              {'Reference'}
              {!limit ? (
                <span style={{ marginRight: '10px', fontSize: '12px', color: '#312c51' }}>
                  <i>{` (*Not Selected)`}</i>
                </span>
              ) : null}
            </Typography>
          </Grid>
          <Grid
            item
            container
            spacing={1}
            xl={12}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            style={{ marginTop: '12px', marginBottom: '24px' }}
          >
            <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
              <FormControl component='fieldset' disabled={limit === 0 || isUnassigned}>
                {/* <FormLabel component='legend'>labelPlacement</FormLabel> */}
                <RadioGroup
                  row
                  aria-label='type'
                  name='type'
                  value={values.type}
                  onChange={(e) => {
                    handleTypeChange(e.target.value);
                  }}
                  disabled={true}
                >
                  <FormControlLabel
                    value='TOTAL'
                    control={<Radio color='primary' />}
                    label='CA config'
                    labelPlacement='end'
                  />
                  <FormControlLabel
                    value='SPECIFIC'
                    control={<Radio color='primary' />}
                    label='Pre-set'
                    labelPlacement='end'
                  />
                  <FormControlLabel
                    value='SPECIFIC_LOCK'
                    control={<Radio color='primary' />}
                    label='Pre-set Lock'
                    labelPlacement='end'
                  />
                  <FormControlLabel
                    value='NONE'
                    control={<Radio color='primary' />}
                    label='None'
                    labelPlacement='end'
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {['TOTAL', 'SPECIFIC', 'SPECIFIC_LOCK'].includes(values.type) ?
              <>
                <Grid item container spacing={1} xl={12} md={12} lg={12} sm={12} xs={12}>
                  {values.type === 'SPECIFIC' || values.type === 'SPECIFIC_LOCK' ?
                    <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                      <Typography
                        variant='subtitle2'
                        gutterBottom
                      >{`Choose ${values.count} references`}</Typography>
                    </Grid>
                    : null}
                  {values.type === 'TOTAL' ?
                    <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                      <Typography
                        variant='subtitle2'
                        gutterBottom
                      >Total References: {limit}</Typography>
                    </Grid>
                    : null}
                  {(values.type === 'SPECIFIC' || values.type === 'SPECIFIC_LOCK') && values?.config?.map((value, index) => {
                    return (
                      <Grid
                        item
                        xl={12}
                        md={12}
                        lg={12}
                        sm={12}
                        xs={12}
                        key={`incrementGridCount_${index}`}
                      >
                        <Typography
                          variant='body2'
                          component='span'
                          style={{ marginRight: '12px' }}
                        >
                          <i>{`${index === 0 ? 'Last' : `${index + 1} Last`} Employment`}
                            {index + 1 > empCount ? '(Only Ref Check)' : null} :</i>
                        </Typography>

                        <IncrementCounter
                          limit={limit}
                          totalCount={totalRefCount}
                          value={value}
                          index={index}
                          disabled={values.type === 'TOTAL'}
                          valueChange={(value, index) => {
                            handleConfigChange(value, index);
                          }}
                          isUnassigned={isUnassigned}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </> : null}
            {/* )} */}
          </Grid>
        </Grid>
      </div>
    </>
  );
};
