import { connect } from 'react-redux';
import { compose } from 'redux';
import { isEmpty } from 'lodash';

import {
  getAddCandidateConfig as getAddCandidateConfigAPI,
  invalidateAddCandidateConfig as invalidateAddCandidateConfigAPI,
  getCompanyById
} from '../../../actions/admin';

const mapStateToProps = (state) => {
  return {
    addCandidateConfig:
      state.addCandidateConfig && !isEmpty(state.addCandidateConfig)
        ? state.addCandidateConfig
        : null,
    companyDetails: state.updateCompanyData ? state.updateCompanyData : {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invalidateAddCandidateConfig: () => dispatch(invalidateAddCandidateConfigAPI()),
    getAddCandidateConfig: (companyId) => dispatch(getAddCandidateConfigAPI(companyId)),
    getCompanyById: (companyId) => dispatch(getCompanyById(companyId))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
