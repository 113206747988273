import {
  TextField,
  DialogTitle,
  Grid,
  Dialog,
  Button,
  Typography,
  TextareaAutosize,
  InputAdornment,
  Checkbox
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { WarningOutlined, CheckCircleOutline } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import styles from './GSTModal.module.scss';
import EditIcon from '@material-ui/icons/EditOutlined';
import moment from 'moment';
import { Field } from 'formik';
import FuzzySet from 'fuzzyset';
import { fetchGstin } from '../../../../../../api/admin';
import { isEmpty } from 'lodash';
import {
  errToastMessage,
  getErrorMessage,
  getTrimmedValue
} from '../../../../../../utils/Utlities';
const DIALOG_TITLE = 'GSTIN Setup';

export default (props) => {
  const { open, handleSubmit, onClose, errors, values, dirty, setFieldValue, handleChange } = props;
  /** State Variables */
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [isFetched, setIsFetched] = useState({
    loading: false,
    fetched: false,
    fetchTime: null
  });
  const [isGstIdFieldDisabled, setIsGstIdFieldDisabled] = useState(props.gstId ? true : false);
  const [isGstNotRegistered, setIsGstNotRegistered] = useState(values['isGstNotRegistered']);
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false); // Added state for tracking button click

  useEffect(() => {
    setIsSaveButtonDisabled(isSaveButtonClicked);
  }, [isSaveButtonClicked]);

  const handleSaveClick = () => {
    setIsSaveButtonClicked(true);
    handleSubmit();
  };



  useEffect(() => {
    setIsGstIdFieldDisabled(isGstNotRegistered || props.gstId);
    setIsSaveButtonDisabled(!isGstNotRegistered);

    if (isGstNotRegistered) {
      resetGstFieldValues();
      setIsFetched({
        loading: false,
        fetched: false,
        fetchTime: null
      });
    }
  }, [isGstNotRegistered]);

  useEffect(() => {
    if (isFetched.fetched) {
      setIsSaveButtonDisabled(!isMismatchExists());
    }
  }, [values]);

  useEffect(() => {
    setFieldValue('gstId', values.gstId.toUpperCase());
  }, [values.gstId]);
  /** Methods */
  const isFetchButtonDisabled = () => {
    return isFetched.loading || isGstNotRegistered || isEmpty(values.gstId) || !isEmpty(errors);
  };

  const resetGstFieldValues = () => {
    setFieldValue('gstId', '');
    setFieldValue('tax', 18);
    setFieldValue('gstLegalName', '');
    setFieldValue('gstTradeName', '');
    setFieldValue('gstState', '');
    setFieldValue('gstAddress', '');
  };

  const fuzzyMatch = (gstName, svName) => {
    var fuzzyMatchInstance = new FuzzySet();
    var matchResult = 0;
    fuzzyMatchInstance.add(svName);
    matchResult =
      fuzzyMatchInstance.get(gstName, '', 0.01) &&
      fuzzyMatchInstance.get(gstName, '', 0.01)[0][0] * 100;

    switch (true) {
      case matchResult === 100:
        return 100;
      case matchResult < 25:
        return 0;
      default:
        return 25;
    }
  };
  const handleKeyDown = (e) => {
    // Prevent input of spaces
    if (e.key === ' ') {
      e.preventDefault();
    }
  };

  const isMismatchExists = () => {
    const isCompanyNameMatched = getMatchScoreOfField('gstLegalName') === 0 ? false : true;
    const isTradeNameMatched = getMatchScoreOfField('gstTradeName') === 0 ? false : true;
    const isStateMatched = getMatchScoreOfField('gstState') === 0 ? false : true;

    return (isCompanyNameMatched || isTradeNameMatched) && isStateMatched;
  };

  const getMatchScoreOfField = (gstFieldName) => {
    let gstFieldValue, svFieldValue;

    if (gstFieldName !== 'gstState') {
      gstFieldValue = values[gstFieldName];
      svFieldValue =
        gstFieldName === 'gstLegalName' ? values['svCompanyName'] : values['svTradeCompanyName'];

      return fuzzyMatch(gstFieldValue?.toLowerCase()?.trim(), svFieldValue?.toLowerCase()?.trim());
    } else {
      gstFieldValue = values['gstState']?.toLowerCase()?.trim();
      svFieldValue = values['svState']?.toLowerCase()?.trim();

      return gstFieldValue === svFieldValue ? 100 : 0;
    }
  };

  const handleClose = () => {
    onClose(false);
  };

  const getCheckIcon = (fieldName) => {
    if (!isFetched.fetched || isFetched.loading) return null;
    const matchResult = getMatchScoreOfField(fieldName);

    switch (true) {
      case matchResult === 100:
        return <CheckCircleOutline style={{ color: '#52C41A', fontSize: '30px' }} />;
      case matchResult === 0:
        return <WarningOutlined style={{ color: 'red', fontSize: '30px' }} />;
      case matchResult === 25:
        return <WarningOutlined style={{ color: '#E8833A', fontSize: '30px' }} />;
      default:
        return null;
    }
  };

  const getErrorText = (fieldName) => {
    if (!isFetched.fetched) return null;

    const matchResult = getMatchScoreOfField(fieldName);

    const mismatchText =
      fieldName === 'gstState'
        ? 'Mismatch. Needs to be an exact match.'
        : 'Re-check. Low match score.';

    switch (true) {
      case matchResult === 0:
        return <p style={{ color: 'red' }}> {mismatchText} </p>;
      case matchResult === 25:
        return <p style={{ color: '#E8833A' }}> Careful. Name is not an exact match. </p>;
      default:
        return null;
    }
  };
  const getGstinPrincipalAddress = (principalAddress) => {
    return `${principalAddress.city ?? ''} ${principalAddress.district ?? ''} ${principalAddress.floor_number ?? ''
      } ${principalAddress.location ?? ''} ${principalAddress.pincode ?? ''} ${principalAddress.street ?? ''
      }`;
  };

  /** API call */
  const fetchGstDetails = () => {
    setIsFetched({
      loading: true,
      fetched: false,
      fetchTime: null
    });
    fetchGstin(values.gstId)
      .then((res) => {
        setIsFetched({
          fetched: true,
          fetchTime: moment().format('MMM Do YYYY, h:mm:ss a')
        });
        setIsGstIdFieldDisabled(true);
        if (
          res.data.data.GST_payer_type === 'SEZ Unit' ||
          res.data.data.GST_payer_type === 'SEZ Developer'
        ) {
          setFieldValue('tax', 0);
        } else {
          setFieldValue('tax', 18);
        }
        setFieldValue('gstLegalName', res.data.data.legal_name);
        setFieldValue('gstTradeName', res.data.data.trade_name);
        setFieldValue('gstState', res.data.data.state);
        setFieldValue('gstAddress', getGstinPrincipalAddress(res.data.data.principal_address));
        setIsFetched({
          loading: false,
          fetched: true,
          fetchTime: moment().format('MMM Do YYYY, h:mm:ss a')
        });
      })
      .catch((error) => {
        setIsFetched({
          loading: false,
          fetched: false,
          fetchTime: null
        });
        setIsGstIdFieldDisabled(true);
        errToastMessage(error);
      });
  };

  /** Body Components */
  const checkBoxField = () => {
    return (
      <div className={styles.gstCheckbox}>
        <Field
          component={Checkbox}
          disabled={isFetched.loading}
          checked={isGstNotRegistered}
          onChange={() => {
            setIsGstNotRegistered(!isGstNotRegistered);
          }}
        />
        <div> {' Organisation is not a registered entity'}</div>
      </div>
    );
  };

  const gstInputField = () => {
    return (
      <div className={styles.formField}>
        <label className={styles.fieldLabel}>
          GSTIN <span style={{ color: 'red' }}>*</span>
        </label>
        <TextField
          disabled={isGstIdFieldDisabled || isFetched.loading}
          variant='outlined'
          size='small'
          value={values?.gstId?.trim()}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          name='gstId'
          InputProps={{
            endAdornment:
              isGstIdFieldDisabled && !isGstNotRegistered ? (
                <InputAdornment position='end'>
                  <EditIcon
                    onClick={() => {
                      setIsSaveButtonDisabled(true);
                      setIsGstIdFieldDisabled(false);
                    }}
                    style={{ fontSize: '16px', color: '#000000', cursor: 'pointer' }}
                  />
                </InputAdornment>
              ) : null
          }}
          onBlur={() => {
            const trimmedValue = getTrimmedValue(values?.gstId, false);
            handleChange({ target: { name: 'gstId', value: trimmedValue || '' } });
          }}
        />

        {errors['gstId'] && <span className={styles.errors}>{errors['gstId']}</span>}
      </div>
    );
  };

  const taxField = () => {
    return (
      <div className={styles.formField}>
        <label className={styles.fieldLabel}>Tax</label>
        <TextField
          variant='outlined'
          size='small'
          name={'tax'}
          value={values.tax}
          disabled
          InputProps={{
            endAdornment: <InputAdornment position='end'>%</InputAdornment>
          }}
        />
      </div>
    );
  };

  const fetchButton = () => {
    return (
      <div className={styles.fetchBlock}>
        <Button
          variant='contained'
          color='primary'
          className={`${styles.fetchBtn} ${isFetched.loading ? styles.loading : ''}`}
          disabled={isFetchButtonDisabled()}
          onClick={fetchGstDetails}
        >
          Fetch
        </Button>
        <div className={styles.lastFetchedText}>
          {isFetched.fetched && (
            <>
              <Typography variant='caption' display='block'>
                Last fetched:
              </Typography>
              <Typography variant='caption' display='block'>
                {isFetched.fetchTime}
              </Typography>
            </>
          )}
        </div>
      </div>
    );
  };

  const getDataRowItem = (gstLabel, svLabel, gstFieldName, svFieldName) => {
    return (
      <div className={styles.fieldInline}>
        <Grid item xs={6}>
          <div className={styles.formField}>
            <label className={styles.fieldLabel}>{gstLabel}</label>
            <TextField
              variant='outlined'
              size='small'
              name={gstFieldName}
              value={values[gstFieldName]}
              disabled
              helperText={getErrorText(gstFieldName)}
            />
          </div>
        </Grid>
        <div
          className={`${styles.indication} ${getErrorText(gstFieldName) ? styles.indicationError : styles.indicationSuccess
            }`}
        >
          {getCheckIcon(gstFieldName)}
        </div>
        <Grid item xs={6}>
          <div className={styles.formField}>
            <label className={styles.fieldLabel}>{svLabel}</label>
            <TextField
              variant='outlined'
              size='small'
              name={svFieldName}
              value={values[svFieldName]}
              disabled
            />
          </div>
        </Grid>
      </div>
    );
  };

  const footerSection = () => {
    return (
      <div className={styles.footerBtn}>
        <div style={{ width: '56%', textAlign: '-webkit-right' }}>
          <Button variant='contained' className={styles.cancelBtn} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={isSaveButtonDisabled || !dirty}
            variant='contained'
            color={'primary'}
            className={styles.fetchBtn}
            onClick={handleSaveClick}
          >
            Save
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      direction='row'
      fullWidth={true}
      maxWidth='md'
      disableBackdropClick={true}
    >
      <DialogTitle>{DIALOG_TITLE}</DialogTitle>
      <form>
        <Grid item xs={12}>
          {checkBoxField()}
        </Grid>
        <div className={styles.dialogForm}>
          <Grid container sm={6} className={styles.firstGrid}>
            <Grid item sm={8}>
              {gstInputField()}
            </Grid>
            <Grid item sm={4} style={{ paddingLeft: '.2rem' }}>
              {taxField()}
            </Grid>
          </Grid>
          <Grid item svCompanyName={6} className={styles.secondGrid}>
            {fetchButton()}
          </Grid>
        </div>
        <div className={styles.dialogForm}>
          <Grid item xs={6} className={styles.firstGrid}>
            <span className={styles.formHeading}>Data as per GST Department</span>
          </Grid>
          <Grid item xs={6} className={styles.secondGrid}>
            <span className={styles.formHeading}>Data on SpringVerify</span>
          </Grid>
        </div>
        <div className={styles.formGroup}>
          {getDataRowItem('Legal Name', 'Company Name', 'gstLegalName', 'svCompanyName')}
          {getDataRowItem('Trade Name', 'Company Name', 'gstTradeName', 'svTradeCompanyName')}
          {getDataRowItem('State', 'State', 'gstState', 'svState')}
          <div className={styles.fieldInline}>
            <Grid item xs={6}>
              <div className={styles.formField}>
                <label className={styles.fieldLabel}>Principal Address</label>
                <TextareaAutosize
                  rowsMin={4}
                  aria-label='maximum height'
                  disabled
                  name='gstAddress'
                  value={values.gstAddress}
                />
              </div>
            </Grid>
            <div className={styles.indication}></div>
            <Grid item xs={6}>
              <div className={styles.formField}>
                <label className={styles.fieldLabel}>Address</label>
                <TextareaAutosize
                  rowsMin={4}
                  aria-label='maximum height'
                  name='svAddress'
                  value={values.svAddress}
                  disabled
                />
              </div>
            </Grid>
          </div>
        </div>
        {footerSection()}
      </form>
    </Dialog>
  );
};
