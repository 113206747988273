import moment from 'moment';
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Dialog,
  Typography,
  IconButton,
  TextField,
  InputLabel,
  FormHelperText,
  Button,
  Box
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { isEmpty } from 'lodash';
import { Done } from '@material-ui/icons';
import { Field } from 'formik';

import Dropdown from '../../../../../../core-components/DropdownInput';
import FileUpload from '../../../../../../core-components/FileUploadToS3';
import {
  TRANSACTION_TYPE,
  RECHARGE,
  CREDIT_REFUND
} from '../../../../../../core-components/CommonConstants';
import { renderSecondRow, renderThirdRow } from './UpdateBalanceUtils';
import FormInput from '../../../../../../core-components/FormInput';
import { getInvoiceData } from '../../../../../../api/admin';
import { toast } from 'react-toastify';
import {
  convertToIndianNumeration,
  errToastMessage,
  getErrorMessage,
  getTrimmedValue,
  toCamelCase
} from '../../../../../../utils/Utlities';
import {
  INVOICE_DATA_STATUSES as InvoiceDataStatus,
  PAYMENT_MODE
} from '../../../CreditCompany/Shared/commonUtils';
import FuzzySet from 'fuzzyset';

let dataOnSv = {
  companyName: '',
  tax: '',
  tds: ''
};

const useStyles = makeStyles((theme) => ({
  disabledField: { background: '#F5F4F4' },
  greenColorText: { color: '#1AAE9F' },
  redColorText: { color: '#ff0000' },
  orangeColorText: { color: 'orange' },
  errorMessageRed: {
    color: 'red'
  },
  rupeeSymbol: {
    fontFamily: 'Roboto'
  },
  errorMessageYellow: {
    color: 'orange'
  },
  customAmountInputBox: {
    padding: '15px',
    border: '1px solid #B6B5B5',
    borderRadius: '4px',
    background: '#F5F4F4',
    height: '40px'
  },
  customTdsDropdown: {
    background: '#F5F4F4',
    height: '40px'
  },
  inlineErrorMessageText: { color: 'red' },
  summaryBox: { paddingTop: '10px', width: '100%' },
  summaryFontNumbers: { fontSize: '17px', textAlign: 'end' },
  summaryFont: { fontSize: '17px' },
  boldFontNumbers: { fontWeight: '700', fontSize: '17px', textAlign: 'end' },
  boldFont: { fontWeight: '700', fontSize: '17px' },
  viewInvoiceButtonDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '31px'
  },
  viewInvoiceButton: {
    width: '80%'
  },
  invoiceDataText: {
    fontSize: '20px',
    fontWeight: '600'
  },
  fetchButton: {
    marginTop: '30px',
    cursor: 'pointer'
  },
  fetchStatusDiv: {
    display: 'flex',
    position: 'relative'
  },
  active: {
    background: 'blue'
  },
  inactive: { background: 'grey' },
  lstFetchText: {
    color: 'grey',
    fontSize: '15px'
  },
  headerLeftBox: {
    justifyContent: 'end',
    paddingRight: '20px'
  },
  widthControlDiv: {
    maxWidth: '80%'
  },
  doneIcon: {
    height: '20px',
    color: 'green'
  },
  leftHeaderContent: {
    color: 'grey',
    padding: '10px',
    fontSize: '15px'
  },
  companyName: {
    paddingInline: '16px',
    color: 'grey'
  },
  label: {
    margin: '10px 0 5px',
    color: '#333333',
    fontSize: '15px'
  },
  dropdownError: {
    color: '#f44336',
    margin: '4px 14px 0'
  },
  activeBtn: {
    width: '100%',
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  refundActiveBtn: {
    width: '100%',
    color: '#ffffff',
    borderColor: '#e8833a',
    backgroundColor: '#e8833a',
    fontSize: 14,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#db6d1d'
    }
  },
  rowGap: {
    marginTop: '8px'
  },
  requiredField: {
    color: 'red'
  },
  green: {
    color: '#1AAE9F',
    border: '#1AAE9F 1px solid'
  },
  errMsg: {
    color: '#f44336',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: 'Poppins',
    fontWeight: 400,
    lineHeight: 1.66
  },
  blue: {
    color: '#0000ff',
    border: '#0000ff 1px solid'
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, flow, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h5'>
        {flow === 'PENDING_ORDER' ? 'Mark as Paid' : 'Update Balance'}
      </Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
    paddingTop: 2
  }
}))(MuiDialogActions);

function UpdateBalance(props) {
  const classes = useStyles();

  const {
    values,
    setFieldValue,
    errors,
    handleSubmit,
    resetForm,
    setErrors,
    touched,
    handleBlur,
    handleChange,
    id,
    companyCreditBalance
  } = props;
  console.log('errors: ', errors);

  const [uploadModal, setUploadModal] = useState(false);
  const [fileName, setFileName] = useState('');

  {
    /* State variables strictly used for Purchase(Recharge) transaction type */
  }
  {
    /* Do not create dependency for other transaction types */
  }
  const [isFetched, setIsFetched] = useState({ loading: false, value: false });
  const [lastFetchedTime, setLastFetchedTime] = useState('');
  const [isZohoConnected, setIsZohoConnected] = useState(true);
  const [invoiceDataStatus, setInvoiceDataStatus] = useState(InvoiceDataStatus.NOT_FETCHED);
  const [invoiceData, setInvoiceData] = useState({});
  const [errorOnFields, setErrorOnFields] = useState({
    companyName: { value: '', color: '' },
    tax: '',
    tds: '',
    isAllfieldsPresent: true
  });

  useEffect(() => {
    // This condition is used for when we open UpdateBalance modal from PendingOrders.jsx file
    if (id === 'PENDING_ORDER') {
      setFieldValue('tag', 'RECHARGE');
    }
  }, []);

  useEffect(() => {
    if (invoiceDataStatus === InvoiceDataStatus.SUCCESS) {
      const dataFromInvoice = {
        companyName: invoiceData?.companyName,
        tax: invoiceData?.taxPercent,
        tds: invoiceData?.tdsPercent
      };

      applyMatchingCriteria(dataFromInvoice);
    }
  }, [invoiceData]);

  useEffect(() => {
    const zohoConnectionStatus = getZohoBookConnectionStatus(props?.companyDetails);
    let tds;

    switch (props?.companyDetails?.tds) {
      case 'BOTH':
        tds = 'BOTH';
        break;
      case 'NONE':
        tds = 'NONE';
        break;
      default:
        tds = parseInt(props?.companyDetails?.tds);
        break;
    }

    dataOnSv = {
      companyName: props?.companyName,
      tax: parseInt(props?.tax),
      tds: tds
    };

    setIsZohoConnected(zohoConnectionStatus);
  }, [props]);

  {
    /* On click handlers */
  }

  const handleFetchInvoiceDetails = () => {
    //*! Only fetch when there is a invoice number
    setIsFetched({ loading: true, value: false });
    resetErrorFields();

    if (values.invoiceNumber && !isFetched.loading) {
      resetInvoiceFieldValues();

      const currentTime = moment().format('LT');
      let payload = {
        invoiceNumber: values.invoiceNumber
      };

      getInvoiceData(payload)
        .then((resp) => {
          const respData = toCamelCase(resp?.data);
          setIsFetched({ loading: false, value: true });
          setLastFetchedTime(currentTime);
          setInvoiceDataStatus(InvoiceDataStatus.SUCCESS);
          setInvoiceData(respData);
          setInvoiceFieldValues(respData);
        })
        .catch((err) => {
          const msg = getErrorMessage(err);

          switch (msg) {
            case 'Invoice not found':
              setInvoiceDataStatus(InvoiceDataStatus.NOT_FOUND);
              break;
            case `Invoice is not yet marked as 'paid'`:
              setInvoiceDataStatus(InvoiceDataStatus.NOT_PAID);
              break;
          }
          setIsFetched({ loading: false, value: false });

          errToastMessage(msg);
        });
    }
  };

  // handling file upload
  const handleFileUpload = () => {
    setUploadModal(!uploadModal);
  };

  // on transaction dropdwon change setFields value to empty
  const handleTransactionTypeChange = () => {
    setIsFetched({ loading: false, value: false });
    setInvoiceDataStatus(InvoiceDataStatus.NOT_FETCHED);
    setInvoiceData({});
    resetForm();
    setErrors({});
  };

  // form submit handler
  const handleOnSubmit = (e) => {
    e.preventDefault();
    setFieldValue('disabled', true);
    handleSubmit();
  };

  const handleOnClickViewInvoiceButton = () => {
    if (invoiceDataStatus === InvoiceDataStatus.SUCCESS) {
      if (values?.invoiceUrl) {
        window.open(values.invoiceUrl, '_blank');
      }
    }
  };

  {
    /* Methods */
  }

  const getValid = () => {
    if (isTransactionTypeRecharge(values?.tag)) {
      if (!isZohoConnected || !isFetched.value || isFieldError(errorOnFields)) {
        return true;
      }
      return false;
    }
    if (isTransactionTypeRefundCustomer(values?.tag)) {
      if (
        !isZohoConnected ||
        !values?.amount ||
        values?.tax === null ||
        !getTrimmedValue(values?.additionalInfo, true) ||
        getTrimmedValue(values?.additionalInfo, true) === '' ||
        parseInt(companyCreditBalance.excessBridgeCredits) !== 0
      ) {
        return true;
      }
      return false;
    }
    if (!values?.tag || values.disabled) {
      return true;
    }
  };

  const getGstStatus = () => {
    //*! Get details from API or state
    //** Either return the icon or '-' */
    let value = props?.companyDetails?.gstin;
    if (value) {
      return <Done className={classes.doneIcon} />;
    } else {
      return `None`;
    }
  };

  const getHeaderTaxPercentage = () => {
    //*! Get details from API or state
    let value = props?.tax ? props.tax : '0';

    return value;
  };

  const getHeaderTdsPercentages = () => {
    //*! Get details from API or state
    let value = props?.companyDetails?.tds;

    switch (value) {
      case 'NONE': {
        value = 'NONE';
        break;
      }
      case 'BOTH': {
        value = '2% & 10%';
        break;
      }
      default:
        value += '%';
    }

    return value;
  };

  const getHeaderCompanyName = () => {
    let value = props?.companyName;

    return value ? value : '';
  };

  const getLastFetchedTime = () => {
    let value = lastFetchedTime;

    return value;
  };

  const getZohoBookConnectionStatus = (companyDetails) => {
    const zohoBookID = companyDetails?.zohoBooksContactId;

    if (zohoBookID) return true;

    return false;
  };

  const isTransactionTypeRecharge = (value) => {
    return value === 'RECHARGE';
  };
  const isTransactionTypeRefundCustomer = (value) => {
    return value === 'CREDIT_REFUND';
  };

  const getClassForRecharge = (tds, invoiceDataStatus) => {
    return !isFieldError(errorOnFields) &&
      invoiceDataStatus === InvoiceDataStatus.SUCCESS &&
      !isFetched.loading &&
      tds !== 0
      ? classes.greenColorText
      : '';
  };
  const getClassForRefund = () => {
    return values?.amount > 0 && isZohoConnected && values?.tax ? classes.orangeColorText : '';
  };
  const setInvoiceFieldValues = (data) => {
    setFieldValue('companyName', data.companyName);
    setFieldValue('invoiceUrl', data.invoiceUrl);
    setFieldValue('invoiceDate', data.invoiceDate);
    if (data.proformaNumber) setFieldValue('proformaNumber', data.proformaNumber);
    setFieldValue('paymentMode', PAYMENT_MODE[data.paymentMode]);
    setFieldValue('paymentDate', data.paymentDate);
    setFieldValue('paymentReference', data.paymentReferenceNumber);
    setFieldValue('amount', data.amount);
    setFieldValue('tax', data.taxPercent);

    if (data.tdsPercent) {
      setFieldValue('tds', data.tdsPercent);
    } else {
      setFieldValue('tds', 0);
    }
  };

  const resetInvoiceFieldValues = () => {
    setFieldValue('companyName', '');
    setFieldValue('invoiceUrl', '');
    setFieldValue('invoiceDate', '');
    setFieldValue('proformaNumber', '');
    setFieldValue('paymentMode', '');
    setFieldValue('paymentDate', '');
    setFieldValue('paymentReference', '');
    setFieldValue('amount', '');
    setFieldValue('tax', '');
    setFieldValue('tds', '');
  };

  const isFieldError = (errorObject) => {
    let res = false;

    if (!errorObject.isAllfieldsPresent) {
      res = true;
    }

    if (!isEmpty(errorObject.tax) || !isEmpty(errorObject.tds)) {
      res = true;
    }

    if (!isEmpty(errorObject.companyName.value) && errorObject.companyName.color === 'red') {
      res = true;
    }

    return res;
  };

  const applyMatchingCriteria = (invoiceData) => {
    let errors = {
      companyName: { value: '', color: '' },
      tax: '',
      tds: '',
      isAllfieldsPresent: true
    };

    if (invoiceDataStatus === InvoiceDataStatus.SUCCESS) {
      const isMandatoryFieldsPresent = isAllInvoiceFieldsArePresent();

      if (dataOnSv.tax !== invoiceData.tax) {
        errors.tax = `Mismatch (SV: ${dataOnSv.tax}%)`;
      }

      switch (dataOnSv.tds) {
        case 'BOTH': {
          errors.tds = '';
          break;
        }
        case 'NONE': {
          if (invoiceData.tds !== 0 && invoiceData.tds !== null) {
            errors.tds = `Mismatch (SV: NONE)`;
          }
          break;
        }
        case 2: {
          if (invoiceData.tds === 10) {
            errors.tds = `Mismatch (SV: 2%)`;
          }
          break;
        }
        case 10: {
          if (invoiceData.tds === 2) {
            errors.tds = `Mismatch (SV: 10%)`;
          }
          break;
        }
        default:
          errors.tds = '';
      }

      if (dataOnSv.companyName !== invoiceData.companyName) {
        const res = applyFuzzyMatch(dataOnSv.companyName, invoiceData.companyName);

        switch (res) {
          case 0: {
            errors.companyName.value = 'Re-check. Low match score';
            errors.companyName.color = 'red';
            break;
          }
          case 25: {
            errors.companyName.value = 'Careful. Name is not an exact match.';
            errors.companyName.color = 'yellow';
            break;
          }
          case 100: {
            errors.companyName.value = '';
            break;
          }
        }
      }

      if (!isMandatoryFieldsPresent) {
        errors.isAllfieldsPresent = false;
      }
    }
    setErrorOnFields(errors);
  };

  const isAllInvoiceFieldsArePresent = () => {
    const data = invoiceData;
    let res = true;

    if (
      isEmpty(data.invoiceUrl) ||
      isEmpty(data.invoiceDate) ||
      isEmpty(data.paymentMode) ||
      isEmpty(data.paymentDate) ||
      isEmpty(data.paymentReferenceNumber) ||
      !data.totalAmount
    ) {
      res = false;
    }
    return res;
  };

  const applyFuzzyMatch = (valueOne, valueTwo) => {
    var fuzzyMatchInstance = new FuzzySet();
    var matchResult = 0;

    fuzzyMatchInstance.add(valueOne);

    matchResult =
      fuzzyMatchInstance.get(valueTwo, '', 0.01) &&
      fuzzyMatchInstance.get(valueTwo, '', 0.01)[0][0] * 100;

    switch (true) {
      case matchResult < 25: {
        return 0;
      }
      case matchResult === 100: {
        return 100;
      }
      default:
        return 25;
    }
  };

  const resetErrorFields = () => {
    let errors = {
      companyName: { value: '', color: '' },
      tax: '',
      tds: '',
      isAllfieldsPresent: true
    };

    setErrorOnFields(errors);
  };

  {
    /** UI components */
  }

  const additionalInfoCommentBox = () => {
    return values?.tag ? (
      <Grid item lg={8} md={8} sm={8} xs={12}>
        <InputLabel className={classes.label}>
          Additional Info (Public Comment)
          {values?.tag !== 'RECHARGE' && <span className={classes.requiredField}>*</span>}
        </InputLabel>
        <TextField
          className={
            isTransactionTypeRecharge(values?.tag) &&
            (invoiceDataStatus !== InvoiceDataStatus.SUCCESS ||
              isFetched.loading ||
              isFieldError(errorOnFields))
              ? classes.disabledField
              : null
          }
          disabled={
            (isTransactionTypeRecharge(values?.tag) &&
              (invoiceDataStatus !== InvoiceDataStatus.SUCCESS ||
                isFetched.loading ||
                isFieldError(errorOnFields))) ||
            (isTransactionTypeRefundCustomer(values?.tag) && !isZohoConnected)
          }
          name='additionalInfo'
          multiline
          fullWidth={true}
          rows={4}
          value={values.additionalInfo}
          variant='outlined'
          onChange={handleChange}
          onBlur={(event) => {
            const trimmedValue = getTrimmedValue(event.target.value, true);
            setFieldValue('additionalInfo', trimmedValue);
            handleBlur(event);
          }}
          required={isTransactionTypeRecharge(values?.tag) ? false : true}
        />
        {touched?.additionalInfo && errors?.additionalInfo && (
          <FormHelperText className={classes.dropdownError}>
            {errors?.additionalInfo}
          </FormHelperText>
        )}
      </Grid>
    ) : null;
  };

  const summaryBoxForRecharge = (status) => {
    let amount, tax, tds, total, netTotal;

    if (!isFetched.value) {
      amount = tax = tds = total = netTotal = '';
    }

    if (isTransactionTypeRecharge(values?.tag) && status === InvoiceDataStatus.SUCCESS) {
      tax = invoiceData.taxAmount || 0;
      tds = invoiceData.tdsAmount || 0;
      amount = invoiceData.amount || 0;

      total = amount + tax;
      netTotal = total - tds;

      tax = parseFloat(tax).toFixed(2);
      amount = parseFloat(amount).toFixed(2);
      tds = parseFloat(tds).toFixed(2);
      netTotal = parseFloat(netTotal).toFixed(2);
      total = parseFloat(total).toFixed(2);
    } else {
      if (values?.amount && !isNaN(values?.amount)) {
        tax = parseFloat((parseFloat(values?.amount) * parseFloat(values?.tax)) / 100).toFixed(2);
        amount = parseFloat(values?.amount).toFixed(2);
        total = parseFloat(parseFloat(amount) + parseFloat(tax)).toFixed(2);
        netTotal = total;
      }
    }

    return (
      <Grid item container lg={4} md={4} sm={4} xs={12}>
        <Box className={classes.summaryBox}>
          <Grid container item lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <div className={`${classes.summaryFont}`}>Amount:</div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <div className={`${classes.summaryFontNumbers}`}>
                {!isFetched.loading && amount && (
                  <span>
                    <span className={classes.rupeeSymbol}>&#8377;</span>
                    {convertToIndianNumeration(parseInt(amount))}
                  </span>
                )}
              </div>
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <div className={`${classes.summaryFont}`}>Tax:</div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <div className={`${classes.summaryFontNumbers}`}>
                {!isFetched.loading && tax && (
                  <span>
                    <span className={classes.rupeeSymbol}>&#8377;</span>
                    {convertToIndianNumeration(parseInt(tax))}
                  </span>
                )}
              </div>
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <div className={`${classes.boldFont}`}>Total:</div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <div className={`${classes.boldFontNumbers}`}>
                {!isFetched.loading && total && (
                  <span>
                    <span className={classes.rupeeSymbol}>&#8377;</span>
                    {convertToIndianNumeration(parseInt(total))}
                  </span>
                )}
              </div>
            </Grid>
          </Grid>
          {isTransactionTypeRecharge(values?.tag) && (
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <div
                  className={
                    !isFieldError(errorOnFields) &&
                    invoiceDataStatus === InvoiceDataStatus.SUCCESS &&
                    !isFetched.loading &&
                    invoiceData.tdsPercent !== null
                      ? `${classes.summaryFont} ${classes.greenColorText}`
                      : `${classes.summaryFont}`
                  }
                >
                  TDS:
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <div
                  className={
                    !isFieldError(errorOnFields) &&
                    invoiceDataStatus === InvoiceDataStatus.SUCCESS &&
                    invoiceData.tdsPercent !== null
                      ? `${classes.summaryFontNumbers} ${classes.greenColorText}`
                      : `${classes.summaryFontNumbers}`
                  }
                >
                  {!isFetched.loading && tds && (
                    <span>
                      <span className={classes.rupeeSymbol}>&#8377;</span>
                      {convertToIndianNumeration(parseInt(tds))}
                    </span>
                  )}
                </div>
              </Grid>
            </Grid>
          )}

          <Grid container item lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <div
                className={`${classes.boldFont} 
                  ${
                    isTransactionTypeRecharge(values?.tag)
                      ? getClassForRecharge(tds, invoiceDataStatus)
                      : getClassForRefund()
                  }`}
              >
                Net Total:
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <div
                className={`${classes.boldFontNumbers} 
                  ${
                    isTransactionTypeRecharge(values?.tag)
                      ? getClassForRecharge(tds, invoiceDataStatus)
                      : getClassForRefund()
                  }`}
              >
                {!isFetched.loading && netTotal && (
                  <span>
                    <span className={classes.rupeeSymbol}>&#8377;</span>
                    {convertToIndianNumeration(parseInt(netTotal))}
                  </span>
                )}
              </div>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    );
  };

  const dialogHeaderContent = () => {
    return (
      <Grid container>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <p className={classes.companyName}>{getHeaderCompanyName()}</p>
        </Grid>
        <Grid className={classes.headerLeftBox} item container lg={4} md={4} sm={12} xs={12}>
          <div className={classes.leftHeaderContent}>GST: {getGstStatus()}</div>
          <div className={classes.leftHeaderContent}>Tax: {getHeaderTaxPercentage()}%</div>
          <div className={classes.leftHeaderContent}>TDS: {getHeaderTdsPercentages()}</div>
        </Grid>
      </Grid>
    );
  };

  const inlineErrorMessageComponent = (status) => {
    const textForZohoNotConnected = 'Zoho Books Not Connected';
    const textForInvoiceNotFound = 'Invoice Not Found';
    const textForUnpaidInvoice =
      'Invoice is not marked as PAID. Contact the finance team immediately with a screenshot.';
    const excessBridgeCredits = 'Excess Bridge Credits Available';

    let errorMessage;

    switch (true) {
      case !isZohoConnected: {
        errorMessage = textForZohoNotConnected;
        break;
      }
      case status === InvoiceDataStatus.NOT_FOUND: {
        errorMessage = textForInvoiceNotFound;
        break;
      }
      case status === InvoiceDataStatus.NOT_PAID: {
        errorMessage = textForUnpaidInvoice;
        break;
      }
      // To show error message if Excess Bridge Credit Available in case of Refund to Customer
      case parseInt(companyCreditBalance.excessBridgeCredits) !== 0 &&
        isTransactionTypeRefundCustomer(values?.tag): {
        errorMessage = excessBridgeCredits;
        break;
      }
      default:
        errorMessage = '';
    }

    return (
      <Box>
        <p className={classes.inlineErrorMessageText}>{errorMessage}</p>
      </Box>
    );
  };

  const renderFirstRow = () => {
    return (
      <Grid
        container
        alignItems={isTransactionTypeRecharge(values?.tag) ? 'flex-start' : 'flex-end'}
        spacing={3}
      >
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <div>
            <InputLabel className={classes.label}>
              Transaction type <span className={classes.requiredField}>*</span>
            </InputLabel>
            <Dropdown
              name='tag'
              dropdownValues={TRANSACTION_TYPE}
              fullWidth={true}
              value={values?.tag}
              disabled={id === 'PENDING_ORDER'}
              label='select'
              handleSelect={(value) => {
                handleTransactionTypeChange();
                setFieldValue('tag', value);
              }}
            />
          </div>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          {isTransactionTypeRecharge(values?.tag) ? (
            <Grid container spacing={2}>
              <Grid justifyContent='flex-end' item lg={8} md={8} sm={8} xs={8}>
                <div>
                  <InputLabel className={classes.label}>
                    Invoice Number <span className={classes.requiredField}>*</span>
                  </InputLabel>
                  {/* Field is disabled if zoho book is not connected for the company */}
                  <Field
                    disabled={!isZohoConnected}
                    type='text'
                    name='invoiceNumber'
                    component={FormInput}
                    variant='outlined'
                    onBlur={(e) => {
                      const trimmedValue = getTrimmedValue(e.target.value);
                      setFieldValue('invoiceNumber', trimmedValue);
                    }}
                  />
                  <div className={classes.fetchStatusDiv}>
                    <div className={classes.lstFetchText}>
                      {`Last Fetched: ${getLastFetchedTime()}`}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Button
                  className={classes.fetchButton}
                  disabled={!values.invoiceNumber || isFetched.loading}
                  variant='contained'
                  color='primary'
                  fullWidth
                  onClick={handleFetchInvoiceDetails}
                >
                  Fetch
                </Button>
              </Grid>
            </Grid>
          ) : !isTransactionTypeRefundCustomer(values?.tag) && values?.tag ? (
            <>
              <div className={classes.widthControlDiv}>
                <Typography style={{ textAlign: 'right', marginTop: '26px' }}>
                  {`Applicable Tax: ${props?.tax}%`}
                </Typography>
              </div>
            </>
          ) : null}
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <Dialog
        onClose={props.handleClose}
        aria-labelledby='customized-dialog-title'
        open={props.open}
        maxWidth={'md'}
        fullWidth={true}
      >
        <DialogTitle
          id='customized-dialog-title'
          onClose={props.handleClose}
          flow={id}
        ></DialogTitle>
        {(isTransactionTypeRecharge(values?.tag) || isTransactionTypeRefundCustomer(values?.tag)) &&
          dialogHeaderContent()}
        <form onSubmit={handleOnSubmit}>
          <DialogContent style={{ paddingTop: '0px' }}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={2} className={classes.rowGap}>
                  {renderFirstRow()}
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={2} className={classes.rowGap}>
                  {renderSecondRow({
                    type: values?.tag,
                    labelClass: classes?.label,
                    values: values,
                    requiredField: classes.requiredField,
                    styles: classes,
                    onClickViewInvoiceHandler: handleOnClickViewInvoiceButton,
                    isFetched: isFetched.value,
                    errorOnFields: errorOnFields,
                    isDisabled: !isZohoConnected,
                    setFieldValue: setFieldValue,
                    errors: errors
                  })}
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={2} className={classes.rowGap}>
                  {renderThirdRow({
                    type: values?.tag,
                    labelClass: classes.label,
                    values: values,
                    requiredField: classes.requiredField,
                    styles: classes,
                    onClick: handleFileUpload,
                    customAmountInputBox: classes.customAmountInputBox,
                    errorOnFields: errorOnFields,
                    isDisabled: !isZohoConnected,
                    setFieldValue: setFieldValue,
                    errors: errors
                  })}
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={2}>
                  <Grid container className={classes.rowGap} alignItems='center' spacing={3}>
                    {additionalInfoCommentBox()}
                    {(isTransactionTypeRecharge(values?.tag) ||
                      isTransactionTypeRefundCustomer(values?.tag)) &&
                      summaryBoxForRecharge(invoiceDataStatus)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid lg={11} md={11} sm={12} xs={12}>
                {(isTransactionTypeRecharge(values?.tag) ||
                  isTransactionTypeRefundCustomer(values?.tag)) &&
                  inlineErrorMessageComponent(invoiceDataStatus)}
              </Grid>
              <Grid lg={1} md={1} sm={12} xs={12}>
                <Button
                  variant='contained'
                  type='submit'
                  className={
                    isTransactionTypeRefundCustomer(values?.tag)
                      ? classes.refundActiveBtn
                      : classes.activeBtn
                  }
                  disabled={getValid() || !isEmpty(errors) || props?.isSubmitting}
                >
                  {isTransactionTypeRefundCustomer(values?.tag) ? 'Refund' : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
      {uploadModal ? (
        <FileUpload
          label='Browse'
          title='Upload File'
          fileUrl={values.invoiceUrl}
          fileName={fileName}
          open={uploadModal}
          handleClose={handleFileUpload}
          submit={(url, fileNameUrl) => {
            setFieldValue('invoiceUrl', url);
            setFileName(fileNameUrl);
            setUploadModal(false);
          }}
          submitButtonLabel='Upload'
        />
      ) : null}
    </div>
  );
}

export default UpdateBalance;
