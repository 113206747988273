import AppHelper from './AppHelper';

import localConfig from './../config/local.json';
import devConfig from '../config/dev.json';
import dev2Config from '../config/dev-2.json';
import dev3Config from '../config/dev-3.json';
import dev4Config from '../config/dev-4.json';
import prodConfig from '../config/prod.json';
import acceptanceConfig from '../config/acceptance.json';
import acceptanceConfig2 from '../config/acceptance-2.json';

let appVar = AppHelper.isLocallyServed()
  ? localConfig
  : AppHelper.isAcceptance()
  ? acceptanceConfig
  : AppHelper.isAcceptance2()
  ? acceptanceConfig2
  : AppHelper.isProduction()
  ? prodConfig
  : AppHelper.isDev2()
  ? dev2Config
  : AppHelper.isDev3()
  ? dev3Config
  : AppHelper.isDev4()
  ? dev4Config
  : devConfig;

const AppConstants = { ...appVar };

export default AppConstants;
