// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".t_qTeEoDxz5hFbh_3r3X {\n  font-weight: 600 !important;\n  margin: 0 !important;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "t_qTeEoDxz5hFbh_3r3X"
};
export default ___CSS_LOADER_EXPORT___;
