import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, has } from 'lodash';
import { toast } from 'react-toastify';
import { getCompanyCreditBalance as getCompanyCreditBalanceAPI } from '../../../../../../actions/admin';

import validationSchema from './DeductionTransaction.validation';

import { createDeductionTransactionLogs as createDeductionTransactionLogsAPI } from '../../../../../../api/admin';

import { errToastMessage, getErrorMessage } from '../../../../../../utils/Utlities';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyCreditBalance: (companyId) => dispatch(getCompanyCreditBalanceAPI(companyId))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      const storedValues = {
        checkType: '',
        checkId: '',
        candidateId: '',
        amount: '',
        eventType: '',
        additionalInfo: '',
        setDisabled: ''
      };

      let propsValue = {};

      if (has(props, 'companyId')) {
        propsValue.companyId = props.companyId;
      } else {
        propsValue = {};
      }

      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    validationSchema,
    enableReinitialize: true,
    handleSubmit: (values, { setFieldError, setSubmitting, resetForm, props }) => {
      const finalValues = {
        additionalInfo: values.additionalInfo,
        amount: parseInt(values.amount),
        candidateId: parseInt(values.candidateId),
        checkId: parseInt(values.checkId),
        checkType: values.checkType,
        companyId: parseInt(values.companyId),
        eventType: values.eventType
      };

      createDeductionTransactionLogsAPI(finalValues)
        .then(() => {
          setSubmitting(false);
          toast.info('Deduction log created successfully');
          props.getCompanyCreditBalance({ companyId: props.companyId, onlyNegative: false });
          resetForm();
          props.handleClose(); // to hide modal
        })
        .catch((error) => {
          setSubmitting(false);
          errToastMessage(error);
        });
    },
    displayName: 'Add Balance'
  })
);
