import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Chip } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  label: {
    paddingRight: 4,
    backgroundColor: '#F7F8FA',
    transform: 'translate(14px, 14px) scale(1)'
  },
  select: {
    height: 42,
    minWidth: 180
  },
  error: {
    margin: '0 8px'
  },
  chips: {
    display: 'contents'
  },
  chip: {
    margin: 1
  },
  hideMenuItem: {
    display: 'none'
  },
  disabled: {
    pointerEvents: 'unset !important',
    cursor: 'not-allowed !important'
  }
}));

export default function CustomizedDropdownInput(props) {
  const classes = useStyles(props);
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
    props?.handleSelect(event.target.value);
  };

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  // handle disabling of dropdown option
  const handldeDisable = (ddValue) => {
    return (ddValue.label === 'Completed(Auto)' && ddValue.value === '10') ||
      (ddValue.label === props.disabledItem.label &&
        ddValue.value === props.disabledItem.value &&
        props.value !== props.disabledItem.value)
      ? true
      : false;
  };
  return (
    <FormControl
      variant='outlined'
      className={props.applyClass ? classes.formControl : null}
      error={props.requiredError}
      disabled={props.disabled}
      required={props.required}
      fullWidth={props.fullWidth}
    >
      <InputLabel className={classes.label} id='customized-select-label'>
        {props.label}
      </InputLabel>
      <Select
        className={classes.select}
        labelId='customized-select-label'
        id='customized-select'
        endAdornment={props?.endAdornment}
        multiple={props.multiSelect}
        value={value !== null && value !== undefined ? value : ''}
        onChange={handleChange}
        classes={{ disabled: classes.disabled }}
        renderValue={
          props.multiSelect
            ? (selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={props?.dropdownValues?.find((val) => val?.value === value)?.label}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )
            : (value) => props?.dropdownValues?.find((val) => val?.value === value)?.label
        }
      >
        {props.placeholder && (
          <MenuItem value='' disabled>
            {props.placeholder}
          </MenuItem>
        )}
        {props.dropdownValues.length > 0 &&
          props.dropdownValues.map((ddValue, index) =>
            props.multiSelect ? (
              <MenuItem key={index} value={ddValue?.value}>
                <Checkbox checked={value.indexOf(ddValue?.value) > -1} />
                <ListItemText primary={ddValue?.label} />
              </MenuItem>
            ) : props?.hideItem && props?.hideItem?.value == ddValue.value ? (
              <MenuItem
                className={classes.hideMenuItem}
                key={index}
                value={ddValue?.value}
              ></MenuItem>
            ) : (
              <MenuItem key={index} value={ddValue?.value} disabled={handldeDisable(ddValue)}>
                {ddValue?.label}
              </MenuItem>
            )
          )}
      </Select>
      {props.requiredError && (
        <FormHelperText className={classes.error}>{`${props.label} is required !`}</FormHelperText>
      )}
    </FormControl>
  );
}

CustomizedDropdownInput.defaultProps = {
  label: '',
  placeholder: '',
  value: '', //required [] in case of multiSelect
  dropdownValues: [],
  disabled: false,
  required: false,
  requiredError: false,
  multiSelect: false,
  handleSelect: null,
  fullWidth: false,
  disabledItem: {},
  hideItem: {}
};
