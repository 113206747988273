import * as Yup from 'yup';
import {
  PINCODE_RULES_FOR_INDIA,
  PINCODE_RULES_FOR_OTHER_COUNTRIES_THAN_INDIA,
  getAlphabeticalStringValidationSchema
} from '../../../../utils/ValidationSchema';

export default Yup.object().shape({
  city: Yup.string(),
  pointPersonSales: getAlphabeticalStringValidationSchema({
    message: 'name'
  }),
  accountExecutive: getAlphabeticalStringValidationSchema({
    message: 'name'
  }),
  pinCode: Yup.string().when('country', {
    is: 'India',
    then: Yup.string().matches(PINCODE_RULES_FOR_INDIA, 'Please enter a valid pincode.'),
    otherwise: Yup.string().matches(
      PINCODE_RULES_FOR_OTHER_COUNTRIES_THAN_INDIA,
      'Please enter a valid pincode.'
    )
  })
});
