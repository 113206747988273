import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Box,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import IncrementCounter from '../../../../../../core-components/Packages/IncrementCounter';
import { EDUCATION_TYPES } from '../../../../../../core-components/CommonConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginLeft: '68px',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  required: {
    color: '#db3131'
  }
}));

/**
 * cPkgDetails: Company PackageDetails
 * sPkgDetails: Customized/CA selected package detailsInfo
 * aPkgDetails: Addon's allowed config/available options in addons
 * onValueChange: Func to save the values to parent state
 * values: Addons values as they changes
 */
export default (props) => {
  const classes = useStyles();

  //   Refer DocBlock above for detailed variable usage
  const { onValueChange, values, educationType } = props;

  const handleConfigChange = (value) => {
    onValueChange(value);
  };
  const getEducationRadioOptions = () => {
    return EDUCATION_TYPES.filter((ob) => ob.name === educationType);
  };
  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid
            item
            xl={12}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            style={{ display: 'flex', verticalAlign: 'middle' }}
          >
            <Typography variant='subtitle2' component='span' style={{ marginRight: '12px' }}>
              <i>{props.type === 'emp' ? `Emp's :` : `Edu's :`}</i>
            </Typography>
            <IncrementCounter
              limit={props.limit}
              totalCount={values} //TODO: Need to pass to total emp
              value={values}
              index={null}
              valueChange={(value) => {
                handleConfigChange(value);
              }}
            />
          </Grid>
          {props.type === 'edu' && (
            <Grid item className='mt-4'>
              <FormLabel component='legend'>
                Choose verification type: <span className={classes.required}>*</span>
              </FormLabel>
              <RadioGroup aria-label='type'>
                <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                  {getEducationRadioOptions().map((ob) => (
                    <FormControlLabel
                      value={ob.name}
                      control={
                        <Radio
                          value={ob.name}
                          color='primary'
                          checked={values > 0}
                          disabled={values === 0}
                        />
                      }
                      label={ob.label}
                    />
                  ))}
                </Grid>
              </RadioGroup>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
};
