import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith } from 'lodash';

import validationSchema from './Login.validation';
import {
  login as loginAPI,
  loginTemp as loginTempAPI,
  googleLogin as googleLoginAAPI
} from '../../actions/admin';
import AppConstants from '../../core-components/AppConstants';

const mapStateToProps = (state) => {
  return {
    user: state.profile ? { ...state.profile } : null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data) => dispatch(loginAPI(data)),
    loginTemp: (data) => dispatch(loginTempAPI(data)),
    googleLogin: (data) => dispatch(googleLoginAAPI(data))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        password: '',
        email: '',
        rememberMe: true
      };
      let propsValue = {};
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (
      values,
      { setFieldError, setStatus, setSubmitting, resetForm, setFieldValue, props }
    ) => {
      const finalSaveObj = {
        email: values.email,
        password: values.password,
        rememberMe: values.rememberMe
      };

      props
        .loginTemp(finalSaveObj)
        .then((response) => {
          setSubmitting(false);
          const { location } = props;
          const lastPath = localStorage.getItem('last-redirect-pathname');
          const stateFrom = location.state?.from?.pathname;
          if (lastPath) {
            window.open(
              `${AppConstants.baseURL}${
                lastPath.startsWith('/') ? lastPath.substring(1) : lastPath
              }`,
              '_self'
            );
          } else if (stateFrom && stateFrom !== '/logout') {
            window.open(
              `${AppConstants.baseURL}${
                stateFrom.startsWith('/') ? stateFrom.substring(1) : stateFrom
              }${location.state?.from?.search}`,
              '_self'
            );
          } else {
            window.open(`${AppConstants.baseURL}dashboard`, '_self');
          }
          localStorage.removeItem('last-redirect-pathname');
        })
        .catch((error) => {
          setSubmitting(false);
          console.log(error);
        });
    },
    displayName: 'Login Page'
  })
);
