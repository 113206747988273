export const customTheme = {
    typography: {
        fontFamily: 'Poppins, Roboto',
    },
    palette: {
        primary: {
            main: '#2755fe',
        },
    },
    typography: {
        fontFamily: 'Poppins',
    },
    headerBackground: '#222',
    white: '#ffffff',
    overrides: {
        MuiFormLabel: {
            asterisk: {
                color: '#db3131',
                '&$error': {
                    color: '#db3131',
                },
            },
        },
    },
};
