import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { PLAIN_CHECK_ICONS } from '../../CommonConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  formControl: {
    margin: theme.spacing(3)
  }
}));

export default (props) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <Grid className={classes.root} item container>
          <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
            <Typography variant='h6' style={{ fontSize: '18px' }} gutterBottom>
              <span style={{ marginRight: '10px' }} title={props.isWorld ? 'world' : 'cibil'}>
                <FontAwesomeIcon
                  icon={
                    PLAIN_CHECK_ICONS[props.isWorld ? 'world' : 'credit']
                      ? PLAIN_CHECK_ICONS[props.isWorld ? 'world' : 'credit'].icon
                      : faBan
                  }
                  color='#222'
                />
              </span>
              {props.isWorld ? 'World Check' : 'Credit Check'}
              {!props.value ? (
                <span style={{ marginRight: '10px', fontSize: '12px', color: '#312c51' }}>
                  <i>{` (*Not Selected)`}</i>
                </span>
              ) : null}
            </Typography>
          </Grid>
          <Grid
            item
            container
            xl={12}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            style={{ marginLeft: '20px', marginTop: '12px', marginBottom: '24px' }}
          >
            <FormControlLabel
              disabled
              control={<Checkbox checked={props.value ? true : false} name='checkedE' />}
              label={props.value ? 'Enabled' : 'Disabled'}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
