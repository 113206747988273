import { connect } from 'react-redux';
import { compose } from 'redux';
import { isEmpty } from 'lodash';

import {
  getReviewAdditionalChargesList as getReviewAdditionalChargesListAPI,
  invalidateReviewAdditionalList
} from '../../../../actions/admin';

const mapStateToProps = (state) => {
  return {
    reviewAdditionalChargesList: state && state?.reviewAdditionalChargesList,
    profile: state.profile && !isEmpty(state.profile) ? state.profile : null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invalidateReviewAdditionalChargesList: () => dispatch(invalidateReviewAdditionalList()),
    getReviewAdditionalChargesList: (query) => dispatch(getReviewAdditionalChargesListAPI(query))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
