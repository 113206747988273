import React from 'react';

import { Container, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import Image from '../../core-components/Image';
import Button from '../../core-components/Button';
import AppConstants from '../../core-components/AppConstants';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    minHeight: '100vh',
    flexDirection: 'column',
    textAlign: 'center'
  },
  image: { marginTop: '100px' },
  heading: {
    marginTop: 40,
    marginBottom: 30,
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '38px',
    color: '#333333'
  },
  button: {
    marginTop: 40,
    color: '#ffffff',
    borderRadius: 20,
    borderColor: '#388e3c',
    background: 'linear-gradient(to right, #0844a6 0%, #086590 48.96%, #088077 100%)',
    fontSize: 14,
    textTransform: 'inherit',
    '&:hover': {
      background: 'linear-gradient(to right, #0844a6 0%, #086590 48.96%, #088077 100%)'
    }
  }
}));
export default (props) => {
  const classes = useStyles();

  const backToHome = () => {
    // props.history.push('/dashboard');
    window.open(`${AppConstants.baseURL}dashboard`, '_self');
  };

  return (
    <React.Fragment>
      <Container>
        <Grid data-testid='unauthorized-box' container className={classes.gridContainer}>
          <Grid item xl={12} md={12}>
            <Image className={classes.image} name={'unauthorized.png'} />
            <Typography variant='h6'>{'YOU ARE NOT ALLOWED TO ENTER HERE 🧐'}</Typography>
            <Typography variant='subtitle2'>
              {'Please Logout or open Signup in another browser'}
            </Typography>
            <Button
              className={classes.button}
              label={'Back to Home'}
              startIcon={<HomeIcon />}
              onClick={backToHome}
            />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};
