import React, { useState, useEffect } from 'react';
import {
  Typography,
  Paper,
  FormControl,
  Grid,
  Button,
  Select,
  MenuItem,
  makeStyles,
  InputAdornment,
  TextField
} from '@material-ui/core';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import SaveIcon from '@material-ui/icons/Save';
import { COMPANY_TDS } from '../../../../core-components/CommonConstants';
import ZohoBookDialog from '../../ZohoBookDialog';
import GSTModal from './Modals/GST';
import { updateFinanceConfig } from '../../../../api/admin';
import { toast } from 'react-toastify';
import { errToastMessage, getErrorMessage, toSnakeCase } from '../../../../utils/Utlities';
import AppConstants from '../../../../core-components/AppConstants';
import Breadcrumb from '../../../../core-components/BreadCrumbs';
import { Prompt } from 'react-router';

export default function (props) {
  const companyId = new URLSearchParams(document.location.search).get('id') || null;
  const breadCrumbsArray = [
    { label: 'Home', href: `${AppConstants.baseURL}dashboard` },
    { label: 'Companies', href: `${AppConstants.baseURL}companies` },
    {
      label: props.updateCompanyData?.name || '...',
      href: `${AppConstants.baseURL}company/view?id=${companyId}`
    }
  ];
  const { values, setFieldValue, handleSubmit } = props;
  const [isNextButtonClicked, setIsNextButtonClicked] = useState(false);

  const handleformSubmit = () => {
    handleSubmit();
    setIsNextButtonClicked(true);
  };


  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: '260px'
    },
    ml20: {
      marginLeft: '1.25rem'
    },
    mt20: {
      marginTop: '1.25rem'
    },
    mb20: {
      marginBottom: '1.25rem'
    },
    btn: {
      minWidth: '8rem',
      marginLeft: '1.25rem'
    },
    settingsbtn: {
      background: '#45AE9F',
      color: 'white',
      '&:hover': {
        background: '#328b7e'
      }
    },
    activeBtn: {
      margin: theme.spacing(1),
      width: '240px',
      color: '#ffffff',
      borderColor: '#388e3c',
      backgroundColor: '#43a047',
      fontSize: 14,
      lineHeight: 1,
      marginRight: 2,
      textTransform: 'inherit',
      '&:hover': {
        backgroundColor: '#388e3c'
      },
      height: 'fitContent',
      marginTop: 'auto'
    },
    disabledBtn: {
      margin: theme.spacing(1),
      width: '240px',
      color: '#dfdfdf',
      borderColor: '#d9d9d9',
      backgroundColor: '#f5f5f5',
      fontSize: 14,
      lineHeight: 1,
      marginRight: 2,
      textTransform: 'inherit',
      '&:hover': {
        backgroundColor: '#f5f5f5'
      },
      height: 'fitContent',
      marginTop: 'auto'
    },
    skipBtn: {
      marginTop: '30px',
      margin: theme.spacing(1),
      width: '240px',
      color: '#ffffff',
      borderColor: '#388e3c',
      backgroundColor: '#1976D2',
      fontSize: 14,
      lineHeight: 1,
      marginRight: 2,
      textTransform: 'inherit',
      '&:hover': {
        backgroundColor: '#1976D2'
      }
    },
    saveButtonGrid: {
      display: 'flex',
      flexDirection: 'row-reverse',
      marginTop: '1rem'
    },
    headerText: {
      padding: '1.25rem 0',
      marginTop: '1.25rem'
    },
    disabledNextBtn: {
      margin: theme.spacing(1),
      width: '240px',
      color: '#ffffff',
      borderColor: '#d9d9d9',
      backgroundColor: '#dfdfdf',
      fontSize: 14,
      lineHeight: 1,
      marginRight: 2,
      textTransform: 'inherit',
      '&:hover': {
        backgroundColor: '#f5f5f5'
      },
      height: 'fitContent',
      marginTop: 'auto'
    }
  }));

  const style = useStyles();
  const isCreateFlow = new URLSearchParams(document.location.search).get('create') || false;
  const [isZohoDialogOpen, setIsZohoDialogOpen] = useState(false);
  const [isGstDialogOpen, setIsGstDialogOpen] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isZohoAutoFetch, setIsZohoAutoFetch] = useState(false);
  useEffect(() => {
    props.invalidateCompanyDetail();
    props.getCompanyById(companyId);
    return () => {
      props.invalidateCompanyDetail();
      setIsZohoAutoFetch(false);
    };
  }, []);
  const handleZohoDialogClose = () => {
    setIsZohoDialogOpen(false);
  };
  const handleAutoFetchFromZoho = () => {
    setIsZohoAutoFetch(false);
  };
  const handleGstDialogClose = (isGstSet) => {
    setIsGstDialogOpen(false);
    if (isGstSet && values?.zohoId) {
      setIsZohoAutoFetch(true);
      setTimeout(() => {
        openZohoBooksFetchModal();
      }, 2000);
    }
  };

  //** Helper function to open the zoho books fetch modal
  const openZohoBooksFetchModal = () => {
    setIsZohoDialogOpen(true);
  };

  const openGstSetupModal = () => {
    setIsGstDialogOpen(true);
  };

  const getFinanceConfigApi = (payload, isPutRequest) => {
    updateFinanceConfig(toSnakeCase(payload), isPutRequest)
      .then(() => {
        toast.success('Saved successfully.');
        setIsSaveDisabled(true);
        let companyState = props.updateCompanyData;
        companyState['tds'] = values.tds;
        props.setCompanyDetails(companyState);
      })
      .catch((error) => {
        errToastMessage(error);
      });
  };

  const handleTdsSave = () => {
    if (!isSaveDisabled) {
      const tdsValue = values?.tds;
      const isPutRequest = true;
      const payload = {
        companyId: companyId,
        tds: tdsValue
      };
      setIsSaveDisabled(true);
      getFinanceConfigApi(payload, isPutRequest);
    }
  };
  return (
    <>
      {!isSaveDisabled ? (
        <Prompt when={true} message='Are you sure you want to leave, there are unsaved changes?' />
      ) : null}
      <Grid container>
        <Grid item xl={1} md={1} lg={1} sm={false} xs={false}></Grid>
        <Grid item xl={10} md={10} lg={10} sm={12} xs={12}>
          <Breadcrumb
            showPrompt={!isSaveDisabled}
            linksArray={breadCrumbsArray}
            current={'Finance Settings'}
          />
          <Typography variant={'h6'} className={style.headerText}>
            Billing Configuration
          </Typography>
          <Paper variant='outlined' elevation={5} style={{ padding: '5px' }}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <div className={`${style.ml20} ${style.mt20} `}>
                  <Typography> GSTIN </Typography>
                  <FormControl size='medium' className={style.formControl}>
                    <TextField
                      type='text'
                      name='gstin'
                      placeholder='No GSTIN'
                      variant='outlined'
                      value={values.gstin}
                      marginRight='80px'
                      disabled={true}
                      size='small'
                      InputProps={{
                        endAdornment: values.gstin ? (
                          <InputAdornment position='end'>
                            <CheckCircleOutline style={{ color: '#52C41A', fontSize: '16px' }} />
                          </InputAdornment>
                        ) : null
                      }}
                    />
                  </FormControl>
                  {values.gstin ? (
                    <Button
                      variant='contained'
                      className={`${style.settingsbtn} ${style.btn}`}
                      size='medium'
                      onClick={openGstSetupModal}
                    >
                      Settings
                    </Button>
                  ) : (
                    <Button
                      variant='outlined'
                      color='primary'
                      onClick={openGstSetupModal}
                      className={style.btn}
                    >
                      Setup
                    </Button>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={`${style.ml20} ${style.mt20} `}>
                  <Typography> Zoho Books Id </Typography>
                  <FormControl size='medium' className={style.formControl}>
                    <TextField
                      type='text'
                      name='zohoId'
                      value={values.zohoId}
                      placeholder='Not Connected'
                      variant='outlined'
                      disabled={true}
                      size='small'
                      InputProps={{
                        endAdornment: values.zohoId ? (
                          <InputAdornment position='end'>
                            <CheckCircleOutline style={{ color: '#52C41A', fontSize: '16px' }} />
                          </InputAdornment>
                        ) : null
                      }}
                    />
                  </FormControl>
                  {values.zohoId ? (
                    <Button
                      variant='contained'
                      className={`${style.settingsbtn} ${style.btn}`}
                      size='medium'
                      onClick={openZohoBooksFetchModal}
                    >
                      Settings
                    </Button>
                  ) : (
                    <Button
                      variant='outlined'
                      color='primary'
                      onClick={openZohoBooksFetchModal}
                      className={style.btn}
                    >
                      Connect
                    </Button>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={`${style.ml20} ${style.mt20} ${style.mb20}`}>
                  <Typography>Tax Deduction Source</Typography>
                  <FormControl size='small' className={style.formControl}>
                    <Select
                      labelId='tdsLabel'
                      id='tds'
                      onChange={(e) => {
                        setIsSaveDisabled(props.updateCompanyData.tds === e.target.value);
                        setFieldValue('tds', e.target.value);
                      }}
                      value={values.tds}
                      variant='outlined'
                    >
                      {COMPANY_TDS.map((item, index) => {
                        return (
                          <MenuItem key={`tds_${index}_${item.value}`} value={item.value}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <Button
                    variant='contained'
                    color='primary'
                    className={style.btn}
                    size='medium'
                    disabled={isSaveDisabled}
                    onClick={handleTdsSave}
                  >
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Paper>
          {isCreateFlow ? (
            <Grid className={style.saveButtonGrid} item container>
              <Button
                variant='contained'
                size='large'
                name='saveAndNext'
                className={isNextButtonClicked ? style.disabledBtn : style.activeBtn}
                onClick={(e) => {
                  if (!isNextButtonClicked) {
                    handleformSubmit();
                  }
                }}
                disabled={isNextButtonClicked}
              >
                {'Next'}
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {isZohoDialogOpen ? (
        <ZohoBookDialog
          open={isZohoDialogOpen}
          onClose={handleZohoDialogClose}
          zohoBookID={values?.zohoId}
          companyValues={values}
          autoFetch={isZohoAutoFetch}
          handleAutoFetch={handleAutoFetchFromZoho}
        />
      ) : null}
      {isGstDialogOpen ? (
        <GSTModal
          open={isGstDialogOpen}
          onClose={handleGstDialogClose}
          gstId={values?.gstin}
          companyValues={values}
        />
      ) : null}
    </>
  );
}
