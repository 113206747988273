import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import {
  Box,
  Grid,
  Card,
  Dialog,
  Typography,
  IconButton,
  InputLabel,
  CircularProgress,
  Button,
  FormHelperText,
  InputAdornment
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import { Field } from 'formik';

import { getCompanyPackages } from '../../../../../../api/admin';
import { convertToIndianNumeration, toCamelCase } from '../../../../../../utils/Utlities';

import FormInput from '../../../../../../core-components/FormInput';
import Dropdown from '../../../../../../core-components/DropdownInput';

const useStyles = makeStyles((theme) => ({
  progressContainer: {
    textAlign: 'center',
    width: '100%'
  },
  row: {
    display: 'flex'
  },
  card: {
    border: '1px solid #666666',
    padding: '8px'
  },
  subHeading: {
    fontWeight: '600',
    marginTop: '16px'
  },
  label: {
    margin: '10px 0 5px',
    color: '#333333'
  },
  dropdownError: {
    color: '#f44336',
    margin: '4px 14px 0'
  },

  activeBtn: {
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  disabledBtn: {
    color: '#dfdfdf',
    borderColor: '#d9d9d9',
    backgroundColor: '#f5f5f5',
    fontSize: 14,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  errorMsg: {
    fontSize: '0.75rem',
    color: '#f44336',
    fontWeight: '400',
    lineHeight: '1.66',
    margin: 0,
    marginBottom: theme.spacing(3)
  },
  rupeeSymbol: {
    fontFamily: 'Roboto'
  },
  amountContainer: {
    display: 'flex'
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h5'>Convert to Package Balance</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  }
}))(MuiDialogContent);

function ConvertToPackage(props) {
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [packageDetails, setPackageDetails] = useState(null);

  const classes = useStyles();

  const { values, setFieldValue, errors, handleSubmit, isSubmitting, setFieldError } = props;

  useEffect(() => {
    setLoading(true);

    let packagesList = [];
    getCompanyPackages(props.companyId).then((response) => {
      setLoading(false);
      if (response.data) {
        let values = toCamelCase(response);
        values?.data.map((val) => {
          packagesList.push({ label: val.name, value: val });
        });
        setPackages(packagesList);
      }
    });
  }, []);

  useEffect(() => {
    if (values.packageUnits > 0 && values.totalCredits > 0) {
      const totalCost = values.packageUnits * values.totalCredits;
      setFieldValue('credits', totalCost);
    }
  }, [values.packageUnits, values.totalCredits]);

  const submitForm = (e) => {
    e.preventDefault();
    if (values.credits > props.balance) {
      setFieldError('balance', 'Insufficient credit balance');
    } else {
      handleSubmit();
    }
  };

  const handlePackageUnits = (event) => {
    const units = parseInt(event.target.value || 0);
    setFieldValue('packageUnits', units);
  };

  const getSubstractionSign = (val) => {
    return <span>{val < 0 ? '- ' : ''}</span>;
  };

  const isValidForm = !isSubmitting && isEmpty(errors);
  return (
    <div>
      <Dialog
        onClose={props.handleClose}
        aria-labelledby='customized-dialog-title'
        open={props.open}
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle id='customized-dialog-title' onClose={props.handleClose}></DialogTitle>
        <DialogContent>
          {loading ? (
            <Box mt={3} mb={3} className={classes.progressContainer}>
              <CircularProgress size={30} />
            </Box>
          ) : (
            <>
              <Dropdown
                name='package'
                label={'Select Package'}
                placeholder={'Select Package'}
                dropdownValues={packages}
                fullWidth={true}
                value={packageDetails}
                handleSelect={(value) => {
                  setFieldValue('packageId', value.id);
                  setFieldValue('packageUuid', value.uuid);
                  setPackageDetails(value);
                }}
              />
              {packageDetails && (
                <>
                  <form onSubmit={submitForm}>
                    <Box mt={2} mb={2} className={classes.row}>
                      <Grid item lg={6} md={6} sm={6} xs={6}>
                        <div>
                          <Grid item lg={7} md={7} sm={7} xs={7}>
                            <InputLabel className={classes.label}>Units to Add</InputLabel>
                          </Grid>
                          <Grid item lg={10} md={10} sm={10} xs={10}>
                            <Field
                              fullWidth={true}
                              type='text'
                              name='packageUnits'
                              component={FormInput}
                              variant='outlined'
                              onChange={handlePackageUnits}
                            />
                          </Grid>
                        </div>
                        <div>
                          <Grid item lg={7} md={7} sm={7} xs={7}>
                            <InputLabel className={classes.label}>Price per Unit</InputLabel>
                          </Grid>
                          <Grid className='inputAdornMent' item lg={10} md={10} sm={10} xs={10}>
                            <Field
                              fullWidth={true}
                              type='text'
                              name='totalCredits'
                              component={FormInput}
                              startAdornment={
                                values?.totalCredits && (
                                  <InputAdornment position='start'>
                                    <span>&#8377;</span>
                                  </InputAdornment>
                                )
                              }
                              variant='outlined'
                              isShowInIndianCurrency={true}
                            />
                            {errors.balance ? (
                              <FormHelperText className={classes.errorMsg}>
                                {errors.balance}
                              </FormHelperText>
                            ) : null}
                          </Grid>
                        </div>
                        <div>
                          <Grid item lg={10} md={10} sm={10} xs={10}>
                            <InputLabel className={classes.label}>
                              Total credits to deduct
                            </InputLabel>
                          </Grid>
                          <Grid item lg={10} md={10} sm={10} xs={10}>
                            <Field
                              className='inputAdornMent'
                              fullWidth={true}
                              type='text'
                              name='credits'
                              component={FormInput}
                              variant='outlined'
                              startAdornment={
                                values?.credits && (
                                  <InputAdornment position='start'>
                                    <span>&#8377;</span>
                                  </InputAdornment>
                                )
                              }
                              isShowInIndianCurrency={true}
                              disabled={true}
                            />
                          </Grid>
                        </div>
                        <div style={{ marginTop: '10px' }}>
                          <div className={classes.amountContainer}>
                            <span>Current Balance:&nbsp;</span>
                            <span className={classes.amountContainer}>
                              {props?.balance < 0 ? '-' : ''}
                              <span className={classes.rupeeSymbol}>&#8377;</span>
                              <span>
                                {convertToIndianNumeration(
                                  parseInt(props?.balance < 0 ? props?.balance * -1 : props.balance)
                                )}
                              </span>
                            </span>
                          </div>
                          <div className={classes.amountContainer}>
                            <span>New Balance:&nbsp;</span>
                            <span className={classes.amountContainer}>
                              {getSubstractionSign(
                                values.packageUnits > 0
                                  ? props.balance - values.credits
                                  : props.balance
                              )}
                              <span className={classes.rupeeSymbol}>&#8377;</span>
                              {convertToIndianNumeration(
                                values.packageUnits > 0
                                  ? props.balance - values.credits < 0
                                    ? (props.balance - values.credits) * -1
                                    : props.balance - values.credits
                                  : props.balance < 0
                                  ? props.balance * -1
                                  : props.balance
                              )}
                            </span>
                          </div>
                          <Typography>
                            Package Balance: {packageDetails.packageBalance || '-'}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Card className={classes.card} variant='outlined'>
                          <div className={classes.row}>
                            <InfoIcon />
                            <Typography>Recharge Info</Typography>
                          </div>
                          <Typography className={classes.subHeading} variant='subtitle2'>
                            Last Recharge
                          </Typography>
                          {packageDetails?.lastRechargeInfo &&
                          packageDetails?.lastRechargeInfo?.rechargeUnits ? (
                            <Typography variant='body2'>
                              <span className={classes.amountContainer}>
                                <span>{packageDetails.lastRechargeInfo.rechargeUnits}&nbsp;</span>
                                <span> candidates&nbsp;</span>
                                <span> (@ </span>
                                <span className={classes.rupeeSymbol}>&#8377;</span>
                                <span>
                                  {convertToIndianNumeration(
                                    parseInt(packageDetails.lastRechargeInfo.costPerCandidate)
                                  )}
                                </span>

                                <span> / </span>
                                <span>candidate)</span>
                              </span>
                            </Typography>
                          ) : (
                            'NA'
                          )}
                          <Typography className={classes.subHeading} variant='subtitle2'>
                            Recharge Configuration
                          </Typography>
                          {packageDetails?.rechargeInfo?.map((value, index) => (
                            <Typography key={index} variant='body2'>
                              <span className={classes.amountContainer}>
                                <span>{value.rechargeUnits} candidate - @</span>
                                <span className={classes.rupeeSymbol}>&nbsp;&#8377;</span>
                                <span>
                                  {convertToIndianNumeration(parseInt(value.costPerCandidate))}
                                </span>
                                <span> / </span>
                                <span>candidate</span>
                              </span>
                            </Typography>
                          ))}
                        </Card>
                      </Grid>
                    </Box>
                    <Box textAlign={'right'}>
                      <Button
                        variant='contained'
                        type='submit'
                        className={isValidForm ? classes.activeBtn : classes.disabledBtn}
                        disabled={!isValidForm}
                      >
                        Save
                      </Button>
                    </Box>
                  </form>
                </>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ConvertToPackage;
