import React from 'react';
import { Field } from 'formik';

import {
  Box,
  Dialog,
  Typography,
  IconButton,
  Button,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import { getTrimmedValue } from '../../../../../../utils/Utlities';
import FormInput from '../../../../../../core-components/FormInput';

const useStyles = makeStyles((theme) => ({
  activeBtn: {
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h5'>Update credit limit</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
    paddingTop: 2
  }
}))(MuiDialogActions);

function UpdateBalance(props) {
  const classes = useStyles();

  const { handleSubmit, setFieldValue, values } = props;
  const checkUnlimited = (e) => {
    setFieldValue('disabled', false);
    setFieldValue('creditLimit', '');
    setFieldValue('isUnlimited', e.target.checked);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setFieldValue('disabled', true);
    setFieldValue('errorHandler', isSaveBtnDisabled);
    handleSubmit();
  };

  const isSaveBtnDisabled = () => {
    const res =
      values.disabled ||
      (!values.creditLimit && !values.isUnlimited) ||
      values.creditLimit > 5000000;
    return res;
  };

  return (
    <div>
      <Dialog
        onClose={props.handleClose}
        aria-labelledby='customized-dialog-title'
        open={props.open}
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle id='customized-dialog-title' onClose={props.handleClose}></DialogTitle>
        <form onSubmit={onSubmitHandler}>
          <DialogContent>
            <Box>
              <Field
                fullWidth={true}
                disabled={values.isUnlimited}
                type='text'
                name='creditLimit'
                label={'Set new Credit limit'}
                component={FormInput}
                variant='outlined'
                onBlur={(e) => {
                  const trimmedValue = getTrimmedValue(e.target.value);
                  setFieldValue('creditLimit', trimmedValue);
                }}
              />
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.isUnlimited}
                  onChange={checkUnlimited}
                  name='isUnlimited'
                  color={'primary'}
                />
              }
              label='Unlimited credit'
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              type='submit'
              className={classes.activeBtn}
              endIcon={<SaveIcon />}
              disabled={isSaveBtnDisabled()}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default UpdateBalance;
