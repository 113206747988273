import { connect } from 'react-redux';
import { compose } from 'redux';

import { getOpsStatus as getOpsStatusAPI } from '../../../actions/admin';

const mapStateToProps = (state) => {
  return {
    // stepCounter: 0,
    opsStatus: state.opsStatus
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOpsStatus: (limit, offset, name, status) =>
      dispatch(getOpsStatusAPI(limit, offset, name, status))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
