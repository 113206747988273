import * as Yup from 'yup';
import {
  getAlphabeticalStringValidationSchema,
  getEmailValidationSchema,
  getPhoneValidationSchema
} from '../../../../utils/ValidationSchema';
import AppHelper from '../../../../core-components/AppHelper';
import { allowedAdminDomain } from '../../../../core-components/CommonConstants';

const isTelephonyValid = (phoneNumber) => {
  if (!/^(\+)?[1-9][0-9]*$/i.test(phoneNumber)) {
    return false;
  }
  if (!phoneNumber.startsWith('+') && phoneNumber.length === 10) {
    return true;
  }
  return false;
};

export default Yup.object().shape({
  name: getAlphabeticalStringValidationSchema({ message: 'admin', isRequired: true }),
  email: getEmailValidationSchema({ isRequired: true }).test({
    message: 'Allowed domains are springworks.in and springverify.com only',
    test: (value) => {
      if (value && AppHelper.isProduction()) {
        const getDomain = value.split('@')[1];
        if (!allowedAdminDomain.includes(getDomain)) {
          return false;
        }
      }
      return true;
    }
  }),
  telephony: Yup.string()
    .test({
      name: 'mobile',
      message: 'Please provide a valid Phone number',
      test: (value) => {
        if (value) {
          return isTelephonyValid(value);
        } else {
          return true;
        }
      }
    })
    .when('isTelephonyEnabled', {
      is: true,
      then: Yup.string().required('Please enter telephony number')
    })
});
