import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import DropdownInput from './DropdownInput';
import { CancelButton, SubmitButton } from './FormButtons';
import Button from './Button';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  warningText: {
    color: 'red',
    marginTop: '10px'
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  }
}))(MuiDialogActions);

const WarningText = withStyles(styles)((props) => {
  return <span className={props.classes.warningText}>{props.children}</span>;
});
export default function DropdownPopup(props) {
  const [value, setValue] = useState('');
  const [initialValue, setInitialValue] = useState('');

  const handleClose = () => {
    props.handleClose();
  };

  const handleSubmit = () => {
    props.handleSubmit(value);
    props.handleClose(false);
  };

  React.useEffect(() => {
    setValue(props.value);
    setInitialValue(props.value);
  }, [props.value]);

  const isValidForm = value || value >= 0 ? true : false;
  const hasChanged = value !== initialValue;

  return (
    <Dialog onClose={handleClose} aria-labelledby='popup-title' open={props.open} fullWidth={true}>
      <DialogTitle id='popup-title' onClose={handleClose}>
        {props.title}
      </DialogTitle>
      <DialogContent>
        <DropdownInput
          label={props.label}
          placeholder={props.placeholder}
          value={value}
          dropdownValues={props.dropdownValues}
          disabled={props.disabled}
          required={props.required}
          requiredError={props.requiredError}
          multiSelect={props.multiSelect}
          handleSelect={(value) => {
            props.hanldeStatusChange && props.hanldeStatusChange(value);
            setValue(value);
          }}
          fullWidth={true}
        />

        {/* if both warningTextBilledCase && warningTextGeneralCase are true give priority to = warningTextBilledCase
             otherwise check for individual value for warningTextBilledCase && warningTextGeneralCase if they ar
             true show respective warning text msg otherwise dont show any msg
        */}

        {props.warningTextBilledCase && props.warningTextGeneralCase ? (
          <WarningText>
            {' '}
            “You are about to change the Overall status to In-Progress for a case which is already
            billed (or partially billed). Are you sure?”
          </WarningText>
        ) : props.warningTextBilledCase ? (
          <WarningText>
            {' '}
            “You are about to change the Overall status to In-Progress for a case which is already
            billed (or partially billed). Are you sure?”
          </WarningText>
        ) : props.warningTextGeneralCase ? (
          <WarningText>
            {' '}
            “You are trying to re-activate a case in the finalized stage ({props.currentStatus}).
            Are you sure?”
          </WarningText>
        ) : null}
      </DialogContent>
      <DialogActions>
        <CancelButton handleClose={handleClose} />
        <SubmitButton
          type={'button'}
          label={'Update'}
          isValidForm={!props.btnDisabled && hasChanged}
          handleClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
}
DropdownPopup.defaultProps = {
  open: false,
  title: '',
  label: '',
  placeholder: '',
  value: '', //required [] in case of multiSelect
  dropdownValues: [],
  disabled: false,
  required: false,
  requiredError: false,
  multiSelect: false,
  handleSubmit: null,
  handleClose: null,
  buttonLabel: '',
  warningText: false,
  hanldeStatusChange: null,
  btnDisabled: false
};
