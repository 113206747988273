import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Button } from '@material-ui/core/';

import Addons from './Addons';
import Summary from './Summary';
import PackageList from '../PackageListing/PackageList';
import CustomizePackage from '../CustomizePackage';
import ConfirmationPopup from '../../../../core-components/ConfirmationPopup';

const useStyles = makeStyles((theme) => ({
  progressContainer: {
    textAlign: 'center',
    width: '100%'
  },
  goBack: {
    height: '44px',
    fontSize: 14,
    lineHeight: 1
  },
  rupee: {
    fontFamily: 'roboto'
  }
}));

export default (props) => {
  const classes = useStyles();
  const [openCustomDialog, setOpenCustomDialog] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [updatedPackageDetail, setUpdatedPackageDetail] = useState(null);
  const packageType = props.companyBillingType === 'PREPAID' ? 'PREPAID' : 'POSTPAID';
  const { isAadhaarAllowed, tax, isBulkUpload, bulkCandidates } = props;

  const handleCustomDialog = () => {
    setOpenCustomDialog(!openCustomDialog);
  };

  const handleCustomizedConfigSubmit = (packageDetail = null) => {
    setShowConfirmationPopup(true);
    setUpdatedPackageDetail(packageDetail);
  };

  const cModalSubmit = () => {
    props.handleStepSubmit(['step3', 'step2'], [props.initialValStep3, updatedPackageDetail], 3);
    setUpdatedPackageDetail(null);
    setShowConfirmationPopup(false);
    handleCustomDialog();
  };

  const handleChoose = () => {
    handleCustomDialog();
  };

  return (
    <>
      <Box mt={4}>
        <Grid container spacing={2}>
          <Grid item container lg={8} md={8} sm={8} xs={8}>
            {props.step2Values ? (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <PackageList
                  showChoose={false}
                  showSelected={false}
                  packageDetails={props.step2Values}
                  fromStep3={true}
                  handleChoose={handleChoose}
                  isAadhaarAllowed={isAadhaarAllowed}
                />
              </Grid>
            ) : null}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Addons
                addCandidateConfig={props.addCandidateConfig}
                setFieldValue={(values) => {
                  props.handleStepSubmit(['step3'], [values]);
                }}
                pDetails={props.step2Values}
                values={props.savedValues}
                packageType={packageType}
                isAadhaarAllowed={isAadhaarAllowed}
              />
            </Grid>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <Summary
              candidateConfig={props.addCandidateConfig}
              tax={tax}
              robotoRupeeFont={classes.rupee}
              step1Values={props.step1Values}
              step2Values={props.step2Values}
              step3Values={props.savedValues}
              packageType={packageType}
              isBulkUpload={isBulkUpload}
              bulkCandidates={bulkCandidates}
            />
          </Grid>
        </Grid>
        <Box mt={3}>
          <Button
            color={'primary'}
            variant={'outlined'}
            onClick={() => props.handleStepChange(2)}
            size={'small'}
            className={classes.goBack}
          >
            {'Go back'}
          </Button>
        </Box>
      </Box>
      {openCustomDialog ? (
        <CustomizePackage
          open={openCustomDialog}
          handleClose={handleCustomDialog}
          selectedIndex={props.selectedIndex}
          updatedConfig={
            props?.step2Values &&
              props?.step2Values?.customizeConfig &&
              props?.step2Values?.customizeConfig[props.selectedIndex]
              ? props?.step2Values?.customizeConfig[props.selectedIndex]
              : null
          }
          isAadhaarAllowed={isAadhaarAllowed}
          selectedPackage={Object.assign({}, props.originalConfig)}
          handleCustomizedConfigSubmit={handleCustomizedConfigSubmit}
        />
      ) : null}
      {showConfirmationPopup ? (
        <ConfirmationPopup
          open={showConfirmationPopup}
          title={'Reset add-ons?'}
          content={'Customizing package will reset any add-ons selected.'}
          handleSubmit={() => {
            cModalSubmit();
          }}
          handleClose={() => {
            setShowConfirmationPopup(false);
          }}
          buttonLabel={'Reset add-ons'}
        />
      ) : null}
    </>
  );
};
