import React, { useState } from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Field } from 'formik';
import { toast } from 'react-toastify';

import Button from '../../../../core-components/Button';
import FormInput from '../../../../core-components/FormInput';
import Dropdown from '../../../../core-components/DropdownInput';
import { Box, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import CustomizedSwitch from '../../../../core-components/CustomizedSwitch';
import { has, isEmpty } from 'lodash';
import CustomValidatedSelect from '../../../../core-components/CustomValidatedSelect';
import { isValidPhoneNumber, getTrimmedValue } from '../../../../utils/Utlities';
import countryNames from '../../../../utils/countryNamesEn.json';
import countryOptions from '../../../../utils/countryCodesEn.json';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(() => ({
  typographyStyles: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: '4px'
  },
  phoneErrorMsg: {
    fontSize: '12px',
    color: '#f44336',
    marginBottom: '1rem'
  },
  marginT: {
    marginTop: '-25px'
  }
}));

const defaultStyles = {
  titleBold: { fontWeight: 600, fontSize: 17 },
  defaultCheckbox: { color: '#6558f5' },
  defaultGridContainer: { padding: '0px 10px' },
  defaultGridItem: { padding: '10px' },
  disabled: { color: '#c0c0c0' }
};

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, title, resetForm, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{title} Admin</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={() => {
            resetForm();
            onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const getDisabledAccessPermissionList = (props) => {
  const disabledAccessPermissionList = ['EMP'];
  // TODO : Populate the disabledAccessPermissionList from props.

  return disabledAccessPermissionList;
};

export default (props) => {
  const accessLevelValues = [
    { label: 'Admin', value: 5 },
    { label: 'Operations', value: 11 },
    { label: 'Operations: Misc', value: 1 },
    { label: 'Operations: Employment', value: 2 },
    { label: 'Operations: Education', value: 3 },
    { label: 'Operations: Address', value: 4 }
  ];
  const { handleSubmit, setFieldValue, setFieldTouched, errors, values } = props;
  const classes = useStyles();

  const checkboxDisabled =
    props.values.isEscalate ||
    !props.values.isTelephonyEnabled ||
    !props.values.isSupervisorModeEnabled;

  const handleDropdown = (value) => {
    setFieldValue('accessLevel', value);
  };

  const handleEsclation = (value) => {
    if (value) {
      setFieldValue('isCs', true);
      setFieldValue('isAdd', true);
      setFieldValue('isEmp', true);
      setFieldValue('isRef', true);
      setFieldValue('isEdu', true);
    } else {
      setFieldValue('isCs', false);
      setFieldValue('isAdd', false);
      setFieldValue('isEmp', false);
      setFieldValue('isRef', false);
      setFieldValue('isEdu', false);
    }
  };
  const isEdit = props?.title === 'Edit';

  const setValue = (fieldname, value, withSpace) => {
    const trimmedVal = value === '' ? '' : getTrimmedValue(value, withSpace);
    setFieldValue(fieldname, trimmedVal);
    return trimmedVal;
  };

  return (
    <div>
      <Dialog
        onClose={() => {
          props.resetForm();
          props.handleClose();
        }}
        aria-labelledby='customized-dialog-title'
        open={props.open}
      >
        <DialogTitle
          id='customized-dialog-title'
          title={props.title}
          onClose={props.handleClose}
          resetForm={props.resetForm}
        ></DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item lg={12} md={12}>
              <form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item lg={5} md={5} style={defaultStyles.defaultGridItem}>
                    <Field
                      type='text'
                      name='name'
                      fullWidth={false}
                      requiredfield={'true'}
                      label='Name'
                      component={FormInput}
                      error={isEdit && errors.name}
                      onBlur={(event) => {
                        const trimmedValue = getTrimmedValue(event.target.value, true);
                        setFieldValue('name', trimmedValue);
                      }}
                    />
                  </Grid>
                  <Grid item lg={7} md={7} style={defaultStyles.defaultGridItem}>
                    <Field
                      type='email'
                      name='email'
                      label='Email'
                      requiredfield={'true'}
                      component={FormInput}
                      error={isEdit && errors.email}
                      onBlur={(event) => {
                        const trimmedValue = getTrimmedValue(event.target.value, false);
                        setFieldValue('email', trimmedValue, true);
                        setFieldTouched('email', true, true);
                      }}
                    />
                  </Grid>
                  <Grid item lg={5} md={5} style={defaultStyles.defaultGridItem}>
                    <Dropdown
                      dropdownValues={accessLevelValues}
                      name='accessLevel'
                      label={'Access Level'}
                      value={props.values.accessLevel}
                      handleSelect={(value) => {
                        handleDropdown(value);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={7}
                    md={7}
                    style={defaultStyles.defaultGridItem}
                    className={classes.marginT}
                  >
                    <Typography component='legend' className={classes.typographyStyles}>
                      Mobile
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xl={5} md={5} lg={5} sm={5} xs={12}>
                        <Field
                          type='text'
                          name='countryCode'
                          defaultValue={values.countryCode}
                          options={countryNames}
                          filterLabel={countryOptions}
                          component={CustomValidatedSelect}
                          onChange={(e) => {
                            setFieldValue('isValidPhone', true);

                            if (values.mobile && e) {
                              let intlPhoneNumber = `${e + '' + values.mobile}`;
                              if (!isValidPhoneNumber(intlPhoneNumber)) {
                                setFieldValue('isValidPhone', false);
                              } else {
                                setFieldValue('isValidPhone', true);
                              }
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xl={7} md={7} lg={7} sm={7} xs={12}>
                        <Field
                          type='text'
                          name='mobile'
                          component={FormInput}
                          variant='outlined'
                          fullWidth
                          error={isEdit && errors.mobile}
                          onBlur={(e) => {
                            props.handleBlur(e);
                            const trimmedVal = setValue('mobile', values.mobile, false);
                            setFieldValue('isValidPhone', true);
                            if (trimmedVal && values.mobile && values?.countryCode) {
                              let intlPhoneNumber = `${values.countryCode + '' + trimmedVal}`;
                              if (!isValidPhoneNumber(intlPhoneNumber)) {
                                setFieldValue('isValidPhone', false);
                              } else {
                                setFieldValue('isValidPhone', true);
                              }
                            }
                          }}
                        />
                        {!values.isValidPhone && (
                          <div className={classes.phoneErrorMsg}>
                            {!values.isValidPhone ? 'Please add a valid Phone Number' : ''}
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container style={defaultStyles.defaultGridItem}>
                    <Grid item lg={6} md={6}>
                      <Typography style={defaultStyles.titleBold}>Supervisor Mode</Typography>
                    </Grid>
                    <Grid item lg={6} md={6}>
                      <Box display='flex' justifyContent='flex-end'>
                        <CustomizedSwitch
                          name='supervisorMode'
                          checked={props.values.supervisorMode}
                          handleChange={() => {
                            let superVisor = false;
                            props.profile?.userAccessRights?.forEach((element) => {
                              if (element.right === 'SUPERVISOR') {
                                superVisor = true;
                              }
                            });
                            if (superVisor) {
                              setFieldValue('supervisorMode', !props.values.supervisorMode);
                            } else {
                              toast.info('Need supervisor access to enable');
                            }
                          }}
                          color={'#d3455b'}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item container style={{ padding: '0px 10px' }}>
                    <Grid item lg={6} md={6}>
                      <Typography style={defaultStyles.titleBold}>Telephony</Typography>
                    </Grid>
                    <Grid item lg={6} md={6}>
                      <Box display='flex' justifyContent='flex-end'>
                        <CustomizedSwitch
                          name='isTelephonyEnabled'
                          checked={props.values.isTelephonyEnabled}
                          handleChange={() => {
                            let isTelephonyEnabled = false;
                            // eslint-disable-next-line no-unused-expressions
                            props.profile?.userAccessRights?.forEach((element) => {
                              if (element.right === 'SUPERVISOR') {
                                isTelephonyEnabled = true;
                              }
                            });
                            if (isTelephonyEnabled) {
                              setFieldValue('isTelephonyEnabled', !props.values.isTelephonyEnabled);
                            } else {
                              toast.info('Need supervisor access to enable');
                            }
                          }}
                          color={'#1AAE9F'}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item style={defaultStyles.defaultGridItem}>
                    <Field
                      style={{ height: '35px', width: '205px' }}
                      type='text'
                      name='telephony'
                      fullWidth={false}
                      label='User Phone Number'
                      requiredfield={props.values.isTelephonyEnabled ? true : false}
                      component={FormInput}
                      disabled={
                        !props.values.isTelephonyEnabled || !props.values.isSupervisorModeEnabled
                      }
                      variant='outlined'
                      onBlur={(e) => {
                        const trimmedValue = getTrimmedValue(e.target.value, false);
                        setFieldValue('telephony', trimmedValue, true);
                        setFieldTouched('telephony', true, false);
                      }}
                      error={isEdit && errors.telephony}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <div style={{ margin: '5px 10px' }}>
                    <Typography style={{ fontWeight: 600, fontSize: 12, color: '#788896' }}>
                      Number registered with telephony service provider to dial or receive calls
                    </Typography>
                  </div>
                </Grid>
                <Grid item style={defaultStyles.defaultGridContainer}>
                  <div style={{ margin: '10px 0px' }}>
                    <Typography style={defaultStyles.titleBold}>Access Permissions</Typography>
                  </div>
                </Grid>
                <Grid item container style={defaultStyles.defaultGridContainer}>
                  <Grid item container lg={5}>
                    <Grid item lg={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='isEscalate'
                            checked={props.values.isEscalate}
                            disabled={
                              !props.values.isTelephonyEnabled ||
                              !props.values.isSupervisorModeEnabled
                            }
                            onChange={(e) => {
                              setFieldValue('isEscalate', !props.values.isEscalate);
                              handleEsclation(e.target.checked);
                            }}
                            style={
                              !props.values.isTelephonyEnabled ||
                              !props.values.isSupervisorModeEnabled
                                ? defaultStyles?.disabled
                                : defaultStyles.defaultCheckbox
                            }
                          />
                        }
                        label='ESCALATE'
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='isCs'
                            checked={props.values.isCs}
                            onChange={() => {
                              setFieldValue('isCs', !props.values.isCs);
                            }}
                            disabled={checkboxDisabled}
                            style={
                              checkboxDisabled
                                ? defaultStyles?.disabled
                                : defaultStyles.defaultCheckbox
                            }
                          />
                        }
                        label='CS'
                      />
                    </Grid>
                  </Grid>
                  <Grid item container lg={7}>
                    <Grid item lg={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='isAdd'
                            checked={props.values.isAdd}
                            disabled={checkboxDisabled}
                            onChange={() => {
                              setFieldValue('isAdd', !props.values.isAdd);
                            }}
                            style={
                              checkboxDisabled
                                ? defaultStyles?.disabled
                                : defaultStyles.defaultCheckbox
                            }
                          />
                        }
                        label='ADD'
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='isEmp'
                            checked={props.values.isEmp}
                            disabled={checkboxDisabled}
                            onChange={() => {
                              setFieldValue('isEmp', !props.values.isEmp);
                            }}
                            style={
                              checkboxDisabled
                                ? defaultStyles?.disabled
                                : defaultStyles.defaultCheckbox
                            }
                          />
                        }
                        label='EMP'
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='isRef'
                            checked={props.values.isRef}
                            disabled={checkboxDisabled}
                            onChange={() => {
                              setFieldValue('isRef', !props.values.isRef);
                            }}
                            style={
                              checkboxDisabled
                                ? defaultStyles?.disabled
                                : defaultStyles.defaultCheckbox
                            }
                          />
                        }
                        label='REF'
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='isEdu'
                            checked={props.values.isEdu}
                            disabled={checkboxDisabled}
                            onChange={() => {
                              setFieldValue('isEdu', !props.values.isEdu);
                            }}
                            style={
                              checkboxDisabled
                                ? defaultStyles?.disabled
                                : defaultStyles.defaultCheckbox
                            }
                          />
                        }
                        label='EDU'
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={
              !isEmpty(props.errors) || values?.isValidPhone === false
                ? true
                : false || !props.dirty || props.isSubmitting
            }
            onClick={handleSubmit}
            color={'green'}
            label={'Save'}
            size={'large'}
          ></Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
