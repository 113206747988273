import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { DropdownList } from 'react-widgets';
import styles from './Dropdownsearch.module.scss';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Input from '../../frontend-common/core-components/Input/Input';
import InputBase from '@material-ui/core/InputBase';
import SearchInputIcon from '../../../../images/SearchInputIcon.svg';
import Mask from '../../../../images/Mask.svg';
import { getTrimmedValue } from '../../../../utils/Utlities';

export default function DropDownSearch({ dropdownValues, searchCandidate, onClickAway }) {
  const [searchTerm, setIsSearchTerm] = useState('');
  const [dropdownTerm, setIsDropdownTerm] = useState(dropdownValues[0].label || 'Select');

  const debounceApplySearch = useCallback((inputVal, dropdownTerm) => {
    if (!inputVal) {
      onClickAway();
      return;
    }
    const dropdownValue = dropdownValues.find((item) => item.label === dropdownTerm);
    return applySearch(getTrimmedValue(inputVal, true), dropdownValue?.value);
  }, []);

  const applySearch = debounce((value, dropdownTerm) => {
    if (value && value.length >= 2 && value !== 'c:') {
      const splitValue = value.split('c:');
      searchCandidate({
        candidateQuery: splitValue[0].trim(),
        companyQuery: (splitValue[1] && splitValue[1].trim()) || '',
        field: dropdownTerm
      });
    }
    return;
  }, 500);

  return (
    <div className={styles.searchFilter}>
      <div className={styles.searchFilterType}>
        <DropdownList
          data={dropdownValues}
          placeholder={<span className={styles.dropDownPlaceholder}>Select</span>}
          containerClassName={cn(styles.dropDownStyle, styles.dropDownBorderNone)}
          value={dropdownTerm}
          textField='label'
          name='searchType'
          selectIcon={<Mask />}
          onChange={(e) => {
            debounceApplySearch(searchTerm, e.label);
            setIsDropdownTerm(e.label);
          }}
        />
      </div>
      <div className={styles.searchInput}>
        <InputBase
          placeholder={`Search by ${dropdownTerm}`}
          className={styles.inputText}
          size='small'
          inputProps={{ 'aria-label': 'search' }}
          value={searchTerm}
          onChange={(e) => {
            const inputVal = e?.target?.value;

            setIsSearchTerm(inputVal);
            debounceApplySearch(inputVal, dropdownTerm);
          }}
          onFocus={(e) => {
            const inputVal = e?.target?.value;

            setIsSearchTerm(inputVal);
            debounceApplySearch(inputVal, dropdownTerm);
          }}
          onBlur={(e) => {
            setIsSearchTerm(getTrimmedValue(e.target.value, true));
          }}
        />
        <SearchInputIcon className={styles.searchIcon} />
      </div>
    </div>
  );
}

DropDownSearch.propTypes = {
  dropdownValues: PropTypes.array,
  onClickAway: PropTypes.func,
  searchCandidate: PropTypes.func
};

DropDownSearch.defaultProps = {
  dropdownValues: [{ label: '', value: '' }],
  onClickAway: () => {},
  searchCandidate: () => {}
};
