import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import MobileMenuItem from './MobileMenuItem';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

export default ({ headerDropdown, user, handleDrawerToggle }) => {
  const classes = useStyles();

  return (
    <List component='nav' aria-labelledby='nested-list-subheader' className={classes.root}>
      {headerDropdown(user).map((item, index) => {
        return item.child && item.child.length ? (
          <React.Fragment key={`${item.title}_mobile-menu-container_${index}`}>
            <MobileMenuItem item={item} index={index} handleDrawerToggle={handleDrawerToggle} />
          </React.Fragment>
        ) : (
          <ListItem
            button
            component='a'
            href={item.href || '#'}
            target='_self'
            key={`${item.title}_mobile-menu-container_${index}`}
          >
            <ListItemText primary={item.title} />
          </ListItem>
        );
      })}
    </List>
  );
};
