import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, forEach, isEmpty } from 'lodash';
import { toast } from 'react-toastify';

import {
  getCompanyServices,
  createCandidate as createCandidateAPI,
  editCandidate as editCandidateAPI,
  getViewCandidate as getViewCandidateAPI,
  invalidateViewCandidates as invalidateViewCandidatesAPI,
  getCompanyBgvConfig as getCompanyBgvConfigAPI,
  invalidateCompanyBgvConfig as invalidateCompanyBgvConfigAPI,
  getAddCandidateConfig as getAddCandidateConfigAPI,
  invalidateAddCandidateConfig as invalidateAddCandidateConfigAPI
} from '../../../actions/admin';
import validationSchema from './CreateCandidate.validation';

import {
  errToastMessage,
  getErrorMessage,
  getIntlPhoneNumberObject
} from '../../../utils/Utlities';

const formatCompanyServices = (services = {}) => {
  const cServices = [];

  for (let value in services) {
    if (value !== 'company') {
      cServices.push({ ...services[value] });
    }
  }
  return cServices;
};

const mapStateToProps = (state) => {
  return {
    addCandidateConfig:
      state.addCandidateConfig && !isEmpty(state.addCandidateConfig)
        ? state.addCandidateConfig
        : null,
    companyServices:
      (!isEmpty(state.companyServices.companyServices) &&
        formatCompanyServices(state.companyServices.companyServices)) ||
      [],
    companyData:
      (!isEmpty(state.companyServices.companyServices) &&
        state?.companyServices?.companyServices.company) ||
      {},
    editCandidateData:
      (!isEmpty(state.viewCandidateData.viewCandidateData) &&
        state?.viewCandidateData?.viewCandidateData) ||
      {},
    companyBgvConfig: (!isEmpty(state.companyBgvConfig) && state.companyBgvConfig) || null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyServices: (companyId) => dispatch(getCompanyServices(companyId)),
    createCandidate: (data) => dispatch(createCandidateAPI(data)),
    editCandidate: (data) => dispatch(editCandidateAPI(data)),
    getViewCandidate: (candidateId, companyId) =>
      dispatch(getViewCandidateAPI(candidateId, companyId)),
    invalidateViewCandidates: () => dispatch(invalidateViewCandidatesAPI()),
    invalidateCompanyBgvConfig: () => dispatch(invalidateCompanyBgvConfigAPI()),
    getCompanyBgvConfig: (companyId) => dispatch(getCompanyBgvConfigAPI(companyId)),
    getAddCandidateConfig: (companyId) => dispatch(getAddCandidateConfigAPI(companyId)),
    invalidateAddCandidateConfig: () => dispatch(invalidateAddCandidateConfigAPI())
  };
};

const formatServices = (services, selectedIdentity, addIdentityType) => {
  let formattedServices = {};
  forEach(services, function (value) {
    if (value.toString() === '1') {
      formattedServices[value.toString()] = { isSelected: true };
      // if (addIdentityType) {
      formattedServices[value.toString()].selectedIdentity = selectedIdentity;
      // }
    } else {
      formattedServices[value.toString()] = true;
    }
  });
  return formattedServices;
};

const formatObject = (formData, isEdit) => {
  let finalData = { ...formData };
  if (!isEdit) {
    let services = formatServices(formData.services, formData.selectedIdentity);
    finalData.services = JSON.stringify(services);
  }
  if (isEdit) {
    delete finalData['services'];
  }
  finalData.phone = formData?.phone ? formData?.countryCode + formData?.phone : formData?.phone;
  finalData.altPhone = formData?.altPhone
    ? formData?.altCountryCode + formData?.altPhone
    : formData?.altPhone;
  if (finalData.category) {
    finalData.category = finalData.category.id || null;
  } else {
    delete finalData.category;
  }
  if (finalData.tags && finalData.tags.length && !isEdit) {
    finalData.tags = JSON.stringify(finalData.tags);
  }
  return finalData;
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        companyId: '',
        name: '',
        email: '',
        emailExists: false,
        alternateEmail: '',
        countryCode: '+91',
        phone: '',
        phoneExists: false,
        isValidPhone: true,
        isAltValidPhone: true,
        altCountryCode: '+91',
        altPhone: '',
        employeeId: '',
        uanNumber: '',
        resume: '',
        resumeLink: undefined,
        resumeName: '',
        consent: '',
        services: [],
        selectedIdentity: 1,
        isEdit: false,
        invite: false,
        formConfig: {},
        tags: [],
        category: ''
      };

      let propsValue = {};

      if (props.history.location.pathname === '/candidate/update') {
        let companyId = new URLSearchParams(props.history.location.search).get('company_id');
        let candidateId = new URLSearchParams(props.history.location.search).get('id');

        propsValue = {
          companyId: companyId,
          candidateId: candidateId,
          isEdit: true
        };
        if (!isEmpty(props.editCandidateData)) {
          let candidateData = props.editCandidateData;
          propsValue = {
            ...propsValue,
            name: candidateData.candidate.name || '',
            email: candidateData.candidate.email || '',
            alternateEmail: candidateData.candidate.alternateEmail || '',
            phone: candidateData.phoneNumber || '',
            altPhone: candidateData.altPhoneNumber || '',
            employeeId: candidateData.employeeId || '',
            uanNumber: candidateData.uanNumber || '',
            gender: candidateData.candidate.gender || null,
            resume: candidateData.resume || '',
            resumeLink: candidateData.resume || undefined,
            altPhoneRef: {}
          };
          if (propsValue?.phone) {
            let phoneNumberObj = getIntlPhoneNumberObject(propsValue.phone);
            if (phoneNumberObj) {
              propsValue.phone = phoneNumberObj.nationalNumber;
              propsValue.countryCode = `+${phoneNumberObj.countryCallingCode}`;
            }
          }
          if (candidateData?.candidate?.candidatesMetadata) {
            const altPhoneNumberMeta = candidateData?.candidate?.candidatesMetadata?.find(
              (p) => p.belongsTo === 'CANDIDATE' && p.type === 'PHONENUMBER'
            );
            if (altPhoneNumberMeta) {
              propsValue['altPhoneRef'] = altPhoneNumberMeta;
              let altPhoneNumberObj = getIntlPhoneNumberObject(altPhoneNumberMeta?.data);
              if (altPhoneNumberObj) {
                propsValue['altPhone'] = altPhoneNumberObj.nationalNumber;
                propsValue['altCountryCode'] = `+${altPhoneNumberObj.countryCallingCode}`;
              }
            }
          }
        }
      } else {
        let companyId = new URLSearchParams(props.history.location.search).get('company_id');

        propsValue = {
          companyId: companyId,
          selectedIdentity:
            props?.companyServices &&
            props?.companyServices.length &&
            props?.companyServices[0]['identities'] &&
            props?.companyServices[0]['identities'].length === 1
              ? props?.companyServices[0]?.identities[0]?.id
              : 1
        };
      }
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (values, { setFieldError, setStatus, setSubmitting, resetForm, props }) => {
      const finalValue = formatObject(values, values.isEdit);
      if (values.isEdit) {
        const formData = { ...finalValue, phone_numbers: [] };
        // Add alternative phone number if provided
        if (finalValue.altCountryCode && finalValue.altPhone) {
          const altPhone = {
            ...finalValue.altPhoneRef,
            data: finalValue.altPhone,
            belongs_to: 'CANDIDATE'
          };
          formData.phone_numbers.push(altPhone);
        } else if (!finalValue.altPhone && !isEmpty(finalValue.altPhoneRef)) {
          formData.phone_numbers.push({ ...finalValue.altPhoneRef, is_deleted: true });
        } else {
          delete formData.phone_numbers;
        }
        props
          .editCandidate(formData)
          .then(() => {
            setSubmitting(false);
            props.history.push({
              pathname: '/candidate/view',
              search: `?id=${values.candidateId}&company_id=${values.companyId}`
            });
          })
          .catch((error) => {
            errToastMessage(error);
            console.error(error);
            setSubmitting(false);
          });
      } else {
        props
          .createCandidate(finalValue)
          .then((response) => {
            setSubmitting(false);
            let candidateId = response.data.data.candidate.id;

            props.history.push({
              pathname: '/candidate/view',
              search: `?id=${candidateId}&company_id=${values.companyId}`
            });
          })
          .catch((error) => {
            errToastMessage(error);
            console.error(error);
            setSubmitting(false);
          });
      }
    },
    displayName: 'Create Candidate'
  })
);
