import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const StyledTableCell = withStyles(() => ({
  root: {
    borderBottom: 'none'
  },
  head: {
    fontSize: 18,
    color: '#333333',
    backgroundColor: '#F2F8FF',
    fontWeight: 600
  },
  body: {
    fontSize: 14,
    color: '#666666'
  }
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: '#FAFCFF'
    }
  }
}))(TableRow);

const useStyles = makeStyles({
  table: {
    margin: '32px auto',
    minWidth: 500,
    border: '1px solid rgba(0, 0, 0, 0.05)',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
    borderRadius: 4
  },
  label: {
    fontWeight: 600
  }
});

export default function DetailsCard(props) {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} aria-label='customized table' data-testid="customized-table">
        {props.heading || props.actions ? (
          <TableHead>
            <TableRow>
              {props.heading && <StyledTableCell>{props.heading}</StyledTableCell>}
              {props.actions && <StyledTableCell align={'right'}>{props.actions}</StyledTableCell>}
            </TableRow>
          </TableHead>
        ) : (
          ''
        )}
        <TableBody>
          {props.additionalActions && (
            <StyledTableRow>
              <StyledTableCell data-testid='addActions' colSpan='2'>
                {props.additionalActions}
              </StyledTableCell>
            </StyledTableRow>
          )}
          {props.data.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell className={classes.label}>{row.label}</StyledTableCell>
              <StyledTableCell>{row.value}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

DetailsCard.defaultProps = {
  data: [], //display Data in form of [] of {label,value}
  heading: '', //heading of the card
  actions: '', //if any actions needed beside Heading
  additionalActions: null //if any actions needed below Heading
};
