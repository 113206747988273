import React, { useEffect, useState, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  Box,
  CardActions,
  CardContent,
  Button,
  Typography,
  Divider
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ordinal from 'ordinal';

import {
  IdentityArr,
  AddressArr,
  EducationArr,
  addressVerificationMediumObject,
  courtVerificationMediumObject,
  ADDON_CONFIG_MAPPING
} from '../../../../../core-components/CommonConstants';

import { getCount } from '../../../../../utils/Utlities';
import { SubmitContext } from '../../AddCandidate';

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  infoBtn: {
    color: '#ffffff',
    borderColor: '#388e3c',
    background: 'linear-gradient(to right, #0844a6 0%, #086590 48.96%, #088077 100%)',
    fontSize: 14,
    textTransform: 'inherit',
    '&:hover': {
      background: 'linear-gradient(to right, #0844a6 0%, #086590 48.96%, #088077 100%)'
    },
    width: '100%'
  },
  heading: {
    color: '#999999',
    fontSize: '12px',
    fontWeight: 500
  },
  headingInfo: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#333333'
  },
  headingDescription: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#666666'
  },
  priceText: {
    fontFamily: 'roboto',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#666666'
  },
  priceTextCheck: {
    fontFamily: 'roboto',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#666666'
  },
  summaryChecksContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  creditBalanceText: {
    color: '#333333',
    fontSize: '14px'
  },
  creditBalancePriceText: {
    fontFamily: 'roboto',
    color: '#333333',
    fontSize: '14px'
  },
  insuffBalancePriceText: {
    color: '#FF0000',
    fontFamily: 'roboto',
    fontSize: '14px'
  },
  taxText: {
    color: '#999999',
    fontSize: '14px'
  },
  taxPrice: {
    fontFamily: 'Roboto',
    color: '#999999',
    fontSize: '14px'
  }
});

const getArraySum = (arr = []) => {
  let sum = arr.reduce((acc, item) => {
    return acc + item;
  }, 0);
  return sum;
};
const getEduPrice = (values, config) => {
  let price = 0;
  if (values.education && values.education.method) {
    switch (values.education.method) {
      case 'OFFICIAL':
        price = config.addons.education?.price || 0;
        break;
      case 'HYBRID':
        price = config.addons.educationHybrid?.price || 0;
        break;
      case 'REGIONAL_PARTNER':
        price = config.addons.educationThirdParty?.price || 0;
        break;

      default:
        break;
    }
  }
  return price;
};

export default function OutlinedCard(props) {
  const classes = useStyles(),
    {
      step1Values,
      step2Values,
      step3Values,
      candidateConfig,
      packageType,
      tax,
      isBulkUpload,
      bulkCandidates
    } = props;
  const [totalPrice, setTotalPrice] = useState(0);
  const [numberOfCandidates, setNumberOfCandidates] = useState(isBulkUpload ? bulkCandidates : 1);
  const [buttonClicked, setButtonClicked] = useState(false);
  const { startSubmit, isSubmitting } = useContext(SubmitContext);

  useEffect(() => {
    setTotalPrice(calculateTotalPrice(step3Values, candidateConfig));
  }, [props.step3Values]);

  const creditBalance =
    candidateConfig.credits && candidateConfig.credits.paymentsCompanyCreditBalance
      ? candidateConfig.credits.paymentsCompanyCreditBalance.creditBalance
      : 0;
  const calculateTotalPrice = (values = {}, config = {}) => {
    let price = 0;
    const step3Values = Object.assign({}, values);

    if (step3Values.identity && (step3Values.identity.any || step3Values.identity.config.length)) {
      price += step3Values.identity.any
        ? config?.addons?.identity?.price * step3Values.identity.any
        : 0;
      price +=
        step3Values.identity.config && step3Values.identity.config.length
          ? config?.addons?.identity?.price * step3Values.identity.config.length
          : 0;
    }

    if (
      step3Values.address &&
      (step3Values.address.any || step3Values.address.config.length) &&
      step3Values?.address?.verification_type
    ) {
      price += step3Values.address.any
        ? config?.addons?.address?.price * step3Values.address.any
        : 0;
      price +=
        step3Values.address.config && step3Values.address.config.length
          ? config?.addons[ADDON_CONFIG_MAPPING[step3Values?.address?.verification_type]]?.price *
          step3Values.address.config.length
          : 0;
    }

    if (
      step3Values.court &&
      (step3Values.court.any || step3Values.court.config.length) &&
      step3Values?.court?.verification_type
    ) {
      price += step3Values.court.any ? config.addons.court.price * step3Values.court.any : 0;
      price +=
        step3Values.court.config && step3Values.court.config.length
          ? config?.addons[ADDON_CONFIG_MAPPING[step3Values?.court?.verification_type]]?.price *
          step3Values.court.config.length
          : 0;
    }
    if (
      step3Values.educationOfficial &&
      (step3Values.educationOfficial.count || step3Values.educationOfficial.config.length)
    ) {
      price += step3Values.educationOfficial.count
        ? config?.addons?.education?.price * step3Values.educationOfficial.count
        : 0;
      price += step3Values.educationOfficial.config.length
        ? config?.addons?.education?.price * step3Values.educationOfficial.config.length
        : 0;
    }

    if (
      step3Values.educationHybrid &&
      (step3Values.educationHybrid.count || step3Values.educationHybrid.config.length)
    ) {
      price += step3Values.educationHybrid.count
        ? config?.addons?.educationHybrid?.price * step3Values.educationHybrid.count
        : 0;
      price += step3Values.educationHybrid.config.length
        ? config?.addons?.educationHybrid?.price * step3Values.educationHybrid.config.length
        : 0;
    }

    if (
      step3Values.educationRegionalPartner &&
      (step3Values.educationRegionalPartner.count ||
        step3Values.educationRegionalPartner.config.length)
    ) {
      price += step3Values.educationRegionalPartner.count
        ? config?.addons?.educationThirdParty?.price * step3Values.educationRegionalPartner.count
        : 0;
      price += step3Values.educationRegionalPartner.config.length
        ? config?.addons?.educationThirdParty?.price *
        step3Values.educationRegionalPartner.config.length
        : 0;
    }

    if (
      step3Values.education &&
      (step3Values.education.count ||
        (step3Values.education.config && step3Values.education.config.length))
    ) {
      price +=
        step3Values.education.count && step3Values.education.type !== 'SPECIFIC'
          ? getEduPrice(step3Values, config) * step3Values.education.count
          : 0;
      price += step3Values.education.config.length
        ? getEduPrice(step3Values, config) * step3Values.education.config.length
        : 0;
    }

    if (step3Values.employment) {
      price += config?.addons?.employment?.price * step3Values.employment;
    }

    if (
      step3Values.reference &&
      step3Values.reference.config &&
      step3Values.reference.config.length
    ) {
      price += config.addons.reference.price * getArraySum(step3Values.reference.config);
    }

    if (step3Values.world) {
      price += config.addons.world.price;
    }

    if (step3Values.cibil) {
      price += config.addons.cibil.price;
    }

    if (step3Values.drug5Panel) {
      price += config.addons.drug5Panel.price;
    }
    if (step3Values.drug7Panel) {
      price += config.addons.drug7Panel.price;
    }
    if (step3Values.drug10Panel) {
      price += config.addons.drug10Panel.price;
    }

    return price * numberOfCandidates;
  };

  const getAddonPriceForBulk = (addonPrice) => {
    if (numberOfCandidates > 1) {
      return `(${numberOfCandidates} * ₹${addonPrice})`;
    } else {
      return '';
    }
  };
  const getVerificationMedium = (medium, type) => {
    switch (type) {
      case 'ADDRESS':
        return medium ? addressVerificationMediumObject[medium] : '-';
      case 'COURT':
        return medium ? courtVerificationMediumObject[medium] : '-';
      default:
        break;
    }
  };
  const isDisabled = () => {
    const isAddresInvalid =
      step3Values.address.config.length > 0 && step3Values.address.verification_type === '';
    const isCourtInvalid =
      step3Values.court.config.length > 0 && step3Values.court.verification_type === '';
    let isEduInvalid = false;
    if (step3Values?.education?.count > 0 || step3Values?.education?.config?.length > 0) {
      isEduInvalid = step3Values?.education?.type === '' || step3Values?.education?.method === '';
    }
    return (
      buttonClicked || isSubmitting ||
      (step3Values?.education?.type === 'SPECIFIC'
        ? step3Values?.education?.count === step3Values?.education?.config?.length
          ? false
          : true
        : false) ||
      isAddresInvalid ||
      isCourtInvalid ||
      isEduInvalid
    );
  };

  const isAddonAdded = () => {
    return (
      (step3Values.identity && (step3Values.identity.any || step3Values.identity.config.length)) ||
      (step3Values.address && (step3Values.address.any || step3Values.address.config.length)) ||
      (step3Values.court && (step3Values.court.any || step3Values.court.config.length)) ||
      (step3Values.educationOfficial &&
        (step3Values.educationOfficial.count || step3Values.educationOfficial.config.length)) ||
      (step3Values.educationHybrid &&
        (step3Values.educationHybrid.count || step3Values.educationHybrid.config.length)) ||
      (step3Values.educationRegionalPartner &&
        (step3Values.educationRegionalPartner.count ||
          step3Values.educationRegionalPartner.config.length)) ||
      (step3Values.education &&
        (step3Values.education.count ||
          (step3Values.education.config && step3Values.education.config.length))) ||
      step3Values.employment ||
      (step3Values.reference &&
        step3Values.reference.config &&
        step3Values.reference.config.length) ||
      step3Values.world ||
      step3Values.cibil ||
      step3Values.drug5Panel ||
      step3Values.drug7Panel ||
      step3Values.drug10Panel
    );
  };
  return (
    <>
      <Card className={classes.root} variant='outlined'>
        <CardContent>
          <Box mb={3}>
            <Typography variant='h6' gutterBottom>
              {'Summary'}
            </Typography>
          </Box>

          {!isBulkUpload && numberOfCandidates === 1 ? (
            <Box mb={3}>
              <Typography variant='subtitle2' gutterBottom className={classes.heading}>
                {'CANDIDATE'}
              </Typography>
              <Typography variant='subtitle2' gutterBottom className={classes.headingInfo}>
                {step1Values?.name}
              </Typography>
              <Typography variant='subtitle2' gutterBottom className={classes.headingDescription}>
                {step1Values?.email}
              </Typography>
            </Box>
          ) : (
            <Box mb={3}>
              <Typography variant='subtitle2' gutterBottom className={classes.heading}>
                {'TOTAL CANDIDATES COUNT:'}
              </Typography>
              <Typography variant='subtitle2' gutterBottom className={classes.headingInfo}>
                {numberOfCandidates}
              </Typography>
            </Box>
          )}

          {step2Values ? (
            <Box className={classes.summaryChecksContainer} mb={3}>
              <div>
                <Typography variant='subtitle2' gutterBottom className={classes.heading}>
                  {'PACKAGE'}
                </Typography>
                <Typography variant='subtitle2' gutterBottom className={classes.headingInfo}>
                  {`${numberOfCandidates} × ${step2Values.subtypeName}`}
                </Typography>
              </div>
              {packageType === 'POSTPAID' && (
                <Typography component={'span'} className={classes.priceText}>
                  &#8377;{step2Values.costPerCandidate * numberOfCandidates}
                </Typography>
              )}
            </Box>
          ) : null}

          {step3Values && isAddonAdded() ? (
            <>
              <Box mb={2}>
                <Typography
                  variant='subtitle2'
                  gutterBottom
                  className={classes.heading}
                  style={{ marginBottom: '16px' }}
                >
                  {'ADD-ONS'}
                </Typography>
                {step3Values.identity &&
                  (step3Values.identity.any || step3Values.identity.config.length) ? (
                  <Box mb={2}>
                    <Typography
                      component={'p'}
                      className={classes.headingInfo}
                      style={{ marginBottom: '8px' }}
                    >
                      {'Identity'}
                    </Typography>

                    {step3Values.identity.any ? (
                      <Box className={classes.summaryChecksContainer} mb={1}>
                        <div>
                          <Typography component={'span'} className={classes.priceTextCheck}>
                            {`${step3Values.identity.any} * Any ID`}
                            {getAddonPriceForBulk(candidateConfig.addons.identity.price)}
                          </Typography>
                        </div>

                        <Typography component={'span'} className={classes.priceText}>
                          &#8377;{' '}
                          {`${candidateConfig.addons.identity.price *
                            step3Values.identity.any *
                            numberOfCandidates
                            }`}
                        </Typography>
                      </Box>
                    ) : null}

                    {step3Values?.identity?.config && step3Values?.identity?.config?.length
                      ? step3Values?.identity?.config.map((item, index) => {
                        return (
                          <Box className={classes.summaryChecksContainer} mb={1} key={index}>
                            <Typography component={'span'} className={classes.priceTextCheck}>
                              {`${IdentityArr[item]}  ${getAddonPriceForBulk(
                                candidateConfig?.addons?.identity?.price
                              )}`}
                            </Typography>
                            <Typography component={'span'} className={classes.priceText}>
                              &#8377;{' '}
                              {`${candidateConfig?.addons?.identity?.price * numberOfCandidates}`}
                            </Typography>
                          </Box>
                        );
                      })
                      : null}
                  </Box>
                ) : null}
                {step3Values.address &&
                  (step3Values.address.any || step3Values.address.config.length) ? (
                  <Box mb={2}>
                    <Typography
                      component={'p'}
                      className={classes.headingInfo}
                      style={{ marginBottom: '8px' }}
                    >
                      {'Address'}
                    </Typography>

                    {step3Values.address.any ? (
                      <Box className={classes.summaryChecksContainer} mb={1}>
                        <Typography component={'span'} className={classes.priceTextCheck}>
                          {`${step3Values.address.any} * Other address ${getAddonPriceForBulk(
                            candidateConfig.addons.address.price
                          )}`}
                        </Typography>
                        <Typography component={'span'} className={classes.priceText}>
                          &#8377;{' '}
                          {`${candidateConfig.addons.address.price *
                            step3Values.address.any *
                            numberOfCandidates
                            }`}
                        </Typography>
                      </Box>
                    ) : null}

                    {step3Values?.address?.config && step3Values?.address?.config?.length
                      ? step3Values?.address?.config.map((item, index) => {
                        return (
                          <Box className={classes.summaryChecksContainer} mb={1} key={index}>
                            <Typography component={'span'} className={classes.priceTextCheck}>
                              {`${AddressArr[item]}`}
                              {step3Values?.address?.verification_type
                                ? `${getAddonPriceForBulk(
                                  candidateConfig?.addons[
                                    ADDON_CONFIG_MAPPING[
                                    step3Values?.address?.verification_type
                                    ]
                                  ]?.price
                                )}`
                                : ''}
                            </Typography>
                            {step3Values?.address?.verification_type && (
                              <Typography component={'span'} className={classes.priceText}>
                                &#8377;{' '}
                                {`${step3Values?.address?.verification_type
                                    ? candidateConfig?.addons[
                                      ADDON_CONFIG_MAPPING[
                                      step3Values?.address?.verification_type
                                      ]
                                    ]?.price * numberOfCandidates
                                    : '-'
                                  }`}
                              </Typography>
                            )}
                          </Box>
                        );
                      })
                      : null}
                  </Box>
                ) : null}
                {step3Values.court &&
                  (step3Values.court.any > 0 || step3Values.court.config.length) ? (
                  <Box mb={2}>
                    <Typography
                      component={'p'}
                      className={classes.headingInfo}
                      style={{ marginBottom: '8px' }}
                    >
                      {'Court record'}
                    </Typography>
                    {step3Values.court.any ? (
                      <Box className={classes.summaryChecksContainer} mb={1}>
                        <Typography component={'span'} className={classes.priceTextCheck}>
                          {`${step3Values.court.any} * Other address ${getAddonPriceForBulk(
                            candidateConfig.addons.court.price
                          )}`}
                        </Typography>
                        <Typography component={'span'} className={classes.priceText}>
                          &#8377;{' '}
                          {`${candidateConfig.addons.court.price *
                            step3Values.court.any *
                            numberOfCandidates
                            }`}
                        </Typography>
                      </Box>
                    ) : null}

                    {step3Values?.court?.config && step3Values?.court?.config?.length
                      ? step3Values?.court?.config.map((item, index) => {
                        return (
                          <Box className={classes.summaryChecksContainer} mb={1} key={index}>
                            <Typography component={'span'} className={classes.priceTextCheck}>
                              {`${AddressArr[item]}`}
                              {step3Values?.court?.verification_type
                                ? `${getAddonPriceForBulk(
                                  candidateConfig?.addons[
                                    ADDON_CONFIG_MAPPING[step3Values?.court?.verification_type]
                                  ]?.price
                                )}`
                                : ''}
                            </Typography>
                            {step3Values?.court?.verification_type && (
                              <Typography component={'span'} className={classes.priceText}>
                                &#8377;
                                {`${step3Values?.court?.verification_type
                                    ? candidateConfig?.addons[
                                      ADDON_CONFIG_MAPPING[
                                      step3Values?.court?.verification_type
                                      ]
                                    ]?.price * numberOfCandidates
                                    : '-'
                                  }`}
                              </Typography>
                            )}
                          </Box>
                        );
                      })
                      : null}
                  </Box>
                ) : null}
                {step3Values.educationRegionalPartner &&
                  (step3Values.educationRegionalPartner.count ||
                    step3Values.educationRegionalPartner.config.length) ? (
                  <>
                    <Box mb={2} className={classes.summaryChecksContainer}>
                      <Typography component={'p'} className={classes.headingInfo}>
                        {`${!step3Values.educationRegionalPartner.config.length
                            ? `${step3Values.educationRegionalPartner.count} * `
                            : ''
                          }Education ${getAddonPriceForBulk(
                            candidateConfig.addons.educationThirdParty.price
                          )}`}
                      </Typography>

                      {!step3Values.educationRegionalPartner.config.length ? (
                        <Typography component={'span'} className={classes.priceText}>
                          &#8377;{' '}
                          {`${candidateConfig.addons.educationThirdParty.price *
                            step3Values.educationRegionalPartner.count *
                            numberOfCandidates
                            }`}
                        </Typography>
                      ) : null}
                    </Box>

                    {step3Values?.educationRegionalPartner?.config?.length
                      ? step3Values?.educationRegionalPartner?.config.map((item, index) => {
                        return (
                          <Box className={classes.summaryChecksContainer} mb={2} key={index}>
                            <Typography component={'span'} className={classes.priceTextCheck}>
                              {`${EducationArr[item]}  ${getAddonPriceForBulk(
                                candidateConfig?.addons?.educationThirdParty?.price
                              )}`}
                            </Typography>
                            <Typography component={'span'} className={classes.priceText}>
                              &#8377;{' '}
                              {`${candidateConfig?.addons?.educationThirdParty?.price *
                                numberOfCandidates
                                }`}
                            </Typography>
                          </Box>
                        );
                      })
                      : null}
                  </>
                ) : null}
                {step3Values.educationHybrid &&
                  (step3Values.educationHybrid.count || step3Values.educationHybrid.config.length) ? (
                  <>
                    <Box mb={2} className={classes.summaryChecksContainer}>
                      <Typography component={'p'} className={classes.headingInfo}>
                        {`${!step3Values.educationHybrid.config.length
                            ? `${step3Values.educationHybrid.count} * `
                            : ''
                          }Education  ${getAddonPriceForBulk(
                            candidateConfig.addons.educationHybrid.price
                          )}`}
                      </Typography>

                      {!step3Values.educationHybrid.config.length ? (
                        <Typography component={'span'} className={classes.priceText}>
                          &#8377;{' '}
                          {`${candidateConfig.addons.educationHybrid.price *
                            step3Values.educationHybrid.count *
                            numberOfCandidates
                            }`}
                        </Typography>
                      ) : null}
                    </Box>

                    {step3Values?.educationHybrid?.config?.length
                      ? step3Values?.educationHybrid?.config?.map((item, index) => {
                        return (
                          <Box className={classes.summaryChecksContainer} mb={2} key={index}>
                            <Typography component={'span'} className={classes.priceTextCheck}>
                              {`${EducationArr[item]}   ${getAddonPriceForBulk(
                                candidateConfig?.addons?.educationHybrid?.price
                              )}`}
                            </Typography>
                            <Typography component={'span'} className={classes.priceText}>
                              &#8377;{' '}
                              {`${candidateConfig?.addons?.educationHybrid?.price *
                                numberOfCandidates
                                }`}
                            </Typography>
                          </Box>
                        );
                      })
                      : null}
                  </>
                ) : null}
                {step3Values.educationOfficial &&
                  (step3Values.educationOfficial.count ||
                    step3Values.educationOfficial.config.length) ? (
                  <>
                    <Box mb={2} className={classes.summaryChecksContainer}>
                      <Typography component={'p'} className={classes.headingInfo}>
                        {`${!step3Values.educationOfficial.config.length
                            ? `${step3Values.educationOfficial.count} * `
                            : ''
                          }Education   ${getAddonPriceForBulk(
                            candidateConfig.addons.education.price
                          )}`}
                      </Typography>

                      {!step3Values.educationOfficial.config.length ? (
                        <Typography component={'span'} className={classes.priceText}>
                          &#8377;{' '}
                          {`${candidateConfig.addons.education.price *
                            step3Values.educationOfficial.count *
                            numberOfCandidates
                            }`}
                        </Typography>
                      ) : null}
                    </Box>

                    {step3Values?.educationOfficial?.config?.length
                      ? step3Values?.educationOfficial?.config?.map((item, index) => {
                        return (
                          <Box className={classes.summaryChecksContainer} mb={2} key={index}>
                            <Typography component={'span'} className={classes.priceTextCheck}>
                              {`${EducationArr[item]} ${getAddonPriceForBulk(
                                candidateConfig?.addons?.education?.price
                              )}`}
                            </Typography>
                            <Typography component={'span'} className={classes.priceText}>
                              &#8377;{' '}
                              {`${candidateConfig?.addons?.education?.price * numberOfCandidates
                                }`}
                            </Typography>
                          </Box>
                        );
                      })
                      : null}
                  </>
                ) : null}
                {step3Values.education &&
                  (step3Values.education.count || step3Values.education.config.length) ? (
                  <>
                    <Box mb={2} className={classes.summaryChecksContainer}>
                      <Typography component={'p'} className={classes.headingInfo}>
                        {`${!step3Values.education.config.length
                            ? `${step3Values.education.count} * `
                            : ''
                          }Education ${step3Values.education.type === 'CHRONOLOGICAL'
                            ? getAddonPriceForBulk(getEduPrice(step3Values, candidateConfig))
                            : ''
                          }`}
                      </Typography>

                      {!step3Values.education.config.length ? (
                        <Typography component={'span'} className={classes.priceText}>
                          &#8377;{' '}
                          {`${getEduPrice(step3Values, candidateConfig) *
                            step3Values.education.count *
                            numberOfCandidates
                            }`}
                        </Typography>
                      ) : null}
                    </Box>

                    {step3Values?.education?.config?.length
                      ? step3Values?.education?.config?.map((item, index) => {
                        return (
                          <Box className={classes.summaryChecksContainer} mb={2} key={index}>
                            <Typography component={'span'} className={classes.priceTextCheck}>
                              {`${EducationArr[item]}   ${getAddonPriceForBulk(
                                getEduPrice(step3Values, candidateConfig)
                              )}`}{' '}
                            </Typography>
                            <Typography component={'span'} className={classes.priceText}>
                              &#8377;{' '}
                              {`${getEduPrice(step3Values, candidateConfig) * numberOfCandidates
                                }`}
                            </Typography>
                          </Box>
                        );
                      })
                      : null}
                  </>
                ) : null}
                {step3Values.employment ? (
                  <Box mb={2} className={classes.summaryChecksContainer}>
                    <Typography component={'p'} className={classes.headingInfo}>
                      {`${step3Values.employment} * Employment ${getAddonPriceForBulk(
                        candidateConfig.addons.employment.price
                      )}`}
                    </Typography>
                    <Typography component={'span'} className={classes.priceText}>
                      &#8377;{' '}
                      {`${candidateConfig.addons.employment.price *
                        step3Values.employment *
                        numberOfCandidates
                        }`}
                    </Typography>
                  </Box>
                ) : null}
                {step3Values.reference &&
                  step3Values.reference.config &&
                  step3Values.reference.config.length &&
                  getCount(step3Values.reference.config) ? (
                  <>
                    <Box mb={2} className={classes.summaryChecksContainer}>
                      <Typography component={'p'} className={classes.headingInfo}>
                        {`Reference ${getAddonPriceForBulk(
                          numberOfCandidates,
                          candidateConfig.addons.reference.price
                        )}`}
                      </Typography>
                    </Box>
                    {step3Values?.reference?.config?.map((item, index) => {
                      return parseInt(item) > 0 ? (
                        <Box mb={2} className={classes.summaryChecksContainer} key={index}>
                          <Typography component={'span'} className={classes.priceTextCheck}>
                            {`${item} * ${index === 0
                                ? 'Last employment'
                                : `${ordinal(index + 1)} Last employment`
                              } `}
                            {getAddonPriceForBulk(candidateConfig?.addons?.employment?.price)}
                          </Typography>
                          <Typography component={'span'} className={classes.priceText}>
                            &#8377;{' '}
                            {`${candidateConfig?.addons?.reference?.price *
                              parseInt(item) *
                              numberOfCandidates
                              }`}
                          </Typography>
                        </Box>
                      ) : null;
                    })}
                  </>
                ) : null}
                {step3Values.world && step3Values.world ? (
                  <Box mb={2} className={classes.summaryChecksContainer}>
                    <Typography component={'p'} className={classes.headingInfo}>
                      {`World check ${getAddonPriceForBulk(candidateConfig.addons.world.price)}`}
                    </Typography>
                    {candidateConfig && candidateConfig.addons && candidateConfig.addons.world ? (
                      <Typography component={'span'} className={classes.priceText}>
                        &#8377;{candidateConfig.addons.world.price * numberOfCandidates}
                      </Typography>
                    ) : null}
                  </Box>
                ) : null}
                {step3Values.drug5Panel ? (
                  <Box mb={2} className={classes.summaryChecksContainer}>
                    <Typography component={'p'} className={classes.headingInfo}>
                      {`Drug test (5 Panel) ${getAddonPriceForBulk(
                        candidateConfig.addons.drug5Panel.price
                      )}`}
                    </Typography>
                    {candidateConfig && candidateConfig.addons && candidateConfig.addons.cibil ? (
                      <Typography component={'span'} className={classes.priceText}>
                        &#8377;{candidateConfig.addons.drug5Panel.price * numberOfCandidates}
                      </Typography>
                    ) : null}
                  </Box>
                ) : null}
                {step3Values.drug7Panel ? (
                  <Box mb={2} className={classes.summaryChecksContainer}>
                    <Typography component={'p'} className={classes.headingInfo}>
                      {`Drug test (7 Panel) ${getAddonPriceForBulk(
                        candidateConfig.addons.drug7Panel.price
                      )}`}
                    </Typography>
                    {candidateConfig && candidateConfig.addons && candidateConfig.addons.cibil ? (
                      <Typography component={'span'} className={classes.priceText}>
                        &#8377;{candidateConfig.addons.drug7Panel.price * numberOfCandidates}
                      </Typography>
                    ) : null}
                  </Box>
                ) : null}
                {step3Values.drug10Panel ? (
                  <Box mb={2} className={classes.summaryChecksContainer}>
                    <Typography component={'p'} className={classes.headingInfo}>
                      {`Drug test (10 Panel) ${getAddonPriceForBulk(
                        candidateConfig.addons.drug10Panel.price
                      )}`}
                    </Typography>
                    {candidateConfig && candidateConfig.addons && candidateConfig.addons.cibil ? (
                      <Typography component={'span'} className={classes.priceText}>
                        &#8377;{candidateConfig.addons.drug10Panel.price * numberOfCandidates}
                      </Typography>
                    ) : null}
                  </Box>
                ) : null}
                {step3Values.cibil && step3Values.cibil ? (
                  <Box mb={2} className={classes.summaryChecksContainer}>
                    <Typography component={'span'} className={classes.headingInfo}>
                      {`Credit Check ${getAddonPriceForBulk(
                        candidateConfig.addons.cibil.price
                      )}   `}
                    </Typography>

                    {candidateConfig && candidateConfig.addons && candidateConfig.addons.cibil ? (
                      <Typography component={'span'} className={classes.priceText}>
                        &#8377;{candidateConfig.addons.cibil.price * numberOfCandidates}
                      </Typography>
                    ) : null}
                  </Box>
                ) : null}
              </Box>
            </>
          ) : null}
          <Divider />

          {packageType === 'PREPAID' ? (
            <>
              {totalPrice > 0 ? (
                <>
                  <Box mb={2} mt={2} className={classes.summaryChecksContainer}>
                    <Typography component={'p'} className={classes.headingInfo}>
                      {'Total credits'}
                    </Typography>
                    <Typography
                      component={'p'}
                      className={classes.priceText}
                      style={{ color: '#333333' }}
                    >
                      &#8377;{totalPrice}
                    </Typography>
                  </Box>

                  <Box mb={2} mt={2} className={classes.summaryChecksContainer}>
                    <Typography component={'p'} className={classes.creditBalanceText}>
                      {'Credit balance'}
                    </Typography>
                    <Typography component={'p'} className={classes.creditBalancePriceText}>
                      &#8377;{totalPrice < creditBalance ? totalPrice : creditBalance}
                    </Typography>
                  </Box>
                </>
              ) : null}
              {totalPrice - creditBalance > 0 ? (
                <Box mb={2} mt={2} className={classes.summaryChecksContainer}>
                  <Typography component={'p'} className={classes.creditBalanceText}>
                    {'Insufficient credits'}
                  </Typography>
                  <Typography component={'p'} className={classes.insuffBalancePriceText}>
                    &#8377;{totalPrice - creditBalance}
                  </Typography>
                </Box>
              ) : null}
              {totalPrice > creditBalance ? (
                <>
                  <Box mb={2} mt={2} className={classes.summaryChecksContainer}>
                    <Typography component={'p'} className={classes.taxText}>
                      {`Tax (${tax}%)`}
                    </Typography>
                    <Typography component={'p'} className={classes.taxPrice}>
                      &#8377;
                      {(18 * ((totalPrice - creditBalance) / 100)).toFixed(2)}
                    </Typography>
                  </Box>
                </>
              ) : null}
            </>
          ) : (
            <>
              <Box mb={2} mt={2} className={classes.summaryChecksContainer}>
                <Typography component={'p'} className={classes.headingInfo}>
                  {'Total'}
                </Typography>
                <Typography component={'p'} className={classes.creditBalancePriceText}>
                  &#8377;
                  {packageType === 'PREPAID'
                    ? totalPrice
                    : totalPrice + step2Values?.costPerCandidate * numberOfCandidates}
                </Typography>
              </Box>
            </>
          )}
        </CardContent>
        <CardActions>
          <Button
            type='button'
            size='small'
            variant={'contained'}
            className={classes.infoBtn}
            disabled={isDisabled()}
            onClick={() => {
              if (!buttonClicked) {
                startSubmit();
                setButtonClicked(true);
              }
            }}
          >
            {'Add Candidate'}
          </Button>
        </CardActions>
      </Card>
      {packageType === 'PREPAID' && totalPrice > creditBalance && (
        <Box mt={1}>
          <Alert severity='warning'>{`Insufficient credits for add-ons.`}</Alert>
        </Box>
      )}
    </>
  );
}
