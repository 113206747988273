// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fE1odrFeqYpQJ3BArBbm .DvD_VXI68lQmMC52J1wt {\n  font-size: 16px;\n}\n.fE1odrFeqYpQJ3BArBbm .no7PiX_jVPEVbSz38PqL {\n  font-size: 16px;\n  padding: 20px 20px 20px 0px;\n}\n.fE1odrFeqYpQJ3BArBbm .no7PiX_jVPEVbSz38PqL .Nmms_p69l4bm0jGVjy1b {\n  padding: 0px 9px;\n  color: #2755fe;\n}\n.fE1odrFeqYpQJ3BArBbm .no7PiX_jVPEVbSz38PqL .Nmms_p69l4bm0jGVjy1b:hover {\n  padding: 0px 9px;\n  color: #2755fe;\n  cursor: pointer;\n}\n\n.PrWrDvdA3jbelph54iDJ {\n  color: #fff;\n  margin: 10px 10px 6px 0px;\n  width: 136px;\n  height: 48px;\n  background-color: #007bff;\n  border-color: #007bff;\n  cursor: pointer;\n}\n\n.HNjc7_8OiyhfZgGYTMNh {\n  margin-right: 45px;\n  background: #ffffff;\n  border: 1px solid #2755fe;\n  border-radius: 5px;\n  width: 136px;\n  height: 48px;\n  font-family: Poppins, sans-serif;\n  font-size: 14px;\n  line-height: 20px;\n  color: #2755fe;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bodyDiv": "fE1odrFeqYpQJ3BArBbm",
	"heading1": "DvD_VXI68lQmMC52J1wt",
	"heading2": "no7PiX_jVPEVbSz38PqL",
	"aTag": "Nmms_p69l4bm0jGVjy1b",
	"btn": "PrWrDvdA3jbelph54iDJ",
	"backBtn": "HNjc7_8OiyhfZgGYTMNh"
};
export default ___CSS_LOADER_EXPORT___;
