import React, { useState } from 'react';

import {
  Grid,
  Dialog,
  Typography,
  IconButton,
  TextField,
  InputLabel,
  FormHelperText,
  Button
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Field } from 'formik';
import { getTrimmedValue } from '../../../../../../utils/Utlities';
import FormInput from '../../../../../../core-components/FormInput';
import Dropdown from '../../../../../../core-components/DropdownInput';
import { TRANSACTION_CHECK_TYPES, TRANSACTION_EVENTS } from '../../../../../../utils/Statuses';
import { isEmpty } from 'lodash';
const useStyles = makeStyles((theme) => ({
  label: {
    margin: '10px 0 5px',
    color: '#333333'
  },
  dropdownError: {
    color: '#f44336',
    margin: '4px 14px 0'
  },
  activeBtn: {
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  requiredField: {
    color: 'red'
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h5'>{props.title}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
    paddingTop: 2
  }
}))(MuiDialogActions);

function DeductionTransaction(props) {
  const classes = useStyles();
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false)
  const { values, setFieldValue, errors, handleSubmit, isSubmitting } = props;
  const onSubmitHandler = (e) => {
    e.preventDefault();
    setIsSaveButtonClicked(true);

    handleSubmit();
  };

  const handleFieldBlur = (fieldName) => (e) => {
    const trimmedValue = getTrimmedValue(e.target.value, true);
    setFieldValue(fieldName, trimmedValue);
  };

  const getValid = () => {
    if (!values.checkType) {
      return true;
    } else {
      if (isEmpty(errors)) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <div>
      <Dialog
        onClose={props.handleClose}
        aria-labelledby='customized-dialog-title'
        open={props.open}
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle
          id='customized-dialog-title'
          onClose={props.handleClose}
          title={props.title}
        ></DialogTitle>
        <form onSubmit={onSubmitHandler}>
          <DialogContent>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={3}>
                  <Grid item lg={4} md={6} sm={4} xs={4}>
                    <InputLabel className={classes.label}>
                      Check type<span className={classes.requiredField}>*</span>
                    </InputLabel>
                    <Dropdown
                      name='checkType'
                      label='Check Type'
                      dropdownValues={TRANSACTION_CHECK_TYPES}
                      fullWidth={true}
                      value={values.checkType}
                      handleSelect={(value) => {
                        setFieldValue('checkType', value);
                      }}
                    />
                    <FormHelperText className={classes.dropdownError}>
                      {errors.checkType}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <InputLabel className={classes.label}>
                      Check ID<span className={classes.requiredField}>*</span>
                    </InputLabel>
                    <Field
                      fullWidth={true}
                      type='text'
                      name='checkId'
                      component={FormInput}
                      variant='outlined'
                      onBlur={(e) => {
                        const trimmedValue = getTrimmedValue(e.target.value, false);
                        setFieldValue('checkId', trimmedValue || '');
                      }}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <InputLabel className={classes.label}>
                      Candidate ID<span className={classes.requiredField}>*</span>
                    </InputLabel>
                    <Field
                      fullWidth={true}
                      type='text'
                      name='candidateId'
                      component={FormInput}
                      variant='outlined'
                      onBlur={(e) => {
                        const trimmedValue = getTrimmedValue(e.target.value, false);
                        setFieldValue('candidateId', trimmedValue || '');
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={3}>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <InputLabel className={classes.label}>
                      Amount<span className={classes.requiredField}>*</span>
                    </InputLabel>
                    <Field
                      fullWidth={true}
                      type='text'
                      name='amount'
                      component={FormInput}
                      variant='normal'
                      onBlur={(e) => {
                        const trimmedValue = getTrimmedValue(e.target.value);
                        setFieldValue('amount', trimmedValue);
                      }}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <InputLabel className={classes.label}>
                      Event Type<span className={classes.requiredField}>*</span>
                    </InputLabel>
                    <Dropdown
                      name='eventType'
                      label='Event Type'
                      dropdownValues={TRANSACTION_EVENTS}
                      fullWidth={true}
                      value={values.eventType}
                      handleSelect={(value) => {
                        setFieldValue('eventType', value);
                      }}
                    />
                    <FormHelperText className={classes.dropdownError}>
                      {errors.eventType}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InputLabel className={classes.label}>
                  Additional Info (Public Comment)<span className={classes.requiredField}>*</span>
                </InputLabel>
                <TextField
                  name='additionalInfo'
                  type='text'
                  multiline
                  fullWidth={true}
                  rows={4}
                  value={values.additionalInfo}
                  variant='outlined'
                  onChange={(e) => {
                    setFieldValue('additionalInfo', e.target.value);
                  }}
                  onBlur={handleFieldBlur('additionalInfo')}
                />
                <FormHelperText className={classes.dropdownError}>
                  {errors.additionalInfo}
                </FormHelperText>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              type='submit'
              className={classes.activeBtn}
              disabled={isSaveButtonClicked || getValid() || isSubmitting}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default DeductionTransaction;
