import React, { useState, useEffect } from 'react';
import { getTrimmedValue } from '../utils/Utlities';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import { debounce } from 'lodash';

const useStyles = makeStyles((theme) => ({
  fontMin: {
    fontSize: '16px'
  },
  widthMin: {
    width: '165px'
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    height: 38,
    marginLeft: theme.spacing(0),
    flex: 1
  },
  iconButton: {
    padding: 6
  }
}));

export default function CustomizedSearchInput(props) {
  const classes = useStyles();
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleBlur = (e) => {
    const trimmedValue = props.shouldTrim ? getTrimmedValue(e.target.value, true) : e.target.value;
    setValue(trimmedValue);
  };

  const handleChange = (event) => {
    let inputValue = event.target.value;

    if (props.type === 'email' || props.type === 'id') {
      inputValue = getTrimmedValue(inputValue, false);
    }
    setValue(inputValue);

    let trimmedValue = getTrimmedValue(inputValue, props.shouldTrim ? true : false);
    props.handleSearch(trimmedValue || '');
  };

  // Create a debounced version of handleSearchInput
  return (
    <Paper
      className={props?.applyMinWidth ? `${classes.root} ${classes.widthMin}` : `${classes.root}`}
    >
      <InputBase
        className={props?.applyMinFont ? `${classes.input} ${classes.fontMin}` : `${classes.input}`}
        placeholder={props.placeholder}
        value={value}
        inputProps={{ 'data-testid': 'input-base' }}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </Paper>
  );
}

CustomizedSearchInput.defaultProps = {
  placeholder: 'Search',
  handleSearch: null,
  value: '',
  applyMinFont: false,
  applyMinWidth: false,
  isBackSpaceCallEnabled: false,
  shouldTrim: true
};
