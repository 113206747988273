import { Modal } from 'react-bootstrap';
import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textContainer: {
    fontSize: '14px',
    fontWeight: '500'
  },
  modalBody: {
    marginBottom: '20px'
  }
}));

const DeleteWarningModal = (props) => {
  const classes = useStyles();

  const handleOnClose = () => {
    props?.setIsDeleteWarningModalOpen(false);
  };

  return (
    <Modal centered show={props?.open} onHide={handleOnClose}>
      <Modal.Header closeButton>
        <Modal.Title>Cannot Delete Admin</Modal.Title>
      </Modal.Header>
      <Modal.Body className={classes.modalBody}>
        <p className={classes.textContainer}>
          The only admin user cannot be deleted. To delete this user, <br></br> make sure another
          admin exists.
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteWarningModal;
