// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".u1M4RSxCsTvocq3Wdcf7 {\n  border-radius: 5px;\n  padding: 0 16px;\n  border: 0;\n  height: 45px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "u1M4RSxCsTvocq3Wdcf7"
};
export default ___CSS_LOADER_EXPORT___;
