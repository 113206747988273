import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Dialog, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    marginRight: '96px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, packageName, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{packageName}</Typography>
      <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: '0 16px 16px'
  }
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  listItem: {
    fontFamily: 'Roboto',
    lineHeight: '24px'
  }
}));

export default function modalWrapper(props) {
  const classes = useStyles();
  const { children, open, onClose, title, modalSize } = props;

  return (
    <div>
      <Dialog
        onClose={onClose}
        aria-labelledby='audit-log-dialog-title'
        open={open}
        fullWidth={true}
        maxWidth={modalSize || 'md'}
      >
        <DialogTitle
          id='audit-log-dialog-title'
          packageName={title}
          onClose={onClose}
        ></DialogTitle>
        <DialogContent>
          <div className={classes.listItem}>{children}</div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
