import React, { useEffect, useState } from 'react';
import { forEach, isEmpty, has, debounce } from 'lodash';
import moment from 'moment';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Tooltip, Zoom, Typography } from '@material-ui/core';
import { Box, Grid } from '@material-ui/core';

import Table from '../../../core-components/Table';
import SearchInput from '../../../core-components/SearchInput';
import Dropdown from '../../../core-components/DropdownInput';
import Button from '../../../core-components/Button';
import BreadCrumbs from '../../../core-components/BreadCrumbs';
import AppConstants from '../../../core-components/AppConstants';
import { displayDateTimeFormat } from '../../../core-components/CommonConstants';
import { convertToIndianNumeration, getCourtVerificationType } from '../../../utils/Utlities';

function createData(
  candidateName,
  check,
  insuffType,
  cost,
  companyName,
  status,
  dateOpened,
  dateClosed
) {
  return { candidateName, check, insuffType, cost, companyName, status, dateOpened, dateClosed };
}

const headCells = [
  {
    id: 'candidateName',
    label: 'Candidate Name',
    align: 'center'
  },
  {
    id: 'check',
    label: 'Check',
    align: 'center'
  },
  {
    id: 'insuffType',
    label: 'Insuff Type',
    align: 'center'
  },
  {
    id: 'cost',
    label: 'Cost',
    align: 'center'
  },
  {
    id: 'companyName',
    label: 'Company Name',
    align: 'center'
  },
  {
    id: 'status',
    label: 'Status',
    align: 'center'
  },
  {
    id: 'dateOpened',
    label: 'Date Opened',
    align: 'center'
  },
  {
    id: 'dateClosed',
    label: 'Date Closed',
    align: 'center'
  }
];

const useStyles = makeStyles({
  linkText: {
    display: 'inline',
    width: 'fit-content',
    color: '#366CF3',
    fontSize: '14px',
    fontWeight: '500',
    cursor: 'pointer'
  },
  rupeeSymbol: {
    fontFamily: 'Roboto'
  },
  costContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
});

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    margin: '8px 0 0 -4px',
    backgroundColor: '#333333',
    color: '#ffffff',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #333333'
  }
}))(Tooltip);

const statusValues = [
  { label: 'Open', value: 'OPEN' },
  { label: 'Cleared', value: 'CLEARED' }
];

const STATUS = {
  OPEN: 'Open',
  CLEARED: 'Cleared'
};

export default function EnhancedTable(props) {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [status, setStatus] = useState(null);
  const [candidateName, setCandidateName] = useState(null);
  const [companyName, setCompanyName] = useState(null);

  const breadCrumbsArray = [{ label: 'Home', href: `${AppConstants.baseURL}dashboard` }];

  useEffect(() => {
    setLoading(true);
    props.invalidateInsuffFundsForChecks();
    props.getInsuffFundsForChecks(rowsPerPage, offset);
  }, []);

  useEffect(() => {
    if (has(props.insuffFundsForChecks, 'data')) {
      let rowsData =
        props?.insuffFundsForChecks &&
        props?.insuffFundsForChecks?.data &&
        props?.insuffFundsForChecks?.data?.length &&
        getRowData(props.insuffFundsForChecks.data);
      setLoading(props.insuffFundsForChecks.loading);
      setRows(rowsData || []);
      setTotalCount(props.insuffFundsForChecks.count);
    }
  }, [props.insuffFundsForChecks]);

  const getRowData = (values) => {
    let dataRow = [];

    forEach(values, function (value, key) {
      let additionalCost = 0;

      if (value?.additionalCost) {
        additionalCost = (
          <div className={classes.costContainer}>
            {value?.additionalCost < 0 ? '- ' : ''}
            <span className={classes.rupeeSymbol}>&#8377;</span>
            <span>
              {convertToIndianNumeration(
                parseInt(
                  value?.additionalCost < 0 ? value?.additionalCost * -1 : value?.additionalCost
                )
              )}
            </span>
          </div>
        );
      }

      if (!isEmpty(value)) {
        let rowData = createData(
          getCandidateName(value),
          getChecks(value),
          value.insuffType || '-',
          value.additionalCost ? additionalCost : '-',
          value.companyName || '-',
          STATUS[value.status] || '-',
          value.addedOn
            ? 'From: ' +
                moment(value.addedOn, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(displayDateTimeFormat)
            : '-',
          value.clearedAt
            ? 'Till: ' +
                moment(value.clearedAt, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(displayDateTimeFormat)
            : '-'
        );

        dataRow.push(rowData);
      }
    });
    return dataRow;
  };

  const getCandidateName = (value) => {
    return value.candidateName ? (
      <Button
        color={'link'}
        variant={'text'}
        label={value.candidateName}
        onClick={() => {
          window.open(
            `${AppConstants.baseURL}candidate/details?id=${value.candidateId}&company_id=${value.companyId}`,
            '_blank'
          );
        }}
      />
    ) : (
      '-'
    );
  };

  const getChecks = (check) => {
    switch (check.checkType) {
      case 'ID': {
        return (
          <Typography component={'p'} variant={'body2'}>
            {`Identity check  ${
              check.checkName && check.checkId ? `( ${check.checkName} (${check.checkId}))` : ''
            }`}
          </Typography>
        );
      }
      case 'ADDRESS': {
        const val = check?.verificationType ? `(${check?.verificationType})` : '';

        return (
          <Typography component={'p'} variant={'body2'}>
            {`Address check  ${
              check.checkName && check.checkId
                ? `(${check.checkName} ${val} (${check.checkId}))`
                : ''
            }`}
          </Typography>
        );
      }
      case 'EMPLOYMENT': {
        const val = check?.insuffType !== 'ADD_ON' ? check.empCompanyName : check.checkName;
        return (
          <Typography component={'p'} variant={'body2'}>
            {`Employment check  ${val && check.checkId ? `( ${val} (${check.checkId}))` : ''}`}
          </Typography>
        );
      }
      case 'EDUCATION': {
        const val = check?.insuffType !== 'ADD_ON' ? check.checkType : check.checkName;
        return (
          <>
            <Typography component={'p'} variant={'body2'}>
              {`Education check  ${val && check.checkId ? `( ${val} (${check.checkId}))` : ''}`}
            </Typography>

            <Typography component={'p'}>{`${check.instituteName || ''}`}</Typography>
          </>
        );
      }
      case 'COURT': {
        const val = check?.verificationType
          ? `(${getCourtVerificationType(check?.verificationType).toUpperCase()})`
          : '';
        return (
          <Typography component={'p'} variant={'body2'}>
            {`Court check ${check.checkId ? `(${check.checkName} ${val} (${check.checkId}))` : ''}`}
          </Typography>
        );
      }
      case 'REF': {
        return (
          <Typography component={'p'} variant={'body2'}>
            {`Reference check ${check.checkId ? `(${check.checkId})` : ''}`}
          </Typography>
        );
      }

      case 'CIBIL': {
        return (
          <Typography component={'p'} variant={'body2'}>
            {`Credit Check ${check.checkId ? `(${check.checkId})` : ''}`}
          </Typography>
        );
      }
      case 'WORLD': {
        return (
          <Typography component={'p'} variant={'body2'}>
            {`World check ${check.checkId ? `(${check.checkId})` : ''}`}
          </Typography>
        );
      }
      case 'DRUG': {
        return (
          <Typography component={'p'} variant={'body2'}>
            {`Drug check ${check.checkId ? `(${check.checkId})` : ''}`}
          </Typography>
        );
      }
      case 'CUSTOM': {
        return (
          <Typography component={'p'} variant={'body2'}>
            {`Custom check  ${
              check.checkName && check.checkId ? `( ${check.checkName} (${check.checkId}))` : ''
            }`}
          </Typography>
        );
      }
      default:
        return <Typography component={'p'} variant={'body2'}>{`-`}</Typography>;
    }
  };

  //these are filters
  const getStatusFilter = () => {
    return (
      <Dropdown
        fullWidth
        dropdownValues={statusValues}
        label={'Status'}
        value={status}
        handleSelect={(value) => {
          applyStatusFilter(value);
        }}
      />
    );
  };

  const getCandidateFilter = () => {
    return (
      <SearchInput
        placeholder={'Search Candidate name'}
        handleSearch={debounce((candidateName) => {
          applyCandidateFilter(candidateName?.trim());
        }, 500)}
      />
    );
  };

  const getCompanyFilter = () => {
    return (
      <SearchInput
        placeholder={'Search Company'}
        handleSearch={debounce((company) => {
          applyCompanyFilter(company?.trim());
        }, 500)}
      />
    );
  };

  const getInsuffFundsForChecks = (rowsPerPage, offset, status, candidateName, companyName) => {
    setLoading(true);
    props
      .getInsuffFundsForChecks(rowsPerPage, offset, status, candidateName, companyName)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const applyStatusFilter = (status) => {
    setStatus(status);
    setOffset(0);
    getInsuffFundsForChecks(rowsPerPage, 0, status, candidateName, companyName);
  };

  const applyCandidateFilter = (candidateName) => {
    setCandidateName(candidateName);
    setOffset(0);
    getInsuffFundsForChecks(rowsPerPage, 0, status, candidateName, companyName);
  };

  const applyCompanyFilter = (companyName) => {
    setCompanyName(companyName);
    setOffset(0);
    getInsuffFundsForChecks(rowsPerPage, 0, status, candidateName, companyName);
  };

  const handleChangePage = (newPage) => {
    setOffset(newPage);
    setLoading(true);
    getInsuffFundsForChecks(rowsPerPage, newPage, status, candidateName, companyName);
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setOffset(0);
    setLoading(true);
    getInsuffFundsForChecks(parseInt(value, 10), 0, status, candidateName, companyName);
  };

  return (
    <>
      <BreadCrumbs linksArray={breadCrumbsArray} current={'Insuff Funds for Checks'} />
      <Typography variant='h4' component='h3'>
        {'Insuff Funds for Checks'}
      </Typography>
      <Box mt={1}>
        <Grid container spacing={2}>
          <Grid item lg={3} md={4} sm={4} xs={10}>
            {getStatusFilter()}
          </Grid>
          <Grid item lg={3} md={4} sm={5} xs={10}>
            {getCandidateFilter()}
          </Grid>
          <Grid item lg={3} md={4} sm={5} xs={10}>
            {getCompanyFilter()}
          </Grid>
        </Grid>
      </Box>
      <Table
        headCells={headCells}
        rowData={rows}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={offset}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        loading={loading}
      />
    </>
  );
}
