import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith } from 'lodash';
import { toast } from 'react-toastify';

import { errToastMessage, getErrorMessage } from '../../../utils/Utlities';
import {
  getViewCandidateDetails as getViewCandidateDetailsAPI,
  getInsufficiencyForCandidate as getInsufficiencyForCandidateAPI
} from '../../../actions/admin';
import { setManualInsuffComment as setManualInsuffCommentAPI } from '../../../api/admin';

import validationSchema from './AddInsuffComments.validation';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getViewCandidateDetails: (companyId, candidateId) =>
      dispatch(getViewCandidateDetailsAPI(companyId, candidateId)),
    getInsufficiencyForCandidate: (candidateUuid) =>
      dispatch(getInsufficiencyForCandidateAPI(candidateUuid))
  };
};
const formatValues = (values) => {
  let valuesCopy = { ...values };
  if (valuesCopy.isEdit) {
    valuesCopy = values.comments[0] || {};
    valuesCopy.manualInsufficiencyCommentId = valuesCopy.id;
    valuesCopy.commentText = valuesCopy.text;
  } else {
    let comments = [];
    comments = valuesCopy.comments.filter((a) => a['text']);
    valuesCopy.comments = comments;
  }
  return valuesCopy;
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        manualInsufficiencyId: '',
        comments: [
          { text: '', type: 'PUBLIC' },
          { text: '', type: 'PRIVATE' }
        ]
      };

      let propsValue = { manualInsufficiencyId: props.tktDetails.id };

      if (props.isEdit) {
        let comments = [];
        comments.push(props.comments);
        propsValue = {
          manualInsufficiencyId: props?.tktDetails?.id,
          isEdit: props.isEdit ? true : false,
          comments: comments
        };
      }
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (values, { setFieldError, setStatus, setSubmitting, resetForm, props }) => {
      let request = formatValues(values);
      request['pushToReview'] = props.isPushToReview || false;

      setManualInsuffCommentAPI(request, values.isEdit ? 'UPDATE' : 'SET')
        .then((response) => {
          setSubmitting(false);
          if (props.action) {
            props.action();
          } else if (props.candidateUuid) {
            props.getInsufficiencyForCandidate(props.candidateUuid);
          }

          props.handleClose();
          toast.success('Comment Added/Updated Successfully');
        })
        .catch((error) => {
          console.error(error);
          setSubmitting(false);
          errToastMessage(error);
        });
    },
    displayName: 'Add Insuff comments'
  })
);
