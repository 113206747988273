import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { getTrimmedValue, isValidPhoneNumber } from '../../../utils/Utlities';
import {
  getEmailValidationSchema,
  getPhoneValidationSchema,
  getAlphabeticalStringValidationSchema,
  getUANValidationSchema
} from '../../../utils/ValidationSchema';

export default Yup.object().shape({
  name: getAlphabeticalStringValidationSchema({ message: 'name', isRequired: true }),
  email: getEmailValidationSchema({ isRequired: false })
    .when('formConfig', {
      is: (formConfig) => {
        return formConfig.isEmailOptional;
      },
      then: getEmailValidationSchema({ isRequired: true })
    })
    .when('emailExists', {
      is: true,
      then: Yup.string().test({
        name: 'email',
        message: 'Email already exists',
        test: (value) => {
          return false;
        }
      })
    }),
  alternateEmail: getEmailValidationSchema({}),
  phone: Yup.string()
    .trim()
    .transform((currentValue) => getTrimmedValue(currentValue) ? getTrimmedValue(currentValue) : currentValue)
    .when('phoneExists', {
      is: true,
      then: Yup.string().test({
        name: 'phone',
        message: 'Phone Number already exists',
        test: (value) => {
          return false;
        }
      })
    })
    .required('Phone number cannot be blank'),
  uanNumber: getUANValidationSchema({}),
  services: Yup.mixed().test('services', null, (services) => {
    if (!isEmpty(services)) {
      if (services.includes(2) && !services.includes(3)) {
        return new Yup.ValidationError(
          'Address Verification is needed for Court Verification to be done',
          null,
          'services'
        );
      } else if (services.includes(4) && !services.includes(5)) {
        return new Yup.ValidationError(
          'Education verification needs to be checked to proceed with Employment',
          null,
          'services'
        );
      } else if (!services.includes(4) && services.includes(5)) {
        return new Yup.ValidationError(
          'Employment verification needs to be checked to proceed with Education',
          null,
          'services'
        );
      }
    } else {
      return true;
    }
  })
});
