import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox
} from '@material-ui/core';
import { INDENTITES_FOR_PACKAGE_CONFIG, PLAIN_CHECK_ICONS } from '../../CommonConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  formControl: {
    margin: theme.spacing(3)
  }
}));

export default (props) => {
  const classes = useStyles();

  const { values, limit, identityChange, isUnassigned } = props;
  const isAllSelected = limit === 5;
  if (values?.config?.includes('ALL')) {
    values.any = 5;
  }
  const handleChange = (event, name) => {
    let dummy = [...values.config];
    if (event.target.checked) {
      if (!dummy.includes(name)) {
        dummy.push(name);
        identityChange('config', dummy);
        identityChange('any', values.any - 1);
      }
    } else {
      dummy.splice(dummy.indexOf(name), 1);
      identityChange('config', dummy);
      identityChange('any', values.any + 1);
    }
    if (isAllSelected && dummy.length > 1) {
      if (dummy.includes('ALL')) {
        dummy.splice(dummy.indexOf('ALL'), 1);
      }
    }
  };

  const hasValue = (name) => {
    return values.config.includes(name);
  };

  const aadharDisabled = (name) => {
    if (name === 'AADHAAR' && !props.isAadhaarAllowed) {
      return true;
    }
    return false;
  };

  const isDisabled = (name) => {
    return values.config.length === limit || aadharDisabled(name);
  };

  return (
    <>
      <div className={classes.root}>
        <Grid className={classes.root} item container>
          <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
            <Typography variant='h6' style={{ fontSize: '18px' }}>
              <span style={{ marginRight: '10px' }} title={'identity'}>
                <FontAwesomeIcon
                  icon={PLAIN_CHECK_ICONS['identity'] ? PLAIN_CHECK_ICONS['identity'].icon : faBan}
                  color='#222'
                />
              </span>
              {`Identity `}
              {!limit ? (
                <span
                  style={{ marginRight: '10px', fontSize: '12px', color: '#312c51' }}
                  title={'identity'}
                >
                  <i>{` (*Not Selected)`}</i>
                </span>
              ) : null}
            </Typography>
          </Grid>
          <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
            <FormControl component='fieldset' className={classes.formControl}>
              <Box mt={2} mb={1}>
                <b>
                  <i>{'Any Ids: '}</i>
                </b>
                {`${values.any} Id's`}
              </Box>
              <FormGroup>
                {INDENTITES_FOR_PACKAGE_CONFIG.map((value, index) => {
                  return (
                    <FormControlLabel
                      key={`Package_Configure_${index}`}
                      control={
                        <Checkbox
                          disabled={
                            (!hasValue(value.name) && isDisabled(value.name)) || isUnassigned
                          }
                          checked={hasValue(value.name)}
                          onChange={(e) => handleChange(e, value.name)}
                          name={value.name}
                        />
                      }
                      label={value.label}
                    />
                  );
                })}
              </FormGroup>
              <FormHelperText>{`Subtype config count: ${props.limit}`}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
