import { connect } from 'react-redux';
import { compose } from 'redux';

import { getDefaultQuestions as getDefaultQuestionsAPI, invalidateDefaultQuestions } from '../../../actions/admin';

const mapStateToProps = (state) => {
    return {
        questions: state.defaultQuestions,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getDefaultQuestions: (companyUuid, limit, offset, status) =>
            dispatch(getDefaultQuestionsAPI(companyUuid, limit, offset, status)),
        invalidateDefaultQuestions: () => dispatch(invalidateDefaultQuestions()),
    };
};
export default compose(connect(mapStateToProps, mapDispatchToProps));
